import {Spinner} from '@fluentui/react';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {formatFileSize} from '../../Components/FileUploadBoxPreview/FileUploadBoxPreview';
import {FileList, LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {IDocumentItem} from '../../Models/IDocument';
import {GenericActions} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import store from '../../Reducers/Store';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {getTrackingComponent} from './../../AppInsights';
import ContextMenu from '../../Components/ContextMenu/ContextMenu';
import './StarredPage.scss';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import PermissionsOnDocuments from '../../Utils/PermissionsOnDocuments';
import {UserActions} from '../../Reducers/User/UserAction';
import {IPermissionByData} from '../../Models/IUser';
import {getDocumentLabel, getDocumentLabelString} from '../../Utils/ComponentsUtils';
import AttachmentSmallBrowserButton from '../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import {formatCurrentVersion} from '../../Utils/VersioningUtils';

const StarredPage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'FavoritesPage'}
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** redux reference to documents  */
  let documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  /** this state is only used as hook to request specific actions across the whole site */
  let generic = useSelector((state: GlobalState) => state.generic);

  const [loadAmount, setLoadAmount] = useState<number>(100);
  const [reloadFavorited, setReloadFavorited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<IDocumentItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [documentsToDelete, setDocumentsToDelete] = useState<string[]>([]);
  let currentPermissions = useSelector((state: GlobalState) => state.user.permissionsForDocuments);
  useEffect(() => {
    let favIds = favorites.map((x: IDocumentItem) => {
      return x.documentId;
    });

    PermissionsOnDocuments.getDocumentPermissions(currentPermissions, favIds, (result: IPermissionByData[]) => {
      store.dispatch(UserActions.setPermissionsByDocuments(result));
    });
  }, [favorites]);

  useEffect(() => {
    /** test body */
    const documentSearchBody: any = {
      //"search": "",
      limit: loadAmount,
      offset: 0,
    };

    /** API call to get favorite documents */
    if (loggedUser !== undefined) {
      setLoading(true);
      ApiService.DocumentController.getFavoriteDocuments(loggedUser.id, documentSearchBody, (response: IAPIResponse) => {
        if (response.error == null) {
          setFavorites(response.payload);
        } else {
          setFavorites([]);
        }
        setLoading(false);
      });
    }

    dispatch(DocumentListAction.setActiveDocument(null));
  }, [reloadFavorited, generic.reloadRequest, loadAmount]);

  for (let i = 0; i < favorites.length; i++) {
    if (favorites[i].mainFile) {
      favorites[i]['fileSize'] = formatFileSize(favorites[i].mainFile.size);
    }
  }

  return (
    <div className="general-page-container">
      <DriveDocumentManagementToolbar
        type={
          documentSearch.activeDocument != null
            ? 'actions-on-document'
            : documentSearch.activeDocuments.length === 0
            ? 'navigation-voices'
            : 'action-on-multi-select-documents'
        }
      />{' '}
      <ModalDialog
        modalTitle={'Delete document?'}
        modalMessage={'Documents deleted in this way are moved to the User Trash Bin. Proceed anyway?'}
        enableModal={showModal}
        onAbort={() => {
          setShowModal(false);
        }}
        onAccept={async () => {
          setShowModal(false);
          for (let i = 0; i < documentsToDelete.length; i++) {
            let id: string = documentsToDelete[i];
            /** this pattern is needed in order to be able to await each file deletion */
            let result = await ApiService.DocumentController.setDeletedDocument({documentId: id, documentActivation: 2});

            if (result.error) {
              if (result.payload?.title === "User's Trash Bin is full") {
                window.document.dispatchEvent(new Event('trashBinFullModal'));
              }
            }
          }

          store.dispatch(GenericActions.reloadRequest());
        }}
      />
      <div className="favorites-page-main-wrap">
        <div className="favorites-page-title">
          Favourite documents
          <VideoTutorialIcon />
        </div>
        {loading && (
          <div className="favorites-page-spinner-wrap-main">
            <div className="favorites-page-spinner-inner">
              <Spinner label="Loading..." />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            <FileList
              defaultSortOnThisColumnIndex={2}
              fileIconFromField={'mainFile.fileName'}
              onItemsSelected={(selectedRows: any[]) => {
                if (selectedRows.length === 0) {
                  store.dispatch(DocumentListAction.setActiveDocument(null));
                  store.dispatch(DocumentListAction.setActiveDocuments([]));
                }
                if (selectedRows.length === 1) {
                  store.dispatch(DocumentListAction.setActiveDocument(selectedRows[0]));
                  store.dispatch(DocumentListAction.setActiveDocuments([]));
                }
                if (selectedRows.length > 1) {
                  store.dispatch(DocumentListAction.setActiveDocument(null));
                  store.dispatch(DocumentListAction.setActiveDocuments(selectedRows));
                }
              }}
              currentSelectedItems={documentSearch.activeDocuments}
              currentSelectedItem={documentSearch.activeDocument}
              hideBottomButton={true}
              columns={[
                {
                  name: 'File Type',
                  dataType: 'string',
                  fieldName: 'name',
                  iconName: 'Page',
                  size: 'tiny',
                },
                {
                  name: '',
                  dataType: 'string',
                  fieldName: '',
                  iconName: '',
                  size: 'micro',
                  onRender: (item: IDocumentItem) => {
                    return (
                      <div className="file-name-column-cell">
                        <span className="file-name-column-cell-buttons">
                          <ContextMenu
                            item={item}
                            type={'favorites'}
                            emitAction={(type: string) => {
                              if (type === 'favorites') {
                                store.dispatch(DocumentListAction.setActiveDocument(null));
                                store.dispatch(DocumentListAction.setActiveDocuments([]));
                                setReloadFavorited(!reloadFavorited);
                              }
                            }}
                          />
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Name',
                  dataType: 'string',
                  fieldName: 'documentName',
                  iconName: null,
                  size: 'large',
                  onRender: (item: IDocumentItem) => {
                    let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();
                    return (
                      <div className="file-name-column-cell">
                        <span
                          title={getDocumentLabelString(item.documentName, item.documentCode)}
                          className="file-name-column-cell-buttons-label file-linkname"
                          onClick={() => {
                            navigate(navUrl);
                          }}>
                          {getDocumentLabel(item.documentName, item.documentCode, navUrl)}
                          <div className="file-name-file-inline-button">
                            <AttachmentSmallBrowserButton ownerDocument={item} />
                          </div>
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Version',
                  dataType: 'string',
                  fieldName: 'versionNumber',
                  iconName: null,
                  size: 'small',
                  onRender: (item: IDocumentItem) => {
                    return formatCurrentVersion(item.versionNumber)
                  }
                },
                {
                  name: 'Created by',
                  dataType: 'string',
                  fieldName: 'createdBy.name',
                  iconName: null,
                  size: 'medium',
                },
                {
                  name: 'Modified',
                  dataType: 'date',
                  fieldName: 'updateDate',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Professional Area',
                  dataType: 'string',
                  fieldName: 'professionalArea.name',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Last modified',
                  dataType: 'date',
                  fieldName: 'updateDate',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'File size',
                  dataType: 'number',
                  fieldName: 'fileSize',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Countries',
                  dataType: null,
                  fieldName: null,
                  iconName: null,
                  size: 'small',
                  onRender: (item: IDocumentItem) => {
                    return <span>{item.countries.map((x) => x.name).join(', ')}</span>;
                  },
                },
              ]}
              items={favorites}
            />
            {favorites.length === loadAmount && (
              <div className="favorites-page-centered-button">
                <LabelButton
                  whiteOutlined
                  icon="ChevronDown"
                  text="Load more documents"
                  onClick={() => {
                    setLoadAmount(loadAmount + 100);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default getTrackingComponent(StarredPage, 'StarredPage');
