import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {IDocumentQuery} from '../../../Models/IDocument';
import {useNavigate} from 'react-router-dom';
import {GlobalState} from '../../../Reducers/RootReducer';
import URIformatter from '../../../Utils/URIformatter';
import {
  ContextualMenu,
  Dialog,
  DialogContent,
  DialogType,
  Dropdown,
  ITooltipHostStyles,
  ITooltipProps,
  Spinner,
  TooltipHost,
} from '@fluentui/react';
import {handleRenderTitle} from '../../../Components/ToolBar/ToolBar';
import SearchTagsControl from '../../../Components/SearchTagsControl/SearchTagsControl';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppRoutes from '../../../Utils/AppRoutes';
import moment from 'moment';
import {getSearchBaseUrl, SearchPagePropsData, setQueryStringWithDefaults} from '../DocumentSearchPage';
import {useNavigateToSearchAndReset} from '../../../Hooks/useNavigateToSearchAndReset';

interface DocumentSearchImagesProps extends SearchPagePropsData {
  images: string[];
  documents: IDocumentQuery[];
}

const DocumentSearchImages = (props: DocumentSearchImagesProps) => {
  const dispatch = useDispatch();
  const [searchBody, setSearchBody] = useState<string>('');
  const [simpleSearch, setSimpleSearch] = useState<string>('');
  const [documents, setDocuments] = useState<IDocumentQuery[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hideDialog, toggleHideDialog] = useState<boolean>(true);

  const [tagsDropdownSelectedKeys, setTagsDropdownSelectedKeys] = useState<string[]>([]);
  const [imageTagsFilter, setImageTagsFilter] = useState<any[]>([]);

  const [images, setImages] = useState<any[]>([]);
  const [filteredImages, setFilteredImages] = useState<any[]>([]);
  const [visibleImages, setVisibleImages] = useState<any[]>([]);

  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigateToSearchAndReset = useNavigateToSearchAndReset();
  let boColors = useSelector((state: GlobalState) => state.generic.boColors);

  let itemsPerPage = 30;

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setSimpleSearch(props.simpleSearch);
  }, [props.simpleSearch]);

  useEffect(() => {
    setSearchBody(props.searchBody);
  }, [props.searchBody]);

  useEffect(() => {
    setDocuments(props.documents);

    setHasMore(true);
    setImages( props.images.filter( (image: any) => ! ["TEXT", "LOGO"].includes(image.tag) ) );
  }, [props.images]);

  useEffect(() => {
    setFilteredImages(imageTagsFilter.length > 0 ? images.filter((image) => imageTagsFilter.includes(image.tag)) : images);
  }, [images, imageTagsFilter]);

  useEffect(() => {
    setVisibleImages(filteredImages.slice(0, itemsPerPage));
  }, [filteredImages]);

  useEffect(() => {
    if (visibleImages.length < filteredImages.length) {
      setHasMore(true);
    }
  }, [visibleImages]);

  let searchBodySearch = searchBody !== '' ? JSON.parse(searchBody).search : '';
  let activeSearchText = searchBodySearch !== '' ? searchBodySearch : '';

  let imageTagOptions: any[] = [{key: 'All', text: 'All image tags'}];
  let tags = [
    '2D_PLOT',
    'GEOSECTION',
    'LOG',
    'MAP',
    'MODEL',
    'OUTCROP',
    'PAPA',
    'PAPA_NEW',
    'PRES',
    'PRES_NEW',
    'PSE_CHART',
    'SEISMIC',
    'SEM',
    'STRATIGRAPHY',
    'TABLE',
  ];
  imageTagOptions = imageTagOptions.concat(
    tags.map((tag: string) => {
      return {key: tag, text: tag};
    })
  );

  const removeFilter = (paths: string[], isLast: boolean) => {
    if (searchBody === '') {
      return;
    }

    let obj = JSON.parse(searchBody);

    for (const path of paths) {
      let stepObj = obj;
      let splPath = path.split('.');
      for (let i = 0; i < splPath.length; i++) {
        let step: string = splPath[i];

        /** last step */
        if (i === splPath.length - 1) {
          delete stepObj[step];
        } else {
          stepObj = stepObj[step];
        }
      }
    }

    let simpleSearchToggle = isLast ? '&simpleSearch=yes' : '';

    obj = setQueryStringWithDefaults(obj);
    navigateToSearchAndReset(`${getSearchBaseUrl()}?searchBody=${URIformatter.encode(JSON.stringify(obj)) + simpleSearchToggle}`);
  };

  let simpleSearchError = simpleSearch === 'yes' && activeSearchText.length <= 3;

  //Dialog section
  function toggleDialog(image: any) {
    let data = documents.find((doc) => doc.document.documentId === image.documentId);
    setCurrentImage({data, image});
    toggleHideDialog(false);
  }

  const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: true,
  };
  const dialogStyles = {
    main: {
      selectors: {
        ['@media (min-width: 480px)']: {
          width: '70vw',
          minWidth: '70vw',
          maxWidth: '70vw',
          height: 'calc(100vh - 5.2em - 65px)',
        },
      },
    },
  };
  const dialogContentProps = {
    type: DialogType.close,
  };

  useEffect(() => {
    if (!hideDialog) {
    }
  }, [hideDialog]);
  //end of Dialog section

  const fetchMoreData = () => {
    if (visibleImages.length >= filteredImages.length) {
      setHasMore(false);
      return;
    }

    let newImages = filteredImages.slice(visibleImages.length, visibleImages.length + itemsPerPage);

    setVisibleImages([...visibleImages, ...newImages]);
  };

  return (
    <div className="search-left-column-wrap-background">
      <nav className="search-left-column-wrap">
        <div className="search-left-column-wrap-inner">
          {simpleSearchError && <div className="document-search-header">Search images using the search bar above.</div>}
          {!simpleSearchError && (
            <div className="document-search-header-wrap">
              <div className="document-search-header">Images results based on the following criteria:</div>
              <div className="document-search-header">
                Images:
                <div className="document-search-text">{loading ? '0 of 0' : visibleImages.length + ' of ' + filteredImages.length}</div>
              </div>
              <div className="image-tags-dropdown-outer-wrap">
                {
                  <Dropdown
                    multiSelect
                    label="Image Classification Tags"
                    placeholder="Select tags"
                    onRenderTitle={handleRenderTitle}
                    disabled={false}
                    options={imageTagOptions}
                    selectedKeys={tagsDropdownSelectedKeys}
                    onChange={(event: any, option: any) => {
                      let selectedTags: any[];

                      if (option.key === 'All' && option.selected) {
                        setTagsDropdownSelectedKeys(imageTagOptions.map((o) => o.key));
                        selectedTags = imageTagOptions.map((o) => o.key).filter((o) => o != 'All');
                      } else if (option.key === 'All' && !option.selected) {
                        setTagsDropdownSelectedKeys([]);
                        selectedTags = [];
                      } else if (option.selected) {
                        setTagsDropdownSelectedKeys([...tagsDropdownSelectedKeys, option.key]);
                        selectedTags = [...imageTagsFilter, option.key];
                      } else {
                        setTagsDropdownSelectedKeys(tagsDropdownSelectedKeys.filter((o) => ![option.key, 'All'].includes(o)));
                        selectedTags = imageTagsFilter.filter((tag) => tag != option.key);
                      }
                      setImageTagsFilter(selectedTags);
                    }}
                  />
                }
              </div>
            </div>
          )}

          {searchBody !== '' && (
            <div>
              <SearchTagsControl
                query={searchBody}
                onRemove={(path: string, isLast: boolean) => {
                  let filterToRemove: string[] = path.split(';');
                  removeFilter(filterToRemove, isLast);
                }}
              />
            </div>
          )}

          <div className="document-search-boxes-wrap">
            {loading && (
              <div className="search-spinner-wrap-main">
                <div className="search-spinner-inner">
                  <Spinner label="Search in progress..." />
                </div>
              </div>
            )}
            {!loading && (
              <div>
                {!simpleSearchError && visibleImages.length > 0 && (
                  <InfiniteScroll
                    dataLength={visibleImages.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height={'calc(100vh - 260px)'}
                    loader={<h4 />}
                    className="search-images-list-wrap">
                    {visibleImages.map((image: any, i: number) => {
                      return (
                        <div className="search-images-image-wrap" key={i} onClick={() => toggleDialog(image)}>
                          <img src={image.thumbnailUrl} alt="" />
                          <div className="search-image-description p-mt-2 p-mb-2">{image.documentName}</div>
                          {image.tag && image.probability && <span className="search-images-tag full-max-width">{image.tag}</span>}
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                )}

                <div>
                  {!simpleSearchError && images?.filter((x) => x !== undefined).length === 0 && (
                    <div className="search-no-results-label">The search did not produce any image result.</div>
                  )}
                  {simpleSearchError && (
                    <div className="search-no-results-label">
                      To perform a basic search, your search text must be longer than 3 characters or you must use any other advanced search
                      criteria.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      <Dialog
        hidden={hideDialog}
        onDismiss={() => {
          toggleHideDialog(true);
          setImageLoaded(false);
        }}
        modalProps={{
          isBlocking: false,
          styles: dialogStyles,
        }}
        dialogContentProps={dialogContentProps}>
        {currentImage && (
          <DialogContent>
            <div className="container-document-preview">
              <div className="p-grid">
                <div className="p-grid p-dir-col p-col-12 p-md-6 p-lg-6 p-justify-center">
                  <div className="p-d-flex p-dir-col p-align-center p-pl-4 p-pr-4 ">
                    {
                      <img
                        className="full-width image-cover"
                        src={currentImage.image.imageUrl}
                        onLoad={() => setImageLoaded(true)}
                        style={{display: imageLoaded ? 'block' : 'none'}}
                      />
                    }
                    {!imageLoaded && (
                      <div>
                        <Spinner label="Loading..." />
                      </div>
                    )}
                  </div>
                </div>

                <div className="p-grid p-dir-col p-justify-between p-col-12 p-md-6 p-lg-6 full-height">
                  <div className="title-preview p-mb-4">
                    <a
                      href={
                        AppRoutes.DOCUMENT_MANAGEMENT +
                        '?documentid=' +
                        currentImage.data.document.documentId.toString() +
                        '&backSearch=' +
                        encodeURI(window.location.href.replace(window.location.origin, ''))
                      }
                      target="_self">
                      {currentImage.data.document.documentName}
                    </a>
                    {currentImage.data.document.content && (
                      <div className="body-content-preview">
                        <p>{currentImage.data.document.content}</p>
                      </div>
                    )}
                  </div>
                  <div className="container-data-document-preview">
                    <div className="head-title-box-list" />
                    <div className="box-info-document-preview">
                      <div className=" p-grid">
                        <span className="title-info-document p-col-12 p-xl-5 p-lg-6">Professional Area:</span>
                        <span className="info-document p-col-12 p-xl-7 p-lg-6"> {currentImage.data.document.professionalArea.name}</span>
                      </div>
                      <div className=" p-grid">
                        <span className="title-info-document p-col-12 p-xl-5 p-lg-6">Document Type:</span>
                        <span className="info-document p-col-12 p-xl-7 p-lg-6"> {currentImage.data.document.documentType.name}</span>
                      </div>
                      <div className=" p-grid">
                        <span className="title-info-document p-col-12 p-xl-5 p-lg-6">Document Subtype:</span>
                        <span className="info-document p-col-12 p-xl-7 p-lg-6"> {currentImage.data.document.documentSubType.name}</span>
                      </div>
                      <div className=" p-grid">
                        <span className="title-info-document p-col-12 p-xl-5 p-lg-6">Author:</span>
                        <span className="info-document p-col-12 p-xl-7 p-lg-6"> {currentImage.data.document.authors[0].name}</span>
                      </div>
                      <div className=" p-grid">
                        <span className="title-info-document p-col-12 p-xl-5 p-lg-6"> Creation: </span>
                        <span className="info-document p-col-12 p-xl-7 p-lg-6">
                          {moment(currentImage.data.document.creationDate).format('DD-MM-YYYY')}
                        </span>
                      </div>
                      <div className=" p-grid">
                        <span className="title-info-document p-col-12 p-xl-5 p-lg-6"> Update: </span>
                        <span className="info-document p-col-12 p-xl-7 p-lg-6">
                          {moment(currentImage.data.document.updateDate).format('DD-MM-YYYY')}
                        </span>
                      </div>
                    </div>
                    <div className="title-preview p-mt-5">
                      <p className="subtitle-preview">Business Objects</p>
                    </div>
                    <div className="box-list-labels labels-document-preview p-mt-2">
                      {currentImage.data.document.bOs?.map((bo, index) => {
                        const tooltipId = 'tooltip-' + index;
                        const calloutProps = {gapSpace: 0};
                        let boElem = boColors.find((boI) => boI.name === bo.type);
                        const hostStyles: Partial<ITooltipHostStyles> = {root: {display: 'inline-block'}};
                        const tooltipProps: ITooltipProps = {
                          onRenderContent: () => <span>{boElem.displayName}</span>,
                        };

                        return (
                          <div key={index}>
                            <TooltipHost
                              // This id is used on the tooltip itself, not the host
                              // (so an element with this id only exists when the tooltip is shown)
                              id={tooltipId}
                              calloutProps={calloutProps}
                              tooltipProps={tooltipProps}>
                              {boElem && bo.values.length > 0 && (
                                <span aria-describedby={tooltipId} className="container-labels" style={{backgroundColor: boElem.cardColor}}>
                                  {bo.values[0].name}
                                </span>
                              )}
                            </TooltipHost>
                          </div>
                        );
                      })}
                    </div>
                    {currentImage.image.tag && currentImage.image.probability && (
                      <div>
                        <div className="title-preview p-mt-4">
                          <p className="subtitle-preview">Image Classification Tag</p>
                        </div>
                        <div className="box-document-type p-d-flex p-justify-between p-mt-2">
                          <div className="p-d-flex">
                            <span className="status-bubble-inner status-bubble-complete p-mr-2">
                              {currentImage.image.tag + ' (' + (currentImage.image.probability * 100).toFixed(2) + '%)'}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default DocumentSearchImages;
