import { LabelButton, ModalDialog } from '@Eni/docware-fe-master';
import { ITag } from '@fluentui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IBaseLibraries } from '../../Models/IUser';
import { getInputInfos } from '../../Reducers/Generic/GenericAction';
import { GlobalState } from '../../Reducers/RootReducer';
import { UserActions } from '../../Reducers/User/UserAction';
import { IAPIResponse } from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import { getAdminCountries } from '../../Utils/AdminUtils';
import AppRoutes from '../../Utils/AppRoutes';
import { TagUtils } from '../../Utils/TagUtils';
import Feature from '../FeatureFlag/Feature';
import useFeature from '../FeatureFlag/useFeature';
import { FluentUIDecorator, FluentUIDecoratorTypes } from '../FluentUIDecorator/FluentUIDecorator';
import { EXP_ASSURANCE_NAME } from '../../Constants/Constants';
import { IAG_FF_KEY } from '../../Constants/FeatureFlags';

interface UserInfoTableLibraryRowProps {
  library: IBaseLibraries;
  isUserInfoPage: boolean;
  userId: string;
  adminSectionOnSave: (userId: string) => void;
}

const UserInfoTableLibraryRow = ({library, isUserInfoPage, userId, adminSectionOnSave}: UserInfoTableLibraryRowProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countriesOptions = useSelector((state: GlobalState) => state.admin.countries);
  const iagActive = useFeature(IAG_FF_KEY);
  const [countriesModalActive, setCountriesModalActive] = useState<boolean>(false);
  const [countriesModalSaving, setCountriesModalSaving] = useState<boolean>(false);
  const [newCountries, setNewCountries] = useState<ITag[]>(library.countries?.map(TagUtils.optionToTag) ?? []);


  function onCloseCountriesModal(){
    setCountriesModalActive(false)
    setNewCountries(library.countries?.map(TagUtils.optionToTag) ?? [])
  }

  function onSaveCountriesModal(){
    setCountriesModalSaving(true)
    ApiService.UsersController.saveUserCountries(library.id, userId, newCountries, (response: IAPIResponse) => {
      if (response.error == null) {
        setCountriesModalSaving(false)
        setCountriesModalActive(false)
        adminSectionOnSave(userId)
      }
    })
  }

  return (
    <>
      <div className="user-info-table-libraries-container">
        <div className="user-info-table-libraries-name-and-email">
          <span>{library.name}</span>
          <span>{library.name === EXP_ASSURANCE_NAME ? '' : library.supportEmail}</span>
        </div>
        <table className="user-info-table-table">
          <thead className="user-info-table-table-head">
            <tr role={'row'}>
              <Feature featureKey={IAG_FF_KEY}>
                <th role={'columnheader'}>Associated on IAG</th>
              </Feature>
              <th role={'columnheader'}>Professional Areas</th>
              <th role={'columnheader'}>Element Types</th>
              <th role={'columnheader'}>Countries</th>
            </tr>
          </thead>
          <tbody className="user-info-table-table-body">
            <tr>
              <Feature featureKey={IAG_FF_KEY}>
                <td role={'cell'}>
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {library.userProfileAssociatedOnIAG ? '🟢 Enabled' : '🔴 Not Enabled'}
                  </span>
                  <span style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <br></br>
                    {library.iagLibraryCode ?? ""}
                  </span>
                </td>
              </Feature>
              <td role={'cell'}>{library.professionalAreas?.map(({name}) => name).join(', ')}</td>
              <td role={'cell'}>{library.elementTypes?.map(({name}) => name).join(', ')}</td>
              <td role={'cell'} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                {library.selectableCountries && !isUserInfoPage && (
                  <LabelButton
                    text="Manage Countries"
                    onClick={() => {
                      setCountriesModalActive(true);
                    }}
                  />
                )}
                <span>{library.countries.map(({name}) => name).join(', ')}</span>{' '}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="user-info-table-libraries-link">
          {isUserInfoPage && library.showAskPermissionForButton && (
            <LabelButton
              text={`Ask permission for ${library.name}`}
              disabled={iagActive ? !library.userProfileAssociatedOnIAG : false}
              icon="Add"
              whiteOutlined
              onClick={() => {
                dispatch(
                  UserActions.setUserInfo({
                    libraryId: library.id,
                  })
                );
                navigate(AppRoutes.USER_REQUEST);
              }}
            />
          )}
          <div></div>
          {isUserInfoPage && !library.userProfileAssociatedOnIAG && (
            <Feature featureKey={IAG_FF_KEY}>
              <LabelButton
                text={`Go to IAG`}
                //disabled={library.userProfileAssociatedOnIAG}
                icon="Add"
                whiteOutlined
                onClick={() => {
                  window.open("https://iag.eni.com/", '_blank').focus();
                }}
              />
            </Feature>
          )}
        </div>
      </div>
      <ModalDialog
      fullWidth={true}
        modalTitle={'Manage Countries'}
        modalInnerComponent={
        <div>
        <span>Library: {library?.name}</span>
        <FluentUIDecorator
              label="Countries"
              info={getInputInfos('new-admin-section-countries', 'countries')}
              errorMessage={undefined}
              fluentComponent={FluentUIDecoratorTypes.TagPicker({
                //inputProps: {id: 'metadataCountry'},
                onResolveSuggestions: (f, s) => getAdminCountries(f, s, countriesOptions),
                selectedItems: newCountries,
                onChange: (value?: ITag[]) => {
                  setNewCountries(value)
                },
              })}
            />
        </div>
      }
        onAbort={onCloseCountriesModal}
        modalButtons={[
          {
            onClick: onCloseCountriesModal,
            label: 'Close',
          },
          {
            onClick: onSaveCountriesModal,
            label: 'Save',
          },
        ]}
        enableModal={countriesModalActive}
        loadingButtons={countriesModalSaving}
      />
    </>
  );
};

export default UserInfoTableLibraryRow;
