import {IconButton, ITooltipHost, ITooltipHostStyles, ITooltipProps, TooltipHost} from '@fluentui/react';
import {useId} from '@fluentui/react-hooks';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getInputInfosValue} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import InputInfoIniter from '../InputInfoIniter/InputInfoIniter';
import './InputInfoWidget.scss';

export interface IInputInfoWidgetProps {
  keyString: string;
  page: string;
  overWrite?: string;
}

const calloutProps = {gapSpace: 0};

const hostStyles: Partial<ITooltipHostStyles> = {
  root: {display: 'inline-block'},
};

const InputInfoWidget = (props: IInputInfoWidgetProps) => {
  const tooltipId = useId('tooltip');
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);
  const tooltipRef = React.useRef<ITooltipHost>(null);
  const [displayEditor, setDisplayEditor] = useState<boolean>(false);
  const [lockInit, setLockInit] = useState<boolean>(false);
  const [currentContent, setCurrentContent] = useState<string>('');

  const doLockInit = () => {
    setLockInit(true);
    setTimeout(() => {
      try {
        setLockInit(false);
      } catch (e) {}
    }, 3500);
  };

  const init = (cnt?: string) => {
    if (lockInit) {
      return;
    }

    let currentValue = cnt ? cnt : getInputInfosValue(props.page, props.keyString);
    if (currentValue != null) {
      setCurrentContent(currentValue);
      setDisplayEditor(false);
    } else {
      setDisplayEditor(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <span
      className="fluent-ui-decorator-icon"
      onMouseEnter={() => {
        init();
      }}>
      <TooltipHost
        componentRef={tooltipRef}
        tooltipProps={
          {
            onRenderContent: () => {
              return (
                <div>
                  {displayEditor && (
                    <InputInfoIniter
                      emit={(cnt: string) => {
                        init(cnt);
                      }}
                      initialValue={currentContent}
                      keyString={props.keyString}
                      page={props.page}
                    />
                  )}
                  {!displayEditor && (
                    <div style={{display: 'flex', alignItems: 'end'}}>
                      <div
                        style={{minHeight: '2em'}}
                        dangerouslySetInnerHTML={{__html: (props.overWrite ? props.overWrite : currentContent)}}></div>
                      {loggedUser && loggedUser.isSuperAdmin ? (
                        <div style={{marginLeft: '1em'}}>
                          <IconButton
                            onClick={() => {
                              doLockInit();
                              setDisplayEditor(true);
                            }}
                            iconProps={{iconName: 'Edit'}}
                            aria-label="Edit this field."
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  )}
                </div>
              );
            },
          } as ITooltipProps
        }
        // This id is used on the tooltip itself, not the host
        // (so an element with this id only exists when the tooltip is shown)
        id={tooltipId}
        calloutProps={calloutProps}
        styles={hostStyles}
        setAriaDescribedBy={false}>
        <IconButton iconProps={{iconName: 'Info'}} aria-label="Information about this field." />
      </TooltipHost>
    </span>
  );
};

export default InputInfoWidget;
