import {HierarchyLibrary, ILibrary, IMetadata, IProfessionalArea} from '../Models/ILibrary';
import { ISecurityLevelOptions } from '../Models/Types';

export const LibraryUtils = {
  getMetadata: function(hierarchyLibrary: HierarchyLibrary[]): IMetadata[] {
    return hierarchyLibrary.map(x => x.metadata).flat();
  },
  getDefaultSecurityLevelsFromLibrary: function(library: ILibrary, professionalArea: IProfessionalArea): ISecurityLevelOptions {
    const securityLevels: ISecurityLevelOptions = {
      options: library?.securityLevels ?? [],
      default: library?.defaultSecurityLevel,
    };
    if (professionalArea?.securityLevels) {
      securityLevels.options = professionalArea.securityLevels;
    }
    if (professionalArea?.defaultSecurityLevel) {
      securityLevels.default = professionalArea.defaultSecurityLevel;
    }
    return securityLevels;
  }
};