import {Spinner} from '@fluentui/react/lib/Spinner';
import './FilePickerFromTeamsOrLocal.scss';
import {ITeamItem} from '../../Models/ITeam';
import FileFromTeams from '../FileFromTeams/FileFromTeams';
import DragAndDrop from '../DragAndDrop/DragAndDrop';
import {FileSelectArea, FileSelectAreaProps} from '../FileSelectArea/FileSelectArea';

interface FilePickerFromTeamsOrLocalProps {
  loading: boolean;
  onDropAndClickLocalFiles: (selectedFiles: File[], unzipFiles?: boolean) => any;
  onTeamsFileSelection: (files: ITeamItem[]) => any;
  fileSelectAreaProps: FileSelectAreaProps;
  teamsFilePicker: boolean;
  uploadDisabled?: boolean;
  displayLoadingOverlay?: boolean;
  checkFileUnZip?: boolean;
}

export const FilePickerFromTeamsOrLocal = (props: FilePickerFromTeamsOrLocalProps) => {
  return (
    <>
      <div style={props.loading && props.displayLoadingOverlay ? {opacity: '0.1'} : {cursor: 'pointer'}}>
        {props.uploadDisabled ? (
          <FileSelectArea {...props.fileSelectAreaProps} />
        ) : props.teamsFilePicker ? (
          <FileFromTeams
            multiFileSelect={true}
            onFileSelection={props.onTeamsFileSelection}
            elementToRender={<FileSelectArea {...props.fileSelectAreaProps} />}
          />
        ) : (
          <DragAndDrop
            onDropAndClick={props.onDropAndClickLocalFiles}
            inputMultiFileSelect={true}
            elementToRender={<FileSelectArea {...props.fileSelectAreaProps} />}
            checkFileUnZip={props.checkFileUnZip}
          />
        )}
      </div>

      {props.loading && (
        <div className={props.displayLoadingOverlay ? 'upload-spinner-wrap-main-overlay' : 'upload-spinner-wrap-bottom'}>
          <div className="upload-spinner-inner">
            <Spinner label="Uploading document..." />
          </div>
        </div>
      )}
    </>
  );
};
