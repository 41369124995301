const EventDispatcher = {
  dispatchToast: (text: string, type: DispatchToastType): void => {
    window.document.dispatchEvent(
      new CustomEvent('api-toast-result', {
        detail: {
          text: text,
          type: type,
        },
      })
    );
  },
};

type DispatchToastType = 'success' | 'info' | 'warning' | 'error' | 'longError';

export default EventDispatcher;
