import './VideoTutorialIcon.scss';
import {useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import Icons from '../../Utils/FabricIconsOutlet';
import AppRoutes from '../../Utils/AppRoutes';
import {useLocation} from 'react-router-dom';
import {CSSProperties, useEffect, useState} from 'react';
import {IVideoTutorial} from '../../Models/IVideoTutorial';

interface VideoTutorialIconProps {
  page?: string;
  style?: CSSProperties;
  iconFontSize?: string;
}

const VideoTutorialIcon = (props: VideoTutorialIconProps) => {
  const videosTutorial = useSelector((state: GlobalState) => state.generic.videosTutorial);
  const [pageVideoTutorial, setPageVideoTutorial] = useState<IVideoTutorial>();
  const {pathname} = useLocation();

  /*
   * currentLocation could be:
   *   /drive
   *   /drive/massive-import
   *   /drive/mydocuments
   * */
  const getCurrentPage = (currentLocation: string): string => {
    const urlParts = currentLocation.split('/');
    const lastPart = urlParts.pop() || urlParts.pop();
    return lastPart;
  };

  const getVideoTutorial = (page: string): IVideoTutorial | null => {
    return videosTutorial.filter((x) => x.page.toLocaleLowerCase() === page.toLocaleLowerCase())[0];
  };

  useEffect(() => {
    if (props.page) {
      setPageVideoTutorial(getVideoTutorial(props.page));
    } else {
      const currentPage = getCurrentPage(pathname);
      setPageVideoTutorial(getVideoTutorial(currentPage));
    }
  }, [props.page]);

  return (
    <>
      {pageVideoTutorial ? (
        <>
          {' '}
          <a
            className="video-tutorial-icon-anchor"
            style={props.style}
            href={`${AppRoutes.VIDEOS_TUTORIAL}/video/${pageVideoTutorial.fileName}`}
            target="_blank">
            {Icons.getIcon('Video', props.iconFontSize)}
          </a>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default VideoTutorialIcon;
