import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {Spinner, TextField, Toggle} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {DocumentSource} from '../../../Models/DocumentSource';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import GenericList from '../../../Components/GenericList/GenericList';
import './AdminDocumentSources.scss';

const AdminDocumentSources = (props: any) => {
  const [docSources, setDocSources] = useState<DocumentSource[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<DocumentSource | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<boolean>(true);

  const LoadItems = () => {
    ApiService.DocumentSourcesController.getDocumentSource((response: IAPIResponse) => {
      if (response.error === null && response.payload !== null) {
        setDocSources(response.payload);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    LoadItems();
  }, []);

  const buttons = [];

  if (newItem) {
    buttons.push({
      onClick: () => {
        setShowEdit(false);
        ApiService.DocumentSourcesController.setDocumentSource(editItem, (response: IAPIResponse) => {
          if (response.error == null) {
            LoadItems();
          }
        });
      },
      label: 'Save Item',
    });
  } else {
    buttons.push({
      onClick: () => {
        setShowEdit(false);
        ApiService.DocumentSourcesController.patchDocumentSource(editItem, (response: IAPIResponse) => {
          if (response.error == null) {
            LoadItems();
          }
        });
      },
      label: 'Update Item',
    });
  }

  buttons.push({
    onClick: () => {
      setShowEdit(false);
    },
    label: 'Cancel',
  });

  //** add delete button for existing records */
  if (editItem) {
    if (editItem.id !== '') {
      buttons.push({
        onClick: () => {
          setShowEdit(false);
          ApiService.DocumentSourcesController.deleteDocumentSource(editItem, (response: IAPIResponse) => {
            if (response.error == null) {
              LoadItems();
            }
          });
        },
        label: 'Delete Item',
      });
    }
  }

  return (
    <div>
      <div className="admin-document-sources-page-title">Document Sources</div>
      <ModalDialog
        modalTitle={newItem ? 'Add Document Source' : 'Edit Document Source'}
        modalInnerComponent={
          <div>
            {editItem && (
              <div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    label={'Name'}
                    value={editItem.name}
                    onChange={(e: any) => {
                      let it = {...editItem};
                      it['name'] = e.target.value;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div style={{marginTop: '1em'}}>
                  <Toggle
                    defaultChecked={editItem.authorsAreRequired}
                    label={'Authors Required'}
                    onChange={(e: any, toggle: boolean) => {
                      let it = {...editItem};
                      it['authorsAreRequired'] = toggle;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    label={'Source Order'}
                    value={editItem.order.toString()}
                    type="number"
                    onChange={(e: any) => {
                      let number = e.target.value.replace(/\D/g, '');
                      if (number !== '') {
                        let it = {...editItem};
                        it['order'] = +number;
                        setEditItem(it);
                      }
                    }}
                  />
                  <div
                    style={{
                      fontSize: '0.8em',
                      color: 'var(--eni-darkText-secondary)',
                    }}>
                    This input will need to be numerical only.
                  </div>
                </div>
              </div>
            )}
          </div>
        }
        modalButtons={buttons.reverse()}
        onAbort={() => {
          setShowEdit(false);
        }}
        enableModal={showEdit}
      />
      {loading && (
        <div className="admin-document-sources-page-spinner-wrap-main">
          <div className="admin-document-sources-page-spinner-inner">
            <Spinner label="Loading..." />
          </div>
        </div>
      )}
      {!loading && (
        <GenericList
          extraHeaderItems={[
            <LabelButton
              text="Add new"
              icon="Add"
              onClick={() => {
                setNewItem(true);
                setEditItem({
                  id: '',
                  name: '',
                  order: 0,
                  authorsAreRequired: false,
                });
                setShowEdit(true);
              }}
            />,
          ]}
          onItemInvoked={(item: any) => {
            // setEditItem({...item}) is not working for some reason
            setNewItem(false);
            setEditItem(JSON.parse(JSON.stringify(item)));
            setShowEdit(true);
          }}
          allowFilter={true}
          searchText={'Search'}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'name',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Authors Required',
              dataType: 'string',
              fieldName: 'order',
              iconName: null,
              onRender: (item: DocumentSource) => {
                return <div>{item.authorsAreRequired ? 'True' : 'False'}</div>;
              },
              size: 'medium',
            },
            {
              name: 'Source Order',
              dataType: 'number',
              fieldName: 'order',
              iconName: null,
              size: 'medium',
            },
          ]}
          items={docSources}
        />
      )}
    </div>
  );
};

export default AdminDocumentSources;
