/**
 * Removes the eventual prefixes from a BO's id
 *
 * E.g. "perm|101|bamba" -> "bamba"
 * @param boId The Id of the bo
 */
export function normalizeBoId(boId: string) {
  return boId?.split('|').pop() ?? '';
}

/**
 * Convert special characters
 *
 * E.g. "Dungeons &amp; Dragons" -> "Dungeons & Dragons"
 * */
export function decodeXML(s: string) {
  return s
    ?.replace(/&apos;/g, "'")
    .replace(/&quot;/g, '"')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&amp;/g, '&');
}

/**
 * E.g. "DuNgEons and DraGoNs" -> "Dungeons And Dragons"
 * */
export function toTitleCase(phrase?: string): string | undefined {
  return phrase
    ?.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function hashCode(str) {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

export function randomColorFromString(s?: string): string {
  if (s) {
    return intToRGB(hashCode(s));
  } else {
    return '#000000';
  }
}

export function getFileExtension(fileName?: string): string {
  return fileName?.toLocaleLowerCase().split('.').pop() ?? "";
}
