import {GENERIC_URLS} from '../../Constants/Urls';
import {InputInfo} from '../../Models/InputInfo';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const TagsController = {
  getTags: async (search: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${GENERIC_URLS.TAGS}?search=${search}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  addTag: async (tag: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${GENERIC_URLS.TAGS}?tag=${tag}`;
    request.method = 'POST';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default TagsController;
