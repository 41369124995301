import {useEffect, useState} from 'react';
import pdfTypeSubtypeFile from '../../assets/pdf_type_subtype.pdf';

export enum AnchorToFileType {
  pdfTypeSubtype,
}

interface AnchorToFileSpec {
  fileLink: string;
  fileName: string;
  displayName: string;
}

export const getAnchorToFileTypeSpec = (anchorToFileType: AnchorToFileType): AnchorToFileSpec => {
  switch (anchorToFileType) {
    case AnchorToFileType.pdfTypeSubtype:
      return {fileLink: pdfTypeSubtypeFile, fileName: 'pdf_type_subtype.pdf', displayName: 'Document Type'};
  }
};

interface AnchorToFileProps {
  file: AnchorToFileSpec;
}

export const AnchorToFile = (props: AnchorToFileProps) => {
  const [fileLinkBlob, setFileLinkBlob] = useState<string>();

  useEffect(() => {
    const convertLinkToBlob = async () => {
      const response: Response = await fetch(props.file.fileLink);
      const blob: Blob = await response.blob();
      const blobUrl: string = window.URL.createObjectURL(blob);
      setFileLinkBlob(blobUrl);
    };
    convertLinkToBlob();
  }, []);

  return (
    <a href={fileLinkBlob} download={props.file.fileName} target="_blank">
      {props.file.displayName}
    </a>
  );
};
