import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileInfo } from '../../Models/FileUtils';
import { ILibrary } from '../../Models/ILibrary';
import { ITeamItem } from '../../Models/ITeam';
import { NewDocumentAction } from '../../Reducers/NewDocument/NewDocumentActions';
import { GlobalState } from '../../Reducers/RootReducer';
import { IAPIResponse } from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import Icons from '../../Utils/FabricIconsOutlet';
import { FilePickerFromTeamsOrLocal } from '../FilePickerFromTeamsOrLocal/FilePickerFromTeamsOrLocal';
import { FileSelectAreaProps } from '../FileSelectArea/FileSelectArea';

interface MainDocumentContainerProps {
  teamsFilePicker: boolean;
  uploadDisabled?: boolean;
}

export type IErrorTypes = 'generic' | 'not-supported-format' | 'max-one-file' | 'file-too-big' | '';

export const getLabelsErrorHandling = (errorType: IErrorTypes): FileSelectAreaProps => {
  switch (errorType) {
    case 'generic': {
      return {
        buttonIcon: <div style={{color: '#a4262c'}}>{Icons.getIcon('SyncToPC', '2em')}</div>,
        dropAreaTitle: (
          <div style={{color: '#a4262c'}}>
            Ops! <strong>There was an issue uploading.</strong>
          </div>
        ),
        buttonLabel: 'Upload file again',
      };
    }
    case 'not-supported-format': {
      return {
        buttonIcon: <div style={{color: '#a4262c'}}>{Icons.getIcon('StatusErrorFull', '2em')}</div>,
        dropAreaTitle: (
          <div style={{color: '#a4262c'}}>
            Ouch! <strong>Format not supported!</strong>
          </div>
        ),
        buttonLabel: 'Upload another file',
      };
    }
    case 'max-one-file': {
      return {
        buttonIcon: <div style={{color: '#a4262c'}}>{Icons.getIcon('StatusErrorFull', '2em')}</div>,
        dropAreaTitle: <div style={{color: '#a4262c'}}>You can select at most one file</div>,
        buttonLabel: 'Upload another file',
      };
    }
    case 'file-too-big': {
      return {
        buttonIcon: <div style={{color: '#a4262c'}}>{Icons.getIcon('StatusErrorFull', '2em')}</div>,
        dropAreaTitle: <div style={{color: '#a4262c'}}>The file's size has to be less than 4GB</div>,
        buttonLabel: 'Upload another file'
      };
    }
    case '': {
    }
  }
};

export const MainDocumentContainer = (props: MainDocumentContainerProps) => {
  const [errorType, setErrorType] = useState<IErrorTypes>('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const documentId = useSelector<GlobalState, string>((state) => state.newDocument.document.documentId);
  const library = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);

  const getLabels = (): FileSelectAreaProps => {
    if (errorType === '') {
      if (props.teamsFilePicker) {
        return {
          buttonIcon: Icons.getIcon('CloudUpload', '2em'),
          dropAreaTitle: (
            <>
              Select the <b>main document</b> from teams.
            </>
          ),
          buttonLabel: 'Choose main document',
        };
      } else {
        return {
          buttonIcon: Icons.getIcon('CloudUpload', '2em'),
          dropAreaTitle: (
            <>
              Upload the <strong>main document</strong> here or select it.
            </>
          ),
          buttonLabel: 'Choose main document',
        };
      }
    } else {
      return getLabelsErrorHandling(errorType);
    }
  };

  const onDropAndClickLocalFiles = (files: File[], unzipFiles?: boolean) => {
    setErrorType('');
    setLoading(true);

    if (files && files.length > 1) {
      setLoading(false);
      setErrorType('max-one-file');
    }
    // Max file size = 4Gi (w\out this filter, the Azure Gateway would return a network error)
    else if ( files[0].size >= 4294967296 ) {
      setLoading(false);
      setErrorType('file-too-big');
    }
    else {
      ApiService.DocumentController.uploadMainDocument(documentId, files[0], library?.id, (response: IAPIResponse) => {
        setLoading(false);
        if (response.error == null) {
          const newFile = response.payload as FileInfo;
          dispatch(NewDocumentAction.setMainFile(newFile));
        }
        else {
          if (response.payload && response.payload.status === 400 && response.payload.title === 'Invalid File Type') {
            setErrorType('not-supported-format');
          }
          else {
            setErrorType('generic');
          }
        }
      });
    }
  };

  const onTeamsFileSelection = (files: ITeamItem[]) => {
    setErrorType('');
    setLoading(true);
    if (files.length > 1) {
      setErrorType('max-one-file');
    } else if (files.length === 1) {
      const file = files[0];
      ApiService.DocumentController.uploadMainDocumentFromTeams(
        documentId,
        file.driveId,
        file.parentFolderID,
        file.itemId,
        library?.id,
        (response: IAPIResponse) => {
          setLoading(false);
          if (response.error == null) {
            let newFile = response.payload as FileInfo;
            dispatch(NewDocumentAction.setMainFile(newFile));
          }
          else {
            if (response.payload && response.payload.status === 400 && response.payload.title === 'Invalid File Type') {
              setErrorType('not-supported-format');
            }
            else {
              setErrorType('generic');
            }
          }
        }
      );
    }
  };

  const fileSelectAreaProps = getLabels();

  return (
    <>
      <FilePickerFromTeamsOrLocal
        loading={loading}
        onDropAndClickLocalFiles={onDropAndClickLocalFiles}
        onTeamsFileSelection={onTeamsFileSelection}
        fileSelectAreaProps={fileSelectAreaProps}
        teamsFilePicker={props.teamsFilePicker}
        uploadDisabled={props.uploadDisabled}
        displayLoadingOverlay={true}
      />
    </>
  );
};
