import {
  AdminActionTypes,
  SET_SELECTED_LIBRARIES,
  SET_SELECTED_NEWS,
  SET_SHOW_NEWS_ADD_MODAL,
  SET_SHOW_NEWS_EDIT_MODAL,
  SET_SHOW_NEWS_DELETE_MODAL,
  SET_ADD_NEW_ITEM,
  SET_EDIT_NEW_ITEM,
  SET_UNSELECET_ALL_NEWS,
  SET_ADMIN_COUNTRIES,
} from './AdminActions';
import {AdminState, DEFAULT_ADMIN_STATE} from './AdminState';

export const adminReducer = (state: AdminState = DEFAULT_ADMIN_STATE, action: AdminActionTypes): AdminState => {
  switch (action.type) {
    case SET_SELECTED_LIBRARIES:
      return {
        ...state,
        libraryTab: {
          ...state.libraryTab,
          selectedLibrary: action.payload,
        },
      };
    case SET_SELECTED_NEWS:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          selectedNews: action.payload,
        },
      };
    case SET_SHOW_NEWS_ADD_MODAL:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          showAddModal: action.payload,
        },
      };
    case SET_SHOW_NEWS_EDIT_MODAL:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          showEditModal: action.payload,
        },
      };
    case SET_SHOW_NEWS_DELETE_MODAL:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          showDeleteModal: action.payload,
        },
      };
    case SET_ADD_NEW_ITEM:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          addNewItem: action.payload,
        },
      };
    case SET_EDIT_NEW_ITEM:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          editNewItem: action.payload,
        },
      };
    case SET_UNSELECET_ALL_NEWS:
      return {
        ...state,
        newsTab: {
          ...state.newsTab,
          unselectAllNews: action.payload,
        },
      };
    case SET_ADMIN_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    default:
      return state;
  }
};
