import {GENERIC_URLS} from '../../Constants/Urls';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const GlobalVariablesController = {
  getAppVersion: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.GLOBAL_VARIABLES_GET_APP_VERSION;
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getAbsoluteAutoIncrement: async (key: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.GLOBAL_VARIABLES_AUTO_INCREMENT + '?key=' + key;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getAbsoluteAutoIncrementPreview: async (key: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.GLOBAL_VARIABLES_AUTO_INCREMENT_PREVIEW + '?key=' + key;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getGlobalVar: async (key: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.GLOBAL_VARIABLES + '?key=' + key;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setGlobalVar: async (key: string, value: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.GLOBAL_VARIABLES + '?key=' + key + '&value=' + value;
    request.method = 'post';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default GlobalVariablesController;
