import './VideoTutorialCard.scss';
import {IVideoTutorial} from '../../Models/IVideoTutorial';
import {APISettings} from '../../Services/AjaxService';
import {VIDEO_TUTORIAL_URLS} from '../../Constants/Urls';
import AppRoutes from '../../Utils/AppRoutes';

interface VideoTutorialCard {
  videoTutorial: IVideoTutorial;
}

const VideoTutorialCard = (props: VideoTutorialCard) => {
  return (
    <div className="tutorial-box-wrap">
      <div className="tutorial-box-image">
        <img
          className="tutorial-image"
          src={`${APISettings.baseUrl}${VIDEO_TUTORIAL_URLS.GET_VIDEO_THUMBNAIL}/${props.videoTutorial.thumbnailName}`}
          alt={props.videoTutorial.title}
        />
      </div>
      <div className="tutorial-box-padded-content">
        <div className="tutorial-box-title">{props.videoTutorial.title}</div>
        <div className="tutorial-box-subtitle">{props.videoTutorial.description.slice(0, 100)}</div>
      </div>
      <a className="tutorial-box-read" href={`${AppRoutes.VIDEOS_TUTORIAL}/video/${props.videoTutorial.fileName}`} target="_blank">
        Play
      </a>
    </div>
  );
};

export default VideoTutorialCard;
