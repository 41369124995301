import {ITag, TagPicker} from '@fluentui/react';
import {ITagPickerProps} from '@fluentui/react/lib/components/pickers/TagPicker/TagPicker.types';

interface TagPickerCustomProps extends ITagPickerProps {
  selectedItems: ITag[];
  onAdd: (selectedItems: ITag[], addedItem: ITag) => any;
  onRemove: (selectedItems: ITag[], removedItem: ITag) => any;
}

const TagPickerCustom = (props: TagPickerCustomProps) => {
  const getRemovedElement = (values: ITag[], newValues: ITag[]): ITag => {
    if (values.length > newValues.length) {
      const newValuesKeys = newValues.map((x) => x.key.toString());
      return values.filter((x) => !newValuesKeys.includes(x.key.toString()))[0];
    }
  };

  return (
    <TagPicker
      {...props}
      onChange={(items?: ITag[]) => {
        if (items?.length > props.selectedItems.length) {
          props.onAdd(items, items[items.length - 1]);
        } else {
          const removedElement = getRemovedElement(props.selectedItems, items);
          props.onRemove(items, removedElement);
        }

        if (props.onChange) {
          props.onChange(items);
        }
      }}
    />
  );
};

export default TagPickerCustom;
