export function getSignTypeString(signType: SignType): string {
  switch (signType) {
    case SignType.FirmaNonQualificata:
      return 'Firma non qualificata';
    case SignType.Inforcert:
      return 'INFOCERT';
  }
}

export enum SignType {
  FirmaNonQualificata = 0,
  Inforcert = 1,
}

export interface IWorkflowUser {
  userId: string;
  name: string;
  userSignStatus: WorkflowSignatureAction;
  signType: SignType;
}

export interface IWorkflowSigner extends IWorkflowUser {
  delegates: IWorkflowUser[];
}

export interface IWorkflowSignStep {
  order: number;
  users: IWorkflowSigner[];
}

export interface IWorkflowItem {
  workflowId: string;
  documentId: string;
  documentName: string;
  mainDocumentName: string;
  newVersionOf: string;
  createdBy: IWorkflowSigner;
  levelOfCompletion: number;
  status: WorkflowStatus;
  userSignStatus?: number;
  creationDate: string;
  signers: IWorkflowSignStep[];
  type: number;
  signatureType: number;
  documentWorkflowStatus: number;
}

export enum WorkflowStatus {
  Active = 1,
  Finished = 2,
  Blocked = 3,
  Deleted = 4, // when workflow is active but the associated file has been deleted
  DeletedByUser = 5,
}

export enum WorkflowSignatureAction {
  notSigned = 0,
  signed = 1,
  rejected = 2,
}

export interface IWorkflowSignatureAction {
  workflowId: string;
  userSignStatus: number;
  comment?: string;
  certificateType?: string;
}
