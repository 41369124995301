import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {getTrackingComponent} from '../../AppInsights';
import AdminTrashBin from './AdminTrashBin/AdminTrashBin';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import InputInfoList from './InputInfoList/InputInfoList';
import AdminNewsList from './AdminNewsList/AdminNewsList';
import UsersList from './UsersList/UsersList';
import WorkflowEditor from './WorkflowEditor/WorkflowEditor';
import {GlobalState} from '../../Reducers/RootReducer';
import AppRoutes, {concatUrl} from '../../Utils/AppRoutes';
import {setPageContentState} from '../FullPageMessage/FullPageMessage';
import './AdminPage.scss';
import PivotFluentWrap from '../../Components/PivotFluentWrap/PivotFluentWrap';
import SysLogsList from './SysLogsList/SysLogsList';
import AdminDocumentTypes from './AdminDocumentTypes/AdminDocumentTypes';
import AdminDocumentSources from './AdminDocumentSources/AdminDocumentSources';
import PermissionMatrixAdminSection from './PermissionMatrixAdminSection/PermissionMatrixAdminSection';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import AdminLibrary from './AdminLibrary/AdminLibrary';
import {AdminAction} from '../../Reducers/Admin/AdminActions';

const AdminPage = () => {
  const navigate = useNavigate();

  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'AdminPage'}
  );

  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);
  const generic = useSelector((state: GlobalState) => state.generic);
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);
  const adminReduxState = useSelector((state: GlobalState) => state.admin);
  const dispatch = useDispatch();

  const [openTrashBinCommands, setOpenTrashBinCommands] = useState<boolean>(false);

  const openTrashBinCommandsCallback = () => {
    setOpenTrashBinCommands(true);
  };

  useEffect(() => {
    setOpenTrashBinCommands(false);
  }, [generic.reloadRequest, window.location.href]);

  useEffect(() => {
    if (!permissions?.canAccessAdminArea) {
      /** define with this call what will be visible in the MESSAGE_PAGE */
      setPageContentState({
        title: "You can't stay here.",
        subtitle: "You don't have the permissions to use the Admin page.",
        imageName: 'accessDeniedPage',
        button: {
          icon: 'ChromeBackMirrored',
          text: 'Go back to Drive',
          onClick: {
            type: 'navigate',
            arguments: AppRoutes.HOME_ROUTE,
          },
        },
      });

      /** goto the MESSAGE_PAGE. this must happen after calling setPageContentState */
      navigate(AppRoutes.MESSAGE_PAGE);
    } else {
      window.document.addEventListener('adminTrashBinDidSelect', openTrashBinCommandsCallback);
      return () => {
        window.document.removeEventListener('adminTrashBinDidSelect', openTrashBinCommandsCallback);
      };
    }
  }, []);

  const tabs = [
    {
      label: 'Users',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_USERS_LIST));
      },
    },
    {
      label: 'Permissions Matrix',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_DOC_TYPE_SUBTYPE));
      },
    },
    {
      label: 'Trash Bin',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_TRASH));
      },
    },
    {
      label: 'Workflows',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_WORKFLOW));
      },
    },
    {
      label: 'Input Information',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_INPUT_INFO));
      },
    },
    {
      label: 'Document Formats',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_DOC_TYPES));
      },
    },
    {
      label: 'Document Sources',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_DOCUMENT_SOURCES));
      },
    },
    {
      label: 'News',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_NEWS));
      },
    },
  ];
  if (process.env.APP_ENV_NAME !== 'Production') {
    // TODO remove to deploy
    tabs.push({
      label: 'Library',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_LIBRARY));
      },
    });
  }

  if (loggedUser.isSuperAdmin) {
    tabs.push({
      label: 'System logs',
      onClick: () => {
        resetToolbar();
        navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_SYS_LOGS));
      },
    });
  }

  const resetToolbar = () => {
    dispatch(AdminAction.setSelectedLibraries(undefined));
    dispatch(AdminAction.setSelectedNews([]));
  };

  const getToolbarType = (): string => {
    if (openTrashBinCommands) {
      return 'actions-on-trash-document-admin';
    }

    if (adminReduxState.libraryTab.selectedLibrary) {
      return 'admin-page-actions-on-library';
    }

    if (adminReduxState.newsTab.selectedNews.length == 1) {
      return 'admin-page-one-new-selected';
    }
    if (adminReduxState.newsTab.selectedNews.length > 1) {
      return 'admin-page-many-news-selected';
    }

    return 'navigation-voices';
  };

  return (
    <div className="admin-page-container">
      <DriveDocumentManagementToolbar type={getToolbarType()} />
      <div className="admin-page-main-wrap">
        <div className="admin-page-title">
          Admin Section
          <VideoTutorialIcon />
        </div>

        <div>
          <div className="admin-page-buttons-tabs-wrap">
            <PivotFluentWrap
              onInitProcedure={() => {
                if (window.location.href.indexOf(AppRoutes.ADMIN_ROUTE_DOC_TYPE_SUBTYPE) !== -1) {
                  return '1';
                }

                if (window.location.href.indexOf(AppRoutes.ADMIN_ROUTE_TRASH) !== -1) {
                  return '2';
                }

                if (window.location.href.indexOf(AppRoutes.ADMIN_ROUTE_WORKFLOW) !== -1) {
                  return '3';
                }

                if (window.location.href.indexOf(AppRoutes.ADMIN_ROUTE_INPUT_INFO) !== -1) {
                  return '4';
                }

                if (window.location.href.indexOf(AppRoutes.ADMIN_ROUTE_NEWS) !== -1) {
                  return '7';
                }

                if (window.location.href.indexOf(AppRoutes.ADMIN_ROUTE_LIBRARY) !== -1) {
                  return '8';
                }

                setTimeout(() => {
                  navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_USERS_LIST));
                }, 100);
                return '0';
              }}
              tabs={tabs}
            />
          </div>
          <Routes>
            <Route path={AppRoutes.ADMIN_ROUTE_USERS_LIST} element={<UsersList />} />
            <Route path={AppRoutes.ADMIN_ROUTE_DOC_TYPE_SUBTYPE} element={<PermissionMatrixAdminSection />} />
            <Route path={AppRoutes.ADMIN_ROUTE_DOCUMENT_SOURCES} element={<AdminDocumentSources />} />
            <Route path={AppRoutes.ADMIN_SYS_LOGS} element={<SysLogsList />} />
            <Route path={AppRoutes.ADMIN_ROUTE_INPUT_INFO} element={<InputInfoList />} />
            <Route path={AppRoutes.ADMIN_ROUTE_TRASH} element={<AdminTrashBin />} />
            <Route path={AppRoutes.ADMIN_ROUTE_WORKFLOW} element={<WorkflowEditor />} />
            <Route path={AppRoutes.ADMIN_ROUTE_DOC_TYPES} element={<AdminDocumentTypes />} />
            <Route path={AppRoutes.ADMIN_ROUTE_NEWS} element={<AdminNewsList />} />
            <Route path={AppRoutes.ADMIN_ROUTE_LIBRARY} element={<AdminLibrary />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default getTrackingComponent(AdminPage, 'AdminPage');
