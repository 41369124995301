import {IDocumentItem, IDocumentQueryDocument} from '../../Models/IDocument';

export const SET_ACTIVE_DOCUMENT_ACTION = 'SET_ACTIVE_DOCUMENT_ACTION';
export const SET_ACTIVE_DOCUMENTS_ACTION = 'SET_ACTIVE_DOCUMENTS_ACTION';
export const SET_DOCUMENTS_ACTION = 'SET_DOCUMENTS_ACTION';
export const SET_FAVORITE_DOCUMENTS_ACTION = 'SET_FAVORITE_DOCUMENTS_ACTION';
export const SET_SEARCH_LIMIT = 'SET_SEARCH_LIMIT';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const SET_CURRENT_SEARCH_MAX_RESULTS = 'SET_CURRENT_SEARCH_MAX_RESULTS';
export const SET_ACTIVE_SEARCH_RESULTS = 'SET_ACTIVE_SEARCH_RESULT';

interface SetActiveSearchResultsActionType {
  type: typeof SET_ACTIVE_SEARCH_RESULTS;
  payload: IDocumentQueryDocument[];
}

interface SetCurrentSearchMaxResultsActionType {
  type: typeof SET_CURRENT_SEARCH_MAX_RESULTS;
  payload: number;
}

interface SetScrollPositionActionType {
  type: typeof SET_SCROLL_POSITION;
  payload?: number;
}

interface SetSearchLimitActionType {
  type: typeof SET_SEARCH_LIMIT;
  payload: number;
}
interface SetActiveDocumentsActionType {
  type: typeof SET_ACTIVE_DOCUMENTS_ACTION;
  payload: IDocumentItem[];
}
interface SetActiveDocumentActionType {
  type: typeof SET_ACTIVE_DOCUMENT_ACTION;
  payload: IDocumentItem | null;
}
interface SetDocumentsActionType {
  type: typeof SET_DOCUMENTS_ACTION;
  payload: IDocumentItem[];
}
interface SetFavoriteDocumentsActionType {
  type: typeof SET_FAVORITE_DOCUMENTS_ACTION;
  payload: IDocumentItem[];
}

export type DocumentSearchActionTypes =
  | SetActiveDocumentActionType
  | SetActiveDocumentsActionType
  | SetFavoriteDocumentsActionType
  | SetSearchLimitActionType
  | SetDocumentsActionType
  | SetScrollPositionActionType
  | SetCurrentSearchMaxResultsActionType
  | SetActiveSearchResultsActionType;

const setActiveSearchResults = (results: IDocumentQueryDocument[]): SetActiveSearchResultsActionType => {
  return {type: SET_ACTIVE_SEARCH_RESULTS, payload: results};
};

const setCurrentSearchMaxResults = (maxResults: number): SetCurrentSearchMaxResultsActionType => {
  return {type: SET_CURRENT_SEARCH_MAX_RESULTS, payload: maxResults};
};

const setScrollPosition = (scrollPosition?: number): SetScrollPositionActionType => {
  return {type: SET_SCROLL_POSITION, payload: scrollPosition};
};

const setSearchLimit = (limit: number): SetSearchLimitActionType => {
  return {type: SET_SEARCH_LIMIT, payload: limit};
};

const setActiveDocument = (document: IDocumentItem | null): SetActiveDocumentActionType => {
  return {type: SET_ACTIVE_DOCUMENT_ACTION, payload: document};
};

const setActiveDocuments = (documents: IDocumentItem[]): SetActiveDocumentsActionType => {
  return {type: SET_ACTIVE_DOCUMENTS_ACTION, payload: documents};
};

const setDocuments = (documents: IDocumentItem[]): SetDocumentsActionType => {
  return {type: SET_DOCUMENTS_ACTION, payload: documents};
};

const setFavoriteDocuments = (documents: IDocumentItem[]): SetFavoriteDocumentsActionType => {
  return {type: SET_FAVORITE_DOCUMENTS_ACTION, payload: documents};
};

export const DocumentListAction = {
  setDocuments,
  setActiveDocument,
  setActiveDocuments,
  setFavoriteDocuments,
  setSearchLimit,
  setScrollPosition,
  setCurrentSearchMaxResults,
  setActiveSearchResults,
};
