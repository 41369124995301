import {useEffect, useState} from 'react';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import {ITag, IconButton} from '@fluentui/react';
import ApiService from '../../Services/ApiService';
import {IValue} from '../../Models/IValue';
import {IAdvancedSearchExpAssuranceResponseItem} from '../../Services/Controllers/AdvancedSearchExpAssuranceController';
import styles from './ExpAssuranceCascadeBoPicker.module.css';
import {BOsObject} from '../../Models/BusinessObject';
import TagPickerCustom from '../TagPickerCustom/TagPickerCustom';
import {ModalDialog} from '@Eni/docware-fe-master';

interface ITagCustom extends ITag {
  nameWithoutCount: string;
}

function ITagCustomToIValue(i: ITagCustom): IValue {
  return {id: i.key.toString(), name: i.nameWithoutCount};
}

function IValueToITagCustom(i: IValue): ITagCustom {
  return {key: i.id, name: i.name, nameWithoutCount: i.name};
}

export const EXPASSURANCE_CASCADE_BOS_INTEGRATION_KEYS = [
  'xware_concessions',
  'generic_prospects',
  'generic_wells',
  'expbmt_newventureprojects',
];

interface ExpAssuranceCascadeBoPickerProps {
  countries: string[];
  bosState?: BOsObject;
  onChange: (bos: BOsObject) => void;
}

const ExpAssuranceCascadeBoPicker = (props: ExpAssuranceCascadeBoPickerProps) => {
  /**
   * The state is managed by the parent component to prevent state duplication. These costants are helpers
   */
  const lease = props.bosState?.xware_concessions?.map(IValueToITagCustom) ?? [];
  const newVentureProject = props.bosState?.expbmt_newventureprojects?.map(IValueToITagCustom) ?? [];
  const prospect = props.bosState?.generic_prospects?.map(IValueToITagCustom) ?? [];
  const well = props.bosState?.generic_wells?.map(IValueToITagCustom) ?? [];
  const setFormState = (input: {lease?: ITagCustom[]; newVentureProject?: ITagCustom[]; prospect?: ITagCustom[]; well?: ITagCustom[]}) => {
    const bos = Object.assign(
      {},
      input.lease && {xware_concessions: input.lease.map(ITagCustomToIValue)},
      input.newVentureProject && {expbmt_newventureprojects: input.newVentureProject.map(ITagCustomToIValue)},
      input.prospect && {generic_prospects: input.prospect.map(ITagCustomToIValue)},
      input.well && {generic_wells: input.well.map(ITagCustomToIValue)}
    );
    props.onChange(bos);
  };
  /**
   * Variables used by the delete modal
   */
  const [deleteModalEnabled, setDeleteModalEnabled] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [leaseOnModalAccept, setLeaseOnModalAccept] = useState<ITagCustom[]>([]);
  const [newVentureProjectOnModalAccept, setNewVentureProjectOnModalAccept] = useState<ITagCustom[]>([]);
  const [prospectOnModalAccept, setProspectOnModalAccept] = useState<ITagCustom[]>([]);
  const [wellOnModalAccept, setWellOnModalAccept] = useState<ITagCustom[]>([]);

  async function resolveSuggestions(
    filter: string,
    suggestionType: 'LEASE' | 'NEWVENTUREPROJECT' | 'PROSPECT' | 'WELL'
  ): Promise<ITagCustom[]> {
    const requestBody = {
      actualData: {
        xware_concessions: lease.map(ITagCustomToIValue),
        expbmt_newventureprojects: newVentureProject.map(ITagCustomToIValue),
        expbmt_prospects: prospect.map(ITagCustomToIValue),
        expbmt_well_prospect: well.map(ITagCustomToIValue),
      },
      searchText: filter,
      countriesId: props.countries,
    };

    let response;
    switch (suggestionType) {
      case 'LEASE':
        response = await ApiService.AdvancedSearchExpAssuranceController.getLeases(requestBody);
        break;
      case 'NEWVENTUREPROJECT':
        response = await ApiService.AdvancedSearchExpAssuranceController.getNewVentureProject(requestBody);
        break;
      case 'PROSPECT':
        response = await ApiService.AdvancedSearchExpAssuranceController.getProspects(requestBody);
        break;
      case 'WELL':
        response = await ApiService.AdvancedSearchExpAssuranceController.getWells(requestBody);
        break;
      default:
        throw new Error('Not implemented');
    }

    if (response.error == null) {
      const data = response.payload as IAdvancedSearchExpAssuranceResponseItem[];
      return data.map((x) => ({key: x.id, name: `${x.name} (${x.documentsCount})`, nameWithoutCount: x.name}));
    }
  }

  const leaseandNewVentureProjectDisabled = lease.length > 0 || newVentureProject.length > 0 || prospect.length > 0 || well.length > 0;
  const prospectDisabled = well.length > 0;

  function resetOnModalAccept() {
    setLeaseOnModalAccept([]);
    setNewVentureProjectOnModalAccept([]);
    setProspectOnModalAccept([]);
    setWellOnModalAccept([]);
  }

  function syncOnModalAccept() {
    setFormState({
      lease: leaseOnModalAccept,
      newVentureProject: newVentureProjectOnModalAccept,
      prospect: prospectOnModalAccept,
      well: wellOnModalAccept,
    });
  }

  return (
    <>
      <ModalDialog
        enableModal={deleteModalEnabled}
        modalTitle={modalTitle}
        onAbort={() => {
          resetOnModalAccept();
          setDeleteModalEnabled(false);
        }}
        onAccept={() => {
          syncOnModalAccept();
          resetOnModalAccept();
          setDeleteModalEnabled(false);
        }}
      />
      <div className={styles.doubleRowContainer}>
        <div>
          <FluentUIDecorator
            className=""
            label={'Business Object Lease'}
            info={getInputInfos('documents-search', 'bo-exp-assurcance-lease')}
            required={false}
            fluentComponent={null}
            directComponentInjection={
              <TagPickerCustom
                className={styles.tagpicker}
                disabled={leaseandNewVentureProjectDisabled}
                selectedItems={lease}
                onResolveSuggestions={async (filter): Promise<ITagCustom[]> => {
                  return resolveSuggestions(filter, 'LEASE');
                }}
                onEmptyResolveSuggestions={async (): Promise<ITagCustom[]> => {
                  return resolveSuggestions('', 'LEASE');
                }}
                onAdd={(items: ITagCustom[]) => {
                  setFormState({
                    lease: items,
                  });
                }}
                onRemove={(items: ITagCustom[]) => {
                  setFormState({
                    lease: items,
                  });
                }}
              />
            }
          />
          <IconButton
            iconProps={{iconName: 'Delete'}}
            onClick={() => {
              setModalTitle('Removing Lease selection Prospect/Well filters will be reset.');
              setLeaseOnModalAccept([]);
              setNewVentureProjectOnModalAccept([]);
              setProspectOnModalAccept([]);
              setWellOnModalAccept([]);
              setDeleteModalEnabled(true);
            }}
          />
        </div>
        <div>
          <FluentUIDecorator
            className=""
            label={'Business Object NV Project'}
            info={getInputInfos('documents-search', 'bo-exp-assurcance-new-venture-project')}
            required={false}
            fluentComponent={null}
            directComponentInjection={
              <TagPickerCustom
                className={styles.tagpicker}
                disabled={leaseandNewVentureProjectDisabled}
                selectedItems={newVentureProject}
                onResolveSuggestions={async (filter): Promise<ITagCustom[]> => {
                  return resolveSuggestions(filter, 'NEWVENTUREPROJECT');
                }}
                onEmptyResolveSuggestions={async (): Promise<ITagCustom[]> => {
                  return resolveSuggestions('', 'NEWVENTUREPROJECT');
                }}
                onAdd={(items: ITagCustom[]) => {
                  setFormState({
                    newVentureProject: items,
                  });
                }}
                onRemove={(items: ITagCustom[]) => {
                  setFormState({
                    newVentureProject: items,
                  });
                }}
              />
            }
          />
          <IconButton
            iconProps={{iconName: 'Delete'}}
            onClick={() => {
              setModalTitle(
                'Removing NV Project selection Prospect/Well filters will be reset.'
              );
              setLeaseOnModalAccept([]);
              setNewVentureProjectOnModalAccept([]);
              setProspectOnModalAccept([]);
              setWellOnModalAccept([]);
              setDeleteModalEnabled(true);
            }}
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <FluentUIDecorator
          className=""
          label={'Business Object Prospect'}
          info={getInputInfos('documents-search', 'bo-exp-assurcance-prospect')}
          required={false}
          fluentComponent={null}
          directComponentInjection={
            <TagPickerCustom
              className={styles.tagpicker}
              disabled={prospectDisabled}
              selectedItems={prospect}
              onResolveSuggestions={async (filter): Promise<ITagCustom[]> => {
                return resolveSuggestions(filter, 'PROSPECT');
              }}
              onEmptyResolveSuggestions={async (): Promise<ITagCustom[]> => {
                return resolveSuggestions('', 'PROSPECT');
              }}
              onAdd={(items: ITagCustom[]) => {
                setFormState({
                  prospect: items,
                });
              }}
              onRemove={(items: ITagCustom[]) => {
                setFormState({
                  prospect: items,
                });
              }}
            />
          }
        />

        <IconButton
          iconProps={{iconName: 'Delete'}}
          onClick={() => {
            setModalTitle('Removing Prospects selection Well filters will be reset.');
            setLeaseOnModalAccept([...lease]);
            setNewVentureProjectOnModalAccept([...newVentureProject]);
            setProspectOnModalAccept([]);
            setWellOnModalAccept([]);
            setDeleteModalEnabled(true);
          }}
        />
      </div>
      <div className={styles.rowContainer}>
        <FluentUIDecorator
          className=""
          label={'Business Object Well'}
          info={getInputInfos('documents-search', 'bo-exp-assurcance-well')}
          required={false}
          fluentComponent={null}
          directComponentInjection={
            <TagPickerCustom
              className={styles.tagpicker}
              disabled={false}
              selectedItems={well}
              onResolveSuggestions={async (filter): Promise<ITagCustom[]> => {
                return resolveSuggestions(filter, 'WELL');
              }}
              onEmptyResolveSuggestions={async (): Promise<ITagCustom[]> => {
                return resolveSuggestions('', 'WELL');
              }}
              onAdd={(items: ITagCustom[]) => {
                setFormState({
                  well: items,
                });
              }}
              onRemove={(items: ITagCustom[]) => {
                setFormState({
                  well: items,
                });
              }}
            />
          }
        />

        <IconButton
          iconProps={{iconName: 'Delete'}}
          onClick={() => {
            setFormState({
              well: [],
            });
          }}
        />
      </div>
    </>
  );
};

export default ExpAssuranceCascadeBoPicker;
