import {TEAMS_INTEGRATION_URLS} from '../../Constants/Urls';
import {AjaxService, APISettings, createEmptyRequest, IAPIRequest, IAPIResponse} from '../AjaxService';
import {IChannel, ITeam} from '../../Models/ITeam';

const TeamsIntegrationsController = {
  getTeams: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.alternative_base_url = APISettings.teamsGraphApiBaseUrl;
    request.url = `${TEAMS_INTEGRATION_URLS.GET_TEAMS}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getChannels: async (teamId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.alternative_base_url = APISettings.teamsGraphApiBaseUrl;
    request.url = `${TEAMS_INTEGRATION_URLS.GET_CHANNELS}?teamId=${teamId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getTeamsWithChannels: async (callback?: (response: IAPIResponse) => void) => {
    let responseTeams: IAPIResponse = await TeamsIntegrationsController.getTeams();
    if (responseTeams.error == null) {
      const teamsWithoutChannels = responseTeams.payload;
      let teamsWithChannels: ITeam[] = [];

      await Promise.all(
        teamsWithoutChannels.map(async (team) => {
          let responseChannel = await TeamsIntegrationsController.getChannels(team.id);
          if (responseChannel.error == null) {
            teamsWithChannels.push({
              id: team.id,
              displayName: team.displayName,
              __channels: responseChannel.payload as IChannel[],
            });
          }
        })
      );
      responseTeams.payload = teamsWithChannels;
    }
    if (callback) {
      callback(responseTeams);
    }
    return responseTeams;
  },
  getTeamsWithChannelsV2: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.alternative_base_url = APISettings.teamsGraphApiBaseUrl;
    request.url = `${TEAMS_INTEGRATION_URLS.GET_TEAMS_WITH_CHANNELS}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getItems: async (driveId: string, itemId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.alternative_base_url = APISettings.teamsGraphApiBaseUrl;
    request.url = `${TEAMS_INTEGRATION_URLS.GET_ITEMS}?itemId=${itemId}&driveId=${driveId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default TeamsIntegrationsController;
