import {useEffect, useState} from 'react';
import {IBOAggregated, ILibrary} from '../../../Models/ILibrary';
import ApiService from '../../../Services/ApiService';
import {useNavigate, useSearchParams} from 'react-router-dom';
import AppRoutes, {concatUrl} from '../../../Utils/AppRoutes';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../../Reducers/RootReducer';
import {Banner, LabelButton, MenuTabs} from '@Eni/docware-fe-master';
import {IValue} from '../../../Models/IValue';
import {NewDocumentAction} from '../../../Reducers/NewDocument/NewDocumentActions';
import {IBoValue, INewDocument} from '../../../Models/IDocument';
import BOsPicker from '../../../Components/BOsPicker/BOsPicker';
import BOsAggregations from '../../../Components/BOsAggregations/BOsAggregations';
import {GenericActions} from '../../../Reducers/Generic/GenericAction';
import {getMasterData} from '../../../Components/MasterDataPicker/MasterDataPicker';
import {getBoNamesFromSources, getRequiredBosList, getSplittedBosUpload, getRequiredMasterDataList, getMasterDataNamesFromSources} from '../../../Utils/NewDocumentUtils';
import TerritorialMasterDataLinkBos from '../../../Components/TerritorialMasterDataLinkBos/TerritorialMasterDataLinkBos';
import {BoUtils} from '../../../Utils/BoUtils';
import { BOsObject } from '../../../Models/BusinessObject';
import Feature from '../../../Components/FeatureFlag/Feature';
import { MASTER_DATA_FOR_SUBTYPE_FF_KEY } from '../../../Constants/FeatureFlags';

export const DocumentBO = () => {
  const [boAggregatedToggles, setBoAggregatedTogglesInner] = useState<{
    [type: string]: boolean;
  }>({});
  const [boValuesAggregated, setBoValuesAggregated] = useState<{
    [type: string]: IValue[];
  }>({});
  const [boValuesAggregatedNonExclusive, setBoValuesAggregatedNonExclusive] = useState<{
    [type: string]: IValue[];
  }>({});
  const libraries = useSelector<GlobalState, ILibrary[]>((state) => state.newDocument.userLibraries);
  const [boValues, setBoValues] = useState<BOsObject>({});
  const [boPickerUpdateBos, setBoPickerUpdateBos] = useState<BOsObject>();
  const selectedLibrary = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);
  const document = useSelector<GlobalState, INewDocument>((state) => state.newDocument.document);
  const [tightBos, setTightBosInner] = useState<string[]>([]);

  const setTightBos = (ls: string[]) => {
    dispatch(GenericActions.setTightErrorBos(ls.length > 0));
    setTightBosInner(ls);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const pageMode = params.get('mode');

  const setBoAggregatedToggles = (source: string, status: boolean) => {
    let newItem: any = {};
    newItem[source] = status;
    setBoAggregatedTogglesInner({...boAggregatedToggles, ...newItem});
  };

  const boAggregationsAreOn = () => {
    return (
      Object.values(boAggregatedToggles).filter((x: boolean) => x).length > 0 ||
      Object.values(boValuesAggregated).filter((x) => (x ? x.length > 0 : false)).length > 0
    );
  };

  useEffect(() => {
    let documentBos = document.bOs ?? {};
    let splitedBos = getSplittedBosUpload(selectedLibrary, documentBos);
    setBoValues(splitedBos.normals);
    setBoValuesAggregated(splitedBos.aggregated);
    setBoValuesAggregatedNonExclusive(splitedBos.aggregatedNONExclusive);
  }, [selectedLibrary?.name]);

  const cleanUpObjectItem = (objectItem: BOsObject) => {
    let keys = Object.keys(objectItem);
    for (let i = 0; i < keys.length; i++) {
      let valueVector: IValue[] = objectItem[keys[i]];
      if (valueVector) {
        if (valueVector.length === 0) {
          delete objectItem[keys[i]];
        }
      } else {
        delete objectItem[keys[i]];
      }
    }
    return objectItem;
  };

  useEffect(() => {
    let cleanBos = cleanUpObjectItem({...boValues});
    let cleanBosAggregated = cleanUpObjectItem({...boValuesAggregated});
    let cleanBosAggregatedNonExclusive = cleanUpObjectItem({...boValuesAggregatedNonExclusive});

    let finalBos = {};

    // exclusive aggregations will override all others bos
    if (Object.keys(cleanBosAggregated).length > 0) {
      finalBos = {...cleanBosAggregated};
    } else {
      finalBos = {...cleanBos, ...cleanBosAggregatedNonExclusive};
    }

    dispatch(NewDocumentAction.setBos(finalBos));
  }, [boValues, boValuesAggregated, boValuesAggregatedNonExclusive]);

  const checkTightBos = async (values: BOsObject) => {
    let results = [];

    let lib = libraries.find((l) => l.name === document.library);
    if (lib.checkTightBOs) {
      let keys = Object.keys(values);

      for (let j = 0; j < keys.length; j++) {
        let key = keys[j];
        let kVals = values[key];
        for (let i = 0; i < kVals.length; i++) {
          let c: IValue = kVals[i];
          let key = 'WELL';
          let res = await ApiService.DocumentController.checkIsTight(c.id, key);
          if (res.error === null && (res.payload === false || (window as any)['force-tight-test'])) {
            results.push(c.name);
          }
        }
      }
    }

    setTightBos(results);
  };

  useEffect(() => {
    checkTightBos(boValues);
  }, [boValues]);

  const goNext = () => {
    if (document != null && document.documentId != null) {
      navigate(concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_SUMMARY) + window.location.search);
    }
  };

  const goBack = () => {
    if (document != null && document.documentId != null) {
      navigate(concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_METADATA) + window.location.search);
    }
  };

  // check required bos and master data are set
  const requiredBos = getRequiredBosList(libraries, document.documentSubTypeId);
  const requiredMasterData = getRequiredMasterDataList(libraries, document.documentSubTypeId);
  const bosKeys = Object.keys(document.bOs ?? {});
  const missingBos = requiredBos.filter(x => !bosKeys.includes(x))
  const missingMasterData = requiredMasterData.filter(x => !bosKeys.includes(x))

  function setBoValuesWithoutChangingMasterData(bos: BOsObject){
    const masterDataIntegrationKeys = selectedLibrary?.masterDatas?.map(x => x.key);
    if(masterDataIntegrationKeys){
      const currentBos = document?.bOs;
      if (currentBos){
        const mastetData: BOsObject = {};
        masterDataIntegrationKeys.forEach(masterData => {
          if(currentBos[masterData]){
            mastetData[masterData] = currentBos[masterData];
          }
        })
        setBoValues({...bos, ...mastetData})
      }
    } else {
      setBoValues(bos);
    }
  }

  function onMasterDataChange(
    firstMasterIntegrationId: string,
    firstMaster: IBoValue[],
    secondMasterIntegrationId: string,
    secondMaster: IBoValue[]
  ): void {
    const bos = {...document.bOs};
    bos[firstMasterIntegrationId] = firstMaster;
    bos[secondMasterIntegrationId] = secondMaster;
    setBoValues(bos);
  }

  async function onClickAddMasterdataLinkedBos(): Promise<void> {
    const territorialIds = document?.bOs["xware_territorial_survey"]?.map(x => x.id) ?? [];
    const response = await ApiService.IntegrationsController.getTerrytorialSurveyLinkedBos(territorialIds);
    if(response.error == null) {
      setBoPickerUpdateBos(response.payload);
    }
  }

  return (
    <div>
      <MenuTabs
        tabs={[
          {
            label: 'Info',
            callback: () => {
              goBack();
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Business Objects',
            callback: () => {},
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Confirm',
            callback: () => {
              goNext();
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={'66%'}
      />
      <div className="metadata-section new-doc-summary-form-container-bos">
        <div>
          <Banner
            type={'warning'}
            message={
              <div>
                You have to select a <strong>Professional Area</strong> before you can add Business Objects.
              </div>
            }
            enabled={selectedLibrary?.bOs == null}
          />
          <Banner
            type={'warning'}
            message={
              <div>
                You have to select a <strong>Country</strong> before you can add a Business Object..
              </div>
            }
            enabled={document?.countries == null || document.countries.length === 0}
          />

          <Banner
            type="warning"
            message={
              <div>
                To save a document <strong>as complete</strong>, you must fill the mandatory Business Object(s):{' '}
                <strong>{getBoNamesFromSources(libraries, missingBos).join(', ')}</strong>
              </div>
            }
            enabled={missingBos.length > 0}
          />
          <Feature featureKey={MASTER_DATA_FOR_SUBTYPE_FF_KEY}>
            <Banner
              type="warning"
              message={
                <div>
                  To save a document <strong>as complete</strong>, you must fill the mandatory Master Data:{' '}
                  <strong>{getMasterDataNamesFromSources(libraries, missingMasterData).join(', ')}</strong>
                </div>
              }
              enabled={missingMasterData.length > 0}
            />
          </Feature>


          {selectedLibrary?.bOs != null && document?.countries?.length > 0 && (
            <div>
              <div className="no-bos-label">Select Business Objects from an aggregated source</div>
              {(selectedLibrary.boAggregations ?? []).map((item: IBOAggregated, i: number) => {
                return (
                  <BOsAggregations
                    initialState={{...boValuesAggregated, ...boValuesAggregatedNonExclusive}}
                    onSelectionChanged={(res: BOsObject) => {
                      if (item.isExclusive) {
                        setBoValuesAggregated(res);
                      } else {
                        setBoValuesAggregatedNonExclusive(res);
                      }
                    }}
                    aggregation={item}
                    countries={document.countries}
                    toggleStatus={setBoAggregatedToggles}
                    key={i}
                  />
                );
              })}
              {!boAggregationsAreOn() && (
                <div>
                  <div className="no-bos-label">Or select Business Objects explicitly</div>
                  <BOsPicker
                    tightBosText={
                      <div>
                        You selected <strong>one or more tight Business Objects</strong> for which you don't have permissions on. You will
                        not be able to save:
                      </div>
                    }
                    tightBos={tightBos}
                    initialState={boValues}
                    updateInnerBos={boPickerUpdateBos}
                    boFromselectedLibrary={selectedLibrary?.bOs}
                    countries={(document?.countries ?? []).map((x) => x.id)}
                    onChange={setBoValuesWithoutChangingMasterData}
                  />
                </div>
              )}
            </div>
          )}

          {selectedLibrary?.masterDatas?.length > 0 && document?.countries?.length > 0 && (
            <div className="new-document-bo-master-data-section">
              <div className="no-bos-label">Select Master Data</div>
              {getMasterData(
                selectedLibrary.masterDatas,
                (document?.countries ?? []).map((x) => x.id),
                document?.bOs,
                onMasterDataChange,
                false
              )}
              {document?.bOs['xware_territorial_survey']?.length > 0 && (
                <div className="bo-territorial-link-button-container">
                  <TerritorialMasterDataLinkBos onClick={onClickAddMasterdataLinkedBos} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="nav-button-zone">
        <LabelButton text="Back" icon="ChromeBack" whiteOutlined onClick={() => goBack()} />
        <LabelButton text="Next" icon="ChromeBackMirrored" orangeSolid onClick={() => goNext()} />
      </div>
    </div>
  );
};
