import {Banner, FileList as FileListC, LabelButton, MenuTabs, ModalDialog} from '@Eni/docware-fe-master';
import './NewLibDocumentSubTypes.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {ILibraryDocumentSubTypeNewLib, ILibraryNewLib, LibraryFunctions} from '../../../Models/ILibrary';
import {SelectionMode} from '@fluentui/react';
import {getItemWithDefaultsValues, ModalMode} from '../NewLibPage';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import NavButtons from '../../../Components/NavButtons/NavButtons';

interface NewLibDocumentSubTypesProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
  selectedDocumentSubType: ILibraryDocumentSubTypeNewLib;
  setSelectedDocumentSubType: Dispatch<SetStateAction<ILibraryDocumentSubTypeNewLib>>;
  showEditModal: boolean;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  existingDocumentSubTypes: string[];
}

const NewLibDocumentSubTypes = (props: NewLibDocumentSubTypesProps) => {
  const [newDocumentSubType, setNewDocumentSubType] = useState<ILibraryDocumentSubTypeNewLib>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <LabelButton
          text="Add"
          icon="Add"
          onClick={() => {
            setShowCreateModal(true);
          }}
        />

        <FileListC
          defaultSortOnThisColumnIndex={0}
          fileIconFromField={''}
          onItemsSelected={(selectedRows: ILibraryDocumentSubTypeNewLib[]) => {
            if (selectedRows.length === 0) {
              props.setSelectedDocumentSubType(undefined);
            }
            if (selectedRows.length === 1) {
              props.setSelectedDocumentSubType(selectedRows[0]);
            }
          }}
          currentSelectedItems={undefined}
          currentSelectedItem={props.selectedDocumentSubType}
          hideBottomButton={true}
          selectionMode={SelectionMode.single}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'name',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Is Active',
              dataType: 'string',
              fieldName: 'isActive',
              iconName: null,
              size: 'large',
              onRender: (item: ILibraryDocumentSubTypeNewLib) => {
                return item.isActive?.toString();
              },
            },
            {
              name: 'Document Type',
              dataType: 'string',
              fieldName: 'documentType',
              iconName: null,
              size: 'large',
            },
          ]}
          items={props.currentLibrary?.documentSubTypes ?? []}
        />
      </div>

      <NavButtons
        onBackClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES)}
        onNextClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT)}
      />

      <ModalDocumentSubType
        mode={ModalMode.Edit}
        documentSubType={props.selectedDocumentSubType}
        setDocumentSubType={props.setSelectedDocumentSubType}
        showModal={props.showEditModal}
        setShowModal={props.setShowEditModal}
        existingDocumentSubTypes={props.existingDocumentSubTypes}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const updatedDocumentSubTypes = props.currentLibrary?.documentSubTypes.map((x) =>
            x.id == props.selectedDocumentSubType.id ? props.selectedDocumentSubType : x
          );
          props.updateLibrary('documentSubTypes', updatedDocumentSubTypes);
        }}
      />

      <ModalDocumentSubType
        mode={ModalMode.Create}
        documentSubType={newDocumentSubType}
        setDocumentSubType={setNewDocumentSubType}
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        existingDocumentSubTypes={props.existingDocumentSubTypes}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const emptyDocumentSubType: ILibraryDocumentSubTypeNewLib = {
            id: crypto.randomUUID(),
            name: '',
            isActive: false,
            addedInThisSession: true,
            documentType: '',
          };
          const documentSubType = getItemWithDefaultsValues(emptyDocumentSubType, newDocumentSubType);

          props.updateLibrary('documentSubTypes', [...(props.currentLibrary?.documentSubTypes ?? []), documentSubType]);
          setNewDocumentSubType(undefined);
        }}
      />
    </>
  );
};

interface ModalDocumentSubTypeProps {
  mode: ModalMode;
  documentSubType: ILibraryDocumentSubTypeNewLib;
  setDocumentSubType: Dispatch<SetStateAction<ILibraryDocumentSubTypeNewLib>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  existingDocumentSubTypes: string[];
  currentLibrary: ILibraryNewLib;
  onConfirm: Function;
}

const ModalDocumentSubType = (props: ModalDocumentSubTypeProps) => {
  const fieldDisabled = props.mode == ModalMode.Edit && !props.documentSubType?.addedInThisSession;
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  return (
    <ModalDialog
      modalTitle={`${props.mode == ModalMode.Create ? 'Create' : 'Edit'} Document SubType`}
      modalInnerComponent={
        <>
          <FluentUIDecorator
            label="Name"
            info={getInputInfos('new-lib', 'document-sub-type-name')}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              disabled: fieldDisabled,
              value: props.documentSubType?.name,
              onChange: (e, currentValue) => props.setDocumentSubType({...props.documentSubType, name: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Is Active"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'document-sub-type-is-active')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              disabled: false,
              defaultChecked: props.documentSubType?.isActive,
              onChange: (e, currentValue) => props.setDocumentSubType({...props.documentSubType, isActive: currentValue}),
            })}
          />
          <FluentUIDecorator
            label={'Document Type'}
            info={getInputInfos('new-lib', 'document-sub-type-document-type-name')}
            fluentComponent={FluentUIDecoratorTypes.Dropdown({
              disabled: fieldDisabled,
              placeholder: 'Select A Document Type',
              options: LibraryFunctions.getDocumentTypesNames([props.currentLibrary]).map((x) => {
                return {key: x, text: x};
              }),
              selectedKey: props.documentSubType?.documentType,
              onChange: (e, currentValue) => {
                props.setDocumentSubType({...props.documentSubType, documentType: currentValue.key.toString()});
              },
            })}
          />
          <Banner
            enabled={showErrorBanner}
            type="error"
            message={
              "The Document Sub Type name must be the unique across all libraries and can't be blank. " + 'You must select a document Type'
            }
          />
        </>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            props.setShowModal(false);
          },
        },
        {
          label: 'Confirm',
          onClick: () => {
            if (props.mode === ModalMode.Create) {
              const documentSubTypeName = props.documentSubType?.name.replace(/^\s+|\s+$/gm, '');
              const currentDocumentSubTypes = LibraryFunctions.getDocumentSubTypesNames([props.currentLibrary]);
              if (
                !documentSubTypeName ||
                props.existingDocumentSubTypes.includes(documentSubTypeName) ||
                currentDocumentSubTypes.includes(documentSubTypeName) ||
                !props.documentSubType?.documentType
              ) {
                setShowErrorBanner(true);
                return;
              }
            }
            props.onConfirm();
            props.setShowModal(false);
          },
        },
      ]}
      onAbort={() => {
        props.setShowModal(false);
      }}
      enableModal={props.showModal}
    />
  );
};

export default NewLibDocumentSubTypes;
