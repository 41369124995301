export enum IDownloadType {
  Direct,
  Email,
}

export interface IDownloadPayload {
  data: string;
  downloadType: IDownloadType;
  error: boolean;
}
