import {GENERIC_URLS} from '../../Constants/Urls';
import {IPermissionMatrixItem} from '../../Models/PermissionMatrixItem';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from '../AjaxService';

const PermissionMatrixController = {
  getAll: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${GENERIC_URLS.PERMISSION_MATRIX}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  createOne: async (item: IPermissionMatrixItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${GENERIC_URLS.PERMISSION_MATRIX}`;
    request.payload = item;
    request.method = 'POST';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  updateOne: async (item: IPermissionMatrixItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${GENERIC_URLS.PERMISSION_MATRIX}`;
    request.payload = item;
    request.method = 'PUT';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteOne: async (item: IPermissionMatrixItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${GENERIC_URLS.PERMISSION_MATRIX}`;
    request.payload = item;
    request.method = 'DELETE';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getExportStatus: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/permission-matrix/export-status`;
    request.method = 'GET';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
  },
  /**
   * Change the permission matrix status from WorkInProgress to ReadyToDeploy
   * */
  updateExportStatus: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/permission-matrix/export-status`;
    request.method = 'PATCH';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
  },
};

export default PermissionMatrixController;
