import {IDropdownOption, IPersonaProps, ITag} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../../Reducers/RootReducer';
import {
  EDocumentSignatureType,
  IDocumentMetadata,
  IDocumentSignStep,
  IMetadataDict,
  INewDocument,
} from '../../../Models/IDocument';
import {
  IElementTypes,
  ILibrary,
  ILibraryDocumentType,
  IProfessionalArea,
  MetadataType,
} from '../../../Models/ILibrary';
import {NewDocumentAction} from '../../../Reducers/NewDocument/NewDocumentActions';
import AppRoutes, {concatUrl} from '../../../Utils/AppRoutes';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {EnumUtils} from '../../../Utils/EnumUtils';
import {DocumentStatus} from '../../../Constants/Enums';
import './DocumentMetadata.scss';
import {ICountryVoice} from '../../../Models/IUser';
import ApiService from '../../../Services/ApiService';
import {IOptionDto} from '../../../Models/IOptionDto';
import {DynamicMetadata} from '../../../Components/DynamicMetadata/DynamicMetadata';
import {GenericActions, getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {IntegrationUtils} from '../../../Utils/IntegrationUtils';
import {TagUtils} from '../../../Utils/TagUtils';
import {IAPIResponse} from '../../../Services/AjaxService';
import {IWorkflowItem} from '../../../Models/IWorkflowItem';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {Banner, LabelButton, MenuTabs, ModalDialog} from '@Eni/docware-fe-master';
import {validateForm} from '../NewDocPage';
import DocumentPickerInput from '../../../Components/DocumentPickerInput/DocumentPickerInput';
import {WorkflowPeoplePicker} from '../../../Components/WorkflowPeoplePicker/WorkflowPeoplePicker';
import {DocumentSource} from '../../../Models/DocumentSource';
import {IDocumentTypeConfiguration} from '../../../Models/DocumentTypeConfiguration';
import {CustomUploadFormBehavior, ICustomUploadFormBehavior} from './DocumentMetadataCustomBehavior';
import VideoTutorialIcon from '../../../Components/VideoTutorialIcon/VideoTutorialIcon';
import {IValue} from '../../../Models/IValue';
import {ISecurityLevelOptions} from '../../../Models/Types';
import {
  authorsShouldBeRequiredFlag,
  getUserCountries,
  optionsToPersonas,
  personasToOptions,
} from '../../../Utils/NewDocumentUtils';
import { IDocumentTypesForLibrary } from '../../../Models/DocumentTypes';
import {getFileExtension} from '../../../Utils/StringUtils';
import {LibraryUtils} from '../../../Utils/LibraryUtils';

interface IDocumentMetadataProps {
  docTypes: IDocumentTypesForLibrary[]
}

export const DocumentMetadata = ({docTypes}: IDocumentMetadataProps) => {
  const libraries = useSelector<GlobalState, ILibrary[]>((state) => state.newDocument.userLibraries);
  const document = useSelector<GlobalState, INewDocument>((state) => state.newDocument.document);
  const approvalNeeded = useSelector<GlobalState, number>((state) => state.generic.documentUploadApprovalFlag);
  const [metadata, setMetadataInner] = useState<IDocumentMetadata>();
  const [profAreaChangeOutsideLibrary, setProfAreaChangeOutsideLibrary] = useState<IDropdownOption<ILibrary> | undefined>(undefined);
  const [profAreaChangeWithinLibrary, setProfAreaChangeWithinLibrary] = useState<IDropdownOption<ILibrary> | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newCountries, setNewCountries] = useState<IOptionDto[] | undefined>(undefined);
  const [docSources, setDocSources] = useState<DocumentSource[]>([]);
  const [activeLibrary, setActiveLibrary] = useState<ILibrary | null>(null);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [lockWhiteListPreFill, setLockWhiteListPreFill] = useState<boolean>(false);
  const [workflow, setWorkflow] = useState<IWorkflowItem | null>(null);
  const [securityLevelOptions, setSecurityLevelOptions] = useState<ISecurityLevelOptions | null>(null);
  const [docSourceOptions, setDocSourceOptions] = useState<{key: string; text: string; order: number}[]>([]);
  const [tightCountries, setTightCountriesInner] = useState<string[]>([]);
  const [showNextPageError, setShowNextPageError] = useState<boolean>(false);
  const [pendingDocType, setPendingDocType] = useState<{key: string; text: string} | null>(null);
  const [docTypeConfirmPopupVisible, setDocTypeConfirmPopupVisible] = useState<boolean>(false);
  const [pendingCountries, setPendingCountries] = useState<ITag[]>([]);
  const [countriesConfirmPopupVisible, setCountriesConfirmPopupVisible] = useState<boolean>(false);
  const [canSuperseded, setCanSuperseded] = useState(false);
  const [canRelatedTo, setCanRelatedTo] = useState(false);
  const [docTypeConfigurations, setDocTypeConfigurations] = useState<IDocumentTypeConfiguration[]>([]);
  const [activeDocTypeConfigurations, setActiveDocTypeConfigurations] = useState<IDocumentTypeConfiguration | null>(null);
  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);
  const currentUser = useSelector((state: GlobalState) => state.user.currentUser);
  const selectedLibrary = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);

  const coOwnerIsModified = (): boolean => {
    const existingCoOwnersIds = metadata.existingCoOwners.map((x) => x.id);
    const coOwnersIds = metadata.coOwners.map((x) => x.id);

    const allExistingCoOwnersInCoOwners = existingCoOwnersIds.every((x) => coOwnersIds.includes(x));
    const allCoOwnersInExistingCoOwners = coOwnersIds.every((x) => existingCoOwnersIds.includes(x));
    return !(allExistingCoOwnersInCoOwners && allCoOwnersInExistingCoOwners);
  };

  const setTightCountries = (ls: string[]) => {
    dispatch(GenericActions.setTightErrorCtr(ls.length > 0));
    setTightCountriesInner(ls);
  };

  const getSecurityLevelDescriptor = () => {
    if (activeDocTypeConfigurations) {
      return {
        default: activeDocTypeConfigurations.defaultSecurityLevel,
        options: activeDocTypeConfigurations.allowedSecurityLevels,
      };
    } else {
      return securityLevelOptions;
    }
  };

  const setMetadata = (meta: IDocumentMetadata) => {
    // apply cascade transformations to state
    for (let i = 0; i < CustomUploadFormBehavior.length; i++) {
      let customEffect: ICustomUploadFormBehavior = CustomUploadFormBehavior[i];
      meta = customEffect.getCustomEffect(meta);
    }

    // generate document code
    meta.documentCode = computeDocumentCode(meta);

    console.log('Metadata update', meta);
    setMetadataInner(meta);
    dispatch(NewDocumentAction.setMetadata(meta as IDocumentMetadata));
  };

  const [params] = useSearchParams();
  const pageMode = params.get('mode');

  let navigate = useNavigate();
  const dispatch = useDispatch();

  let areas: IDropdownOption<ILibrary>[] = [];
  libraries.forEach((library: ILibrary) => {
    areas = areas.concat(
      library.professionalAreas.map((area: IProfessionalArea) => {
        return {
          key: area.id,
          text: area.name,
          data: library,
        };
      })
    );
  });

  let allElementTypes: string[] = [];
  libraries.forEach((library: ILibrary) => {
    library.elementTypes.forEach((el: IElementTypes) => {
      if (allElementTypes.indexOf(el.name) === -1) {
        allElementTypes.push(el.name);
      }
    });
  });

  const initSecurityOptions = () => {
    if (metadata && metadata.professionalArea && securityLevelOptions === null) {
      let securityLevelsFromLibrary: ISecurityLevelOptions = {
        options: [],
        default: null,
      };

      let libraryMatch: any[] = areas.filter(
        (x: {key: string; text: string; data: ILibrary}) =>
          x.data.professionalAreas.filter((x: IProfessionalArea) => x.id === metadata.professionalArea.id).length > 0
      );

      if (libraryMatch.length > 0) {
        let matched: ILibrary = libraryMatch[0].data;
        if (matched.defaultSecurityLevel) {
          securityLevelsFromLibrary.default = matched.defaultSecurityLevel;
        }
        if (matched.securityLevels) {
          securityLevelsFromLibrary.options = matched.securityLevels;
        }

        let pAreasInner: IProfessionalArea[] = matched.professionalAreas.filter(
          (x: IProfessionalArea) => x.id === metadata.professionalArea.id
        );

        if (pAreasInner.length > 0) {
          let matchedInner: IProfessionalArea = pAreasInner[0];
          if (matchedInner.defaultSecurityLevel) {
            securityLevelsFromLibrary.default = matchedInner.defaultSecurityLevel;
          }
          if (matchedInner.securityLevels) {
            securityLevelsFromLibrary.options = matchedInner.securityLevels;
          }
        }
      }

      setSecurityLevelOptions(securityLevelsFromLibrary);
    }
  };

  useEffect(() => {
    const sourcesOptions = docSources.map((x: DocumentSource) => {
      return {key: x.id, text: x.name, order: x.order};
    });

    setDocSourceOptions(sourcesOptions);

    if (metadata && !metadata.documentSource && sourcesOptions.length > 0) {
      onFixedFieldChange('documentSource', sourcesOptions[0].key);
    }
  }, [docSources]);

  useEffect(() => {
    ApiService.DocumentSourcesController.getDocumentSource((response: IAPIResponse) => {
      if (response.error == null) {
        setDocSources(response.payload);
      }
    });
  }, []);

  const checkTightCountries = async () => {
    let lib = libraries.find((l) => l.name === metadata.library);

    let results = [];

    if (lib.checkTightBOs) {
      for (let i = 0; i < metadata.countries.length; i++) {
        let c: IOptionDto = metadata.countries[i];
        let res = await ApiService.DocumentController.checkIsTight(c.id, 'COUNTRY_CD');
        if (res.error === null && (res.payload === false || (window as any)['force-tight-test'])) {
          results.push(c.name);
        }
      }
    }
    setTightCountries(results);
  };

  useEffect(() => {
    if (metadata && metadata.countries) {
      checkTightCountries();
    }
  }, [metadata?.countries])

  useEffect(() => {
    initSecurityOptions();

    libraries.forEach((library) => {
      library.professionalAreas.forEach((professionalArea) => {
        if (professionalArea.id === metadata?.professionalArea?.id) {
          setCanSuperseded(library.allowSuperseded);
          setCanRelatedTo(library.allowRelatedTo);
        }
      });
    });

    if (
      metadata &&
      metadata.securityLevel === 'Restricted Whitelist' &&
      ((metadata.whitelist && metadata.whitelist.length === 0) || metadata.whitelist === undefined) &&
      activeLibrary.defaultRestrictedWhitelistTypes &&
      !lockWhiteListPreFill
    ) {
      let def = [];
      for (let i = 0; i < activeLibrary.defaultRestrictedWhitelistTypes.length; i++) {
        def.push({
          key: activeLibrary.defaultRestrictedWhitelistTypes[i],
          text: activeLibrary.defaultRestrictedWhitelistTypes[i],
        } as IPersonaProps);
      }

      if (def.length > 0) {
        setLockWhiteListPreFill(true);
        onPeopleChange('whitelist', def);
      }
    }
  }, [metadata]);

  useEffect(() => {
    if (document) {
      ApiService.LibraryController.getDocTypeslConfigurations((response: IAPIResponse) => {
        if (response.error === null) {
          setDocTypeConfigurations(response.payload);
        }
      });

      ApiService.WorkflowController.getById(document.documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          setWorkflow(response.payload);
        }
      });
    }

    if (metadata?.documentId == null && document != null && document.documentId != null) {
      setMetadata({...(document as IDocumentMetadata)});

      if (document.professionalArea) {
        const libraryDropDown = areas.filter((x) => x.key === document.professionalArea.id)[0];
        if(libraryDropDown){
          setActiveLibrary(libraryDropDown.data);
        }
      }
    }

    if (areas.length === 1 && !metadata?.professionalArea) {
      setTimeout(() => {
        try {
          setActiveLibrary(areas[0]?.data);
        } catch (e) {}
      }, 100);
    }
  }, [document?.documentId]);

  useEffect(() => {
    if (metadata?.documentName == null && metadata?.documentId != null) {
      let fName = document.mainFile?.fileName ?? '';
      let fNameSpl = fName?.split('.');
      fNameSpl.pop();

      let newMeta = {
        ...metadata,
        documentName: fNameSpl.join('.'),
      };
      setMetadata(newMeta as IDocumentMetadata);
    }
  }, [document.mainFile]);

  const detectErrors = () => {
    if (!validateForm(document, selectedLibrary, approvalNeeded, false)) {
      (window as any)['highlight-errors'] = 1;
    } else {
      (window as any)['highlight-errors'] = 0;
    }
  };

  const dotExploreObject = (object, path) => {
    let item = {...object};
    let splPath = path.split('.');
    for (let i = 0; i < splPath.length; i++) {
      item = item[splPath[i]];

      if (!item) {
        return '?';
      }
    }
    return item;
  };

  const computeDocumentCode = (data) => {
    if (selectedLibrary && selectedLibrary.isDocumentCodeVisible) {
      let documentCodes: string[] = [];
      for (let i = 0; i < selectedLibrary.documentCodeDefinition.length; i++) {
        let code = selectedLibrary.documentCodeDefinition[i];
        documentCodes.push(dotExploreObject(data, code));
      }
      return documentCodes.join('-');
    }

    return null;
  };

  const applyDocumentSybTypeConfiguration = (docTypeId: string) => {
    let extraInits = [];
    let doNull = true;
    if (docTypeConfigurations.length > 0) {
      let matched: IDocumentTypeConfiguration[] = docTypeConfigurations.filter((x: IDocumentTypeConfiguration) =>
        x.documentSubTypeIds.includes(docTypeId)
      );
      if (matched.length > 0) {
        let match: IDocumentTypeConfiguration = matched[0];

        setActiveDocTypeConfigurations(match);

        // update security level
        if (match.defaultSecurityLevel) {
          extraInits.push(['securityLevel', match.defaultSecurityLevel]);
          doNull = false;
        }

        // if whitelist is empty, pre fill it
        if (match.defaultWhitelist.length > 0) {
          let def = [];
          for (let i = 0; i < match.defaultWhitelist.length; i++) {
            let option: string = match.defaultWhitelist[i];
            def.push({
              key: option,
              text: option,
            } as IPersonaProps);
          }
          extraInits.push(['whitelist', personasToOptions(def ?? [])]);
          doNull = false;
        }
      }
    }

    if (doNull) {
      extraInits.push(['whitelist', []]);
      setActiveDocTypeConfigurations(null);
    }

    return extraInits;
  };

  const goNext = (route: string) => {
    if (!metadata.professionalArea) {
      setShowNextPageError(true);
    }
    if (document != null && document.documentId && metadata && metadata.professionalArea) {
      detectErrors();
      navigate(concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, route) + window.location.search);
    }
  };

  const getSelectedProfessionalArea = (option: IDropdownOption<ILibrary> | undefined): IProfessionalArea | undefined => {
    const pAreas: IProfessionalArea[] = option?.data?.professionalAreas;
    return pAreas?.filter((x: IProfessionalArea) => x.id === option.key)[0];
  };

  const getDefaultCountry = (libraryData: ILibrary, countryList: ICountryVoice[]): IValue[] => {
    if (libraryData) {
      const targetId = libraryData.defaultCountryId;
      const matchedCountries =
        targetId === null
          ? undefined
          : countryList
            .filter((x: ICountryVoice) => x.code === targetId)
            .map((country: ICountryVoice) => {
              return {id: country.code, name: country.name};
            });
      if (matchedCountries && matchedCountries.length > 0) {
        return [matchedCountries[0]];
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  /**
   * Delete files that don't are allowed in the target libraryId
   */
  function resetFiles(libraryId: string){
    const futureLibraryDocTypes = docTypes.filter(dt => dt.libraryId == libraryId)[0];
    if(futureLibraryDocTypes){
      const mainFileName = document?.mainFile?.fileName
      if(mainFileName){
        const fileExt = `.${getFileExtension(mainFileName)}`;
        if(!futureLibraryDocTypes.mainFileExts.includes(fileExt)){
          dispatch(NewDocumentAction.setMainFile(null))
        }
      }
      document?.attachments.forEach(attachment => {
        const fileExt = `.${getFileExtension(attachment.fileName)}`;
        if(!futureLibraryDocTypes.attachmentsExts.includes(fileExt)){
          dispatch(NewDocumentAction.deleteAttachment(attachment.id));
        }
      })
    } else {
      console.warn("resetFiles: could not found the target library")
    }
  }


  function setProfessionalArea(option: IDropdownOption<ILibrary>, reset?: ("WITHIN_LIBRARY" | "OUTSIDE_LIBRARY")) {
    const library = option.data;
    const baseMeta = metadata ? {...metadata} : {...(document as IDocumentMetadata)};
    const pArea: IProfessionalArea = getSelectedProfessionalArea(option);
    const defaultSecurityLevel = LibraryUtils.getDefaultSecurityLevelsFromLibrary(library, pArea);

    const newInfo = {
      ...baseMeta,
      library: option.data?.name,
      professionalArea: pArea,
    } as IDocumentMetadata;


    if (activeDocTypeConfigurations) {
      newInfo.securityLevel = activeDocTypeConfigurations.defaultSecurityLevel;
    } else {
      if (defaultSecurityLevel.default && newInfo.securityLevel == null) {
        newInfo.securityLevel = defaultSecurityLevel.default;
      }
    }

    if (showDocumentSubTypesAsTypes(library)) {
      newInfo.documentTypeId = library?.documentTypes[0].id;
      newInfo.documentTypeName = library?.documentTypes[0].name;
    } else {
      newInfo.documentTypeId = null;
      newInfo.documentTypeName = null;
    }

    if (!reset) {
      // first time that I set the Professional Area
      newInfo.countries = getDefaultCountry(option?.data, permissions.countries ?? []);
      resetFiles(library.id);
    } else {
      switch (reset) {
        case 'OUTSIDE_LIBRARY':{
          newInfo.relatedDocuments = [];
          newInfo.linkedDocuments = [];
          dispatch(NewDocumentAction.setBos({}));
          newInfo.countries = getDefaultCountry(option?.data, permissions.countries ?? []);
          resetFiles(library.id);
          newInfo.metadata = null;
          break;
        }
        case 'WITHIN_LIBRARY':{
          break;
        }
      }
      // always reset
      newInfo.whitelist = [];
      newInfo.documentSubTypeId = null;
      newInfo.documentSubTypeName = null;
      newInfo.coOwners = [...newInfo.existingCoOwners];
      newInfo.signers = [];
      setLockWhiteListPreFill(false);
      GenericActions.setUploadApprovalFlag(-1);
      setTimeout(() => {
        GenericActions.setUploadApprovalFlag(0);
      }, 300);
    }

    setSecurityLevelOptions(defaultSecurityLevel);
    setActiveLibrary(library);
    setMetadata(newInfo);
    dispatch(NewDocumentAction.setSelectedLibrary(library));
  }

  const onAreaChange = (option: IDropdownOption<ILibrary> | undefined) => {
    if (!option) {
      return;
    }
    if (metadata?.professionalArea) {
      if(metadata.library == option.data.name) {
        setProfAreaChangeWithinLibrary(option);
      } else {
        setProfAreaChangeOutsideLibrary(option);
      }
    } else {
      setProfessionalArea(option);
    }
  };

  const getDocumentStatusOptions = () => {
    const statusDict = EnumUtils.getDict(DocumentStatus);
    const options = [] as IDropdownOption[];
    for (let key in statusDict) {
      options.push({key: key, text: statusDict[key]});
    }
    return options;
  };

  const getTags = () => {
    if (metadata) {
      if (metadata.tags) {
        return metadata.tags.map((t) => {
          return {
            name: t,
            key: t,
          };
        }) as ITag[];
      }
    }

    return [] as ITag[];
  };

  const onFixedFieldChange = (key: string, value: any) => {
    const newInfo = {...metadata} as any;
    newInfo[key] = value;
    setMetadata(newInfo as IDocumentMetadata);
  };

  const onMultipleFixedFieldChange = (keys: string[], values: any[]) => {
    const newInfo = {...metadata} as any;
    for (let i = 0; i < keys.length; i++) {
      newInfo[keys[i]] = values[i];
    }
    setMetadata(newInfo as IDocumentMetadata);
  };

  const getCountries = (value: ITag[]): {id: string; name: string}[] | null => {
    const oldCountries = (metadata as IDocumentMetadata).countries ?? [];
    const newCountries = value.map(TagUtils.tagToOption);

    if (document && document.bOs) {
      if (Object.keys(document.bOs).length > 0 && oldCountries.length > newCountries.length) {
        setNewCountries(newCountries);
        setShowModal(true);
      } else {
        return newCountries;
      }
    } else {
      return newCountries;
    }
  };

  const onPeopleChange = (key: string, people: IPersonaProps[] | undefined) => {
    const options = personasToOptions(people ?? []);
    onFixedFieldChange(key, options);
  };

  const getTagsFromDB = async (filterText: string) => {
    if (filterText.length > 0) {
      ApiService.TagsController.getTags(filterText, (response: IAPIResponse) => {
        if (response.error == null) {
          setSuggestedTags(
            response.payload.map((x: any) => {
              return x.content;
            })
          );
        }
      });
    }
  };

  const filterSelectedTags = (filterText: string, tagList: ITag[] | undefined): ITag[] => {
    getTagsFromDB(filterText);

    const suggestedArray = suggestedTags.map((x: string, i: number) => {
      return {key: i, name: x};
    });
    let list = [{key: -1, name: filterText} as ITag].concat(suggestedArray);
    list = list.filter(
      (x: any) =>
        x.name.toLowerCase().indexOf(filterText.toLocaleLowerCase()) !== -1 ||
        filterText.toLowerCase().indexOf(x.name.toLocaleLowerCase()) !== -1
    );
    const exclude = metadata?.tags;
    return list.filter((x) => (exclude ? exclude : []).indexOf(x.name) === -1);
  };

  let documentTypeOptions: any = [];
  let documentSubTypeOptions: any = [];

  if (activeLibrary && metadata.professionalArea && selectedLibrary?.professionalAreas) {
    let profAreaId: string = metadata.professionalArea.id;
    let profAreaObj: IProfessionalArea[] = selectedLibrary.professionalAreas.filter((x: IProfessionalArea) => x.id === profAreaId);

    if (profAreaObj.length > 0) {
      documentTypeOptions = activeLibrary.documentTypes
        .filter((x: ILibraryDocumentType) => x.professionalAreaIds.includes(profAreaObj[0].id))
        .map((x: ILibraryDocumentType) => {
          return {key: x.id, text: x.name, isActive: x.isActive};
        });

      let selectedDocType: ILibraryDocumentType = activeLibrary.documentTypes[0];
      if (metadata.documentTypeId) {
        let selectedDocTypeMatch: ILibraryDocumentType[] = activeLibrary.documentTypes
          .filter((x: ILibraryDocumentType, i: number) => x.professionalAreaIds.includes(profAreaObj[0].id))
          .filter((x: ILibraryDocumentType) => x.id === metadata.documentTypeId);
        if (selectedDocTypeMatch.length > 0) {
          selectedDocType = selectedDocTypeMatch[0];
        }

        if (selectedDocType.subTypes) {
          documentSubTypeOptions = selectedDocType.subTypes
            .filter((x: ILibraryDocumentType, i: number) => x.professionalAreaIds.includes(profAreaObj[0].id))
            .map((x: ILibraryDocumentType, i: number) => {
              return {key: x.id, text: x.name, isActive: x.isActive};
            });
        }
      }
    }
  }

  const fileValidForApproval = (fileName: string) => {
    if (fileName.indexOf('.') !== -1) {
      let fspl = fileName.split('.');
      let ext = fspl[fspl.length - 1];
      return ['doc', 'docx', 'ppt', 'pptx', 'pdf'].includes(ext.toLocaleLowerCase());
    }

    return false;
  };

  const showDocumentSubTypesAsTypes = (library: ILibrary): boolean => {
    if (library) {
      let lengthCheck = library && library.documentTypes && library.documentTypes.length === 1;
      return library.skipDocTypeSelectionIfOnlyOne && lengthCheck;
    }
    return false;
  };

  const handleSubDocTypeChanged = (option: any) => {
    let o = option;
    let extraInits = applyDocumentSybTypeConfiguration(o.key);
    let baseNames = ['documentSubTypeId', 'documentSubTypeName'];
    let baseValues = [o.key, o.text];

    for (let i = 0; i < extraInits.length; i++) {
      baseNames.push(extraInits[i][0]);
      baseValues.push(extraInits[i][1]);
    }
    baseNames.push('coOwners');
    baseValues.push([...metadata.existingCoOwners]);

    onMultipleFixedFieldChange(baseNames, baseValues);
  };

  const getCustomDisabledMap = () => {
    let map: any = {};

    if (metadata && metadata.professionalArea && metadata.professionalArea.name === 'DSD') {
      map['unit'] = metadata.documentTypeId === 'Fundamental';
    }

    return map;
  };

  const renderDynamicMetadataBlock = (positioning: number) => {
    return (
      <div>
        <DynamicMetadata
          positioning={positioning}
          outerState={metadata}
          autoIncrementNameFixer={(name: string) => {
            if (metadata && metadata.professionalArea && metadata.professionalArea.name === 'DSD') {
              if (metadata.metadata && metadata.metadata.unit) {
                return metadata.metadata.unit.selectValue.id + '-' + name;
              } else {
                return '';
              }
            }
            return name;
          }}
          customDisableMap={getCustomDisabledMap()}
          values={metadata?.metadata as IMetadataDict}
          onChange={(k: any, t: any, v: any) => {
            let newMetadata = {...metadata};
            newMetadata.metadata = newMetadata.metadata ?? {};
            newMetadata.metadata[k] = v;

            // Only if development library is selected select projectName if projectCode is selected and vice versa
            if (newMetadata?.professionalArea?.id === 'DPMS') {
              if (k === 'projectCode') {
                if (v === undefined) {
                  newMetadata.metadata['projectName'] = undefined;
                } else {
                  newMetadata.metadata['projectName'] = {
                    selectValue: {id: v.selectValue.name, name: v.selectValue.id},
                    type: MetadataType.TagPickerSingleSelect,
                  };
                }
              } else if (k === 'projectName') {
                if (v === undefined) {
                  newMetadata.metadata['projectCode'] = undefined;
                } else {
                  newMetadata.metadata['projectCode'] = {
                    selectValue: {id: v.selectValue.name, name: v.selectValue.id},
                    type: MetadataType.TagPickerSingleSelect,
                  };
                }
              }
            }

            if (metadata && metadata.professionalArea && metadata.professionalArea.name === 'DSD') {
              // reset document Number on DSD if unit changes
              if (k === 'unit') {
                delete newMetadata.metadata['documentNumber'];
              }
            }
            setMetadata(newMetadata as IDocumentMetadata);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <ModalDialog
        modalTitle={'Delete Country?'}
        modalMessage={'Removing a Country will cause the deletion of all Business Objects. Proceed anyway?'}
        enableModal={showModal}
        onAbort={() => {
          setNewCountries(undefined);
          setShowModal(false);
        }}
        onAccept={async () => {
          setShowModal(false);
          if (newCountries != null) {
            onFixedFieldChange('countries', newCountries);
            setNewCountries(undefined);
            dispatch(NewDocumentAction.setBos({}));
          }
        }}
      />
      <MenuTabs
        tabs={[
          {
            label: 'Info',
            callback: () => {},
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Business Objects',
            callback: () => {
              goNext(AppRoutes.DOCUMENT_CREATION_ROUTE_BO);
            },
            isEvident: false,
            isPassed: false,
          },
          {
            label: 'Confirm',
            callback: () => {
              goNext(AppRoutes.DOCUMENT_CREATION_ROUTE_SUMMARY);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={'33%'}
      />

      <div className="metadata-section new-doc-summary-form-container">
        {showNextPageError && !activeLibrary && (
          <div style={{marginBottom: '1em'}}>
            <Banner enabled type='error'
                    message={'You must select the Professional Area before being able to switch tab.'} />
          </div>
        )}

        {renderDynamicMetadataBlock(1)}

        <div className="field-row flex-column-on-small-screen">
          <div className="field-33 fill-width-on-small-screen" style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Professional Area"
              info={getInputInfos('new-document', 'area')}
              required={true}
              errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                disabled:
                  pageMode === 'newversion' ||
                  (workflow !== null && pageMode !== 'newversion') ||
                  (document.documentStatus === 1 && pageMode === 'edit'),
                selectedKey: metadata?.professionalArea ? metadata?.professionalArea.id : undefined,
                options: areas,
                onChange: (e, o) => onAreaChange(o),
              })}
            />
            <Banner
              type={'warning'}
              message={
                <div>
                  You cannot edit the Area on a document saved as <strong>Signed</strong>.
                </div>
              }
              enabled={document.documentStatus === 1 && pageMode === 'edit'}
            />
            <Banner
              type={'warning'}
              message={
                <div>
                  You cannot edit the Area on a document saved as <strong>Completed</strong> for which an approval flow is already started.
                </div>
              }
              enabled={workflow !== null && pageMode !== 'newversion'}
            />
          </div>
          <div className="field-66 fill-width-on-small-screen" style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Document Name"
              info={getInputInfos('new-document', 'document-name')}
              required={true}
              errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
              fluentComponent={FluentUIDecoratorTypes.TextField({
                maxLength: 255,
                value: metadata?.documentName ?? '',
                onChange: (e, t) => onFixedFieldChange('documentName', t),
              })}
            />
          </div>
        </div>
        <div style={{marginBottom: '0.5em'}}>
          <div className="field-100">
            <FluentUIDecorator
              label="Countries"
              info={getInputInfos('new-document', 'country')}
              required={true}
              errorMessage={
                activeLibrary
                  ? (window as any)['highlight-errors'] === 1
                    ? 'This field is required'
                    : null
                  : 'You must select a Professional Area first.'
              }
              fluentComponent={FluentUIDecoratorTypes.TagPicker({
                disabled: pageMode === 'newversion',
                inputProps: {id: 'metadataCountry'},
                onResolveSuggestions: (f, s) => getUserCountries(f, s, permissions.countries, activeLibrary ? [activeLibrary.name] : null),
                selectedItems: metadata?.countries ? metadata.countries.map(TagUtils.optionToTag) : undefined,
                onChange: (value?: ITag[]) => {
                  if (coOwnerIsModified()) {
                    setPendingCountries(value ?? []);
                    setCountriesConfirmPopupVisible(true);
                  } else {
                    const newCountries = getCountries(value ?? []);
                    if (newCountries) {
                      onFixedFieldChange('countries', newCountries);
                    }
                  }
                },
              })}
            />
          </div>
          {tightCountries.length > 0 && (
            <div style={{marginTop: '0.5em'}}>
              <Banner
                type="error"
                message={
                  <div>
                    You selected <strong>one or more tight countries</strong> for which you don't have permissions on. If you don't remove
                    the following countries, you will not be able to save: {tightCountries.join(', ')}.
                  </div>
                }
                enabled
              />
            </div>
          )}
        </div>

        {/** this is a mock input used to have a UI warning message
         *   the real input is dependant of activeLibrary which may be undefined at first
         */}
        {!activeLibrary && (
          <div style={{marginBottom: '0.5em'}}>
            <div className='field-100'>
              <FluentUIDecorator
                label='Document Type'
                errorMessage={
                  activeLibrary
                    ? (window as any)['highlight-errors'] === 1
                      ? 'This field is required'
                      : null
                    : 'You must select a Professional Area first.'
                }
                info={getInputInfos('new-document', 'document-type')}
                required={true}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  disabled: pageMode === 'newversion',
                  selectedKey: null,
                  options: [],
                  onChange: (e, o: any) => {},
                })}
              />
            </div>
          </div>
        )}

        {!showDocumentSubTypesAsTypes(activeLibrary) && activeLibrary && (
          <div style={{marginBottom: '0.5em'}}>
            <div className='field-100'>
              <FluentUIDecorator
                label='Document Type'
                info={getInputInfos('new-document', 'document-type')}
                required={true}
                errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  disabled: pageMode === 'newversion',
                  selectedKey: metadata?.documentTypeId,
                  options: documentTypeOptions.filter((x: any) => x.isActive),
                  onChange: (e, o: any) => {
                    onMultipleFixedFieldChange(
                      ['documentTypeId', 'documentTypeName', 'documentSubTypeId', 'documentSubTypeName'],
                      [o.key, o.text, null, null],
                    );
                  },
                })}
              />
            </div>
          </div>
        )}
        {activeLibrary && activeLibrary.documentTypes && (
          <div style={{marginBottom: '0.5em'}}>
            <div className='field-100'>
              <FluentUIDecorator
                label={!showDocumentSubTypesAsTypes(activeLibrary) ? 'Document Sub-Type' : 'Document Type'}
                info={
                  !showDocumentSubTypesAsTypes(activeLibrary)
                    ? getInputInfos('new-document', 'document-sub-type')
                    : getInputInfos('new-document', 'document-type')
                }
                required={true}
                errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  disabled: pageMode === 'newversion',
                  selectedKey: metadata?.documentSubTypeId,
                  options: documentSubTypeOptions.filter((x: any) => x.isActive),
                  onChange: (e, o: any) => {
                    if (metadata.whitelist?.length > 0 || coOwnerIsModified()) {
                      setDocTypeConfirmPopupVisible(true);
                      setPendingDocType(o);
                    } else {
                      handleSubDocTypeChanged(o);
                    }
                  },
                })}
              />
            </div>
          </div>
        )}

        {renderDynamicMetadataBlock(0)}

        {/** this is a mock input used to have a UI warning message
         *   the real input is dependant of activeLibrary which may be undefined at first
         */}
        {selectedLibrary && selectedLibrary.isDocumentCodeVisible && (
          <div style={{marginBottom: '0.5em'}}>
            <div className="field-100">
              <FluentUIDecorator
                label='Document Code'
                info={getInputInfos('new-document', 'document-code-0')}
                fluentComponent={FluentUIDecoratorTypes.TextField({
                  disabled: true,
                  value: metadata?.documentCode ?? '',
                })}
              />
            </div>
          </div>
        )}

        <div className="field-row flex-column-on-small-screen">
          <div className="field-33 fill-width-on-small-screen" style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Document Status"
              info={getInputInfos('new-document', 'document-status')}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                disabled: true,
                selectedKey: '' + (metadata?.documentStatus ?? 0),
                options: getDocumentStatusOptions(),
                onChange: (e, o) => onFixedFieldChange('documentStatus', Number(o?.key)),
              })}
            />
          </div>
          <div className="field-66 fill-width-on-small-screen" style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Document Date"
              info={getInputInfos('new-document', 'document-date')}
              required={true}
              errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
              fluentComponent={FluentUIDecoratorTypes.DatePicker({
                value: metadata?.documentDate ? new Date(metadata?.documentDate) : undefined,
                onSelectDate: (d) => onFixedFieldChange('documentDate', +(d || 0)),
              })}
            />
          </div>
        </div>

        <div className="field-row flex-column-on-small-screen">
          <div className="field-33 fill-width-on-small-screen" style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Security Level"
              info={getInputInfos('new-document', 'security-level')}
              required={true}
              errorMessage={
                activeLibrary
                  ? (window as any)['highlight-errors'] === 1
                    ? 'This field is required'
                    : null
                  : 'You must select a Professional Area first.'
              }
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                selectedKey: metadata?.securityLevel
                  ? metadata?.securityLevel
                  : getSecurityLevelDescriptor()
                  ? getSecurityLevelDescriptor().default
                  : null,
                options: (getSecurityLevelDescriptor() ? getSecurityLevelDescriptor().options : []).map((x: string) => {
                  return {key: x, text: x};
                }),
                onChange: (e, o: any) => onFixedFieldChange('securityLevel', o.key),
              })}
            />
          </div>
          <div className="field-66 fill-width-on-small-screen" style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Authors"
              info={getInputInfos('new-document', 'authors')}
              errorMessage={
                activeLibrary
                  ? (window as any)['highlight-errors'] === 1
                    ? authorsShouldBeRequiredFlag(docSources, metadata?.documentSource)
                      ? 'This field is required'
                      : null
                    : null
                  : 'You must select a Professional Area first.'
              }
              required={authorsShouldBeRequiredFlag(docSources, metadata?.documentSource)}
              fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                disabled:
                  (workflow !== null && pageMode !== 'newversion') ||
                  (document.documentStatus === DocumentStatus.Signed && pageMode === 'edit'),
                selectedUsers: optionsToPersonas(metadata?.authors ?? []),
                onFilterChanged: (filterText: string, currentPersonas: IPersonaProps[] | undefined, limitResults?: number | undefined) => {
                  if (metadata?.professionalArea) {
                    let signers: IDocumentSignStep[] = metadata?.signers ?? [];
                    let users: IPersonaProps[][] = [metadata?.authors ?? []];

                    for (let i = 0; i < signers.length; i++) {
                      users.push(optionsToPersonas(signers[i].users));
                    }

                    return IntegrationUtils.resolvePeoplePickerVoices(filterText, IntegrationUtils.joinPersonasArrays(users));
                  } else {
                    return [];
                  }
                },
                onPeopleChanged: (people: IPersonaProps[]) => {
                  onPeopleChange('authors', people);
                },
              })}
            />

            <Banner
              type={'warning'}
              message={
                <div>
                  You cannot edit Authors on a document saved as <strong>Signed</strong>.
                </div>
              }
              enabled={document.documentStatus === 1 && pageMode === 'edit'}
            />
            <Banner
              type={'warning'}
              message={
                <div>
                  You cannot edit Authors on a document saved as <strong>Completed</strong> for which an approval flow is already started.
                </div>
              }
              enabled={workflow !== null && pageMode !== 'newversion'}
            />
          </div>
        </div>

        <div style={{marginTop: '0.5em'}}>
          <FluentUIDecorator
            label="Co Owners"
            info={getInputInfos('new-document', 'coOwners')}
            errorMessage={activeLibrary ? null : 'You must select a Professional Area, Countries, Document Sub Type first.'}
            required={true}
            fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
              selectedUsers: optionsToPersonas(metadata?.coOwners ?? []),
              onFilterChanged: (filterText: string, currentPersonas: IPersonaProps[] | undefined, limitResults?: number | undefined) => {
                if (metadata?.professionalArea && metadata?.countries?.length > 0 && metadata?.documentSubTypeId) {
                  let coOwners: IPersonaProps[][] = [metadata?.coOwners ?? []];

                  return IntegrationUtils.resolvePeoplePickerVoicesCoOwners(
                    filterText,
                    metadata.professionalArea.id,
                    metadata.countries.map((x) => x.id),
                    metadata?.documentSubTypeId,
                    currentPersonas ?? []
                  );
                } else {
                  return [];
                }
              },
              onPeopleChanged: (people: IPersonaProps[]) => {
                const IAmAdmin = currentUser.isSuperAdmin || permissions.canAccessAdminArea;
                const untouchedExistingCoOwners = (existingCoOwners: string[], newPeople: string[]): boolean => {
                  return existingCoOwners.every((coOwner) => newPeople.includes(coOwner));
                };

                if (people.length > 0) {
                  // I can't remove the last coOwner
                  if (IAmAdmin) {
                    onPeopleChange('coOwners', people);
                  } else {
                    // If I'm not admin I can't remove the existing coOwners
                    if (
                      untouchedExistingCoOwners(
                        document.existingCoOwners.map((x) => x.id),
                        people.map((x) => x.key.toString())
                      )
                    ) {
                      onPeopleChange('coOwners', people);
                    }
                  }
                }
              },
            })}
          />
        </div>

        <div style={{marginTop: '0.5em'}}>
          <FluentUIDecorator
            label='Whitelist'
            errorMessage={activeLibrary ? null : 'You must select a Professional Area first.'}
            info={getInputInfos('new-document', 'white-list')}
            fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
              selectedUsers: optionsToPersonas(metadata?.whitelist ?? []),
              onFilterChanged: (a, b) => {
                if (metadata?.professionalArea) {
                  let whiteListTypes = activeLibrary.allowedTypesInWorkflow ?? [];
                  let asPersonas = whiteListTypes.map((x: string) => {
                    return {key: x, text: x, secondaryText: x};
                  });

                  // if possible, add all element types
                  if (activeLibrary && activeLibrary.allowWhitelistOutsideLibrary) {
                    for (let i = 0; i < allElementTypes.length; i++) {
                      let el: string = allElementTypes[i];
                      asPersonas.push({key: el, text: el, secondaryText: el});
                    }
                  }

                  return IntegrationUtils.resolvePeoplePickerVoices(a, b, asPersonas);
                } else {
                  return [];
                }
              },
              onPeopleChanged: (p) => onPeopleChange('whitelist', p),
            })}
          />
        </div>

        <hr style={{marginTop: '1em'}} />

        {metadata?.professionalArea?.id !== 'DPMS' && (
          <div style={{marginTop: '0.5em'}}>
            <FluentUIDecorator
              label="Document Source"
              info={getInputInfos('new-document', 'document-sources')}
              required={true}
              fluentComponent={FluentUIDecoratorTypes.Dropdown({
                selectedKey: metadata?.documentSource,
                options: docSourceOptions,
                onChange: (e, o: any) => onFixedFieldChange('documentSource', o.key),
              })}
            />
          </div>
        )}

        <div style={{height: '1em'}} />

        <FluentUIDecorator
          label="Tags"
          info={getInputInfos('new-document', 'tags')}
          fluentComponent={FluentUIDecoratorTypes.TagPicker({
            inputProps: {id: 'tagPicker'},
            onResolveSuggestions: filterSelectedTags,
            selectedItems: getTags(),
            onChange: (t) => {
              if (t) {
                let tags = t.map((tag) => tag.name);
                if (tags.length > 0) {
                  setSuggestedTags([]);
                  ApiService.TagsController.addTag(tags[tags.length - 1], () => {});
                }
                onFixedFieldChange('tags', tags);
              }
            },
          })}
        />

        {canSuperseded && (
          <div>
            <div style={{height: '1em'}} />
            <FluentUIDecorator
              label="Replace Documents (Supersed Documents)"
              info={getInputInfos('new-document', 'document-picker-superseed')}
              fluentComponent={null}
              directComponentInjection={
                <DocumentPickerInput
                  activeLibrary={selectedLibrary}
                  selectedItems={metadata?.relatedDocuments}
                  onChange={(i) => onFixedFieldChange('relatedDocuments', i)}
                  modalTitle="Replace Documents (Supersed Documents)"
                  modalLabel="Replace Documents"
                />
              }
            />
          </div>
        )}

        {canRelatedTo && (
          <div>
            <div style={{height: '1em'}} />
            <FluentUIDecorator
              label="Related Documents"
              info={getInputInfos('new-document', 'document-picker-related')}
              fluentComponent={null}
              directComponentInjection={
                <DocumentPickerInput
                  activeLibrary={selectedLibrary}
                  selectedItems={metadata?.linkedDocuments}
                  onChange={(i) => onFixedFieldChange('linkedDocuments', i)}
                  modalTitle="Search Related Documents"
                  modalLabel="Link Documents"
                />
              }
            />
          </div>
        )}

        <div style={{height: '1em'}} />
        <FluentUIDecorator
          label="Description"
          info={getInputInfos('new-document', 'description')}
          fluentComponent={FluentUIDecoratorTypes.TextField({
            multiline: true,
            maxLength: 600,
            rows: 3,
            value: metadata?.description ?? '',
            onChange: (e, t) => onFixedFieldChange('description', t),
          })}
        />

        <div style={{height: '1em'}} />
        <FluentUIDecorator
          label="Remarks"
          info={getInputInfos('new-document', 'remarks')}
          fluentComponent={FluentUIDecoratorTypes.TextField({
            multiline: true,
            maxLength: 600,
            rows: 3,
            value: metadata?.remarks ?? '',
            onChange: (e, t) => onFixedFieldChange('remarks', t),
          })}
        />

        {renderDynamicMetadataBlock(2)}

        <div>
          <hr style={{marginTop: '1em'}} />
          <div className="document-metadata-zone-title">
            <div>
              Define an Approval Workflow <VideoTutorialIcon page="new-document-workflow-create" style={{fontSize: '2em'}} />
            </div>
          </div>
          <Banner
            type={'warning'}
            message={
              <div>
                You must first upload a Main Document in order to define an <strong>Approval Workflow</strong>.
              </div>
            }
            enabled={!(document.mainFile && metadata)}
          />
          {document.mainFile && metadata && (
            <div>
              <Banner
                type={'warning'}
                message={
                  <div>
                    You cannot define an Approval Workflow for this document. Approvals workflows only support{' '}
                    <strong>Word, PowerPoint and PDF</strong> files.
                  </div>
                }
                enabled={!fileValidForApproval(document.mainFile.fileName)}
              />
              {fileValidForApproval(document.mainFile.fileName) && (
                <div>
                  <Banner
                    type={'warning'}
                    message={
                      <div>
                        You cannot edit this section on a document saved as <strong>Signed</strong>.
                      </div>
                    }
                    enabled={document.documentStatus === 1 && pageMode === 'edit'}
                  />
                  <Banner
                    type={'warning'}
                    message={
                      <div>
                        You cannot edit this section on a document saved as <strong>Completed</strong> for which an Approval Workflow is
                        already started.
                      </div>
                    }
                    enabled={workflow !== null && pageMode !== 'newversion'}
                  />
                  {approvalNeeded >= 0 && (
                    <WorkflowPeoplePicker
                      maxSteps={selectedLibrary.maxWorkflowSignersGroups}
                      blackList={[]}
                      allowedElementTypes={selectedLibrary.allowedTypesInWorkflow}
                      steps={metadata.signers ?? []}
                      signatureType={metadata.workflowSignatureType}
                      onPeopleChanged={(steps: IDocumentSignStep[]) => {
                        onFixedFieldChange('signers', steps);
                        dispatch(GenericActions.setUploadApprovalFlag(steps.length));
                      }}
                      onSignatureTypeChanged={(signatureType: EDocumentSignatureType) => {
                        onFixedFieldChange('workflowSignatureType', signatureType);
                      }}
                      disabled={(document.documentStatus === 1 && pageMode === 'edit') || (workflow !== null && pageMode !== 'newversion')}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="nav-button-zone">
        <div />
        <LabelButton
          disabled={!(metadata && metadata.professionalArea)}
          text='Next'
          icon='ChromeBackMirrored'
          orangeSolid
          onClick={() => goNext(AppRoutes.DOCUMENT_CREATION_ROUTE_BO)}
        />
      </div>
      <ModalDialog
        modalTitle={'Warning'}
        modalInnerComponent={
          <div>
            Changing the <b>Professional Area</b> field will reset the following fields of the form:
            <br />
            <br />
            <ul>
              <li>Countries</li>
              <li>Replace Documents</li>
              <li>Related Documents</li>
              <li>Business Objects</li>
              <li>Doc. Type / Doc. SubType</li>
              <li>Whitelist</li>
              <li>Workflow Steps</li>
              <li>Co Owners</li>
              <li>Uploaded Files (if the target professional area doesn't support the uploaded format)</li>
            </ul>
            Are you sure you want to proceed?
          </div>
        }
        enableModal={profAreaChangeOutsideLibrary != undefined}
        onAbort={() => {
          setProfAreaChangeOutsideLibrary(undefined);
        }}
        onAccept={() => {
          setProfessionalArea(profAreaChangeOutsideLibrary, "OUTSIDE_LIBRARY");
          setProfAreaChangeOutsideLibrary(undefined);
        }}
      />
      <ModalDialog
        modalTitle={'Warning'}
        modalInnerComponent={
          <div>
            Changing the <b>Professional Area</b> field will reset the following fields of the form:
            <br />
            <br />
            <ul>
              <li>Doc. Type / Doc. SubType</li>
              <li>Whitelist</li>
              <li>Workflow Steps</li>
              <li>Co Owners</li>
            </ul>
            Are you sure you want to proceed?
          </div>
        }
        enableModal={profAreaChangeWithinLibrary != undefined}
        onAbort={() => {
          setProfAreaChangeWithinLibrary(undefined);
        }}
        onAccept={() => {
          setProfessionalArea(profAreaChangeWithinLibrary, "WITHIN_LIBRARY");
          setProfAreaChangeWithinLibrary(undefined);
        }}
      />
      <ModalDialog
        modalTitle={'Warning'}
        modalInnerComponent={
          <div>
            Changing the {!showDocumentSubTypesAsTypes(activeLibrary) ? 'Document Sub-Type' : 'Document Type'} right now
            will reset the
            Whitelist and Co Owners fields.
            <br />
            <br />
            Are you sure you want to proceed?
          </div>
        }
        enableModal={docTypeConfirmPopupVisible}
        onAbort={() => {
          setDocTypeConfirmPopupVisible(false);
        }}
        onAccept={() => {
          handleSubDocTypeChanged(pendingDocType);
          setDocTypeConfirmPopupVisible(false);
        }}
      />
      <ModalDialog
        modalTitle={'Warning'}
        modalInnerComponent={
          <div>
            Changing the countries right now will reset the Co Owners field
            <br />
            <br />
            Are you sure you want to proceed?
          </div>
        }
        enableModal={countriesConfirmPopupVisible}
        onAbort={() => {
          setCountriesConfirmPopupVisible(false);
        }}
        onAccept={() => {
          const newCountries = getCountries(pendingCountries);
          onMultipleFixedFieldChange(['countries', 'coOwners'], [newCountries, [...metadata.existingCoOwners]]);
          setCountriesConfirmPopupVisible(false);
        }}
      />
    </div>
  );
};
