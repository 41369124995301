import {ACTIVITY_URLS} from '../../Constants/Urls';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const ActivityController = {
  getUserActivities: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = ACTIVITY_URLS.GET_ACTIVITIES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocumentActivities: async (referenceId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${ACTIVITY_URLS.GET_ACTIVITIES_BY_REF}/${referenceId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default ActivityController;
