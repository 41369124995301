import {IDocumentItem} from '../../Models/IDocument';
import Icons from '../../Utils/FabricIconsOutlet';
import './StatusBubble.scss';

export const capitalizeText = (text: string) => {
  if (!text || text === '') {
    return text;
  }
  return text.charAt(0).toLocaleUpperCase() + text.substring(1);
};

export const getStatusLabel = (status: number, capitalize = false) => {
  let name = '';
  if (status === 0) {
    name = 'draft';
  }
  if (status === 1) {
    name = 'signed';
  }
  if (status === 2) {
    name = 'pending';
  }
  if (status === 3) {
    name = 'rejected';
  }
  if (status === 4) {
    name = 'superseded';
  }
  if (status === 5) {
    name = 'complete';
  }

  if (capitalize) {
    name = capitalizeText(name);
  }
  return name;
};

export const getStatusIconName = (status: number) => {
  if (status === 0) {
    return 'PageEdit';
  }
  if (status === 1) {
    return 'PenWorkspace';
  }
  if (status === 2) {
    return 'ReminderTime';
  }
  if (status === 3) {
    return 'Blocked12';
  }
  if (status === 4) {
    return 'Certificate';
  }
  if (status === 5) {
    return 'DocumentApproval';
  }

  return '';
};

export const getStatusIcon = (status: number) => {
  return Icons.getIcon(getStatusIconName(status), '1em');
};

export interface StatusBubbleProps {
  documentStatus: number;
  isOldVersion: boolean;
}

const StatusBubble = (props: StatusBubbleProps) => {
  let statusLabel = getStatusLabel(props.documentStatus);
  return (
    <div
      style={{opacity: props.isOldVersion ? '0.5' : '1'}}
      className={'status-bubble-main-wrap ' + ([0, 2].indexOf(props.documentStatus) !== -1 ? 'add-border' : 'no-border')}>
      <div className={'status-bubble-inner status-bubble-' + statusLabel}>
        <div className="status-bubble-icon">{getStatusIcon(props.documentStatus)}</div>
        <div className="status-bubble-text">{capitalizeText(statusLabel)}</div>
      </div>
    </div>
  );
};

export default StatusBubble;
