import {IDocumentItem, IDocumentQueryDocument} from '../../Models/IDocument';
import {SEARCH_BASE_PAGINATION_SIZE} from '../../Constants/Constants';

export interface DocumentSearchState {
  documents: IDocumentItem[];
  favoriteDocuments: IDocumentItem[];
  activeDocument: IDocumentItem | null;
  activeDocuments: IDocumentItem[];
  activeSearchResults: IDocumentQueryDocument[];
  resultsLimit: number;
  scrollPosition?: number;
  currentSearchMaxResults: number;
}

export const DOCUMENT_SEARCH_DEFAULT_STATE: DocumentSearchState = {
  documents: [],
  favoriteDocuments: [],
  activeDocument: null,
  activeDocuments: [],
  activeSearchResults: [],
  resultsLimit: SEARCH_BASE_PAGINATION_SIZE,
  scrollPosition: null,
  currentSearchMaxResults: 0,
};
