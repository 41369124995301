import {Banner, FileList as FileListC, LabelButton, MenuTabs, ModalDialog} from '@Eni/docware-fe-master';
import './NewLibElementTypes.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IElementTypes, ILibraryNewLib, LibraryFunctions} from '../../../Models/ILibrary';
import {SelectionMode} from '@fluentui/react';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {getItemWithDefaultsValues, ModalMode} from '../NewLibPage';
import NavButtons from '../../../Components/NavButtons/NavButtons';

interface NewLibElementTypesProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
  selectedElementType: IElementTypes;
  setSelectedElementType: Dispatch<SetStateAction<IElementTypes>>;
  showEditModal: boolean;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  existingElementTypes: string[];
}

const NewLibElementTypes = (props: NewLibElementTypesProps) => {
  const navigate = useNavigate();
  const [newElementType, setNewElementType] = useState<IElementTypes>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <LabelButton
          text="Add"
          icon="Add"
          onClick={() => {
            setShowCreateModal(true);
          }}
        />
        <FileListC
          defaultSortOnThisColumnIndex={0}
          fileIconFromField={''}
          onItemsSelected={(selectedRows: IElementTypes[]) => {
            if (selectedRows.length === 0) {
              props.setSelectedElementType(undefined);
            }
            if (selectedRows.length === 1) {
              props.setSelectedElementType(selectedRows[0]);
            }
          }}
          currentSelectedItems={undefined}
          currentSelectedItem={props.selectedElementType}
          hideBottomButton={true}
          selectionMode={SelectionMode.single}
          tableName={'normal'}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'name',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Is Admin',
              dataType: 'string',
              fieldName: 'isAdmin',
              iconName: null,
              size: 'large',
              onRender: (item: IElementTypes) => {
                return item.isAdmin?.toString();
              },
            },
            {
              name: 'Is Manually Managed',
              dataType: 'boolean',
              fieldName: 'isManuallyManaged',
              iconName: null,
              size: 'large',
              onRender: (item: IElementTypes) => {
                return item.isManuallyManaged?.toString();
              },
            },
          ]}
          items={props.currentLibrary?.elementTypes ?? []}
        />
      </div>

      <NavButtons
        onBackClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS)}
        onNextClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES)}
      />

      <ModalElementType
        mode={ModalMode.Edit}
        elementType={props.selectedElementType}
        setElementType={props.setSelectedElementType}
        showModal={props.showEditModal}
        setShowModal={props.setShowEditModal}
        existingElementTypes={props.existingElementTypes}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const updatedElementTypes = props.currentLibrary?.elementTypes.map((x) =>
            x.id == props.selectedElementType.id ? props.selectedElementType : x
          );
          props.updateLibrary('elementTypes', updatedElementTypes);
        }}
      />

      <ModalElementType
        mode={ModalMode.Create}
        elementType={newElementType}
        setElementType={setNewElementType}
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        existingElementTypes={props.existingElementTypes}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const emptyElementType: IElementTypes = {
            id: crypto.randomUUID(),
            name: '',
            isAdmin: false,
            isManuallyManaged: false,
            addedInThisSession: true,
          };
          const elementType = getItemWithDefaultsValues(emptyElementType, newElementType);

          props.updateLibrary('elementTypes', [...(props.currentLibrary?.elementTypes ?? []), elementType]);
          setNewElementType(undefined);
        }}
      />
    </>
  );
};

interface ModalElementTypeProps {
  mode: ModalMode;
  elementType: IElementTypes;
  setElementType: Dispatch<SetStateAction<IElementTypes>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  existingElementTypes: string[];
  currentLibrary: ILibraryNewLib;
  onConfirm: Function;
}

const ModalElementType = (props: ModalElementTypeProps) => {
  const fieldDisabled = props.mode == ModalMode.Edit && !props.elementType?.addedInThisSession;
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  return (
    <ModalDialog
      modalTitle={`${props.mode == ModalMode.Create ? 'Create' : 'Edit'} Element Type`}
      modalInnerComponent={
        <>
          <FluentUIDecorator
            label="Name"
            info={getInputInfos('new-lib', 'element-type-name')}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              disabled: fieldDisabled,
              value: props.elementType?.name,
              onChange: (e, currentValue) => props.setElementType({...props.elementType, name: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Is Admin"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'element-type-is-admin')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              disabled: fieldDisabled,
              defaultChecked: props.elementType?.isAdmin,
              onChange: (e, currentValue) => props.setElementType({...props.elementType, isAdmin: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Is Manually Managed"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'element-type-is-manually-managed')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              defaultChecked: props.elementType?.isManuallyManaged,
              onChange: (e, currentValue) => props.setElementType({...props.elementType, isManuallyManaged: currentValue}),
            })}
          />
          <Banner
            enabled={showErrorBanner}
            type="error"
            message={"The Element Type name must be the unique across all libraries and can't be blank"}
          />
        </>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            props.setShowModal(false);
          },
        },
        {
          label: 'Confirm',
          onClick: () => {
            if (props.mode === ModalMode.Create) {
              const elementTypeName = props.elementType?.name.replace(/^\s+|\s+$/gm, '');
              const currentElementTypes = LibraryFunctions.getElementTypesNames([props.currentLibrary]);
              if (
                !elementTypeName ||
                props.existingElementTypes.includes(elementTypeName) ||
                currentElementTypes.includes(elementTypeName)
              ) {
                setShowErrorBanner(true);
                return;
              }
            }
            props.onConfirm();
            props.setShowModal(false);
          },
        },
      ]}
      onAbort={() => {
        props.setShowModal(false);
      }}
      enableModal={props.showModal}
    />
  );
};

export default NewLibElementTypes;
