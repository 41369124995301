import {Banner, LabelButton} from '@Eni/docware-fe-master';
import {ITag} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {HierarchyLibrary, IBoLibrary, ILibrary, IMetadata} from '../../Models/ILibrary';
import {IValue} from '../../Models/IValue';
import './BOsPicker.scss';
import BOsPickerRow from './BOsPickerRow';
import {IBoRow} from './Model';
import {mergeIBoRow} from './Utils';
import {BOsObject} from '../../Models/BusinessObject';

export interface BOsPickerProps {
  initialState?: BOsObject;
  updateInnerBos?: BOsObject;
  boFromselectedLibrary: IBoLibrary[] | null;
  countries: string[];
  libraries?: HierarchyLibrary[];
  tightBosText?: JSX.Element | string;
  tightBos?: string[];
  verticalDisplay?: boolean;
  onChange: (object: BOsObject) => void;
  hideNoBoSelectedMessage?: boolean;
}

export const BOsPicker = (props: BOsPickerProps) => {
  const [lockReinit, setLockReinit] = useState<boolean>(false);
  const [bosRowsInner, setBosRowsInner] = useState<(IBoRow | undefined)[]>([]);
  const [boMetadataList, setBoMetadataList] = useState<IMetadata[]>([]);

  const emitState = (useRows: (IBoRow | undefined)[]) => {
    const data: BOsObject = {};
    for (const row of useRows) {
      if (row) {
        const key = row.data.source;
        const value = row.values;
        data[key] = value;
      }
    }
    props.onChange(data);
  };

  const setBosRowsAndEmit = (rows: (IBoRow | undefined)[]) => {
    setLockReinit(true);
    emitState(rows);
    setBosRowsInner(rows);
  };

  const onValuesChange = (source: any, valuesToProcess: ITag[], index: number) => {
    let rows = [...bosRowsInner];
    let tagToValues = valuesToProcess.map((v) => {
      return {id: v.key, name: v.name};
    }) as IValue[];
    rows[index] = {...source};
    rows[index]['values'] = tagToValues;
    setBosRowsAndEmit(rows);
  };

  const onAddRow = () => {
    const bos = [...bosRowsInner];
    bos.push(null);
    setBosRowsAndEmit(bos);
  };

  const onDeleteRow = (index: number) => {
    let rows = [...bosRowsInner];
    rows.splice(index, 1);
    setBosRowsAndEmit(rows);
  };

  function getBosMetadataAndBosRowsInner(baseBos: IBoLibrary[], bos: BOsObject): {boMetadataList: IMetadata[]; bosRowsInner: IBoRow[]} {
    const boMetadataList = [];
    const bosRowsInner: IBoRow[] = [];

    for (const baseBo of baseBos) {
      baseBo['values'] = [];
      boMetadataList.push(baseBo);
      if (Object.keys(bos).indexOf(baseBo.name) !== -1) {
        bosRowsInner.push({
          key: baseBo.source,
          text: baseBo.displayName,
          data: baseBo,
          values: bos[baseBo.name],
        });
      }
    }

    return {
      boMetadataList: boMetadataList,
      bosRowsInner: bosRowsInner,
    };
  }

  useEffect(() => {
    if (props.boFromselectedLibrary == null) {
      return;
    }

    const baseBos = props.boFromselectedLibrary;
    const initialState = props.initialState ? {...props.initialState} : {};

    if (baseBos) {
      const {boMetadataList: bos, bosRowsInner: initialRows} = getBosMetadataAndBosRowsInner(baseBos, initialState);

      setBoMetadataList(bos);
      if (bosRowsInner.length === 0 && initialRows.length > 0 && !lockReinit) {
        setBosRowsInner(initialRows);
      }
    }
  }, [props.boFromselectedLibrary, props.initialState]);

  useEffect(() => {
    if (props.updateInnerBos) {
      const updateInnerBos = {...props.updateInnerBos};
      const baseBos = props.boFromselectedLibrary;
      if (baseBos) {
        const {bosRowsInner: bosRowsInnerFromUpdate} = getBosMetadataAndBosRowsInner(baseBos, updateInnerBos);
        const merged = mergeIBoRow(
          bosRowsInner.filter((x) => x),
          bosRowsInnerFromUpdate
        );
        setBosRowsInner(merged);
      }
    }
  }, [props.updateInnerBos]);

  let availableBos = boMetadataList.filter((x: any) => x.isLegacyBusinessObject !== true);
  let availableMetadata = availableBos.filter(
    (x: any) => bosRowsInner.filter((x) => x != null).filter((y: any) => y.data.source === x.source).length === 0
  );
  let availableMetadataAsOptions = availableMetadata.map((m: any, i) => {
    return {key: m.source, text: m.displayName, data: m, values: []};
  });

  return (
    <div className="bos-picker-main-wrap">
      <Banner
        type={'warning'}
        message={
          <div>
            You must select a <strong>Professional Area</strong> before being able to select any Business Object.
          </div>
        }
        enabled={props.boFromselectedLibrary === null}
      />
      {props.boFromselectedLibrary !== null && (
        <div className="bos-picker-inner-wrap">
          {(bosRowsInner.length === 0 && !props.hideNoBoSelectedMessage) && <div className="bos-picker-select-one">No Business Object selected</div>}
          {bosRowsInner.map((metadata: IBoRow, i: number) => {
            return (
              <BOsPickerRow
                tightBosText={props.tightBosText ?? ''}
                tightBos={props.tightBos ?? []}
                verticalDisplay={props.verticalDisplay}
                metadataOptions={availableMetadataAsOptions}
                lockOption={
                  metadata === null
                    ? ''
                    : metadata.data.isLegacyBusinessObject
                    ? 'This row cannot be edited. This Business Object is a Legacy Business Object.'
                    : ''
                }
                defaultMetadataKey={metadata ? {...metadata} : null}
                countries={props.countries}
                libraries={props.libraries}
                onValuesChange={(source: any, valuesToProcess: ITag[]) => {
                  onValuesChange(source, valuesToProcess, i);
                }}
                onDeleteRow={onDeleteRow}
                values={metadata ? metadata['values'] ?? [] : []}
                index={i}
                key={i}
              />
            );
          })}
          <div className="bos-picker-row">
            {availableBos.length > bosRowsInner.filter((x: any) => (x === null ? true : x.data.isLegacyBusinessObject !== true)).length &&
              availableMetadataAsOptions.length > 0 && (
                <LabelButton
                  whiteOutlined
                  icon={'Add'}
                  text="Add Business Object"
                  onClick={() => {
                    onAddRow();
                  }}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BOsPicker;
