interface LocallyStored<T> {
  expiresAt: number;
  data: T;
}

/**
 * Helper class to store data in the local storage with automatic retrieval (e.g. from the BE)
 * when the time expires
 */
export class CachedData<T> {
  private readonly expiresIn: number;
  private readonly innerGetter: () => Promise<T>;
  private readonly cacheKey: string;

  /**
   * Use the innerGetter to get the new data,
   * stores it in the local storage
   * and returns it
   */
  private async localStorageSetter(): Promise<T> {
    const data = await this.innerGetter();
    const timestamp = new Date().getTime();

    const tmp: LocallyStored<T> = {
      expiresAt: timestamp + this.expiresIn * 1000,
      data: data,
    };

    localStorage.setItem(this.cacheKey, JSON.stringify(tmp));

    return data;
  }

  /**
   *
   * @param cacheKey The local storage key where to store the data
   * @param innerGetter Function to get the data (e.g. a call to the BE)
   * @param expiresIn The ttl of the data. In seconds. The data is cached for this time interval, after that the `innerGetter` function is called again
   */
  constructor(cacheKey: string, innerGetter: () => Promise<T>, expiresIn: number) {
    this.cacheKey = cacheKey;
    this.innerGetter = innerGetter;
    this.expiresIn = expiresIn;
  }

  /**
   * Gets the data from the local storage or from the `innerGetter` if the ttl is expired
   */
  get(): Promise<T> {
    const tmp: LocallyStored<T> = JSON.parse(localStorage.getItem(this.cacheKey));

    // If there is no data in the cache or it's expired, get new values
    if (!tmp || new Date().getTime() >= tmp.expiresAt) {
      return this.localStorageSetter();
    } else {
      return Promise.resolve(tmp.data);
    }
  }
}
