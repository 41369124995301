export interface IFilePage {
  files: IMIFile[];
  activeFiles: IMIFile[];
}

export interface IMIFile {
  id: string;
  fileName: string;
  documentSize: number;
  extension: string;
  creationDate: string;
  timeToLive: number;
  status: IMIFileStatus;
  file: File;
}

export enum IMIFileStatus {
  NotMatching = 0,
  Matching = 1,
  Upload = 2,
  GenericError = 3,
  DuplicatedFile = 4,
}

export const MIFileStatusVisualization = [
  {key: '0', text: 'Not Matching'},
  {key: '1', text: 'Matching'},
  {key: '2', text: 'Uploading'},
  {key: '3', text: 'Error'},
  {key: '4', text: 'Duplicated File'},
];
