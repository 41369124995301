import {NewItem, ESeverity} from '../../Models/New';
import {Stack, MessageBar, MessageBarType, IStackProps} from '@fluentui/react';

export interface INewBarProps {
  title?: string;
  description: string;
  severity: string;
  className?: string;
}

interface INewsColumnProps {
  news: NewItem[];
}

const verticalStackProps: IStackProps = {
  grow: true,
  styles: {root: {overflow: 'hidden', width: '100%'}},
  tokens: {childrenGap: 20},
};

export const NewBar = (props: INewBarProps) => (
  <MessageBar className={props.className ? props.className : 'single-new-label'} messageBarType={MessageBarType[props.severity]}>
    {props.title && (
      <div>
        <b>{props.title}</b>
        <br></br>
        {props.description}
      </div>
    )}
    {!props.title && <div>{props.description}</div>}
  </MessageBar>
);

export const NewsColumn = (props: INewsColumnProps) => {
  return (
    <div className="news-column">
      <Stack {...verticalStackProps}>
        {props.news.map((new_item) => {
          return (
            <div key={new_item.id}>
              <NewBar title={new_item.title} description={new_item.description} severity={ESeverity[new_item.severity]} />
            </div>
          );
        })}
      </Stack>
    </div>
  );
};
