import {SIGNATURE_URLS} from '../../Constants/Urls';
import {FileSignaturePayload} from '../../Models/Signature';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';
//available-certificates
const SignatureController = {
  getAvailableSignatures: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = SIGNATURE_URLS.SIGNATURES_TYPES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  getImage: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = SIGNATURE_URLS.GET_TIMESTAMPS;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getOTP: async (pin: string, certificateType: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = SIGNATURE_URLS.GET_OTP;
    request.method = 'POST';
    request.payload = {pin: pin, certificateType: certificateType};
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  signDocument: async (payload: FileSignaturePayload, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = SIGNATURE_URLS.SIGN_DOCUMENT;
    request.method = 'POST';
    request.payload = payload;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },

  adminGetAvailableCertificates: async (UserId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = SIGNATURE_URLS.GET_USER_AVAILABLE_CERTIFICATES;
    request.payload = {UserId};
    request.method = 'POST';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default SignatureController;
