import {Route, Routes, useNavigate, useSearchParams} from 'react-router-dom';
import {getTrackingComponent} from '../../AppInsights';
import AppRoutes, {concatUrl} from '../../Utils/AppRoutes';

import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import './NewLibPage.scss';
import React, {useEffect, useState} from 'react';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import NewLibGeneralInfo from './NewLibGeneralInfo/NewLibGeneralInfo';
import NewLibProfessionalAreas from './NewLibProfessionalAreas/NewLibProfessionalAreas';
import NewLibElementTypes from './NewLibElementTypes/NewLibElementTypes';
import NewLibDocumentTypes from './NewLibDocumentTypes/NewLibDocumentTypes';
import NewLibDocumentSubTypes from './NewLibDocumentSubTypes/NewLibDocumentSubTypes';
import NewLibBusinessObject from './NewLibBusinessObject/NewLibBusinessObject';
import NewLibExtraMetadata from './NewLibExtraMetadata/NewLibExtraMetadata';
import {
  IElementTypes,
  ILibrary,
  ILibraryDocumentSubTypeNewLib,
  ILibraryDocumentType,
  ILibraryNewLib,
  ILibrarySummary,
  IMetadata,
  IProfessionalArea,
  LibraryFunctions,
} from '../../Models/ILibrary';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/AjaxService';
import {Spinner} from '@fluentui/react';
import {InformationPanel} from '../../Components/InformationPanel/InformationPanel';

export enum ModalMode {
  Create,
  Edit,
}

export const getItemWithDefaultsValues = (itemDefaultsValues: object, item: {name: string}): {name: string} => {
  let newItem;
  // delete spaces if the user has selected the item
  if (item?.name) {
    const profAreaWithoutSpace = {...item, name: item.name.replace(/^\s+|\s+$/gm, '')};
    newItem = {...itemDefaultsValues, ...profAreaWithoutSpace};
  } else {
    newItem = {...itemDefaultsValues, ...item};
  }
  return newItem;
};

const NewLibPage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'NewLibPage'}
  );
  const navigate = useNavigate();
  const [libraries, setLibraries] = useState<ILibrary[]>();
  const [currentLibrary, setCurrentLibrary] = useState<ILibraryNewLib>();
  const [createMode, setCreateMode] = useState<boolean>();
  const [librarySummary, setLibrarySummary] = useState<ILibrarySummary[]>();
  const [selectedProfessionalArea, setSelectedProfessionalArea] = useState<IProfessionalArea>();
  const [selectedElementType, setSelectedElementType] = useState<IElementTypes>();
  const [selectedDocumentType, setSelectedDocumentType] = useState<ILibraryDocumentType>();
  const [selectedDocumentSubType, setSelectedDocumentSubType] = useState<ILibraryDocumentSubTypeNewLib>();
  const [selectedMetadata, setSelectedMetadata] = useState<IMetadata>();
  const [showProfessionalAreaEditModal, setShowProfessionalAreaEditModal] = useState<boolean>(false);
  const [showElementTypeEditModal, setShowElementTypeEditModal] = useState<boolean>(false);
  const [showDocumentTypeEditModal, setShowDocumentTypeEditModal] = useState<boolean>(false);
  const [showDocumentSubTypeEditModal, setShowDocumentSubTypeEditModal] = useState<boolean>(false);
  const [showMetadataEditModal, setShowMetadataEditModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [params] = useSearchParams();

  const libraryId = params.get('libraryId');

  useEffect(() => {
    ApiService.LibraryController.getAllLibraries((response: IAPIResponse) => {
      if (response.error === null) {
        setLibraries(response.payload as ILibrary[]);
        const library: ILibrary | null = (response.payload as ILibrary[]).filter((x) => x.id === libraryId)[0];

        if (library) {
          setCreateMode(false);
          const libraryNewLib = LibraryFunctions.getILibraryNewLibFromILibrary(library);
          setCurrentLibrary(libraryNewLib);
        } else {
          setCreateMode(true);
          setCurrentLibrary({} as ILibraryNewLib);
        }
        setLoading(false);
      }
    });

    if(libraryId) {
      ApiService.LibraryController.getLibrarySummary(libraryId, (response: IAPIResponse) => {
        if (response.error == null) {
          setLibrarySummary(response.payload as ILibrarySummary[]);
        }
      });
    }

  }, [libraryId]);

  const goToPage = (route: string) => {
    setSelectedProfessionalArea(undefined);
    setSelectedElementType(undefined);
    setSelectedDocumentType(undefined);
    setSelectedDocumentSubType(undefined);
    setSelectedMetadata(undefined);
    navigate(concatUrl(AppRoutes.NEW_LIB_PAGE, route) + window.location.search);
  };

  const updateFieldCurrentLibrary = (key: string, value: any) => {
    let library = {...currentLibrary};
    library[key] = value;
    setCurrentLibrary(library);
  };

  const getToolbar = () => {
    if (selectedProfessionalArea) {
      return (
        <DriveDocumentManagementToolbar
          type={''}
          customLayout={{
            name: 'xxx',
            content: {
              leftButtons: [
                {
                  text: 'Edit',
                  iconName: 'Edit',
                  disabled: false,
                  onClick: () => {
                    setShowProfessionalAreaEditModal(true);
                  },
                },
                {
                  text: 'Delete',
                  iconName: 'Delete',
                  disabled: !selectedProfessionalArea.addedInThisSession,
                  onClick: () => {
                    updateFieldCurrentLibrary(
                      'professionalAreas',
                      currentLibrary.professionalAreas.filter((x) => x.id != selectedProfessionalArea.id)
                    );
                    setSelectedProfessionalArea(undefined);
                  },
                },
              ],
              rightButtons: [
                {
                  text: '1 Item selected',
                  iconName: 'Cancel',
                  onClick: () => {
                    setSelectedProfessionalArea(undefined);
                    sessionStorage.removeItem('file-table-last-selection');
                  },
                },
              ],
            },
          }}
        />
      );
    }
    if (selectedElementType) {
      return (
        <DriveDocumentManagementToolbar
          type={''}
          customLayout={{
            name: 'xxx',
            content: {
              leftButtons: [
                {
                  text: 'Edit',
                  iconName: 'Edit',
                  disabled: !selectedElementType.addedInThisSession,
                  onClick: () => {
                    setShowElementTypeEditModal(true);
                  },
                },
                {
                  text: 'Delete',
                  iconName: 'Delete',
                  disabled: !selectedElementType.addedInThisSession,
                  onClick: () => {
                    updateFieldCurrentLibrary(
                      'elementTypes',
                      currentLibrary.elementTypes.filter((x) => x.id != selectedElementType.id)
                    );
                    setSelectedElementType(undefined);
                  },
                },
              ],
              rightButtons: [
                {
                  text: '1 Item selected',
                  iconName: 'Cancel',
                  onClick: () => {
                    setSelectedElementType(undefined);
                    sessionStorage.removeItem('file-table-last-selection');
                  },
                },
              ],
            },
          }}
        />
      );
    }
    if (selectedDocumentType) {
      return (
        <DriveDocumentManagementToolbar
          type={''}
          customLayout={{
            name: 'xxx',
            content: {
              leftButtons: [
                {
                  text: 'Edit',
                  iconName: 'Edit',
                  disabled: false,
                  onClick: () => {
                    setShowDocumentTypeEditModal(true);
                  },
                },
                {
                  text: 'Delete',
                  iconName: 'Delete',
                  disabled: !selectedDocumentType.addedInThisSession,
                  onClick: () => {
                    setCurrentLibrary({
                      ...currentLibrary,
                      documentTypes: currentLibrary.documentTypes.filter((x) => x.id != selectedDocumentType.id),
                      documentSubTypes: currentLibrary.documentSubTypes?.filter((x) => x.documentType != selectedDocumentType.name),
                    });
                    setSelectedDocumentType(undefined);
                  },
                },
              ],
              rightButtons: [
                {
                  text: '1 Item selected',
                  iconName: 'Cancel',
                  onClick: () => {
                    setSelectedDocumentType(undefined);
                    sessionStorage.removeItem('file-table-last-selection');
                  },
                },
              ],
            },
          }}
        />
      );
    }
    if (selectedDocumentSubType) {
      return (
        <DriveDocumentManagementToolbar
          type={''}
          customLayout={{
            name: 'xxx',
            content: {
              leftButtons: [
                {
                  text: 'Edit',
                  iconName: 'Edit',
                  disabled: false,
                  onClick: () => {
                    setShowDocumentSubTypeEditModal(true);
                  },
                },
                {
                  text: 'Delete',
                  iconName: 'Delete',
                  disabled: !selectedDocumentSubType.addedInThisSession,
                  onClick: () => {
                    updateFieldCurrentLibrary(
                      'documentSubTypes',
                      currentLibrary.documentSubTypes.filter((x) => x.id != selectedDocumentSubType.id)
                    );
                    setSelectedDocumentSubType(undefined);
                  },
                },
              ],
              rightButtons: [
                {
                  text: '1 Item selected',
                  iconName: 'Cancel',
                  onClick: () => {
                    setSelectedDocumentSubType(undefined);
                    sessionStorage.removeItem('file-table-last-selection');
                  },
                },
              ],
            },
          }}
        />
      );
    }
    if (selectedMetadata) {
      return (
        <DriveDocumentManagementToolbar
          type={''}
          customLayout={{
            name: 'xxx',
            content: {
              leftButtons: [
                {
                  text: 'Edit',
                  iconName: 'Edit',
                  disabled: false,
                  onClick: () => {
                    setShowMetadataEditModal(true);
                  },
                },
                {
                  text: 'Delete',
                  iconName: 'Delete',
                  disabled: !selectedMetadata.addedInThisSession,
                  onClick: () => {
                    updateFieldCurrentLibrary(
                      'metadata',
                      currentLibrary.metadata.filter((x) => x.name != selectedMetadata.name)
                    );
                    setSelectedMetadata(undefined);
                  },
                },
              ],
              rightButtons: [
                {
                  text: '1 Item selected',
                  iconName: 'Cancel',
                  onClick: () => {
                    setSelectedMetadata(undefined);
                    sessionStorage.removeItem('file-table-last-selection');
                  },
                },
              ],
            },
          }}
        />
      );
    }

    return <DriveDocumentManagementToolbar type={'navigation-voices'} />;
  };

  return (
    <>
      <div className="general-page-container">
        {getToolbar()}
        {loading ? (
          <Spinner label="Loading..." />
        ) : (
          <div className="new-lib-inner-section" data-is-scrollable="true">
            <div className="new-lib-header">
              <div className="new-lib-title">
                {createMode ? 'Create Library' : `Edit Library: ${currentLibrary.name}`}
                <VideoTutorialIcon />
              </div>
              <div className="new-lib-subtitle">Create or edit a Docware Drive Library</div>
            </div>

            <div className="new-lib-content flex-column-on-small-screen">
              <div className="new-lib-left-section">
                <section>
                  <div className="new-lib-section-title">LIBRARY INFORMATION</div>
                  <div className="new-lib-summary">
                    {librarySummary ? (
                      <InformationPanel
                        rows={librarySummary.map((profArea) => ({
                          header: (
                            <span>
                              <b>Professional area:</b> {profArea.professionalAreaName}
                            </span>
                          ),
                          content: (
                            <div>
                              <b>Main files</b>
                              <div className="new-lib-summary-prof-areas-stats">
                                <span>
                                  <b>Number:</b> {profArea.mainFilesCount}
                                </span>
                                <span>
                                  <b>Size:</b> {profArea.mainFilesSize}
                                </span>
                              </div>
                              <div style={{marginTop: '16px'}}>
                                <b>Attachments</b>
                                <div className="new-lib-summary-prof-areas-stats">
                                  <span>
                                    <b>Number:</b> {profArea.attachmentsCount}
                                  </span>
                                  <span>
                                    <b>Size:</b> {profArea.attachmentsSize}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ),
                        }))}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              </div>

              <div className="new-lib-right-section">
                <section style={{maxWidth: '75vw'}}>
                  <div className="new-lib-section-title">DETAILS</div>
                  <Routes>
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_GENERAL_INFO}
                      element={
                        <NewLibGeneralInfo
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                          securityLevels={LibraryFunctions.getSecurityLevels(libraries)}
                          availableSignatures={LibraryFunctions.getSignatures(libraries)}
                        />
                      }
                    />
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS}
                      element={
                        <NewLibProfessionalAreas
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                          selectedProfessionalArea={selectedProfessionalArea}
                          setSelectedProfessionalArea={setSelectedProfessionalArea}
                          showEditModal={showProfessionalAreaEditModal}
                          setShowEditModal={setShowProfessionalAreaEditModal}
                          existingProfessionalAreas={LibraryFunctions.getProfessionalAreasNames(libraries)}
                        />
                      }
                    />
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES}
                      element={
                        <NewLibElementTypes
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                          selectedElementType={selectedElementType}
                          setSelectedElementType={setSelectedElementType}
                          showEditModal={showElementTypeEditModal}
                          setShowEditModal={setShowElementTypeEditModal}
                          existingElementTypes={LibraryFunctions.getElementTypesNames(libraries)}
                        />
                      }
                    />
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES}
                      element={
                        <NewLibDocumentTypes
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                          selectedDocumentType={selectedDocumentType}
                          setSelectedDocumentType={setSelectedDocumentType}
                          showEditModal={showDocumentTypeEditModal}
                          setShowEditModal={setShowDocumentTypeEditModal}
                          existingDocumentTypes={LibraryFunctions.getDocumentTypesNames(libraries)}
                        />
                      }
                    />
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES}
                      element={
                        <NewLibDocumentSubTypes
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                          selectedDocumentSubType={selectedDocumentSubType}
                          setSelectedDocumentSubType={setSelectedDocumentSubType}
                          showEditModal={showDocumentSubTypeEditModal}
                          setShowEditModal={setShowDocumentSubTypeEditModal}
                          existingDocumentSubTypes={LibraryFunctions.getDocumentSubTypesNames(libraries)}
                        />
                      }
                    />
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT}
                      element={
                        <NewLibBusinessObject
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                        />
                      }
                    />
                    <Route
                      path={AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA}
                      element={
                        <NewLibExtraMetadata
                          currentLibrary={currentLibrary}
                          updateLibrary={updateFieldCurrentLibrary}
                          goToPage={goToPage}
                          selectedMetadata={selectedMetadata}
                          setSelectedMetadata={setSelectedMetadata}
                          showEditModal={showMetadataEditModal}
                          setShowEditModal={setShowMetadataEditModal}
                          existingMetadata={LibraryFunctions.getMetadataNames(libraries)}
                          createMode={createMode}
                        />
                      }
                    />
                  </Routes>
                </section>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default getTrackingComponent(NewLibPage, 'NewLibPage');
