export interface NewItem {
  id: string;
  title: string;
  description: string;
  insertDate: Date;
  dateRange: DateRange;
  severity: ESeverity;
}

export enum ESeverity {
  unset = -1,
  error = 0,
  severeWarning = 1,
  success = 2,
}

export interface DateRange {
  from: Date;
  to: Date;
}
