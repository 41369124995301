import {MenuTabs} from '@Eni/docware-fe-master';
import './NewLibBusinessObject.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ApiService from '../../../Services/ApiService';
import {IAPIResponse} from '../../../Services/AjaxService';
import {ILibraryNewLib} from '../../../Models/ILibrary';
import {
  FluentUIDecorator,
  FluentUIDecoratorTypes,
  getListOfSelectedKeysMultiSelect,
} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {IIntegrationBos} from '../../../Models/IIntegration';
import NavButtons from '../../../Components/NavButtons/NavButtons';

interface NewLibBusinessObjectProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
}

const NewLibBusinessObject = (props: NewLibBusinessObjectProps) => {
  const navigate = useNavigate();
  const [integrationBos, setIntegrationBos] = useState<IIntegrationBos>();

  useEffect(() => {
    ApiService.IntegrationsController.getIntegrationBo((response: IAPIResponse) => {
      console.log('pluto', response);
      if (response.error === null) {
        setIntegrationBos(response.payload as IIntegrationBos);
      }
    });
  }, []);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <FluentUIDecorator
          denyComponentHardReset={true}
          label={'Business Objects'}
          info={getInputInfos('new-lib', 'business-objects')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: '',
            multiSelect: true,
            options: integrationBos?.bOs.map((x) => {
              return {key: x.key, text: x.description};
            }),
            selectedKeys: props.currentLibrary?.bOs,
            onChange: (e, currentValue) => {
              const selectedKeys = props.currentLibrary?.bOs ?? [];
              const newValue = getListOfSelectedKeysMultiSelect(selectedKeys, currentValue);
              props.updateLibrary('bOs', newValue);
            },
          })}
        />
        <FluentUIDecorator
          denyComponentHardReset={true}
          label={'Business Objects Aggregated'}
          info={getInputInfos('new-lib', 'business-objects-aggregated')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: '',
            multiSelect: true,
            options: integrationBos?.aggregatedBOs.map((x) => {
              return {key: x.key, text: x.description};
            }),
            selectedKeys: props.currentLibrary?.boAggregations,
            onChange: (e, currentValue) => {
              const selectedKeys = props.currentLibrary?.boAggregations ?? [];
              const newValue = getListOfSelectedKeysMultiSelect(selectedKeys, currentValue);
              props.updateLibrary('boAggregations', newValue);
            },
          })}
        />
      </div>

      <NavButtons
        onBackClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES)}
        onNextClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA)}
      />
    </>
  );
};

export default NewLibBusinessObject;
