import './CountdownBox.scss';
import Countdown from 'react-countdown';
import {CountdownRenderProps} from 'react-countdown/dist/Countdown';
import * as React from 'react';
import {useEffect, useState} from 'react';

interface CountdownBoxProps {
  expiringDate: number;
  onComplete?: () => any;
}

export const CountdownBox = (props: CountdownBoxProps) => {
  const [hurryUp, setHurryUp] = useState<boolean>(false);
  const [expiringDate, setExpiringDate] = useState<number>(props.expiringDate);

  useEffect(() => {
    if (expiringDate != props.expiringDate) {
      setHurryUp(true);
      console.log('CountdownBox expiringDate', props.expiringDate);
    }

  }, [props.expiringDate]);

  const renderer = ({completed, formatted: {hours, minutes, seconds}}: CountdownRenderProps): React.ReactNode => {
    if (completed) {
      if (props.onComplete) {
        props.onComplete();
      }
    }
    return (
      <>
        <span className='countdown-text'>
          {hours}:{minutes}:{seconds}
        </span>
      </>
    );
  };

  return (
    <div className='countdown-box' style={hurryUp ? {background: 'red'} : {}}>
      {hurryUp &&
      <span className='countdown-message' style={{fontWeight: 'bold'}}>Hurry up the document is about to expire!</span>}
      <span className='countdown-message'>Remaining editing time</span>
      <Countdown date={props.expiringDate} renderer={renderer} key={props.expiringDate} />
    </div>
  );
};
