import {ITag} from '@fluentui/react';
import {IMetadataValue} from '../Models/IDocument';
import {MetadataType} from '../Models/ILibrary';
import {IOptionDto} from '../Models/IOptionDto';

const optionToTag = (option: IOptionDto) => {
  return {
    key: option.id,
    name: option.name,
  };
};

const metadataToTag = (metadata: IMetadataValue): ITag[] => {
  if (metadata.type === MetadataType.Select) {
    let option = metadata.selectValue as IOptionDto;
    return option
      ? [
          {
            key: option.id,
            name: option.name,
          },
        ]
      : [];
  } else if (metadata.type === MetadataType.MultiSelect) {
    let options = metadata.multiselectValue as IOptionDto[];
    if (options.length === 0) {
      return [];
    }
    return options.map((o) => {
      return {
        key: o.id,
        name: o.name,
      };
    });
  } else {
    return [
      /* {
            key: metadata.stringValue as string,
            name: metadata.stringValue as string
        } */
    ];
  }
};

const tagsToMetadata = (tags: ITag[], type: MetadataType): IMetadataValue | undefined => {
  if (type === MetadataType.Select) {
    return {
      type: type,
      selectValue: tags.map(tagToOption)[0],
    };
  } else if (type === MetadataType.MultiSelect) {
    return {
      type: type,
      multiselectValue: tags.map(tagToOption),
    };
  }
  return undefined;
};

const tagToOption = (tag: ITag) => {
  return {
    id: tag.key as string,
    name: tag.name,
  };
};

export const TagUtils = {
  optionToTag,
  metadataToTag,
  tagsToMetadata,
  tagToOption,
};
