import { LabelButton } from '@Eni/docware-fe-master';
import { IconButton, IPersonaProps } from '@fluentui/react';
import { EDocumentSignatureType, IDocumentSignStep } from '../../Models/IDocument';
import { IPersonaOptionDto } from '../../Models/IOptionDto';
import { getInputInfos } from '../../Reducers/Generic/GenericAction';
import { bonifyEmailField } from '../../Utils/DictUtils';
import { IntegrationUtils } from '../../Utils/IntegrationUtils';
import { optionsToPersonas, personasToOptions } from '../../Utils/NewDocumentUtils';
import { FluentUIDecorator, FluentUIDecoratorTypes } from '../FluentUIDecorator/FluentUIDecorator';
import './WorkflowPeoplePicker.scss';

export interface IWorkflowPeoplePicker {
  disabled: boolean;
  maxSteps: number;
  steps: IDocumentSignStep[];
  signatureType: EDocumentSignatureType;
  blackList: IPersonaProps[];
  allowedElementTypes: string[] | null;
  onPeopleChanged: (steps: IDocumentSignStep[]) => void;
  onSignatureTypeChanged: (signatureType: EDocumentSignatureType) => void;
}

export const WorkflowPeoplePicker = (props: IWorkflowPeoplePicker) => {
  const verifyElementTypesValidity = (accepted: string[], toTest: string[]) => {
    for (let i = 0; i < toTest.length; i++) {
      if (accepted.indexOf(toTest[i]) !== -1) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="workflow-people-picker-wrap">
      {props.steps.map((x: IDocumentSignStep, i: number) => {
        return (
          <div key={i} className="workflow-people-picker-line">
            <div style={{width: '100%'}}>
              <FluentUIDecorator
                label={'Step ' + (x.order + 1).toString()}
                info={getInputInfos('new-document', 'workflow-step-users')}
                fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                  disabled: props.disabled,
                  selectedUsers: optionsToPersonas(props.steps[i].users),
                  onFilterChanged: async (
                    filterText: string,
                    currentPersonas: IPersonaProps[] | undefined,
                    limitResults?: number | undefined
                  ) => {
                    let allPeople: IPersonaProps[][] = [currentPersonas, props.blackList];
                    for (let j = 0; j < props.steps.length; j++) {
                      allPeople.push(optionsToPersonas(props.steps[j].users));
                    }

                    let people: any[] = await IntegrationUtils.resolvePeoplePickerVoicesNoCast(
                      filterText,
                      IntegrationUtils.joinPersonasArrays(allPeople)
                    );

                    if (props.allowedElementTypes !== null) {
                      people = people.filter((x: any) => verifyElementTypesValidity(props.allowedElementTypes, x.elementTypes));
                    }

                    return optionsToPersonas(
                      people.map((u) => {
                        return {
                          id: u.id,
                          name: `${u.firstName} ${u.lastName}`,
                          secondaryText: bonifyEmailField(u.email),
                        };
                      }) as IPersonaOptionDto[]
                    );
                  },
                  onPeopleChanged: (p) => {
                    let steps_: IDocumentSignStep[] = [...props.steps];
                    steps_[i].users = personasToOptions(p);
                    props.onPeopleChanged(steps_);
                  },
                })}
              />
            </div>
            <IconButton
              iconProps={{iconName: 'Delete'}}
              disabled={props.disabled}
              onClick={() => {
                let newSteps = props.steps
                newSteps.splice(i, 1)
                newSteps = newSteps.map((s, i) => ({...s, order: i}))
                props.onPeopleChanged(newSteps);
              }}
            />
          </div>
        );
      })}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginTop: '1em',
        }}>
        <LabelButton
          whiteOutlined
          text="Add another Approvation Step"
          icon="Add"
          disabled={props.disabled || props.steps.length === props.maxSteps}
          onClick={() => {
            let steps_: IDocumentSignStep[] = [...props.steps];
            steps_.push({order: props.steps.length, users: []});
            props.onPeopleChanged(steps_);
          }}
        />
      </div>
    </div>
  );
};
