import {SelectionMode, Spinner} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FileList} from '@Eni/docware-fe-master';
import {formatFileSize} from '../../../Components/FileUploadBoxPreview/FileUploadBoxPreview';
import {IDocumentItem} from '../../../Models/IDocument';
import {GlobalState} from '../../../Reducers/RootReducer';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import ContextMenu from '../../../Components/ContextMenu/ContextMenu';
import './AdminTrashBin.scss';
import {DocumentListAction} from '../../../Reducers/DocumentSearch/DocumentSearchActions';
import store from '../../../Reducers/Store';
import PermissionsOnDocuments from '../../../Utils/PermissionsOnDocuments';
import {UserActions} from '../../../Reducers/User/UserAction';
import {IPermissionByData} from '../../../Models/IUser';
import {getDocumentLabel, getDocumentLabelString} from '../../../Utils/ComponentsUtils';
import AttachmentSmallBrowserButton from '../../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';

const AdminTrashBin = () => {
  const navigate = useNavigate();
  /** redux reference to documents  */
  let documentSearch = useSelector((state: GlobalState) => state.documentSearch);
  let generic = useSelector((state: GlobalState) => state.generic);

  const [reloadTrashed, setreloadTrashed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [trash, setTrash] = useState<IDocumentItem[]>([]);
  let currentPermissions = useSelector((state: GlobalState) => state.user.permissionsForDocuments);
  useEffect(() => {
    let trashIds = trash.map((x: IDocumentItem) => {
      return x.documentId;
    });

    PermissionsOnDocuments.getDocumentPermissions(currentPermissions, trashIds, (result: IPermissionByData[]) => {
      store.dispatch(UserActions.setPermissionsByDocuments(result));
    });
  }, [trash]);
  useEffect(() => {
    /** test body */
    const documentSearchBody: any = {
      //"search": "",
      limit: 200,
      offset: 0,
    };

    /** API call to get favorite documents */
    setLoading(true);
    ApiService.DocumentController.getPermanentlyDeletedDocuments(documentSearchBody, (response: IAPIResponse) => {
      if (response.error == null) {
        setTrash(response.payload);
      } else {
        setTrash([]);
      }
      setLoading(false);
    });
  }, [reloadTrashed, generic.reloadRequest]);

  for (let i = 0; i < trash.length; i++) {
    if (trash[i].mainFile) {
      trash[i]['fileSize'] = formatFileSize(trash[i].mainFile.size);
    }
  }

  return (
    <div>
      <div className="admin-trash-page-title">Document Trash Bin</div>
      {loading && (
        <div className="admin-trash-page-spinner-wrap-main">
          <div className="admin-trash-page-spinner-inner">
            <Spinner label="Loading..." />
          </div>
        </div>
      )}

      {!loading && (
        <div>
          <FileList
            defaultSortOnThisColumnIndex={2}
            fileIconFromField={'mainFile.fileName'}
            onItemsSelected={(selectedRows: any[]) => {
              if (selectedRows.length === 0) {
                store.dispatch(DocumentListAction.setActiveDocument(null));
                store.dispatch(DocumentListAction.setActiveDocuments([]));
              }
              if (selectedRows.length === 1) {
                store.dispatch(DocumentListAction.setActiveDocument(selectedRows[0]));
                store.dispatch(DocumentListAction.setActiveDocuments([]));
                window.document.dispatchEvent(new Event('adminTrashBinDidSelect'));
              }
              if (selectedRows.length > 1) {
                store.dispatch(DocumentListAction.setActiveDocument(null));
                store.dispatch(DocumentListAction.setActiveDocuments(selectedRows));
                window.document.dispatchEvent(new Event('adminTrashBinDidSelect'));
              }
            }}
            currentSelectedItems={documentSearch.activeDocuments}
            currentSelectedItem={documentSearch.activeDocument}
            hideBottomButton={true}
            selectionMode={SelectionMode.none}
            columns={[
              {
                name: 'File Type',
                dataType: 'string',
                fieldName: 'name',
                iconName: 'Page',
                size: 'tiny',
              },
              {
                name: '',
                dataType: 'string',
                fieldName: '',
                iconName: '',
                size: 'micro',
                onRender: (item: IDocumentItem) => {
                  return (
                    <div className="file-name-column-cell">
                      <span className="file-name-column-cell-buttons">
                        <ContextMenu
                          item={item}
                          type={'admin-bin'}
                          emitAction={(type: string) => {
                            if (type === 'trash') {
                              setreloadTrashed(!reloadTrashed);
                            }
                          }}
                        />
                      </span>
                    </div>
                  );
                },
              },
              {
                name: 'Name',
                dataType: 'string',
                fieldName: 'documentName',
                iconName: null,
                size: 'large',
                onRender: (item: IDocumentItem) => {
                  return (
                    <div className="file-name-column-cell">
                      <span
                        title={getDocumentLabelString(item.documentName, item.documentCode)}
                        className="file-name-column-cell-buttons-label file-linkname"
                        style={{display: "flex", gap: "3px"}}>
                        <div>
                          <AttachmentSmallBrowserButton ownerDocument={item} />
                        </div>
                        {getDocumentLabel(item.documentName, item.documentCode, null)}
                      </span>
                    </div>
                  );
                },
              },
              {
                name: 'Created by',
                dataType: 'string',
                fieldName: 'createdBy.name',
                iconName: null,
                size: 'medium',
              },
              {
                name: 'Last Modified',
                dataType: 'date',
                fieldName: 'updateDate',
                iconName: null,
                size: 'small',
              },
              {
                name: 'Professional Area',
                dataType: 'string',
                fieldName: 'library',
                iconName: null,
                size: 'small',
              },
              {
                name: 'Last modified',
                dataType: 'date',
                fieldName: 'updateDate',
                iconName: null,
                size: 'small',
              },
              {
                name: 'File size',
                dataType: 'number',
                fieldName: 'fileSize',
                iconName: null,
                size: 'small',
              },
            ]}
            items={trash}
          />
        </div>
      )}
    </div>
  );
};

export default AdminTrashBin;
