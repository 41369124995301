interface URITranslation {
  from: string;
  to: string;
}

const URITranslations: URITranslation[] = [{from: '&', to: '_!and!_'}];

const URIformatter = {
  encode: (text: string) => {
    for (let i = 0; i < URITranslations.length; i++) {
      let trad: URITranslation = URITranslations[i];
      text = text.replaceAll(trad.from, trad.to);
    }
    return text;
  },
  decode: (uri: string) => {
    uri = decodeURIComponent(uri)
    for (let i = 0; i < URITranslations.length; i++) {
      let trad: URITranslation = URITranslations[i];
      uri = uri.replaceAll(trad.to, trad.from);
    }
    return uri;
  },
};

export default URIformatter;
