import {
  InputInfosActionType,
  RELOAD_REQUEST,
  SET_ALL_CLEAN_LIBRARIES,
  SET_ALL_USERS,
  SET_BO_COLORS,
  SET_DRIVE_DOC_SIZE, SET_FEATURE_FLAGS,
  SET_INPUT_INFOS,
  SET_LIBRARY_HIERARCHY,
  SET_LIBRARY_HIERARCHY_WHERE_USER_CAN_SEARCH,
  SET_SORT_ORDER_DIRECTION,
  SET_SORT_ORDER_SEARCH,
  SET_TASKS_BACKGROUND,
  SET_TIGHT_ERROR_BOS,
  SET_TIGHT_ERROR_CTR,
  SET_UPLOAD_APPROVAL_FLAG,
  SET_VIDEOS_TUTORIAL,
  SET_WAITING_LOG_IN,
} from './GenericAction';
import {DEFAULT_GENERIC_STATE, GenericState} from './GenericState';

export const genericReducer = (state: GenericState = DEFAULT_GENERIC_STATE, action: InputInfosActionType): GenericState => {
  switch (action.type) {
    case SET_TIGHT_ERROR_BOS:
      return {
        ...state,
        tightErrorBos: action.payload,
      };
    case SET_TASKS_BACKGROUND:
      return {
        ...state,
        tasks: action.payload,
      };
    case SET_TIGHT_ERROR_CTR:
      return {
        ...state,
        tightErrorCountry: action.payload,
      };
    case SET_LIBRARY_HIERARCHY:
      return {
        ...state,
        libraryHierachyModel: action.payload,
      };
    case SET_LIBRARY_HIERARCHY_WHERE_USER_CAN_SEARCH:
      return {
        ...state,
        libraryHierachyModelUserCanAccess: action.payload,
      };
    case SET_DRIVE_DOC_SIZE:
      return {
        ...state,
        documentsDriveViewSize: action.payload,
      };
    case RELOAD_REQUEST:
      let currentReloadRequest = state.reloadRequest;
      return {
        ...state,
        reloadRequest: !currentReloadRequest,
      };
    case SET_UPLOAD_APPROVAL_FLAG:
      return {
        ...state,
        documentUploadApprovalFlag: action.payload,
      };
    case SET_SORT_ORDER_SEARCH:
      return {
        ...state,
        sortSearchBy: action.payload,
      };
    case SET_SORT_ORDER_DIRECTION:
      return {
        ...state,
        sortDirectionDesc: action.payload,
      };
    case SET_INPUT_INFOS:
      return {
        ...state,
        inputInfos: action.payload,
      };
    case SET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload,
      };
    case SET_BO_COLORS:
      return {
        ...state,
        boColors: action.payload,
      };
    case SET_VIDEOS_TUTORIAL:
      return {
        ...state,
        videosTutorial: action.payload,
      };
    case SET_WAITING_LOG_IN:
      return {
        ...state,
        waitingLogIn: action.payload,
      };
    case SET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.payload,
      };
    case SET_ALL_CLEAN_LIBRARIES:
      return {
        ...state,
        allCleanLibraries: action.payload,
      };
    default:
      return state;
  }
};
