import {Spinner} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import Icons from '../../Utils/FabricIconsOutlet';
import './SearchSuggestions.scss';

export interface SuggestionElement {
  label: string;
  type: number;
}

export interface SearchSuggestionsProps {
  searchText: string;
  highlight: number;
  emitSearch: (txt: string) => void;
  emitResults: (suggestions: SuggestionElement[]) => void;
}

export const cleanLabelData = (data: string) => {
  data = data.replaceAll('<b>', '');
  data = data.replaceAll('</b>', '');
  if (data.endsWith('...')) {
    data = data.substring(0, data.length - 3);
  }
  return data;
};

const SearchSuggestions = (props: SearchSuggestionsProps) => {
  const [coolDown, setCoolDown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [suggestions, setSuggestions] = useState<SuggestionElement[]>([]);
  const [highlight, setHighlight] = useState<number>(-1);
  const [positioning, setPositioning] = useState<any>({
    top: 0,
    left: 0,
    width: 300,
  });

  const setPosition = () => {
    let t = document.getElementById('search-toolbar-node');
    if (t !== null) {
      let rect = t.getBoundingClientRect();
      setPositioning({
        top: rect.bottom + 2,
        left: rect.left,
        width: rect.width,
      });
    }
  };

  useEffect(() => {
    let h = props.highlight;
    if (h >= suggestions.length) {
      h = suggestions.length - 1;
    }
    setHighlight(h);
  }, [props.highlight]);

  useEffect(() => {
    if (coolDown) {
      setSearchText(props.searchText);
      getSearchSuggestions(props.searchText);
    } else {
      setSuggestions([]);
      setTimeout(() => {
        try {
          setCoolDown(true);
        } catch (e) {}
      }, 1000);
    }
    setPosition();
  }, [props.searchText]);

  const getSearchSuggestions = (search: string) => {
    if (search.length > 2) {
      setSuggestions([]);
      setLoading(true);
      ApiService.DocumentController.getDocumentSearchSuggestions(search, '5', (response: IAPIResponse) => {
        if (response.error === null) {
          setSuggestions(response.payload);
          props.emitResults(response.payload);
        }
        setLoading(false);
      });
    } else {
      setSuggestions([]);
    }
  };

  const tryResize = () => {
    setTimeout(() => {
      try {
        setPosition();
      } catch (e) {}
    }, 300);
  };

  let boxStyle: any = {};
  boxStyle.top = positioning.top;
  boxStyle.left = positioning.left;
  boxStyle.width = positioning.width;

  useEffect(() => {
    window.addEventListener('resize', tryResize);

    return () => {
      window.removeEventListener('resize', tryResize);
    };
  }, []);

  return (
    <>
      {(suggestions.length > 0 || loading) && searchText.length > 0 && (
        <div className="search-suggestions-main-wrap" style={boxStyle}>
          {loading && (
            <div className="search-suggestion-spinner-wrap-main">
              <div className="search-suggestion-spinner-inner">
                <Spinner label="Loading..." labelPosition="right" />
              </div>
            </div>
          )}
          {suggestions
            .sort((a: SuggestionElement, b: SuggestionElement) => {
              return b.type - a.type;
            })
            .map((x: SuggestionElement, i: number) => {
              return (
                <div
                  className={'search-suggestion-row ' + (i === highlight ? 'search-suggestion-row-highlight' : '')}
                  key={i}
                  onClick={() => {
                    setCoolDown(false);
                    setSuggestions([]);
                    props.emitSearch(cleanLabelData(x.label));
                  }}>
                  <div className="search-suggestion-row-icon">{Icons.getIcon(x.type === 0 ? 'Search' : 'Clock')}</div>
                  <div className="search-suggestion-row-label" dangerouslySetInnerHTML={{__html: x.label}}></div>
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default SearchSuggestions;
