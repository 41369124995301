import {GENERIC_URLS} from '../../Constants/Urls';
import {InputInfo} from '../../Models/InputInfo';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const InputInfoController = {
  getInputInfo: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.INPUT_INFO;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteInputInfo: async (body: InputInfo, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.INPUT_INFO;
    request.method = 'delete';
    request.payload = body;
    request.successMessage = 'Record deleted.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setInputInfo: async (body: InputInfo, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.INPUT_INFO;
    request.method = 'post';
    request.payload = body;
    request.successMessage = 'Record updated.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default InputInfoController;
