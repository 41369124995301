import {IAccessToken, IPermissionByData, IPermissionRecap, IBaseLibraries, IPermissionsByAction, IUser} from '../../Models/IUser';
import {TSelectUserInfo} from './UserState';

export const SET_USER = 'SET_USER';
export const SET_PERMISSIONS_BY_ACTION = 'SET_PERMISSIONS_BY_ACTION';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_PERMISSIONS_BY_DOCUMENTS = 'SET_PERMISSIONS_BY_DOCUMENTS';
export const SET_ACCESSTOKEN = 'SET_ACCESS_TOKEN';

interface SetPermissionsByDocuments {
  type: typeof SET_PERMISSIONS_BY_DOCUMENTS;
  payload: IPermissionByData[];
}

interface SetUserActionType {
  type: typeof SET_USER;
  payload: IUser | undefined;
}

interface SetPermissionsByAction {
  type: typeof SET_PERMISSIONS_BY_ACTION;
  payload: IPermissionsByAction | undefined;
}

interface SetUserInfo {
  type: typeof SET_USER_INFO;
  payload: TSelectUserInfo;
}

interface SetAccessToken {
  type: typeof SET_ACCESSTOKEN;
  payload: IAccessToken | undefined;
}

export type UserActionType = SetUserActionType | SetPermissionsByAction | SetAccessToken | SetPermissionsByDocuments | SetUserInfo;

const setUser = (user: IUser | undefined): SetUserActionType => {
  return {type: SET_USER, payload: user};
};

const setPermissionsByAction = (permissions: IPermissionsByAction | undefined): SetPermissionsByAction => {
  return {type: SET_PERMISSIONS_BY_ACTION, payload: permissions};
};

const setUserInfo = (content: TSelectUserInfo) => {
  return {
    type: SET_USER_INFO,
    payload: content,
  };
};
const setPermissionsByDocuments = (permissions: IPermissionByData[]): SetPermissionsByDocuments => {
  return {type: SET_PERMISSIONS_BY_DOCUMENTS, payload: permissions};
};

export const UserActions = {
  setUser,
  setPermissionsByAction,
  setPermissionsByDocuments,
  setUserInfo,
};
