export enum DocumentStatus {
  Draft,
  Signed,
  Pending,
  Rejected,
  Obsolete,
  Completed,
}

export const DocumentStatusLs = ['Draft', 'Signed', 'Pending', 'Rejected', 'Superseded', 'Completed'];
