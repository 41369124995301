import {GENERIC_URLS} from '../../Constants/Urls';
import {DocumentSource} from '../../Models/DocumentSource';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const DocumentSourcesController = {
  getDocumentSource: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_SOURCES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteDocumentSource: async (body: DocumentSource, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_SOURCES;
    request.method = 'delete';
    request.payload = body;
    request.successMessage = 'Record deleted.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setDocumentSource: async (body: DocumentSource, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_SOURCES;
    request.method = 'post';
    request.payload = body;
    request.successMessage = 'Record added.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  patchDocumentSource: async (body: DocumentSource, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_SOURCES;
    request.method = 'PATCH';
    request.payload = body;
    request.successMessage = 'Record updated.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default DocumentSourcesController;
