import {VERSIONING_URLS} from '../../Constants/Urls';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const VersioningController = {
  getVersionById: async (versionId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = VERSIONING_URLS.GET_VERSION_URL + '?versionId=' + versionId;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getVersionLatestById: async (versionId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = VERSIONING_URLS.GET_VERSION_LATEST + '?versionId=' + versionId;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VersioningController;
