import {IDocumentMetadata} from '../../../Models/IDocument';

export interface ICustomUploadFormBehavior {
  getCustomEffect: (formStatus: IDocumentMetadata) => IDocumentMetadata;
}

export const CustomUploadFormBehavior: ICustomUploadFormBehavior[] = [
  {
    getCustomEffect: (formStatus: IDocumentMetadata) => {
      if (
        formStatus &&
        formStatus.professionalArea &&
        formStatus.professionalArea.name === 'DSD' &&
        formStatus.documentTypeId === 'Fundamental'
      ) {
        let customMeta = formStatus.metadata ?? {};
        let currentUnit = customMeta['unit'] ? customMeta['unit'].selectValue.id : null;

        // you are setting the doc type to foundamental and STAP was not selected
        if (currentUnit !== 'STAP') {
          customMeta['unit'] = {
            selectValue: {id: 'STAP', name: 'STAP'},
            type: 2,
          };
          delete customMeta['documentNumber'];
        }
        formStatus.metadata = customMeta;
      }
      return formStatus;
    },
  },
];
