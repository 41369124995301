import {IDocumentLock, INewDocument} from '../../Models/IDocument';
import {ILibrary} from '../../Models/ILibrary';

export interface NewDocumentState {
  document: INewDocument;
  selectedLibrary: ILibrary;
  userLibraries: ILibrary[];
  documentLock?: IDocumentLock;
}

export const DEFAULT_NEW_DOCUMENT_STATE: NewDocumentState = {
  document: {} as INewDocument,
  selectedLibrary: {} as ILibrary,
  userLibraries: [],
  documentLock: undefined as IDocumentLock,
};
