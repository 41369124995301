import './InformationPanel.scss';
import React from 'react';

interface InformationPanelProps {
  rows: {header: JSX.Element; content: JSX.Element}[];
}

export const InformationPanel = (props: InformationPanelProps) => {
  return (
    <>
      <div className="information-panel-container">
        <div className="information-panel-container-rectangle" />
        <div className="information-panel-content">
          {props.rows.map((row, index) => (
            <React.Fragment key={index}>
              <div className="information-panel-row-header">
                {index !== 0 ? <hr className="information-panel-row-divider" /> : <></>}
                {row.header}
              </div>

              <div className="information-panel-row-content">{row.content}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
