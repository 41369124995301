import {Spinner, Toggle} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IDocumentQuery, IDocumentQueryDocument} from '../../Models/IDocument';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AdvancedSearchInputTable from '../AdvancedSearchInputTable/AdvancedSearchInputTable';
import {getUploadIcon} from '../FileUploadBoxPreview/FileUploadBoxPreview';
import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import './AdvancedSearchInputUploadSelector.scss';
import {useSearchParams} from 'react-router-dom';
import {ILibrary} from '../../Models/ILibrary';

export interface AdvancedSearchInputUploadSelectorProps {
  activeLibrary: ILibrary;
  enabled: boolean;
  emit: (items: IDocumentQuery[] | null) => void;
  modalTitle: string;
  modalLabel: string;
}

interface DocumentSearchRowProps {
  document: IDocumentQuery;
  emitToggle: (toggle: boolean) => void;
  defaultToggled: boolean;
}

const DocumentSearchRow = (props: DocumentSearchRowProps) => {
  const [selected, setSelected] = useState<boolean>(props.defaultToggled);

  return (
    <div
      className="search-input-upload-selector-result-wrap"
      onClick={(e: any) => {
        setSelected(!selected);
        props.emitToggle(!selected);
      }}>
      <Toggle checked={selected} />
      <div className="search-input-upload-selector-result-icon">{getUploadIcon(props.document.document.fileName)}</div>
      <div className="search-input-upload-selector-result-title" title={props.document.document.documentName}>
        {props.document.document.documentName}
      </div>
      <div className="search-input-upload-selector-result-label" title={props.document.document.professionalArea.name ?? '-'}>
        {props.document.document.professionalArea.name ?? '-'}
      </div>
      <div
        className="search-input-upload-selector-result-label"
        title={props.document.document.createdBy ? props.document.document.createdBy.name : '-'}>
        {props.document.document.createdBy ? props.document.document.createdBy.name : '-'}
      </div>
      <div
        className="search-input-upload-selector-result-label"
        title={props.document.document.creationDate ? new Date(props.document.document.creationDate).toLocaleDateString() : '-'}>
        {props.document.document.creationDate ? new Date(props.document.document.creationDate).toLocaleDateString() : '-'}
      </div>
    </div>
  );
};

const AdvancedSearchInputUploadSelector = (props: AdvancedSearchInputUploadSelectorProps) => {
  const [params] = useSearchParams();
  let docIdParam = params.get('documentid');

  const [searchBody, setSearchBody] = useState<string>('');
  const [enabled, setEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [seeResults, setSeeResults] = useState<boolean>(false);
  const [searchResults, setSearchResult] = useState<IDocumentQuery[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState<IDocumentQuery[]>([]);

  useEffect(() => {
    setSearchBody('');
    setSearchResult([]);
    setSelectedDocuments([]);
    setEnabled(props.enabled);
  }, [props.enabled]);

  const validQueryDocument = (doc: IDocumentQueryDocument) => {
    if (doc.replacedBy !== null && doc.replacedBy !== '') {
      return false;
    }

    if (doc.documentStatus !== 1 && doc.documentStatus !== 5) {
      return false;
    }

    if (doc.documentId === docIdParam) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (searchBody === '') {
      return;
    }

    setLoading(true);
    let body = JSON.parse(searchBody);

    /** API call to get documents */
    ApiService.DocumentController.queryDocuments(body, (response: IAPIResponse) => {
      if (response.error == null) {
        let docs: any[] = response.payload.results;
        for (let i = 0; i < docs.length; i++) {
          let doc: any = docs[i];
          doc['document']['score'] = doc['score'];
        }
        setSearchResult(docs.filter((x: IDocumentQuery) => validQueryDocument(x.document)));
      } else {
        setSearchResult([]);
      }
      setLoading(false);
      setSeeResults(true);
      setSearchBody('');
    });
  }, [searchBody]);

  let modalButtons = [
    {
      onClick: () => {
        props.emit(null);
      },
      label: 'Cancel',
    },
    {
      disabled: selectedDocuments.length === 0,
      label: props.modalLabel,
      onClick: () => {
        props.emit(selectedDocuments);
        setSeeResults(false);
        setSearchResult([]);
      },
    },
  ];

  return (
    <div>
      <ModalDialog
        modalTitle={props.modalTitle}
        onAbort={() => {
          props.emit(null);
        }}
        modalButtons={modalButtons}
        enableModal={enabled}
        modalInnerComponent={
          <div className="file-input-picker-wrapper">
            {loading && <Spinner label="Searching..." labelPosition="right" />}
            {!loading && !seeResults && (
              <AdvancedSearchInputTable
                activeLibrary={props.activeLibrary}
                onSubmit={(data: any) => {
                  setSearchBody(JSON.stringify(data));
                }}
                showTextSearch={true}
              />
            )}
            {!loading && seeResults && (
              <div>
                <LabelButton
                  text={'New search'}
                  icon={'Search'}
                  onClick={() => {
                    setSeeResults(false);
                    setSearchResult([]);
                  }}
                />
                <div className="search-input-upload-selector-result-page-title">
                  Select the documents you want to link to the current document
                </div>
                <div className="search-input-upload-selector-result-wrap-outer">
                  {searchResults.length === 0 && (
                    <div className="search-input-upload-selector-result-no-results">The search produced 0 results.</div>
                  )}
                  {searchResults.map((x: IDocumentQuery, i: number) => {
                    return (
                      <DocumentSearchRow
                        key={i}
                        document={x}
                        defaultToggled={
                          selectedDocuments.filter((y: IDocumentQuery) => y.document.documentId === x.document.documentId).length > 0
                        }
                        emitToggle={(toggle: boolean) => {
                          if (!toggle) {
                            let docs = [];
                            for (let j = 0; j < selectedDocuments.length; j++) {
                              if (i !== j) {
                                docs.push(selectedDocuments[j]);
                              }
                            }
                            setSelectedDocuments(docs);
                          } else {
                            let docs = [...selectedDocuments];
                            docs.push(x);
                            setSelectedDocuments(docs);
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default AdvancedSearchInputUploadSelector;
