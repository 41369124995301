import {NewItem} from '../../../Models/New';

export const validateNewItem = (newItem: NewItem) => {
  if (!newItem.title || newItem.title == '') {
    return false;
  }
  if (!newItem.description || newItem.description == '') {
    return false;
  }
  if ((!newItem.severity && newItem.severity != 0) || newItem.severity < 0 || newItem.severity > 2) {
    return false;
  }
  if (!newItem.dateRange.from || !newItem.dateRange.to || newItem.dateRange.from > newItem.dateRange.to) {
    return false;
  }

  return true;
};
