import {Spinner} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {UserInfoTable} from '../../Components/UserInfoTable/UserInfoTable';
import {IPermissionRecap} from '../../Models/IUser';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import {getLastLoginDate} from '../../Utils/DateUtils';
import SignatureUtils from '../../Utils/SignatureUtils';
import './UserInfoPage.scss';

const UserInfoPage = () => {
  const [recap, setRecap] = useState<IPermissionRecap | undefined>();

  useEffect(() => {
    ApiService.UsersController.getPermissionRecap((response: IAPIResponse) => {
      if (response.error === null) {
        setRecap(response.payload);
      }
    });
  }, []);

  return (
    <div>
      {localStorage.getItem('user-not-enabled') !== 'true' && <DriveDocumentManagementToolbar type={'navigation-voices'} />}
      <div className="user-info-page-wrapper">
        <div className="user-info-page-container">
          {recap ? (
            <UserInfoTable
              userId={recap.userId}
              name={recap.fullName}
              email={recap.email}
              digitalSignature={SignatureUtils.getSignatureVisualization(recap.digitalSignatureType, recap.digitalSignatureData)}
              lastLogin={getLastLoginDate(recap.lastLogin)}
              libraries={recap.libraries}
            />
          ) : (
            <Spinner label="Loading user..." />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfoPage;
