export const EnumUtils = {
  getDict: <T extends {[name: string]: any}>(coso: T) => {
    let result = {} as {[k: number]: string};
    let items = [] as string[];
    for (let member in coso) {
      items.push(member);
    }
    let n = items.length;
    for (let i: number = 0; i < n / 2; i++) {
      let key = Number(items[i]);
      let value = items[i + n / 2] as string;
      result[key] = value;
    }
    return result;
  },
};
