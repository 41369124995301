import {getTrackingComponent} from '../../AppInsights';
import {useParams} from 'react-router-dom';
import {APISettings} from '../../Services/AjaxService';
import {VIDEO_TUTORIAL_URLS} from '../../Constants/Urls';
import './VideoTutorialPage.scss';

const VideoTutorialPage = () => {
  const {videoName} = useParams();

  return (
    <>
      <div className="video-tutorial-video-container">
        <video className="video-tutorial-video" controls autoPlay>
          <source src={`${APISettings.baseUrl}${VIDEO_TUTORIAL_URLS.GET_VIDEO_STREAM}/${videoName}`} />
          Your browser does not support HTML video.
        </video>
      </div>
    </>
  );
};

export default getTrackingComponent(VideoTutorialPage, 'VideoTutorialPage');
