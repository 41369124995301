import React from 'react';
import useFeature from './useFeature';

interface FeatureFlagProps {
  featureKey: string;
  children: React.ReactNode;
}

const Feature = ({
  featureKey,
  children,
}: FeatureFlagProps) => {
  const isActive = useFeature(featureKey);

  return (
    <>
      {isActive ? (children) : (<></>)}
    </>
  );
};

export default Feature;
