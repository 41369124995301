import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from '../AjaxService';
import {IValue} from '../../Models/IValue';

interface IAdvancedSearchExpAssuranceRequest { 
  actualData: {
    xware_concessions: IValue[]
    expbmt_prospects: IValue[]
    expbmt_well_prospect: IValue[]
  }
  searchText: string
  countriesId: string[]
}

export interface IAdvancedSearchExpAssuranceResponseItem extends IValue { 
  documentsCount: number
}


const AdvancedSearchExpAssuranceController = {
  getLeases: async (requestBody: IAdvancedSearchExpAssuranceRequest) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/advancedsearchexpassurance/xware_concessions`;
    request.method = "POST";
    request.payload = requestBody
    let response: IAPIResponse = await AjaxService.call(request);
    return response;
  },
  getNewVentureProject: async (requestBody: IAdvancedSearchExpAssuranceRequest) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/advancedsearchexpassurance/expbmt_newventureprojects`;
    request.method = "POST";
    request.payload = requestBody
    let response: IAPIResponse = await AjaxService.call(request);
    return response;
  },
  getProspects: async (requestBody: IAdvancedSearchExpAssuranceRequest) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/advancedsearchexpassurance/expbmt_prospects`;
    request.method = "POST";
    request.payload = requestBody
    let response: IAPIResponse = await AjaxService.call(request);
    return response;
  },
  getWells: async (requestBody: IAdvancedSearchExpAssuranceRequest) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/advancedsearchexpassurance/expbmt_well_prospect`;
    request.method = "POST";
    request.payload = requestBody
    let response: IAPIResponse = await AjaxService.call(request);
    return response;
  },
};

export default AdvancedSearchExpAssuranceController;
