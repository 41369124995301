import * as React from 'react';
import {IPersonaProps} from '@fluentui/react/lib/Persona';
import {IBasePickerSuggestionsProps, NormalPeoplePicker, IPeoplePickerProps, ValidationState} from '@fluentui/react/lib/Pickers';
import {Label} from '@fluentui/react';

const suggestionProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested People',
  mostRecentlyUsedHeaderText: 'Suggested Contacts',
  noResultsFoundText: 'No results found',
  loadingText: 'Loading',
  showRemoveButtons: true,
  suggestionsAvailableAlertText: 'People Picker Suggestions available',
  suggestionsContainerAriaLabel: 'Suggested contacts',
};

export interface PeoplePickerProps {
  label?: string;
  disabled?: boolean;
  selectedUsers: IPersonaProps[];
  mostRecentlyUsed?: IPersonaProps[];
  onFilterChanged: (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    limitResults?: number
  ) => IPersonaProps[] | Promise<IPersonaProps[]>;
  onPeopleChanged: (updatedPeople: IPersonaProps[] | undefined) => void;
  inputProps?: any;
}

export const PeoplePicker = (props: PeoplePickerProps) => {
  const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState<IPersonaProps[]>(props.mostRecentlyUsed ?? []);
  const [peopleList, setPeopleList] = React.useState<IPersonaProps[]>(props.selectedUsers);

  const picker = React.useRef(null);

  const onRemoveSuggestion = (item: IPersonaProps): void => {
    const indexPeopleList: number = peopleList.indexOf(item);
    const indexMostRecentlyUsed: number = mostRecentlyUsed.indexOf(item);

    if (indexPeopleList >= 0) {
      const newPeople: IPersonaProps[] = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
      setPeopleList(newPeople);
    }

    if (indexMostRecentlyUsed >= 0) {
      const newSuggestedPeople: IPersonaProps[] = mostRecentlyUsed
        .slice(0, indexMostRecentlyUsed)
        .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
      setMostRecentlyUsed(newSuggestedPeople);
    }
  };

  return (
    <div>
      {props.label && <Label>{props.label}</Label>}
      <NormalPeoplePicker
        inputProps={props.inputProps}
        selectedItems={props.selectedUsers}
        onResolveSuggestions={props.onFilterChanged}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={suggestionProps}
        className={'ms-PeoplePicker'}
        key={'normal'}
        onRemoveSuggestion={onRemoveSuggestion}
        onValidateInput={validateInput}
        selectionAriaLabel={'Selected contacts'}
        removeButtonAriaLabel={'Remove'}
        componentRef={picker}
        onInputChange={onInputChange}
        resolveDelay={300}
        disabled={props.disabled}
        onChange={(people) => props.onPeopleChanged(people.filter((x: any) => x.text))}
      />
    </div>
  );
};

function doesTextStartWith(text: string, filterText: string): boolean {
  return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(personas: IPersonaProps[], possibleDupes: IPersonaProps[]) {
  return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona: IPersonaProps, personas: IPersonaProps[]) {
  if (!personas || !personas.length || personas.length === 0) {
    return false;
  }
  return personas.filter((item) => item.text === persona.text).length > 0;
}

function convertResultsToPromise(results: IPersonaProps[]): Promise<IPersonaProps[]> {
  return new Promise<IPersonaProps[]>((resolve, reject) => setTimeout(() => resolve(results), 2000));
}

function getTextFromItem(persona: IPersonaProps): string {
  return persona.text as string;
}

function validateInput(input: string): ValidationState {
  if (input.indexOf('@') !== -1) {
    return ValidationState.valid;
  } else if (input.length > 1) {
    return ValidationState.warning;
  } else {
    return ValidationState.invalid;
  }
}

/**
 * Takes in the picker input and modifies it in whichever way
 * the caller wants, i.e. parsing entries copied from Outlook (sample
 * input: "Aaron Reid <aaron>").
 *
 * @param input The text entered into the picker.
 */
function onInputChange(input: string): string {
  const outlookRegEx = /<.*>/g;
  const emailAddress = outlookRegEx.exec(input);

  if (emailAddress && emailAddress[0]) {
    return emailAddress[0].substring(1, emailAddress[0].length - 1);
  }

  return input;
}
