import './NavButtons.scss';
import {LabelButton} from '@Eni/docware-fe-master';

interface NavButtonsProps {
  onBackClick?: () => void;
  onNextClick?: () => void;
  nextLabel?: string;
  nextIcon?: string;
  nextDisabled?: boolean;
}

const NavButtons = (props: NavButtonsProps) => {
  return (
    <div className="nav-buttons-container">
      {props.onBackClick ? <LabelButton text="Back" icon="ChromeBack" whiteOutlined onClick={props.onBackClick} /> : <div />}

      {props.onNextClick ? (
        <LabelButton
          text={props.nextLabel ?? 'Next'}
          icon={props.nextIcon ?? 'ChromeBackMirrored'}
          orangeSolid
          onClick={props.onNextClick}
          disabled={props.nextDisabled ?? false}
        />
      ) : (
        <div />
      )}
    </div>
  );
};

export default NavButtons;
