import {DirectionalHint, ITooltipProps, TooltipHost} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DocumentStatusLs} from '../../Constants/Enums';
import {IMetadataValue} from '../../Models/IDocument';
import {HierarchyDocType, HierarchyItem, HierarchyLibrary, HierarchyProfessionalArea, IBOAggregated, IBoLibrary, IMetadata} from '../../Models/ILibrary';
import {FormatBoName} from '../../Pages/DocumentSearchPage/DocumentSearchPage';
import {GlobalState} from '../../Reducers/RootReducer';
import {extractMetadataValue} from '../../Utils/DocumentUtils';
import {injectValue} from '../../Utils/Methods';
import {getBoMeta} from '../AggregatedBosHistorySearch/AggregatedBosHistorySearch';
import './SearchTagsControl.scss';
import React from 'react';
import {LibraryUtils} from '../../Utils/LibraryUtils';

export const DeserializeObject = (object: any, parentPath: string = ''): {key: string; value: any}[] => {
  if (!object) {
    return [];
  }

  let leafValues: {key: string; value: any}[] = [];
  let keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    let parentKeyPath = (parentPath !== '' ? parentPath + '.' : '') + keys[i];
    let item: any = object[keys[i]];
    if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
      let subValues = DeserializeObject(item, parentKeyPath);
      for (let j = 0; j < subValues.length; j++) {
        leafValues.push(subValues[j]);
      }
    } else {
      leafValues.push({key: parentKeyPath, value: item});
    }
  }
  return leafValues;
};

export interface TagButtonProps {
  onRemove: (path: string) => void;
  label: string;
  labelValue: string;
  inferredState: any;
  trickFilter: boolean;
}

const TagButton = (props: TagButtonProps) => {
  const hierarchy = useSelector<GlobalState, HierarchyLibrary[]>((state) => state.generic.libraryHierachyModel);

  const metadata: IMetadata[] = LibraryUtils.getMetadata(hierarchy);

  const bos: any[] = [];
  for (let i = 0; i < hierarchy.length; i++) {
    let vals = (hierarchy[i].boSearch as IBoLibrary[]).concat(hierarchy[i].masterDatas).map((y: IBoLibrary) => {
      return {source: y.source, displayName: y.displayName, idBoAggregated: false};
    });
    let aggr = hierarchy[i].boAggregations.map((y: IBOAggregated) => {
      return {source: y.source, displayName: y.displayName, idBoAggregated: true};
    });
    for (let j = 0; j < vals.length; j++) {
      if (bos.filter((x: any) => x.source === vals[j].source).length === 0) {
        bos.push(vals[j]);
      }
    }
    for (let j = 0; j < aggr.length; j++) {
      if (bos.filter((x: any) => x.source === aggr[j].source).length === 0) {
        bos.push(aggr[j]);
      }
    }
  }

  const getDocumentTypeByKeys = (): [string, string[]] => {
    let names: string[] = [];
    let profAreasIds = props.inferredState.professionalAreaIds;
    let docTypesIds = props.inferredState.documentTypeIds;

    if (profAreasIds && docTypesIds) {
      for (let i = 0; i < hierarchy.length; i++) {
        let lib: HierarchyLibrary = hierarchy[i];
        for (let j = 0; j < lib.professionalAreas.length; j++) {
          let area: HierarchyProfessionalArea = lib.professionalAreas[j];
          if (profAreasIds.includes(area.id)) {
            for (let k = 0; k < area.documentTypes.length; k++) {
              let docType: HierarchyDocType = area.documentTypes[k];
              if (docTypesIds.includes(docType.id)) {
                names.push(docType.name);
              }
            }
          }
        }
      }
    }

    return names.length > 1 ? [`${names[0]} (+${names.length - 1})`, names] : [`${names[0]}`, []];
  };

  const getDocumentSubTypeByKeys = (): [string, string[]] => {
    let names: string[] = [];
    let profAreasIds = props.inferredState.professionalAreaIds;
    let docTypesIds = props.inferredState.documentTypeIds;
    let docSubTypesIds = props.inferredState.documentSubTypeIds;

    if (profAreasIds && docTypesIds && docSubTypesIds) {
      for (let i = 0; i < hierarchy.length; i++) {
        let lib: HierarchyLibrary = hierarchy[i];
        for (let j = 0; j < lib.professionalAreas.length; j++) {
          let area: HierarchyProfessionalArea = lib.professionalAreas[j];
          if (profAreasIds.includes(area.id)) {
            for (let k = 0; k < area.documentTypes.length; k++) {
              let docType: HierarchyDocType = area.documentTypes[k];
              if (docTypesIds.includes(docType.id)) {
                for (let h = 0; h < docType.subTypes.length; h++) {
                  let subType: HierarchyItem = docType.subTypes[h];
                  if (docSubTypesIds.includes(subType.id)) {
                    names.push(subType.name);
                  }
                }
              }
            }
          }
        }
      }
    }

    return names.length > 1 ? [`${names[0]} (+${names.length - 1})`, names] : [`${names[0]}`, []];
  };

  const getDocumentStatusLabelValue = (value: string) => {
    let asNumb = +value;
    return DocumentStatusLs[asNumb];
  };

  const getProfessionalAreasNames = (): [string, string[]] => {
    let names: string[] = [];
    let profAreasIds = props.inferredState.professionalAreaIds;
    if (profAreasIds) {
      for (let i = 0; i < hierarchy.length; i++) {
        let lib: HierarchyLibrary = hierarchy[i];
        for (let j = 0; j < lib.professionalAreas.length; j++) {
          let area: HierarchyProfessionalArea = lib.professionalAreas[j];
          if (profAreasIds.includes(area.id)) {
            names.push(area.name);
          }
        }
      }
    }
    return names.length > 1 ? [`${names[0]} (+${names.length - 1})`, names] : [`${names[0]}`, []];
  };

  const advancedNameCasting = (label: string) => {
    const converter = [
      ['date', 'Year'],
      ['documentTypeIds', 'Document Types'],
      ['professionalAreaIds', 'Professional Areas'],
      ['documentSubTypeIds', 'Document Sub-Types'],
      ['Name', 'Professional Area'],
      ['documentCode', 'Document Code'],
      ['archiveCode', 'Archive Code'],
      ['documentNumber', 'Document Number'],
      ['from', 'Date From'],
      ['to', 'Date To'],
      ['documentStatus', 'Document Status'],
    ];

    for (let i = 0; i < converter.length; i++) {
      let match = converter[i][0].toLocaleLowerCase();
      if (label.toLocaleLowerCase() === match) {
        return converter[i][1];
      }
    }

    return label;
  };

  const reduceNameToLastItem = (label: string) => {
    label = label.replaceAll('.selectValue.name', '');
    label = label.split('.')[label.split('.').length - 1];
    label = advancedNameCasting(label);
    return label;
  };

  const betterLabel = (originalLabel: string) => {
    let label = reduceNameToLastItem(originalLabel);

    const matchedBos = bos.filter((x: any) => x.source === label);
    if (matchedBos.length > 0) {
      label = matchedBos[0].displayName;
    }

    if(originalLabel.toLowerCase().startsWith("document.metadata")){
      const matchMetadata = metadata.filter(x => x.name === label)[0];
      if(matchMetadata){
        label = matchMetadata.displayName;
      }
    }


    if (label.indexOf('_') !== -1) {
      let splLabel = label.split('_');
      let result = '';
      for (let i = 0; i < splLabel.length; i++) {
        result += splLabel[i].charAt(0).toLocaleUpperCase() + splLabel[i].substring(1) + ' ';
      }
      label = result;
    } else {
      label = label.charAt(0).toLocaleUpperCase() + label.substring(1) + ' ';
    }

    return label;
  };

  const betterValue = (label: string, betterLabel: string, value: any) => {
    let betterValue = JSON.stringify(value).replaceAll('"', '');
    let tooltipValue: string[] = [];
    let prerenderedValue = null;
    label = reduceNameToLastItem(label);
    let matchedBos = bos.filter((x: any) => x.source === label);
    let isAggregation = false;
    if (matchedBos.length > 0) {
      if (matchedBos[0].idBoAggregated) {
        isAggregation = true;
      }
    }

    if (Array.isArray(value)) {
      if (isAggregation) {
        prerenderedValue = value.map((x: {id: string; name: string}, i) => {
          return (
          <React.Fragment key={i}>
            {FormatBoName(x.id, getBoMeta(x.id))}
          </React.Fragment>
          );
        });
      }

      betterValue = value
        .map((x: any) => {
          if (typeof x === 'object' && !Array.isArray(x) && x !== null) {
            return x.name;
          } else {
            return x != null ? x.toString() : ' - ';
          }
        })
        .join(', ');
    }

    if (betterLabel.toLocaleLowerCase().indexOf('Year'.toLocaleLowerCase()) !== -1) {
      betterValue = new Date(+betterValue).getFullYear().toString();
    }
    if (betterLabel.toLocaleLowerCase().indexOf('Document Types'.toLocaleLowerCase()) !== -1) {
      [betterValue, tooltipValue] = getDocumentTypeByKeys();
    }
    if (betterLabel.toLocaleLowerCase().indexOf('Document Sub-Types'.toLocaleLowerCase()) !== -1) {
      [betterValue, tooltipValue] = getDocumentSubTypeByKeys();
    }
    if (betterLabel.toLocaleLowerCase().indexOf('Professional Areas'.toLocaleLowerCase()) !== -1) {
      [betterValue, tooltipValue] = getProfessionalAreasNames();
    }
    if (betterLabel.toLocaleLowerCase().indexOf('Status'.toLocaleLowerCase()) !== -1) {
      betterValue = getDocumentStatusLabelValue(betterValue);
    }
    if (betterLabel.toLocaleLowerCase().indexOf('date'.toLocaleLowerCase()) !== -1) {
      betterValue = new Date(betterValue).toLocaleDateString();
    }

    if (betterLabel.toLocaleLowerCase().indexOf('Countries'.toLocaleLowerCase()) !== -1) {
      [betterValue, tooltipValue] =
        value.length > 1 ? [`${value[0].name} (+${value.length - 1})`, value.map((val) => val.name)] : [`${value[0].name}`, []];
    }

    if (!isAggregation) {
      return [betterValue.replaceAll('_', ' | '), tooltipValue];
    } else {
      return [prerenderedValue, tooltipValue];
    }
  };

  let betterLabelItem = betterLabel(props.label);

  const backwardsPathKillCleaner = (path: string) => {
    // when deleting professional area, kill also doc type and subtype
    if (path === 'document.professionalAreaIds') {
      path =
        'document.professionalAreaIds;document.bOs;document.documentTypeIds;document.documentSubTypeIds;document.documentCode;document.metadata';
    }
    if (path === 'document.documentTypeIds') {
      path = 'document.documentTypeIds;document.documentSubTypeIds';
    }
    return path;
  };

  let betterLabelFinal: string = betterLabelItem.trim();
  let hideBox = false;
  if (props.trickFilter && betterLabelFinal === 'Document Types') {
    hideBox = true;
  }
  if (props.trickFilter && betterLabelFinal === 'Document Sub-Types') {
    betterLabelFinal = 'Document Types';
  }

  let betterValues = betterValue(props.label, betterLabelItem, props.labelValue);
  let tooltipId = 'tooltip-' + betterLabel;
  const calloutProps = {gapSpace: 0};
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <ul>
        {betterValues[1].map((value, i) => (
          <li key={i}>{value}</li>
        ))}
      </ul>
    ),
  };

  return (
    <>
      {betterValues[1].length > 0 && (
        <TooltipHost id={tooltipId} calloutProps={calloutProps} tooltipProps={tooltipProps} directionalHint={DirectionalHint.bottomCenter}>
          <div style={{display: 'inline-block'}} aria-describedby={tooltipId}>
            {!hideBox && (
              <div className="search-tags-tag-wrap-outer">
                <div className="search-tags-tag-wrap">
                  <div className="search-tags-tag-inner">
                    <div className="search-tags-label">{betterLabelFinal + ': '}</div>
                    <div className="search-tags-label-value">{betterValues[0]}</div>
                  </div>
                  <div
                    className="search-tags-exit-button"
                    onClick={() => {
                      props.onRemove(backwardsPathKillCleaner(props.label));
                    }}>
                    X
                  </div>
                </div>
              </div>
            )}
          </div>
        </TooltipHost>
      )}
      {betterValues[1].length === 0 && (
        <div style={{display: 'inline-block'}} aria-describedby={tooltipId}>
          {!hideBox && (
            <div className="search-tags-tag-wrap-outer">
              <div className="search-tags-tag-wrap">
                <div className="search-tags-tag-inner">
                  <div className="search-tags-label">{betterLabelFinal + ': '}</div>
                  <div className="search-tags-label-value">{betterValues[0]}</div>
                </div>
                <div
                  className="search-tags-exit-button"
                  onClick={() => {
                    props.onRemove(backwardsPathKillCleaner(props.label));
                  }}>
                  X
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export interface SearchTagsControlProps {
  query: string; // this is always a JSON string
  onRemove: (path: string, isLast: boolean) => void;
}

interface KeyValue {
  key: string;
  value: any;
}

const SearchTagsControl = (props: SearchTagsControlProps) => {
  const excludeFiltersFromDisplay = [
    'limit',
    'offset',
    'queryColumn',
    'sortBy',
    'sortDesc',
    'isPublic',
    // Discard metadata, it must be processed separately
    'document.metadata',
  ];
  const hierarchy = useSelector<GlobalState, HierarchyLibrary[]>((state) => state.generic.libraryHierachyModel);

  const [inferredState, setInferredState] = useState<any>({});
  const [validFields, setValidFields] = useState<{key: string; value: any}[]>([]);
  const [trickFilter, setTrickFilter] = useState<boolean>(false);

  useEffect(() => {
    let object: any = JSON.parse(props.query);
    let deserializedObject: KeyValue[] = DeserializeObject(object);
    let validFields_ = deserializedObject.filter((x: KeyValue) => {
      if (excludeFiltersFromDisplay.filter((y: string) => x.key.indexOf(y) !== -1).length > 0 || x.key.endsWith('.id')) {
        return false;
      }

      let asStr: string = JSON.stringify(x.value);
      return asStr !== '' && asStr !== '""' && asStr !== '[]' && asStr !== 'null';
    });

    // Process metadata
    const metadata = {};
    for (const m of deserializedObject.filter((x: KeyValue) => x.key.includes('document.metadata'))) {
      injectValue(metadata, m.key.replace(/^document\.metadata\./, ''), m.value);
    }
    for (const key in metadata) {
      if (Object.prototype.hasOwnProperty.call(metadata, key)) {
        const m: IMetadataValue = metadata[key];
        const value = extractMetadataValue(m);
        validFields_.push({
          key: 'document.metadata.' + key,
          value,
        });
      }
    }

    setValidFields(validFields_);
  }, [props.query]);

  const tryInferState = () => {
    let toInferState = {};
    for (let i = 0; i < validFields.length; i++) {
      let field: {key: string; value: any} = validFields[i];
      if (field.key === 'document.professionalAreaIds') {
        toInferState['professionalAreaIds'] = field.value;
      }
      if (field.key === 'document.documentTypeIds') {
        toInferState['documentTypeIds'] = field.value;
      }
      if (field.key === 'document.documentSubTypeIds') {
        toInferState['documentSubTypeIds'] = field.value;
      }
    }
    setInferredState(toInferState);
  };

  useEffect(() => {
    let profAreas = validFields.filter((x: {key: string; value: any}) => x.key === 'document.professionalAreaIds');
    let trickF = false;
    if (profAreas.length > 0) {
      let areaValues = profAreas[0].value;
      if (areaValues.length === 1) {
        let av = areaValues[0];

        for (let i = 0; i < hierarchy.length; i++) {
          let l: HierarchyLibrary = hierarchy[i];
          for (let j = 0; j < l.professionalAreas.length; j++) {
            let a: HierarchyProfessionalArea = l.professionalAreas[j];
            if (a.id === av) {
              trickF = a.documentTypes.length === 1;
              break;
            }
          }
        }
      }
    }

    setTrickFilter(trickF);

    tryInferState();
  }, [validFields]);

  return (
    <div className="search-tags-control-main-wrap">
      <div className="search-tags-control-inner-wrap">
        {validFields.map((x: {key: string; value: any}, i: number) => {
          return (
            <TagButton
              inferredState={inferredState}
              trickFilter={trickFilter}
              key={i}
              label={x.key}
              labelValue={x.value}
              onRemove={(path: string) => {
                props.onRemove(path, validFields.length === 1);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SearchTagsControl;
