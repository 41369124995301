import './InputInfoIniter.scss';
import {useState} from 'react';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/AjaxService';
import {InputInfo} from '../../Models/InputInfo';
import {Spinner} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import {GenericActions} from '../../Reducers/Generic/GenericAction';

export interface InputInfoIniterProps {
  keyString: string;
  page: string;
  initialValue: string;
  emit: (content: string) => void;
}

const InputInfoIniter = (props: InputInfoIniterProps) => {
  const [comment, setComment] = useState<string>(props.initialValue);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const reloadInputInfos = () => {
    /** API call to get input infos */
    ApiService.InputInfoController.getInputInfo((response: IAPIResponse) => {
      if (response.error == null) {
        dispatch(GenericActions.setInputInfos(response.payload));
      }
    });
  };

  const dispatch = useDispatch();
  let generic = useSelector((state: GlobalState) => state.generic);

  const addInputInfo = () => {
    if (comment.length < 10) {
      return;
    }

    let inpId: string = 'new';
    let matchedInput: InputInfo[] = generic.inputInfos.filter((x: InputInfo) => x.key === props.keyString && x.page === props.page);
    if (matchedInput.length > 0) {
      inpId = matchedInput[0].id;
    }

    setLoading(true);
    let inputInfo: InputInfo = {
      id: inpId,
      key: props.keyString,
      page: props.page,
      message: comment,
    };
    ApiService.InputInfoController.setInputInfo(inputInfo, (response: IAPIResponse) => {
      setLoading(false);
      if (response.error === null) {
        reloadInputInfos();
        setSuccess(true);
        props.emit(comment);
      }
    });
  };

  return (
    <div className="input-info-input-wrap">
      <div className="input-info-input-title">
        <div>Admin Quick Fix:</div>
      </div>
      {success && (
        <div className="input-info-input-subtitle">
          <strong>Success!</strong>
          <div>Tooltip created. You will see this Tooltip on your next visit.</div>
        </div>
      )}
      {!success && loading && (
        <div className="input-info-input-spinner">
          <Spinner label="Adding Tooltip..." labelPosition="right" />
        </div>
      )}
      {!success && !loading && (
        <div>
          <div className="input-info-input-subtitle">This Info Tooltip has no message. Please, insert it.</div>
          <div className="input-info-input-parameters">
            Parameters are key={props.keyString}, page={props.page}
          </div>
          <div className="input-info-input-line">
            <textarea
              className="input-info-input-content"
              placeholder={'Insert Tooltip'}
              rows={4}
              maxLength={1000}
              onChange={(e: any) => {
                setComment(e.target.value);
              }}
              defaultValue={comment}
            />

            <div
              className={'input-info-input-button ' + (comment.length < 10 ? 'input-info-input-button-disabled' : '')}
              onClick={addInputInfo}>
              Save
            </div>
          </div>
          <div className="input-info-input-hint">Min lenght required 10 characters.</div>
        </div>
      )}
    </div>
  );
};

export default InputInfoIniter;

<ul>
  <li>
    <strong>Public</strong>: visibile to all professional areas (whitelist not available);
  </li>
  <li>
    <strong>Restricted Professional Area</strong>: visible only to your selected Professional Area. You can add people outside your selected
    professional area using the field "Whitelist";
  </li>
  <li>
    <strong>Restricted Whitelist</strong>: visible only to people in the field <strong>Whitelist</strong>.
  </li>
</ul>;
