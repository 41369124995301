import {HierarchyLibrary, IBoColor, ILibrary} from '../../Models/ILibrary';
import {InputInfo} from '../../Models/InputInfo';
import {IUser} from '../../Models/IUser';
import {IWorkflowItem} from '../../Models/IWorkflowItem';
import {IVideoTutorial} from '../../Models/IVideoTutorial';
import {IFeatureFlag} from '../../Models/IFeatureFlag';

export interface GenericState {
  inputInfos: InputInfo[];
  allUsers: IUser[];
  reloadRequest: boolean;
  sortSearchBy: string;
  sortDirectionDesc: boolean;
  documentUploadApprovalFlag: number;
  documentsDriveViewSize: number;
  libraryHierachyModel: HierarchyLibrary[];
  libraryHierachyModelUserCanAccess: HierarchyLibrary[];
  tightErrorBos: boolean;
  tightErrorCountry: boolean;
  tasks: IWorkflowItem[];
  boColors: IBoColor[];
  videosTutorial: IVideoTutorial[];
  waitingLogIn: boolean;
  featureFlags: IFeatureFlag[],
  allCleanLibraries: ILibrary[],
}

export const DEFAULT_GENERIC_STATE: GenericState = {
  inputInfos: [],
  allUsers: [],
  reloadRequest: false,
  sortSearchBy: 'score',
  sortDirectionDesc: true,
  documentUploadApprovalFlag: 0,
  documentsDriveViewSize: 10,
  libraryHierachyModel: [],
  libraryHierachyModelUserCanAccess: [],
  tightErrorBos: false,
  tightErrorCountry: false,
  tasks: [],
  boColors: [],
  videosTutorial: [],
  waitingLogIn: false,
  featureFlags: [],
  allCleanLibraries: [],
};
