import {ITag} from '@fluentui/react';
import {IBoValue} from '../../Models/IDocument';
import { Dispatch } from 'react';

export interface IMasterDataTag extends ITag {
  key: string; // logical & react key
  associatedId?: string; // used by the backend to do the second api call to autocomplete
  groupId: string; // used by the frontend to group items
}

export interface IMasterdataResponseCheck extends IMasterDataResponse{
  checked: boolean
}

export interface IMasterDataResponse {
  id: string;
  name: string;
  associatedId: string;
}

export interface MasterDataDirection {
  startMaster: {get: IMasterDataTag[], set: Dispatch<IMasterDataTag[]>}
  targetMaster: {get: IMasterDataTag[], set: Dispatch<IMasterDataTag[]>}
}

export const masterDataConvertResponseToTag = (response: IMasterDataResponse[], groupId?: string): IMasterDataTag[] => {
  return response.map((x) => {
    return {
      key: x.id,
      name: x.name,
      associatedId: x.associatedId,
      groupId: groupId ?? crypto.randomUUID(), // When I add the first element I random generate the groupId, when I add the linked item I use the same groupId
    };
  });
};

export const iBoValueToIMasterDataTag = (masterDatas: IBoValue[]): IMasterDataTag[] => {
  return masterDatas.map((masterData) => {
    return {
      name: masterData.name,
      key: masterData.id,
      associatedId: undefined,
      groupId: masterData.groupId,
    };
  });
};

export const iMasterDataTagToIBoValue = (masterDatas: IMasterDataTag[]): IBoValue[] => {
  return masterDatas.map((masterData) => {
    return {
      name: masterData.name,
      id: masterData.key,
      groupId: masterData.groupId,
    };
  });
};
