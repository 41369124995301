import {useNavigate} from 'react-router-dom';
import {RecentSearchItem} from '../../Models/RecentSearch';
import AppRoutes from '../../Utils/AppRoutes';
import {getUploadIcon} from '../FileUploadBoxPreview/FileUploadBoxPreview';
import moment from 'moment';
import './FileMiniPreview.scss';

export interface FileMiniPreviewProps {
  item: RecentSearchItem;
  onMouseLeave: () => void;
  onMouseEnter: () => void;
}

const FileMiniPreview = (props: FileMiniPreviewProps) => {
  const navigate = useNavigate();

  const formatDate = () => {
    let todayYear = new Date().getFullYear();
    let momentDate = moment(props.item.date);
    let momentYear = momentDate.year();
    if (momentYear === todayYear) {
      let almost = momentDate.format('LL');
      let almostClean = almost.split(',')[0];
      let almostCleanSpl = almostClean.split(' ');
      if (almostCleanSpl.length == 2) {
        return almostCleanSpl[1] + ' ' + almostCleanSpl[0].substring(0, 3);
      } else {
        return almostCleanSpl.reverse().join(' ');
      }
    } else {
      return momentDate.format('dd/mm/yyyy');
    }
  };

  return (
    <div
      className="file-mini-preview-main-wrap"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={() => {
        navigate(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + props.item.documentId.toString());
      }}
      title={props.item.documentName}>
      <div className="file-mini-preview-inner-wrap">
        <div className="file-mini-preview-icon">{getUploadIcon('.' + props.item.documentExtension)}</div>
        <div className="file-mini-preview-name">{props.item.documentName}</div>
        <div className="file-mini-preview-date">{formatDate()}</div>
      </div>
    </div>
  );
};

export default FileMiniPreview;
