import {LabelButton} from '@Eni/docware-fe-master';
import { useState } from 'react';
import {IBoValue} from '../../Models/IDocument';
import {Spinner} from '@fluentui/react';

interface TerritorialMasterDataLinkBosProps {
  onClick: () => Promise<void>
}

const TerritorialMasterDataLinkBos = ({onClick}: TerritorialMasterDataLinkBosProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  

  return (
    <>
    {loading ? (
    <Spinner label="Adding Linked Business Objects..." labelPosition="right" />
    ) : (
      <LabelButton
        whiteOutlined
        icon={'Add'}
        text="Add Linked Business Objects"
        onClick={async () => {
          setLoading(true);
          await onClick();
          setLoading(false);
        }}
      />
    )}

    </>
  );
};

export default TerritorialMasterDataLinkBos;
