import {LabelButton} from '@Eni/docware-fe-master';
import './FileSelectArea.scss';
import clsx from 'clsx';

export interface FileSelectAreaProps {
  buttonIcon: JSX.Element;
  dropAreaTitle: JSX.Element | string;
  buttonLabel: string;
  disabled?: boolean;
}

export const FileSelectArea = (props: FileSelectAreaProps) => {
  return (
    <div className={clsx("file-select-area", {"disabled": props.disabled} )} >
      <div>{props.buttonIcon}</div>
      <div>{props.dropAreaTitle}</div>
      <LabelButton text={props.buttonLabel} orangeSolid icon={'OpenFile'} disabled={props.disabled}/>
    </div>
  );
};
