import {DOCUMENT_URLS} from '../../Constants/Urls';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from '../AjaxService';
import {XWARE_SEISMIC_SURVEY} from '../../Constants/MasterData';

export interface IIntegrationQueryObject {
  integrationId: string;
  search: string;
  limit: number;
  offset: number;
  queryColumn: string;
}

const IntegrationsController = {
  getIntegrationHealthCheck: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_INTEGRATION_HEALTH_CHECK_UR}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getAllCountries: async (callback?: (response: IAPIResponse) => void) => {
    let queryObject = {
      integrationId: 'xware_countries',
      search: '',
      limit: 500,
      offset: 0,
      queryColumn: 'name',
    };
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_INTEGRATION_URL}${queryObject.integrationId}`;
    request.method = 'post';
    request.payload = queryObject;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getBOValues: async (queryObject: IIntegrationQueryObject, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_INTEGRATION_URL}${queryObject.integrationId}`;
    request.method = 'post';
    request.payload = queryObject;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getIntegrationBo: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_INTEGRATION_URL}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getMasterDataValues: async (
    integrationId: string,
    search: string,
    searchAssociatedMasterData: boolean,
    countriesIds: string[],
    isOnSearch: boolean,
    callback?: (response: IAPIResponse) => void
  ): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_INTEGRATION_URL}${integrationId}`;
    request.method = 'post';
    request.payload = {
      search: search,
      searchAssociatedMasterData: searchAssociatedMasterData,
      countryCodes: countriesIds.join(';'),
      limit: 9999,
      offset: 0,
      isAdvancedSearch: (isOnSearch && integrationId == XWARE_SEISMIC_SURVEY) ? true : false
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getTerrytorialSurveyLinkedBos: async (territorialSurveyIds: string[], callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_INTEGRATION_TERRITORIAL_SURVEY_LINKED_BOS_URL}`;
    request.method = 'POST';
    request.payload = {
      ids: territorialSurveyIds
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default IntegrationsController;
