import {ITag} from '@fluentui/react';
import {ICountryAdmin} from '../Models/IUser';
export const getAdminCountries = (text: string, selectedItems: ITag[], countries: ICountryAdmin[]): ITag[] => {
  if (text.length < 3) {
    return [];
  }

  const matches = countries.filter(country => (
    country.name.toLowerCase().indexOf(text.toLowerCase()) !== -1 &&
    selectedItems.filter((x) => x.key === country.code).length === 0 
  ))
  return matches.map(m => ({key: m.code, name: m.name}))

};