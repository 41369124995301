import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse, silentLevels} from '../AjaxService';

const DocumentEditLockController = {
  lock: async (documentId: string, callback: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.method = 'PUT';
    request.url = `/documenteditlock/${documentId}/lock`;
    const response = await AjaxService.call(request);
    callback(response);
  },
  renew: async (documentId: string, callback: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.method = 'PUT';
    request.url = `/documenteditlock/${documentId}/lock/renew`;
    request.silentLevel = silentLevels.NO_UI_INTERACTIONS;
    const response = await AjaxService.call(request);
    callback(response);
  },
  unlock: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.method = 'PUT';
    request.url = `/documenteditlock/${documentId}/unlock`;
    const response = await AjaxService.call(request);
    if (callback) callback(response);
  },
};

export default DocumentEditLockController;
