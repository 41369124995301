import {IMIStagingArea} from '../../Models/MassiveImport/IMassiveImport';
import {IFilePage} from '../../Models/MassiveImport/IMIFile';
import {IMetadataPage} from '../../Models/MassiveImport/IMIMetadata';

export interface MassiveImportState {
  stagingArea: IMIStagingArea;
  filePage: IFilePage;
  metadataPage: IMetadataPage;
}

export const DEFAULT_MASSIVE_IMPORT_STATE: MassiveImportState = {
  stagingArea: {} as IMIStagingArea,
  filePage: {
    files: [],
    activeFiles: [],
  } as IFilePage,
  metadataPage: {
    metadata: [],
    activeMetadata: undefined,
    needRefreshMetadata: false,
    metadataUploadValidation: undefined,
  } as IMetadataPage,
};
