import {Spinner} from '@fluentui/react';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import GenericList from '../../../Components/GenericList/GenericList';
import {LabelButton} from '@Eni/docware-fe-master';
import './SysLogsList.scss';
import {useEffect, useState} from 'react';

const SysLogsList = (props: any) => {
  const [limit, setLimit] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [canSeeButton, setCanSeeButton] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ApiService.GenericController.getSystemLogs(limit, (response: IAPIResponse) => {
      if (response.error === null) {
        let parsed: any = [];
        for (let i = 0; i < response.payload.length; i++) {
          let item: any = response.payload[i];
          item.statusString = item.status === 0 ? 'Started' : item.status === 1 ? 'Error' : 'Complete';
          parsed.push(item);
        }

        setRows(parsed);

        setCanSeeButton(response.payload.length === limit);
      }
      setLoading(false);
    });
  }, [limit]);

  return (
    <div>
      <div className="users-list-page-title">System Logs</div>
      {loading && (
        <div className="sys-logs-list-center-item">
          <Spinner label="Loading..." />
        </div>
      )}
      {!loading && (
        <GenericList
          columns={[
            {
              name: 'Procedure Type',
              dataType: 'string',
              fieldName: 'procedureType',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Start Date',
              dataType: 'date',
              fieldName: 'startDate',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'End Date',
              dataType: 'date',
              fieldName: 'endDate',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Status',
              dataType: 'string',
              fieldName: 'statusString',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Message',
              dataType: 'string',
              fieldName: 'message',
              iconName: null,
              size: 'large',
            },
          ]}
          items={rows}
        />
      )}
      <div>
        {canSeeButton && (
          <div className="sys-logs-list-center-item">
            <LabelButton
              text="Load more"
              icon="ChevronDown"
              onClick={() => {
                setLimit(limit + 100);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SysLogsList;
