import {Banner, FileList as FileListC, LabelButton, MenuTabs, ModalDialog} from '@Eni/docware-fe-master';
import './NewLibExtraMetadata.scss';
import AppRoutes, {concatUrl} from '../../../Utils/AppRoutes';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ILibraryNewLib, IMetadata, IOptionMetadata, LibraryFunctions, MetadataType} from '../../../Models/ILibrary';
import {IconButton, SelectionMode, TextField} from '@fluentui/react';
import {getItemWithDefaultsValues, ModalMode} from '../NewLibPage';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import InputInfoWidget from '../../../Components/InputInfoWidget/InputInfoWidget';
import NavButtons from '../../../Components/NavButtons/NavButtons';
import ApiService from '../../../Services/ApiService';
import {IAPIResponse} from '../../../Services/AjaxService';
import EventDispatcher from '../../../Utils/EventDispatcher';

interface NewLibExtraMetadataProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
  selectedMetadata: IMetadata;
  setSelectedMetadata: Dispatch<SetStateAction<IMetadata>>;
  showEditModal: boolean;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  existingMetadata: string[];
  createMode: boolean;
}

const NewLibExtraMetadata = (props: NewLibExtraMetadataProps) => {
  const navigate = useNavigate();
  const [newMetadata, setNewMetadata] = useState<IMetadata>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const saveLibrary = () => {
    setSaveDisabled(true);
    const libraryForBe = LibraryFunctions.ILibraryNewLibToBackendFormat(props.currentLibrary);
    if (props.createMode) {
      ApiService.LibraryController.createLibrary(libraryForBe, (response: IAPIResponse) => {
        if (response.error === null) {
          navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_LIBRARY));
        } else {
          EventDispatcher.dispatchToast(response.error, 'error');
        }
      });
    } else {
      ApiService.LibraryController.editLibrary(libraryForBe, (response: IAPIResponse) => {
        if (response.error === null) {
          navigate(concatUrl(AppRoutes.ADMIN_ROUTE, AppRoutes.ADMIN_ROUTE_LIBRARY));
        } else {
          EventDispatcher.dispatchToast(response.error, 'error');
        }
      });
    }
  };

  const libraryIsValid = (library: ILibraryNewLib): boolean => {
    return (
      library?.name != undefined
      && library?.securityLevels?.length > 0 
      && library?.documentTypes?.length > 0
      && library?.documentSubTypes?.length > 0 
      && library?.availableSignatures?.length > 0 
      && library?.defaultSignature != undefined
    );
  };

  useEffect(() => {
    setSaveDisabled(!libraryIsValid(props.currentLibrary));
  }, [props.currentLibrary]);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: true,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <LabelButton
          text="Add"
          icon="Add"
          onClick={() => {
            setShowCreateModal(true);
          }}
        />
        <FileListC
          defaultSortOnThisColumnIndex={0}
          fileIconFromField={''}
          onItemsSelected={(selectedRows: IMetadata[]) => {
            if (selectedRows.length === 0) {
              props.setSelectedMetadata(undefined);
            }
            if (selectedRows.length === 1) {
              props.setSelectedMetadata(selectedRows[0]);
            }
          }}
          currentSelectedItems={undefined}
          currentSelectedItem={props.selectedMetadata}
          hideBottomButton={true}
          selectionMode={SelectionMode.single}
          tableName={'normal'}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'displayName',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Locked On New Version',
              dataType: 'string',
              fieldName: 'lockedOnNewVersion',
              iconName: null,
              size: 'medium',
              onRender: (item: IMetadata) => {
                return item.lockedOnNewVersion?.toString();
              },
            },
            {
              name: 'Searchable',
              dataType: 'boolean',
              fieldName: 'searchable',
              iconName: null,
              size: 'small',
              onRender: (item: IMetadata) => {
                return item.searchable?.toString();
              },
            },
            {
              name: 'Required',
              dataType: 'boolean',
              fieldName: 'required',
              iconName: null,
              size: 'small',
              onRender: (item: IMetadata) => {
                return item.required?.toString();
              },
            },
            {
              name: 'Type',
              dataType: 'boolean',
              fieldName: 'type',
              iconName: null,
              size: 'medium',
              onRender: (item: IMetadata) => {
                return MetadataType[item.type];
              },
            },
            {
              name: 'Values',
              dataType: 'boolean',
              fieldName: 'values',
              iconName: null,
              size: 'large',
              onRender: (item: IMetadata) => {
                return item.values?.map((x) => x.name).join(', ');
              },
            },
          ]}
          items={props.currentLibrary?.metadata ?? []}
        />
      </div>

      <NavButtons
        onBackClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT)}
        onNextClick={saveLibrary}
        nextLabel="Save"
        nextIcon="Save"
        nextDisabled={saveDisabled}
      />

      <ModalMetadata
        mode={ModalMode.Edit}
        metadata={props.selectedMetadata}
        setMetadata={props.setSelectedMetadata}
        showModal={props.showEditModal}
        setShowModal={props.setShowEditModal}
        existingMetadata={props.existingMetadata}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const updatedMetadata = props.currentLibrary?.metadata.map((x) =>
            x.name == props.selectedMetadata.name ? props.selectedMetadata : x
          );
          props.updateLibrary('metadata', updatedMetadata);
        }}
      />

      <ModalMetadata
        mode={ModalMode.Create}
        metadata={newMetadata}
        setMetadata={setNewMetadata}
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        existingMetadata={props.existingMetadata}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const emptyMetadata: IMetadata = {
            name: crypto.randomUUID(),
            displayName: '',
            inputPositioning: 2,
            lockedOnNewVersion: false,
            searchable: false,
            showAdvancedFilterInFE: false,
            required: false,
            type: MetadataType.Text,
            values: [],
            addedInThisSession: true,
          };
          const metadata = getItemWithDefaultsValues(emptyMetadata, newMetadata);

          props.updateLibrary('metadata', [...(props.currentLibrary?.metadata ?? []), metadata]);
          setNewMetadata(undefined);
        }}
      />
    </>
  );
};

interface ModalMetadataProps {
  mode: ModalMode;
  metadata: IMetadata;
  setMetadata: Dispatch<SetStateAction<IMetadata>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  existingMetadata: string[];
  currentLibrary: ILibraryNewLib;
  onConfirm: Function;
}

const ModalMetadata = (props: ModalMetadataProps) => {
  const fieldDisabled = props.mode == ModalMode.Edit && !props.metadata?.addedInThisSession;
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  return (
    <ModalDialog
      modalTitle={`${props.mode == ModalMode.Create ? 'Create' : 'Edit'} Extra Metadata`}
      modalInnerComponent={
        <>
          <FluentUIDecorator
            label="Name"
            info={getInputInfos('new-lib', 'metadata-display-name')}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              value: props.metadata?.displayName,
              onChange: (e, currentValue) => props.setMetadata({...props.metadata, displayName: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Locked On New Version"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'metadata-locked-on-new-version')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              defaultChecked: props.metadata?.lockedOnNewVersion,
              onChange: (e, currentValue) => props.setMetadata({...props.metadata, lockedOnNewVersion: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Searchable"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'metadata-searchable')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              defaultChecked: props.metadata?.searchable,
              onChange: (e, currentValue) => props.setMetadata({...props.metadata, searchable: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Required"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'metadata-required')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              defaultChecked: props.metadata?.required,
              onChange: (e, currentValue) => props.setMetadata({...props.metadata, required: currentValue}),
            })}
          />
          <FluentUIDecorator
            label={'Type'}
            info={getInputInfos('new-lib', 'metadata-type')}
            fluentComponent={FluentUIDecoratorTypes.Dropdown({
              disabled: fieldDisabled,
              placeholder: 'Select Metadata Type',
              options: [
                {key: MetadataType.Text, text: 'Text'},
                {key: MetadataType.Date, text: 'Date'},
                {key: MetadataType.Select, text: 'Select'},
                {key: MetadataType.MultiSelect, text: 'MultiSelect'},
                {key: MetadataType.AutoIncrement, text: 'AutoIncrement'},
                {key: MetadataType.TagPickerSingleSelect, text: 'TagPickerSingleSelect'},
              ],
              selectedKey: props.metadata?.type,
              onChange: (e, currentValue) => {
                const selectedKey = Number(currentValue.key);
                let values = props.metadata.values;
                if ([MetadataType.Text, MetadataType.Date, MetadataType.AutoIncrement].includes(selectedKey)) {
                  values = [];
                }
                props.setMetadata({...props.metadata, type: selectedKey, values: values});
              },
            })}
          />
          {[MetadataType.Select, MetadataType.MultiSelect, MetadataType.TagPickerSingleSelect].includes(props.metadata?.type) && (
            <MetadataValues
              metadataValues={props.metadata?.values ?? []}
              setMetadataValues={(metadataValues: IOptionMetadata[]) => {
                props.setMetadata({...props.metadata, values: metadataValues});
              }}
            />
          )}

          <Banner
            enabled={showErrorBanner}
            type="error"
            message={
              "The Metadata name must be the unique across all libraries and can't be blank. " +
              'The type must be selected.' +
              "The metadata values can't be blank"
            }
          />
        </>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            props.setShowModal(false);
          },
        },
        {
          label: 'Confirm',
          onClick: () => {
            if (props.mode === ModalMode.Create) {
              const metadataDisplayName = props.metadata?.displayName.replace(/^\s+|\s+$/gm, '');
              const metadataType = props.metadata?.type;
              const currentLibraryProfAreas = LibraryFunctions.getMetadataNames([props.currentLibrary]);
              if (
                !metadataDisplayName ||
                typeof metadataType === 'undefined' ||
                props.existingMetadata.includes(metadataDisplayName) ||
                currentLibraryProfAreas.includes(metadataDisplayName)
              ) {
                setShowErrorBanner(true);
                return;
              }
            }

            const metadataEmptyValues =
              props.metadata?.values?.filter((x) => x.name.replace(/^\s+|\s+$/gm, '') === '' && x.addedInThisSession) ?? [];
            if (metadataEmptyValues.length > 0) {
              setShowErrorBanner(true);
              return;
            }

            props.onConfirm();
            props.setShowModal(false);
          },
        },
      ]}
      onAbort={() => {
        props.setShowModal(false);
      }}
      enableModal={props.showModal}
    />
  );
};

interface MetadataValuesProps {
  metadataValues: IOptionMetadata[];
  setMetadataValues: Dispatch<SetStateAction<IOptionMetadata[]>>;
}

const MetadataValues = (props: MetadataValuesProps) => {
  return (
    <>
      <div className="fluent-ui-decorator-label">
        Values
        <InputInfoWidget keyString={'new-lib'} page={'metadata-values'} />
        {props.metadataValues.map((x) => (
          <div style={{display: 'grid', gridTemplateColumns: '14fr 1fr', paddingBottom: '0.5em'}}>
            <TextField
              disabled={!x.addedInThisSession}
              value={x.name}
              onChange={(e, currentValue) => {
                const valuesUpdated: IOptionMetadata[] = props.metadataValues.map((value) => {
                  if (value.id === x.id) {
                    return {...x, name: currentValue};
                  } else {
                    return value;
                  }
                });
                props.setMetadataValues(valuesUpdated);
              }}
            />
            <IconButton
              disabled={!x.addedInThisSession}
              iconProps={{iconName: 'Delete'}}
              onClick={() => {
                props.setMetadataValues(props.metadataValues.filter((value) => value.id !== x.id));
              }}
            />
          </div>
        ))}
        <LabelButton
          text="Add"
          icon="Add"
          onClick={() => {
            const emptyValue: IOptionMetadata = {
              id: crypto.randomUUID(),
              name: '',
              addedInThisSession: true,
            };
            props.setMetadataValues([...props.metadataValues, emptyValue]);
          }}
        />
      </div>
    </>
  );
};

export default NewLibExtraMetadata;
