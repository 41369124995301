import {LabelButton} from '@Eni/docware-fe-master';
import {IconButton, IPersonaProps} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IPersonaOptionDto} from '../../Models/IOptionDto';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {bonifyEmailField} from '../../Utils/DictUtils';
import {IntegrationUtils} from '../../Utils/IntegrationUtils';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import './EmailPicker.scss';
import {optionsToPersonas, personasToOptions} from '../../Utils/NewDocumentUtils';

export interface IEmailPickerProps {
  onChange: (list: string[]) => void;
}

const RemoveDuplicates = (items: string[]) => {
  let newVect: string[] = [];
  for (let i = 0; i < items.length; i++) {
    if (newVect.indexOf(items[i]) === -1) {
      newVect.push(items[i]);
    }
  }
  return newVect;
};

const EmailPicker = (props: IEmailPickerProps) => {
  const [shareUsers, setShareUsers] = useState<IPersonaOptionDto[]>([]);
  const [seenUsers, setSeenUsers] = useState<any[]>([]);
  const [writtenEmail, setWrittenEmail] = useState<string>('');
  const [emailsFromUsers, setEmailsFromUsers] = useState<string[]>([]);
  const [emailsList, setSmailsList] = useState<string[]>([]);

  const addSeenUsers = (results: any[]) => {
    let usr: any[] = [...seenUsers];
    for (let i = 0; i < results.length; i++) {
      if (usr.filter((x: any) => x.id === results[i].id).length === 0) {
        usr.push(results[i]);
      }
    }
    setSeenUsers(usr);
  };

  const userEmailById = (id: string) => {
    let match: any[] = seenUsers.filter((x) => x.id === id);
    if (match.length > 0) {
      return match[0].email;
    }
    return '';
  };

  const deleteEmailSource = (email: string) => {
    setSmailsList([...emailsList].filter((x) => x !== email));
    setEmailsFromUsers([...emailsFromUsers].filter((x) => x !== email));
    setShareUsers([...shareUsers].filter((x) => userEmailById(x.id) !== email));
  };

  const finalEmails: string[] = [...emailsFromUsers].concat([...emailsList]);

  useEffect(() => {
    props.onChange([...emailsFromUsers].concat([...emailsList]));
  }, [emailsFromUsers, emailsList]);

  return (
    <div>
      <div className="email-picker-upper-message">Search people to share this file with or add their emails manually.</div>
      <FluentUIDecorator
        label="Search people"
        info={getInputInfos('share-document', 'target-people')}
        fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
          selectedUsers: optionsToPersonas(shareUsers),
          onFilterChanged: async (filterText: string, currentPersonas: IPersonaProps[] | undefined, limitResults?: number | undefined) => {
            let results: any[] = await IntegrationUtils.resolvePeoplePickerVoicesNoCast(filterText, currentPersonas);
            addSeenUsers(results);

            let users: IPersonaOptionDto[] = results
              .filter((x) => x.email && x.email !== '')
              .map((u) => {
                return {
                  id: u.id,
                  name: `${u.firstName} ${u.lastName}`,
                  secondaryText: bonifyEmailField(u.email),
                };
              }) as IPersonaOptionDto[];

            return optionsToPersonas(users);
          },
          onPeopleChanged: (p) => {
            if (p) {
              let els: string[] = [];
              for (let i = 0; i < p.length; i++) {
                let email: string = userEmailById(p[i].key.toString());
                if (email !== '') {
                  els.push(email);
                }
              }
              setEmailsFromUsers(RemoveDuplicates(els));
              setShareUsers(personasToOptions(p));
            }
          },
        })}
      />
      <div className="email-picker-line">
        <div style={{width: '100%', marginRight: '1em'}}>
          <FluentUIDecorator
            label="Manual email input"
            info={getInputInfos('share-document', 'manual-email')}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              onChange: (e: any, v: any) => {
                setWrittenEmail(v);
              },
              value: writtenEmail,
              placeholder: 'Add an email',
            })}
          />
        </div>
        <div style={{marginLeft: '1em', marginBottom: '1px'}}>
          <LabelButton
            text="Add"
            icon="Add"
            disabled={!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(writtenEmail)}
            onClick={() => {
              let emails: string[] = [...emailsList];
              emails.push(writtenEmail);
              setSmailsList(RemoveDuplicates(emails));
              setWrittenEmail('');
            }}
            whiteOutlined
          />
        </div>
      </div>
      <div>
        <div className="email-picker-under-title">Current receivers:</div>
        {finalEmails.length === 0 && <div className="email-picker-choose-one">You must choose at least one email.</div>}
        {finalEmails.map((x: string, i: number) => {
          return (
            <div key={i} className="email-picker-row">
              <div style={{marginRight: '1em'}}>
                <IconButton
                  iconProps={{iconName: 'Delete'}}
                  onClick={() => {
                    deleteEmailSource(x);
                  }}
                />
              </div>
              <div>{x}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmailPicker;
