export interface IDocumentTypes {
  id: string;
  ext: string;
  contentTypes: string[];
  previewType: string;
  compressed: boolean;
  mainFileAvailableOn: string[];
  attachmentsAvailableOn: string[];
}

export const DocumentTypeLibraryAll = "__all__"

export class DocumentTypes implements IDocumentTypes{
  id: string = "";
  ext: string= "";
  contentTypes: string[] = [];
  previewType: string= "";
  compressed: boolean = false;
  mainFileAvailableOn: string[] = [];
  attachmentsAvailableOn: string[] = [];
}


export interface IDocumentTypesForLibrary {
  libraryId: string;
  mainFileExts: string[];
  attachmentsExts: string[];
}
