import {DocumentPickerEntry} from '../Components/DocumentPickerInput/DocumentPickerInput';
import {DocumentStatus} from '../Constants/Enums';
import { BOsObject } from './BusinessObject';
import {FileInfo} from './FileUtils';
import {IProfessionalArea, MetadataType} from './ILibrary';
import {IOptionDto, IPersonaOptionDto} from './IOptionDto';
import {IValue} from './IValue';

export interface IDocumentProfessionalArea {
  id: string;
  name: string;
  isPublic: boolean;
}

export enum EDocumentSignatureType {
  DigitalSignature = 0,
  SimpleApproval = 1,
}

export interface IDocumentSignStep {
  order: number;
  users: IPersonaOptionDto[];
}

export interface IDocumentMetadata {
  documentId: string;
  documentCode: string;
  isOldVersion: boolean;
  securityLevel: string;
  library: string;
  professionalArea: IProfessionalArea;
  documentName: string;
  countries: IOptionDto[];
  documentStatus: DocumentStatus;
  documentSource: string;
  documentDate: number;
  authors: IPersonaOptionDto[];
  coOwners: IPersonaOptionDto[];
  existingCoOwners: IPersonaOptionDto[];
  whitelist: IPersonaOptionDto[];
  signers: IDocumentSignStep[];
  description: string;
  remarks: string;
  mainFile: FileInfo | null;
  mainFileForSignature: FileInfo | null;
  tags: string[];
  createdBy: IOptionDto;
  relatedDocuments: DocumentPickerEntry[];
  linkedDocuments: DocumentPickerEntry[];
  metadata?: IMetadataDict;
  metadataString?: string;
  documentTypeId: string;
  documentSubTypeId: string;
  documentTypeName: string;
  documentSubTypeName: string;
  replacedBy: string;
  workflowSignatureType: EDocumentSignatureType;
}

export interface IBoValue extends IValue {
  groupId?: string; // only used by master data
}

export interface IBoS{
  [type: string]: IBoValue[]
}

export interface INewDocument extends IDocumentMetadata {
  bOs: IBoS;
  attachments: FileInfo[];
  versionTrack: string;
  documentTypeId: string;
  documentActivation: number;
  updateHistory: IDocumentEditHistoryItem[];
  alternativeRoutes: string[];
  lockByUsers: IValue[];
  redirectEditUrl?: string;
  sourceAppName?: string;
  versionNumber?: number;
  mainDocumentFileHash: string;
}

export interface IDocumentEditHistoryItem {
  editedBy: {id: string; name: string};
  optionalEditInfo: string;
  updateDate: string;
}

export interface IDocumentItem {
  bOs: BOsObject;
  countries: IValue[];
  documentId: string;
  documentCode: string;
  professionalArea: IProfessionalArea;
  key: string;
  documentName: string;
  value: string;
  alternativeRoutes: string[];
  iconName: string;
  fileType: string;
  isOldVersion: boolean;
  createdBy: any;
  documentSource: string;
  relatedDocuments: DocumentPickerEntry[];
  linkedDocuments: DocumentPickerEntry[];
  modifiedBy: string;
  creationDate: string;
  updateDate: string;
  dateModified: string;
  fileSize: string;
  fileSizeRaw: number;
  status: string;
  mainFile: FileInfo;
  mainFileForSignature: FileInfo;
  attachments: FileInfo[];
  documentStatus: number;
  updateHistory: IDocumentEditHistoryItem[];
  authors: IOptionDto[];
  versionNumber?: number;
}

export interface IMetadataDict {
  [key: string]: IMetadataValue;
}

export interface IMetadataValue {
  type: MetadataType;
  // value: any; // string | boolean | number | IOptionDto | IOptionDto[];
  stringValue?: string;
  boolValue?: boolean;
  numberValue?: number;
  selectValue?: IOptionDto;
  dateValue?: number;
  multiselectValue?: IOptionDto[];
}

export interface IBoQueryDocument {
  type: string;
  values: {
    boId: string; 
    name: string;
    additionalInfo: string[];
  }[];
}

export interface IDocumentQueryDocument {
  professionalArea: {areaId: string; name: string; isPublic: boolean};
  countries: any[];
  authors: any[];
  bOs: IBoQueryDocument[];
  content: string;
  createdBy: any;
  contentType: string;
  creationDate: string;
  description: string;
  remarks: string;
  documentDate: string;
  documentId: string;
  documentName: string;
  documentStatus: number;
  documentType: IValue;
  documentSubType: IValue;
  fileName: string;
  fileSize: number;
  library: string;
  tags: string[];
  updateDate: string;
  isOldVersion: boolean;
  replacedBy: string;
  attachments: any[];
  isFavorite: boolean;
  latitude: number | null;
  longitude: number | null;
  versionNumber?: number;
}

export interface IDocumentQuery {
  score: number;
  highlights: any[];
  document: IDocumentQueryDocument;
}

export interface IDocumentVersionItem {
  version: number;
  referenceId: string;
}

export interface IDocumentVersion {
  id: string;
  versions: IDocumentVersionItem[];
}

export interface IDocumentLock {
  documentId: string;
  intervalID: NodeJS.Timer;
}

// all the item should be nullable
export interface INewDocumentPreFillData {
  professionalAreaId?: string;
  countriesId?: string[];
  documentName?: string;
  documentTypeId?: string;
  documentSubTypeId?: string;
  documentDate?: number;
  bOs?: {[type: string]: string[]};
}
