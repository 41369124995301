import './DownloadBlobPage.scss';
import {useParams} from 'react-router-dom';
import {useEffect, useRef} from 'react';
import {APISettings} from '../../Services/AjaxService';

const DownloadBlobPage = () => {
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const {blobId} = useParams();

  useEffect(() => {
      const timer1 = setTimeout(() => {
        console.log("Automatically click")
        anchorRef.current.click()
      }, 3000);
      return () => {
        clearTimeout(timer1);
      };
  }, []);

  return (
    <div className="download-blob-container">
      <span className="download-blob-text">
        The download will start automatically soon. You can click{' '}
        <a ref={anchorRef} href={`${APISettings.baseUrl}/download/${blobId}`}>
          here
        </a>{' '}
        to start it manually.
      </span>
    </div>
  );
};

export default DownloadBlobPage;
