import {useRef, useState} from 'react';

import './DragAndDrop.scss';
import {ModalDialog} from '@Eni/docware-fe-master';
import {FileDrop} from './FileDrop/FileDrop';

export interface DragAndDropProps {
  onDropAndClick: (selectedFiles: File[], unzipFiles?: boolean) => any;
  inputAccept?: string;
  inputMultiFileSelect: boolean;
  elementToRender: JSX.Element;
  checkFileUnZip?: boolean;
}

const ZIP_FORMATS = ['zip', 'rar', 'zip2'];

const DragAndDrop = (props: DragAndDropProps) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [showZipModal, setShowZipModal] = useState<boolean>(false);
  const [selectedFilesForUnZipModal, setSelectedFilesForUnZipModal] = useState<File[]>([]);

  const inputOnchange = () => {
    const files = inputEl.current.files;
    handleSelectedFiles(files);
    inputEl.current.value = '';
  };

  const triggerInput = () => {
    inputEl.current.click();
  };

  const handleUnZip = (selectedFiles: File[]) => {
    const fileWithZipFormat = selectedFiles.filter((file) => ZIP_FORMATS.includes(file.name.toLocaleLowerCase().split('.').pop()));
    if (fileWithZipFormat.length > 0) {
      setSelectedFilesForUnZipModal(selectedFiles);
      setShowZipModal(true);
    } else {
      props.onDropAndClick(selectedFiles, false);
    }
  };

  const handleSelectedFiles = (files: FileList) => {
    const selectedFiles = [...files];
    if (props.checkFileUnZip) {
      handleUnZip(selectedFiles);
    } else {
      props.onDropAndClick(selectedFiles);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={inputEl}
        multiple={props.inputMultiFileSelect}
        onChange={inputOnchange}
        style={{display: 'none'}}
        accept={props.inputAccept}
      />

      <FileDrop
        onDrop={(files) => {
          handleSelectedFiles(files);
        }}
        onTargetClick={triggerInput}>
        {props.elementToRender}
      </FileDrop>
      <ModalDialog
        enableModal={showZipModal}
        modalTitle="Zip file detected"
        modalInnerComponent={
          <div>
            <span style={{fontWeight: '600'}}>You are uploading one or more ZIP files.</span>
            <br />
            <br />
            <span>Do you want to UNZIP all the archives after the upload?</span>
            <br />
            <br />
            <span>If you choose to UNZIP the archives all the zipped content will be uploaded as individual files.</span>
          </div>
        }
        modalButtons={[
          {
            label: 'cancel',
            onClick: () => {
              setShowZipModal(false);
            },
          },
          {
            label: 'Do not unzip',
            onClick: () => {
              setShowZipModal(false);
              props.onDropAndClick(selectedFilesForUnZipModal, false);
              setSelectedFilesForUnZipModal([]);
            },
          },
          {
            label: 'Unzip',
            onClick: () => {
              setShowZipModal(false);
              props.onDropAndClick(selectedFilesForUnZipModal, true);
              setSelectedFilesForUnZipModal([]);
            },
          },
        ]}
      />
    </>
  );
};

export default DragAndDrop;
