import {Spinner, Toggle} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IBOAggregated} from '../../Models/ILibrary';
import {IOptionDto} from '../../Models/IOptionDto';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import GenericList from '../GenericList/GenericList';
import {LabelButton} from '@Eni/docware-fe-master';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import {SelectionMode} from '@fluentui/react/lib/DetailsList';
import './BOsAggregations.scss';
import {getInputInfos, getInputInfosValue} from '../../Reducers/Generic/GenericAction';
import {IValue} from '../../Models/IValue';
import InputInfoWidget from '../InputInfoWidget/InputInfoWidget';
import { BOsObject } from '../../Models/BusinessObject';

interface AggregatedBODescriptor {
  key: string;
  displayName: string;
  enabled: boolean;
  order?: number;
}

const dictKeyToDisplayNameDefault = (key: string) => {
  if (!key) {
    return '';
  }

  let displayNameParts: string[] = [];
  let spl = key.split('_');

  for (let i = 0; i < spl.length; i++) {
    let item: string = spl[i];
    displayNameParts.push(item.charAt(0).toLocaleUpperCase() + item.substring(1));
  }

  return displayNameParts.join(' ');
};

const dictKeyToEnabledStatus = (aggregationSource: string, key: string) => {
  let inputInfoKeyMap = getInputInfosValue('aggregated-bo', aggregationSource) as string;
  if (inputInfoKeyMap != null) {
    let parseDict: AggregatedBODescriptor[] = [];
    try {
      //** this is a vector of {key: string; displayName: string;} */
      parseDict = JSON.parse(inputInfoKeyMap);

      for (let i = 0; i < parseDict.length; i++) {
        let item: AggregatedBODescriptor = parseDict[i];
        if (item.key === key) {
          return item.enabled;
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  return true;
};

const dictKeyToDisplayName = (aggregationSource: string, key: string) => {
  let inputInfoKeyMap = getInputInfosValue('aggregated-bo', aggregationSource) as string;

  if (inputInfoKeyMap == null) {
    return dictKeyToDisplayNameDefault(key);
  } else {
    let parseDict: AggregatedBODescriptor[] = [];

    try {
      parseDict = JSON.parse(inputInfoKeyMap);
    } catch (e) {
      console.error(e);
    }

    for (let i = 0; i < parseDict.length; i++) {
      let item: AggregatedBODescriptor = parseDict[i];
      if (item.key === key) {
        return item.displayName;
      }
    }

    //** error, missing key, exceptions... -> use default */
    return dictKeyToDisplayNameDefault(key);
  }
};

interface BOsAggregationsProps {
  initialState?: BOsObject;
  aggregation: IBOAggregated;
  onSelectionChanged: (item: BOsObject) => void;
  toggleStatus?: (source: string, status: boolean) => void;
  countries: IOptionDto[];
}

const prepopulateInput = (countries: IOptionDto[], aggregation: IBOAggregated) => {
  if (!countries 
    || countries.length === 0 
    || ['xware_virtual_projects', 'xware_activities'].includes(aggregation?.source)
    ) {
    return '';
  } else {
    let countryName;
    switch (countries[0].name) {
      case 'United States':
        countryName = 'USA';
        break;
      case 'United Kingdom':
        countryName = 'UK';
        break;
      case "Cote D'Ivoire":
        countryName = 'Ivory Coast';
        break;
      case 'United Arab Emirates':
        countryName = 'UAE';
        break;
      default:
        countryName = countries[0].name;
    }
    return countryName + '-' + new Date().getFullYear().toString() + '-';
  }
};

export const BOsAggregations = (props: BOsAggregationsProps) => {
  const [searchText, setSearchText] = useState<string>(prepopulateInput(props.countries, props.aggregation));
  const [lastAutoSearch, setLastAutoSearch] = useState<string>('');

  const [expanded, setExpandedInner] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bosEvents, setBosEvents] = useState<any[]>([]);
  const [relatedPreselections, setRelatedPreselections] = useState<IValue[]>([]);

  const bosColumns = [];

  const setExpanded = (toggle: boolean) => {
    if (props.toggleStatus && props.aggregation.isExclusive) {
      props.toggleStatus(props.aggregation.source, toggle);
    }
    setExpandedInner(toggle);
  };

  const initInitalState = () => {
    if (props.initialState) {
      let initialState = props.initialState[props.aggregation.source] ?? [];
      if (initialState.length > 0 && bosEvents.length === 0) {
        setRelatedPreselections(initialState);
        let searchAuto = initialState[0].id.split('_')[0];
        setExpanded(true);
        setTimeout(() => {
          setLastAutoSearch(searchAuto);
        }, 1000);
      }
    }
  };

  const validateSearch = (text: string) => {
    return text.match(new RegExp(props.aggregation.searchTextPattern, 'g')) !== null;
  };

  const performSearch = (forceSearchInput?: string) => {
    let searchOn: string = searchText;

    if (forceSearchInput && forceSearchInput !== '') {
      searchOn = forceSearchInput;
    }

    if (!validateSearch(searchOn)) {
      return;
    }

    if (searchOn.toLocaleLowerCase() !== lastAutoSearch.toLocaleLowerCase()) {
      emitReset();
    }

    let queryObject = {
      integrationId: props.aggregation.source,
      search: searchOn,
      limit: 20,
      offset: 0,
      queryColumn: 'name',
      countryCodes: (props.countries || []).map((c) => c.id).join(';'),
    };

    setLoading(true);
    ApiService.IntegrationsController.getBOValues(queryObject, (response: IAPIResponse) => {
      if (response.error == null) {
        setBosEvents(response.payload);
      } else {
        setBosEvents([]);
      }
      setLoading(false);
    });
  };

  if (bosEvents.length > 0) {
    let exampleBos = bosEvents[0];
    let meta = exampleBos.metadata ?? {};
    let metaKeys = Object.keys(meta);
    for (let i = 0; i < metaKeys.length; i++) {
      let key = metaKeys[i];
      let dType = key.toLocaleLowerCase().indexOf('date') === -1 ? 'string' : 'date';
      let basicColumn = {
        name: dictKeyToDisplayName(props.aggregation.source, key),
        dataType: dType,
        fieldName: 'metadata.' + key,
        iconName: null,
        size: 'medium',
        isMultiline: true,
      };
      if (dictKeyToEnabledStatus(props.aggregation.source, key)) {
        bosColumns.push(basicColumn);
      }
    }
  }

  useEffect(() => {
    setSearchText(lastAutoSearch);
    setTimeout(() => {
      try {
        performSearch(lastAutoSearch);
      } catch (e) {}
    }, 200);
  }, [lastAutoSearch]);

  useEffect(() => {
    initInitalState();
  }, [props.initialState]);

  const emitReset = () => {
    let voidSrc: any = {};
    voidSrc[props.aggregation.source] = [];
    props.onSelectionChanged(voidSrc);
  };

  return (
    <div className="bos-aggregations-main-wrap">
      <div className="bos-aggregations-inner-wrap">
        <div className="bos-aggregations-inner-title">
          <Toggle
            label={props.aggregation.displayName}
            checked={expanded}
            inlineLabel
            onChange={(e: any, b: any) => {
              setExpanded(b);
              if (!b) {
                emitReset();
              } else {
                setSearchText(prepopulateInput(props.countries, props.aggregation));
              }
            }}
          />
          <div style={{marginLeft: '1em', position: 'relative', top: '-0.5em'}}>
            <InputInfoWidget keyString={'aggration-title-' + props.aggregation.source} page={'new-document'} />
          </div>
        </div>
        <div className="bos-aggregations-vertical-zone-slide" style={{maxHeight: expanded ? '' : '0px'}}>
          <div className="bos-aggregations-row flex-column-on-small-screen">
            <FluentUIDecorator
              className={'bos-aggregations-search'}
              label={props.aggregation.searchTitle}
              required={true}
              validation={(content: any) => {
                return validateSearch(content);
              }}
              info={getInputInfos('new-document', 'aggregated-search')}
              fluentComponent={FluentUIDecoratorTypes.SearchBox({
                value: searchText,
                placeholder: 'Search',
                onClear: () => {
                  setSearchText('');
                },
                onChange: (e: any) => {
                  setSearchText(e.target.value);
                },
              })}
            />
            <div style={{minWidth: '11em', marginLeft: '1em', marginTop: '1em'}}>
              <LabelButton
                disabled={!validateSearch(searchText)}
                text="Show items"
                icon="Search"
                onClick={() => {
                  performSearch();
                }}
              />
            </div>
          </div>
          <div className="bos-aggregations-table">
            {loading && (
              <div className="bos-aggregations-spinner-wrap-main">
                <div className="bos-aggregations-spinner-inner">
                  <Spinner label="Loading..." />
                </div>
              </div>
            )}
            {!loading && bosEvents.length > 0 && (
              <div>
                <FluentUIDecorator
                  className={'bos-aggregations-search'}
                  label={props.aggregation.tableTitle}
                  info={getInputInfos('new-document', 'aggregated-table')}
                  fluentComponent={null}
                />
                <div className="bos-aggregations-scrollable-table">
                  <GenericList
                    initialSelection={(item: any) => {
                      return (
                        relatedPreselections.filter((x: IValue) => item.compositeKey.toLocaleLowerCase() === x.id.toLocaleLowerCase())
                          .length > 0
                      );
                    }}
                    selectionMode={SelectionMode.multiple}
                    columns={bosColumns}
                    items={bosEvents}
                    onSelectionChanged={(items: any[]) => {
                      let itemObj: BOsObject = {};
                      itemObj[props.aggregation.source] = [];
                      for (let i = 0; i < items.length; i++) {
                        let item: any = items[i];
                        let itemId = item.compositeKey ? item.compositeKey : item.id;
                        let pair: any = {
                          id: itemId,
                          name: itemId,
                          metadata: item.metadata,
                          isBOAggregate: true,
                        };
                        itemObj[props.aggregation.source].push(pair);
                      }
                      props.onSelectionChanged(itemObj);
                    }}
                  />
                </div>
              </div>
            )}
            {!loading && bosEvents.length === 0 && <div className="bos-aggregations-table-no-data">No events to display.</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BOsAggregations;
