import {DigitalSignatureType} from '../Models/IUser';
import {Link} from 'react-router-dom';

const SignatureUtils = {
  getSignatureVisualization: (digitalSignatureType: DigitalSignatureType, digitalSignatureData: string): string | JSX.Element => {
    switch(digitalSignatureType){
      case(DigitalSignatureType.Yes):
        return digitalSignatureData
      case(DigitalSignatureType.No):
        const signatureLink = SignatureUtils.getSignatureRequestUrl()
        return (
          <Link target="_blank" to={signatureLink}>
            Click here to request the signature
          </Link>
        )
      default:
        return ""
    }
  },
  getSignatureRequestUrl : (): string => {
    const trusdedLinkDomain: string =
    process.env.APP_ENV_NAME === 'Production' ? 'https://trustedservices.eni.com' : 'https://st-trustedservices.eni.com';
    const trusdedLink = trusdedLinkDomain + '/RAweb/operatore/flow/AnagrafeUtente.do?_flowId=InterrogazioniUtenti&_flowExecutionKey=e1s1';
    return trusdedLink
  },
}

export default SignatureUtils