import {DatePicker, Icon, IDatePickerProps} from '@fluentui/react';
import {useEffect, useState} from 'react';
import './CustomDatePicker.scss';

const CustomDatePicker = (props: IDatePickerProps) => {
  const initValue = (dt: any) => {
    if (dt && !isNaN(dt)) {
      return dt;
    } else {
      return undefined;
    }
  };

  const [value, setValue] = useState<Date | undefined>(undefined);
  const [inited, setInited] = useState<boolean>(false);

  useEffect(() => {
    if (props.value && !inited) {
      setValue(initValue(props.value));
      setInited(true);
    }
  }, [props.value]);

  useEffect(() => {
    if (value !== null && inited) {
      emit();
    }
  }, [value]);

  const emit = () => {
    if (props.onSelectDate) {
      props.onSelectDate(value);
    }
  };
  return (
    <div className="custom-dp-wrapper">
      <DatePicker
        {...props}
        value={value}
        onSelectDate={(e) => {
          if (e) {
            setInited(true);
            setValue(e);
          }
        }}
      />
      {value && (
        <div className="custom-dp-clear">
          <Icon
            iconName="Cancel"
            onClick={() => {
              setValue(undefined);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
