import {BUSINESS_OBJECT_HISTORY} from '../../Constants/Urls';
import {BusinessObjectHistoryItem} from '../../Models/BusinessObject';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const BusinessObjectHistoryController = {
  getHistory: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = BUSINESS_OBJECT_HISTORY.GET_BUSINESS_OBJECT_HISTORY;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  addHistory: async (historyItems: BusinessObjectHistoryItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = BUSINESS_OBJECT_HISTORY.ADD_BUSINESS_OBJECT_HISTORY;
    request.method = 'POST';
    request.payload = historyItems;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default BusinessObjectHistoryController;
