import {getTrackingComponent} from '../../AppInsights';
import {ActivityColumn, FileList} from '@Eni/docware-fe-master';
import StatusBubble from '../../Components/StatusBubble/StatusBubble';
import {IDocumentItem} from '../../Models/IDocument';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/AjaxService';
import './DrivePage.scss';
import {useEffect, useState} from 'react';
import store from '../../Reducers/Store';
import {useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {Link, useNavigate} from 'react-router-dom';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {formatFileSize} from '../../Components/FileUploadBoxPreview/FileUploadBoxPreview';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {Spinner} from '@fluentui/react';
import AppRoutes from '../../Utils/AppRoutes';
import {IActivity} from '../../Models/IActivity';
import {NewItem} from '../../Models/New';
import {NewsColumn} from '../../Components/NewsColumn/NewsColumn';
import {RecentSearchItem} from '../../Models/RecentSearch';
import FileMiniPreview from '../../Components/FileMiniPreview/FileMiniPreview';
import ContextMenu from '../../Components/ContextMenu/ContextMenu';
import PermissionsOnDocuments from '../../Utils/PermissionsOnDocuments';
import {IPermissionByData} from '../../Models/IUser';
import {UserActions} from '../../Reducers/User/UserAction';
import {getDocumentLabel, getDocumentLabelString} from '../../Utils/ComponentsUtils';
import AttachmentSmallBrowserButton from '../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import VideoTutorialCarousel from '../../Components/VideoTutorialCarousel/VideoTutorialCarousel';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import { formatCurrentVersion } from '../../Utils/VersioningUtils';

const DrivePage = () => {
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  const [reloadFavorited, setReloadFavorited] = useState<boolean>(false);

  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'DrivePage'}
  );

  /** redux reference to documents  */
  let documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  let currentPermissions = useSelector((state: GlobalState) => state.user.permissionsForDocuments);

  /** this state is only used as hook to request specific actions across the whole site */
  let generic = useSelector((state: GlobalState) => state.generic);

  const [getNews, setNews] = useState<NewItem[]>([]);
  const [recentSearches, setRecentSearches] = useState<RecentSearchItem[]>([]);

  const [activities, setActivities] = useState<IActivity[]>([]);
  const [loadingActivities, setLoadingActivities] = useState<boolean>(true);
  const [loadingNews, setLoadingNews] = useState<boolean>(true);
  const [loadingRecents, setLoadingRecents] = useState<boolean>(true);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);
  const [loadingFavoriteDocuments, setLoadingFavoriteDocuments] = useState<boolean>(true);
  const [recentHoverKey, setRecentHoverKey] = useState<number>(-1);
  const videosTutorial = useSelector((state: GlobalState) => state.generic.videosTutorial);

  const navigate = useNavigate();

  /** get activities for the user */
  useEffect(() => {
    store.dispatch(DocumentListAction.setActiveDocument(null));

    /** API call to get the activities */
    setLoadingActivities(true);
    ApiService.ActivityController.getUserActivities((response: IAPIResponse) => {
      if (response.error == null) {
        const sortMyList = (list: IActivity[]) => {
          let sortedList = list
            ? list.sort((a: IActivity, b: IActivity) => {
                return new Date(b.date).getTime() - new Date(a.date).getTime();
              })
            : [];

          return sortedList;
        };

        let useList = sortMyList(response.payload);
        let _activities = [];
        for (let i = 0; i < 50 && i < useList.length; i++) {
          _activities.push(useList[i]);
        }
        setActivities(_activities);
      } else {
        setActivities([]);
      }
      setLoadingActivities(false);
    });
  }, []);

  useEffect(() => {
    let docIds = documentSearch.documents.map((x: IDocumentItem) => {
      return x.documentId;
    });
    let favIds = documentSearch.favoriteDocuments.map((x: IDocumentItem) => {
      return x.documentId;
    });

    let neededList: string[] = [];
    neededList = neededList.concat(docIds);
    neededList = neededList.concat(favIds);

    PermissionsOnDocuments.getDocumentPermissions(currentPermissions, neededList, (result: IPermissionByData[]) => {
      store.dispatch(UserActions.setPermissionsByDocuments(result));
    });
  }, [documentSearch.documents, documentSearch.favoriteDocuments]);

  const loadDocuments = (searchText: string) => {
    const documentSearchBody: any = {
      //"search": searchText,
      limit: generic.documentsDriveViewSize,
      offset: 0,
    };

    /** API call to get documents */
    setLoadingDocuments(true);
    ApiService.DocumentController.getDocuments(documentSearchBody, (response: IAPIResponse) => {
      if (response.error == null) {
        store.dispatch(DocumentListAction.setDocuments(response.payload));
      } else {
        store.dispatch(DocumentListAction.setDocuments([]));
      }
      setLoadingDocuments(false);
    });
  };

  const loadFavorites = (searchText: string) => {
    const documentSearchBody: any = {
      //"search": searchText,
      limit: generic.documentsDriveViewSize,
      offset: 0,
    };

    if (loggedUser !== undefined) {
      setLoadingFavoriteDocuments(true);
      ApiService.DocumentController.getFavoriteDocuments(loggedUser.id, documentSearchBody, (response: IAPIResponse) => {
        if (response.error == null) {
          store.dispatch(DocumentListAction.setFavoriteDocuments(response.payload));
        } else {
          store.dispatch(DocumentListAction.setFavoriteDocuments([]));
        }
        setLoadingFavoriteDocuments(false);
      });
    }
  };

  const loadNews = () => {
    /** API call to get news */
    setLoadingNews(true);
    ApiService.NewsController.readAllNews(true, (response: IAPIResponse) => {
      if (response.error == null) {
        setNews(response.payload);
      } else {
        setNews([]);
      }
      setLoadingNews(false);
    });
  };

  const loadRecentSearches = () => {
    /** API call to get the recent searches */
    setLoadingRecents(true);
    ApiService.RecentSearchController.getRecentSearch((response: IAPIResponse) => {
      if (response.error == null) {
        setRecentSearches(response.payload);
      } else {
        setRecentSearches([]);
      }
      setLoadingRecents(false);
    });
  };

  /**
   * generic.reloadRequest is updated when a document is deleted,
   * this means we need to reload both documents and favorites in order to display the effects
   * of the delete action.
   */
  useEffect(() => {
    loadDocuments('');
    loadFavorites('');
    loadNews();
    loadRecentSearches();
  }, [generic.reloadRequest]);

  useEffect(() => {
    loadFavorites('');
  }, [reloadFavorited]);

  //** add formatted information about file size */
  for (let i = 0; i < documentSearch.documents.length; i++) {
    if (documentSearch.documents[i].mainFile) {
      documentSearch.documents[i]['fileSize'] = formatFileSize(documentSearch.documents[i].mainFile.size);
    }
  }
  for (let i = 0; i < documentSearch.favoriteDocuments.length; i++) {
    if (documentSearch.favoriteDocuments[i].mainFile) {
      documentSearch.favoriteDocuments[i]['fileSize'] = formatFileSize(documentSearch.favoriteDocuments[i].mainFile.size);
    }
  }

  return (
    <div>
      <div className="general-page-container">
        <div className="drive-basic-page-wrap">
          <div className="drive-left-section-outer fill-width-on-small-screen">
            <DriveDocumentManagementToolbar
              type={
                documentSearch.activeDocument != null
                  ? 'actions-on-document'
                  : documentSearch.activeDocuments.length === 0
                  ? 'navigation-voices'
                  : 'action-on-multi-select-documents'
              }
            />
            <nav className="drive-left-section">
              <div className="drive-welcome-label">
                <span>Welcome, </span>
                <span>
                  <div className="drive-welcome-user-title-name">{loggedUser !== undefined ? loggedUser.firstName : '?'}</div>
                </span>
                <VideoTutorialIcon />
              </div>
              <div className="drive-left-section-title">RECENT SEARCH</div>
              <div className="drive-left-section-zone">
                {loadingRecents && (
                  <div className="drive-page-spinner-wrap-main">
                    <div className="drive-page-spinner-inner">
                      <Spinner label="Loading..." />
                    </div>
                  </div>
                )}
                {!loadingRecents && recentSearches.length === 0 && (
                  <div className="drive-page-no-recent-search">You dont have any recent search yet.</div>
                )}
                {!loadingRecents &&
                  recentSearches.map((x: RecentSearchItem, i: number) => {
                    return (
                      <div
                        key={i}
                        className="drive-page-recent-search-box-wrap"
                        style={recentHoverKey !== -1 && i !== recentHoverKey ? {opacity: '0.3'} : {}}>
                        <FileMiniPreview
                          item={x}
                          onMouseEnter={() => {
                            setRecentHoverKey(i);
                          }}
                          onMouseLeave={() => {
                            setRecentHoverKey(-1);
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
              <div className="drive-left-section-title">MY DOCUMENTS</div>
              <div className="drive-left-section-zone">
                {loadingDocuments && (
                  <div className="drive-page-spinner-wrap-main">
                    <div className="drive-page-spinner-inner">
                      <Spinner label="Loading..." />
                    </div>
                  </div>
                )}
                {!loadingDocuments && (
                  <FileList
                    defaultSortOnThisColumnIndex={2}
                    fileIconFromField={'mainFile.fileName'}
                    onItemsSelected={(selectedRows: any[]) => {
                      if (selectedRows.length === 0) {
                        store.dispatch(DocumentListAction.setActiveDocument(null));
                        store.dispatch(DocumentListAction.setActiveDocuments([]));
                      }
                      if (selectedRows.length === 1) {
                        store.dispatch(DocumentListAction.setActiveDocument(selectedRows[0]));
                        store.dispatch(DocumentListAction.setActiveDocuments([]));
                      }
                      if (selectedRows.length > 1) {
                        store.dispatch(DocumentListAction.setActiveDocument(null));
                        store.dispatch(DocumentListAction.setActiveDocuments(selectedRows));
                      }
                    }}
                    currentSelectedItems={documentSearch.activeDocuments}
                    currentSelectedItem={documentSearch.activeDocument}
                    tableName={'normal'}
                    buttonCallback={() => {
                      navigate(AppRoutes.MY_DOCUMENTS_ROUTE);
                    }}
                    columns={[
                      {
                        name: 'File Type',
                        dataType: 'string',
                        fieldName: 'name',
                        iconName: 'Page',
                        size: 'tiny',
                      },
                      {
                        name: '',
                        dataType: 'string',
                        fieldName: '',
                        iconName: '',
                        size: 'micro',
                        onRender: (item: IDocumentItem) => {
                          return (
                            <div className="file-name-column-cell">
                              <span className="file-name-column-cell-buttons">
                                <ContextMenu
                                  item={item}
                                  type={'normal'}
                                  emitAction={(type: string) => {
                                    if (type === 'favorites') {
                                      store.dispatch(DocumentListAction.setActiveDocument(null));
                                      store.dispatch(DocumentListAction.setActiveDocuments([]));
                                      setReloadFavorited(!reloadFavorited);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          );
                        },
                      },
                      {
                        name: 'Name',
                        dataType: 'string',
                        fieldName: 'documentName',
                        iconName: null,
                        size: 'large',
                        onRender: (item: IDocumentItem) => {
                          let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();
                          return (
                            <div className="file-name-column-cell">
                              <span
                                title={getDocumentLabelString(item.documentName, item.documentCode)}
                                className="file-name-column-cell-buttons-label file-linkname"
                                onClick={() => {
                                  navigate(navUrl);
                                }}>
                                {getDocumentLabel(item.documentName, item.documentCode, navUrl)}
                                <div className="file-name-file-inline-button">
                                  <AttachmentSmallBrowserButton ownerDocument={item} />
                                </div>
                              </span>
                            </div>
                          );
                        },
                      },
                      {
                        name: 'Version',
                        dataType: 'string',
                        fieldName: 'versionNumber',
                        iconName: null,
                        size: 'small',
                        onRender: (item: IDocumentItem) => {
                          return formatCurrentVersion(item.versionNumber)
                        }
                      },
                      {
                        name: 'Created by',
                        dataType: 'string',
                        fieldName: 'createdBy.name',
                        iconName: null,
                        size: 'medium',
                      },
                      {
                        name: 'Professional Area',
                        dataType: 'string',
                        fieldName: 'professionalArea.name',
                        iconName: null,
                        size: 'small',
                      },
                      {
                        name: 'Last modified',
                        dataType: 'date',
                        fieldName: 'updateDate',
                        iconName: null,
                        size: 'small',
                      },
                      {
                        name: 'Status',
                        dataType: 'string',
                        fieldName: 'documentStatus',
                        iconName: null,
                        size: 'small',
                        onRender: (item: IDocumentItem) => {
                          return <StatusBubble isOldVersion={item.isOldVersion} documentStatus={item.documentStatus} />;
                        },
                      },
                    ]}
                    items={documentSearch.documents}
                  />
                )}
              </div>
              <div className="drive-left-section-title">FAVORITES</div>
              <div className="drive-left-section-zone">
                {loadingFavoriteDocuments && (
                  <div className="drive-page-spinner-wrap-main">
                    <div className="drive-page-spinner-inner">
                      <Spinner label="Loading..." />
                    </div>
                  </div>
                )}
                {!loadingFavoriteDocuments && (
                  <FileList
                    defaultSortOnThisColumnIndex={2}
                    fileIconFromField={'mainFile.fileName'}
                    onItemsSelected={(selectedRows: any[]) => {
                      if (selectedRows.length === 0) {
                        store.dispatch(DocumentListAction.setActiveDocument(null));
                        store.dispatch(DocumentListAction.setActiveDocuments([]));
                      }
                      if (selectedRows.length === 1) {
                        store.dispatch(DocumentListAction.setActiveDocument(selectedRows[0]));
                        store.dispatch(DocumentListAction.setActiveDocuments([]));
                      }
                      if (selectedRows.length > 1) {
                        store.dispatch(DocumentListAction.setActiveDocument(null));
                        store.dispatch(DocumentListAction.setActiveDocuments(selectedRows));
                      }
                    }}
                    currentSelectedItems={documentSearch.activeDocuments}
                    currentSelectedItem={documentSearch.activeDocument}
                    tableName={'favorites'}
                    buttonCallback={() => {
                      navigate(AppRoutes.STARRED_ROUTE);
                    }}
                    columns={[
                      {
                        name: 'File Type',
                        dataType: 'string',
                        fieldName: 'name',
                        iconName: 'Page',
                        size: 'tiny',
                      },
                      {
                        name: '',
                        dataType: 'string',
                        fieldName: '',
                        iconName: '',
                        size: 'micro',
                        onRender: (item: IDocumentItem) => {
                          return (
                            <div className="file-name-column-cell">
                              <span className="file-name-column-cell-buttons">
                                <ContextMenu
                                  item={item}
                                  type={'favorites'}
                                  emitAction={(type: string) => {
                                    if (type === 'favorites') {
                                      store.dispatch(DocumentListAction.setActiveDocument(null));
                                      store.dispatch(DocumentListAction.setActiveDocuments([]));
                                      setReloadFavorited(!reloadFavorited);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          );
                        },
                      },
                      {
                        name: 'Name',
                        dataType: 'string',
                        fieldName: 'documentName',
                        iconName: null,
                        size: 'large',
                        onRender: (item: IDocumentItem) => {
                          let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();
                          return (
                            <div className="file-name-column-cell">
                              <span
                                title={getDocumentLabelString(item.documentName, item.documentCode)}
                                className="file-name-column-cell-buttons-label file-linkname"
                                onClick={() => {
                                  navigate(navUrl);
                                }}>
                                {getDocumentLabel(item.documentName, item.documentCode, navUrl)}
                                <div className="file-name-file-inline-button">
                                  <AttachmentSmallBrowserButton ownerDocument={item} />
                                </div>
                              </span>
                            </div>
                          );
                        },
                      },
                      {
                        name: 'Version',
                        dataType: 'string',
                        fieldName: 'versionNumber',
                        iconName: null,
                        size: 'small',
                        onRender: (item: IDocumentItem) => {
                          return formatCurrentVersion(item.versionNumber)
                        }
                      },
                      {
                        name: 'Created by',
                        dataType: 'string',
                        fieldName: 'createdBy.name',
                        iconName: null,
                        size: 'medium',
                      },
                      {
                        name: 'Professional Area',
                        dataType: 'string',
                        fieldName: 'professionalArea.name',
                        iconName: null,
                        size: 'small',
                      },
                      {
                        name: 'Last modified',
                        dataType: 'date',
                        fieldName: 'updateDate',
                        iconName: null,
                        size: 'small',
                      },
                      {
                        name: 'Countries',
                        dataType: null,
                        fieldName: null,
                        iconName: null,
                        size: 'small',
                        onRender: (item: IDocumentItem) => {
                          return <span>{item.countries.map((x) => x.name).join(', ')}</span>;
                        },
                      },
                    ]}
                    items={documentSearch.favoriteDocuments}
                  />
                )}
              </div>
              {videosTutorial.length > 0 && (
                <div className="drive-left-lower-zone">
                  <div className="drive-left-section-title">TUTORIALS</div>
                  <div className="drive-left-section-zone">
                    <VideoTutorialCarousel tutorials={videosTutorial} slidesPerView={4} />
                  </div>
                </div>
              )}
            </nav>
          </div>
          <nav className="drive-right-section hide-on-small-screen">
            {loadingNews && (
              <div className="drive-page-spinner-wrap-main">
                <div className="drive-page-spinner-inner">
                  <Spinner label="Loading..." />
                </div>
              </div>
            )}
            {!loadingNews && <div className="navbar-news-title">NEWS</div>}
            {!loadingNews && getNews.length === 0 && <div className="drive-page-no-news-navbar">There are no news to display yet.</div>}
            {!loadingNews && getNews.length > 0 && <NewsColumn news={getNews} />}

            {loadingActivities && (
              <div className="drive-page-spinner-wrap-main">
                <div className="drive-page-spinner-inner">
                  <Spinner label="Loading..." />
                </div>
              </div>
            )}
            {!loadingActivities && (
              <ActivityColumn
                onRenderLink={(reference: IActivity) => {
                  let ref = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + reference.referenceId.toString();
                  let message = reference.message;
                  return <Link to={ref}>{message.length < 80 ? message : message.substring(0, 80) + '…'}</Link>;
                }}
                title={'Your Activities'}
                activityList={activities}
              />
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default getTrackingComponent(DrivePage, 'DrivePage');
