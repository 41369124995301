import { AjaxService, IAPIRequest, IAPIResponse, createEmptyRequest } from "../AjaxService";

const CountriesController = {
  getCountries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = "/admin/user/xware-countries";
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default CountriesController;
