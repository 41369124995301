import {FileInfo} from '../../Models/FileUtils';
import {IBoS, IBoValue, IDocumentLock, IDocumentMetadata, INewDocument} from '../../Models/IDocument';
import {ILibrary} from '../../Models/ILibrary';

export const SET_DOCUMENT_ACTION = 'SET_DOCUMENT_ACTION';
export const SET_MAIN_FILE_ACTION = 'SET_MAIN_FILE_ACTION';
export const ADD_ATTACHMENTS_ACTION = 'SET_ATTACHMENTS_ACTION';
export const DELETE_ATTACHMENT_ACTION = 'DELETE_ATTACHMENT_ACTION';
export const SET_METADATA_ACTION = 'SET_METADATA_ACTION';
export const SET_BOS_ACTION = 'SET_BOS_ACTION';

export const SET_USER_LIBRARIES_ACTION = 'SET_USER_LIBRARIES_ACTION';
export const SET_SELECTED_LIBRARY_ACTION = 'SET_SELECTED_LIBRARY_ACTION';
export const SET_DOCUMENT_LIBRARY_ACTION = 'SET_DOCUMENT_LIBRARY_ACTION';
export const SET_DOCUMENT_AREA_ACTION = 'SET_DOCUMENT_AREA_ACTION';

export const SET_TAGS_ACTION = 'SET_TAGS_ACTION';
export const SET_DESCRIPTION_ACTION = 'SET_DESCRIPTION_ACTION';

export const SET_DOCUMENT_LOCK = 'SET_DOCUMENT_LOCK';

interface SetDocumentLockActionType {
  type: typeof SET_DOCUMENT_LOCK;
  payload: IDocumentLock;
}

interface SetDocumentActionType {
  type: typeof SET_DOCUMENT_ACTION;
  payload: INewDocument;
}

interface SetMainFileActionType {
  type: typeof SET_MAIN_FILE_ACTION;
  payload: FileInfo | null;
}

interface AddAttachmentsActionType {
  type: typeof ADD_ATTACHMENTS_ACTION;
  payload: FileInfo[];
}

interface DeleteAttachmentActionType {
  type: typeof DELETE_ATTACHMENT_ACTION;
  payload: string;
}

interface SetMetadataActionType {
  type: typeof SET_METADATA_ACTION;
  payload: IDocumentMetadata;
}

interface SetBosActionType {
  type: typeof SET_BOS_ACTION;
  payload: IBoS;
}

interface SetLibrariesActionType {
  type: typeof SET_USER_LIBRARIES_ACTION;
  payload: ILibrary[];
}

interface SetSelectedLibraryActionType {
  type: typeof SET_SELECTED_LIBRARY_ACTION;
  payload: ILibrary;
}

interface SetDocumentLibraryActionType {
  type: typeof SET_DOCUMENT_LIBRARY_ACTION;
  payload: ILibrary;
}

interface SetTagsActionType {
  type: typeof SET_TAGS_ACTION;
  payload: string[];
}

export type NewDocumentActionTypes =
  | SetDocumentActionType
  | SetMainFileActionType
  | AddAttachmentsActionType
  | DeleteAttachmentActionType
  | SetMetadataActionType
  | SetBosActionType
  | SetLibrariesActionType
  | SetSelectedLibraryActionType
  | SetDocumentLibraryActionType
  | SetTagsActionType
  | SetDocumentLockActionType;

const setDocumentLock = (documentLock: IDocumentLock): SetDocumentLockActionType => {
  return {type: SET_DOCUMENT_LOCK, payload: documentLock};
};

const setDocument = (document: INewDocument): SetDocumentActionType => {
  return {type: SET_DOCUMENT_ACTION, payload: document};
};

const setMainFile = (file: FileInfo | null): SetMainFileActionType => {
  return {type: SET_MAIN_FILE_ACTION, payload: file};
};

const addAttachments = (files: FileInfo[]): AddAttachmentsActionType => {
  return {type: ADD_ATTACHMENTS_ACTION, payload: files};
};

const deleteAttachment = (fileId: string): DeleteAttachmentActionType => {
  return {type: DELETE_ATTACHMENT_ACTION, payload: fileId};
};

const setMetadata = (metadata: IDocumentMetadata): SetMetadataActionType => {
  return {type: SET_METADATA_ACTION, payload: metadata};
};

const setBos = (bos: {[type: string]: IBoValue[]}): SetBosActionType => {
  return {type: SET_BOS_ACTION, payload: bos};
};

const setUserLibraries = (libraries: ILibrary[]): SetLibrariesActionType => {
  return {type: SET_USER_LIBRARIES_ACTION, payload: libraries};
};

const setSelectedLibrary = (library: ILibrary): SetSelectedLibraryActionType => {
  return {type: SET_SELECTED_LIBRARY_ACTION, payload: library};
};

export const NewDocumentAction = {
  setDocument,
  setMainFile,
  addAttachments,
  deleteAttachment,
  setMetadata,
  setBos,
  setUserLibraries,
  setSelectedLibrary,
  setDocumentLock,
};
