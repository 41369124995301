import { useEffect, useState } from 'react';
import './DocumentSearchMap.scss';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet.markercluster/dist/leaflet.markercluster';

import { IDocumentQuery } from '../../../Models/IDocument';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { GlobalState } from '../../../Reducers/RootReducer';
import { Spinner } from '@fluentui/react';
import SearchTagsControl from '../../../Components/SearchTagsControl/SearchTagsControl';
import { useNavigate } from 'react-router-dom';
import { getSearchBaseUrl, SearchPagePropsData, setQueryStringWithDefaults } from '../DocumentSearchPage';
import URIformatter from '../../../Utils/URIformatter';
import { LabelButton } from '@Eni/docware-fe-master';
import { DocumentListAction } from '../../../Reducers/DocumentSearch/DocumentSearchActions';
import { SEARCH_BASE_PAGINATION_INCREMENT } from '../../../Constants/Constants';
import AppRoutes from '../../../Utils/AppRoutes';
import {useNavigateToSearchAndReset} from '../../../Hooks/useNavigateToSearchAndReset';

interface DocumentSearchMapProps extends SearchPagePropsData {
  documents: IDocumentQuery[];
}

export default function DocumentSearchMap(props: DocumentSearchMapProps) {
  const [documents, setDocuments] = useState<IDocumentQuery[]>([]);
  const [markerList, setMarkers] = useState<any[]>([]);
  const [myMap, setMyMap] = useState<L.Map>(null);
  const [bounds, setBounds] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [simpleSearch, setSimpleSearch] = useState<string>('');
  const [searchBody, setSearchBody] = useState<string>('');
  const [maxResults, setMaxResults] = useState<number>(0);

  let boColors = useSelector((state: GlobalState) => state.generic.boColors);
  let documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  let searchBodySearch = searchBody !== '' ? JSON.parse(searchBody).search : '';
  let activeSearchText = searchBodySearch !== '' ? searchBodySearch : '';
  const SEARCH_SCROLLABLE_CONTENT_ID = 'search-scrollable-content';
  let sortedResults = documents.filter((x) => x !== undefined);

  const navigateToSearchAndReset = useNavigateToSearchAndReset();
  const dispatch = useDispatch();

  useEffect(() => {
    setMaxResults(props.maxResults);
  }, [props.maxResults]);

  useEffect(() => {
    setDocuments(props.documents);
  }, [props.documents]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setSimpleSearch(props.simpleSearch);
  }, [props.simpleSearch]);

  useEffect(() => {
    setSearchBody(props.searchBody);
  }, [props.searchBody]);

  useEffect(() => {
    if (!loading) {
      // INITIALIZATION MAP
      const DefaultIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
      });
      L.Marker.prototype.options.icon = DefaultIcon;
      let mymap = myMap;
      mymap = L.map('map', { attributionControl: false }).setView(
        // [51.505, -0.09], //default position
        [35.588, -0.26], //default position
        3 // zoom level
      );
      mymap.options.minZoom = 3; //limit zoom out to one world
      mymap.setMaxBounds([
        //limit for just showing one world
        [-90, -180],
        [90, 180],
      ]);
      L.tileLayer(
        // "http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
        {
          noWrap: true,
        }
      ).addTo(mymap);
      L.control
        .attribution({
          position: 'bottomright',
          prefix: '',
        })
        .addTo(mymap);

      mymap.on('draw:created', function (e: any) {
        const type = e.layerType;
        const layer = e.layer;

        if (type === 'rectangle') {
          //dispatchVisibleMarkers(layer.getBounds());
          setBounds(layer.getBounds());
        }
      });

      setMyMap(mymap);
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      //CREATE MARKERS AFTER MAP INIT
      var markers = new L.MarkerClusterGroup();
      documents.forEach((document, index) => {
        let lat = document.document.latitude;
        let long = document.document.longitude;
        if (lat && long) {
          let marker = L.marker([lat, long]);
          marker.bindPopup(openPopup(document.document));
          markers.addLayer(marker);
        }
      });
      setMarkers(markers);

      const drawControl = new L.Control.Draw({
        draw: {
          polygon: false,
          marker: false,
          polyline: false,
          circle: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: markers,
          edit: false,
          remove: false,
        },
      });
      if (myMap) {
        let map = myMap;
        map.addLayer(markers);
        map.addControl(drawControl);
        setMyMap(map);
      }
    }
  }, [myMap]);

  function dispatchVisibleMarkers(bounds: any) { }

  useEffect(() => {
    if (Object.keys(bounds).length > 0) {
      // SETTING VISIBLE MARKERS INSIDE MAP
      const values = [
        // convert to top-left, right-bottom
        [bounds._northEast.lat, bounds._southWest.lng],
        [bounds._southWest.lat, bounds._northEast.lng],
      ];

      let docs = documents;
      docs = docs.filter((doc) => {
        return (
          values[1][0] < doc.document.latitude &&
          doc.document.latitude < values[0][0] &&
          values[0][1] < doc.document.longitude &&
          doc.document.longitude < values[1][1]
        );
      });
      var markers = new L.MarkerClusterGroup();

      docs.forEach((doc) => {
        let lat = doc.document.latitude;
        let long = doc.document.longitude;
        if (lat && long) {
          let marker = L.marker([lat, long]);
          marker.bindPopup(openPopup(doc.document));
          markers.addLayer(marker);
        }
      });

      let map = myMap;
      if (map) {
        map.removeLayer(markerList);
        map.addLayer(markers);
        map.fitBounds(bounds);
        setMarkers(markers);
        setDocuments(docs);
        props.onSelectedItem(docs, 0);
        setMaxResults(docs.length);
        sortedResults = docs;
      }
    }
  }, [bounds]);

  function openPopup(document) {
    let content =
      '<a href="' +
      AppRoutes.DOCUMENT_MANAGEMENT +
      '?documentid=' +
      document.documentId.toString() +
      '&backSearch=' +
      encodeURI(window.location.href.replace(window.location.origin, '')) +
      '" target="_self">' +
      document.fileName +
      '</a><br />Author: ' +
      (document.authors.length > 0 ? document.authors[0].name : '') +
      '<br /><br />Released: ' +
      moment(document.documentDate).format('llll') +
      '<br />Update: ' +
      moment(document.updateDate).format('llll') +
      '<br /><br /> ' +
      document.bOs
        ?.map((el) => {
          let boElem = boColors.find((bo) => bo.name === el.type);
          return (
          `<i class="pi pi-circle-on circle-highlights tooltip" style="color:${boElem.cardColor}" }}>
            ${boElem ? `<span class="tooltiptext">${boElem.displayName}</span>` : ""}
          </i>`)
        })
        .join(' ');

    return content;
  }

  const removeFilter = (paths: string[], isLast: boolean) => {
    if (searchBody === '') {
      return;
    }

    let obj = JSON.parse(searchBody);

    for (const path of paths) {
      let stepObj = obj;
      let splPath = path.split('.');
      for (let i = 0; i < splPath.length; i++) {
        let step: string = splPath[i];

        /** last step */
        if (i === splPath.length - 1) {
          delete stepObj[step];
        } else {
          stepObj = stepObj[step];
        }
      }
    }

    let simpleSearchToggle = isLast ? '&simpleSearch=yes' : '';

    obj = setQueryStringWithDefaults(obj);
    navigateToSearchAndReset(`${getSearchBaseUrl()}?searchBody=${URIformatter.encode(JSON.stringify(obj)) + simpleSearchToggle}`);
  };

  let simpleSearchError = simpleSearch === 'yes' && activeSearchText.length <= 3;

  return (
    <div className="search-left-column-wrap-background">
      <nav className="search-left-column-wrap" id={SEARCH_SCROLLABLE_CONTENT_ID}>
        <div className="search-left-column-wrap-inner">
          {(simpleSearchError || sortedResults.length === 0) && (
            <div className="document-search-header">Search documents using the search bar above.</div>
          )}
          {!simpleSearchError && sortedResults.length > 0 && (
            <div className="document-search-header-wrap">
              <div className="document-search-header">Search results based on the following criteria:</div>
              <div className="document-search-header">
                Results:{' '}
                <div className="document-search-text">
                  {loading ? '-' : sortedResults.length.toString() + ' of ' + maxResults.toString()}
                </div>
              </div>
            </div>
          )}

          {searchBody !== '' && (
            <div>
              <SearchTagsControl
                query={searchBody}
                onRemove={(path: string, isLast: boolean) => {
                  let filterToRemove: string[] = path.split(';');
                  removeFilter(filterToRemove, isLast);
                }}
              />
            </div>
          )}

          <div className="document-search-boxes-wrap">
            {loading && (
              <div className="search-spinner-wrap-main">
                <div className="search-spinner-inner">
                  <Spinner label="Search in progress..." />
                </div>
              </div>
            )}
            {!loading && (
              <div>
                {!simpleSearchError && (
                  <section className={'map-container'}>
                    <div id="map"></div>
                  </section>
                )}
                {!simpleSearchError && sortedResults.length > 0 && sortedResults.length >= documentSearch.resultsLimit && (
                  <div className="favorites-page-centered-button">
                    <LabelButton
                      whiteOutlined
                      icon="ChevronDown"
                      text="Load more results"
                      onClick={() => {
                        dispatch(DocumentListAction.setSearchLimit(documentSearch.resultsLimit + SEARCH_BASE_PAGINATION_INCREMENT));
                      }}
                    />
                  </div>
                )}
                <div>
                  {!simpleSearchError && documents.filter((x) => x !== undefined).length === 0 && (
                    <div className="search-no-results-label">The search did not produce a result.</div>
                  )}
                  {simpleSearchError && (
                    <div className="search-no-results-label">
                      To perform a basic search, your search text must be longer than 3 characters or you must use any other advanced search
                      criteria.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}
