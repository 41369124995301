import {Dispatch, useEffect, useState} from 'react';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import {IDocumentVersion, INewDocument} from '../../Models/IDocument';
import {initVersionTracks} from '../../Pages/DocumentManagementPage/DocumentManagementPage';
import {testIsLatest} from '../../Utils/VersioningUtils';
import {Banner, ModalDialog} from '@Eni/docware-fe-master';
import {Spinner} from '@fluentui/react';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {GenericActions} from '../../Reducers/Generic/GenericAction';
import {useDispatch} from 'react-redux';

export const loadDocumentDeletingDocumentVersionInfo = async (documentIds: string[]): Promise<string[]> => {
  let warnings = [];
  for (let i = 0; i < documentIds.length; i++) {
    let documentQuery: IAPIResponse = await ApiService.DocumentController.getDocument(documentIds[i]);
    if (documentQuery.error === null) {
      let doc: INewDocument = documentQuery.payload;
      let versions: IDocumentVersion = await initVersionTracks(doc.versionTrack);

      let workflowResponse: IAPIResponse = await ApiService.WorkflowController.getById(doc.documentId);

      // wf warnings
      if (workflowResponse.error === null && workflowResponse.payload !== null) {
        warnings.push(
          'Warning on document <strong>' +
            doc.documentName +
            '</strong>: ' +
            'this document has an active Workflow. If you move this document to the Trash Bin, the Workflow will be aborted and all the progress will be lost. This action cannot be undone.'
        );
      }

      // versioning warnings
      let isLatest = testIsLatest(doc.documentId, versions);
      let secondSentence = isLatest
        ? '<br /><br />Since this document is the Latest, the first document preceding it will revert to being the latest. This action cannot be undone. We strongly suggest that instead of deleting this document, you proceed and <strong>create a New Version</strong> of it with all the necessary corrections.'
        : 'This action cannot be undone.';
      if (versions.versions.length > 1) {
        warnings.push(
          'Warning on document <strong>' +
            doc.documentName +
            '</strong>: this document is part of a set of versioned documents. If you move this document to the Trash Bin, it will be forever detached from the set it belongs to.' +
            ' ' +
            secondSentence
        );
      }
    }
  }

  return warnings;
};

interface MoveToTrashSelectedDocumentsProps {
  showModal: boolean;
  setShowModal: Dispatch<boolean>;
  deleteLevel: number;
  setDeleteLevel: Dispatch<number>;
  documentIdsToDelete: string[];
  checkDocumentVersionInfo: boolean;
}

export const MoveToTrashSelectedDocuments = (props: MoveToTrashSelectedDocumentsProps) => {
  const [trashModalLoading, setTrashModalLoading] = useState<boolean>(false);
  const [trashModalWarnings, setTrashModalWarnings] = useState<string[]>([]);
  const [trashModalErrors, setTrashModalErrors] = useState<string[]>([]);
  const [deletingDocuments, setDeletingDocuments] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.checkDocumentVersionInfo) {
      const loadDocumentDeleteInfo = async () => {
        setTrashModalLoading(true);
        const warnings = await loadDocumentDeletingDocumentVersionInfo(props.documentIdsToDelete);
        setTrashModalWarnings(warnings);
        setTrashModalLoading(false);
      };

      loadDocumentDeleteInfo();
    }
  }, [props.documentIdsToDelete]);

  useEffect(() => {
    setTrashModalErrors([]);
  }, [props.showModal]);

  let modalTitle = 'Move to Trash';
  let modalMessage = 'Documents deleted in this way are moved to the User Trash Bin. Proceed anyway?';

  if (props.deleteLevel === 3) {
    modalTitle = 'Delete document?';
    modalMessage = 'Documents deleted in this way are deleted and removed from the trash page. Proceed anyway?';
  }

  if (props.deleteLevel === 4) {
    modalTitle = 'Delete phisically?';
    modalMessage =
      'Documents deleted in this way are removed from the system and are physically deleted. This action cannot be undone. Proceed anyway?';
  }
  return (
    <>
      <ModalDialog
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        modalInnerComponent={
          <div>
            {trashModalLoading && (
              <div style={{display: 'flex', justifyContent: 'space-around', margin: '1em'}}>
                <Spinner label="Checking selected document(s)..." labelPosition="right" />
              </div>
            )}
            {!trashModalLoading && (
              <div>
                {trashModalWarnings.length > 0 && <div className="trash-documents-warning">Please read carefully:</div>}
                {trashModalWarnings.map((x: string, i: number) => {
                  return (
                    <div className="trash-documents-warning-label" key={i}>
                      <Banner notDismissable enabled message={<div dangerouslySetInnerHTML={{__html: x}} />} type="warning" />
                    </div>
                  );
                })}
              </div>
            )}
            {trashModalErrors.length > 0 && <hr style={{marginTop: '1em'}} />}
            {trashModalErrors.map((x: string, i: number) => {
              return <Banner message={x} key={i} enabled={true} type="error" />;
            })}
          </div>
        }
        enableModal={props.showModal}
        onAbort={() => {
          props.setShowModal(false);
        }}
        loadingButtons={deletingDocuments}
        modalButtons={[
          {
            label: 'Cancel',
            onClick: () => {
              props.setShowModal(false);
            },
          },
          {
            label: 'Accept',
            disabled: trashModalLoading,
            onClick: async () => {
              let erroredDocuments: string[] = [];
              setDeletingDocuments(true);
              console.log('documentsToDeleteeeee', props.documentIdsToDelete);
              for (let i = 0; i < props.documentIdsToDelete.length; i++) {
                let id: string = props.documentIdsToDelete[i];
                /** this pattern is needed in order to be able to await each file deletion */

                if (props.deleteLevel === 4) {
                  let result = await ApiService.DocumentController.setAdminDeletedDocument({
                    documentId: id,
                    documentActivation: props.deleteLevel,
                  });
                  if (result.error !== null) {
                    erroredDocuments.push(result.error);
                  }
                } else {
                  let result = await ApiService.DocumentController.setDeletedDocument({
                    documentId: id,
                    documentActivation: props.deleteLevel,
                  });
                  if (result.error !== null) {
                    if (props.deleteLevel === 2 && result.payload?.title === "User's Trash Bin is full") {
                      window.document.dispatchEvent(new Event('trashBinFullModal'));
                    } else {
                      erroredDocuments.push(result.error);
                    }
                  }
                }
              }
              setDeletingDocuments(false);

              if (erroredDocuments.length === 0) {
                dispatch(DocumentListAction.setActiveDocument(null));
                dispatch(DocumentListAction.setActiveDocuments([]));
                dispatch(GenericActions.reloadRequest());
                props.setShowModal(false);
              } else {
                setTrashModalErrors(erroredDocuments);
              }
            },
          },
        ]}
      />
    </>
  );
};
