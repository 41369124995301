import moment from 'moment';
import {DetailBox} from '../DetailBox/DetailBox';
import PeopleGallery, {IPeopleGalleryToken} from '../PeopleGallery/PeopleGallery';
import {capitalizeText, getStatusIconName, getStatusLabel} from '../StatusBubble/StatusBubble';

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {FileInfo} from '../../Models/FileUtils';
import {IActivity} from '../../Models/IActivity';
import {IDocumentEditHistoryItem, IDocumentSignStep, IDocumentVersionItem, INewDocument} from '../../Models/IDocument';
import {ILibrary} from '../../Models/ILibrary';
import {IOptionDto, IPersonaOptionDto} from '../../Models/IOptionDto';
import {IWorkflowItem, IWorkflowSigner, IWorkflowSignStep, IWorkflowUser, WorkflowSignatureAction} from '../../Models/IWorkflowItem';
import {FormatBoName} from '../../Pages/DocumentSearchPage/DocumentSearchPage';
import {NewDocumentAction} from '../../Reducers/NewDocument/NewDocumentActions';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {extractMetadataValue} from '../../Utils/DocumentUtils';
import FileUploadBoxPreview from '../FileUploadBoxPreview/FileUploadBoxPreview';
import './DocumentDetailColumn.scss';
import {DocumentSource} from '../../Models/DocumentSource';
import {ActivityColumn, LabelButton} from '@Eni/docware-fe-master';
import ImageThumbnailBox from '../FileUploadBoxPreview/ImageThumbnailBox';
import VideoTutorialIcon from '../VideoTutorialIcon/VideoTutorialIcon';
import {cleanBoName} from '../../Utils/NewDocumentUtils';
import { formatCurrentVersion } from '../../Utils/VersioningUtils';

  /**
   * Retrieves the workflow user sign information for a given user ID.
   *
   * @param {string} userId - The ID of the user.
   * @param {IWorkflowItem} workflow - The workflow item.
   * @return {IWorkflowUser | null} The workflow user sign information or null if not found.
   */
const getUserSign = (userId: string, workflow: IWorkflowItem): IWorkflowUser | null => {
  let you: IWorkflowSigner = null;

  for (let k = 0; k < workflow.signers.length; k++) {
    let step: IWorkflowSignStep = workflow.signers[k];

    for (let i = 0; i < step.users.length && you === null; i++) {
      let item: IWorkflowSigner = step.users[i];
      if (item.userId === userId) {
        you = {...item};
        break;
      }
    }
  }

  if (you) {
    const delegatedActionMatch = you.delegates.find((x) => x.userSignStatus !== WorkflowSignatureAction.notSigned);
    if(you.userSignStatus === WorkflowSignatureAction.notSigned){
      return delegatedActionMatch ? delegatedActionMatch : you;
    } else {
      return you
    }
    
  }

  return null;
};

/**
 * Parses an array of people and returns an array of IPeopleGalleryToken objects.
 *
 * @param {IPersonaOptionDto[]} people - The array of people to parse.
 * @param {IWorkflowItem | null | undefined} workflow - The workflow item.
 * @param {string} [role] - The role of the people.
 * @return {IPeopleGalleryToken[]} The array of parsed people.
 */
export const parsePeople = (people: IPersonaOptionDto[], workflow: IWorkflowItem | null | undefined, role?: string): IPeopleGalleryToken[] => {
  let pPeople: IPeopleGalleryToken[] = [];
  for (let i = 0; i < people.length; i++) {
    let item = people[i];
    let status = null;
    let signType
    if (workflow) {
      const userSign = getUserSign(item.id, workflow);

      if(userSign) {
        switch (userSign.userSignStatus) {
          case WorkflowSignatureAction.notSigned:
            status = 'Pending';
            break;
          case WorkflowSignatureAction.signed:
            status = 'Signed';
            break;
          case WorkflowSignatureAction.rejected:
            status = 'Rejected';
            break;
        }
        signType = userSign.signType
      }

    }

    pPeople.push({
      id: item.name,
      role: role,
      status: status,
      signType: signType,
    });
  }

  return pPeople;
};

export interface IExtraColumnItem {
  position: number;
  content: any;
}

export interface IDocumentDetailColumnProps {
  document: INewDocument | null;
  onFileSelected?: (file: FileInfo, type: 'main' | 'signature' | 'attachment') => void;
  extraColumnsItems?: IExtraColumnItem[];
  workflow?: IWorkflowItem | null;
  versions: IDocumentVersionItem[];
  openFileInfo: {fileName: string; fileType: 'main' | 'signature' | 'attachment'};
}

const DocumentDetailColumn = (props: IDocumentDetailColumnProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [document, setDocument] = useState<INewDocument | null>(null);

  const [activities, setActivities] = useState<IActivity[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [oldFiles, setOldFiles] = useState<any[]>([]);
  const [replacedUrl, setReplacedUrl] = useState<string>('');

  const libraries = useSelector<GlobalState, ILibrary[]>((state) => state.newDocument.userLibraries);

  const [docSources, setDocSources] = useState<DocumentSource[]>([]);

  const [params] = useSearchParams();
  const backSearch = params.get('backSearch');

  const getSourceLabelFromKey = (sourceKey: string) => {
    let matched: DocumentSource[] = docSources.filter((x: DocumentSource) => x.id === sourceKey);
    if (matched.length > 0) {
      return matched[0].name;
    }
    return null;
  };

  useEffect(() => {
    setDocument(props.document);

    if (props.document) {
      if (props.document.replacedBy) {
        ApiService.VersioningController.getVersionLatestById(props.document.replacedBy.toString(), (response: IAPIResponse) => {
          if (response.error === null && response.payload !== null) {
            setReplacedUrl(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + response.payload.referenceId.toString());
          }
        });
      }

      ApiService.DocumentController.getPreviousVersionFiles(props.document.documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          setOldFiles(response.payload);
        } else {
          setOldFiles([]);
        }
      });

      ApiService.DocumentSourcesController.getDocumentSource((response: IAPIResponse) => {
        if (response.error === null && response.payload !== null) {
          setDocSources(response.payload);
        }
      });

      ApiService.ActivityController.getDocumentActivities(props.document.documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          setActivities(response.payload);
        } else {
          setActivities([]);
        }
      });

      ApiService.DocumentController.getDocumentsImages([props.document.documentId], (response: IAPIResponse) => {
        if (response.error == null) {
          setImages( response.payload.filter( (image: any) => ! ["TEXT", "LOGO"].includes(image.tag) ) );
        } else {
          setImages([]);
        }
      });
    }
  }, [props.document]);

  useEffect(() => {
    if (libraries.length === 0) {
      ApiService.LibraryController.getLibraries((response) => {
        if (response.error == null) {
          dispatch(NewDocumentAction.setUserLibraries(response.payload));
        }
      });
    }
  }, []);

  const attachments = (document ? document.attachments : []) ?? [];
  const authors = (document ? document.authors : []) ?? [];
  const whitelist = (document ? document.whitelist : []) ?? [];
  const coOwners = (document ? document.coOwners : []) ?? [];
  // const matchingDocumentType = extractDocumentTypeFromDocument(
  //   libraries,
  //   document
  // );
  // const matchingDocumentSubType = extractDocumentSubTypeFromDocument(
  //   libraries,
  //   document
  // );

  const matchingDocumentType = document?.documentTypeName;
  const matchingDocumentSubType = document?.documentSubTypeName;

  const onFileSelected = (file: FileInfo | null, type: 'main' | 'signature' | 'attachment') => {
    if (file) {
      if (props.onFileSelected) {
        props.onFileSelected(file, type);
      }
    }
  };

  function getMyGisLink(libraryName: string, documentId: string): string {
    // US: https://dev.azure.com/eni-tecs/Docware%20Drive/_workitems/edit/14144
    return process.env.APP_ENV_NAME === 'Production' 
      ? `https://mygis.eni.com/mygis/?service=DOCWARE_DRIVE&idlayer=10&fieldname=DOC_DWARE_ID&fieldvalue=${documentId}`
      : `https://st-mygis.eni.com/mygis/?service=DOCWARE_DRIVE&idlayer=10&fieldname=DOC_DWARE_ID&fieldvalue=${documentId}`
  }

  let initialBoxItems: any[] = [];

  if (document != null) {
    initialBoxItems = [
      {
        title: props.openFileInfo.fileType !== 'attachment' ? 'Main Document' : 'Attachment Document',
        data: props.openFileInfo.fileName,
      },
      {
        title: 'Document Id',
        data: document.documentId.toLocaleUpperCase(),
      },
      {
        title: 'MyGis',
        data: (
          <a
            href={getMyGisLink(document.library, document.documentId)}
            target="_blank">
            Link to MyGis
          </a>
        ),
      },
      {
        title: 'Document Date',
        data: moment(document.documentDate).format('DD MMMM YYYY'),
      },
    ];

    if (document.documentCode) {
      initialBoxItems.push({
        title: 'Document Code',
        data: document.documentCode,
      });
    }

    if (document.updateHistory) {
      initialBoxItems.push({
        title: 'Last Modified By',
        data: (
          <div>
            {document.updateHistory?.slice(-1).map((x: IDocumentEditHistoryItem, i: number) => {
              return (
                <div key={i} className="document-detail-updated-by-wrap">
                  <div className="document-detail-updated-by-name">{x.editedBy.name}</div>
                  <div className="document-detail-updated-by-date">
                    {new Date(x.updateDate).toLocaleDateString() + ' ' + new Date(x.updateDate).toLocaleTimeString()}
                  </div>
                </div>
              );
            })}
          </div>
        ),
      });
    }

    if (document.documentStatus === 4) {
      initialBoxItems.push({
        title: 'Superseded link',
        data: (
          <div>
            Replaced by <Link to={replacedUrl}>this document</Link>
          </div>
        ),
      });
    }
  }

  const getFileFromVersionsDivision = (files: FileInfo[]) => {
    let results: any = {};
    for (let i = 0; i < files.length; i++) {
      let splDesc = files[i].url.split('::');
      if (splDesc.length === 3) {
        let type = splDesc[0];
        let version = splDesc[1];
        let url = splDesc[2];

        if (results[version] === undefined) {
          results[version] = [];
        }

        let f_: FileInfo = {...files[i]};
        f_.url = url;
        results[version].push({type: type, file: f_});
      }
    }

    let keys = Object.keys(results);
    if (keys.length > 0) {
      let lastKey = keys[keys.length - 1];
      delete results[lastKey];
    }
    return results;
  };

  const getVersionCount = (files: FileInfo[]) => {
    let fromV = getFileFromVersionsDivision(files);
    return Object.keys(fromV).length;
  };

  const renderFileFromVersions = (content: any) => {
    let keys = Object.keys(content);
    keys.sort();
    keys = keys.reverse();

    let groups: JSX.Element[] = [];

    for (let i = 0; i < keys.length; i++) {
      let elementsForVersion: {type: string; file: FileInfo}[] = content[keys[i]];

      let elements: JSX.Element[] = [];

      for (let j = 0; j < elementsForVersion.length; j++) {
        let description: string = 'from ' + (+keys[i] < 10 ? 'V.0' + keys[i] : 'V.' + keys[i]);

        let currElement: {type: string; file: FileInfo} = elementsForVersion[j];

        let elType: 'main' | 'attachment' = currElement.type === 'mainFile' ? 'main' : 'attachment';

        if (elType === 'main') {
          description = 'Main Doc. ' + description;
        }
        if (elType === 'attachment') {
          description = 'Attachment ' + description;
        }

        elements.push(
          <div
            key={i.toString() + '_' + j.toString()}
            style={{padding: '0.5em', cursor: 'pointer'}}
            onClick={() => {
              let targetVersion: number = +keys[i];

              // extrapolate proper document id for version
              if (props.versions.length > targetVersion) {
                let targetDoc: string = '';
                for (let d = 0; d < props.versions.length; d++) {
                  if (props.versions[d].version === targetVersion) {
                    targetDoc = props.versions[d].referenceId;
                    break;
                  }
                }

                if (targetDoc !== '') {
                  sessionStorage.setItem(
                    'open-this-file-version-nav',
                    JSON.stringify({
                      file: currElement.file,
                      type: elType,
                    })
                  );
                  navigate(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + targetDoc.toString());
                }
              }
            }}>
            <FileUploadBoxPreview
              extraIcon={elType === 'attachment' ? 'Attach' : null}
              extraDesc={description}
              fileId={currElement.file.id}
              fileName={currElement.file.fileName}
              fileSize={currElement.file.size}
              lastUpdated={currElement.file.uploadTime}
              readOnly={true}
            />
          </div>
        );
      }

      groups.push(
        <div key={i} className="column-old-file-version-box">
          <div className="column-old-file-version-box-wrap-inner">
            <div className="column-old-file-version-box-line">
              <div className="column-old-file-version-box-dot">
                <div className="column-old-file-version-box-label ">{+keys[i] < 10 ? 'V.0' + keys[i] : 'V.' + keys[i]}</div>
              </div>
              {i < keys.length - 1 && <div className="column-old-file-version-box-line-conclusive" />}
            </div>
            <div>{elements}</div>
          </div>
        </div>
      );
    }
    return groups;
  };

  const SplitCamelCaseString = (str: string) => {
    let res = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    return res.split(' ');
  };

  const getDynamicMetaName = (raw: string) => {
    return SplitCamelCaseString(capitalizeText(raw)).join(' ');
  };

  const getDynamicMetadataName = (documentLibraryName: string, libraries: ILibrary[], metadataKey: string): string => {
    const documentLibrary = libraries.filter((library) => library.name === documentLibraryName)[0];
    if (documentLibrary) {
      const metadata = documentLibrary.metadata.filter((metadata) => metadata.name == metadataKey)[0];
      if (metadata) {
        return metadata.displayName;
      }
    }
    return getDynamicMetaName(metadataKey);
  };

  /**
   * Gets the data that is always shown in the detail column
   * (i.e. it's not hidden in the "show more" part)
   */
  function getDocumentData(documentLibraryName: string, libraries: ILibrary[]) {
    const res = [
      {
        title: 'Status',
        icon: getStatusIconName(document.documentStatus),
        data: getStatusLabel(document.documentStatus, true),
      },
      {
        title: 'Countries',
        icon: 'Globe',
        data: document.countries ? document.countries.map((c: IOptionDto) => c.name).join(', ') : '-',
      },
      {
        title: 'Professional Area',
        icon: 'FolderOpen',
        data: document.professionalArea.name ?? '-',
      },
      {
        title: 'Security',
        icon: 'ShieldAlert',
        data: document.securityLevel ?? '-',
      },
      {
        title: 'Document Type',
        icon: 'Library',
        data: matchingDocumentType + (matchingDocumentSubType !== null ? ', ' + matchingDocumentSubType : ''),
      },
    ];

    const METADATA_TO_BE_EXCLUDED = ['documentNumber'];
    const metas: any = document.metadata ?? {};
    const keys: string[] = Object.keys(metas);

    for (let i = 0; i < keys.length; i++) {
      let key: string = keys[i];

      if (METADATA_TO_BE_EXCLUDED.includes(key)) {
        continue;
      }

      const value = metas[key];
      if (value != null) {
        res.push({
          title: getDynamicMetadataName(documentLibraryName, libraries, key),
          icon: 'Info',
          data: extractMetadataValue(value) ?? '-',
        });
      }
    }

    return res;
  }

  /**
   * Order the bOs keys: aggregate bOs are returned last
   */
  function getBOsKeysOrderedByAggregate(bOs: {[type: string]: any[]}): string[] {
    let bOsAggregate = [];
    let bOsNotAggregate = [];

    Object.keys(bOs).map((key, i) => {
      let value = bOs[key];
      if (Array.isArray(value) && value.length > 0) {
        if (value[0].isBOAggregate === true) {
          bOsAggregate.push(key);
        } else {
          bOsNotAggregate.push(key);
        }
      }
    });
    return bOsNotAggregate.concat(bOsAggregate);
  }

  const detailColumns =
    document == null
      ? []
      : [
          <DetailBox title={'Document'} data={initialBoxItems} />,
          <DetailBox
            title={'Properties'}
            data={getDocumentData(document.library, libraries)}
            extraData={[
              {
                title: 'Document Source',
                icon: 'OpenSource',
                data: getSourceLabelFromKey(document.documentSource) ?? '-',
              },
              {
                title: 'Description',
                icon: 'Info',
                data: document.description ?? '-',
              },
              {
                title: 'Remarks',
                icon: 'MultiSelectMirrored',
                data: document.remarks ?? '-',
              },
              {
                title: 'Tags',
                icon: 'Edit',
                data: document.tags ? document.tags.join(', ') : '-',
              },
            ]}
          />,
          <DetailBox
            title={'Authors (' + authors.length + ')'}
            data={[
              {
                title: null,
                data: <PeopleGallery people={parsePeople(authors, null, 'Owner')} />,
              },
            ]}
          />,
          <DetailBox
            title={'Co Owners (' + coOwners.length + ')'}
            data={[
              {
                title: null,
                data: <PeopleGallery people={parsePeople(coOwners, null, 'Owner')} />,
              },
            ]}
          />,
          <DetailBox
            title={'Whitelist (' + whitelist.length + ')'}
            data={[
              {
                title: null,
                data: <PeopleGallery people={parsePeople(whitelist, props.workflow)} shortView={true} />,
              },
            ]}
          />,
          <div>
            {document.bOs != null && (
              <DetailBox
                title={'Business Objects (' + Object.keys(document.bOs).length.toString() + ')'}
                data={getBOsKeysOrderedByAggregate(document.bOs).map((key: string, i: number) => {
                  let businessObject: any = document ? document.bOs[key] : [];
                  let prettyName = cleanBoName(key, libraries);
                  let value = businessObject.map((x: any, i: number) => {
                    let name = x.name;
                    let additionalInfo = x.isBOAggregate ? x.additionalInfo : null;
                    let denyNamePrint = false;
                    if (x.isBOAggregate && additionalInfo === null) {
                      additionalInfo = [name];
                      denyNamePrint = true;
                    }

                    return <span key={i}>{FormatBoName(name, {additionalInfo, index: i, denyNamePrint})}</span>;
                  });
                  return {
                    title: null,
                    data: (
                      <div className="document-management-column-bo-wrap">
                        <div className="document-management-column-bo-name">Business Object</div>
                        <div className="document-management-column-bo-value">{prettyName}</div>
                        <div className="document-management-column-bo-name">Value</div>
                        <div className="document-management-column-bo-value">{value}</div>
                      </div>
                    ),
                  };
                })}
              />
            )}
          </div>,
          <DetailBox
            title={'Approval Steps (' + document.signers.length + ')'}
            data={document.signers
              .sort((a: IDocumentSignStep, b: IDocumentSignStep) => a.order - b.order)
              .map((x: IDocumentSignStep, i: number) => {
                return {
                  title: 'Approval Step ' + (i + 1).toString(),
                  data: <PeopleGallery people={parsePeople(x.users, props.workflow)} />,
                };
              })}
          />,
          <div>
            {document.mainFile && (
              <DetailBox
                title={`Main Version (${formatCurrentVersion(document.versionNumber)})`}
                data={[
                  {
                    title: null,
                    data: (
                      <div>
                        <div
                          style={{padding: '0.5em', cursor: 'pointer'}}
                          onClick={() => {
                            onFileSelected(document ? document.mainFile : null, 'main');
                          }}>
                          <FileUploadBoxPreview
                            showLargeBox={true}
                            fileId={document.mainFile.id}
                            fileName={document.mainFile.fileName}
                            fileSize={document.mainFile.size}
                            lastUpdated={document.mainFile.uploadTime}
                            readOnly={true}
                          />
                        </div>
                        {attachments.length > 0 && (
                          <div style={{borderTop: '2px solid rgba(0,0,0,0.2)'}}>
                            {attachments.map((x: any, i: number) => {
                              return (
                                <div
                                  key={i}
                                  style={{padding: '0.5em', cursor: 'pointer'}}
                                  onClick={() => {
                                    onFileSelected(x, 'attachment');
                                  }}>
                                  <FileUploadBoxPreview
                                    extraIcon={'Attach'}
                                    fileId={x.id}
                                    fileName={x.fileName}
                                    fileSize={x.size}
                                    lastUpdated={x.uploadTime}
                                    readOnly={true}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </div>,
          <DetailBox
            title={'Version History (' + getVersionCount(oldFiles).toString() + ')'}
            data={[
              {
                title: null,
                data: (
                  <div>
                    <div>{renderFileFromVersions(getFileFromVersionsDivision(oldFiles))}</div>
                  </div>
                ),
              },
            ]}
          />,
          <DetailBox
            title={'Images (' + images.length + ')'}
            data={images.map((image: any, i: number) => {
              return {
                title: null,
                data: (
                  <div
                    style={{padding: '0.5em', cursor: 'poin\ter'}}
                    onClick={() => {
                      let imageFileInfo: FileInfo = {
                        id: image.documentId,
                        fileName: image.imageName,
                        size: 0,
                        uploadTime: '',
                        url: image.imageUrl,
                        inlineUrl: image.imageUrl,
                      };
                      onFileSelected(imageFileInfo, 'attachment');
                    }}>
                    <ImageThumbnailBox
                      documentId={image.documentId}
                      documentName={image.documentName}
                      imageName={image.imageName}
                      size={image.size}
                      tag={image.tag}
                      probability={image.probability}
                      imageUrl={image.imageUrl}
                      thumbnailUrl={image.thumbnailUrl}
                    />
                  </div>
                ),
              };
            })}
          />,
        ];

  if (props.extraColumnsItems) {
    props.extraColumnsItems.forEach((item: IExtraColumnItem, i: number) => {
      detailColumns.splice(item.position, 0, item.content);
    });
  }

  return (
    <div>
      <div style={{position: 'relative'}}>
        <div className="document-management-column-right-section-details">DETAILS <VideoTutorialIcon style={{fontSize: '1.5em', bottom: '-6px', left: '1px'}}/></div>
        {backSearch && (
          <div className="document-management-column-right-section-details-back-to-search-button">
            <LabelButton
              orangeSolid
              text="Back to Search"
              onClick={() => {
                navigate(backSearch);
              }}
              icon="Search"
            />
          </div>
        )}
      </div>
      {document && (
        <div>
          {detailColumns.map((x: any, i: number) => {
            return <div key={i}>{x}</div>;
          })}
          <div style={{marginTop: '2em'}}>
            <ActivityColumn
              onRenderLink={(reference: IActivity) => {
                let ref = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + reference.referenceId.toString();
                let message = reference.message;
                return <Link to={ref}>{message.length < 80 ? message : message.substring(0, 80) + '…'}</Link>;
              }}
              title={'All activities'}
              activityList={activities}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentDetailColumn;
