import ApiService from '../Services/ApiService';
import {IValue} from '../Models/IValue';
import {IBoS, IBoValue} from '../Models/IDocument';
import { removeDuplicatesFromArrayOfObject } from './DictUtils';

export const BoUtils = {
  getBoNames: async function(bos: {[type: string]: string[]}, countriesIds: string[]): Promise<IBoS> {
    const outputBos = {};
    for (const integrationId of Object.keys(bos)) {
      const boIds = bos[integrationId];
      for (const boId of boIds) {
        const queryObject = {
          integrationId: integrationId,
          search: boId,
          limit: 1,
          offset: 0,
          queryColumn: 'id',
          countryCodes: countriesIds.join(';'),
          useQuery: true,
        };
        const boResponse = await ApiService.IntegrationsController.getBOValues(queryObject);
        if (boResponse.error == null && boResponse.payload.length > 0) {
          if (outputBos[integrationId] == undefined) {
            outputBos[integrationId] = [(boResponse.payload as IValue[])[0]];
          } else {
            outputBos[integrationId] = outputBos[integrationId].concat([(boResponse.payload as IValue[])[0]]);
          }
        }
      }
    }
    return outputBos;
  },
  getBoMerged: function(currentBOs: {[key: string]: IValue[]}, boToMerge: {[key: string]: IValue[]}): {[key: string]: IValue[]} {
    const filterIntegrationId = Object.keys(boToMerge)[0];

    let BOsMerged;
    if (Object.keys(currentBOs).includes(filterIntegrationId)) {
      BOsMerged = {...currentBOs};
      BOsMerged[filterIntegrationId] = [...BOsMerged[filterIntegrationId], ...boToMerge[filterIntegrationId]];
      BOsMerged[filterIntegrationId] = removeDuplicatesFromArrayOfObject(BOsMerged[filterIntegrationId], 'id');
    } else {
      BOsMerged = {...currentBOs, ...boToMerge};
    }
    return BOsMerged;
  }
};