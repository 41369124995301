import {IDocumentVersion, IDocumentVersionItem} from '../Models/IDocument';

export const getLatestVersion = (versioning: IDocumentVersion) => {
  let versions = versioning.versions;
  let highVersion: IDocumentVersionItem | null = null;
  for (let i = 0; i < versions.length; i++) {
    let currV: IDocumentVersionItem = versions[i];
    if (highVersion === null || highVersion.version < currV.version) {
      highVersion = {...currV};
    }
  }
  return highVersion;
};

export const testIsLatest = (documentId: string, versioning: IDocumentVersion) => {
  let high: IDocumentVersionItem | null = getLatestVersion(versioning);
  return high ? high.referenceId === documentId : false;
};


export function formatCurrentVersion(versionNumber?: number): string{
  return `V.${(versionNumber ?? 1).toString().padStart(2, '0')}`
}