import {FEATURE_FLAG_URLS} from '../../Constants/Urls';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from '../AjaxService';

const FeaturesFlagsController = {
  getFeaturesFlags: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${FEATURE_FLAG_URLS.GET_FEATURE_FLAGS}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default FeaturesFlagsController;
