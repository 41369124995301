import ActivityController from './Controllers/ActivityController';
import AuthenticationController from './Controllers/AuthenticationController';
import BusinessObjectHistoryController from './Controllers/BusinessObjectHistoryController';
import DocumentController from './Controllers/DocumentController';
import DocumentSourcesController from './Controllers/DocumentSourcesController';
import DocumentTypesController from './Controllers/DocumentTypesController';
import DownloadController from './Controllers/DownloadController';
import GenericController from './Controllers/GenericController';
import GlobalVariablesController from './Controllers/GlobalVariablesController';
import InputInfoController from './Controllers/InputInfoController';
import IntegrationsController from './Controllers/IntegrationsController';
import LibraryController from './Controllers/LibraryController';
import MassiveImportController from './Controllers/MassiveImportController';
import NewsController from './Controllers/NewsController';
import PermissionMatrixController from './Controllers/PermissionMatrixController';
import RecentSearchController from './Controllers/RecentSearchController';
import SignatureController from './Controllers/SignatureController';
import TagsController from './Controllers/TagsController';
import TeamsIntegrationsController from './Controllers/TeamsIntegrationsController';
import UsersController from './Controllers/UsersController';
import VersioningController from './Controllers/VersioningController';
import VideoTutorialController from './Controllers/VideoTutorialController';
import WorkflowController from './Controllers/WorkflowController';
import FeaturesFlagsController from './Controllers/FeatureFlagController';
import CountriesController from './Controllers/CountriesController';
import AdvancedSearchExpAssuranceController from './Controllers/AdvancedSearchExpAssuranceController';

const ApiService = {
  ActivityController,
  AuthenticationController,
  BusinessObjectHistoryController,
  DocumentController,
  DocumentSourcesController,
  DocumentTypesController,
  DownloadController,
  GenericController,
  GlobalVariablesController,
  InputInfoController,
  IntegrationsController,
  LibraryController,
  MassiveImportController,
  NewsController,
  PermissionMatrixController,
  RecentSearchController,
  SignatureController,
  TagsController,
  TeamsIntegrationsController,
  UsersController,
  VersioningController,
  VideoTutorialController,
  WorkflowController,
  FeaturesFlagsController,
  CountriesController,
  AdvancedSearchExpAssuranceController,
};

(window as any)['ApiService'] = ApiService;

export default ApiService;
