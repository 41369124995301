import Icons from '../../Utils/FabricIconsOutlet';
import './StatusBubbleGeneric.scss';

import {IMIFileStatus} from '../../Models/MassiveImport/IMIFile';
import {IExportStatus} from '../../Models/ILibrary';
import {IMIMetadataStatus} from '../../Models/MassiveImport/IMIMetadata';

enum BubbleStatusType {
  Draft = 'draft',
  Signed = 'signed',
  Pending = 'pending',
  Rejected = 'rejected',
  Superseded = 'superseded',
  Complete = 'complete',
  Warning = 'warning',
}

interface IBubbleStatus {
  label: string;
  iconName: string;
  type: BubbleStatusType;
}

export const BubbleStatuses: {[key: string]: IBubbleStatus} = {
  MATCHING: {label: 'Matching', iconName: 'BoxCheckmarkSolid', type: BubbleStatusType.Complete},
  NOT_MATCHING: {label: 'Not Matching', iconName: 'BoxMultiplySolid', type: BubbleStatusType.Rejected},
  UPLOAD: {label: 'Uploading', iconName: 'CloudUpload', type: BubbleStatusType.Pending},
  GENERIC_ERROR: {label: 'Error', iconName: 'StatusErrorFull', type: BubbleStatusType.Rejected},
  DUPLICATED_FILE: {label: 'Duplicated File', iconName: 'DuplicateRow', type: BubbleStatusType.Superseded},
  VALID: {label: 'Valid', iconName: 'BoxCheckmarkSolid', type: BubbleStatusType.Complete},
  NOT_VALID: {label: 'Not valid', iconName: 'BoxMultiplySolid', type: BubbleStatusType.Rejected},
  WORK_IN_PROGRESS: {label: 'Work In Progress', iconName: 'PenWorkspace', type: BubbleStatusType.Draft},
  READY_TO_DEPLOY: {
    label: 'Ready To Deploy',
    iconName: 'DoubleChevronLeftMedMirrored',
    type: BubbleStatusType.Complete,
  },
  UP_TO_DATE: {label: 'Up To Date', iconName: 'BoxCheckmarkSolid', type: BubbleStatusType.Complete},
  NOT_FOUND: {label: '404', iconName: 'StatusErrorFull', type: BubbleStatusType.Draft},
  WARNING: {label: 'Warning', iconName: 'Warning', type: BubbleStatusType.Warning},
};

export const getMassiveImportFileStatus = (status: IMIFileStatus): IBubbleStatus => {
  switch (status) {
    case IMIFileStatus.Matching:
      return BubbleStatuses.MATCHING;
    case IMIFileStatus.NotMatching:
      return BubbleStatuses.NOT_MATCHING;
    case IMIFileStatus.Upload:
      return BubbleStatuses.UPLOAD;
    case IMIFileStatus.GenericError:
      return BubbleStatuses.GENERIC_ERROR;
    case IMIFileStatus.DuplicatedFile:
      return BubbleStatuses.DUPLICATED_FILE;
    default:
      return BubbleStatuses.NOT_MATCHING;
  }
};

export const getMassiveImportMetadataStatus = (status: IMIMetadataStatus): IBubbleStatus => {
  switch (status) {
    case IMIMetadataStatus.SUCCESS:
      return BubbleStatuses.VALID;
    case IMIMetadataStatus.WARNING:
      return BubbleStatuses.WARNING;
    case IMIMetadataStatus.FAIL:
      return BubbleStatuses.NOT_VALID;
    default:
      return BubbleStatuses.NOT_VALID;
  }
};

export const getExportStatus = (status: IExportStatus): IBubbleStatus => {
  switch (status) {
    case IExportStatus.WorkInProgress:
      return BubbleStatuses.WORK_IN_PROGRESS;
    case IExportStatus.ReadyToDeploy:
      return BubbleStatuses.READY_TO_DEPLOY;
    case IExportStatus.UpToDate:
      return BubbleStatuses.UP_TO_DATE;
    default:
      return BubbleStatuses.NOT_FOUND;
  }
};

interface StatusBubbleProps {
  bubbleStatus: IBubbleStatus;
}

export const StatusBubbleGeneric = (props: StatusBubbleProps) => {
  return (
    <div style={{opacity: '1'}} className={'status-bubble-generic-main-wrap add-border'}>
      <div className={'status-bubble-generic-inner status-bubble-generic-' + props.bubbleStatus.type}>
        <div className="status-bubble-generic-icon">{Icons.getIcon(props.bubbleStatus.iconName, '1em')}</div>
        <div className="status-bubble-generic-text">{props.bubbleStatus.label}</div>
      </div>
    </div>
  );
};
