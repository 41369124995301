import Icons from '../../Utils/FabricIconsOutlet';
import {SearchBox} from '@fluentui/react/lib/SearchBox';
import {createRef, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import AppRoutesMap from '../../AppRoutes';

import './ToolBar.scss';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {Dropdown, IDropdown, IDropdownOption} from '@fluentui/react';
import {useDispatch, useSelector} from 'react-redux';
import AppRoutes, {DOCUMENT_SEARCH} from '../../Utils/AppRoutes';
import URIformatter from '../../Utils/URIformatter';
import {GlobalState} from '../../Reducers/RootReducer';
import {HierarchyLibrary, HierarchyProfessionalArea} from '../../Models/ILibrary';
import SearchSuggestions, {cleanLabelData, SuggestionElement} from '../SearchSuggestions/SearchSuggestions';
import {getBasicSearchBody} from '../AdvancedSearchInputTable/AdvancedSearchInputTable';
import {LabelButton} from '@Eni/docware-fe-master';
import {SEARCH_BASE_PAGINATION_SIZE} from '../../Constants/Constants';
import {getSearchBaseUrl} from '../../Pages/DocumentSearchPage/DocumentSearchPage';
import { useNavigateToSearchAndReset } from '../../Hooks/useNavigateToSearchAndReset';


export const handleRenderTitle = (items: IDropdownOption[]) => {
  let useItems: IDropdownOption[] = items.filter((x: IDropdownOption) => x.key !== 'All');
  if (useItems.length < 3) {
    return (
      <>{`${useItems
        .map((x: any) => {
          return x.text;
        })
        .join(', ')}`}</>
    );
  }
  return <>{`${useItems[0].text} (+ ${useItems.length - 1})`}</>;
};

const toolbarIconGet = (iconName: string, after: any = <div></div>) => {
  if (iconName.startsWith('data:image/png;base64,')) {
    return (
      <div style={{position: 'relative'}}>
        <img className="toolbar-user-image" src={iconName} alt="" />
        {after}
      </div>
    );
  } else {
    return (
      <div className="toolbar-icon">
        {Icons.getIcon(iconName)}
        {after}
      </div>
    );
  }
};

const getActivePath = () => {
  let p: string = window.location.pathname;

  if (p === '/') {
    return 'Drive';
  }

  for (let i = 0; i < AppRoutesMap.length; i++) {
    let route = AppRoutesMap[i];

    let cleanA = p.split('/').filter((x) => x);
    let cleanB = route.path.split('/').filter((x: any) => x);

    if (cleanA.length > 0 && cleanB.length > 0) {
      if (cleanA[0] === cleanB[0].substring(0, p.length)) {
        let label = cleanA[0];
        label = label.replaceAll('-', ' ');
        return label.charAt(0).toLocaleUpperCase() + label.substring(1);
      }
    }
  }

  return null;
};

const tryGetBodyAreas = (parsedBody: any) => {
  if (parsedBody) {
    if (parsedBody.document) {
      if (parsedBody.document.professionalAreaIds) {
        return parsedBody.document.professionalAreaIds;
      }
    }
  }
  return [];
};

export interface AreaTokenProps {
  key: string;
  text: string;
  object: any;
}

const ToolBar = (props: any) => {
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  const notifications = Math.floor(Math.random() * 20);
  const notificationCounter = notifications > 0 ? <div className="toolbar-notification-bell">{notifications}</div> : null;
  const pathName = getActivePath();

  const [params] = useSearchParams();
  const searchQuery: string = URIformatter.decode(params.get('query') ?? '');
  const hierarchyLibrariesWhereUserCanAccess = useSelector<GlobalState, HierarchyLibrary[]>((state) => state.generic.libraryHierachyModelUserCanAccess);

  let areas: any[] = [{key: 'All', text: 'All Professional Areas'}];
  hierarchyLibrariesWhereUserCanAccess.forEach((library: HierarchyLibrary) => {
    if ( loggedUser.isSuperAdmin || loggedUser?.UserLibraries?.includes(library.name) ) {
      areas = areas.concat(
        library.professionalAreas.map((area: HierarchyProfessionalArea) => {
          return {
            key: area.id,
            text: area.name,
          };
        })
      );
    }
  });

  const searchBody: string = URIformatter.decode(params.get('searchBody') ?? '');
  const parsedBody = searchBody !== '' ? JSON.parse(searchBody) : null;
  let searchBodySearch = searchBody !== '' ? parsedBody.search : '';
  let activeSearchText = searchBody !== '' ? searchBodySearch : '';

  const areaFiltersFromIds = () => {
    if (hierarchyLibrariesWhereUserCanAccess.length > 0) {
      let searchBodyAreas = tryGetBodyAreas(parsedBody);
      let matchedBody = searchBodyAreas.map((x: string) => {
        let match = areas.filter((y: any) => y.key === x);
        if (match.length > 0) {
          return {
            key: match[0].key,
            text: match[0].text,
          };
        } else {
          return {key: '', text: ''};
        }
      });
      return matchedBody;
    }
    return [];
  };

  const [suggestions, setSuggestions] = useState<SuggestionElement[]>([]);
  const [value, setValueInner] = useState<string>(activeSearchText);
  const [areaFilter, setAreaFilter] = useState<any[]>(areaFiltersFromIds());
  const generic = useSelector((state: GlobalState) => state.generic);
  const [highlight, setHighlight] = useState<number>(-1);
  const [hideSuggestions, setHideSuggestionsInner] = useState<boolean>(true);
  const [openSearch, setOpenSearch] = useState<boolean>(true);

  const DropdownRef = createRef<IDropdown>();

  const setValue = (text: string) => {
    if (!text || text.length === 0) {
      localStorage.removeItem('search-bar-current-value');
    } else {
      localStorage.setItem('search-bar-current-value', text);
    }
    setValueInner(text);
  };

  const setHideSuggestions = (toggle: boolean) => {
    if (toggle) {
      setTimeout(() => {
        try {
          setHideSuggestionsInner(true);
        } catch (e) {}
      }, 200);
    } else {
      setHideSuggestionsInner(false);
    }
  };

  const dispatch = useDispatch();
  const navigateToSearchAndReset = useNavigateToSearchAndReset();

  useEffect(() => {
    if (searchQuery !== '') {
      applySearch(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (window.location.pathname.indexOf(AppRoutes.DOCUMENT_SEARCH) !== -1 && searchQuery === '') {
      applySearch();
    }
  }, [generic.sortDirectionDesc, generic.sortSearchBy]);

  useEffect(() => {
    if (hierarchyLibrariesWhereUserCanAccess.length > 0) {
      setAreaFilter(areaFiltersFromIds());
    }
  }, [params, hierarchyLibrariesWhereUserCanAccess]);

  const openAdvSearch = () => {
    setOpenSearch(true);
  };

  const closeAdvSearch = () => {
    setOpenSearch(false);
  };

  useEffect(() => {
    if (parsedBody && parsedBody.search !== value) {
      setValue(parsedBody.search);
    }
  }, [searchBody]);

  useEffect(() => {
    window.document.addEventListener('adv-search-filter-open', openAdvSearch);
    window.document.addEventListener('adv-search-filter-close', closeAdvSearch);

    return () => {
      window.document.removeEventListener('adv-search-filter-open', openAdvSearch);
      window.document.removeEventListener('adv-search-filter-close', closeAdvSearch);
    };
  }, []);

  const onBarChange = (event: any) => {
    if (event) {
      let inputValue = event.target.value;
      setValue(inputValue);
    }
  };

  const applySearch = (useSearch?: string) => {
    if (areas.length === 1) {
      return;
    }
    dispatch(DocumentListAction.setSearchLimit(SEARCH_BASE_PAGINATION_SIZE));
    dispatch(DocumentListAction.setScrollPosition(null));
    let searchText: string = useSearch ? useSearch : value;
    let searchBody: any = getBasicSearchBody(searchText);
    searchBody['sortBy'] = generic.sortSearchBy;
    searchBody['sortDesc'] = generic.sortDirectionDesc;

    let simpleSearchText = 'yes';
    if (areaFilter.length > 0) {
      searchBody['document']['professionalAreaIds'] = [];

      for (let i = 0; i < areaFilter.length; i++) {
        let item: any = {...areaFilter[i]};
        if (item.key !== 'All' && item.key !== undefined) {
          searchBody['document']['professionalAreaIds'].push(item.key);
        }
      }
      simpleSearchText = 'no';
    } else {
      delete searchBody['document']['professionalAreaIds'];
    }

    if (useSearch) {
      setValue(useSearch);
    }

    if (searchText === '') {
      localStorage.removeItem('search-bar-current-value');
    }

    let baseUrl = getSearchBaseUrl();
    let currSearch = decodeURI(window.location.search);
    let newSearch = `?searchBody=${URIformatter.encode(JSON.stringify(searchBody))}&simpleSearch=${simpleSearchText}`;
    if (newSearch !== currSearch) {
      setHighlight(-1);
      navigateToSearchAndReset(`${baseUrl}${newSearch}`);
    }
  };

  let profAreaOptions: {key: string; text: string}[] = areas.map((x: any) => {
    return {
      key: x.key,
      text: x.text,
    };
  });

  profAreaOptions.sort((a: {key: string; text: string}, b: {key: string; text: string}) => a.text.localeCompare(b.text));

  let toolbarComponent = (
    <div className="toolbar-advanced-search-bar-outer-wrap">
      <Dropdown
        disabled={window.location.href.includes(DOCUMENT_SEARCH) && openSearch}
        multiSelect
        placeholder="Select Professional Areas"
        onRenderTitle={handleRenderTitle}
        defaultSelectedKeys={areaFilter.map((x: any) => {
          return x.key;
        })}
        componentRef={DropdownRef}
        options={profAreaOptions}
        onChange={(e: any, o: any) => {
          if (o.key === 'All') {
            (window as any)['profAreaSelectionWasAll'] = true;
          } else {
            (window as any)['profAreaSelectionWasAll'] = false;
          }

          setTimeout(() => {
            if (
              DropdownRef.current.selectedOptions.filter((x) => x.key == 'All').length > 0 &&
              (window as any)['profAreaSelectionWasAll']
            ) {
              setAreaFilter(profAreaOptions);
            } else if (
              DropdownRef.current.selectedOptions.filter((x) => x.key == 'All').length === 0 &&
              (window as any)['profAreaSelectionWasAll']
            ) {
              setAreaFilter([]);
            } else {
              setAreaFilter(DropdownRef.current.selectedOptions.filter((x) => x.key !== 'All'));
            }
          }, 300);
        }}
      />
      <div className="toolbar-advanced-search-spacer"></div>

      <div className="toolbar-advanced-search-bar" id="search-toolbar-node">
        <div className="toolbar-advanced-search-separator"></div>
        <SearchBox
          onBlur={() => {
            setHideSuggestions(true);
          }}
          className="fluent-input-fix fluent-input-no-background"
          placeholder={'Search in Drive'}
          autoComplete={'off'}
          value={value}
          onClear={() => {
            setValue('');
            localStorage.removeItem('search-bar-current-value');
          }}
          onKeyUp={(e: any) => {
            setHideSuggestions(e.keyCode === 13);
            if (e.keyCode === 13) {
              if (highlight === -1) {
                applySearch();
              } else {
                let search = cleanLabelData(suggestions[highlight].label);
                setValue(search);
                setHighlight(-1);
                applySearch(search);
              }
            }
            if (e.key === 'ArrowDown') {
              let newHighlight = highlight + 1;
              if (newHighlight >= suggestions.length) {
                newHighlight = suggestions.length - 1;
              }
              setHighlight(newHighlight);
            }
            if (e.key === 'ArrowUp') {
              let newHighlight = highlight - 1;
              if (newHighlight < 0) {
                newHighlight = 0;
              }
              setHighlight(newHighlight);
            }
          }}
          onChange={onBarChange}
        />
        <div className="toolbar-advanced-search-separator"></div>
        {!hideSuggestions && (
          <SearchSuggestions
            emitResults={setSuggestions}
            highlight={highlight}
            searchText={value}
            emitSearch={(search: string) => {
              setValue(search);
              applySearch(search);
            }}
          />
        )}
      </div>
      <div style={{marginLeft: '1em'}}>
        <LabelButton
          //disabled={window.location.href.includes(DOCUMENT_SEARCH) && openSearch}
          icon="Search"
          whiteOutlined
          text="Search"
          onClick={() => {
            if (value === '') {
              localStorage.removeItem('search-bar-current-value');
            }

            if (window.location.href.includes(DOCUMENT_SEARCH)) {
              window.document.dispatchEvent(new Event('search-perform-request'));
            } else {
              applySearch();
            }
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="advanced-toolbar-wrap">
      <div className="toolbar-wrapper-drive">
        <div className="toolbar-search-bar-wrap">{pathName != null && toolbarComponent}</div>
      </div>
    </div>
  );
};

export default ToolBar;
