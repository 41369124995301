import {LabelButton} from '@Eni/docware-fe-master';
import './FullPageMessage.scss';
import {useNavigate} from 'react-router-dom';
import {getTrackingComponent, reactPlugin} from './../../AppInsights';
import {useAppInsightsContext, withAITracking} from '@microsoft/applicationinsights-react-js';
import coloredIcons from '../../Utils/base64Icons';
export interface IFullPageMessageContent {
  imageName: string;
  title: string;
  subtitle: string;
  smallSubtitle?: string;
  button: {
    icon: string;
    text: string;
    onClick: {
      type: string;
      arguments: string;
    };
  };
}

const getPageContentState = () => {
  let content = localStorage.getItem('full-page-message');
  return content != null ? JSON.parse(content) : null;
};

export const setPageContentState = (content: IFullPageMessageContent) => {
  localStorage.setItem('full-page-message', JSON.stringify(content));
};

const FullPageMessage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'FullPageMessage'}
  );

  let navigate = useNavigate();

  let content = getPageContentState();

  let image = content ? content.imageName : null;
  let title = content ? content.title : '';
  let subtitle = content ? content.subtitle : '';
  let smallSubtitle = (content ? content.smallSubtitle : '') ?? '';
  let button = content ? content.button : null;

  const getCallback = (content: IFullPageMessageContent) => {
    let meta = content.button.onClick;

    if (meta.type === 'navigate') {
      return () => {
        navigate(meta.arguments);
      };
    }

    return () => {}; // empty callback
  };

  let onClick = content && content.button ? getCallback(content) : () => {};

  return (
    <div>
      <div className="full-page-message-wrap">
        {image && <img className="full-page-message-image" alt="" src={coloredIcons[image]} />}
        <div className="full-page-message-title">{title}</div>
        <div className="full-page-message-subtitle">{subtitle}</div>
        {smallSubtitle && <div className="full-page-message-small-subtitle">{smallSubtitle}</div>}
        <div className="full-page-message-button-wrap">
          {button && <LabelButton text={button.text} whiteOutlined icon={button.icon} onClick={onClick} />}
        </div>
      </div>
    </div>
  );
};

export default getTrackingComponent(FullPageMessage, 'FullPageMessage');
