import './VideoTutorialCarousel.scss';
import {IVideoTutorial} from '../../Models/IVideoTutorial';
import {A11y, Navigation, Pagination, Scrollbar} from 'swiper';
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import VideoTutorialCard from '../VideoTutorialCard/VideoTutorialCard';
import Icons from '../../Utils/FabricIconsOutlet';

interface VideoTutorialCarouselProps {
  tutorials: IVideoTutorial[];
  slidesPerView: number;
}

const VideoTutorialCarousel = (props: VideoTutorialCarouselProps) => {
  return (
    <div className="video-tutorial-carousel-swiper-wrapper">
      <div className="review-swiper-button-prev video-tutorial-swiper-button">{Icons.getIcon('Previous', '2em')}</div>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={25}
        slidesPerView={props.slidesPerView}
        navigation={{
          nextEl: '.review-swiper-button-next',
          prevEl: '.review-swiper-button-prev',
        }}
        pagination={{clickable: true}}
        scrollbar={{draggable: true}}>
        {props.tutorials.map((x) => (
          <SwiperSlide key={x.id}>
            {' '}
            <VideoTutorialCard videoTutorial={x} />{' '}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="review-swiper-button-next video-tutorial-swiper-button">{Icons.getIcon('Next', '2em')}</div>
    </div>
  );
};

export default VideoTutorialCarousel;
