import {
  DocumentSearchActionTypes,
  SET_ACTIVE_DOCUMENT_ACTION,
  SET_ACTIVE_DOCUMENTS_ACTION,
  SET_ACTIVE_SEARCH_RESULTS,
  SET_CURRENT_SEARCH_MAX_RESULTS,
  SET_DOCUMENTS_ACTION,
  SET_FAVORITE_DOCUMENTS_ACTION,
  SET_SCROLL_POSITION,
  SET_SEARCH_LIMIT,
} from './DocumentSearchActions';
import {DOCUMENT_SEARCH_DEFAULT_STATE, DocumentSearchState} from './DocumentSearchState';

export const documentSearchReducer = (
  state: DocumentSearchState = DOCUMENT_SEARCH_DEFAULT_STATE,
  action: DocumentSearchActionTypes
): DocumentSearchState => {
  switch (action.type) {
    case SET_FAVORITE_DOCUMENTS_ACTION:
      return {
        ...state,
        favoriteDocuments: action.payload,
      };
    case SET_SEARCH_LIMIT:
      return {
        ...state,
        resultsLimit: action.payload,
      };
    case SET_DOCUMENTS_ACTION:
      return {
        ...state,
        documents: action.payload,
      };
    case SET_ACTIVE_DOCUMENT_ACTION:
      return {
        ...state,
        activeDocument: action.payload,
      };
    case SET_ACTIVE_DOCUMENTS_ACTION:
      return {
        ...state,
        activeDocuments: action.payload,
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case SET_CURRENT_SEARCH_MAX_RESULTS:
      return {
        ...state,
        currentSearchMaxResults: action.payload,
      };
    case SET_ACTIVE_SEARCH_RESULTS:
      return {
        ...state,
        activeSearchResults: action.payload,
      };
    default:
      return state;
  }
};
