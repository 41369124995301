import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {IDropdown, IDropdownOption} from '@fluentui/react';
import {createRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {ILibrary} from '../../../Models/ILibrary';
import {IPermissionMatrixItem} from '../../../Models/PermissionMatrixItem';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../../Reducers/RootReducer';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getDocumentTypePerSubtype, getMandatoryBosForProfessionalArea, getMandatoryMasterDataForProfessionalArea} from './PermissionMatrixUtils';
import Feature from '../../../Components/FeatureFlag/Feature';
import { MASTER_DATA_FOR_SUBTYPE_FF_KEY } from '../../../Constants/FeatureFlags';


interface IRowValue {
  documentSubTypeId: string
  elementTypeId: string
  professionalAreaId: string
  mandatoryBOs: string[]
  mandatoryMasterDatas?: string[]
}

type IRowValueKeys = keyof IRowValue

const PermissionMatrixAdminSectionRow = (props: {
  item: IPermissionMatrixItem;
  optionsMap: any;
  noTitles: boolean;
  onEdited: (edit: IPermissionMatrixItem) => void;
  onDeleted: (deleted: IPermissionMatrixItem) => void;
}) => {
  const [rowValue, setRowValue] = useState<IRowValue>(props.item);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const libraries = useSelector<GlobalState, ILibrary[]>((state) => state.newDocument.userLibraries);
  const MandatoryBODropDownRef = createRef<IDropdown>();
  const MandatoryMasterDataDropDownRef = createRef<IDropdown>();

  useEffect(() => {
    setRowValue({
      documentSubTypeId: props.item.documentSubTypeId,
      elementTypeId: props.item.elementTypeId,
      professionalAreaId: props.item.professionalAreaId,
      mandatoryBOs: props.item.mandatoryBOs,
      mandatoryMasterDatas: props.item.mandatoryMasterDatas,
    });
  }, [props.item]);

  const updateValue = (key: IRowValueKeys, value: any) => {
    let curr: any = {...rowValue};
    curr[key] = value;
    setRowValue(curr);
    setEdited(true);
  };

  const getShortDescription = () => {
    let optForSubType: any[] = props.optionsMap['documentSubTypeIds'].filter((x: any) => x.key === rowValue.documentSubTypeId);
    let optForElType: any[] = props.optionsMap['elementTypeIds'].filter((x: any) => x.key === rowValue.elementTypeId);
    let optForProfArea: any[] = props.optionsMap['professionalAreaIds'].filter((x: any) => x.key === rowValue.professionalAreaId);

    let desc =
      (optForSubType.length > 0 ? optForSubType[0].text : '?') +
      ' | ' +
      (optForElType.length > 0 ? optForElType[0].text : '?') +
      ' | ' +
      (optForProfArea.length > 0 ? optForProfArea[0].text : '?');
    return desc;
  };

  return (
    <div>
      {!deleted && (
        <div className="permission-matrix-admin-section-row-wrap">
          <ModalDialog
            modalTitle="Delete Matrix Item?"
            modalInnerComponent={
              <div>
                <div>Are you sure you want to remove the following item?</div>
                <br />
                <br />
                <div className="permission-matrix-admin-section-short-desc">{getShortDescription()}</div>
              </div>
            }
            enableModal={openDialog}
            onAccept={() => {
              ApiService.PermissionMatrixController.deleteOne(props.item, (response: IAPIResponse) => {
                if (response.error === null) {
                  setOpenDialog(false);
                  setDeleted(true);
                  props.onDeleted(props.item);
                }
              });
            }}
            onAbort={() => {
              setOpenDialog(false);
            }}
          />
          <div className="permission-matrix-admin-section-row-inner-wrap">
            <div className="permission-matrix-admin-section-row-voice wide-voice">
              <FluentUIDecorator
                noLabels={props.noTitles}
                label="Document Sub-Type"
                info={getInputInfos('admin-area-permission-matrix', 'document-sub-type')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  selectedKey: rowValue.documentSubTypeId,
                  onRenderOption: (option: IDropdownOption) => {
                    return (
                      <span>
                        <span>{'[' + getDocumentTypePerSubtype(props.optionsMap, option.key.toString()) + '] '}</span>
                        <span>{option.text}</span>
                      </span>
                    );
                  },
                  onRenderTitle: (options: IDropdownOption[]) => {
                    if (options.length === 0) {
                      return <span></span>;
                    }
                    return (
                      <span>
                        <span>{'[' + getDocumentTypePerSubtype(props.optionsMap, options[0].key.toString()) + '] '}</span>
                        <span>{options[0].text}</span>
                      </span>
                    );
                  },
                  options: props.optionsMap['documentSubTypeIds'],
                  onChange: (e, o: any) => {
                    updateValue('documentSubTypeId', o.key);
                  },
                })}
              />
            </div>
            <div className="permission-matrix-admin-section-row-voice">
              <FluentUIDecorator
                noLabels={props.noTitles}
                label="Element Type"
                info={getInputInfos('admin-area-permission-matrix', 'element-type')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  selectedKey: rowValue.elementTypeId,
                  options: props.optionsMap['elementTypeIds'],
                  onChange: (e, o: any) => {
                    updateValue('elementTypeId', o.key);
                  },
                })}
              />
            </div>
            <div className="permission-matrix-admin-section-row-voice">
              <FluentUIDecorator
                noLabels={props.noTitles}
                label="Professional Area"
                info={getInputInfos('admin-area-permission-matrix', 'professional-area')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  selectedKey: rowValue.professionalAreaId,
                  options: props.optionsMap['professionalAreaIds'],
                  onChange: (e, o: any) => {
                    updateValue('professionalAreaId', o.key);
                  },
                })}
              />
            </div>
            <div className="permission-matrix-admin-section-row-voice">
              <FluentUIDecorator
                noLabels={props.noTitles}
                label="Mandatory BOs"
                info={getInputInfos('admin-area-permission-matrix', 'mandatory-bos')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  componentRef: MandatoryBODropDownRef,
                  multiSelect: true,
                  defaultSelectedKeys: rowValue.mandatoryBOs,
                  placeholder: 'Mandatory BOs',
                  options: getMandatoryBosForProfessionalArea(libraries, rowValue.professionalAreaId),
                  onDismiss: () => {
                    updateValue(
                      'mandatoryBOs',
                      MandatoryBODropDownRef.current.selectedOptions.map((x: any) => {
                        return x.key;
                      })
                    );
                  },
                })}
              />
            </div>
            <Feature featureKey={MASTER_DATA_FOR_SUBTYPE_FF_KEY}>
              <div className="permission-matrix-admin-section-row-voice">
                <FluentUIDecorator
                  noLabels={props.noTitles}
                  label="Mandatory Master Data"
                  info={getInputInfos('admin-area-permission-matrix', 'mandatory-master-data')}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    componentRef: MandatoryMasterDataDropDownRef,
                    multiSelect: true,
                    defaultSelectedKeys: rowValue.mandatoryMasterDatas,
                    placeholder: 'Mandatory Master Data',
                    options: getMandatoryMasterDataForProfessionalArea(libraries, rowValue.professionalAreaId),
                    onDismiss: () => {
                      updateValue(
                        'mandatoryMasterDatas',
                        MandatoryMasterDataDropDownRef.current.selectedOptions.map((x: any) => {
                          return x.key;
                        })
                      );
                    },
                  })}
                />
              </div>
            </Feature>
            <div style={{marginRight: '2em'}}>
              <LabelButton
                text="Save"
                disabled={!edited}
                icon={'Save'}
                whiteOutlined
                onClick={() => {
                  let itemEdited: IPermissionMatrixItem = {...props.item};
                  itemEdited.documentSubTypeId = rowValue.documentSubTypeId;
                  itemEdited.elementTypeId = rowValue.elementTypeId;
                  itemEdited.professionalAreaId = rowValue.professionalAreaId;
                  itemEdited.mandatoryBOs = rowValue.mandatoryBOs;
                  itemEdited.mandatoryMasterDatas = rowValue.mandatoryMasterDatas;
                  ApiService.PermissionMatrixController.updateOne(itemEdited, (response: IAPIResponse) => {
                    if (response.error === null) {
                      setEdited(false);
                      props.onEdited(itemEdited);
                    }
                  });
                }}
              />
            </div>
            <div>
              <LabelButton
                text="Delete"
                icon={'Delete'}
                whiteOutlined
                onClick={() => {
                  setOpenDialog(true);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PermissionMatrixAdminSectionRow