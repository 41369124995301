import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';

import {useEffect, useState} from 'react';
import {GlobalState} from '../../../Reducers/RootReducer';
import {useDispatch, useSelector} from 'react-redux';
import {AdminAction} from '../../../Reducers/Admin/AdminActions';

import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';

import {Spinner} from '@fluentui/react';
import {ESeverity, NewItem} from '../../../Models/New';
import {NewBar} from '../../../Components/NewsColumn/NewsColumn';
import GenericList from '../../../Components/GenericList/GenericList';
import {LabelButton} from '@Eni/docware-fe-master';

import {AdminNewsAddModal, AdminNewsDeleteModal, AdminNewsEditModal} from './AdminNewsModals';

import './AdminNewsList.scss';

const AdminNewsList = (props: any) => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)', // per ora lascio questo
      sampleCount: 1,
    },
    {
      'Component Name': 'AdminNewsPage',
    }
  );

  const dispatch = useDispatch();
  const newsState = useSelector((state: GlobalState) => state.admin.newsTab);

  let generic = useSelector((state: GlobalState) => state.generic);

  const [loadingNews, setLoadingNews] = useState<boolean>(false);
  const [news, setNews] = useState<NewItem[]>([]);

  const loadNews = () => {
    setLoadingNews(true);
    ApiService.NewsController.readAllNews(false, (response: IAPIResponse) => {
      if (response.error == null) {
        setNews(response.payload);
      } else {
        setNews([]);
      }
      setLoadingNews(false);
    });
  };

  useEffect(() => {
    loadNews();
  }, [generic.reloadRequest]);

  useEffect(() => {
    if (newsState.unselectAllNews) {
      dispatch(AdminAction.SetUnselectAllNews(false));
    }
  }, [newsState.unselectAllNews]);

  return (
    <div>
      <div className="news-list-page-title">News</div>
      {(newsState.unselectAllNews || loadingNews) && (
        <div className="drive-page-spinner-wrap-main">
          <div className="drive-page-spinner-inner">
            <Spinner label="Loading..." />
          </div>
        </div>
      )}
      {!newsState.unselectAllNews && !loadingNews && news.length === 0 && (
        <div>
          <div className="drive-page-no-news">There are no news to display yet.</div>
          <br></br>
          <LabelButton
            key={-1}
            text="Add"
            icon="Add"
            onClick={() => {
              dispatch(
                AdminAction.setAddNewItem({
                  id: '',
                  title: '',
                  description: '',
                  insertDate: new Date(),
                  dateRange: {
                    from: null,
                    to: null,
                  },
                  severity: -1,
                })
              );
              dispatch(AdminAction.setShowAddModal(true));
            }}
          />
        </div>
      )}
      {!newsState.unselectAllNews && !loadingNews && news.length > 0 && (
        <GenericList
          selectionMode={2}
          onSelectionChanged={(selectedNews: any[]) => {
            if (selectedNews.length === 0) {
              dispatch(AdminAction.setSelectedNews([]));
            } else {
              dispatch(AdminAction.setSelectedNews(selectedNews));
            }
          }}
          extraHeaderItems={[
            <LabelButton
              key={-1}
              text="Add"
              icon="Add"
              onClick={() => {
                dispatch(
                  AdminAction.setAddNewItem({
                    id: '',
                    title: '',
                    description: '',
                    insertDate: new Date(),
                    dateRange: {
                      from: null,
                      to: null,
                    },
                    severity: -1,
                  })
                );
                dispatch(AdminAction.setShowAddModal(true));
              }}
            />,
          ]}
          allowFilter={true}
          searchText={'Search new'}
          columns={[
            {
              name: 'Severity',
              fieldName: 'severity',
              iconName: null,
              size: 'medium',
              onRender: (newItem: NewItem) => {
                let severityString = newItem.severity === 1 ? 'warning' : ESeverity[newItem.severity];
                severityString = severityString.charAt(0).toUpperCase() + severityString.slice(1);
                return <NewBar className="single-new-label-admin" description={severityString} severity={ESeverity[newItem.severity]} />;
              },
            },
            {
              name: 'Title',
              fieldName: 'title',
              iconName: null,
              size: 'medium',
              onRender: (newItem: NewItem) => {
                return <div className="news-admin-list-text">{newItem.title}</div>;
              },
            },
            {
              name: 'Description',
              fieldName: 'description',
              iconName: null,
              size: 'wide',
              onRender: (newItem: NewItem) => {
                return <div className="news-admin-list-text-wide">{newItem.description}</div>;
              },
            },
            {
              name: 'From',
              dataType: 'date',
              fieldName: 'dateRange.from',
              iconName: null,
              size: 'medium',
              onRender: (newItem: NewItem) => {
                return <div className="news-admin-list-text">{new Date(newItem.dateRange.from).toLocaleDateString()}</div>;
              },
            },
            {
              name: 'To',
              dataType: 'date',
              fieldName: 'dateRange.to',
              iconName: null,
              size: 'medium',
              onRender: (newItem: NewItem) => {
                return <div className="news-admin-list-text">{new Date(newItem.dateRange.to).toLocaleDateString()}</div>;
              },
            },
          ]}
          items={news}
        />
      )}

      <AdminNewsAddModal loadNews={loadNews} />
      <AdminNewsEditModal loadNews={loadNews} />
      <AdminNewsDeleteModal loadNews={loadNews} />
    </div>
  );
};

export default AdminNewsList;
