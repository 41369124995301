export const DOCUMENT_URLS = {
  GET_DOCUMENTS_URL: '/Documents',
  GET_DOCUMENT_PERMISSIONS: '/document/get_permission_by_data/',
  GET_DOCUMENT_LIST_PERMISSIONS: '/document/get_list_permission_by_data',
  GET_DOCUMENT_URL: '/Documents/GetDocument',
  CREATE_DOCUMENT_URL: '/Documents/CreateDocument',
  UPLOAD_MAIN_DOC_URL: '/Documents/LoadMainDocument',
  UPLOAD_MAIN_DOC_URL_FROM_TEAMS: '/documents/teams/loadmaindocument',
  MULTIPLE_DOWNLOAD_URL: '/documents/mainfile/download/zip',
  UPLOAD_ATTACHMENTS_URL: '/Documents/LoadAttachments',
  UPLOAD_ATTACHMENTS_URL_FROM_TEAMS: '/documents/teams/loadattachments',
  DELETE_MAIN_DOC_URL: '/Documents/DeleteMainDocument',
  DELETE_ATTACHMENT_URL: '/Documents/DeleteAttachment',
  SAVE_DOCUMENT_URL: '/Documents/SaveDocument',
  SAVE_DOCUMENT_AS_DRAFT: '/documents/savedocument/draft',
  GET_DOCUMENTS_SUGGESTED: '/Documents/suggestdocuments',
  SAVE_DOCUMENT_NEW_VERSION: '/documents/savedocument/newversion',
  GET_INTEGRATION_URL: '/Integration/',
  GET_INTEGRATION_TERRITORIAL_SURVEY_LINKED_BOS_URL: '/Integration/territorial-survey-bos',
  GET_INTEGRATION_HEALTH_CHECK_UR: '/Integration/health-check',
  GET_LIBRARIES_ADMIN_URL: '/libraries/admin-page',
  GET_LIBRARIES_URL: '/Libraries/',
  GET_ALL_LIBRARIES_URL: '/all-clean-libraries/',
  GET_LIBRARIES_SEARCH: '/libraries/search',
  GET_DOC_TYPE_CONF: '/libraries/doc-sub-types-configurations',
  GET_LIBRARIES_INFO_URL: '/libraries/info',
  UPDATE_LIBRARIES_ADMIN: '/libraries/update',
  GET_ALL_LIBRARIES: '/admin-page/libraries',
  CREATE_NEW_LIBRARY: '/admin-page/library',
  UPDATE_LIBRARY: '/admin-page/library',
  GET_LIBRARY_SUMMARY: '/admin-page/library/documents-summary',
  UPDATE_EXPORT_STATUS: '/admin-page/library/export-status',
  FAVORITE_DOCUMENTS: '/Documents/favorite',
  CHECK_IS_FAVORITE: '/Documents/isfavorite',
  DOWNLOAD_MAIN_DOCUMENT: '/document/mainfile/download/{id}',
  DOWNLOAD_MAIN_DOCUMENT_FOR_SIGNATURE: '/document/mainfileforsignature/download/{id}',
  DOWNLOAD_MAIN_DOCUMENT_FOR_SIGNATURE_BASE_64: '/document/mainfileforsignature/download/base64/{id}',
  PREVIEW_MAIN_DOCUMENT: '/document/mainfile/pdfpreview/',
  PREVIEW_MAIN_FOR_SIGNATURE_DOCUMENT: '/document/mainfilesignature/pdfpreview/',
  REVERT_DOCUMENT: '/documents/edit-document-with-active-workflow',
  DOWNLOAD_ATTACHMENT_DOCUMENT: '/document/attachment/download/{id}',
  PREVIEW_ATTACHMENT_DOCUMENT: '/document/attachment/pdfpreview/',
  GET_DELETED_DOCUMENTS: '/documents/trash',
  GET_DELETED_DOCUMENTS_AMOUNT: '/documents/trash/amount',
  FLAG_AS_OLD: '/documents/flagasold',
  GET_DOCUMENT_IMAGES: '/document/getdocumentimages',
  GET_DOCUMENTS_IMAGES: '/documents/getdocumentimages',
  GET_PERMANENTLY_DELETED_DOCUMENTS: '/documents/trash/admin',
  SET_PERMANENTLY_DELETED_DOCUMENTS: '/documents/trash/admin/set',
  SET_DELETED_DOCUMENT: '/documents/trash/set',
  TEST_DOCUMENTS_LIST_ANY_ATTACHMENTS: '/document/testlist/onattachments',
  CHECK_IS_TIGHT: '/document/check-tight-object',
  QUERY_DOCUMENTS: '/documents/querydocuments',
  DOCUMENT_CREATE_SNAPSHOT: '/documents/generatesnapshot',
  GET_DOCUMENT_FILE_TYPES: '/documents/filetypes',
  GET_PREVIOUS_VERSION_FILES: '/documents/allfiles',
  SHARE_DOCUMENT: '/document/share',
};

export const SIGNATURE_URLS = {
  GET_OTP: '/signature/generate-otp/',
  SIGN_DOCUMENT: '/signature/sign-file',
  SIGNATURES_TYPES: '/signature/available-certificates',
  GET_TIMESTAMPS: '/signature/generate-stamp',
  GET_USER_AVAILABLE_CERTIFICATES: '/signature/user/available-certificates',
};

export const GENERIC_URLS = {
  SYSTEM_LOGS: '/orchestratorlog',
  INPUT_INFO: '/InputInfo',
  DOCUMENT_SOURCES: '/document-sources',
  DOCUMENT_TYPES: '/document-formats',
  DOCUMENT_TYPES_FOR_LIBRARIES: '/document-formats-for-libraries',
  TAGS: '/Tags',
  GLOBAL_VARIABLES: '/globalvariables',
  PERMISSION_MATRIX: '/permission-matrix',
  GLOBAL_VARIABLES_AUTO_INCREMENT: '/globalvariables/absoluteautoincrement',
  GLOBAL_VARIABLES_AUTO_INCREMENT_PREVIEW: '/globalvariables/absoluteautoincrement/preview',
  GLOBAL_VARIABLES_GET_APP_VERSION: '/globalvariables/getDocwareDriveVersion',
};

export const VERSIONING_URLS = {
  GET_VERSION_URL: '/version/document',
  GET_VERSION_LATEST: '/version/document/latest',
};

export const NEWS_URLS = {
  READ_ONE_NEW: '/DocwareNews/readone',
  READ_ALL_NEWS: '/DocwareNews/read',
  CREATE_NEW: '/DocwareNews/create',
  UPDATE_NEW: '/DocwareNews/update',
  DELETE_NEW: '/DocwareNews/delete',
};

export const RECENT_SEARCH_URLS = {
  GET_RECENT_SEARCH: '/RecentSearch/get',
  ADD_RECENT_SEARCH: '/RecentSearch/add',
};

export const BUSINESS_OBJECT_HISTORY = {
  GET_BUSINESS_OBJECT_HISTORY: '/businessobjecthistory/get',
  ADD_BUSINESS_OBJECT_HISTORY: '/businessobjecthistory/add',
};

export const WORKFLOWS_URLS = {
  GET_WORKFLOW_BY_ID: '/Workflow/Byid',
  INSPECT_WORKFLOW_BY_ID: '/Workflow/byid/inspect',
  GET_ALL_WORKFLOWS: '/Workflow/All',
  GET_WF_HISTORY: '/Workflow/history',
  CANCEL_WORKFLOW_BY_ID: '/workflow/kill/',
  INSERT_WORKFLOW_CREATE: '/Workflow/create',
  INSERT_WORKFLOW_UPDATE_SIGNATURE: '/Workflow/update',
  INSERT_WORKFLOW_UPDATE_DELEGATES: '/workflow/deletegates/update',
};

export const ACTIVITY_URLS = {
  GET_ACTIVITIES: '/Activities',
  GET_ACTIVITIES_BY_REF: '/Activities/Reference',
};

export const USERS_URLS = {
  ADMIN_IMPERSONATE_USER: '/user/impersonation/',
  GET_USERS_URL: '/Users/',
  GET_CO_OWNERS_URL: '/valid-coowners',
  GET_USERS_AUTHORIZATION_REQUEST_URL: '/users/authorization-request',
  GET_USERS_URL_ADMIN: '/Users/all',
  GET_USERS_FILTERS_ADMIN: '/admin/user/filters',
  GET_USERS_BY_PROFESSIONAL_AREA_URL: '/Users/professionalAreaId',
  GET_USER_URL: '/User',
  GET_PERMISSIONS_BY_ACTION: '/user/get_permission_by_action',
  GET_PERMISSION_RECAP: '/user/permission-recap',
  GET_PERMISSION_RECAP_ADMIN: '/user/admin/permission-recap',
  GET_BASE_LIBRARIES: '/user/base-libraries',
  SET_ADMIN_FLAG: '/user/adminflag',
  SET_ENABLED_FLAG: '/user/enabledflag',
  RUN_PERM_SYNC: '/bosync/sync-users-permissions',
  ADMIN_MANAGE_USERS: '/user/manage',
  LOGOUT: '/user/logout',
  POST_SEND_AUTHORIZATION_REQUEST: '/user/send-authorization-request',
};

export const AUTHENTICATION_URLS = {
  GET_USER: '/root/user',
};

export const MASSIVE_IMPORT_URLS = {
  GET_EXCEL_FILE_SUBSURFACE: '/massiveimportdocument/subsurface/get-excel-file',
  GET_EXCEL_FILE_DEVELOPMENT: '/massiveimportdocument/development/get-excel-file',
  CLEAR_USER_STAGING_AREA: '/massiveimportdocument/user-staging-area/clear',
  GET_BLOBS_DOCUMENTS: '/massiveimportdocument/collection/blob-documents',
  GET_STAGING_AREA_COUNT: '/massiveimportdocument/count',
  UPLOAD_DOCUMENT: '/massiveimportdocument/document/upload',
  DELETE_FILES: '/massiveimportdocument/documents/delete',
  UPLOAD_METADATA_EXCEL: '/massiveimportdocument/load-metadata-excel-file',
  GET_METADATA: '/massiveimportdocument/collection',
  TRIGGER_VALIDATION: '/massiveimportdocument/trigger-documents-validation',
  SAVE_STAGING_AREA: '/massiveimportdocument/documents/save-staging-area',
  METADATA_UPLOAD_STATUS: '/massiveimportdocument/metadata/status',
};

export const VIDEO_TUTORIAL_URLS = {
  GET_VIDEOS_TUTORIAL: '/videotutorial/videos',
  GET_VIDEO_STREAM: '/videotutorial/videostream',
  GET_VIDEO_THUMBNAIL: '/videotutorial/thumbnail',
};

export const TEAMS_INTEGRATION_URLS = {
  GET_TEAMS: '/teams-integration/teams',
  GET_CHANNELS: '/teams-integration/channels',
  GET_TEAMS_WITH_CHANNELS: '/teams-integration/hierarchy',
  GET_ITEMS: '/teams-integration/items',
};


export const FEATURE_FLAG_URLS = {
  GET_FEATURE_FLAGS: '/featureflag/featureflags',
};
