import {IStyleSet, Label, ILabelStyles, Pivot, PivotItem} from '@fluentui/react';
import React, {useEffect, useState} from 'react';
import './PivotFluentWrap.scss';

export interface IPivotFluentWrapTab {
  label: string;
  onClick: () => void;
}

export interface IPivotFluentWrapProps {
  tabs: IPivotFluentWrapTab[];
  onInitProcedure?: () => string;
}

interface IPivotTrickClickActuatorProps {
  emitClick: () => void;
  inited: boolean;
}

const PivotTrickClickActuator = (props: IPivotTrickClickActuatorProps) => {
  const [emitted, setEmitted] = useState<boolean>(false);

  useEffect(() => {
    if (!emitted && props.inited) {
      props.emitClick();
      setEmitted(true);
    }
  }, [props.inited]);
  return <div></div>;
};

export const PivotFluentWrap = (props: IPivotFluentWrapProps) => {
  const [selKey, setSelKey] = useState<string>('0');
  const [inited, setInited] = useState<boolean>(false);
  useEffect(() => {
    if (props.onInitProcedure) {
      setSelKey(props.onInitProcedure());
    }
    setInited(true);
  }, []);

  const _handleLinkClick = (item: PivotItem): void => {
    setSelKey(item.props.itemKey);
  };

  return (
    <Pivot aria-label="Pivot Menu" selectedKey={selKey} onLinkClick={_handleLinkClick}>
      {props.tabs.map((x: IPivotFluentWrapTab, i: number) => {
        return (
          <PivotItem key={i} headerText={x.label} itemKey={i.toString()}>
            <PivotTrickClickActuator
              inited={inited}
              emitClick={() => {
                x.onClick();
              }}
            />
          </PivotItem>
        );
      })}
    </Pivot>
  );
};

export default PivotFluentWrap;
