import {TextField, Toggle} from '@fluentui/react';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GenericActions} from '../../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../../Reducers/RootReducer';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import GenericList from '../../../Components/GenericList/GenericList';
import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import './InputInfoList.scss';

const InputInfoList = (props: any) => {
  let generic = useSelector((state: GlobalState) => state.generic);
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>(null);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const buttons = [
    {
      onClick: () => {
        setShowEdit(false);
        ApiService.InputInfoController.setInputInfo(editItem, (response: IAPIResponse) => {
          if (response.error == null) {
            /** API call to get input infos */
            ApiService.InputInfoController.getInputInfo((response: IAPIResponse) => {
              if (response.error == null) {
                dispatch(GenericActions.setInputInfos(response.payload));
              } else {
                dispatch(GenericActions.setInputInfos([]));
              }
            });
          }
        });
      },
      label: 'Save Item',
    },
    {
      onClick: () => {
        setShowEdit(false);
      },
      label: 'Cancel',
    },
  ];

  //** add delete button for existing records */
  if (editItem) {
    if (editItem.id !== 'new') {
      buttons.push({
        onClick: () => {
          setShowEdit(false);
          ApiService.InputInfoController.deleteInputInfo(editItem, (response: IAPIResponse) => {
            if (response.error == null) {
              /** API call to get input infos */
              ApiService.InputInfoController.getInputInfo((response: IAPIResponse) => {
                if (response.error == null) {
                  dispatch(GenericActions.setInputInfos(response.payload));
                } else {
                  dispatch(GenericActions.setInputInfos([]));
                }
              });
            }
          });
        },
        label: 'Delete Item',
      });
    }
  }

  let isMultiline = false;
  if (editItem) {
    isMultiline = editItem.message.startsWith('[') || editItem.message.startsWith('{');
  }

  return (
    <div>
      <div className="input-info-list-page-title">Input Information</div>
      <GenericList
        extraHeaderItems={[
          <LabelButton
            key={-1}
            text="Add new"
            icon="Add"
            onClick={() => {
              setEditItem({
                key: '',
                page: '',
                message: '',
                id: 'new',
              });
              setShowPreview(false);
              setShowEdit(true);
            }}
          />,
        ]}
        onItemInvoked={(item: any) => {
          setEditItem({...item});
          setShowPreview(false);
          setShowEdit(true);
        }}
        allowFilter={true}
        searchText={'Search Input Information'}
        columns={[
          {
            name: 'Key',
            dataType: 'string',
            fieldName: 'key',
            iconName: null,
            size: 'medium',
          },
          {
            name: 'Page',
            dataType: 'string',
            fieldName: 'page',
            iconName: null,
            size: 'medium',
          },
          {
            name: 'Message',
            dataType: 'string',
            fieldName: 'message',
            iconName: null,
            size: 'wide',
          },
        ]}
        items={generic.inputInfos}
      />
      <ModalDialog
        modalTitle={'Edit Input Information'}
        modalInnerComponent={
          <div>
            {editItem && (
              <div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    label={'Key'}
                    value={editItem.key}
                    onChange={(e: any) => {
                      let it = {...editItem};
                      it['key'] = e.target.value;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    label={'Page'}
                    value={editItem.page}
                    onChange={(e: any) => {
                      let it = {...editItem};
                      it['page'] = e.target.value;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    multiline={isMultiline}
                    rows={10}
                    resizable={false}
                    label={'Message'}
                    value={editItem.message}
                    onChange={(e: any) => {
                      let it = {...editItem};
                      it['message'] = e.target.value;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div className="input-info-format-command-bar">
                  <Toggle
                    inlineLabel
                    checked={showPreview}
                    onChange={(e, b: boolean) => {
                      setShowPreview(b);
                    }}
                    onText="Preview On"
                    offText="Preview Off"
                  />
                  {isMultiline && (
                    <div
                      className="input-info-format-button"
                      onClick={() => {
                        let it = {...editItem};

                        try {
                          let obj = JSON.parse(it['message']);
                          let text = JSON.stringify(obj, null, 4);
                          it['message'] = decodeURI(text);

                          setEditItem(it);
                        } catch (e) {}
                      }}>
                      Format JSON
                    </div>
                  )}
                </div>
                {showPreview && (
                  <div>
                    <div className="input-info-box-title">Render Preview:</div>
                    <div
                      className="input-info-box-preview"
                      style={{whiteSpace: isMultiline ? 'pre' : 'normal'}}
                      dangerouslySetInnerHTML={{__html: editItem.message}}></div>
                  </div>
                )}
              </div>
            )}
          </div>
        }
        modalButtons={buttons.reverse()}
        onAbort={() => {
          setShowEdit(false);
        }}
        enableModal={showEdit}
      />
    </div>
  );
};

export default InputInfoList;
