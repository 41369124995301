import {ACTIVITY_URLS, RECENT_SEARCH_URLS} from '../../Constants/Urls';
import {RecentSearchItem} from '../../Models/RecentSearch';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const RecentSearchController = {
  getRecentSearch: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = RECENT_SEARCH_URLS.GET_RECENT_SEARCH;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  addRecendSearch: async (recentSearch: RecentSearchItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = RECENT_SEARCH_URLS.ADD_RECENT_SEARCH;
    request.method = 'POST';
    request.payload = recentSearch;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default RecentSearchController;
