import { Banner, FileList as FileListC, MenuTabs } from '@Eni/docware-fe-master';
import { SelectionMode, Spinner } from '@fluentui/react';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DragAndDrop from '../../../Components/DragAndDrop/DragAndDrop';
import { FileSelectArea } from '../../../Components/FileSelectArea/FileSelectArea';
import FluentUIMultiSelectDropdown from '../../../Components/FluentUIMultiSelectDropdown/FluentUIMultiSelectDropdown';
import InputFileButton from '../../../Components/InputFileButton/InputFileButton';
import MassiveImportTableContainer from '../../../Components/MassiveImportTableContainer/MassiveImportTableContainer';
import NavButtons from '../../../Components/NavButtons/NavButtons';
import { BubbleStatuses, StatusBubbleGeneric, getMassiveImportMetadataStatus } from '../../../Components/StatusBubbleGeneric/StatusBubbleGeneric';
import { IMIMetadata, IMIMetadataStatus, IMMetadataValidationStatus, MIMetadataStatusVisualization, MassiveImportSavingStatus } from '../../../Models/MassiveImport/IMIMetadata';
import { getInputInfos } from '../../../Reducers/Generic/GenericAction';
import { MassiveImportAction } from '../../../Reducers/MassiveImport/MassiveImportActions';
import { GlobalState } from '../../../Reducers/RootReducer';
import { IAPIResponse } from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import AppRoutes, { concatUrl } from '../../../Utils/AppRoutes';
import EventDispatcher from '../../../Utils/EventDispatcher';
import Icons from '../../../Utils/FabricIconsOutlet';
import { getMetadataValidationBanner } from '../MassiveImportPage';
import './MassiveImportMetadata.scss';
import MassiveImportMetadataDetail from './MassiveImportMetadataDetail/MassiveImportMetadataDetail';
import { convertIMIMetadataResponseToIMIMetadata } from '../../../Utils/MassiveImportUtils';

interface MassiveImportFilesProps {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  reload: number;
}

const MassiveImportMetadata = (props: MassiveImportFilesProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stagingArea = useSelector((state: GlobalState) => state.massiveImport.stagingArea);
  const metadataPage = useSelector((state: GlobalState) => state.massiveImport.metadataPage);
  const metadataUploadValidation = useSelector((state: GlobalState) => state.massiveImport.metadataPage.metadataUploadValidation);
  const [metadataDisplay, setMetadataDisplay] = useState<IMIMetadata[]>(metadataPage.metadata);
  const [metadataDropDownSelectedKeys, setMetadataDropDownSelectedKeys] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    props.setLoading(true);
    ApiService.MassiveImportController.getMetadata((response: IAPIResponse) => {
      props.setLoading(false);
      if (response.error == null) {
        dispatch(MassiveImportAction.setStagingArea(response.payload.summary));
        dispatch(
          MassiveImportAction.setMetadata(convertIMIMetadataResponseToIMIMetadata(response.payload.massiveImport))
        );
      }
    });
  }, [props.reload]);

  useEffect(() => {
    setMetadataDropDownSelectedKeys([]);
    setMetadataDisplay(metadataPage.metadata);
  }, [metadataPage.metadata]);

  useEffect(() => {
    if (metadataDropDownSelectedKeys.length > 0) {
      setMetadataDisplay(metadataPage.metadata.filter((file) => metadataDropDownSelectedKeys.includes(file.status.toString())));
    } else {
      setMetadataDisplay(metadataPage.metadata);
    }
  }, [metadataDropDownSelectedKeys]);

  const goBack = () => {
    navigate(concatUrl(AppRoutes.MASSIVE_IMPORT_ROUTE, AppRoutes.MASSIVE_IMPORT_ROUTE_FILES) + window.location.search);
  };

  const uploadExcel = (selectedFiles: File[]) => {
    props.setLoading(true);
    const file = selectedFiles[0];
    if (file) {
      ApiService.MassiveImportController.uploadMetadataExcel(file, (response: IAPIResponse) => {
        if (response.error == null) {
          ApiService.MassiveImportController.metadataUploadStatus((response: IAPIResponse) => {
            if (response.error == null) {
              props.setLoading(false);
              dispatch(MassiveImportAction.setMetadataUploadValidation(response.payload));
            }
          });
        } else {
          props.setLoading(false);
          EventDispatcher.dispatchToast(response.error, 'error');
        }
      });
    }
  };

  const saveStagingArea = () => {
    setSaving(true);
    ApiService.MassiveImportController.saveStagingArea((response: IAPIResponse) => {
      if (response.error == null) {
        EventDispatcher.dispatchToast('The staging area saving is in progress. You will be notified by email when the saving process is complete', 'success');
        ApiService.MassiveImportController.metadataUploadStatus((response: IAPIResponse) => {
          setSaving(false);
          if (response.error == null) {
            dispatch(MassiveImportAction.setMetadataUploadValidation(response.payload));
          }
        });
        
        /*
        dispatch(
          MassiveImportAction.setStagingArea({
            isSuccessful: false,
            filesMatchingCount: 0,
            filesNotMatchingCount: 0,
            metadataValidCount: 0,
            metadataNotValidCount: 0,
          })
        );
        dispatch(MassiveImportAction.setFiles([]));
        dispatch(MassiveImportAction.setMetadata([]));*/
      } else {
        EventDispatcher.dispatchToast(response.error, 'error');
      }
    });
  };

  function renderBodyTable(): JSX.Element{
    if (props.loading){
      return <Spinner label="Loading..." />
    }
    if(metadataUploadValidation && metadataUploadValidation?.validationStatus == IMMetadataValidationStatus.Pending){
      return getMetadataValidationBanner(metadataUploadValidation);
    }

    if(saving){
      <Spinner label="Saving..." />
    }

    if(metadataPage.metadata.length > 0){
      return (
        <MassiveImportTableContainer
          header={
            <>
              {metadataPage.needRefreshMetadata && !stagingArea.isSuccessful && (
                <Banner enabled notDismissable type="error" message={'Remember to refresh metadata with the "Refresh Metadata" button.'} />
              )}
              <InputFileButton
                buttonLabel="Upload new excel"
                onClick={uploadExcel}
                buttonDisable={false}
                multiFileSelect={false}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <FluentUIMultiSelectDropdown
                label="Metadata Status"
                placeholder="Select a metadata status"
                info={getInputInfos('massive-import', 'metadata-status')}
                options={MIMetadataStatusVisualization}
                selectedKeys={metadataDropDownSelectedKeys}
                setSelectedKeys={setMetadataDropDownSelectedKeys}
              />
            </>
          }
          body={
            <div className="massive-import-split-table-container">
              <div className={'massive-import-split-table-table ' + (metadataPage.activeMetadata ? 'detail-active' : '')}>
                <FileListC
                  defaultSortOnThisColumnIndex={0}
                  fileIconFromField={'id'}
                  onItemsSelected={(selectedRows: IMIMetadata[]) => {
                    if (selectedRows.length === 0) {
                      dispatch(MassiveImportAction.setActiveMetadata(undefined));
                    }
                    if (selectedRows.length === 1) {
                      dispatch(MassiveImportAction.setActiveMetadata(selectedRows[0]));
                    }
                  }}
                  currentSelectedItems={undefined}
                  currentSelectedItem={undefined}
                  hideBottomButton={true}
                  selectionMode={SelectionMode.single}
                  tableName={'normal'}
                  columns={[
                    {
                      name: 'Name',
                      dataType: 'string',
                      fieldName: 'documentTitle',
                      iconName: null,
                      size: 'large',
                      onRender: (item: IMIMetadata) => {
                        return <>{item?.documentTitle?.value}</>;
                      },
                    },
                    {
                      name: 'Validation',
                      dataType: 'boolean',
                      fieldName: 'isSuccessful',
                      iconName: null,
                      size: 'small',
                      onRender: (item: IMIMetadata) => {
                        return <StatusBubbleGeneric bubbleStatus={getMassiveImportMetadataStatus(item.status)} />;
                      },
                    },
                  ]}
                  items={metadataDisplay}
                />
              </div>
              <div className={'massive-import-split-table-detail-view ' + (metadataPage.activeMetadata ? 'detail-active' : '')}>
                <MassiveImportMetadataDetail metadata={metadataPage.activeMetadata} />
              </div>
            </div>
          }
        />
      )
    } else {
      return (
        <DragAndDrop
          onDropAndClick={uploadExcel}
          inputMultiFileSelect={false}
          inputAccept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          elementToRender={
            <FileSelectArea
              buttonIcon={Icons.getIcon('CloudUpload', '2em')}
              buttonLabel="Choose excel metadata"
              dropAreaTitle="Drag your excel metadata here or select it"
            />
          }
        />
      )
    }
  }

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'Files',
            callback: () => {
              goBack();
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Metadata',
            callback: () => {},
            isPassed: false,
            isEvident: true,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      {renderBodyTable()}

      {!props.loading && (
        <NavButtons
          onBackClick={goBack}
          onNextClick={saveStagingArea}
          nextLabel="Save"
          nextIcon="Save"
          nextDisabled={!stagingArea.isSuccessful || saving || [MassiveImportSavingStatus.Saving, MassiveImportSavingStatus.Saved].includes(metadataUploadValidation?.savingStatus)}
        />
      )}
    </>
  );
};

export default MassiveImportMetadata;
