import {
  ADD_ATTACHMENTS_ACTION,
  DELETE_ATTACHMENT_ACTION,
  NewDocumentActionTypes,
  SET_BOS_ACTION,
  SET_DOCUMENT_ACTION,
  SET_DOCUMENT_LOCK,
  SET_USER_LIBRARIES_ACTION,
  SET_MAIN_FILE_ACTION,
  SET_METADATA_ACTION,
  SET_SELECTED_LIBRARY_ACTION,
  SET_TAGS_ACTION,
} from './NewDocumentActions';
import {DEFAULT_NEW_DOCUMENT_STATE, NewDocumentState} from './NewDocumentState';

export const newDocumentReducer = (
  state: NewDocumentState = DEFAULT_NEW_DOCUMENT_STATE,
  action: NewDocumentActionTypes
): NewDocumentState => {
  switch (action.type) {
    case SET_DOCUMENT_ACTION:
      return {
        ...state,
        document: action.payload,
      };
    case SET_MAIN_FILE_ACTION:
      return {
        ...state,
        document: {
          ...state.document,
          mainFile: action.payload,
          documentName: state.document.documentName ?? action.payload?.fileName,
          mainDocumentFileHash: action.payload?.fileHash256,
        },
      };
    case SET_METADATA_ACTION:
      return {
        ...state,
        document: {
          ...state.document,
          library: action.payload.library,
          professionalArea: action.payload.professionalArea,
          documentName: action.payload.documentName,
          documentTypeId: action.payload.documentTypeId,
          documentSubTypeId: action.payload.documentSubTypeId,
          documentTypeName: action.payload.documentTypeName,
          documentSubTypeName: action.payload.documentSubTypeName,
          countries: action.payload.countries,
          documentStatus: action.payload.documentStatus,
          documentDate: action.payload.documentDate,
          authors: action.payload.authors,
          coOwners: action.payload.coOwners,
          signers: action.payload.signers,
          whitelist: action.payload.whitelist,
          securityLevel: action.payload.securityLevel,
          description: action.payload.description,
          remarks: action.payload.remarks,
          documentSource: action.payload.documentSource,
          relatedDocuments: action.payload.relatedDocuments,
          linkedDocuments: action.payload.linkedDocuments,
          documentCode: action.payload.documentCode,
          tags: action.payload.tags,
          createdBy: action.payload.createdBy,
          metadata: action.payload.metadata,
          metadataString: JSON.stringify(action.payload.metadata),
          workflowSignatureType: action.payload.workflowSignatureType,
        },
      };
    case SET_BOS_ACTION:
      return {
        ...state,
        document: {
          ...state.document,
          bOs: action.payload,
        },
      };
    case ADD_ATTACHMENTS_ACTION:
      return {
        ...state,
        document: {
          ...state.document,
          attachments: (state?.document?.attachments || []).concat(action.payload),
        },
      };
    case DELETE_ATTACHMENT_ACTION:
      return {
        ...state,
        document: {
          ...state.document,
          attachments: (state?.document?.attachments || []).filter((a) => a.id !== action.payload),
        },
      };
    case SET_USER_LIBRARIES_ACTION:
      return {
        ...state,
        userLibraries: action.payload,
      };
    case SET_SELECTED_LIBRARY_ACTION:
      return {
        ...state,
        selectedLibrary: action.payload,
      };
    case SET_TAGS_ACTION:
      return {
        ...state,
        document: {
          ...state.document,
          tags: action.payload,
        },
      };
    case SET_DOCUMENT_LOCK:
      return {
        ...state,
        documentLock: action.payload,
      };
    default:
      return state;
  }
};
