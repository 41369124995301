import {DOCUMENT_URLS} from '../../Constants/Urls';
import {INewDocument} from '../../Models/IDocument';
import {AjaxService, APISettings, createEmptyRequest, IAPIRequest, IAPIResponse} from './../AjaxService';
import {decodeXML} from '../../Utils/StringUtils';

const DocumentController = {
  getDocumentImages: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_DOCUMENT_IMAGES}?documentId=${documentId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocumentsImages: async (documentIds: string[], callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_DOCUMENTS_IMAGES}`;
    request.payload = {DocumentIds: documentIds};
    request.method = 'post';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocumentTrashAmount: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_DELETED_DOCUMENTS_AMOUNT;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getFlagDocumentAsOld: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.FLAG_AS_OLD}?documentId=${documentId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setFlagDocumentAsOld: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.FLAG_AS_OLD}?documentId=${documentId}`;
    request.method = 'post';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUserPermissionsOnDocument: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_DOCUMENT_PERMISSIONS}${documentId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getUserPermissionsOnDocumentList: async (documentIds: string[], callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_DOCUMENT_LIST_PERMISSIONS}`;
    request.payload = documentIds;
    request.method = 'post';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getFavoriteDocuments: async (favoritesForUserId: string, body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_DOCUMENTS_URL}?favoritesForUserId=${favoritesForUserId}`;
    request.method = 'post';
    request.payload = body;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDeletedDocuments: async (body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_DELETED_DOCUMENTS;
    request.method = 'post';
    request.payload = body;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getPermanentlyDeletedDocuments: async (body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_PERMANENTLY_DELETED_DOCUMENTS;
    request.method = 'post';
    request.payload = body;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setAdminDeletedDocument: async (body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.SET_PERMANENTLY_DELETED_DOCUMENTS;
    request.method = 'post';
    request.payload = body;

    if (body.documentActivation === 1) {
      request.successMessage = 'Document restored.';
    }
    if (body.documentActivation === 2) {
      request.successMessage = 'Document moved to trash.';
    }

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  checkIsTight: async (objectId: string, objectValue: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.CHECK_IS_TIGHT;
    request.method = 'post';
    request.payload = {id: objectId, value: objectValue};
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  testDocumentListAnyAttachments: async (documentIds: string[], callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.TEST_DOCUMENTS_LIST_ANY_ATTACHMENTS;
    request.method = 'post';
    request.payload = documentIds;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setDeletedDocument: async (body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.SET_DELETED_DOCUMENT;
    request.method = 'post';
    request.payload = body;

    if (body.documentActivation === 1) {
      request.successMessage = 'Document restored.';
    }
    if (body.documentActivation === 2) {
      request.successMessage = 'Document moved to trash.';
    }
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocuments: async (body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_DOCUMENTS_URL;
    request.method = 'post';
    request.payload = body;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  queryDocuments: async (body: any, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.QUERY_DOCUMENTS;
    request.method = 'post';
    request.payload = body;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocument: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_DOCUMENT_URL;
    request.url += `?documentId=${documentId}`;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      /*************************************Begin Quick and dirty fix: 11387*******************************************/
      /** Remove this fix when the database will be fixed **/
      if (response.error == null) {
        let payload = response.payload;

        Object.keys(payload).forEach((key) => {
          if (['documentName', 'description', 'remarks'].includes(key)) {
            payload[key] = decodeXML(payload[key]);
          }
          if (key === 'mainFile' && payload[key]) {
            let mainFile = payload[key];
            mainFile['fileName'] = decodeXML(mainFile['fileName']);
          }

          if (key === 'bOs') {
            const bOs = payload[key];
            Object.keys(bOs).forEach((bo_key) => {
              let bo_key_values = [];
              bOs[bo_key].forEach((el_list) => {
                el_list['name'] = decodeXML(el_list['name']);
                bo_key_values.push(el_list);
              });
              bOs[bo_key] = bo_key_values;
            });
          }
          if (key === 'attachments') {
            const attachments = payload[key];
            attachments.forEach((attachment) => {
              attachment['fileName'] = decodeXML(attachment['fileName']);
            });
          }
        });
      }
      /*************************************End Quick and dirty fix: 11387*******************************************/

      callback(response);
    }
    return response;
  },
  createDocument: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.CREATE_DOCUMENT_URL;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  uploadMainDocument: async (documentId: string, file: File, libraryId?: string, callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let formData = new FormData();
    formData.append('main_document', file, file.name);
    formData.append('documentId', documentId);
    if (libraryId)
      formData.append('libraryId', libraryId);
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.UPLOAD_MAIN_DOC_URL;
    request.method = 'post';
    request.payload = formData;
    request.ignoreDefaultHeaders = true;
    request.dontStringify = true;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteMainDocument: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.method = 'delete';
    request.url = `${DOCUMENT_URLS.DELETE_MAIN_DOC_URL}?documentId=${documentId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  uploadAttachments: async (
    documentId: string,
    files: File[],
    unzipFiles: boolean,
    libraryId?: string,
    callback?: (response: IAPIResponse) => void,
  ): Promise<IAPIResponse> => {
    let formData = new FormData();
    formData.append('documentId', documentId);
    for (let i = 0; i < files.length; i++) {
      formData.append('document_' + i, files[i], files[i].name);
    }
    if (libraryId) {
      formData.append('libraryId', libraryId);
    }

    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.UPLOAD_ATTACHMENTS_URL}?uploadAsZipFile=${!unzipFiles}`;
    request.method = 'post';
    request.payload = formData;
    request.ignoreDefaultHeaders = true;
    request.dontStringify = true;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteAttachment: async (documentId: string, fileId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.method = 'delete';
    request.url = `${DOCUMENT_URLS.DELETE_ATTACHMENT_URL}?documentId=${documentId}&attachmentId=${fileId}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getPreviousVersionFiles: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_PREVIOUS_VERSION_FILES}?documentId=${documentId}`;
    request.method = 'post';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  generateDocumentSnapshot: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.DOCUMENT_CREATE_SNAPSHOT}?documentId=${documentId}`;
    request.method = 'post';
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocumentSearchSuggestions: async (text: string, amount: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_DOCUMENTS_SUGGESTED}?historicSize=${amount}&autocompletionFuzzy=false&autocompletionSize=${amount}&term=${text}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  saveDocumentNewVersion: async (document: INewDocument, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.SAVE_DOCUMENT_NEW_VERSION}`;
    request.method = 'post';
    request.payload = document;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  saveDocument: async (document: INewDocument, isEdit: boolean, isNewVersion: boolean, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();

    let extra = '';
    if (isEdit) {
      extra = `?isEdit=${isEdit}`;
    }
    if (isNewVersion) {
      extra = `?isNewVersion=${isNewVersion}`;
    }
    request.url = `${DOCUMENT_URLS.SAVE_DOCUMENT_URL}${extra}`;
    request.method = 'post';
    request.payload = document;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  saveDraftDocument: async (
    document: INewDocument,
    isEdit: boolean,
    isNewVersion: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let request: IAPIRequest = createEmptyRequest();

    let extra = '';
    if (isEdit) {
      extra = `?isEdit=${isEdit}`;
    }
    if (isNewVersion) {
      extra = `?isNewVersion=${isNewVersion}`;
    }
    request.url = `${DOCUMENT_URLS.SAVE_DOCUMENT_AS_DRAFT}${extra}`;
    request.method = 'post';
    request.payload = document;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  revertDocument: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.REVERT_DOCUMENT += '?documentId=' + documentId;
    request.method = 'DELETE';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getMainDocumentForSignatureBase64: async (documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.DOWNLOAD_MAIN_DOCUMENT_FOR_SIGNATURE_BASE_64.replace('{id}', documentId);
    request.method = 'GET';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  downloadMainDocumentForSignature: async (
    fileName: string,
    documentId: string,
    useLinkDownload: boolean = false,
    callback?: (response: IAPIResponse) => void
  ) => {
    const url = DOCUMENT_URLS.DOWNLOAD_MAIN_DOCUMENT_FOR_SIGNATURE.replace('{id}', documentId);

    if (useLinkDownload) {
      AjaxService.downloadFileFromUrl(APISettings.baseUrl + url);
    } else {
      let request: IAPIRequest = createEmptyRequest();
      request.url = url + '?useBase64=true';
      request.method = 'GET';
      let response: IAPIResponse = await AjaxService.call(request);
      if (callback) {
        callback(response);
      } else {
        if (response.error == null) {
          AjaxService.downloadBase64File(fileName, response.payload.data);
        }
      }
      return response;
    }
  },
  downloadMainDocument: async (
    fileName: string,
    documentId: string,
    useLinkDownload: boolean = false,
    callback?: (response: IAPIResponse) => void
  ) => {
    const url = DOCUMENT_URLS.DOWNLOAD_MAIN_DOCUMENT.replace('{id}', documentId);

    if (useLinkDownload) {
      AjaxService.downloadFileFromUrl(APISettings.baseUrl + url);
    } else {
      let request: IAPIRequest = createEmptyRequest();
      request.url = url + '?useBase64=true';
      request.method = 'GET';
      let response: IAPIResponse = await AjaxService.call(request);
      if (callback) {
        callback(response);
      } else {
        if (response.error == null) {
          AjaxService.downloadBase64File(fileName, response.payload.data);
        }
      }
      return response;
    }
  },
  downloadAttachmentDocument: async (
    fileName: string,
    documentId: string,
    useLinkDownload: boolean = false,
    callback?: (response: IAPIResponse) => void
  ) => {
    const url = DOCUMENT_URLS.DOWNLOAD_ATTACHMENT_DOCUMENT.replace('{id}', documentId);

    if (useLinkDownload) {
      AjaxService.downloadFileFromUrl(APISettings.baseUrl + url);
    } else {
      let request: IAPIRequest = createEmptyRequest();
      request.url = url + '?useBase64=true';
      request.method = 'GET';
      let response: IAPIResponse = await AjaxService.call(request);
      if (callback) {
        callback(response);
      } else {
        if (response.error == null) {
          AjaxService.downloadBase64File(fileName, response.payload.data);
        }
      }
      return response;
    }
  },
  checkDocumentIsFavorite: async (userId: string, documentId: string, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.CHECK_IS_FAVORITE;
    request.method = 'POST';
    request.payload = {id: '', userId: userId, documentId: documentId};
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  addFavoriteDocument: async (documentIds: string[], callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.FAVORITE_DOCUMENTS;
    request.method = 'POST';
    request.successMessage = 'Document added to favorites.';
    request.payload = {documentIds: documentIds};
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteFavoriteDocument: async (documentIds: string[], callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.FAVORITE_DOCUMENTS;
    request.method = 'DELETE';
    request.successMessage = 'Document removed from favorites.';
    request.payload = {documentIds: documentIds};
    request.skipResponseJsonParse = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getValidFileTypes: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_DOCUMENT_FILE_TYPES;
    request.method = 'GET';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }

    return response;
  },
  shareDocuments: async (emails: string[], documentId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.SHARE_DOCUMENT;
    request.method = 'POST';
    request.payload = {documentId: documentId, tOs: emails.join(';')};
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  uploadMainDocumentFromTeams: async (
    documentId: string,
    driveId: string,
    parentFolderID: string,
    itemId: string,
    libraryId?: string,
    callback?: (response: IAPIResponse) => void,
  ) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.UPLOAD_MAIN_DOC_URL_FROM_TEAMS;
    request.method = 'POST';
    request.payload = {
      documentId: documentId,
      driveId: driveId,
      itemId: parentFolderID,
      uploadAsZipFile: true,
      filesIds: [itemId],
      libraryId: libraryId,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  uploadAttachmentsFromTeams: async (
    documentId: string,
    driveId: string,
    parentFolderID: string,
    itemsId: string[],
    libraryId?: string,
    callback?: (response: IAPIResponse) => void,
  ): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.UPLOAD_ATTACHMENTS_URL_FROM_TEAMS;
    request.method = 'POST';
    request.payload = {
      documentId: documentId,
      driveId: driveId,
      itemId: parentFolderID,
      uploadAsZipFile: true,
      filesIds: itemsId,
      libraryId: libraryId,
    };
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default DocumentController;
