import {useDispatch, useSelector} from 'react-redux';
import './AdminLibrary.scss';
import {useEffect, useState} from 'react';
import {ILibrary} from '../../../Models/ILibrary';
import ApiService from '../../../Services/ApiService';
import {IAPIResponse} from '../../../Services/AjaxService';
import {FileList as FileListC, LabelButton} from '@Eni/docware-fe-master';
import {SelectionMode, Spinner} from '@fluentui/react';
import {AdminAction} from '../../../Reducers/Admin/AdminActions';
import {useNavigate} from 'react-router-dom';
import AppRoutes from '../../../Utils/AppRoutes';
import {GlobalState} from '../../../Reducers/RootReducer';
import {getExportStatus, StatusBubbleGeneric} from '../../../Components/StatusBubbleGeneric/StatusBubbleGeneric';

const AdminLibrary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [libraries, setLibraries] = useState<ILibrary[]>([]);
  const selectedLibrary = useSelector<GlobalState, ILibrary>((state) => state.admin.libraryTab.selectedLibrary);
  const reloadRequest = useSelector<GlobalState, boolean>((state) => state.generic.reloadRequest);

  const loadLibraries = () => {
    setLoading(true);
    ApiService.LibraryController.getAllLibraries((response: IAPIResponse) => {
      if (response.error === null) {
        setLibraries(response.payload);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadLibraries();
  }, []);

  useEffect(() => {
    loadLibraries();
  }, [reloadRequest]);

  return (
    <>
      <div className="admin-library-page-title">Libraries</div>
      <LabelButton
        text="Add"
        icon="Add"
        onClick={() => {
          navigate(AppRoutes.NEW_LIB_PAGE);
        }}
      />
      {loading ? (
        <Spinner label="Loading..." />
      ) : (
        <FileListC
          defaultSortOnThisColumnIndex={0}
          fileIconFromField={''}
          onItemsSelected={(selectedRows: ILibrary[]) => {
            if (selectedRows.length === 0) {
              dispatch(AdminAction.setSelectedLibraries(undefined));
            }
            if (selectedRows.length === 1) {
              dispatch(AdminAction.setSelectedLibraries(selectedRows[0]));
            }
          }}
          currentSelectedItems={undefined}
          currentSelectedItem={selectedLibrary}
          hideBottomButton={true}
          selectionMode={SelectionMode.single}
          tableName={'normal'}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'name',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Default Security Level',
              dataType: 'boolean',
              fieldName: 'active',
              iconName: null,
              size: 'medium',
              onRender: (item: ILibrary) => {
                return item.active?.toString();
              },
            },
            {
              name: 'Default Security Level',
              dataType: 'boolean',
              fieldName: 'defaultSecurityLevel',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Permission Model',
              dataType: 'boolean',
              fieldName: 'permissionModel',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Export Status',
              dataType: 'number',
              fieldName: 'exportStatus',
              iconName: null,
              size: 'small',
              onRender: (item: ILibrary) => {
                return <StatusBubbleGeneric bubbleStatus={getExportStatus(item.exportStatus)} />;
              },
            },
          ]}
          items={libraries}
        />
      )}
    </>
  );
};

export default AdminLibrary;
