import './NotFoundPage.scss';
import {getTrackingComponent, reactPlugin} from './../../AppInsights';
import {useAppInsightsContext, withAITracking} from '@microsoft/applicationinsights-react-js';

const NotFoundPage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'NotFoundPage'}
  );

  return (
    <div className="general-page-container">
      <div className="not-found-inner-wrap">
        <img className="not-found-image" alt="" src={'./assets/404robot.png'} />
        <div className="not-found-number">404</div>
        <div className="not-found-label">The page you are looking for does not exist, or has been removed.</div>
      </div>
    </div>
  );
};

export default getTrackingComponent(NotFoundPage, 'NotFoundPage');
