// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JbCb9NfxSm-op1jMj6BQFg\\=\\= {\n  /*display: flex;\n  align-items: end;*/\n}\n\n._3\\+Z3s\\+rq4jmK2Vo5y5lNCQ\\=\\= {\n  display: flex;\n  align-items: flex-end;\n  width: 100%;\n  margin-bottom: 1em;\n}\n\n.yRiPrZtdZVOlbmh-aDs3Eg\\=\\= {\n  background: white;\n}", "",{"version":3,"sources":["webpack://./src/Components/ExpAssuranceCascadeBoPicker/ExpAssuranceCascadeBoPicker.module.css"],"names":[],"mappings":"AAAA;EACE;oBACkB;AACpB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".rowContainer {\n  /*display: flex;\n  align-items: end;*/\n}\n\n.doubleRowContainer {\n  display: flex;\n  align-items: flex-end;\n  width: 100%;\n  margin-bottom: 1em;\n}\n\n.tagpicker {\n  background: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowContainer": "JbCb9NfxSm-op1jMj6BQFg==",
	"doubleRowContainer": "_3+Z3s+rq4jmK2Vo5y5lNCQ==",
	"tagpicker": "yRiPrZtdZVOlbmh-aDs3Eg=="
};
export default ___CSS_LOADER_EXPORT___;
