import { IDownloadPayload, IDownloadType } from '../../Models/IDownload';
import { getCurrentDateFormatted } from '../../Utils/DateUtils';
import EventDispatcher from '../../Utils/EventDispatcher';
import { AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse } from '../AjaxService';

const DownloadController = {
  downloadFile: async (
    documentIds: string[],
    downloadOriginalFile: boolean,
    downloadSignedFile: boolean,
    downloadAttachments: boolean,
    callback?: (response: IAPIResponse) => void
  ) => {
    let zipPopup = document.createElement('div');
    zipPopup.id = 'zip-popup-item';
    zipPopup.className = 'zip-popup-class-wrap';
    zipPopup.innerHTML = 'ZIP download in progress. Please, wait...';
    document.body.appendChild(zipPopup);

    let request: IAPIRequest = createEmptyRequest();
    request.method = 'POST';
    request.payload = {
      documentIds: documentIds,
      downloadOriginalFile: downloadOriginalFile,
      downloadSignedFile: downloadSignedFile,
      downloadAttachments: downloadAttachments,
    };
    request.url = `/download/documents/download/zip`;
    let response: IAPIResponse = await AjaxService.call(request);
    document.body.removeChild(zipPopup);
    if (callback) {
      callback(response);
    }

    if (response.error == null) {
      const payload = response.payload as IDownloadPayload;
      if (!payload.error) {
        if (payload.downloadType === IDownloadType.Direct) {
          AjaxService.downloadBase64File('DocwareFiles.zip', payload.data);
        } else {
          EventDispatcher.dispatchToast('You will receive an email with the link to download the documents', 'success');
        }
      } else {
        EventDispatcher.dispatchToast(payload.data, 'error');
      }
    }

    return response;
  },
  searchExport: async (body: any, startDownloadIfPossible?: boolean, callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `/download/documents/search/export`;
    request.method = 'post';
    request.payload = body;

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    if (startDownloadIfPossible) {
      if (response.error == null) {
        let payload = response.payload as IDownloadPayload;
        if (payload.downloadType === IDownloadType.Direct) {
          AjaxService.downloadBase64File(`Search_Result_Export_${getCurrentDateFormatted()}.xlsx`, payload.data);
        } else {
          EventDispatcher.dispatchToast(
            "We're preparing your export. You will receive an email with the link to download the export",
            'success'
          );
        }
      }
    }
    return response;
  },
};

export default DownloadController;
