export const concatRoute = (firstRoute, secondRoute) => {
  return `${firstRoute}/${secondRoute}`;
};

/**
 * Given an `obj`ect, a dot-separated `path` and a `value`,
 * insert that `value` into the object with that specific `path`,
 * creating all the sub-objects needed
 * E.g.
 * `injectValue({}, "a.b", 123) === {a: { b: 123 } }`
 * @param obj The object in which to insert the value
 * @param path The path where the value must be inserted. Sub-objects are separated by dots
 * @param value The value to be inserted
 * @returns
 */
export function injectValue(obj: any, path: string, value: any): any {
  let stepObj: any = obj;
  let splPath = path.split('.');
  for (let i = 0; i < splPath.length; i++) {
    let step = splPath[i];

    /** last step -> add value */
    if (i === splPath.length - 1) {
      stepObj[step] = value;
    } else {
      if (!stepObj.hasOwnProperty(step)) {
        stepObj[step] = {};
      }
      stepObj = stepObj[step];
    }
  }

  return obj;
}

/**
 * Wait for `prom` for the given `time`, throw an error if the timeout is exceeded
 * @param prom Promise to execute
 * @param time Times in milliseconds to wait for the
 * @returns A promise that errors out after the time out
 */
export async function withTimeout<T>(prom: Promise<T>, time: number): Promise<T> {
  return Promise.race([prom, new Promise<T>((_r, rej) => setTimeout(rej, time))]);
}
