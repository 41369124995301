import {useEffect, useState} from 'react';
import Icons from '../../Utils/FabricIconsOutlet';
import {Persona, PersonaSize, PersonaPresence} from '@fluentui/react/lib/Persona';

import './PeopleGallery.scss';
import { SignType, getSignTypeString } from '../../Models/IWorkflowItem';

export interface IPeopleGalleryToken {
  id: string;
  role: string | undefined;
  status: string | null;
  signType?: SignType
}

export interface IPeopleGalleryProps {
  shortView?: boolean;
  people: IPeopleGalleryToken[];
}

const PeopleGallery = (props: IPeopleGalleryProps) => {
  const [people, setPeople] = useState<IPeopleGalleryToken[]>([]);
  const [more, setMore] = useState<boolean>(false);

  useEffect(() => {
    setPeople(props.people ? props.people : []);
  }, [props.people]);

  const getPersonaPresence = (status: string) => {
    if (status === 'Pending') {
      return PersonaPresence.offline;
    }
    if (status === 'Signed') {
      return PersonaPresence.online;
    }
    if (status === 'Rejected') {
      return PersonaPresence.dnd;
    }
    return PersonaPresence.none;
  };


  return (
    <div className="people-gallery-wide-wrap">
      {!props.shortView &&
        people.map((x: IPeopleGalleryToken, i: number) => {
          let status = x.status;
          if(x.status === "Signed"){
            status = `Signed (with ${getSignTypeString(x.signType)})`;
          }
          const secondaryText = `${x.role ?? ''}${status && x.role ? ' | ' : ''}${status ?? ''}`;
          return (
            <div className="people-gallery-wide-inner-wrap" key={i}>
              <div>
                <Persona
                  text={x.id}
                  size={PersonaSize.size40}
                  presence={getPersonaPresence(x.status ?? '')}
                  secondaryText={secondaryText}
                />
              </div>
            </div>
          );
        })}
      {props.shortView && (
        <div>
          {!more && (
            <div className="people-gallery-small-inner-wrap">
              {people.slice(0, 5).map((x: IPeopleGalleryToken, i: number) => {
                return (
                  <div className="people-gallery-small-circle-wrap" key={i} title={x.id}>
                    <Persona text={x.id} size={PersonaSize.size32} />
                  </div>
                );
              })}
              {people.length > 5 && (
                <div className="people-gallery-small-circle-wrap">
                  <div
                    className="people-gallery-circle people-gallery-gray-more-circle"
                    onClick={() => {
                      setMore(true);
                    }}>
                    {'+' + (people.length - 5).toString()}
                  </div>
                </div>
              )}
            </div>
          )}
          {more && (
            <div className="people-gallery-small-inner-wrap">
              {people.map((x: IPeopleGalleryToken, i: number) => {
                return (
                  <div className="people-gallery-small-circle-wrap" key={i} title={x.id}>
                    <Persona text={x.id} size={PersonaSize.size32} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PeopleGallery;
