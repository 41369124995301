import {IPersonaProps, ITag} from '@fluentui/react';
import {IPersonaOptionDto} from '../Models/IOptionDto';
import {ICountryVoice} from '../Models/IUser';
import {DocumentSource} from '../Models/DocumentSource';
import {bonifyEmailField} from './DictUtils';
import {IBoLibrary, ILibrary, ILibraryDocumentSubTypeBase, ILibraryDocumentType} from '../Models/ILibrary';
import {IValue} from '../Models/IValue';
import {getInputInfosValue} from '../Reducers/Generic/GenericAction';
import {BOsObject, SplittetBosUpload, SplittetBosSearch} from '../Models/BusinessObject';

export const authorsShouldBeRequiredFlag = (sources: DocumentSource[], sourceKey: string) => {
  let matched: DocumentSource[] = sources.filter((x: DocumentSource) => x.id === sourceKey);
  if (matched.length > 0) {
    return matched[0].authorsAreRequired;
  }
  return false;
};

export const getBoNamesFromSources = (libraries?: ILibrary[], sources?: string[]): string[] => {
  if (libraries && sources) {
    const bos = libraries.map(l => l.bOs).flat()
    const bosWithSources = bos.filter(b => sources.includes(b.source))
    return [...new Set(bosWithSources.map(x => x.displayName))]
  } else {
    return []
  }
}

export const getMasterDataNamesFromSources = (libraries?: ILibrary[], sources?: string[]): string[] => {
  if (libraries && sources) {
    const masterData = libraries.map(l => l.masterDatas).flat()
    const masterDataWithSources = masterData.filter(b => sources.includes(b.source))
    return [...new Set(masterDataWithSources.map(x => x.displayName))]
  } else {
    return []
  }
}

export const getRequiredBosList = (libraries?: ILibrary[], documentSubTypeId?: string) => {
  if (libraries && documentSubTypeId) {
    for (const lib of libraries) {
      for (const type of lib.documentTypes) {
        if (type.subTypes) {
          for (const sub of type.subTypes) {
            if (sub.id === documentSubTypeId) {
              return sub.mandatoryBOs ?? [];
            }
          }
        }
      }
    }
  }
  return [];
};

export const getRequiredMasterDataList = (libraries?: ILibrary[], documentSubTypeId?: string) => {
  if (libraries && documentSubTypeId) {
    for (const lib of libraries) {
      for (const type of lib.documentTypes) {
        if (type.subTypes) {
          for (const sub of type.subTypes) {
            if (sub.id === documentSubTypeId) {
              return sub.mandatoryMasterDatas ?? [];
            }
          }
        }
      }
    }
  }
  return [];
};

export const optionsToPersonas = (users: IPersonaOptionDto[]) => {
  return users.map((u) => {
    return {
      key: u.id,
      text: u.name,
      secondaryText: u.id.toUpperCase() + ' - ' + bonifyEmailField(u.secondaryText),
    };
  }) as IPersonaProps[];
};

export const personasToOptions = (users: IPersonaProps[]) => {
  return users.map((u, i) => {
    return {
      id: u.key,
      name: u.text,
      secondaryText: u.secondaryText,
    };
  }) as IPersonaOptionDto[];
};

export const getUserCountries = (text: string, selectedItems: ITag[], countries: ICountryVoice[], selectedProfessionalAreaIds: string[]) => {
  let matches: ITag[] = [];

  if (!selectedProfessionalAreaIds) {
    return matches;
  }

  if (text.length < 3 || selectedProfessionalAreaIds.length === 0) {
    return matches;
  }

  for (let i = 0; i < countries.length; i++) {
    let country: ICountryVoice = countries[i];
    if (
      country.name.toLowerCase().indexOf(text.toLowerCase()) !== -1 &&
      selectedItems.filter((x: ITag) => x.key === country.code).length === 0 &&
      selectedProfessionalAreaIds.includes(country.library) &&
      matches.filter((x: ITag) => x.key === country.code).length === 0
    ) {
      matches.push({key: country.code, name: country.name});
    }
  }

  return matches;
};

const getAggregationBosExclusive = (selectedLibrary: ILibrary, documentBos: BOsObject) => {
  const aggregatedBosExistent: BOsObject = {};

  if (!selectedLibrary || !selectedLibrary.boAggregations) {
    return {};
  }

  const aggregatedBos = selectedLibrary.boAggregations ?? [];
  for (let i = 0; i < aggregatedBos.length; i++) {
    if (aggregatedBos[i].isExclusive) {
      const source = aggregatedBos[i].source;
      if (documentBos.hasOwnProperty(source)) {
        aggregatedBosExistent[source] = documentBos[source];
      }
    }
  }
  return aggregatedBosExistent;
};

const getAggregationBosNONExclusive = (selectedLibrary: ILibrary, documentBos: BOsObject) => {
  let aggregatedBosExistent: BOsObject = {};
  if (!selectedLibrary) {
    return aggregatedBosExistent;
  }
  if (!selectedLibrary.boAggregations) {
    return aggregatedBosExistent;
  }
  let aggregatedBos = selectedLibrary.boAggregations ?? [];
  for (let i = 0; i < aggregatedBos.length; i++) {
    if (aggregatedBos[i].isExclusive === false) {
      let source = aggregatedBos[i].source;
      if (documentBos.hasOwnProperty(source)) {
        aggregatedBosExistent[source] = documentBos[source];
      }
    }
  }
  return aggregatedBosExistent;
};

const getAggregationBosSearch = (selectedLibrary: ILibrary, documentBos: BOsObject) => {
  const aggregatedBosExistent: BOsObject = {};

  if (!selectedLibrary || !selectedLibrary.boAggregationsSearch) {
    return {};
  }
  const aggregatedBos = selectedLibrary.boAggregationsSearch ?? [];
  for (let i = 0; i < aggregatedBos.length; i++) {
      const source = aggregatedBos[i].source;
      if (documentBos.hasOwnProperty(source)) {
        aggregatedBosExistent[source] = documentBos[source];
      }
  }
  return aggregatedBosExistent;
}

const getNotAggregationBos = (selectedLibrary: ILibrary, documentBos: BOsObject, isSearch: boolean = false) => {
  let normalBos: BOsObject = {};
  if (!selectedLibrary) {
    return normalBos;
  }
  if (!selectedLibrary.bOs) {
    return normalBos;
  }

  const bos: IBoLibrary[] = isSearch ? (selectedLibrary.boSearch as IBoLibrary[]) : selectedLibrary.bOs;
  let bosListKeys = bos.concat(selectedLibrary.masterDatas).map((x) => x.source);
  let bosKeys = Object.keys(documentBos);

  for (let i = 0; i < bosKeys.length; i++) {
    let key = bosKeys[i];
    if (bosListKeys.indexOf(key) !== -1) {
      normalBos[key] = documentBos[key];
    }
  }

  return normalBos;
};


export const getSplittedBosMultipleAreasSearch = (selectedLibraries: ILibrary[], documentBos: BOsObject): SplittetBosSearch => {
  let baseObj = {
    normals: {},
    search: {},
    aggregatedSearch: {},
  };

  for (let i = 0; i < selectedLibraries.length; i++) {
    let bos = getSplittedBosSearch(selectedLibraries[i], documentBos);
    baseObj.normals = {...baseObj.normals, ...bos.normals};
    baseObj.search = {...baseObj.search, ...bos.search};
    baseObj.aggregatedSearch = {...baseObj.aggregatedSearch, ...bos.aggregatedSearch};
  }

  return baseObj;
};

export const getSplittedBosUpload = (selectedLibrary: ILibrary, documentBos: BOsObject): SplittetBosUpload => {
  return {
    normals: getNotAggregationBos(selectedLibrary, documentBos),
    aggregated: getAggregationBosExclusive(selectedLibrary, documentBos),
    aggregatedNONExclusive: getAggregationBosNONExclusive(selectedLibrary, documentBos),
  };
};

const getSplittedBosSearch = (selectedLibrary: ILibrary, documentBos: BOsObject): SplittetBosSearch => {
  return {
    normals: getNotAggregationBos(selectedLibrary, documentBos),
    search: getNotAggregationBos(selectedLibrary, documentBos, true),
    aggregatedSearch: getAggregationBosSearch(selectedLibrary, documentBos),
  };
};

export const cleanBoName = (name: string, libraries: ILibrary[]): string => {
  if (!name) {
    return '-';
  }

  for (let i = 0; i < libraries.length; i++) {
    let bos = libraries[i].bOs;
    for (let j = 0; j < bos.length; j++) {
      if (bos[j].source === name) {
        return bos[j].displayName;
      }
    }
  }

  let customNames = getInputInfosValue('aggregated-bo', 'bo-custom-names');
  if (customNames != null) {
    customNames = JSON.parse(customNames) ?? {};
    if (customNames.hasOwnProperty(name)) {
      return customNames[name];
    }
  }

  name = name.replaceAll('expbmt_', '');
  name = name.replaceAll('xware_', '');

  let finalName = '';
  let splName = name.split('_');
  for (let i = 0; i < splName.length; i++) {
    finalName += splName[i].charAt(0).toLocaleUpperCase() + splName[i].substring(1) + ' ';
  }

  return finalName;
};

export const validateData = (data: any) => {
  let value = data.value;
  let valid = true;
  //* deny empty arrays */
  if (Array.isArray(value)) {
    //** deny empty data */
    if (value.length === 0) {
      valid = false;
    }
  } else {
    valid = value != null && value !== undefined && value !== '';
  }
  return valid;
};
