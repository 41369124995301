import {useAppInsightsContext, useTrackEvent} from '@microsoft/applicationinsights-react-js';
import {Fragment, useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {IDocumentSignStep, INewDocument, INewDocumentPreFillData} from '../../Models/IDocument';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import {NewDocumentAction} from '../../Reducers/NewDocument/NewDocumentActions';
import AppRoutes from '../../Utils/AppRoutes';
import ApiService from '../../Services/ApiService';
import {DocumentMetadata} from './DocumentMetadata/DocumentMetadata';
import {getTrackingComponent} from '../../AppInsights';
import {DocumentBO} from './DocumentBO/DocumentBO';
import {DocumentSummary} from './DocumentSummary/DocumentSummary';

import FileUploadBoxPreview from '../../Components/FileUploadBoxPreview/FileUploadBoxPreview';
import {Spinner} from '@fluentui/react';
import {DEFAULT_NEW_DOCUMENT_STATE} from '../../Reducers/NewDocument/NewDocumentState';
import {setPageContentState} from '../FullPageMessage/FullPageMessage';
import {AjaxService, IAPIResponse} from '../../Services/AjaxService';
import {
  HierarchyLibrary,
  ILibrary,
  ILibraryDocumentSubTypeBase,
  ILibraryDocumentType,
  IProfessionalArea,
  MetadataType,
} from '../../Models/ILibrary';
import {GenericActions, getInputInfos} from '../../Reducers/Generic/GenericAction';
import {IWorkflowSignStep, SignType} from '../../Models/IWorkflowItem';
import {Banner} from '@Eni/docware-fe-master';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {DocumentSource} from '../../Models/DocumentSource';
import {IDocumentTypes, IDocumentTypesForLibrary} from '../../Models/DocumentTypes';
import InputInfoWidget from '../../Components/InputInfoWidget/InputInfoWidget';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../Components/FluentUIDecorator/FluentUIDecorator';
import {AttachmentsContainer} from '../../Components/AttachmentsContainer/AttachmentsContainer';
import {MainDocumentContainer} from '../../Components/MainDocumentContainer/MainDocumentContainer';
import {CountdownBox} from '../../Components/CountdownBox/CountdownBox';
import DocumentEditLockController from '../../Services/Controllers/DocumentEditLockController';
import {IPersonaOptionDto} from '../../Models/IOptionDto';
import {IValue} from '../../Models/IValue';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import {BoUtils} from '../../Utils/BoUtils';
import {authorsShouldBeRequiredFlag, getBoNamesFromSources, getRequiredBosList, validateData, getSplittedBosUpload, getRequiredMasterDataList, getMasterDataNamesFromSources} from '../../Utils/NewDocumentUtils';
import { LibraryUtils } from '../../Utils/LibraryUtils';
import useFeature from '../../Components/FeatureFlag/useFeature';
import {DOCUMENT_MANAGEMENT} from '../../Utils/AppRoutes';
import {DOCUMENT_UPLOAD_FF_KEY, MASTER_DATA_FOR_SUBTYPE_FF_KEY} from '../../Constants/FeatureFlags';

const validateDraftForm = (
  currentDocument: INewDocument,
  selectedLibrary: ILibrary,
  approvalNeeded: number,
  showMessages = true,
) => {
  if (currentDocument) {
    let toValidate = [
      {
        name: 'Area',
        value: currentDocument.professionalArea ? currentDocument.professionalArea.id : null,
      },
      {name: 'Document Name', value: currentDocument.documentName},
      {name: 'Document Type', value: currentDocument.documentTypeId},
      {name: 'Document SubType', value: currentDocument.documentSubTypeId},
      {name: 'Document Countries', value: currentDocument.countries},
      {name: 'Document Security Level', value: currentDocument.securityLevel},
    ];

    // they must all exist
    let invalids = toValidate.filter((x) => !validateData(x));
    if (invalids.length > 0) {
      if (showMessages) {
        AjaxService.openToastError(
          'Please fill the required input: ' +
            invalids
              .map((x: any) => {
                return x.name;
              })
              .join(', ')
        );
      }
      return false;
    }

    return true;
  }

  if (showMessages) {
    AjaxService.openToastError('Please, fill the needed form fields before saving.');
  }
  return false;
};

export const validateForm = (currentDocument: INewDocument, selectedLibrary: ILibrary, approvalNeeded: number, showMessages = true) => {
  if (currentDocument) {
    let toValidate = [
      {name: 'Main Document', value: currentDocument.mainFile},
      {
        name: 'Area',
        value: currentDocument.professionalArea ? currentDocument.professionalArea.id : null,
      },
      {name: 'Document Name', value: currentDocument.documentName},
      {name: 'Document Date', value: currentDocument.documentDate},
      {name: 'Document Status', value: currentDocument.documentStatus},
      {name: 'Document Countries', value: currentDocument.countries},
      {name: 'Document Type', value: currentDocument.documentTypeId},
      {name: 'Document SubType', value: currentDocument.documentSubTypeId},
      {name: 'Document Source', value: currentDocument.documentSource},
      {name: 'Document Security Level', value: currentDocument.securityLevel},
    ];

    // they must all exist
    let invalids: any = toValidate.filter((x) => !validateData(x));

    let dynamicMeta = (selectedLibrary.metadata || []).filter((x) => x.required);
    let dynamicMetaFromForm = currentDocument.metadata ?? {};
    for (let i = 0; i < dynamicMeta.length; i++) {
      let m: any = dynamicMeta[i];
      let value: any = null;

      //* the required dynamic field exists */
      if (dynamicMetaFromForm[m.name]) {
        if (m.type === MetadataType.Text) {
          value = dynamicMetaFromForm[m.name].stringValue;
        } else if (m.type === MetadataType.Date) {
          value = dynamicMetaFromForm[m.name].dateValue;
        } else if (m.type === MetadataType.Select) {
          value = dynamicMetaFromForm[m.name].selectValue;
        } else if (m.type === MetadataType.MultiSelect) {
          value = dynamicMetaFromForm[m.name].multiselectValue;
        } else if (m.type === MetadataType.AutoIncrement) {
          value = dynamicMetaFromForm[m.name].numberValue;
        } else if (m.type === MetadataType.TagPickerSingleSelect) {
          value = dynamicMetaFromForm[m.name].selectValue;
        }
      }

      if (!validateData({value: value})) {
        invalids.push({name: m.displayName});
      }
    }

    if (invalids.length > 0) {
      if (showMessages) {
        AjaxService.openToastError(
          'Please fill the required input(s): ' +
            invalids
              .map((x: any) => {
                return x.name;
              })
              .join(', ')
        );
      }
      return false;
    }

    if (approvalNeeded > 0) {
      for (let k = 0; k < currentDocument.signers.length; k++) {
        let signStep: IDocumentSignStep = currentDocument.signers[k];
        if (signStep.users.length === 0) {
          if (showMessages) {
            AjaxService.openToastError('You must specify at least one user in each Approval Step.');
          }
          return false;
        }
      }
    }

    return true;
  }

  if (showMessages) {
    AjaxService.openToastError('Please, fill the needed form fields before saving.');
  }
  return false;
};

const NewDocPageInner = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'NewDocPage'}
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const approvalNeeded = useSelector<GlobalState, number>((state) => state.generic.documentUploadApprovalFlag);
  const currentDocument = useSelector<GlobalState, INewDocument>((state) => state.newDocument.document);
  const libraries = useSelector<GlobalState, ILibrary[]>((state) => state.newDocument.userLibraries);
  const selectedLibrary = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);
  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);
  const hierarchy = useSelector<GlobalState, HierarchyLibrary[]>((state) => state.generic.libraryHierachyModel);
  const [oldFiles, setOldFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSavingInner] = useState<boolean>(false);
  const [lockPage, setLockPage] = useState<string | null>(null);
  const [docSources, setDocSources] = useState<DocumentSource[]>([]);
  const [docTypes, setDocTypes] = useState<IDocumentTypesForLibrary[]>([]);
  const masterDataFeature = useFeature(MASTER_DATA_FOR_SUBTYPE_FF_KEY)
  const documentUploadEnabled = useFeature(DOCUMENT_UPLOAD_FF_KEY)

  const [teamsFilePickerEnabled, setTeamsFilePickerEnabled] = useState<boolean>(false);
  const [countdownMaxExpireTime, setCountdownMaxExpireTime] = useState<number>();

  const currentDocumentSet = (doc: INewDocument) => {
    dispatch(NewDocumentAction.setDocument(doc));
  };

  const currentDocumentGet = () => {
    return currentDocument;
  };

  // developer tools
  if (localStorage.getItem('enable-doc-dev-tools')) {
    (window as any)['documentDevTools'] = {
      currentDocumentSet,
      currentDocumentGet,
    };
  }

  const setSaving = (value: boolean) => {
    if (value) {
      setSavingInner(true);
    } else {
      setTimeout(() => {
        try {
          setSavingInner(false);
        } catch (e) {}
      }, 3000);
    }
  };

  const [params] = useSearchParams();
  const pageMode = params.get('mode');
  const previousVersionId = params.get('previousId');
  const docIdParam = params.get('documentid');
  const preFillDataQuery = params.get('preFillData');

  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  /** application insight */
  const appInsights = useAppInsightsContext();
  const trackDocumentAction = useTrackEvent(
    appInsights,
    pageMode == 'newversion' ? 'DocumentNewVersion' : pageMode == 'edit' ? 'DocumentEdit' : 'DocumentUpload',
    {
      UserId: loggedUser.id,
      UserName: loggedUser.firstName + ' ' + loggedUser.lastName,
      UserLibraries: loggedUser.UserLibraries,
      UserProfAreas: loggedUser.UserProfAreas,
    }
  );

  const sendDocumentBusinessObjectHistory = async () => {
    let aggregatedBos = getSplittedBosUpload(selectedLibrary, currentDocument.bOs).aggregated;
    let keys = Object.keys(aggregatedBos);
    for (let i = 0; i < keys.length; i++) {
      let boAggregation: {id: string; name: string}[] = aggregatedBos[keys[i]];
      for (let j = 0; j < boAggregation.length; j++) {
        let boItem = boAggregation[j];
        await ApiService.BusinessObjectHistoryController.addHistory(
          {
            historyType: keys[i],
            historyValue: boItem.name,
          },
          (response: IAPIResponse) => {}
        );
      }
    }
  };

  const documentError = (
    title: string,
    message: string,
    imageName: string,
    documentId: string,
    buttonDescriptions?: {label: string; path: string}
  ) => {
    /** define with this call what will be visible in the MESSAGE_PAGE */
    let desc = buttonDescriptions ? buttonDescriptions : {label: 'Go back to Home', path: AppRoutes.HOME_ROUTE};
    setPageContentState({
      title: title,
      subtitle: message,
      smallSubtitle: documentId,
      imageName: imageName,
      button: {
        icon: 'ChromeBackMirrored',
        text: desc.label,
        onClick: {
          type: 'navigate',
          arguments: desc.path,
        },
      },
    });

    /** goto the MESSAGE_PAGE. this must happen after calling setPageContentState */
    navigate(AppRoutes.MESSAGE_PAGE);
  };

  const documentLockExpired = (documentId: string) => {
    documentError(
      `The document lock has expired`,
      'The document lock has expired because you have exceeded the maximum time or an admin has deleted your lock',
      'warning',
      documentId
    );
  };

  const documentLockManagement = (documentId: string) => {
    DocumentEditLockController.lock(documentId, (response) => {
      if (response.error == null) {
        setCountdownMaxExpireTime(response.payload.documentLockMaxExpireTime);
        const documentLockRenewTime = response.payload.documentLockTime / 2;

        const lockRenew = () => {
          DocumentEditLockController.renew(documentId, (response) => {
            if (response.error) {
              documentLockExpired(documentId);
            } else {
              if (response.payload.documentLockMaxExpireTime !== countdownMaxExpireTime) {
                setCountdownMaxExpireTime(response.payload.documentLockMaxExpireTime);
              }
            }
          });
        };
        const intervalID = setInterval(lockRenew, documentLockRenewTime * 1000);
        dispatch(NewDocumentAction.setDocumentLock({documentId: documentId, intervalID: intervalID}));
      } else {
        documentError(
          `The document is locked by ${response.payload.detail}`,
          'Wait that the user complete his work or ask an administrator to unlock the document',
          'warning',
          documentId
        );
      }
    });
  };

  const getDocument = async (documentId: string, libraries: ILibrary[]) => {
    // if an active flow exists, you cannot release a new version
    ApiService.WorkflowController.getById(documentId, (response: IAPIResponse) => {
      if (response.error === null) {
        if (pageMode === 'newversion' && response.payload !== null) {
          setLockPage('You cannot update a document when an approval flow is still active.');
        }
      }
    });

    if (pageMode === 'newversion' && previousVersionId) {
      ApiService.DocumentController.getPreviousVersionFiles(previousVersionId, (response: IAPIResponse) => {
        if (response.error == null) {
          setOldFiles(response.payload);
        } else {
          setOldFiles([]);
        }
      });
    }

    setLoading(true);
    const getDocumentResponse = await ApiService.DocumentController.getDocument(documentId);
    if (getDocumentResponse.error == null) {
      let doc: INewDocument = getDocumentResponse.payload;

      const currentUser: IPersonaOptionDto = {
        id: loggedUser.id,
        name: loggedUser.firstName + ' ' + loggedUser.lastName,
        secondaryText: loggedUser.email,
      };

      if (pageMode !== 'newversion' && pageMode !== 'edit') {
        // the document is new (being created in this user session)
        if (doc.authors.length === 0) {
          doc.authors = [currentUser];
        }

        // Pre fill
        let preFillData: INewDocumentPreFillData;
        try {
          preFillData = JSON.parse(preFillDataQuery);
        } catch (error) {
          console.error('preFillData is not a valid json', error);
        }
        if (preFillData) {
          if (preFillData.professionalAreaId) {
            const library: ILibrary = libraries.filter(
              (lib) => lib.professionalAreas.filter((profArea) => profArea.id === preFillData.professionalAreaId).length > 0,
            )[0];
            const professionalArea: IProfessionalArea = library ? library.professionalAreas.filter(
              (profArea) => profArea.id == preFillData.professionalAreaId,
            )[0] : null;
            if (library && professionalArea) {
              doc.professionalArea = professionalArea;
              doc.library = library.name;
              doc.securityLevel = LibraryUtils.getDefaultSecurityLevelsFromLibrary(library, professionalArea).default
              if (preFillData.countriesId) {
                const countries: IValue[] = permissions.countries
                  .filter((country) => country.library === library.name && preFillData.countriesId.includes(country.code))
                  .map((c) => {
                    return {id: c.code, name: c.name};
                  });
                if (countries) {
                  doc.countries = countries;
                  if (preFillData.bOs) {
                    doc.bOs = await BoUtils.getBoNames(preFillData.bOs, countries.map(c => c.id));
                  }
                }
              }
              if (preFillData.documentTypeId) {
                const documentType: ILibraryDocumentType = library.documentTypes.filter(
                  (dt) => dt.isActive && dt.professionalAreaIds.includes(professionalArea.id) && dt.id === preFillData.documentTypeId
                )[0];
                if (documentType) {
                  doc.documentTypeId = documentType.id;
                  doc.documentTypeName = documentType.name;
                  if (preFillData.documentSubTypeId) {
                    const documentSubType: ILibraryDocumentSubTypeBase = documentType.subTypes?.filter(
                      (dst) => dst.isActive && dst.id === preFillData.documentSubTypeId
                    )[0];
                    if (documentSubType) {
                      doc.documentSubTypeId = documentSubType.id;
                      doc.documentSubTypeName = documentSubType.name;
                    }
                  }
                }
              }
            }
          }
          if (preFillData.documentName) {
            doc.documentName = preFillData.documentName;
          }
          if (preFillData.documentDate) {
            doc.documentDate = preFillData.documentDate;
          }
        }
      }

      if ((doc.coOwners ?? []).length === 0) {
        doc.coOwners = [currentUser];
      }
      doc.existingCoOwners = [...doc.coOwners];

      if (doc['countries'].length === 0) {
        doc['countries'] = undefined;
      }

      if (doc['relatedDocuments'] !== null) {
        doc['relatedDocuments'] = doc['relatedDocuments'].map((x) => {
          x['readonly'] = true;
          return x;
        });
      }

      if(pageMode == 'newversion' && doc.library == "DSD"){ // US: 14691
        doc.documentDate = undefined
      }

      dispatch(NewDocumentAction.setDocument(doc));
      dispatch(GenericActions.setUploadApprovalFlag((doc.signers ?? []).length));

      if (['newversion', 'edit'].includes(pageMode)) {
        documentLockManagement(documentId);
      }
    } else {
      if (getDocumentResponse.raw.status === 403) {
        documentError('Document not available.', 'You don\'t have permission to read this document.', 'accessDeniedDocument', documentId);
      } else if (getDocumentResponse.raw.status === 406) {
        documentError('Document is Tight.', 'You don\'t have permission to access this document.', 'documentNotExistRocket', documentId);
      } else if (getDocumentResponse.raw.status === 404) {
        documentError('Document not available.', 'This document does not exist.', 'documentNotExistRocket', documentId);
      } else {
        documentError('Ops! Something went wrong.', 'There was a problem opening this document.', 'documentNotExistRocket', documentId);
      }
    }
    setLoading(false);
  };

  const initPage = async () => {
    dispatch(NewDocumentAction.setDocument(DEFAULT_NEW_DOCUMENT_STATE.document));

    const [responseDocumentSource, responseDocumentTypes] = await Promise.all([
      ApiService.DocumentSourcesController.getDocumentSource(),
      ApiService.DocumentTypesController.getDocumentTypesForLibraries(),
    ]);

    if (responseDocumentSource.error === null && responseDocumentSource.payload !== null) {
      setDocSources(responseDocumentSource.payload);
    }

    if (responseDocumentTypes.error == null) {
      setDocTypes(responseDocumentTypes.payload);
    }

    if (docIdParam == null && !pageMode) {
      const responseCreateDocument: IAPIResponse = await ApiService.DocumentController.createDocument();
      if (responseCreateDocument.error == null) {
        dispatch(NewDocumentAction.setSelectedLibrary({} as ILibrary));

        navigate(`?documentid=${responseCreateDocument.payload.id}${preFillDataQuery ? `&preFillData=${preFillDataQuery}` : ""}`);
      }
    } else {
      getDocument(docIdParam, libraries);
    }
  };

  useEffect(() => {
    if (permissions) {
      if (permissions.canCreate || pageMode) {
        initPage();
      }
    }
  }, [permissions, docIdParam]);

  useEffect(() => {
    if (permissions) {
      if (!permissions.canCreate && !pageMode) {
        setLockPage('You are not allowed to create documents.');
      }

      if (pageMode === 'newversion' && currentDocument.documentStatus === 3) {
        setLockPage('You cannot update a rejected document.');
      }

      if (currentDocument && currentDocument.documentStatus === 4) {
        setLockPage('You cannot edit or update an obsolete document.');
      }

      if (selectedLibrary?.name == null && currentDocument?.professionalArea != null && libraries?.length > 0) {
        let library: any = null;
        for (let i = 0; i < libraries.length; i++) {
          let lib = libraries[i];
          for (let j = 0; j < lib.professionalAreas.length; j++) {
            let pArea: IProfessionalArea = lib.professionalAreas[j];
            if (pArea.id.indexOf(currentDocument.professionalArea.id) !== -1) {
              library = {...lib};
              break;
            }
          }
        }

        if (library != null) {
          dispatch(NewDocumentAction.setSelectedLibrary(library));
        }
      }
    }
  }, [currentDocument, libraries, permissions]);

  const onMainFileDelete = () => {
    if (currentDocument.mainFile != null) {
      /*ApiService.DocumentController.deleteMainDocument(currentDocument.documentId, (response) => {
        if (response.error == null) {
          dispatch(NewDocumentAction.setMainFile(null));
        }
      });*/
      dispatch(NewDocumentAction.setMainFile(null));
    }
  };

  const onAttachmentDelete = (fileId: string) => {
    if (currentDocument.attachments?.length > 0) {
      /*ApiService.DocumentController.deleteAttachment(currentDocument.documentId, fileId, (response) => {
        if (response.error == null) {
          dispatch(NewDocumentAction.deleteAttachment(fileId));
        }
      });*/
      dispatch(NewDocumentAction.deleteAttachment(fileId));
    }
  };

  const getSummary = (): any => {
    let mainFile = null;
    let attachments: any = [];

    if (currentDocument) {
      if (currentDocument.mainFile) {
        mainFile = currentDocument.mainFile;
      }
      if (currentDocument.attachments) {
        attachments = currentDocument.attachments;
      }
    }
    return {mainFile: mainFile ? 1 : 0, attachments: attachments};
  };

  let summary = getSummary();

  const saveComplete = () => {
    // approved documents remain approved
    if (currentDocument.documentStatus === 1) {
      save(1);
    }
    // pending documents remain pending
    if (currentDocument.documentStatus === 2) {
      save(2);
    }
    // else complete
    else {
      save(5);
    }

    trackDocumentAction({
      UserId: loggedUser.id,
      UserName: loggedUser.firstName + ' ' + loggedUser.lastName,
      UserLibraries: loggedUser.UserLibraries,
      UserProfAreas: loggedUser.UserProfAreas,
    });
  };

  const concludeFlowStep = () => {
    /** define with this call what will be visible in the MESSAGE_PAGE */
    setPageContentState({
      title: 'Upload completed!',
      subtitle: 'You can now find your uploaded documents among your files.',
      imageName: 'uploadCompleted',
      button: {
        icon: 'ChromeBackMirrored',
        text: 'Go to Home Page',
        onClick: {
          type: 'navigate',
          arguments: AppRoutes.HOME_ROUTE,
        },
      },
    });

    dispatch(NewDocumentAction.setDocument({} as INewDocument));

    /** goto the MESSAGE_PAGE. this must happen after calling setPageContentState */
    navigate(AppRoutes.MESSAGE_PAGE);
  };

  const fillSignerVoices = (steps: IDocumentSignStep[]) => {
    let wfSteps: IWorkflowSignStep[] = [];
    for (let i = 0; i < steps.length; i++) {
      let wfStep: IWorkflowSignStep = {
        order: steps[i].order,
        users: steps[i].users.map((x: {id: string; name: string}) => {
          return {
            name: x.name,
            userId: x.id,
            userSignStatus: 0,
            delegates: [],
            signType: SignType.FirmaNonQualificata,
          };
        }),
      };
      wfSteps.push(wfStep);
    }
    return wfSteps;
  };

  const errorOnSave = (error: string) => {
    window.document.dispatchEvent(
      new CustomEvent('api-toast-result', {
        detail: {
          text: 'There was an error while saving this document.',
          type: 'error',
        },
      })
    );
    console.error('Error on save document', error);
  };

  const concludeSaveFile = (startFlow: boolean, documentId: string) => {
    sendDocumentBusinessObjectHistory();

    if (startFlow && loggedUser) {
      // start a workflow iff there is not already a workflow
      ApiService.WorkflowController.getById(currentDocument.documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          if (response.payload === null) {
            ApiService.WorkflowController.insertWorkflowCreate(
              {
                workflowId: '',
                documentId: documentId,
                newVersionOf: '',
                documentName: currentDocument.documentName,
                mainDocumentName: currentDocument.mainFile.fileName,
                levelOfCompletion: 0,
                createdBy: {
                  name: loggedUser.firstName + ' ' + loggedUser.lastName,
                  userId: loggedUser.id,
                  userSignStatus: 0,
                  delegates: [],
                  signType: SignType.FirmaNonQualificata,
                },
                creationDate: new Date().toISOString(),
                status: 1,
                type: approvalNeeded,
                signers: fillSignerVoices(currentDocument.signers),
                signatureType: currentDocument.workflowSignatureType,
                documentWorkflowStatus: undefined,
              },
              (response: IAPIResponse) => {
                if (response.error == null) {
                  concludeFlowStep();
                }
              }
            );
          } else {
            concludeFlowStep();
          }
        }
      });
    } else {
      concludeFlowStep();
    }
  };

  const cleanDocumentDynamicMetas = (documentSave) => {
    let metas = documentSave.metadata;
    if (metas) {
      let cleanMetas = {};

      let keys = Object.keys(metas);
      for (let i = 0; i < keys.length; i++) {
        let item = metas[keys[i]];
        let value = null;

        if (item.type === MetadataType.Text) {
          value = {stringValue: item.stringValue};
        } else if (item.type === MetadataType.Date) {
          value = {dateValue: item.dateValue};
        } else if (item.type === MetadataType.Select) {
          value = {selectValue: item.selectValue};
        } else if (item.type === MetadataType.MultiSelect) {
          value = {multiselectValue: item.multiselectValue};
        } else if (item.type === MetadataType.AutoIncrement) {
          value = {numberValue: item.numberValue, stringValue: item.stringValue};
        } else if (item.type === MetadataType.TagPickerSingleSelect) {
          value = {selectValue: item.selectValue};
        }

        if (value !== null) {
          value['type'] = item.type;
          cleanMetas[keys[i]] = value;
        }
      }

      documentSave.metadata = cleanMetas;
    }

    return documentSave;
  };

  const getBonifiedDocument = (docInitial: INewDocument) => {
    let doc = {...docInitial};
    let library = doc.library;

    if (doc.hasOwnProperty('metadata') === false) {
      doc.metadata = {};
    }

    for (let i = 0; i < hierarchy.length; i++) {
      let lib: HierarchyLibrary = hierarchy[i];
      if (lib.name === library) {
        let dynamic = lib.metadata;
        // for each dynamic metadata
        // fill it with a 000 default value if is missing
        for (let j = 0; j < dynamic.length; j++) {
          let d = dynamic[j];
          // for each auto increment
          if (d.type === MetadataType.AutoIncrement) {
            // if is not set for some WEIRD reason
            if (doc.metadata.hasOwnProperty(d.name) === false) {
              doc.metadata[d.name] = {
                numberValue: 0,
                stringValue: '000',
                type: MetadataType.AutoIncrement,
              };
            }
          }
        }

        // stop on first valid lib
        break;
      }
    }
    return doc;
  };

  const saveAsDraft = () => {
    if (!validateDraftForm(currentDocument, selectedLibrary, approvalNeeded)) {
      return;
    }

    let saveDocument: INewDocument = getBonifiedDocument(currentDocument);
    saveDocument.documentStatus = 0;
    setSaving(true);
    saveDocument = cleanDocumentDynamicMetas(saveDocument);

    ApiService.DocumentController.saveDraftDocument(
      saveDocument,
      pageMode === 'edit',
      pageMode === 'newversion',
      (response: IAPIResponse) => {
        if (response.error === null) {
          concludeSaveFile(false, saveDocument.documentId);
        } else {
          errorOnSave(response.error);
        }
        setSaving(false);
      }
    );
  };

  const save = (finalStatus: number) => {
    if (!validateForm(currentDocument, selectedLibrary, approvalNeeded)) {
      return;
    }

    // date cannot be future
    let date = new Date(currentDocument.documentDate);
    if (date > new Date()) {
      (window as any)['highlight-errors'] = 1;
      AjaxService.openToastError('You cannot use a future date when saving as completed.');
      return;
    }

    // check required bos and master data are set
    const requiredBos = getRequiredBosList(libraries, currentDocument.documentSubTypeId);
    const requiredMasterData = getRequiredMasterDataList(libraries, currentDocument.documentSubTypeId);
    const bosKeys = Object.keys(currentDocument.bOs ?? {});
    const missingBos = requiredBos.filter(x => !bosKeys.includes(x))
    const missingMasterData = requiredMasterData.filter(x => !bosKeys.includes(x))
    if (missingBos.length > 0) {
      AjaxService.openToastError(
        'You must set all the mandatory Business Objects: ' + getBoNamesFromSources(libraries, missingBos).join(', ')
      );
      return;
    }
    if (masterDataFeature && missingMasterData.length > 0) {
      AjaxService.openToastError(
        'You must set all the mandatory Master Data: ' + getMasterDataNamesFromSources(libraries, missingMasterData).join(', ')
      );
      return;
    }

    // authors are required on some sources
    if (authorsShouldBeRequiredFlag(docSources, currentDocument.documentSource)) {
      let error: boolean = false;
      if (!currentDocument.authors) {
        error = true;
      }
      if (currentDocument.authors.length === 0) {
        error = true;
      }

      if (error) {
        (window as any)['highlight-errors'] = 1;
        AjaxService.openToastError('Authors are Required for the selected document source.');
        return;
      }
    }

    // we start a workflow iff:
    // - an approval is required by the user
    // - the document is not being saved as draft
    // - there is not already an active flow for this document (this is checked in the concludeSaveFile function)
    // - the document is not in approved status during editings
    let requiresFlow: boolean =
      approvalNeeded > 0 && finalStatus !== 0 && !(currentDocument.documentStatus === 1 && pageMode !== 'newversion');

    let saveDocument: INewDocument = getBonifiedDocument(currentDocument);
    let documentStatusWas = saveDocument.documentStatus;
    saveDocument.documentStatus = finalStatus;

    saveDocument = cleanDocumentDynamicMetas(saveDocument);

    //** is a new document: save normally*/
    setSaving(true);

    ApiService.DocumentController.saveDocument(saveDocument, pageMode === 'edit', pageMode === 'newversion', (response: IAPIResponse) => {
      if (response.error == null) {
        concludeSaveFile(requiresFlow, saveDocument.documentId);
      } else {
        errorOnSave(response.error);
      }
      setSaving(false);
    });
  };

  let introLabel = 'Upload a new document and set its metadata.';
  if (pageMode === 'newversion') {
    introLabel = 'Upload a new version of the document and set its metadata.';
  }
  if (pageMode === 'edit') {
    introLabel = 'Edit the document by setting its metadata.';
  }

  function getFileExtensionTooltip(): string | undefined{
    if(selectedLibrary.id){
      const docType = docTypes.filter(x => x.libraryId == selectedLibrary.id)[0]
      if(docType){
        const mainFileTypes = docType.mainFileExts.join(", ")
        const attachmentsTypes = docType.attachmentsExts.join(", ")
        return `Supported file types for the selected Professional Area.<br />Main file: ${mainFileTypes} <br />Attachments: ${attachmentsTypes}`
      }
    }
    return
  }

  if(!documentUploadEnabled){
    documentError(
      `The system is under maintenance`,
      'The upload functionality is interrupted by the system administrator. Please wait the end of maintenance. You can check the news for more details',
      'warning',
      ""
    );
  }

  return (
    <div className="general-page-container">
      <DriveDocumentManagementToolbar type={'navigation-voices'} />
      <div className="new-doc-inner-section">
        {saving && (
          <div className="new-doc-page-spinner-wrap-upload-main">
            <div className="new-doc-page-spinner-inner">
              <Spinner label="Uploading document..." />
            </div>
          </div>
        )}
        {!saving && (
          <div>
            {lockPage !== null && (
              <div className="new-doc-header">
                <div className="new-doc-title">{pageMode === 'edit' ? 'Edit Document' : 'New Document'}</div>
                <div className="new-doc-subtitle">{introLabel}</div>
                <Banner type={'error'} isLarge={true} message={lockPage} enabled={true} />
              </div>
            )}
            {lockPage === null && (
              <>
                <div className="new-doc-header">
                  <div className="new-doc-header-left">
                    <div className="new-doc-title">
                      {pageMode === 'edit' ? 'Edit Document' : 'New Document'}{' '}
                      <VideoTutorialIcon page={`new-document-${selectedLibrary?.name}`} />
                    </div>
                    <div className="new-doc-subtitle">{introLabel}</div>
                  </div>
                  <div className="new-doc-header-right">
                    {countdownMaxExpireTime && (
                      <CountdownBox
                        expiringDate={countdownMaxExpireTime}
                        onComplete={() => {
                          documentLockExpired(docIdParam);
                        }}
                      />
                    )}
                  </div>
                </div>

                {loading && (
                  <div className="new-doc-content-spinner">
                    <div className="new-doc-page-spinner-wrap-main">
                      <div className="new-doc-page-spinner-inner">
                        <Spinner label="Loading document..." />
                      </div>
                    </div>
                  </div>
                )}
                {!loading && (
                  <div className="new-doc-content flex-column-on-small-screen">
                    <div className="new-doc-left-section">
                      <section>
                        <div className="new-doc-section-title">
                          FILES
                          <div className="new-doc-file-list-button-info">
                            <InputInfoWidget overWrite={getFileExtensionTooltip()} keyString="file-valid-types" page="new-document" />
                          </div>
                        </div>
                        <div className="new-doc-summary-wrap">
                          <div className={'new-doc-summary-amount ' + (summary.mainFile > 0 ? 'new-doc-summary-amount-yellow' : '')}>
                            {summary.mainFile}
                          </div>
                          <div className="new-doc-summary-label">Main Document</div>
                          <div
                            className={'new-doc-summary-amount ' + (summary.attachments.length > 0 ? 'new-doc-summary-amount-yellow' : '')}>
                            {summary.attachments.length}
                          </div>
                          <div className="new-doc-summary-label">Attachments</div>
                        </div>
                        <div className="new-doc-summary-drag-drop">
                          <FluentUIDecorator
                            label="Teams File Picker"
                            inlineLabel={true}
                            info={getInputInfos('new-doc', 'new-doc-teams-file-picker-toggle')}
                            fluentComponent={FluentUIDecoratorTypes.Toggle({
                              defaultChecked: teamsFilePickerEnabled,
                              onChange: (e, currentValue) => setTeamsFilePickerEnabled(currentValue),
                            })}
                          />
                          {currentDocument?.mainFile?.duplicatedFile?.duplicatedMainFile && (
                            <Banner
                              message={
                                <>
                                  The main file is duplicated!. These are the duplicated files:{" "}
                                  {currentDocument?.mainFile?.duplicatedFile?.documents.map((d, i) => (
                                    <Fragment key={i}>
                                      {i !== 0 && ", "}
                                      <a href={`${AppRoutes.DOCUMENT_MANAGEMENT}?documentid=${d.documentId}`} target="_blank">
                                        {d.documentName}
                                      </a>
                                    </Fragment>
                                  ))}
                                </>
                              }
                              type="warning"
                              enabled={true}
                              isLarge={false}
                              notDismissable={true}
                              isMultiline={true}
                            />
                          )}
                          {currentDocument?.mainFile == null && (
                            <MainDocumentContainer
                              teamsFilePicker={teamsFilePickerEnabled}
                              uploadDisabled={pageMode === 'edit' && [0, 3].indexOf(currentDocument.documentStatus) === -1}
                            />
                          )}
                          {currentDocument?.mainFile != null && (
                            <div className="new-doc-summary-main-doc-container">
                              <FileUploadBoxPreview
                                fileId={currentDocument.mainFile.id}
                                fileName={currentDocument?.mainFile.fileName}
                                fileSize={currentDocument?.mainFile.size}
                                showLargeBox={true}
                                readOnly={pageMode === 'edit' && [0, 3].indexOf(currentDocument.documentStatus) === -1}
                                lastUpdated={currentDocument?.mainFile.uploadTime}
                                onExitClick={onMainFileDelete}
                              />
                            </div>
                          )}
                          <div style={{margin: '2em 0 1em 0'}}>Attachments</div>
                          <AttachmentsContainer teamsFilePicker={teamsFilePickerEnabled} />

                          {summary.attachments.map((x, i) => (
                            <div style={{margin: '1em'}} key={i}>
                              <FileUploadBoxPreview
                                fileId={x.id}
                                fileName={x.fileName ?? x.name}
                                fileSize={x.size}
                                lastUpdated={x.uploadTime}
                                onExitClick={(fileId) => onAttachmentDelete(fileId)}
                              />
                            </div>
                          ))}
                        </div>
                        {pageMode === 'newversion' && (
                          <div style={{padding: '1em'}}>
                            <div style={{margin: '2em 0 1em 0'}}>Old version files ({oldFiles.length})</div>
                            <div>
                              {oldFiles.map((x: any, i: number) => {
                                return (
                                  <div key={i} style={{marginTop: '1em'}}>
                                    <FileUploadBoxPreview
                                      fileId={x.id}
                                      fileName={x.fileName}
                                      fileSize={x.size}
                                      lastUpdated={x.uploadTime}
                                      readOnly={true}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </section>
                    </div>

                    <div className="new-doc-right-section">
                      <section style={{maxWidth: '75vw'}}>
                        <div className="new-doc-section-title">DETAILS</div>
                        <Routes>
                          <Route path={AppRoutes.DOCUMENT_CREATION_ROUTE_METADATA} element={<DocumentMetadata docTypes={docTypes} />} />
                          <Route path={AppRoutes.DOCUMENT_CREATION_ROUTE_BO} element={<DocumentBO />} />
                          <Route
                            path={AppRoutes.DOCUMENT_CREATION_ROUTE_SUMMARY}
                            element={<DocumentSummary onDraftSave={saveAsDraft} onCompleteSave={saveComplete} />}
                          />
                        </Routes>
                      </section>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const NewDocPage = () => {
  const [reloadAll, setReloadAll] = useState<boolean>(false);
  const [unlockReset, setUnlockReset] = useState<boolean>(false);

  const dispatch = useDispatch();
  const [params] = useSearchParams();
  let docIdParam = params.get('documentid');

  useEffect(() => {
    dispatch(GenericActions.setTightErrorBos(false));
    dispatch(GenericActions.setTightErrorCtr(false));

    setTimeout(() => {
      setUnlockReset(true);
    }, 300);
  }, []);

  useEffect(() => {
    if (!docIdParam && unlockReset) {
      (window as any)['highlight-errors'] = 0;
      setReloadAll(true);
      setTimeout(() => {
        try {
          setReloadAll(false);
        } catch (e) {}
      }, 500);
    }
  }, [docIdParam]);

  return (
    <div>
      {reloadAll && (
        <div className="new-doc-page-spinner-wrap-main-outer">
          <div className="new-doc-page-spinner-inner">
            <Spinner label="Loading..." />
          </div>
        </div>
      )}
      {!reloadAll && <NewDocPageInner />}
    </div>
  );
};

export default getTrackingComponent(NewDocPage, 'NewDocPage');
