import './MassiveImportTableContainer.scss';

interface MassiveImportTableContainerProps {
  header: JSX.Element;
  body: JSX.Element;
}

const MassiveImportTableContainer = (props: MassiveImportTableContainerProps) => {
  return (
    <div className="massive-import-right-section-content">
      <div className="massive-import-right-section-content-header">{props.header}</div>
      <div className="massive-import-right-section-content-body">{props.body}</div>
    </div>
  );
};

export default MassiveImportTableContainer;
