import {Link} from 'react-router-dom';

/**
 * If a document code is given DSD, then it's prepended to the JSX element
 */
export function getDocumentLabel(documentName: string, documentCode: string, navUrl: string, allowPadding: boolean = true): JSX.Element {
  return navUrl ? (
    <Link style={allowPadding ? {paddingLeft: '3.5em'} : {}} to={navUrl}>
      {documentCode && <span>{`${documentCode} - `}</span>}
      <span>{documentName}</span>
    </Link>
  ) : (
    <>
      {documentCode && <span>{`${documentCode} - `}</span>}
      <span>{documentName}</span>
    </>
  );
}

/**
 * If a document code is given DSD, then it's prepended to the string
 */
export function getDocumentLabelString(documentName: string, documentCode: string): string {
  if (documentCode) {
    return `${documentCode} - ${documentName}`;
  } else {
    return documentName;
  }
}
