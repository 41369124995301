import {useRef} from 'react';

import ButtonNoBorder from '../ButtonNoBorder/ButtonNoBorder';

export interface InputFileButtonProps {
  onClick: (selectedFiles: File[]) => void;
  buttonLabel: string;
  buttonDisable: boolean;
  multiFileSelect: boolean;
  accept?: string;
}

const InputFileButton = (props: InputFileButtonProps) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const inputOnchange = () => {
    const files = inputEl.current.files;
    props.onClick([...files]);
    inputEl.current.value = '';
  };

  const triggerInput = () => {
    inputEl.current.click();
  };

  return (
    <>
      <input
        type="file"
        ref={inputEl}
        multiple={props.multiFileSelect}
        onChange={inputOnchange}
        style={{display: 'none'}}
        accept={props.accept}
      />
      <ButtonNoBorder onClick={triggerInput} buttonLabel={props.buttonLabel} buttonIcon={'OpenFile'} buttonDisable={props.buttonDisable} />
    </>
  );
};

export default InputFileButton;
