import {CommandBarButton, IconButton, IContextualMenuProps} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FileInfo} from '../../Models/FileUtils';
import {IDocumentItem} from '../../Models/IDocument';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import './AttachmentSmallBrowserButton.scss';

export interface IAttachmentDesc {
  fileName: string;
  id: string;
  extension: string;
}

export interface IAttachmentSmallBrowserButtonProps {
  ownerDocument?: IDocumentItem;
  explicitAttachmentList?: FileInfo[];
  explicitDocumentId?: string;
}

const AttachmentSmallBrowserButton = (props: IAttachmentSmallBrowserButtonProps) => {
  const navigate = useNavigate();
  const [attachmentsCount, setAttachmentsCount] = useState<number>(0);
  const [document, setDocument] = useState<IDocumentItem | null>(null);
  const [buttons, setButtons] = useState<any | null>(null);

  const onVoiceClicked = (info: FileInfo, documentId: string) => {
    if (documentId && documentId !== '') {
      sessionStorage.setItem(
        'open-this-file-version-nav',
        JSON.stringify({
          file: info,
          type: 'attachment',
        })
      );
      navigate(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + documentId.toString());
    }
  };

  const tryLoadDocument = () => {
    if (!document && props.explicitDocumentId && !props.explicitAttachmentList) {
      ApiService.DocumentController.getDocument(props.explicitDocumentId, (response: IAPIResponse) => {
        if (response.error === null) {
          setDocument(response.payload);
        }
      });
    }
  };

  useEffect(() => {
    if (props.ownerDocument) {
      setDocument(props.ownerDocument);
    } else {
      tryLoadDocument();
    }
  }, []);

  useEffect(() => {
    if (document && document.attachments && document.attachments.length > 0) {
      setAttachmentsCount(document.attachments.length);
      setButtons({
        items: document.attachments.map((x: FileInfo, i: number) => {
          return {
            key: i,
            text: x.fileName,
            iconProps: {iconName: 'OpenInNewTab'},
            onClick: () => {
              onVoiceClicked(x, document.documentId);
            },
          };
        }),
      });
    }

    if (props.explicitDocumentId && props.explicitAttachmentList) {
      setAttachmentsCount(props.explicitAttachmentList.length);
      setButtons({
        items: props.explicitAttachmentList.map((x: FileInfo, i: number) => {
          return {
            key: i,
            text: x.fileName,
            iconProps: {iconName: 'OpenInNewTab'},
            onClick: () => {
              onVoiceClicked(x, props.explicitDocumentId);
            },
          };
        }),
      });
    }
  }, [props.explicitDocumentId, props.explicitAttachmentList, document]);

  return (
    <div className="attachments-small-browser-button-wrap">
      {attachmentsCount > 0 && (
        <div>
          <CommandBarButton
            text={attachmentsCount.toString()}
            menuProps={buttons}
            iconProps={{iconName: 'Attach'}}
            title="Document Attachments"
            ariaLabel="Document Attachments"
          />
        </div>
      )}
    </div>
  );
};

export default AttachmentSmallBrowserButton;
