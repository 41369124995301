export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  creationDate: string;
  status: number;
  isSuperAdmin: boolean;
  professionalAreas: string[];
  libraries: any[];
  securityUserGroups: any[];
  countries: {
    permissionModel: string;
    countries: {countryCode: string; countryDescription: string}[];
  }[];
  elementTypes: string[];
  massiveImportOption: IMassiveImportOption;
  UserLibraries?: string[];
  UserProfAreas?: string[];
}

export enum IMassiveImportOption {
  Undefined = 0,
  Subsurface = 1,
  DevelopmentProjectManagementAndServices = 2,
  All = 3,
}

export interface ICountryAdmin {
  code: string;
  name: string;
}

export interface ICountryVoice {
  code: string;
  name: string;
  library: string;
}

export interface IPermissionsByAction {
  canAccessAdminArea: boolean;
  canCreate: boolean;
  countries: ICountryVoice[];
  documentTypes: {id: string; name: string}[];
  elementTypes: string[];
  professionalAreas: {id: string; name: string; isPublic: boolean}[];
}

export interface ISendAuthorizationRequest {
  professionalAreasIds: string[];
  supervisorEmail: string;
  countries: string[];
  note?: string;
  documentTypesIds: string[];
  documentSubTypesIds: string[];
  permissionType: number;
  libraryId: string;
}

export enum DigitalSignatureType {
  Yes = 0,
  No = 1,
  ServiceUnavailable = 2,
}

export interface IPermissionRecap {
  userId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  digitalSignatureType: DigitalSignatureType;
  digitalSignatureData: string;
  libraries: IBaseLibraries[];
  lastLogin: string;
}

export interface BaseLibraryItem {
  id: string;
  name: string;
  isActive: boolean;
}

export interface BaseLibraryDocType extends BaseLibraryItem {
  subTypes: BaseLibraryItem[];
}

export interface BaseLibraryProfessionalArea extends BaseLibraryItem {
  documentTypes: BaseLibraryDocType[];
}

export interface IBaseLibraries {
  id: string;
  name: string;
  supportEmail: string;
  professionalAreas: BaseLibraryProfessionalArea[];
  elementTypes: ILibraryProperties[];
  countries: ILibraryProperties[];
  userProfileAssociatedOnIAG: boolean;
  iagLibraryCode: string
  selectableCountries: boolean;
  showAskPermissionForButton: boolean;
}

export interface ILibraryProperties {
  id: string;
  name: string;
}

export interface IPermissionByData {
  canRead: boolean;
  canReadExplanation: string;
  canEdit: boolean;
  canEditMetadata: boolean;
  canEditExplanation: string;
  canCreateNewVersion: boolean;
  canDelete: boolean;
  canDeleteExplanation: string;
  canRevert: boolean;
  documentId: string;
}

export interface IAccessToken {
  accessToken: string;
}
