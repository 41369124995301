import {Banner, FileList as FileListC, LabelButton, MenuTabs, ModalDialog} from '@Eni/docware-fe-master';
import './NewLibDocumentTypes.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {ILibraryDocumentType, ILibraryNewLib, LibraryFunctions} from '../../../Models/ILibrary';
import {SelectionMode} from '@fluentui/react';
import {getItemWithDefaultsValues, ModalMode} from '../NewLibPage';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import NavButtons from '../../../Components/NavButtons/NavButtons';

interface NewLibDocumentTypesProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
  selectedDocumentType: ILibraryDocumentType;
  setSelectedDocumentType: Dispatch<SetStateAction<ILibraryDocumentType>>;
  showEditModal: boolean;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  existingDocumentTypes: string[];
}

const NewLibDocumentTypes = (props: NewLibDocumentTypesProps) => {
  const [newDocumentType, setNewDocumentType] = useState<ILibraryDocumentType>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <LabelButton
          text="Add"
          icon="Add"
          onClick={() => {
            setShowCreateModal(true);
          }}
        />
        <FileListC
          defaultSortOnThisColumnIndex={0}
          fileIconFromField={''}
          onItemsSelected={(selectedRows: ILibraryDocumentType[]) => {
            if (selectedRows.length === 0) {
              props.setSelectedDocumentType(undefined);
            }
            if (selectedRows.length === 1) {
              props.setSelectedDocumentType(selectedRows[0]);
            }
          }}
          currentSelectedItems={undefined}
          currentSelectedItem={props.selectedDocumentType}
          hideBottomButton={true}
          selectionMode={SelectionMode.single}
          tableName={'normal'}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'name',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Is Active',
              dataType: 'string',
              fieldName: 'isAdmin',
              iconName: null,
              size: 'large',
              onRender: (item: ILibraryDocumentType) => {
                return item.isActive?.toString();
              },
            },
          ]}
          items={props.currentLibrary?.documentTypes ?? []}
        />
      </div>

      <NavButtons
        onBackClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES)}
        onNextClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES)}
      />

      <ModalDocumentType
        mode={ModalMode.Edit}
        documentType={props.selectedDocumentType}
        setDocumentType={props.setSelectedDocumentType}
        showModal={props.showEditModal}
        setShowModal={props.setShowEditModal}
        existingDocumentTypes={props.existingDocumentTypes}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const updatedDocumentTypes = props.currentLibrary?.documentTypes.map((x) =>
            x.id == props.selectedDocumentType.id ? props.selectedDocumentType : x
          );
          props.updateLibrary('documentTypes', updatedDocumentTypes);
        }}
      />

      <ModalDocumentType
        mode={ModalMode.Create}
        documentType={newDocumentType}
        setDocumentType={setNewDocumentType}
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        existingDocumentTypes={props.existingDocumentTypes}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const emptyDocumentType: ILibraryDocumentType = {
            id: crypto.randomUUID(),
            name: '',
            subTypes: [],
            isActive: false,
            addedInThisSession: true,
          };
          const documentType = getItemWithDefaultsValues(emptyDocumentType, newDocumentType);

          props.updateLibrary('documentTypes', [...(props.currentLibrary?.documentTypes ?? []), documentType]);
          setNewDocumentType(undefined);
        }}
      />
    </>
  );
};

interface ModalDocumentTypeProps {
  mode: ModalMode;
  documentType: ILibraryDocumentType;
  setDocumentType: Dispatch<SetStateAction<ILibraryDocumentType>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  existingDocumentTypes: string[];
  currentLibrary: ILibraryNewLib;
  onConfirm: Function;
}

const ModalDocumentType = (props: ModalDocumentTypeProps) => {
  const fieldDisabled = props.mode == ModalMode.Edit && !props.documentType?.addedInThisSession;
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  return (
    <ModalDialog
      modalTitle={`${props.mode == ModalMode.Create ? 'Create' : 'Edit'} Document Type`}
      modalInnerComponent={
        <>
          <FluentUIDecorator
            label="Name"
            info={getInputInfos('new-lib', 'document-type-name')}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              disabled: fieldDisabled,
              value: props.documentType?.name,
              onChange: (e, currentValue) => props.setDocumentType({...props.documentType, name: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Is Active"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'document-type-is-active')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              disabled: false,
              defaultChecked: props.documentType?.isActive,
              onChange: (e, currentValue) => props.setDocumentType({...props.documentType, isActive: currentValue}),
            })}
          />
          <Banner
            enabled={showErrorBanner}
            type="error"
            message={"The Document Type name must be the unique across all libraries and can't be blank"}
          />
        </>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            props.setShowModal(false);
          },
        },
        {
          label: 'Confirm',
          onClick: () => {
            if (props.mode === ModalMode.Create) {
              const documentTypeName = props.documentType?.name.replace(/^\s+|\s+$/gm, '');
              const currentDocumentTypes = LibraryFunctions.getDocumentTypesNames([props.currentLibrary]);
              if (
                !documentTypeName ||
                props.existingDocumentTypes.includes(documentTypeName) ||
                currentDocumentTypes.includes(documentTypeName)
              ) {
                setShowErrorBanner(true);
                return;
              }
            }
            props.onConfirm();
            props.setShowModal(false);
          },
        },
      ]}
      onAbort={() => {
        props.setShowModal(false);
      }}
      enableModal={props.showModal}
    />
  );
};

export default NewLibDocumentTypes;
