import './MassiveImportMetadataDetail.scss';
import {IMetadataFieldDetail, IMIMetadata, MIMetadata} from '../../../../Models/MassiveImport/IMIMetadata';
import {Banner} from '@Eni/docware-fe-master';
import Feature from '../../../../Components/FeatureFlag/Feature';
import { MASTER_DATA_FOR_SUBTYPE_FF_KEY } from '../../../../Constants/FeatureFlags';

interface MassiveImportMetadataDetailRowProps {
  fieldName: string;
  rowData: IMetadataFieldDetail;
}

const MassiveImportMetadataDetailRow = (props: MassiveImportMetadataDetailRowProps) => {
  return (
    <div className="massive-import-metadata-detail-row">
      <span className="massive-import-metadata-detail-row-header">{props.fieldName}</span>
      <span className="massive-import-metadata-detail-row-value">{props.rowData.value}</span>
      <div className="massive-import-metadata-detail-row-error-container">
        <Banner
          notDismissable
          enabled={props.rowData.errorMessages.length > 0}
          type="error"
          isMultiline
          message={props.rowData.errorMessages.join(' ')}
        />
        <Banner
          notDismissable
          enabled={props.rowData.warningMessages?.length > 0}
          type="warning"
          isMultiline
          message={props.rowData.warningMessages?.join(' ')}
        />
      </div>
    </div>
  );
};

interface MassiveImportFilesProps {
  metadata: IMIMetadata | null;
}

const MassiveImportMetadataDetail = (props: MassiveImportFilesProps) => {
  const metadata = props.metadata ?? new MIMetadata();
  const isDevelopmentLibrary = metadata.professionalArea.id === 'DPMS';

  return (
    <div className="massive-import-metadata-detail-container">
      <h1>{metadata.documentTitle.value}</h1>
      <hr className="massive-import-metadata-detail-divider" />
      <MassiveImportMetadataDetailRow fieldName="Document Title" rowData={metadata.documentTitle} />
      <MassiveImportMetadataDetailRow fieldName="Professional Area" rowData={metadata.professionalArea} />
      <MassiveImportMetadataDetailRow fieldName="Document Type" rowData={metadata.documentType} />
      <MassiveImportMetadataDetailRow fieldName="Document Sub Type" rowData={metadata.documentSubType} />
      <MassiveImportMetadataDetailRow fieldName="Country" rowData={metadata.country} />
      <MassiveImportMetadataDetailRow fieldName="Document Date" rowData={metadata.documentDate} />
      <MassiveImportMetadataDetailRow fieldName="Security Level" rowData={metadata.securityLevel} />
      <MassiveImportMetadataDetailRow fieldName="Source" rowData={metadata.source} />
      <MassiveImportMetadataDetailRow fieldName="Authors" rowData={metadata.authors} />
      <MassiveImportMetadataDetailRow fieldName="Whitelist" rowData={metadata.whitelist} />
      <MassiveImportMetadataDetailRow fieldName="Description" rowData={metadata.description} />
      <MassiveImportMetadataDetailRow fieldName="Remarks" rowData={metadata.remarks} />
      <MassiveImportMetadataDetailRow fieldName="Main File Name" rowData={metadata.fileName} />
      <MassiveImportMetadataDetailRow fieldName="Attachments" rowData={metadata.attachments} />
      <MassiveImportMetadataDetailRow fieldName="Unzip" rowData={metadata.unzip} />
      {isDevelopmentLibrary ? (
        <>
          <MassiveImportMetadataDetailRow fieldName="Metadata Project Code" rowData={metadata.metadata_projectCode} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Project Name" rowData={metadata.metadata_projectName} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Project Phase" rowData={metadata.metadata_projectPhase} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Reason For Issue" rowData={metadata.metadata_reasonForIssue} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Contract Name" rowData={metadata.metadata_contractName} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Contract Number" rowData={metadata.metadata_contractNumber} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Document Code" rowData={metadata.metadata_documentCode} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Contractor Name" rowData={metadata.metadata_contractorName} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Issue Date" rowData={metadata.metadata_issueDate} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Document ID (other ref.)" rowData={metadata.metadata_documentId} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Transmittal Node" rowData={metadata.metadata_transmittalNode} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Originator" rowData={metadata.metadata_originator} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Receiver" rowData={metadata.metadata_receiver} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Revision Number" rowData={metadata.metadata_revisionNumber} />
          <MassiveImportMetadataDetailRow fieldName="Metadata Validation" rowData={metadata.metadata_validation} />
        </>
      ) : (
        <>
          <MassiveImportMetadataDetailRow fieldName="Business Object Type 1" rowData={metadata.bo1_type} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Values 1" rowData={metadata.bo1_value} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Type 2" rowData={metadata.bo2_type} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Values 2" rowData={metadata.bo2_value} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Type 3" rowData={metadata.bo3_type} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Values 3" rowData={metadata.bo3_value} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Values 3" rowData={metadata.bo3_value} />
          <MassiveImportMetadataDetailRow fieldName="Business Object Values 3" rowData={metadata.bo3_value} />
          <Feature featureKey={MASTER_DATA_FOR_SUBTYPE_FF_KEY}>
            <MassiveImportMetadataDetailRow fieldName="Seismic Acquisition" rowData={metadata.seismicAcquisitions} />
            <MassiveImportMetadataDetailRow fieldName="Seismic Survey" rowData={metadata.seismicSurveys} />
          </Feature>

          <MassiveImportMetadataDetailRow fieldName="BO Validation" rowData={metadata.bo_validation} />
        </>
      )}

      <MassiveImportMetadataDetailRow fieldName="Sheet row" rowData={metadata.sheetRow} />
    </div>
  );
};

export default MassiveImportMetadataDetail;
