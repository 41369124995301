import {useEffect, useState} from 'react';
import Icons from '../../Utils/FabricIconsOutlet';
import './Tab.scss';

export interface TabbedListTab {
  label: string;
  iconName: string;
  color: string;
  key: number;
  matchDataOnField: string;
}

export interface TabbedListProps {
  tabs: TabbedListTab[];
  onTabChange: (activeTab: TabbedListTab) => any;
}

const Tab = (props: TabbedListProps) => {
  const [activeTab, setActiveTab] = useState<TabbedListTab>(props.tabs[0]);

  useEffect(() => {
    props.onTabChange(props.tabs[0]);
  }, []);

  return (
    <div className="tabbed-list-main-wrap">
      {activeTab && (
        <div>
          <div className="tabbed-list-tabs-wrap">
            {props.tabs.map((x: TabbedListTab, i: number) => {
              return (
                <div
                  key={i}
                  style={activeTab.key === x.key ? {color: x.color, borderColor: x.color} : {color: x.color}}
                  className={'tabbed-list-tabs-tab'}
                  onClick={() => {
                    setActiveTab(x);
                    props.onTabChange(x);
                  }}>
                  {Icons.getIcon(x.iconName)}
                  <div style={{width: '1em'}} />
                  {x.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Tab;
