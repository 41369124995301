import {ILibrary} from '../../Models/ILibrary';
import { ICountryAdmin } from '../../Models/IUser';
import {NewItem} from '../../Models/New';

export const SET_SELECTED_LIBRARIES = 'SET_SELECTED_LIBRARIES';

export const SET_SELECTED_NEWS = 'SET_SELECTED_NEWS';
export const SET_SHOW_NEWS_ADD_MODAL = 'SET_SHOW_NEWS_ADD_MODAL';
export const SET_SHOW_NEWS_EDIT_MODAL = 'SET_SHOW_NEWS_EDIT_MODAL';
export const SET_SHOW_NEWS_DELETE_MODAL = 'SET_SHOW_NEWS_DELETE_MODAL';
export const SET_ADD_NEW_ITEM = 'SET_ADD_NEW_ITEM';
export const SET_EDIT_NEW_ITEM = 'SET_EDIT_NEW_ITEM';
export const SET_UNSELECET_ALL_NEWS = 'SET_UNSELECET_ALL_NEWS';
export const SET_ADMIN_COUNTRIES = 'SET_ADMIN_COUNTRIES';

interface SetSelectedLibrariesActionType {
  type: typeof SET_SELECTED_LIBRARIES;
  payload: ILibrary;
}

interface SetSelectedNewsActionType {
  type: typeof SET_SELECTED_NEWS;
  payload: NewItem[];
}

interface SetShowAddModalActionType {
  type: typeof SET_SHOW_NEWS_ADD_MODAL;
  payload: boolean;
}

interface SetShowEditModalActionType {
  type: typeof SET_SHOW_NEWS_EDIT_MODAL;
  payload: boolean;
}

interface SetShowDeleteModalActionType {
  type: typeof SET_SHOW_NEWS_DELETE_MODAL;
  payload: boolean;
}

interface SetAddNewItemActionType {
  type: typeof SET_ADD_NEW_ITEM;
  payload: NewItem;
}

interface SetEditNewItemActionType {
  type: typeof SET_EDIT_NEW_ITEM;
  payload: NewItem;
}

interface SetUnselectAllNewsActionType {
  type: typeof SET_UNSELECET_ALL_NEWS;
  payload: boolean;
}

interface SetAdminCountriesActionType {
  type: typeof SET_ADMIN_COUNTRIES;
  payload: ICountryAdmin[];
}

export type AdminActionTypes =
  | SetSelectedLibrariesActionType
  | SetSelectedNewsActionType
  | SetShowAddModalActionType
  | SetShowEditModalActionType
  | SetShowDeleteModalActionType
  | SetAddNewItemActionType
  | SetEditNewItemActionType
  | SetUnselectAllNewsActionType
  | SetAdminCountriesActionType

const setSelectedLibraries = (library: ILibrary): SetSelectedLibrariesActionType => {
  return {type: SET_SELECTED_LIBRARIES, payload: library};
};

const setSelectedNews = (news: NewItem[]): SetSelectedNewsActionType => {
  return {type: SET_SELECTED_NEWS, payload: news};
};

const setShowAddModal = (show: boolean): SetShowAddModalActionType => {
  return {type: SET_SHOW_NEWS_ADD_MODAL, payload: show};
};

const setShowEditModal = (show: boolean): SetShowEditModalActionType => {
  return {type: SET_SHOW_NEWS_EDIT_MODAL, payload: show};
};

const setShowDeleteModal = (show: boolean): SetShowDeleteModalActionType => {
  return {type: SET_SHOW_NEWS_DELETE_MODAL, payload: show};
};

const setAddNewItem = (newItem: NewItem): SetAddNewItemActionType => {
  return {type: SET_ADD_NEW_ITEM, payload: newItem};
};

const setEditNewItem = (newItem: NewItem): SetEditNewItemActionType => {
  return {type: SET_EDIT_NEW_ITEM, payload: newItem};
};

const SetUnselectAllNews = (unselect: boolean): SetUnselectAllNewsActionType => {
  return {type: SET_UNSELECET_ALL_NEWS, payload: unselect};
};

const SetUnselectAdminCountries = (countries: ICountryAdmin[]): SetAdminCountriesActionType => {
  return {type: SET_ADMIN_COUNTRIES, payload: countries};
};

export const AdminAction = {
  setSelectedLibraries,
  setSelectedNews,
  setShowAddModal,
  setShowEditModal,
  setShowDeleteModal,
  setAddNewItem,
  setEditNewItem,
  SetUnselectAllNews,
  SetUnselectAdminCountries,
};
