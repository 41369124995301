import AppRoutes, {VIDEO_TUTORIAL_VIDEO} from './Utils/AppRoutes';
import DrivePage from './Pages/DrivePage/DrivePage';
import ActivitiesPage from './Pages/ActivitiesPage/ActivitiesPage';
import NewDocPage from './Pages/NewDocPage/NewDocPage';
import StarredPage from './Pages/StarredPage/StarredPage';
import TrashPage from './Pages/TrashPage/TrashPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import FullPageMessage from './Pages/FullPageMessage/FullPageMessage';
import DocumentManagementPage from './Pages/DocumentManagementPage/DocumentManagementPage';
import DocumentSearchPage from './Pages/DocumentSearchPage/DocumentSearchPage';
import AdminPage from './Pages/AdminPage/AdminPage';
import DocumentSignaturePage from './Pages/DocumentSignaturePage/DocumentSignaturePage';
import MyDocumentsPage from './Pages/MyDocumentsPage/MyDocumentsPage';
import UserInfoPage from './Pages/UserInfoPage/UserInfoPage';
import UserRequestPage from './Pages/UserRequestPage/UserRequestPage';
import MassiveImportPage from './Pages/MassiveImportPage/MassiveImportPage';
import VideosTutorialListPage from './Pages/VideosTutorialListPage/VideosTutorialListPage';
import VideoTutorialPage from './Pages/VideoTutorialPage/VideoTutorialPage';
import NewLibPage from './Pages/NewLibPage/NewLibPage';
import DownloadBlobPage from './Pages/DownloadBlobPage/DownloadBlobPage';

export interface IAppRoute {
  path: string;
  element: JSX.Element;
  requiresEnabled: boolean;
}

const AppRoutesMap: IAppRoute[] = [
  {
    path: AppRoutes.HOME_ROUTE,
    element: <DrivePage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.DOCUMENT_CREATION_ROUTE_WILDCARD,
    element: <NewDocPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.DOCUMENT_MANAGEMENT,
    element: <DocumentManagementPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.DOCUMENT_MANAGEMENT_SIGNATURE,
    element: <DocumentSignaturePage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.ACTIVITIES_ROUTE,
    element: <ActivitiesPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.STARRED_ROUTE,
    element: <StarredPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.TRASH_ROUTE,
    element: <TrashPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.MY_DOCUMENTS_ROUTE,
    element: <MyDocumentsPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.USER_INFO,
    element: <UserInfoPage />,
    requiresEnabled: false,
  },
  {
    path: AppRoutes.USER_REQUEST,
    element: <UserRequestPage />,
    requiresEnabled: false,
  },
  {
    path: AppRoutes.DOCUMENT_SEARCH_WILDCARD,
    element: <DocumentSearchPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.MESSAGE_PAGE,
    element: <FullPageMessage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.ADMIN_ROUTE_WILDCARD,
    element: <AdminPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.MASSIVE_IMPORT_ROUTE_WILDCARD,
    element: <MassiveImportPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.VIDEOS_TUTORIAL_LIST,
    element: <VideosTutorialListPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.VIDEO_TUTORIAL_VIDEO_NAME,
    element: <VideoTutorialPage />,
    requiresEnabled: false,
  },
  {
    path: AppRoutes.NEW_LIB_PAGE_WILDCARD,
    element: <NewLibPage />,
    requiresEnabled: true,
  },
  {
    path: AppRoutes.DOWNLOAD_BLOB,
    element: <DownloadBlobPage />,
    requiresEnabled: true,
  },
  {
    path: '*',
    element: <NotFoundPage />,
    requiresEnabled: true,
  },
];

export function currentLocationIsAvailableWithoutAuth(location: string): boolean {
  const routesWithoutAuth = AppRoutesMap.filter(x => !x.requiresEnabled).map(x => x.path);
  for (const route of routesWithoutAuth) {
    if (route === AppRoutes.VIDEO_TUTORIAL_VIDEO_NAME) {
      if (location.startsWith(VIDEO_TUTORIAL_VIDEO)) {
        return true;
      }
    }
    if (location.startsWith(route)) {
      return true;
    }
  }
  return false;

}

export default AppRoutesMap;
