import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {Dropdown, IDropdownOption, Spinner, TextField, Toggle} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IDocumentTypes, DocumentTypes, DocumentTypeLibraryAll} from '../../../Models/DocumentTypes';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import GenericList from '../../../Components/GenericList/GenericList';
import {useSelector} from 'react-redux';
import {GlobalState} from '../../../Reducers/RootReducer';
import {HierarchyLibrary} from '../../../Models/ILibrary';
import './AdminDocumentTypes.scss';

const AdminDocumentTypes = () => {
  const hierarchy = useSelector<GlobalState, HierarchyLibrary[]>((state) => state.generic.libraryHierachyModel);
  const [loading, setLoading] = useState<boolean>(true);
  const [editItem, setEditItem] = useState<IDocumentTypes | null>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<boolean>(true);
  const [docTypes, setDocTypes] = useState<IDocumentTypes[]>([]);

  const LoadItems = () => {
    ApiService.DocumentTypesController.getDocumentTypes((response: IAPIResponse) => {
      if (response.error === null) {
        setDocTypes(response.payload);
      }
      setLoading(false);
    });

  };

  useEffect(() => {
    LoadItems();
  }, []);

  const buttons = [];

  if (newItem) {
    buttons.push({
      onClick: () => {
        setShowEdit(false);
        ApiService.DocumentTypesController.setDocumentTypes(editItem, (response: IAPIResponse) => {
          if (response.error == null) {
            LoadItems();
          }
        });
      },
      label: 'Save Item',
    });
  } else {
    buttons.push({
      onClick: () => {
        setShowEdit(false);
        ApiService.DocumentTypesController.patchDocumentTypes(editItem, (response: IAPIResponse) => {
          if (response.error == null) {
            LoadItems();
          }
        });
      },
      label: 'Update Item',
    });
  }

  buttons.push({
    onClick: () => {
      setShowEdit(false);
    },
    label: 'Cancel',
  });

  //** add delete button for existing records */
  if (editItem) {
    if (editItem.id !== '') {
      buttons.push({
        onClick: () => {
          setShowEdit(false);
          ApiService.DocumentTypesController.deleteDocumentTypes(editItem, (response: IAPIResponse) => {
            if (response.error == null) {
              LoadItems();
            }
          });
        },
        label: 'Delete Item',
      });
    }
  }

  const librariesOption: IDropdownOption[] = [
    {key: DocumentTypeLibraryAll, text: "All libraries"}
  ].concat(hierarchy.map(h => {return {key: h.id, text: h.name}}))

  return (
    <div>
      <div className="admin-document-types-page-title">Uploadable Document Formats</div>
      <ModalDialog
        modalTitle={newItem ? 'Add Document Format' : 'Edit Document Format'}
        modalInnerComponent={
          <div>
            {editItem && (
              <div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    label={'Extension (Ext)'}
                    value={editItem.ext}
                    onChange={(e: any) => {
                      let it = {...editItem};
                      it['ext'] = e.target.value;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div style={{marginTop: '1em'}}>
                  <TextField
                    label={'Preview Type'}
                    value={editItem.previewType}
                    onChange={(e: any) => {
                      let it = {...editItem};
                      it['previewType'] = e.target.value;
                      setEditItem(it);
                    }}
                  />
                </div>
                <div style={{marginTop: '1em'}}></div>
                <TextField
                  label={'Content Types (Comma Separated)'}
                  value={editItem.contentTypes.join(',')}
                  onChange={(e: any) => {
                    let it = {...editItem};
                    it['contentTypes'] = e.target.value.split(',');
                    setEditItem(it);
                  }}
                />
                <div style={{marginTop: '1em'}}></div>
                <Toggle
                  label={'Compressed'}
                  checked={editItem.compressed}
                  inlineLabel
                  onChange={(e: any, b: any) => {
                    let it = {...editItem};
                    it['compressed'] = b;
                    setEditItem(it);
                  }}
                />
                <Dropdown
                  placeholder="Select a library"
                  label="Main File Available On"
                  selectedKeys={editItem.mainFileAvailableOn}
                  onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                    if (item) {
                      const it = {...editItem};
                      /*
                      if(item.key == DocumentTypeLibraryAll){
                        if(item.selected){
                          it.mainFileAvailableOn = librariesOption.map(x => x.key.toString());
                        } else {
                          it.mainFileAvailableOn = [];
                        }
                      } else {
                        it.mainFileAvailableOn = item.selected ? [...editItem.mainFileAvailableOn, item.key as string] : editItem.mainFileAvailableOn.filter(key => key !== item.key);
                        it.mainFileAvailableOn = it.mainFileAvailableOn.filter(x => x != DocumentTypeLibraryAll);
                      }*/
                      it.mainFileAvailableOn = item.selected? [...editItem.mainFileAvailableOn, item.key as string] : editItem.mainFileAvailableOn.filter((key) => key !== item.key);
                      setEditItem(it);
                    }
                  }}
                  multiSelect
                  options={librariesOption}
                />
                <Dropdown
                  placeholder="Select a library"
                  label="Attachments Available On"
                  selectedKeys={editItem.attachmentsAvailableOn}
                  onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
                    if (item) {
                      const it = {...editItem};
                      it.attachmentsAvailableOn = item.selected? [...editItem.attachmentsAvailableOn, item.key as string] : editItem.attachmentsAvailableOn.filter((key) => key !== item.key);
                      setEditItem(it);
                    }
                  }}
                  multiSelect
                  options={librariesOption}
                />
              </div>
            )}
          </div>
        }
        modalButtons={buttons.reverse()}
        onAbort={() => {
          setShowEdit(false);
        }}
        enableModal={showEdit}
      />
      {loading && (
        <div className="admin-document-types-page-spinner-wrap-main">
          <div className="admin-document-types-page-spinner-inner">
            <Spinner label="Loading..." />
          </div>
        </div>
      )}
      {!loading && (
        <GenericList
          extraHeaderItems={[
            <LabelButton
              text="Add new"
              icon="Add"
              onClick={() => {
                setNewItem(true);
                setEditItem(new DocumentTypes());
                setShowEdit(true);
              }}
            />,
          ]}
          onItemInvoked={(item: IDocumentTypes) => {
            // setEditItem({...item}) is not working for some reason
            setNewItem(false);
            setEditItem(JSON.parse(JSON.stringify(item)));
            setShowEdit(true);
          }}
          allowFilter={true}
          searchText={'Search'}
          columns={[
            {
              name: 'Content Types',
              dataType: 'string',
              fieldName: '',
              iconName: null,
              size: 'wide',
              onRender: (item: IDocumentTypes) => {
                return <div>{item.contentTypes.join(', ')}</div>;
              },
            },
            {
              name: 'Extension',
              dataType: 'string',
              fieldName: 'ext',
              iconName: null,
              size: 'medium',
            },
            {
              name: 'Preview Type',
              dataType: 'string',
              fieldName: 'previewType',
              iconName: null,
              size: 'large',
            },
            {
              name: 'Compressed',
              dataType: 'string',
              fieldName: '',
              iconName: null,
              size: 'medium',
              onRender: (item: IDocumentTypes) => {
                return <div>{item.compressed ? 'True' : 'False'}</div>;
              },
            },
            {
              name: 'Main File Available On',
              dataType: 'string',
              fieldName: '',
              iconName: null,
              size: 'medium',
              onRender: (item: IDocumentTypes) => {
                return (
                  <>
                    {librariesOption
                      .filter((l) => item.mainFileAvailableOn.includes(l.key.toString()))
                      .map((l) => l.text)
                      .join(', ')}
                  </>
                );
              },
            },
            {
              name: 'Attachments Available On',
              dataType: 'string',
              fieldName: '',
              iconName: null,
              size: 'medium',
              onRender: (item: IDocumentTypes) => {
                return (
                  <>
                    {librariesOption
                      .filter((l) => item.attachmentsAvailableOn.includes(l.key.toString()))
                      .map((l) => l.text)
                      .join(', ')}
                  </>
                );
              },
            },
          ]}
          items={docTypes}
        />
      )}
    </div>
  );
};

export default AdminDocumentTypes;
