import {useEffect, useState} from 'react';
import AdvancedSearchInputUploadSelector from '../AdvancedSearchInputUploadSelector/AdvancedSearchInputUploadSelector';
import {getUploadIconFromExt} from '../FileUploadBoxPreview/FileUploadBoxPreview';
import Icons from '../../Utils/FabricIconsOutlet';
import './DocumentPickerInput.scss';
import {IDocumentQuery} from '../../Models/IDocument';
import {ILibrary} from '../../Models/ILibrary';

export interface DocumentPickerEntry {
  documentId: string;
  documentName: string;
  extension: string;
  readonly?: boolean;
}

export interface DocumentPickerInputProps {
  activeLibrary: ILibrary;
  selectedItems: DocumentPickerEntry[];
  onChange: (items: DocumentPickerEntry[]) => void;
  modalTitle: string;
  modalLabel: string;
}

export const DocumentPickerEntryVoice = (props: {entry: DocumentPickerEntry; onClick: () => void}) => {
  let x = props.entry;
  return (
    <div className="document-picker-fake-fluent-document-entry">
      <div className="document-picker-fake-fluent-document-entry-inner">
        <div className="document-picker-fake-fluent-document-entry-icon">{getUploadIconFromExt(x.extension.replace('.', ''))}</div>
        <div className="document-picker-fake-fluent-document-entry-name">{x.documentName}</div>
        {x.readonly !== true && (
          <div className="document-picker-fake-cancel-button-wrap" onClick={props.onClick}>
            <div className="document-picker-fake-cancel-button-inner">{Icons.getIcon('Cancel')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

var STALLDIALOG = false;

const DocumentPickerInput = (props: DocumentPickerInputProps) => {
  const [showDocumentsModal, setDocumentsShowModal] = useState<boolean>(false);
  const [documents, setDocuments] = useState<DocumentPickerEntry[]>([]);

  const lockDialogStall = () => {
    STALLDIALOG = true;
    setTimeout(() => {
      try {
        STALLDIALOG = false;
      } catch (e) {}
    }, 400);
  };

  const openDialog = () => {
    setTimeout(() => {
      try {
        if (!STALLDIALOG) {
          setDocumentsShowModal(true);
        }
      } catch (e) {}
    }, 200);
  };

  useEffect(() => {
    setDocuments(props.selectedItems ?? []);
  }, [props.selectedItems]);

  const getExtension = (fileName: string) => {
    if (!fileName) {
      return '';
    }

    let splName = fileName.split('.');
    return splName[splName.length - 1];
  };

  return (
    <div>
      <div
        className="document-picker-fake-fluent-container"
        tabIndex={0}
        onClick={() => {
          openDialog();
        }}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            openDialog();
          }
        }}>
        {documents.map((x: DocumentPickerEntry, i: number) => {
          return (
            <DocumentPickerEntryVoice
              key={i}
              entry={x}
              onClick={() => {
                lockDialogStall();
                let list = [];
                for (let i = 0; i < documents.length; i++) {
                  if (documents[i].documentId !== x.documentId) {
                    list.push({...documents[i]});
                  }
                }
                setDocuments(list);
                props.onChange(list);
              }}
            />
          );
        })}
      </div>
      <AdvancedSearchInputUploadSelector
        activeLibrary={props.activeLibrary}
        enabled={showDocumentsModal}
        emit={(result: IDocumentQuery[] | null) => {
          setDocumentsShowModal(false);
          if (result !== null) {
            let docs = [...documents];
            for (let i = 0; i < result.length; i++) {
              if (docs.filter((x: DocumentPickerEntry) => x.documentId == result[i].document.documentId).length === 0) {
                docs.push({
                  documentId: result[i].document.documentId,
                  documentName: result[i].document.documentName,
                  extension: getExtension(result[i].document.fileName),
                });
              }
            }
            setDocuments(docs);
            props.onChange(docs);
          }
        }}
        modalTitle={props.modalTitle}
        modalLabel={props.modalLabel}
      />
    </div>
  );
};

export default DocumentPickerInput;
