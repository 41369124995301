export const DictUtils = {
  getKeys: (dict: any) => {
    let keys: any[] = [];
    if (dict == null) return keys;
    for (let key in dict) keys.push(key);
    return keys;
  },
};

export const bonifyEmailField = (email: string) => {
  if (email && email !== '') {
    return email;
  }
  return 'No email available';
};

/*
* https://webtips.dev/webtips/javascript/update-nested-property-by-string
* Usage:
* const obj = {
    property: {
        updated: false
    }
}
* const updatedObj = setProperty(obj, 'property.updated', true)
* // The above will return:
* {
    property: {
        updated: true
    }
  }
* */
export const setNestedPropertyByString = (obj, path, value) => {
  const [head, ...rest] = path.split('.');

  return {
    ...obj,
    [head]: rest.length ? setNestedPropertyByString(obj[head], rest.join('.'), value) : value,
  };
};

/*
* const obj = {
  selector: { to: { val: 'val to select' } },
  target: [1, 2, { a: 'test' }],
};
getNestedByString(obj, 'selector.to.val');
// 'val to select'
* */

export const getNestedPropertyByString = (obj, path) => {
  return path
    .replace(/\[([^\[\]]*)\]/g, '.$1.')
    .split('.')
    .filter((t) => t !== '')
    .reduce((prev, cur) => prev && prev[cur], obj);
};

/*
* Usage:
* Input:
*   arr = [
	{
		"id": "CS005",
		"name": "KASHAGAN"
	},
	{
		"id": "CS005",
		"name": "ALLIANCE PORTER KS"
	}
]
*   key = "id"
*
*  Output:
* [
	{
		"id": "CS005",
		"name": "KASHAGAN"
	}
]
*  */

export const removeDuplicatesFromArrayOfObject = (arr: any[], key: string): any[] => {
  return arr.filter((value, index, self) => index === self.findIndex((t) => t[key] === value[key]));
};
