import {MenuTabs} from '@Eni/docware-fe-master';
import './NewLibGeneralInfo.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import React from 'react';
import {
  FluentUIDecorator,
  FluentUIDecoratorTypes,
  getListOfSelectedKeysMultiSelect,
} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {ILibraryNewLib, LibraryFunctions} from '../../../Models/ILibrary';
import NavButtons from '../../../Components/NavButtons/NavButtons';

interface NewLibGeneralInfoProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
  securityLevels: string[];
  availableSignatures: string[];
}

const NewLibGeneralInfo = (props: NewLibGeneralInfoProps) => {
  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <FluentUIDecorator
          label="Library Name"
          info={getInputInfos('new-lib', 'general-info-library-name')}
          required={true}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.TextField({
            maxLength: 255,
            value: props.currentLibrary?.name,
            onChange: (e, currentValue) => props.updateLibrary('name', currentValue),
          })}
        />
        <FluentUIDecorator
          label="Is Active"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-is-active')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.active,
            onChange: (e, currentValue) => props.updateLibrary('active', currentValue),
          })}
        />
        <FluentUIDecorator
          label="Document Type / Document Type + Sub Type"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-skip-doc-type-selection-if-only-one')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.skipDocTypeSelectionIfOnlyOne,
            onChange: (e, currentValue) => props.updateLibrary('skipDocTypeSelectionIfOnlyOne', currentValue),
          })}
        />
        <FluentUIDecorator
          denyComponentHardReset={true}
          label={'Allowed Types In Workflow'}
          info={getInputInfos('new-lib', 'general-info-allowed-types-in-workflow')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: '',
            multiSelect: true,
            options: LibraryFunctions.getElementTypesNames([props.currentLibrary]).map((x) => {
              return {key: x, text: x};
            }),
            selectedKeys: props.currentLibrary?.allowedTypesInWorkflow,
            onChange: (e, currentValue) => {
              const selectedKeys = props.currentLibrary?.allowedTypesInWorkflow ?? [];
              const newValue = getListOfSelectedKeysMultiSelect(selectedKeys, currentValue);
              props.updateLibrary('allowedTypesInWorkflow', newValue);
            },
          })}
        />
        <FluentUIDecorator
          denyComponentHardReset={true}
          label={'Default Restricted Whitelist Types'}
          info={getInputInfos('new-lib', 'general-info-default-restricted-whitelist-types')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: '',
            multiSelect: true,
            options: LibraryFunctions.getElementTypesNames([props.currentLibrary]).map((x) => {
              return {key: x, text: x};
            }),
            selectedKeys: props.currentLibrary?.defaultRestrictedWhitelistTypes,
            onChange: (e, currentValue) => {
              const selectedKeys = props.currentLibrary?.defaultRestrictedWhitelistTypes ?? [];
              const newValue = getListOfSelectedKeysMultiSelect(selectedKeys, currentValue);
              props.updateLibrary('defaultRestrictedWhitelistTypes', newValue);
            },
          })}
        />
        <FluentUIDecorator
          label="Allow Whitelist Outside Library"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-allow-whitelist-outside-library')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.allowWhitelistOutsideLibrary,
            onChange: (e, currentValue) => props.updateLibrary('allowWhitelistOutsideLibrary', currentValue),
          })}
        />
        <FluentUIDecorator
          label="Allow Authors Outside Library"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-allow-authors-outside-library')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.allowAuthorsOutsideLibrary,
            onChange: (e, currentValue) => props.updateLibrary('allowAuthorsOutsideLibrary', currentValue),
          })}
        />
        <FluentUIDecorator
          label="Allow Versioning"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-allow-versioning')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.allowVersioning,
            onChange: (e, currentValue) => props.updateLibrary('allowVersioning', currentValue),
          })}
        />
        <FluentUIDecorator
          label="Allow Superseded"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-allow-superseded')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.allowSuperseded,
            onChange: (e, currentValue) => props.updateLibrary('allowSuperseded', currentValue),
          })}
        />
        <FluentUIDecorator
          label="Allow Related To"
          inlineLabel={true}
          info={getInputInfos('new-lib', 'general-info-allow-related-to')}
          errorMessage={(window as any)['highlight-errors'] === 1 ? 'This field is required' : undefined}
          fluentComponent={FluentUIDecoratorTypes.Toggle({
            defaultChecked: props.currentLibrary?.allowRelatedTo,
            onChange: (e, currentValue) => props.updateLibrary('allowRelatedTo', currentValue),
          })}
        />
        <FluentUIDecorator
          denyComponentHardReset={true}
          label={'Security Levels'}
          info={getInputInfos('new-lib', 'general-info-security-levels')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: '',
            multiSelect: true,
            options: props.securityLevels.map((x) => {
              return {key: x, text: x};
            }),
            selectedKeys: props.currentLibrary?.securityLevels,
            onChange: (e, currentValue) => {
              const selectedKeys = props.currentLibrary?.securityLevels ?? [];
              const newValue = getListOfSelectedKeysMultiSelect(selectedKeys, currentValue);
              props.updateLibrary('securityLevels', newValue);
            },
          })}
        />
        <FluentUIDecorator
          label={'Default Security Level'}
          info={getInputInfos('new-lib', 'general-info-default-security-level')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: 'Select Default Security Level',
            options: props.securityLevels.map((x) => {
              return {key: x, text: x};
            }),
            selectedKey: props.currentLibrary?.defaultSecurityLevel,
            onChange: (e, currentValue) => {
              props.updateLibrary('defaultSecurityLevel', currentValue.key);
            },
          })}
        />
        <FluentUIDecorator
          denyComponentHardReset={true}
          label={'Available Signatures'}
          info={getInputInfos('new-lib', 'available-signatures')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: '',
            multiSelect: true,
            options: props.availableSignatures.map((x) => {
              return {key: x, text: x};
            }),
            selectedKeys: props.currentLibrary?.availableSignatures,
            onChange: (e, currentValue) => {
              const selectedKeys = props.currentLibrary?.availableSignatures ?? [];
              const newValue = getListOfSelectedKeysMultiSelect(selectedKeys, currentValue);
              props.updateLibrary('availableSignatures', newValue);
            },
          })}
        />
        <FluentUIDecorator
          label={'Default Signature'}
          info={getInputInfos('new-lib', 'default-signature')}
          fluentComponent={FluentUIDecoratorTypes.Dropdown({
            placeholder: 'Select Default Signature',
            options: props.availableSignatures.map((x) => {
              return {key: x, text: x};
            }),
            selectedKey: props.currentLibrary?.defaultSignature,
            onChange: (e, currentValue) => {
              props.updateLibrary('defaultSignature', currentValue.key);
            },
          })}
        />
      </div>
      <NavButtons onNextClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS)} />
    </>
  );
};

export default NewLibGeneralInfo;
