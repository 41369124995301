import {IMIStagingArea} from '../../Models/MassiveImport/IMassiveImport';
import {IMIFile} from '../../Models/MassiveImport/IMIFile';
import {IMIMetadata, IMMetadataUploadValidation} from '../../Models/MassiveImport/IMIMetadata';

export const SET_STAGING_AREA = 'SET_STAGING_AREA';
export const SET_FILES = 'SET_FILES';
export const UPDATE_FILE = 'UPDATE_FILE';
export const SET_ACTIVE_FILES = 'SET_ACTIVE_FILES';
export const DELETE_FILES = 'DELETE_FILES';
export const SET_METADATA = 'SET_METADATA';
export const SET_ACTIVE_METADATA = 'SET_ACTIVE_METADATA';
export const SET_NEED_REFRESH_METADATA = 'SET_NEED_REFRESH_METADATA';
export const SET_METADATA_UPLOAD_VALIDATION = 'SET_METADATA_UPLOAD_VALIDATION';

interface SetDocumentActionType {
  type: typeof SET_STAGING_AREA;
  payload: IMIStagingArea;
}

interface SetFilesActionType {
  type: typeof SET_FILES;
  payload: IMIFile[];
}

interface UpdateFileActionType {
  type: typeof UPDATE_FILE;
  payload: IMIFile;
}

interface SetActiveFilesActionType {
  type: typeof SET_ACTIVE_FILES;
  payload: IMIFile[];
}

interface DeleteFilesActionType {
  type: typeof DELETE_FILES;
  payload: IMIFile[];
}

interface SetMetadataActionType {
  type: typeof SET_METADATA;
  payload: IMIMetadata[];
}

interface SetActiveMetadataActionType {
  type: typeof SET_ACTIVE_METADATA;
  payload: IMIMetadata;
}

interface SetNeedRefreshMetadataActionType {
  type: typeof SET_NEED_REFRESH_METADATA;
  payload: boolean;
}

interface SetMetadataUploadValidationActionType {
  type: typeof SET_METADATA_UPLOAD_VALIDATION;
  payload: IMMetadataUploadValidation;
}

export type MassiveImportActionTypes =
  | SetDocumentActionType
  | SetFilesActionType
  | UpdateFileActionType
  | SetActiveFilesActionType
  | DeleteFilesActionType
  | SetMetadataActionType
  | SetActiveMetadataActionType
  | SetNeedRefreshMetadataActionType
  | SetMetadataUploadValidationActionType;

const setStagingArea = (stagingArea: IMIStagingArea): SetDocumentActionType => {
  return {type: SET_STAGING_AREA, payload: stagingArea};
};

const setFiles = (files: IMIFile[]): SetFilesActionType => {
  return {type: SET_FILES, payload: files};
};

const updateFile = (file: IMIFile): UpdateFileActionType => {
  return {type: UPDATE_FILE, payload: file};
};

const setActiveFiles = (files: IMIFile[]): SetActiveFilesActionType => {
  return {type: SET_ACTIVE_FILES, payload: files};
};

const deleteFiles = (files: IMIFile[]): DeleteFilesActionType => {
  return {type: DELETE_FILES, payload: files};
};

const setMetadata = (metadata: IMIMetadata[]): SetMetadataActionType => {
  return {type: SET_METADATA, payload: metadata};
};

const setActiveMetadata = (metadata: IMIMetadata): SetActiveMetadataActionType => {
  return {type: SET_ACTIVE_METADATA, payload: metadata};
};

const setNeedRefreshMetadata = (flag: boolean): SetNeedRefreshMetadataActionType => {
  return {type: SET_NEED_REFRESH_METADATA, payload: flag};
};

const setMetadataUploadValidation = (b: IMMetadataUploadValidation): SetMetadataUploadValidationActionType => {
  return {type: SET_METADATA_UPLOAD_VALIDATION, payload: b};
};

export const MassiveImportAction = {
  setStagingArea,
  setFiles,
  updateFile,
  setActiveFiles,
  deleteFiles,
  setMetadata,
  setActiveMetadata,
  setNeedRefreshMetadata,
  setMetadataUploadValidation,
};
