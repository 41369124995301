import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NewDocumentAction} from '../../Reducers/NewDocument/NewDocumentActions';
import {GlobalState} from '../../Reducers/RootReducer';
import ApiService from '../../Services/ApiService';
import Icons from '../../Utils/FabricIconsOutlet';
import {ITeamItem} from '../../Models/ITeam';
import {IAPIResponse} from '../../Services/AjaxService';
import {FileSelectAreaProps} from '../FileSelectArea/FileSelectArea';
import {getLabelsErrorHandling, IErrorTypes} from '../MainDocumentContainer/MainDocumentContainer';
import {FilePickerFromTeamsOrLocal} from '../FilePickerFromTeamsOrLocal/FilePickerFromTeamsOrLocal';
import {ILibrary} from '../../Models/ILibrary';

interface AttachmentsContainerProps {
  teamsFilePicker: boolean;
}

export const AttachmentsContainer = (props: AttachmentsContainerProps) => {
  const [errorType, setErrorType] = useState<IErrorTypes>('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const documentId = useSelector<GlobalState, string>((state) => state.newDocument.document.documentId);
  const library = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);

  const getLabels = (): FileSelectAreaProps => {
    if (errorType === '') {
      if (props.teamsFilePicker) {
        return {
          buttonIcon: Icons.getIcon('CloudUpload', '2em'),
          dropAreaTitle: 'Upload any attachment file from Teams.',
          buttonLabel: 'Choose attachments',
        };
      } else {
        return {
          buttonIcon: Icons.getIcon('CloudUpload', '2em'),
          dropAreaTitle: 'Upload any attachment file.',
          buttonLabel: 'Choose attachments',
        };
      }
    } else {
      return getLabelsErrorHandling(errorType);
    }
  };

  const onDropAndClickLocalFiles = (files: File[], unzipFiles?: boolean) => {
    setErrorType('');
    setLoading(true);

    // Max file size = 4Gi (w\out this filter, the Azure Gateway would return a network error)
    if ( files.some( file => file.size >= 4294967296 ) ) {
      setLoading(false);
      setErrorType('file-too-big');
    }
    else {
      ApiService.DocumentController.uploadAttachments(documentId, files, unzipFiles, library?.id, (response) => {
        setLoading(false);
        if (response.error == null) {
          dispatch(NewDocumentAction.addAttachments(response.payload || []));
        }
        else {
          if (response.payload && response.payload.status === 400 && response.payload.title === 'Invalid File Type') {
            setErrorType('not-supported-format');
          }
          else {
            setErrorType('generic');
          }
        }
      });
    }
  };

  const onTeamsFileSelection = (files: ITeamItem[]) => {
    setErrorType('');
    setLoading(true);

    if (files.length > 0) {
      const firstFile = files[0];
      ApiService.DocumentController.uploadAttachmentsFromTeams(
        documentId,
        firstFile.driveId,
        firstFile.parentFolderID,
        files.map((x) => x.itemId),
        library?.id,
        (response: IAPIResponse) => {
          setLoading(false);
          if (response.error == null) {
            dispatch(NewDocumentAction.addAttachments(response.payload || []));
          }
          else {
            if (response.payload && response.payload.status === 400 && response.payload.title === 'Invalid File Type') {
              setErrorType('not-supported-format');
            }
            else {
              setErrorType('generic');
            }
          }
        }
      );
    }
  };

  const fileSelectAreaProps = getLabels();

  return (
    <>
      <FilePickerFromTeamsOrLocal
        loading={loading}
        onDropAndClickLocalFiles={onDropAndClickLocalFiles}
        onTeamsFileSelection={onTeamsFileSelection}
        fileSelectAreaProps={fileSelectAreaProps}
        teamsFilePicker={props.teamsFilePicker}
        displayLoadingOverlay={false}
        checkFileUnZip={true}
      />
    </>
  );
};
