import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {IDropdown, IDropdownOption, Spinner} from '@fluentui/react';
import {createRef, useEffect, useState} from 'react';
import {IBoLibrary, IElementTypes, IExportStatus, ILibrary, ILibraryDocumentType, IProfessionalArea} from '../../../Models/ILibrary';
import {IPermissionMatrixItem} from '../../../Models/PermissionMatrixItem';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import './PermissionMatrixAdminSection.scss';
import {getExportStatus, StatusBubbleGeneric} from '../../../Components/StatusBubbleGeneric/StatusBubbleGeneric';
import { getDocumentTypePerSubtype, getMandatoryBosForProfessionalArea, getMandatoryMasterDataForProfessionalArea } from './PermissionMatrixUtils';
import PermissionMatrixAdminSectionRow from './PermissionMatrixAdminSectionRow';

const PermissionMatrixAdminSection = () => {
  const [permMatrixRows, setPermMatrixRows] = useState<IPermissionMatrixItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [libraries, setLibraries] = useState<ILibrary[]>([]);
  const MandatoryBODropDownRef = createRef<IDropdown>();
  const [exportStatus, setExportStatus] = useState<IExportStatus>();

  const updateExportStatus = () => {
    ApiService.PermissionMatrixController.getExportStatus((r: IAPIResponse) => {
      if (r.error === null) {
        setExportStatus(r.payload);
      }
    });
  };

  const [optionsMap, setOptionsMap] = useState<any>({
    documentSubTypeIds: [],
    elementTypeIds: [],
    professionalAreaIds: [],
  });
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>({
    documentSubTypeId: 'all',
    elementTypeId: 'all',
    professionalAreaId: 'all',
  });
  const [newItem, setNewItem] = useState<IPermissionMatrixItem>({
    id: null,
    elementTypeId: '',
    documentSubTypeId: '',
    professionalAreaId: '',
    creationDate: null,
    updateDate: null,
    updatedBy: null,
    mandatoryBOs: [],
  });

  useEffect(() => {
    let map: any = {
      elementTypeIds: [],
      professionalAreaIds: [],
      documentSubTypeIds: [],
      librariesForAreas: [],
      documentTypesForSubtypes: [],
    };

    for (let i = 0; i < libraries.length; i++) {
      let lib: ILibrary = libraries[i];

      // map as options al professional areas
      for (let j = 0; j < lib.professionalAreas.length; j++) {
        let a: IProfessionalArea = lib.professionalAreas[j];
        map.professionalAreaIds.push({
          key: a.id,
          text: a.name,
        });

        // store library name for each prof Area
        map.librariesForAreas.push({key: a.id, text: lib.name});
      }

      // map as options al element types
      for (let j = 0; j < lib.elementTypes.length; j++) {
        let e: IElementTypes = lib.elementTypes[j];
        map.elementTypeIds.push({
          key: e.id,
          text: e.name,
        });
      }

      // map as options al document sub types
      for (let j = 0; j < lib.documentTypes.length; j++) {
        let t: ILibraryDocumentType = lib.documentTypes[j];
        if (t.subTypes) {
          for (let k = 0; k < t.subTypes.length; k++) {
            let st: ILibraryDocumentType = t.subTypes[k];
            map.documentSubTypeIds.push({
              key: st.id,
              text: st.name,
            });

            // store doc type name for each document sub type
            map.documentTypesForSubtypes.push({key: st.id, text: t.name});
          }
        }
      }
    }

    setOptionsMap(map);
  }, [libraries]);

  useEffect(() => {
    setLoading(true);

    ApiService.LibraryController.getLibrariesAdmin((response: IAPIResponse) => {
      if (response.error === null) {
        setLibraries(response.payload);

        ApiService.PermissionMatrixController.getAll((response2: IAPIResponse) => {
          if (response2.error === null) {
            setPermMatrixRows(response2.payload);
            updateExportStatus();
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  const updateValue = (key: string, value: any) => {
    let edited: IPermissionMatrixItem = {...newItem};
    edited[key] = value;
    setNewItem(edited);
  };

  const updateFilterValue = (key: string, value: string) => {
    let edited: IPermissionMatrixItem = {...filters};
    edited[key] = value;
    setPage(0);
    setFilters(edited);
  };

  const filterResults = () => {
    let rows: IPermissionMatrixItem[] = [...permMatrixRows];
    let rowsFiltered: IPermissionMatrixItem[] = [];

    for (let i = 0; i < rows.length; i++) {
      let matchLevel = 0;
      if (rows[i].documentSubTypeId === filters.documentSubTypeId || filters.documentSubTypeId === 'all') {
        matchLevel += 1;
      }
      if (rows[i].professionalAreaId === filters.professionalAreaId || filters.professionalAreaId === 'all') {
        matchLevel += 1;
      }
      if (rows[i].elementTypeId === filters.elementTypeId || filters.elementTypeId === 'all') {
        matchLevel += 1;
      }

      if (matchLevel === 3) {
        rowsFiltered.push(rows[i]);
      }
    }

    return rowsFiltered;
  };

  let filters_documentSubTypeIds: any = [...optionsMap['documentSubTypeIds']];
  let filters_professionalAreaIds: any = [...optionsMap['professionalAreaIds']];
  let filters_elementTypeIds: any = [...optionsMap['elementTypeIds']];

  filters_documentSubTypeIds.unshift({key: 'all', text: 'All Document Sub-Types'});
  filters_professionalAreaIds.unshift({key: 'all', text: 'All Professional Areas'});
  filters_elementTypeIds.unshift({key: 'all', text: 'All Element Types'});

  const results: IPermissionMatrixItem[] = filterResults();

  const maxPages: number = Math.ceil(results.length / perPage);
  return (
    <div>
      <div className="permission-matrix-admin-section-header">
        <span className="permission-matrix-admin-section-title">Permissions Matrix</span>
        {exportStatus != undefined && <StatusBubbleGeneric bubbleStatus={getExportStatus(exportStatus)} />}
      </div>
      <ModalDialog
        enableModal={openDialog}
        modalTitle="Add new Permission"
        modalInnerComponent={
          <div>
            <div className="permission-matrix-admin-section-row-voice wide-voice">
              <FluentUIDecorator
                label="Document Sub-Type"
                required={true}
                info={getInputInfos('admin-area-permission-matrix', 'document-sub-type')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  selectedKey: newItem.documentSubTypeId,
                  onRenderOption: (option: IDropdownOption) => {
                    return (
                      <span>
                        <span>{'[' + getDocumentTypePerSubtype(optionsMap, option.key.toString()) + '] '}</span>
                        <span>{option.text}</span>
                      </span>
                    );
                  },
                  onRenderTitle: (options: IDropdownOption[]) => {
                    if (options.length === 0) {
                      return <span></span>;
                    }
                    return (
                      <span>
                        <span>{'[' + getDocumentTypePerSubtype(optionsMap, options[0].key.toString()) + '] '}</span>
                        <span>{options[0].text}</span>
                      </span>
                    );
                  },
                  options: optionsMap['documentSubTypeIds'],
                  onChange: (e, o: any) => {
                    updateValue('documentSubTypeId', o.key);
                  },
                })}
              />
            </div>
            <div className="permission-matrix-admin-section-row-voice wide-voice">
              <FluentUIDecorator
                label="Element Type"
                required={true}
                info={getInputInfos('admin-area-permission-matrix', 'element-type')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  selectedKey: newItem.elementTypeId,
                  options: optionsMap['elementTypeIds'],
                  onChange: (e, o: any) => {
                    updateValue('elementTypeId', o.key);
                  },
                })}
              />
            </div>
            <div className="permission-matrix-admin-section-row-voice wide-voice">
              <FluentUIDecorator
                label="Professional Area"
                required={true}
                info={getInputInfos('admin-area-permission-matrix', 'professional-area')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  selectedKey: newItem.professionalAreaId,
                  options: optionsMap['professionalAreaIds'],
                  onChange: (e, o: any) => {
                    updateValue('professionalAreaId', o.key);
                  },
                })}
              />
            </div>
            <div className="permission-matrix-admin-section-row-voice wide-voice">
              <FluentUIDecorator
                label="Mandatory BOs"
                info={getInputInfos('admin-area-permission-matrix', 'mandatory-bos')}
                fluentComponent={FluentUIDecoratorTypes.Dropdown({
                  componentRef: MandatoryBODropDownRef,
                  disabled: !newItem.professionalAreaId,
                  multiSelect: true,
                  defaultSelectedKeys: newItem.mandatoryBOs,
                  placeholder: 'Mandatory BOs',
                  options: getMandatoryBosForProfessionalArea(libraries, newItem.professionalAreaId),
                  onDismiss: () => {
                    updateValue(
                      'mandatoryBOs',
                      MandatoryBODropDownRef.current.selectedOptions.map((x: any) => {
                        return x.key;
                      })
                    );
                  },
                })}
              />
            </div>
          </div>
        }
        onAbort={() => {
          setOpenDialog(false);
        }}
        modalButtons={[
          {
            label: 'Cancel',
            onClick: () => {
              setOpenDialog(false);
            },
          },
          {
            label: 'Add Item',
            disabled: newItem.documentSubTypeId === '' || newItem.elementTypeId === '' || newItem.professionalAreaId === '',
            onClick: () => {
              ApiService.PermissionMatrixController.createOne(newItem, (response: IAPIResponse) => {
                if (response.error === null) {
                  setOpenDialog(false);

                  let newRows: IPermissionMatrixItem[] = [...permMatrixRows];
                  newRows.unshift(response.payload);
                  setPermMatrixRows(newRows);
                  updateExportStatus();
                }
              });
            },
          },
        ]}
      />
      {loading && (
        <div className="permission-matrix-admin-section-spinner-wrap">
          <Spinner labelPosition="right" label="Loading..." />
        </div>
      )}
      {!loading && (
        <div>
          <div className="permission-matrix-admin-section-row" style={{justifyContent: 'space-between'}}>
            <div className="permission-matrix-admin-section-left-buttons">
              <LabelButton
                text="Add new"
                icon={'Add'}
                whiteOutlined
                onClick={() => {
                  setNewItem({
                    id: null,
                    elementTypeId: '',
                    documentSubTypeId: '',
                    professionalAreaId: '',
                    creationDate: null,
                    updateDate: null,
                    updatedBy: null,
                    mandatoryBOs: [],
                  });
                  setOpenDialog(true);
                }}
              />
              {exportStatus === IExportStatus.WorkInProgress && (
                <LabelButton
                  text="Mark as ready to deploy"
                  icon={'Upload'}
                  whiteOutlined
                  onClick={() => {
                    ApiService.PermissionMatrixController.updateExportStatus((r: IAPIResponse) => {
                      if (r.error === null) {
                        setExportStatus(IExportStatus.ReadyToDeploy);
                      }
                    });
                  }}
                />
              )}
            </div>

            <div className="permission-matrix-admin-section-row">
              <div style={{marginRight: '2em', minWidth: '25em', maxWidth: '25em'}}>
                <FluentUIDecorator
                  label="Filter Document Sub-Type"
                  noLabels={true}
                  info={getInputInfos('admin-area-permission-matrix', 'document-sub-type')}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    selectedKey: filters.documentSubTypeId,
                    options: filters_documentSubTypeIds,
                    onChange: (e, o: any) => {
                      updateFilterValue('documentSubTypeId', o.key);
                    },
                  })}
                />
              </div>
              <div style={{marginRight: '2em', minWidth: '15em', maxWidth: '15em'}}>
                <FluentUIDecorator
                  label="Filter Element Type"
                  noLabels={true}
                  info={getInputInfos('admin-area-permission-matrix', 'document-sub-type')}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    selectedKey: filters.elementTypeId,
                    options: filters_elementTypeIds,
                    onChange: (e, o: any) => {
                      updateFilterValue('elementTypeId', o.key);
                    },
                  })}
                />
              </div>
              <div style={{marginRight: '2em', minWidth: '15em', maxWidth: '15em'}}>
                <FluentUIDecorator
                  label="Filter Professional Area"
                  noLabels={true}
                  info={getInputInfos('admin-area-permission-matrix', 'document-sub-type')}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    selectedKey: filters.professionalAreaId,
                    options: filters_professionalAreaIds,
                    onChange: (e, o: any) => {
                      updateFilterValue('professionalAreaId', o.key);
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="permission-matrix-admin-section-main-wrap">
            {results.slice(page * perPage, (page + 1) * perPage).map((x: IPermissionMatrixItem, i: number) => {
              return (
                <PermissionMatrixAdminSectionRow
                  key={i}
                  onEdited={(item: IPermissionMatrixItem) => {
                    let rows: IPermissionMatrixItem[] = [...permMatrixRows];
                    for (let i = 0; i < rows.length; i++) {
                      if (rows[i].id === item.id) {
                        rows[i] = item;
                        break;
                      }
                    }
                    setPermMatrixRows(rows);
                  }}
                  onDeleted={(deleted: IPermissionMatrixItem) => {
                    let rows: IPermissionMatrixItem[] = [...permMatrixRows];
                    let rowsNew: IPermissionMatrixItem[] = [];
                    for (let i = 0; i < rows.length; i++) {
                      if (rows[i].id !== deleted.id) {
                        rowsNew.push(rows[i]);
                      }
                    }
                    setPermMatrixRows(rowsNew);
                    updateExportStatus();
                  }}
                  optionsMap={optionsMap}
                  item={x}
                  noTitles={i > 0}
                />
              );
            })}
          </div>
          <div className="permission-matrix-admin-section-nav-buttons">
            <LabelButton
              text="Back"
              icon="ChromeBack"
              whiteOutlined
              disabled={page === 0}
              onClick={() => {
                setPage(page - 1);
              }}
            />
            <div className="permission-matrix-admin-section-nav-label">
              <span style={{marginRight: '1em'}}>
                Page {maxPages === 0 ? 0 : page + 1} of {maxPages}, results from {maxPages === 0 ? 0 : 1 + page * perPage} to{' '}
                {maxPages === page + 1 ? results.length : maxPages === 0 ? 0 : (page + 1) * perPage}
              </span>
              <span>
                Per page:
                <select
                  style={{marginLeft: '1em'}}
                  onChange={(e: any) => {
                    setPage(0);
                    setPerPage(e.target.value);
                  }}>
                  <option value={10} selected={perPage === 10}>
                    10
                  </option>
                  <option value={20} selected={perPage === 20}>
                    20
                  </option>
                  <option value={50} selected={perPage === 50}>
                    50
                  </option>
                  <option value={100} selected={perPage === 100}>
                    100
                  </option>
                </select>
              </span>
            </div>
            <LabelButton
              text="Next"
              icon="ChromeBackMirrored"
              orangeSolid
              disabled={(page + 1) * perPage >= results.length}
              onClick={() => {
                setPage(page + 1);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PermissionMatrixAdminSection;
