import {combineReducers} from 'redux';
import {documentSearchReducer} from './DocumentSearch/DocumentSearchReducer';
import {DocumentSearchState} from './DocumentSearch/DocumentSearchState';
import {genericReducer} from './Generic/GenericReducer';
import {GenericState} from './Generic/GenericState';
import {newDocumentReducer} from './NewDocument/NewDocumentReducer';
import {NewDocumentState} from './NewDocument/NewDocumentState';
import {userReducer} from './User/UserReducer';
import {UserState} from './User/UserState';
import {MassiveImportState} from './MassiveImport/MassiveImportState';
import {massiveImportReducer} from './MassiveImport/MassiveImportReducer';
import {adminReducer} from './Admin/AdminReducer';
import {AdminState} from './Admin/AdminState';

const rootReducer = combineReducers({
  newDocument: newDocumentReducer,
  documentSearch: documentSearchReducer,
  user: userReducer,
  generic: genericReducer,
  massiveImport: massiveImportReducer,
  admin: adminReducer,
});

export interface GlobalState {
  newDocument: NewDocumentState;
  documentSearch: DocumentSearchState;
  user: UserState;
  generic: GenericState;
  massiveImport: MassiveImportState;
  admin: AdminState;
}

export default rootReducer;
