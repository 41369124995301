import Icons from '../../Utils/FabricIconsOutlet';
import {FILE_ICONS} from '@Eni/docware-fe-master';
import './FileUploadBoxPreview.scss';

export interface IImageThumbnailBox {
  documentId: string;
  documentName: string;
  imageName: string;
  size?: number;
  tag?: string;
  probability?: number;
  imageUrl?: string;
  thumbnailUrl?: string;
}

export const formatFileSize = (size: number) => {
  if (!size) {
    return '';
  }

  let label = '';
  if (size > 1000000000000) {
    label = (size / 1000000000000).toString().substring(0, 6) + 'TB';
  } else if (size > 1000000000) {
    label = (size / 1000000000).toString().substring(0, 6) + 'GB';
  } else if (size > 1000000) {
    label = (size / 1000000).toString().substring(0, 6) + 'MB';
  } else if (size > 1000) {
    label = (size / 1000).toString().substring(0, 6) + 'KB';
  } else {
    label = size.toString().substring(0, 6) + 'Bytes';
  }

  return label;
};

const processFormat = (fileName: string) => {
  if (!fileName) {
    return '';
  }

  let nameSpl = fileName.split('.');
  return nameSpl[nameSpl.length - 1].toUpperCase();
};

export const getUploadIconFromExt = (format: string) => {
  for (let i = 0; i < FILE_ICONS.length; i++) {
    let icon = FILE_ICONS[i];
    if (icon.name === format) {
      return (
        <img
          className="upload-preview-icon"
          src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${icon.name}.svg`}
          alt=""
        />
      );
    }
    if (icon.validFor) {
      if (icon.validFor.indexOf(format) !== -1) {
        return (
          <img
            className="upload-preview-icon"
            src={`https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${icon.name}.svg`}
            alt=""
          />
        );
      }
    }
  }

  return Icons.getIcon('Page');
};

export const getUploadIcon = (fileName: string) => {
  let format = processFormat(fileName).toLocaleLowerCase();
  return getUploadIconFromExt(format);
};

const ImageThumbnailBox = (props: IImageThumbnailBox) => {
  return (
    <div className="file-upload-box-main-wrap">
      <div className="file-upload-box-upper-zone">
        <div className="file-upload-box-ico-name-wrap">
          <div className="file-upload-box-icon">
            {props.thumbnailUrl && <img className="upload-preview-icon" src={props.thumbnailUrl} alt="" />}
            {!props.thumbnailUrl && getUploadIcon(props.imageName)}
          </div>
          <div className="file-upload-box-inner-wrap">
            <div className="file-upload-box-name">
              {props.tag && props.probability ? props.tag + ' (' + (props.probability * 100).toFixed(2) + '%)' : 'TAG N/A'}
            </div>
            <div className="file-upload-box-small-file-size">{'Image classification tag'}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageThumbnailBox;
