import {IMetadataValue} from '../Models/IDocument';
import {MetadataType} from '../Models/ILibrary';

/**
 * Given a metadata info and a value, return a complete IMetadataValue
 * @param metadataType The metadata for which we wish to construct an IMetadataValue
 * @param value The actual value. If null return null
 */
export function createMetadataValue(metadataType: MetadataType, value: any): IMetadataValue | null {
  if (!value) {
    return null;
  }
  const typeDict = {
    [MetadataType.Text]: 'stringValue',
    [MetadataType.Date]: 'dateValue',
    [MetadataType.Select]: 'selectValue',
    [MetadataType.MultiSelect]: 'multiselectValue',
    [MetadataType.AutoIncrement]: 'numberValue',
    [MetadataType.TagPickerSingleSelect]: 'selectValue',
    // [MetadataType.???] : "boolValue"
  };

  const field = typeDict[metadataType];
  return {
    type: metadataType,
    [field]: value,
  };
}

/**
 * Given a metadata returns its appropriate and human readable value (e.g. the name instead of the id for options)
 * @param metadata The metadata for which we wish to construct an IMetadataValue
 * @param value The actual value. If null return null
 */
export function extractMetadataValue(metadata: IMetadataValue): any {
  switch (metadata?.type) {
    case MetadataType.Text:
      return metadata.stringValue;
    case MetadataType.Date:
      return new Date(metadata.dateValue).toLocaleDateString();
    case MetadataType.TagPickerSingleSelect:
    case MetadataType.Select:
      return metadata.selectValue.name;
    case MetadataType.MultiSelect:
      return metadata.multiselectValue.map((msv) => msv.name);
    case MetadataType.AutoIncrement:
      return metadata.numberValue;
    default:
      throw new Error(`Invalid metadata type: ${metadata?.type}`);
  }
}
