import {GENERIC_URLS} from '../../Constants/Urls';
import {IDocumentTypes} from '../../Models/DocumentTypes';

import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const DocumentTypesController = {
  getDocumentTypesForLibraries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_TYPES_FOR_LIBRARIES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocumentTypes: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_TYPES;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteDocumentTypes: async (body: IDocumentTypes, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_TYPES;
    request.method = 'delete';
    request.payload = body;
    request.successMessage = 'Record deleted.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  setDocumentTypes: async (body: IDocumentTypes, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_TYPES;
    request.method = 'post';
    request.payload = body;
    request.successMessage = 'Record added.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  patchDocumentTypes: async (body: IDocumentTypes, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.DOCUMENT_TYPES;
    request.method = 'PATCH';
    request.payload = body;
    request.successMessage = 'Record updated.';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default DocumentTypesController;
