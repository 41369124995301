import {GlobalState} from '../../../Reducers/RootReducer';
import {useDispatch, useSelector} from 'react-redux';
import {AdminAction} from '../../../Reducers/Admin/AdminActions';

import {ModalDialog} from '@Eni/docware-fe-master';
import {TextField} from '@fluentui/react';
import {SeverityDropdown} from './SeverityDropdown';
import CustomDatePicker from '../../../Components/CustomDatePicker/CustomDatePicker';

import {validateNewItem} from './AdminNewsValidateNewItem';

import ApiService from '../../../Services/ApiService';
import {IAPIResponse} from '../../../Services/AjaxService';

interface INewsModalProps {
  loadNews(): void;
}

export const AdminNewsAddModal = (props: INewsModalProps) => {
  const newsState = useSelector((state: GlobalState) => state.admin.newsTab);
  const dispatch = useDispatch();

  return (
    <ModalDialog
      modalTitle={'Add New item'}
      modalInnerComponent={
        <div>
          <div>
            <div style={{marginTop: '1em'}}>
              <TextField
                label={'Title'}
                value={newsState.addNewItem.title}
                onChange={(e: any) => {
                  let x = {...newsState.addNewItem};
                  x['title'] = e.target.value;
                  dispatch(AdminAction.setAddNewItem(x));
                }}
              />
            </div>
            <div style={{marginTop: '1em'}}>
              <TextField
                label={'Description'}
                multiline={true}
                value={newsState.addNewItem.description}
                onChange={(e: any) => {
                  let x = {...newsState.addNewItem};
                  x['description'] = e.target.value;
                  dispatch(AdminAction.setAddNewItem(x));
                }}
              />
            </div>
            <div style={{marginTop: '1em'}}>
              <SeverityDropdown
                selected={newsState.addNewItem.severity}
                onChange={(e: any) => {
                  let x = {...newsState.addNewItem};
                  x['severity'] = parseInt(e.currentTarget.getAttribute('data-index'));
                  dispatch(AdminAction.setAddNewItem(x));
                }}
              />
            </div>
            <div style={{marginTop: '1em'}}>
              <CustomDatePicker
                label="From"
                value={null}
                onSelectDate={(e: any) => {
                  let x = {...newsState.addNewItem};
                  x['dateRange']['from'] = e;
                  dispatch(AdminAction.setAddNewItem(x));
                }}
              />
            </div>
            <div style={{marginTop: '1em'}}>
              <CustomDatePicker
                label="To"
                value={null}
                onSelectDate={(e: any) => {
                  let x = {...newsState.addNewItem};
                  x['dateRange']['to'] = new Date(e);
                  dispatch(AdminAction.setAddNewItem(x));
                }}
              />
            </div>
          </div>
        </div>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            dispatch(AdminAction.setShowAddModal(false));
            dispatch(
              AdminAction.setAddNewItem({
                id: '',
                title: '',
                description: '',
                insertDate: new Date(),
                dateRange: {
                  from: null,
                  to: null,
                },
                severity: -1,
              })
            );
          },
        },
        {
          label: 'Save',
          onClick: () => {
            if (validateNewItem(newsState.addNewItem)) {
              ApiService.NewsController.createNew(newsState.addNewItem, (response: IAPIResponse) => {
                if (response.error == null) {
                  props.loadNews();
                  dispatch(AdminAction.setSelectedNews([]));
                  dispatch(AdminAction.setShowAddModal(false));
                  dispatch(
                    AdminAction.setAddNewItem({
                      id: '',
                      title: '',
                      description: '',
                      insertDate: new Date(),
                      dateRange: {
                        from: null,
                        to: null,
                      },
                      severity: -1,
                    })
                  );
                }
              });
            }
          },
        },
      ]}
      onAbort={() => {
        dispatch(AdminAction.setShowAddModal(false));
      }}
      enableModal={newsState.showAddModal}
    />
  );
};

export const AdminNewsEditModal = (props: INewsModalProps) => {
  const newsState = useSelector((state: GlobalState) => state.admin.newsTab);
  const dispatch = useDispatch();

  return (
    <ModalDialog
      modalTitle={'Edit New item'}
      modalInnerComponent={
        newsState.editNewItem && (
          <div>
            <div>
              <div style={{marginTop: '1em'}}>
                <TextField
                  label={'Title'}
                  value={newsState.editNewItem.title}
                  onChange={(e: any) => {
                    let x = {...newsState.editNewItem};
                    x['title'] = e.target.value;
                    dispatch(AdminAction.setEditNewItem(x));
                  }}
                />
              </div>
              <div style={{marginTop: '1em'}}>
                <TextField
                  label={'Description'}
                  multiline={true}
                  value={newsState.editNewItem.description}
                  onChange={(e: any) => {
                    let x = {...newsState.editNewItem};
                    x['description'] = e.target.value;
                    dispatch(AdminAction.setEditNewItem(x));
                  }}
                />
              </div>
              <div style={{marginTop: '1em'}}>
                <SeverityDropdown
                  selected={newsState.editNewItem.severity}
                  onChange={(e: any) => {
                    let x = {...newsState.editNewItem};
                    x['severity'] = parseInt(e.currentTarget.getAttribute('data-index'));
                    dispatch(AdminAction.setEditNewItem(x));
                  }}
                />
              </div>
              <div style={{marginTop: '1em'}}>
                <CustomDatePicker
                  label="From"
                  value={new Date(newsState.editNewItem.dateRange.from)}
                  onSelectDate={(e: any) => {
                    let x = {...newsState.editNewItem};
                    x['dateRange']['from'] = new Date(e);
                    dispatch(AdminAction.setEditNewItem(x));
                  }}
                />
              </div>
              <div style={{marginTop: '1em'}}>
                <CustomDatePicker
                  label="To"
                  value={new Date(newsState.editNewItem.dateRange.to)}
                  onSelectDate={(e: any) => {
                    let x = {...newsState.editNewItem};
                    x['dateRange']['to'] = new Date(e);
                    dispatch(AdminAction.setEditNewItem(x));
                  }}
                />
              </div>
            </div>
          </div>
        )
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            dispatch(AdminAction.setShowEditModal(false));
          },
        },
        {
          label: 'Save',
          onClick: () => {
            if (validateNewItem(newsState.editNewItem)) {
              ApiService.NewsController.updateNew(newsState.editNewItem, (response: IAPIResponse) => {
                if (response.error == null) {
                  props.loadNews();
                  dispatch(AdminAction.setSelectedNews([]));
                  dispatch(AdminAction.setShowEditModal(false));
                }
              });
            }
          },
        },
      ]}
      onAbort={() => {
        dispatch(AdminAction.setShowEditModal(false));
      }}
      enableModal={newsState.showEditModal}
    />
  );
};

export const AdminNewsDeleteModal = (props: INewsModalProps) => {
  const newsState = useSelector((state: GlobalState) => state.admin.newsTab);
  const dispatch = useDispatch();

  return (
    <ModalDialog
      modalTitle={'Delete News'}
      modalInnerComponent={
        <div>
          {newsState.selectedNews.length == 1 && (
            <div>
              <div style={{marginTop: '1em'}}>
                <p>Are you sure you want to delete "{newsState.selectedNews[0].title}" new item?</p>
              </div>
            </div>
          )}
          {newsState.selectedNews.length > 1 && (
            <div>
              <div style={{marginTop: '1em'}}>
                <p>Are you sure you want to delete the {newsState.selectedNews.length} selected new items?</p>
              </div>
            </div>
          )}
        </div>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            dispatch(AdminAction.setShowDeleteModal(false));
          },
        },
        {
          label: 'Delete',
          onClick: () => {
            let newsIds: string[] = newsState.selectedNews.map((newItem) => newItem.id);
            ApiService.NewsController.deleteNews(newsIds, (response: IAPIResponse) => {
              if (response.error == null) {
                props.loadNews();
                dispatch(AdminAction.setSelectedNews([]));
                dispatch(AdminAction.setShowDeleteModal(false));
              }
            });
          },
        },
      ]}
      onAbort={() => {
        dispatch(AdminAction.setShowDeleteModal(false));
      }}
      enableModal={newsState.showDeleteModal}
    />
  );
};
