import {NEWS_URLS} from '../../Constants/Urls';
import {NewItem} from '../../Models/New';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const NewsController = {
  readOneNew: async (New: NewItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = NEWS_URLS.READ_ONE_NEW;
    request.method = 'GET';
    request.payload = New;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  readAllNews: async (active: Boolean, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = NEWS_URLS.READ_ALL_NEWS;
    request.method = 'POST';
    request.payload = {Skip: 0, Limit: 0, Active: active};
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  createNew: async (New: NewItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = NEWS_URLS.CREATE_NEW;
    request.method = 'POST';
    request.payload = New;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  updateNew: async (New: NewItem, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = NEWS_URLS.UPDATE_NEW;
    request.method = 'PUT';
    request.payload = New;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteNews: async (newsIds: string[], callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = NEWS_URLS.DELETE_NEW;
    request.method = 'DELETE';
    request.payload = newsIds;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default NewsController;
