import {
  DELETE_FILES,
  MassiveImportActionTypes,
  SET_ACTIVE_FILES,
  SET_ACTIVE_METADATA,
  SET_FILES,
  SET_METADATA,
  SET_METADATA_UPLOAD_VALIDATION,
  SET_NEED_REFRESH_METADATA,
  SET_STAGING_AREA,
  UPDATE_FILE,
} from './MassiveImportActions';
import {DEFAULT_MASSIVE_IMPORT_STATE, MassiveImportState} from './MassiveImportState';

import {IMIFile} from '../../Models/MassiveImport/IMIFile';

export const massiveImportReducer = (
  state: MassiveImportState = DEFAULT_MASSIVE_IMPORT_STATE,
  action: MassiveImportActionTypes
): MassiveImportState => {
  switch (action.type) {
    case SET_STAGING_AREA:
      return {
        ...state,
        stagingArea: action.payload,
      };
    case SET_FILES:
      return {
        ...state,
        filePage: {
          ...state.filePage,
          files: action.payload,
        },
      };
    case UPDATE_FILE:
      const newFile = action.payload;
      return {
        ...state,
        filePage: {
          ...state.filePage,
          files: state.filePage.files.map((file) => {
            if (file.fileName == newFile.fileName) {
              return newFile;
            } else {
              return file;
            }
          }),
        },
      };
    case SET_ACTIVE_FILES:
      return {
        ...state,
        filePage: {
          ...state.filePage,
          activeFiles: action.payload,
        },
      };
    case DELETE_FILES:
      const filesName = action.payload.map((file) => file.fileName);
      return {
        ...state,
        filePage: {
          ...state.filePage,
          files: state.filePage.files.reduce<IMIFile[]>((accumulator: IMIFile[], currentValue: IMIFile) => {
            if (!filesName.includes(currentValue.fileName)) {
              accumulator.push(currentValue);
            }
            return accumulator;
          }, [] as IMIFile[]),
        },
      };
    case SET_METADATA:
      return {
        ...state,
        metadataPage: {
          ...state.metadataPage,
          metadata: action.payload,
          activeMetadata: undefined,
        },
      };
    case SET_ACTIVE_METADATA:
      return {
        ...state,
        metadataPage: {
          ...state.metadataPage,
          activeMetadata: action.payload,
        },
      };
    case SET_NEED_REFRESH_METADATA:
      return {
        ...state,
        metadataPage: {
          ...state.metadataPage,
          needRefreshMetadata: action.payload,
        },
      };
    case SET_METADATA_UPLOAD_VALIDATION:
      return {
        ...state,
        metadataPage: {
          ...state.metadataPage,
          metadataUploadValidation: action.payload,
        },
      };

    default:
      return state;
  }
};
