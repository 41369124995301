import {IMIMetadata, IMIMetadataBos, IMIMetadataMessageStatusMessage, IMIMetadataResponse, IMIMetadataStatus} from '../Models/MassiveImport/IMIMetadata';

  /*
    * This function converts the dynamic bos received from the backend to a static structure
    * Example
    * Input :
      {
				"xware_concessions": [
					"PERM|330|160",
					"pluto"
				],
				"xware_targets": [
					"test21"
				]
			}
    * Output:
      {
        "bo1_type": "xware_concessions",
        "bo1_value": "PERM|330|160",
        "bo2_type": "xware_concessions",
        "bo2_value": "pluto",
        "bo3_type": "xware_targets",
        "bo3_value": "test21"
      }
    * */
function convertBosToStaticStructure(bos?: IMIMetadataBos){
  if (bos == undefined) {
    return {
      bo1_type: '',
      bo1_value: '',
      bo2_type: '',
      bo2_value: '',
      bo3_type: '',
      bo3_value: '',
    };
  }
  let output = {};
  let boCounter = 1;
  Object.entries(bos).forEach(([type, values]) => {
    values.forEach((value, i) => {
      output[`bo${boCounter}_type`] = type;
      output[`bo${boCounter}_value`] = value;
      boCounter++;
    });
  });
  return output;
}

function getErrorMessageObj(messages: IMIMetadataMessageStatusMessage[]): {[columnName: string]: string[]} {
  return messages.reduce((acc, currentValue) => {
    currentValue.impactedColumns.forEach((column) => {
      acc[column] = [...(acc[column] ?? []), currentValue.message];
    });
    return acc;
  }, {});
}

export function convertIMIMetadataResponseToIMIMetadata(response: IMIMetadataResponse[]): IMIMetadata[] {
  return response.map((item) => {
    const errorMessages = [
      ...item.messages.metadata.messages,
      ...item.messages.bo.messages,
      ...item.messages.permissions.messages,
      ...item.messages.files.messages,
    ];
    const errorMessagesObj = getErrorMessageObj(errorMessages)

    const warningMessagesObj = getErrorMessageObj(item.messages.additionalInfos.messages)
    const bos = convertBosToStaticStructure(item.bOs);
    return {
      id: item.id,
      documentTitle: {
        value: item?.documentTitle ?? '',
        errorMessages: errorMessagesObj['documentTitle'] ?? [],
      },
      professionalArea: {
        id: item?.professionalArea?.id ?? '',
        value: item?.professionalArea?.name ?? '',
        errorMessages: errorMessagesObj['professionalArea'] ?? [],
      },
      documentType: {
        value: item?.documentType?.name ?? '',
        errorMessages: errorMessagesObj['documentType'] ?? [],
      },
      documentSubType: {
        value: item?.documentSubType?.name ?? '',
        errorMessages: errorMessagesObj['documentSubType'] ?? [],
      },
      country: {
        value: item?.country?.name ?? '',
        errorMessages: errorMessagesObj['country'] ?? [],
      },
      documentDate: {
        value: item.documentDate ?? '',
        errorMessages: errorMessagesObj['documentDate'] ?? [],
      },
      securityLevel: {
        value: item.securityLevel ?? '',
        errorMessages: errorMessagesObj['securityLevel'] ?? [],
        warningMessages: warningMessagesObj['securityLevel'],
      },
      source: {
        value: item.source ?? '',
        errorMessages: errorMessagesObj['source'] ?? [],
      },
      authors: {
        value: item?.authors.join(', ') ?? '',
        errorMessages: errorMessagesObj['authors'] ?? [],
      },
      whitelist: {
        value: item?.whitelist.join(', ') ?? '',
        errorMessages: errorMessagesObj['whitelist'] ?? [],
        warningMessages: warningMessagesObj['whitelist'],
      },
      description: {
        value: item.description ?? '',
        errorMessages: errorMessagesObj['description'] ?? [],
      },
      remarks: {
        value: item.remarks ?? '',
        errorMessages: errorMessagesObj['remarks'] ?? [],
      },
      fileName: {
        value: item.fileName ?? '',
        errorMessages: errorMessagesObj['fileName'] ?? [],
      },
      attachments: {
        value: item?.attachments.join(', ') ?? '',
        errorMessages: errorMessagesObj['attachments'] ?? [],
      },
      bo1_type: {
        value: bos['bo1_type'],
        errorMessages: [],
      },
      bo1_value: {
        value: bos['bo1_value'],
        errorMessages: [],
      },
      bo2_type: {
        value: bos['bo2_type'],
        errorMessages: [],
      },
      bo2_value: {
        value: bos['bo2_value'],
        errorMessages: [],
      },
      bo3_type: {
        value: bos['bo3_type'],
        errorMessages: [],
      },
      bo3_value: {
        value: bos['bo3_value'],
        errorMessages: [],
      },
      seismicAcquisitions: {
        value: item.seismicAcquisitions?.join(', ') ?? '',
        errorMessages: [],
      },
      seismicSurveys: {
        value: item.seismicSurveys?.join(', ') ?? '',
        errorMessages: [],
      },
      bo_validation: {
        value: '',
        errorMessages: errorMessagesObj['bOs'] ?? [],
      },
      metadata_projectCode: {
        value: item.metadata?.projectCode?.name ?? '',
        errorMessages: [],
      },
      metadata_projectName: {
        value: item.metadata?.projectName?.name ?? '',
        errorMessages: [],
      },
      metadata_projectPhase: {
        value: item.metadata?.projectPhase?.name ?? '',
        errorMessages: [],
      },
      metadata_reasonForIssue: {
        value: item.metadata?.reasonforIssue?.name ?? '',
        errorMessages: [],
      },
      metadata_contractName: {
        value: item.metadata?.contractName?.name ?? '',
        errorMessages: [],
      },
      metadata_contractNumber: {
        value: item.metadata?.contractNumber?.name ?? '',
        errorMessages: [],
      },
      metadata_documentCode: {
        value: item.metadata?.developmentDocumentCode?.name ?? '',
        errorMessages: [],
      },
      metadata_contractorName: {
        value: item.metadata?.contractorName?.name ?? '',
        errorMessages: [],
      },
      metadata_issueDate: {
        value: item.metadata?.issueDate?.name ?? '',
        errorMessages: [],
      },
      metadata_documentId: {
        value: item.metadata?.documentId?.name ?? '',
        errorMessages: [],
      },
      metadata_transmittalNode: {
        value: item.metadata?.transmittalNode?.name ?? '',
        errorMessages: [],
      },
      metadata_originator: {
        value: item.metadata?.originator?.name ?? '',
        errorMessages: [],
      },
      metadata_receiver: {
        value: item.metadata?.receiver?.name ?? '',
        errorMessages: [],
      },
      metadata_revisionNumber: {
        value: item.metadata?.revisionNumber?.name ?? '',
        errorMessages: [],
      },
      metadata_validation: {
        value: '',
        errorMessages: errorMessagesObj['metadata'] ?? [],
      },
      unzip: {
        value: item.unzip.toString(),
        errorMessages: errorMessagesObj['unzip'] ?? [],
      },
      sheetRow: {
        value: item.sheetRow.toString(),
        errorMessages: errorMessagesObj['sheetRow'] ?? [],
      },
      status: Object.keys(warningMessagesObj).length != 0 ? IMIMetadataStatus.WARNING : (item.messages.isSuccessful ? IMIMetadataStatus.SUCCESS : IMIMetadataStatus.FAIL),
    };
  });
}
