import {Spinner} from '@fluentui/react';
import {useEffect, useRef, useState} from 'react';
import {LOCAL_STORAGE_KEYS} from '../../Constants/LocalStorageKeys';
import {DOCUMENT_URLS} from '../../Constants/Urls';
import {FileInfo} from '../../Models/FileUtils';
import {APISettings} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import {CachedData} from '../../Utils/CachedData';
import {LabelButton} from '@Eni/docware-fe-master';
import './DocumentPreview.scss';

const MAX_SUPPORTED_FILE_SIZE = 750_000_000; // 750MB

const NOFILE = {
  size: 0,
  label: 'No file specified',
  url: '',
  extension: '',
  id: '',
  previewUrl: '',
  inlineUrl: '',
};

export interface IPreviewFileParameters {
  size: number;
  label: string;
  url: string;
  extension: string;
  id: string;
  previewUrl: string;
  inlineUrl: string;
  title?: string;
  previewType?: PreviewType;
  contentType?: string;
}

enum PreviewType {
  CONVERTIBLE_TO_PDF = 'CONVERTIBLE_TO_PDF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  EMBEDDABLE = 'EMBEDDABLE',
}

interface UploadFileType {
  /** The extension of the file */
  ext: string;

  /** The content types associated to of the file.
   * The first one is the "default"
   */
  contentTypes: string[];

  /** The type of preview available for this file type.
   * `null` if the file is no preview available
   */
  previewType: PreviewType | null;

  /** Wether the this file type is a compressed (e.g. .zip, .rar, etc.) */
  compressed: boolean;
}

export const DocumentPreview = (props: any) => {
  const [parameters, setParameters] = useState<IPreviewFileParameters>(NOFILE);
  const [previewLoading, setLoadingPreview] = useState<boolean>(true);
  const [previewError, setPreviewError] = useState<boolean>(false);

  const refCachedFileTypes = useRef<CachedData<UploadFileType[]>>(
    new CachedData<UploadFileType[]>(
      LOCAL_STORAGE_KEYS.UPLOAD_FILE_TYPES,
      async () => {
        const res = await ApiService.DocumentController.getValidFileTypes();
        return res.payload as UploadFileType[];
      },
      5 * 60 // 5 min
    )
  );

  // Add an event listener to handle the failure of the pdf preview creation.
  // It's the only way to communicate with the iframe:
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
  function pdfPreviewFailed(e) {
    if (e.data === 'pdf_preview_failed') {
      console.error('Failed to create pdf preview');
      setLoadingPreview(false);
      setPreviewError(true);
    }
  }
  window.addEventListener('message', pdfPreviewFailed);

  const setParametersAsync = async () => {
    let result = await getFileParameters(props.isAttachment, props.currentFile, props.signatureFile);
    console.log('On document preview', props, result);
    setParameters(result);
  };

  useEffect(() => {
    setParametersAsync();
  }, [props.currentFile, props.currentDocumentId]);

  useEffect(() => {
    if (parameters.previewType === PreviewType.CONVERTIBLE_TO_PDF) {
      setLoadingPreview(true);
      setPreviewError(false);
    }
  }, [parameters.id]);

  const getFileExtension = (name: string) => {
    let spl = name.split('.');
    if (spl.length > 1) {
      return '.' + spl[spl.length - 1].toLocaleLowerCase();
    } else {
      return '';
    }
  };

  const getFileParameters = async (isAttachment: boolean, document: FileInfo | null, signatureFile: FileInfo | null) => {
    if (document != null) {
      const extension = getFileExtension(document.fileName);
      const previewUrl = isAttachment
        ? `${DOCUMENT_URLS.PREVIEW_ATTACHMENT_DOCUMENT}${document.id}`
        : signatureFile == null
        ? `${DOCUMENT_URLS.PREVIEW_MAIN_DOCUMENT}${document.id}`
        : `${DOCUMENT_URLS.PREVIEW_MAIN_FOR_SIGNATURE_DOCUMENT}${signatureFile.id}`;

      let dataUrl = document.url;
      let inlineUrl = document.inlineUrl;
      if (!isAttachment && signatureFile != null) {
        dataUrl = signatureFile.url;
        inlineUrl = signatureFile.inlineUrl;
      }

      const previewTypes = await refCachedFileTypes.current.get();
      const fileType = previewTypes.find((x) => x.ext === extension);

      return {
        size: document.size,
        label: document.fileName,
        url: dataUrl ?? '',
        inlineUrl: inlineUrl,
        extension,
        id: document.id,
        previewUrl: APISettings.baseUrl + previewUrl,
        previewType: fileType?.previewType ?? null,
        contentType: fileType?.contentTypes?.[0] ?? null,
      };
    } else {
      return NOFILE;
    }
  };

  const downloadFileButton = () => {
    return (
      <LabelButton
        whiteOutlined
        text={'Download file'}
        icon={'Installation'}
        onClick={() => {
          /*
                    per davide: usiamo la onclick per creare i link
                    che altrimenti fluent mi sballa i css del bottone
                */
          let a = document.createElement('a');
          a.href = parameters.url;
          a.target = '_self';
          a.click();
        }}
      />
    );
  };

  const getProperTitle = () => {
    return (
      <div className="document-preview-main-line-info">
        <div>{props.title}</div>
        {false && (
          <div className="document-preview-main-file-name-label" title={parameters.label}>
            {parameters.label ?? ''}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={props.reduceHeight ? 'document-preview-main-wrap-reduce-height' : 'document-preview-main-wrap'}>
      <div className="document-preview-header">{getProperTitle()}</div>
      {parameters.url !== '' && componentToRender()}
    </div>
  );

  function componentToRender() {
    if (parameters.size >= MAX_SUPPORTED_FILE_SIZE) {
      return (
        <div className="unsupported-renderer-wrap">
          <div className="unsupported-renderer-title">This file is larger than 750MB. Preview is unavailable.</div>
          {downloadFileButton()}
        </div>
      );
    }
    switch (parameters.previewType) {
      case PreviewType.CONVERTIBLE_TO_PDF:
        const hideDiv = 'document-preview-pdf-hidden';
        return (
          <div>
            <div className={'document-preview-spinner-wrap-main ' + (previewLoading ? '' : hideDiv)}>
              <div className="document-preview-spinner-inner">
                <Spinner label={'Loading preview ' + parameters.label + '...'} />
              </div>
            </div>
            <div className={'pdf-renderer-wrap ' + (previewLoading || previewError ? hideDiv : '')}>
              <iframe
                id="myframe"
                width="100%"
                src={parameters.previewUrl}
                title={parameters.label}
                onLoad={() => setLoadingPreview(false)}
                referrerPolicy="origin"></iframe>
            </div>
            <div className={'unsupported-renderer-wrap ' + (previewError ? '' : hideDiv)}>
              <div className="unsupported-renderer-title">Preview not available</div>
              {downloadFileButton()}
            </div>
          </div>
        );
      case PreviewType.IMAGE:
        return (
          <div className="image-renderer-wrap">
            <img className="image-renderer-img" alt="" src={parameters.url} />
          </div>
        );
      case PreviewType.EMBEDDABLE:
        return (
          <div className="pdf-renderer-wrap">
            <iframe width="100%" src={parameters.inlineUrl} title={parameters.label}></iframe>
          </div>
        );
      case PreviewType.VIDEO:
        return (
          <div className="video-renderer-wrap">
            <video width="100%" key={parameters.url} controls>
              <source src={parameters.url} type={parameters.contentType} />
              Your browser does not support the video tag.
            </video>
          </div>
        );
      default:
        return (
          <div className="unsupported-renderer-wrap">
            <div className="unsupported-renderer-title">Preview is unavailable for this type of file.</div>
            {downloadFileButton()}
          </div>
        );
    }
  }
};

export default DocumentPreview;
