import {getTrackingComponent} from '../../AppInsights';
import VideoTutorialCarousel from '../../Components/VideoTutorialCarousel/VideoTutorialCarousel';
import {useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import './VideosTutorialListPage.scss';

const VideosTutorialListPage = () => {
  const videosTutorial = useSelector((state: GlobalState) => state.generic.videosTutorial);

  return (
    <>
      <VideoTutorialCarousel tutorials={videosTutorial} slidesPerView={5} />
    </>
  );
};

export default getTrackingComponent(VideosTutorialListPage, 'VideosTutorialListPage');
