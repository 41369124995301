import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../Reducers/RootReducer';
import {IBoColor} from '../../Models/ILibrary';
import {FormEvent, useState} from 'react';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/AjaxService';
import {toTitleCase} from '../../Utils/StringUtils';
import {getUploadIcon} from '../FileUploadBoxPreview/FileUploadBoxPreview';
import StatusBubble from '../StatusBubble/StatusBubble';
import Icons from '../../Utils/FabricIconsOutlet';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import AttachmentSmallBrowserButton from '../AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import AppRoutes from '../../Utils/AppRoutes';
import {FormatBoName, SEARCH_SCROLLABLE_CONTENT_ID, textToJSX, tryHighLighting} from '../../Pages/DocumentSearchPage/DocumentSearchPage';
import {IBoQueryDocument, IDocumentQueryDocument, INewDocument} from '../../Models/IDocument';
import './DocumentSearchResumeBox.scss';
import {Checkbox} from '@fluentui/react';
import { formatCurrentVersion } from '../../Utils/VersioningUtils';

interface IDocumentSearchResumeBoxProps {
  item: IDocumentQueryDocument;
  relatedDocument: INewDocument | null;
  highlights: any;
  onItemSelected: (item: any) => void;
  onAddFilters: (filters: {filterName: string; filterValue: any}[]) => void;
}

const DocumentSearchResumeBox = (props: IDocumentSearchResumeBoxProps) => {
  const boColors = useSelector<GlobalState, IBoColor[]>((state) => state.generic.boColors);
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);
  const dispatch = useDispatch();

  let maxCharsBeforeShowMore = 150;
  let highlights = props.highlights;

  let itemToUse = {...props.item};
  itemToUse = tryHighLighting(itemToUse, highlights);

  const [showText, setShowText] = useState<string>(itemToUse.content);
  const [canSeeShowMore, setCanSeeShowMore] = useState<boolean>((props.item.content ?? '').length > maxCharsBeforeShowMore);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [documentIsFavorite, setDocumentIsFavorite] = useState<boolean>(itemToUse.isFavorite);
  const [documentFavoriteIsLoading, setDocumentFavoriteIsLoading] = useState<boolean>(false);
  const [boxIsChecked, setBoxIsChecked] = useState<boolean>(false);
  const documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  const changeDocumentFavoriteState = () => {
    if (loggedUser !== undefined && documentFavoriteIsLoading === false) {
      setDocumentFavoriteIsLoading(true);
      const callMe = documentIsFavorite
        ? ApiService.DocumentController.deleteFavoriteDocument
        : ApiService.DocumentController.addFavoriteDocument;

      callMe([itemToUse.documentId], (res: IAPIResponse) => {
        if (res.error == null) {
          setDocumentIsFavorite(!documentIsFavorite);
          setDocumentFavoriteIsLoading(false);
        }
      });
    }
  };

  let resumeData: any = {
    author: (
      <div
        onClick={() => {
          const filter = {
            filterName: 'document.authors',
            filterValue: [{id: itemToUse?.authors?.[0]?.optionId, name: itemToUse?.authors?.[0]?.name}],
          };
          props.onAddFilters([filter]);
        }}>
        {textToJSX(toTitleCase(itemToUse?.authors?.[0]?.name) ?? '-')}
      </div>
    ),
    icon: <div className="search-box-reduce-image">{getUploadIcon(itemToUse.fileName ?? '-')}</div>,
    documentName: <div>{textToJSX(itemToUse.documentName ?? '-')}</div>,
    area: (
      <div
        onClick={() => {
          const filter = {filterName: 'document.professionalAreaIds', filterValue: [itemToUse.professionalArea.areaId]};
          props.onAddFilters([filter]);
        }}>
        {textToJSX(itemToUse.professionalArea.name ?? '-')}
      </div>
    ),
    countries: (
      <div>
        {textToJSX(
          itemToUse.countries
            ? itemToUse.countries
                .map((x) => {
                  return x.name;
                })
                .join(', ')
            : '-'
        )}
      </div>
    ),
    attachments: itemToUse.attachments,
    date: (
      <div
        onClick={() => {
          const filter = {
            filterName: 'documentDateRange',
            filterValue: {from: new Date(itemToUse.documentDate), to: new Date(itemToUse.documentDate)},
          };
          props.onAddFilters([filter]);
        }}>
        {textToJSX(itemToUse.documentDate ? new Date(itemToUse.documentDate).toLocaleDateString() : '-')}
      </div>
    ),
    status: <StatusBubble isOldVersion={itemToUse.isOldVersion} documentStatus={itemToUse.documentStatus} />,
    documentSubType: (
      <div
        onClick={() => {
          const filters = [
            {filterName: 'document.professionalAreaIds', filterValue: [itemToUse.professionalArea.areaId]},
            {filterName: 'document.documentTypeIds', filterValue: [itemToUse.documentType.id]},
            {filterName: 'document.documentSubTypeIds', filterValue: [itemToUse.documentSubType.id]},
          ];
          props.onAddFilters(filters);
        }}>
        {textToJSX(toTitleCase(itemToUse.documentSubType.name))}
      </div>
    ),
    favorite: (
      <div onClick={changeDocumentFavoriteState}>
        {documentIsFavorite === true ? Icons.getIcon('SingleBookmarkSolid') : Icons.getIcon('SingleBookmark')}
      </div>
    ),
  };

  interface IBOsVisualize {
    content: JSX.Element;
    cardColor: string | null;
    textColor: string | null;
    displayName: string | null;
  }

  const bOsNames: IBOsVisualize[] = itemToUse.bOs
    .map((bo: IBoQueryDocument, externalIndex) => {
      const currentBoColors: IBoColor = boColors.find((b) => b.name == bo.type);

      return bo.values.map((value, i) => {
        const content = (
          <div
            key={`${externalIndex} ${i}`}
            onClick={() => {
              const filterBo = {};
              filterBo[bo.type] = [
                {
                  id: value.boId,
                  name: value.name,
                },
              ];
              const filter = {filterName: 'document.bOs', filterValue: filterBo};
              props.onAddFilters([filter]);
            }}>
            {FormatBoName(bo.values[i].name, {additionalInfo: value.additionalInfo, searchBox: true})}
          </div>
        );
        return {
          content: content,
          cardColor: currentBoColors ? currentBoColors.cardColor : undefined,
          textColor: currentBoColors ? currentBoColors.textColor : undefined,
          displayName: currentBoColors ? currentBoColors.displayName : undefined,
        };
      });
    })
    .flat();

  const cutShowText = (text: string) => {
    if (!showMore && canSeeShowMore) {
      let blocks = text.split('<span></span>');
      let final = '';
      let i = 0;
      while (final.length < maxCharsBeforeShowMore && i < blocks.length) {
        final += blocks[i];
        i += 1;
      }

      return final + '…';
    }

    return text;
  };

  const setScrollPosition = () => {
    const scrollableContent = document.querySelector(`#${SEARCH_SCROLLABLE_CONTENT_ID}`);
    dispatch(DocumentListAction.setScrollPosition(scrollableContent.scrollTop));
  };

  const onCheckboxChange = (ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
    const isChecked = !!checked;
    setBoxIsChecked(isChecked);
    const activeSearchResults: IDocumentQueryDocument[] = isChecked
      ? [...documentSearch.activeSearchResults, props.item]
      : [...documentSearch.activeSearchResults.filter((x) => x.documentId != props.item.documentId)];
    dispatch(DocumentListAction.setActiveSearchResults(activeSearchResults));
  };

  return (
    <div className={`search-resume-box-wrap ${boxIsChecked ? 'checked' : ''}`}>
      <div className="search-resume-box-checkbox-wrap">
        <Checkbox styles={{checkbox: {borderRadius: '100%'}}} checked={boxIsChecked} onChange={onCheckboxChange} />
      </div>
      <div className={`search-resume-box-card ${boxIsChecked ? 'checked' : ''}`}>
        <div className="search-resume-box-row">
          <div className="search-resume-box-row-item">{resumeData.status}</div>
          <div className="search-resume-box-row-item">
            <div className="search-resume-box-row-item-inner">
              {resumeData.favorite}
            </div>
            <div className="search-resume-box-row-item-inner" style={{cursor: "unset"}}>
              {formatCurrentVersion(itemToUse.versionNumber)}
            </div>
            <div className="search-resume-box-row-item-inner">
              <div className="search-resume-box-data-value">{resumeData.documentSubType}</div>
            </div>
            {resumeData.attachments.length > 0 && (
              <div className="search-resume-box-row-item-inner">
                <AttachmentSmallBrowserButton
                  explicitDocumentId={itemToUse.documentId}
                  explicitAttachmentList={resumeData.attachments ?? []}
                />
              </div>
            )}
            <div className="search-resume-box-row-item-inner">
              {Icons.getIcon('Calendar')}
              <div className="search-resume-box-data-value">{resumeData.date}</div>
            </div>
            <div className="search-resume-box-row-item-inner">
              {Icons.getIcon('Library')}
              <div className="search-resume-box-data-value">{resumeData.area}</div>
            </div>
            <div className="search-resume-box-row-item-inner">
              {Icons.getIcon('UserFollowed')}
              <div className="search-resume-box-data-value">{resumeData.author}</div>
            </div>
          </div>
        </div>
        <div className="search-resume-box-row">
          <div className="search-resume-box-row-item">
            <div className="search-resume-box-row-item">{resumeData.icon}</div>
            <div className="search-resume-box-row-item file-linkname">
              <Link
                onClick={setScrollPosition}
                to={
                  AppRoutes.DOCUMENT_MANAGEMENT +
                  '?documentid=' +
                  itemToUse.documentId.toString() +
                  '&backSearch=' +
                  encodeURI(window.location.href.replace(window.location.origin, ''))
                }>
                {resumeData.documentName}
              </Link>
            </div>
          </div>
          <div className="search-resume-box-row-item-overflow">
            {bOsNames.map((x: IBOsVisualize, i: number) => {
              return (
                <div
                  className="search-resume-box-row-item-inner-minor search-resume-bo-slot"
                  key={i}
                  style={{backgroundColor: x.cardColor, color: x.textColor}}
                  title={x.displayName}>
                  {x.content}
                </div>
              );
            })}
          </div>
        </div>
        <div className="search-resume-box-row">
          <div style={{width: '100%'}}>
            <div
              style={
                !showMore && canSeeShowMore
                  ? {
                      width: '100%',
                      overflow: 'hidden',
                      maxHeight: '4em',
                    }
                  : {width: '100%'}
              }>
              {textToJSX('[…] ' + cutShowText(showText) + ' […]')}
            </div>
            {canSeeShowMore && showMore && (
              <div
                onClick={() => {
                  setShowMore(false);
                }}
                className="search-resume-show-more-label">
                Show less
              </div>
            )}
            {canSeeShowMore && !showMore && (
              <div
                onClick={() => {
                  setShowMore(true);
                }}
                className="search-resume-show-more-label">
                Show more
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentSearchResumeBox;
