import {ILibrary} from '../../Models/ILibrary';
import { ICountryAdmin } from '../../Models/IUser';
import {NewItem} from '../../Models/New';

interface ILibraryTab {
  selectedLibrary: ILibrary;
}

interface INewsTab {
  selectedNews: NewItem[];
  showAddModal: boolean;
  showEditModal: boolean;
  showDeleteModal: boolean;
  addNewItem: NewItem;
  editNewItem: NewItem;
  unselectAllNews: boolean;
}

export interface AdminState {
  libraryTab: ILibraryTab;
  newsTab: INewsTab;
  countries: ICountryAdmin[];
}

export const DEFAULT_ADMIN_STATE: AdminState = {
  libraryTab: {
    selectedLibrary: undefined,
  } as ILibraryTab,
  newsTab: {
    selectedNews: [],
    showAddModal: false,
    showEditModal: false,
    showDeleteModal: false,
    addNewItem: {
      id: '',
      title: '',
      description: '',
      insertDate: new Date(),
      dateRange: {
        from: null,
        to: null,
      },
      severity: -1,
    },
    editNewItem: {
      id: '',
      title: '',
      description: '',
      insertDate: new Date(),
      dateRange: {
        from: null,
        to: null,
      },
      severity: -1,
    },
    unselectAllNews: false,
  } as INewsTab,
  countries: [],
};
