import {AuthenticationHelper, Banner, LabelButton, ModalDialog, SharedState} from '@Eni/docware-fe-master';
import {Link, Spinner} from '@fluentui/react';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import AppRoutesMap, {currentLocationIsAvailableWithoutAuth, IAppRoute} from './AppRoutes';
import DriveUserMenu from './Components/DriveUserMenu/DriveUserMenu';
import ToolBar from './Components/ToolBar/ToolBar';
import {LibraryFunctions} from './Models/ILibrary';
import {IMassiveImportOption, IPermissionRecap, IUser} from './Models/IUser';
import {IWorkflowItem, WorkflowSignatureAction} from './Models/IWorkflowItem';
import FullPageMessage, {setPageContentState} from './Pages/FullPageMessage/FullPageMessage';
import {GenericActions} from './Reducers/Generic/GenericAction';
import {GlobalState} from './Reducers/RootReducer';
import {UserActions} from './Reducers/User/UserAction';
import {IAPIResponse} from './Services/AjaxService';
import ApiService from './Services/ApiService';
import AppRoutes from './Utils/AppRoutes';
import {IDocumentLock} from './Models/IDocument';
import {NewDocumentAction} from './Reducers/NewDocument/NewDocumentActions';
import DocumentEditLockController from './Services/Controllers/DocumentEditLockController';

export const enhanceAppTitle = (title: string | null) => {
  let futureTitle = 'Docware';
  if (title) {
    futureTitle = 'Docware - ' + title;
  }
  if (document.title !== futureTitle) {
    document.title = futureTitle;
  }
};

const getAppBuildNumber = (): string | null => {
  return localStorage.getItem('known-version');
};

const testPageShouldUpdate = (appBuildNumber: string) => {
  let shouldReload = false;
  const currentVersion = localStorage.getItem('known-version');
  if (currentVersion) {
    shouldReload = currentVersion != appBuildNumber;
  } else {
    localStorage.setItem('known-version', appBuildNumber);
  }

  if (shouldReload) {
    localStorage.setItem('known-version', appBuildNumber);
    console.warn("[testPageShouldUpdate] Reloading the page");
    (window.location as any).reload(true);
  }
};

let impTimeoutSupp_ = 1;
export const AppLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appInsight = useAppInsightsContext();
  const currentUser = useSelector((state: GlobalState) => state.user.currentUser);
  const waitingLogIn = useSelector((state: GlobalState) => state.generic.waitingLogIn);
  const documentLock = useSelector<GlobalState, IDocumentLock>((state) => state.newDocument.documentLock);
  const [showLogoutMessage, setShowLogoutMessage] = useState<boolean>(false);
  const [appBuildNumber, setAppBuildNumber] = useState<string | null>(getAppBuildNumber());
  const [badError, setBadError] = useState<boolean>(false);
  const [notEnabledUser, setNotEnabledUserInner] = useState<boolean>(false);
  const [impTimeout, setImpTimeout] = useState<number>(0);
  const [trashBinFullModal, setTrashBinFullModal] = useState<boolean>(false);

  // Impersonation timeout
  useEffect(() => {
    if (currentUser) {
      if (impTimeoutSupp_ <= 0) {
        let old = localStorage.getItem('original-user');
        if (old) {
          setUser(JSON.parse(old));
          impTimeoutSupp_ = 1;
        }
      } else {
        setTimeout(() => {
          impTimeoutSupp_ = impTimeoutSupp_ - 1;
          setImpTimeout(impTimeoutSupp_);
        }, 1000);
      }
    }
  }, [impTimeout]);

  const startImpersonationTimeout = (impersonation: any) => {
    if (impersonation) {
      let timeOut = impersonation.timeOut;
      let timeOutDate = new Date(timeOut);
      let timeOutMs = timeOutDate.getTime();
      let nowMs = new Date().getTime();
      impTimeoutSupp_ = (timeOutMs - nowMs) / 1000;
      setImpTimeout(impTimeoutSupp_);
    }
  };

  const doImpersonate = (userId: string) => {
    ApiService.UsersController.adminImpersonate(userId, (response: IAPIResponse) => {
      if (response.error === null) {
        // store original infos
        localStorage.setItem('original-user', localStorage.getItem('logged-user'));
        setPageContentState({
          title: 'Impersonated user!',
          subtitle: 'You are impersonating... ',
          imageName: 'signedDocumentEnd',
          button: {
            icon: 'ChromeBackMirrored',
            text: 'Go back to Home',
            onClick: {
              type: 'navigate',
              arguments: AppRoutes.HOME_ROUTE,
            },
          },
        });
        /** goto the MESSAGE_PAGE. this must happen after calling setPageContentState */
        navigate(AppRoutes.MESSAGE_PAGE);
        const user = response.payload;
        setUser(user);
      }
    });
  };
  (window as any)['doImpersonate'] = doImpersonate;

  function goToPage(page: string){
    navigate(page)
  }
  (window as any)['goToPage'] = goToPage;

  const setNotEnabledUser = async () => {
    const response: IAPIResponse = await ApiService.UsersController.getPermissionRecap();
    if (response.error === null) {
      setNotEnabledUserInner(true);
      localStorage.setItem('user-not-enabled', 'true');
      const recap: IPermissionRecap = response.payload;
      const user = {
        id: recap.userId,
        firstName: recap.firstName,
        lastName: recap.lastName,
        email: recap.email,
        isEnabled: false,
        creationDate: '',
        status: 0,
        isSuperAdmin: false,
        lastLogin: '',
        professionalAreas: [],
        libraries: [],
        securityUserGroups: [],
        countries: [],
        elementTypes: [],
        massiveImportOption: IMassiveImportOption.Undefined,
        UserLibraries: [],
        UserProfAreas: [],
      };
      setUser(user);
      navigate(AppRoutes.USER_INFO);
    } else {
      setBadError(true);
    }
  };


  useEffect(() => {
    // If the user is not enabled redirect them only to the approved routes
    if (localStorage.getItem('user-not-enabled') === 'true' && !currentLocationIsAvailableWithoutAuth(location.pathname)) {
      navigate(AppRoutes.USER_INFO);
    }
    if (currentUser) {
      const pageName = window.location.pathname;

      /** application insight pageViews */
      appInsight.trackPageView({
        name: pageName,
        uri: window.location.href,
        properties: {
          UserId: currentUser.id,
          UserName: currentUser.firstName + ' ' + currentUser.lastName,
          UserLibraries: currentUser.UserLibraries,
          UserProfAreas: currentUser.UserProfAreas,
        },
      });

      enhanceAppTitle(null);

      /** API populate the alarm icon */
      ApiService.WorkflowController.getWorkflowHistory(1000, WorkflowSignatureAction.notSigned, (response: IAPIResponse) => {
        let countShow = 0;
        if (response.error == null && currentUser) {
          let activities: IWorkflowItem[] = response.payload;
          countShow = activities.length;
          dispatch(GenericActions.SetTasksBackground(activities));
        }
        let event: CustomEvent = new CustomEvent('activity-count-for-bell', {
          detail: {count: countShow},
        });
        document.dispatchEvent(event);
      });

      if (!location.pathname.startsWith(AppRoutes.DOCUMENT_CREATION_ROUTE) && documentLock) {
        DocumentEditLockController.unlock(documentLock.documentId);
        clearInterval(documentLock.intervalID);
        dispatch(NewDocumentAction.setDocumentLock(undefined as IDocumentLock));
      }
    }
  }, [location]);


  useEffect(() => {
    // Add all auth event listeners
    // Run postLoginProcess every time we refresh the user log in
    window.document.addEventListener('trashBinFullModal', () => setTrashBinFullModal(true));
    if (localStorage.getItem('do-not-start-the-login') === 'true') {
      /** define with this call what will be visible in the MESSAGE_PAGE */
      setPageContentState({
        title: 'Logged out!',
        subtitle: 'Is a good idea to close all docware tabs to complete the logout process.',
        imageName: 'logoutDog',
        button: null,
      });

      dispatch(GenericActions.setWaitingLogIn(false));
      setShowLogoutMessage(true);

      localStorage.removeItem('do-not-start-the-login');
    } else {
      console.debug('[auth] Use Effecting start login');
      /** API call to get input infos */
      ApiService.InputInfoController.getInputInfo((response: IAPIResponse) => {
        if (response.error == null) {
          dispatch(GenericActions.setInputInfos(response.payload));
        } else {
          dispatch(GenericActions.setInputInfos([]));
        }
      });
      SharedState.authStatus.subscribe((status) => {
        console.debug('[auth] SharedState.authStatus subscribe. status: ', status);
        if (status.error) {
          failedLoginProcess();
          return;
        }
        if (status.accessToken) {
          postLoginProcess();
        } else {
          if (!status.pending) {
            startLoginProcedure();
          }
        }
      });
    }

    return () => {
      window.document.removeEventListener('trashBinFullModal', () => setTrashBinFullModal(true));
    };
  }, []);

  /**
   * Set up everything and start the logic procedure
   */
  function startLoginProcedure() {
    console.debug('[auth] [startLoginProcedure]');
    dispatch(GenericActions.setWaitingLogIn(true));
    setBadError(false);
    AuthenticationHelper.startLoginRoutine().catch((e) => {
      console.error(e);
      failedLoginProcess();
    });
  }

  /**
   * What should happen when the login procedure succeeds
   */
  const postLoginProcess = async () => {
    console.debug('[auth] [postLoginProcess]');
    dispatch(GenericActions.setWaitingLogIn(true));

    const [userInfoRes, userByIdRes, userLibrariesRes, newDocUserlibrariesRes, allLibrariesRes] = await Promise.all([
      ApiService.AuthenticationController.getUserInfo(),
      ApiService.UsersController.getUserById(null, true),
      ApiService.UsersController.getLibraries(),
      ApiService.LibraryController.getLibraries(),
      ApiService.LibraryController.getAllCleanLibraries(),
    ]);

    if (userInfoRes.raw.status === 403 || userByIdRes.raw.status === 403 || userLibrariesRes.raw.status === 403) {
      setNotEnabledUser();
      return;
    }

    if (
      userInfoRes.error === null &&
      userInfoRes.payload !== null &&
      userByIdRes.error === null &&
      userByIdRes.payload !== null &&
      userLibrariesRes.error === null &&
      userLibrariesRes.payload !== null &&
      newDocUserlibrariesRes.error == null &&
      allLibrariesRes.error == null
    ) {
      dispatch(NewDocumentAction.setUserLibraries(newDocUserlibrariesRes.payload))
      dispatch(GenericActions.setAllCleanLibraries(allLibrariesRes.payload))
      let user = userInfoRes.payload;

      user['id'] = userByIdRes.payload['id'];
      user['firstName'] = userByIdRes.payload['firstName'];
      user['lastName'] = userByIdRes.payload['lastName'];
      user['email'] = userByIdRes.payload['email'];
      user['isSuperAdmin'] = userByIdRes.payload['isSuperAdmin'];
      user['massiveImportOption'] = userByIdRes.payload['massiveImportOption'];
      user['UserProfAreas'] = userByIdRes.payload['professionalAreas'];

      let libraries: object[] = [];
      let userLibraries: string[] = [];
      for (const library of userLibrariesRes.payload) {
        libraries.push({name: library['name'], profAreas: library['professionalAreas']});
      }

      for (const library of libraries) {
        for (const profArea of library['profAreas']) {
          if (user['UserProfAreas'].includes(profArea.id)) {
            userLibraries.push(library['name']);
            break;
          }
        }
      }
      user['UserLibraries'] = userLibraries;

      console.debug('[auth] [postLoginProcess] updating current user');
      setUser(user);
      SharedState.loggedUser?.next({
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName
      });

      ApiService.IntegrationsController.getIntegrationHealthCheck((response: IAPIResponse) => {
        if (response.error == null && !response.payload.ok) {
          setTimeout(() => {
            let event: CustomEvent = new CustomEvent('api-toast-result', {
              detail: {text: response.payload.message, type: 'longError'},
            });
            document.dispatchEvent(event);
          }, 4000);
        }
      });
      localStorage.removeItem('user-not-enabled');
      // in this case the GenericActions.setWaitingLogIn to false is set by processUser function called by setUser
    } else {
      failedLoginProcess();
    }
  };

  /**
   * What should happen when the login procedure fails
   */
  function failedLoginProcess() {
    console.error('[Auth] [failedLoginProcess] Failed login process');
    dispatch(UserActions.setUser(null));
    dispatch(GenericActions.setWaitingLogIn(false));
    setBadError(true);
  }

  /**
   * Process the user and add some information in the redux
   */
  async function processUser(user) {
    console.debug('[auth] [processUser] {start}. user: ', user, 'notEnabledUser: ', notEnabledUser);
    if (!notEnabledUser) {
      dispatch(GenericActions.setWaitingLogIn(true));
      const [
        globalVariablesRes,
        videoTutorialRes,
        librarySearchRes,
        librariesWhereUserCanAccess,
        userPermissionsRes,
        featuresFlagsRes,
      ] = await Promise.all([
        ApiService.GlobalVariablesController.getAppVersion(),
        ApiService.VideoTutorialController.getVideosTutorial(),
        ApiService.LibraryController.getLibrariesSearch(),
        ApiService.UsersController.librariesWhereUserCanSearch(),
        ApiService.UsersController.getPermissionByAction(),
        ApiService.FeaturesFlagsController.getFeaturesFlags(),
      ]);

      if (globalVariablesRes.error == null) {
        const appVersion = globalVariablesRes.payload ? globalVariablesRes.payload : '0';
        setAppBuildNumber(appVersion);
        testPageShouldUpdate(appVersion);
      }

      if (videoTutorialRes.error == null) {
        dispatch(GenericActions.setVideosTutorial(videoTutorialRes.payload));
      }

      if (librarySearchRes.error == null) {
        dispatch(GenericActions.setLibraryHierarchy(librarySearchRes.payload));
        const bosColors = LibraryFunctions.convertHierarchyLibraryToIbOsColor(librarySearchRes.payload);
        dispatch(GenericActions.setBoColors(bosColors));
      }

      if (librariesWhereUserCanAccess.error == null) {
        dispatch(GenericActions.setLibraryHierarchyWhereUserCanSearch(librariesWhereUserCanAccess.payload));
      }

      if (userPermissionsRes.error == null) {
        startImpersonationTimeout(userPermissionsRes.payload.impersonation);
        dispatch(UserActions.setPermissionsByAction(userPermissionsRes.payload));
      }

      if (featuresFlagsRes.error == null) {
        dispatch(GenericActions.setFeatureFlags(featuresFlagsRes.payload));
      }
      dispatch(GenericActions.setWaitingLogIn(false));
    }
    console.debug('[auth] [processUser] {end}');
  }

  /**
   * Set the user in the redux and process it
   */
  function setUser(user: IUser) {
    dispatch(UserActions.setUser(user));
    processUser(user).catch((e) => {
      console.error(e);
      failedLoginProcess();
    });
  }

  return (
    <div>
      <div className="docware-drive-fe">
        <ModalDialog
          enableModal={trashBinFullModal}
          modalTitle={'WARNING: Your trash bin is full!'}
          modalMessage={'You cannot delete this file because your trash bin is full. Please, empty your trash bin.'}
          onAbort={() => {
            setTrashBinFullModal(false);
          }}
          modalButtons={[
            {
              label: 'Cancel',
              onClick: () => {
                setTrashBinFullModal(false);
              },
            },
          ]}
        />

        {localStorage.getItem('original-user') !== null && impTimeout > 0 && (
          <div className="fixed-low-corner-info">Imp. timeout: {new Date(impTimeout * 1000).toISOString().slice(11, 19)}</div>
        )}
        <div>
          {badError && (
            <div style={{padding: '4em', marginTop: '20vh', background: 'transparent'}}>
              <Banner
                message={
                  <>
                    Your session could not be started. Please, retry later.
                    {window !== window.parent && (
                      // if we're inside teams
                      <>
                        {' '}
                        You can still use the{' '}
                        <Link href={'https://drive.docware.eni.com/spud-letter'} target={'_blank'}>
                          Web Version
                        </Link>{' '}
                        of the App.
                      </>
                    )}
                  </>
                }
                type="error"
                enabled={true}
              />
              <br />
              <LabelButton text="Retry" onClick={startLoginProcedure} />
            </div>
          )}
          {!badError && (
            <div>
              {showLogoutMessage && <FullPageMessage />}
              {!showLogoutMessage && (
                <div>
                  {waitingLogIn && (
                    <div
                      style={{
                        height: '70vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        fontSize: '2em',
                        fontWeight: '600',
                      }}>
                      <div>
                        Working with Eni login...
                        <br />
                        <br />
                        <Spinner label="Please wait..." />
                      </div>
                    </div>
                  )}
                  {!waitingLogIn && currentUser != null && (
                    <div>
                      <ToolBar />
                      <DriveUserMenu />
                      {notEnabledUser && (
                        <div>
                          <Routes>
                            {AppRoutesMap.filter((x: IAppRoute) => x.requiresEnabled === false).map((x, i) => {
                              return <Route key={i} path={x.path} element={x.element} />;
                            })}
                          </Routes>
                        </div>
                      )}
                      {!notEnabledUser && (
                        <div>
                          <Routes>
                            {AppRoutesMap.map((x, i) => {
                              return <Route key={i} path={x.path} element={x.element} />;
                            })}
                          </Routes>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="abs-version-label">{appBuildNumber?.padStart(4, '0')}</div>
    </div>
  );
};
