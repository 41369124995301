import {Banner, FileList as FileListC, LabelButton, MenuTabs, ModalDialog} from '@Eni/docware-fe-master';
import './NewLibProfessionalAreas.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ILibraryNewLib, IProfessionalArea, LibraryFunctions} from '../../../Models/ILibrary';
import {SelectionMode} from '@fluentui/react';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import {getItemWithDefaultsValues, ModalMode} from '../NewLibPage';
import NavButtons from '../../../Components/NavButtons/NavButtons';

interface NewLibProfessionalAreasProps {
  currentLibrary: ILibraryNewLib;
  updateLibrary: Function;
  goToPage: Function;
  selectedProfessionalArea: IProfessionalArea;
  setSelectedProfessionalArea: Dispatch<SetStateAction<IProfessionalArea>>;
  showEditModal: boolean;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  existingProfessionalAreas: string[];
}

const NewLibProfessionalAreas = (props: NewLibProfessionalAreasProps) => {
  const navigate = useNavigate();
  const [newProfessionalArea, setNewProfessionalArea] = useState<IProfessionalArea>();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'General Info',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Professional Areas',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_PROFESSIONAL_AREAS);
            },
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Element Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Document Types',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Document Subtypes',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_DOCUMENT_SUB_TYPES);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Business Object',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_BUSINESS_OBJECT);
            },
            isPassed: false,
            isEvident: false,
          },
          {
            label: 'Extra metadata',
            callback: () => {
              props.goToPage(AppRoutes.NEW_LIB_PAGE_EXTRA_METADATA);
            },
            isPassed: false,
            isEvident: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      <div className="new-lib-right-section-content-container">
        <LabelButton
          text="Add"
          icon="Add"
          onClick={() => {
            setShowCreateModal(true);
          }}
        />
        <FileListC
          defaultSortOnThisColumnIndex={0}
          fileIconFromField={''}
          onItemsSelected={(selectedRows: IProfessionalArea[]) => {
            if (selectedRows.length === 0) {
              props.setSelectedProfessionalArea(undefined);
            }
            if (selectedRows.length === 1) {
              props.setSelectedProfessionalArea(selectedRows[0]);
            }
          }}
          currentSelectedItems={undefined}
          currentSelectedItem={props.selectedProfessionalArea}
          hideBottomButton={true}
          selectionMode={SelectionMode.single}
          tableName={'normal'}
          columns={[
            {
              name: 'Name',
              dataType: 'string',
              fieldName: 'name',
              iconName: null,
              size: 'large',
            },
            {
              name: 'User Management Enabled',
              dataType: 'string',
              fieldName: 'userManagementEnabled',
              iconName: null,
              size: 'large',
              onRender: (item: IProfessionalArea) => {
                return item.userManagementEnabled?.toString();
              },
            },
            {
              name: 'Is Active',
              dataType: 'boolean',
              fieldName: 'isActive',
              iconName: null,
              size: 'large',
              onRender: (item: IProfessionalArea) => {
                return item.isActive?.toString();
              },
            },
          ]}
          items={props.currentLibrary?.professionalAreas ?? []}
        />
      </div>

      <NavButtons
        onBackClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_GENERAL_INFO)}
        onNextClick={() => props.goToPage(AppRoutes.NEW_LIB_PAGE_ELEMENT_TYPES)}
      />

      <ModalProfessionalArea
        mode={ModalMode.Edit}
        professionalArea={props.selectedProfessionalArea}
        setProfessionalArea={props.setSelectedProfessionalArea}
        showModal={props.showEditModal}
        setShowModal={props.setShowEditModal}
        existingProfessionalAreas={props.existingProfessionalAreas}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const updatedProfessionalAreas = props.currentLibrary?.professionalAreas.map((x) =>
            x.id == props.selectedProfessionalArea.id ? props.selectedProfessionalArea : x
          );
          props.updateLibrary('professionalAreas', updatedProfessionalAreas);
        }}
      />

      <ModalProfessionalArea
        mode={ModalMode.Create}
        professionalArea={newProfessionalArea}
        setProfessionalArea={setNewProfessionalArea}
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        existingProfessionalAreas={props.existingProfessionalAreas}
        currentLibrary={props.currentLibrary}
        onConfirm={() => {
          const emptyProfessionalArea: IProfessionalArea = {
            id: crypto.randomUUID(),
            name: '',
            userManagementEnabled: false,
            isActive: false,
            addedInThisSession: true,
          };
          const professionalArea = getItemWithDefaultsValues(emptyProfessionalArea, newProfessionalArea);

          props.updateLibrary('professionalAreas', [...(props.currentLibrary?.professionalAreas ?? []), professionalArea]);
          setNewProfessionalArea(undefined);
        }}
      />
    </>
  );
};

interface ModalProfessionalAreaProps {
  mode: ModalMode;
  professionalArea: IProfessionalArea;
  setProfessionalArea: Dispatch<SetStateAction<IProfessionalArea>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  existingProfessionalAreas: string[];
  currentLibrary: ILibraryNewLib;
  onConfirm: Function;
}

const ModalProfessionalArea = (props: ModalProfessionalAreaProps) => {
  const fieldDisabled = props.mode == ModalMode.Edit && !props.professionalArea?.addedInThisSession;
  const [showErrorBanner, setShowErrorBanner] = useState<boolean>(false);

  return (
    <ModalDialog
      modalTitle={`${props.mode == ModalMode.Create ? 'Create' : 'Edit'} Professional Area`}
      modalInnerComponent={
        <>
          <FluentUIDecorator
            label="Name"
            info={getInputInfos('new-lib', 'professional-area-name')}
            fluentComponent={FluentUIDecoratorTypes.TextField({
              disabled: fieldDisabled,
              value: props.professionalArea?.name,
              onChange: (e, currentValue) => props.setProfessionalArea({...props.professionalArea, name: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="User Management Enabled"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'professional-area-user-management-enabled')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              disabled: fieldDisabled,
              defaultChecked: props.professionalArea?.userManagementEnabled,
              onChange: (e, currentValue) => props.setProfessionalArea({...props.professionalArea, userManagementEnabled: currentValue}),
            })}
          />
          <FluentUIDecorator
            label="Is Active"
            inlineLabel={true}
            info={getInputInfos('new-lib', 'professional-area-is-active')}
            fluentComponent={FluentUIDecoratorTypes.Toggle({
              defaultChecked: props.professionalArea?.isActive,
              onChange: (e, currentValue) => props.setProfessionalArea({...props.professionalArea, isActive: currentValue}),
            })}
          />
          <Banner
            enabled={showErrorBanner}
            type="error"
            message={"The Professional Area must be the unique across all libraries and can't be blank"}
          />
        </>
      }
      modalButtons={[
        {
          label: 'Cancel',
          onClick: () => {
            props.setShowModal(false);
          },
        },
        {
          label: 'Confirm',
          onClick: () => {
            if (props.mode === ModalMode.Create) {
              const profArea = props.professionalArea?.name.replace(/^\s+|\s+$/gm, '');
              const currentLibraryProfAreas = LibraryFunctions.getProfessionalAreasNames([props.currentLibrary]);
              if (!profArea || props.existingProfessionalAreas.includes(profArea) || currentLibraryProfAreas.includes(profArea)) {
                setShowErrorBanner(true);
                return;
              }
            }
            props.onConfirm();
            props.setShowModal(false);
          },
        },
      ]}
      onAbort={() => {
        props.setShowModal(false);
      }}
      enableModal={props.showModal}
    />
  );
};

export default NewLibProfessionalAreas;
