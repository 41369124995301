import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';

import {IDropdownOption} from '@fluentui/react/lib/Dropdown';
import {Dispatch, SetStateAction} from 'react';

interface FluentUIMultiSelectDropdownProps {
  label: string | JSX.Element | null;
  placeholder: string;
  info: {key: string; page: string};
  options: {key: string; text: string}[];
  selectedKeys: string[];
  setSelectedKeys: Dispatch<SetStateAction<string[]>>;
}

const FluentUIMultiSelectDropdown = (props: FluentUIMultiSelectDropdownProps) => {
  const fileStatusDropDownOnChange = (event, item: IDropdownOption) => {
    if (item) {
      // set the keys based on the ui
      props.setSelectedKeys(
        item.selected ? [...props.selectedKeys, item.key as string] : props.selectedKeys.filter((key) => key !== item.key)
      );
    }
  };

  return (
    <FluentUIDecorator
      denyComponentHardReset={true}
      label={props.label}
      info={props.info}
      noLabels={true}
      fluentComponent={FluentUIDecoratorTypes.Dropdown({
        placeholder: props.placeholder,
        multiSelect: true,
        options: props.options,
        selectedKeys: props.selectedKeys,
        onChange: fileStatusDropDownOnChange,
      })}
    />
  );
};
export default FluentUIMultiSelectDropdown;
