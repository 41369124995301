import {useEffect, useState} from 'react';
import AppRoutes, {concatUrl} from '../../../Utils/AppRoutes';
import {useNavigate} from 'react-router-dom';
import {FileList as FileListC, MenuTabs} from '@Eni/docware-fe-master';
import ApiService from '../../../Services/ApiService';
import {IAPIResponse} from '../../../Services/AjaxService';
import {getMassiveImportFileStatus, StatusBubbleGeneric} from '../../../Components/StatusBubbleGeneric/StatusBubbleGeneric';
import {MassiveImportAction} from '../../../Reducers/MassiveImport/MassiveImportActions';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalState} from '../../../Reducers/RootReducer';
import DragAndDrop from '../../../Components/DragAndDrop/DragAndDrop';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import FluentUIMultiSelectDropdown from '../../../Components/FluentUIMultiSelectDropdown/FluentUIMultiSelectDropdown';
import InputFileButton from '../../../Components/InputFileButton/InputFileButton';
import './MassiveImportFiles.scss';
import MassiveImportTableContainer from '../../../Components/MassiveImportTableContainer/MassiveImportTableContainer';
import {IMIFile, IMIFileStatus, MIFileStatusVisualization} from '../../../Models/MassiveImport/IMIFile';
import {Spinner} from '@fluentui/react';
import NavButtons from '../../../Components/NavButtons/NavButtons';
import Icons from '../../../Utils/FabricIconsOutlet';
import {FileSelectArea} from '../../../Components/FileSelectArea/FileSelectArea';

interface MassiveImportFilesProps {}

const MassiveImportFiles = (props: MassiveImportFilesProps) => {
  const navigate = useNavigate();
  const filePage = useSelector((state: GlobalState) => state.massiveImport.filePage);
  const stagingArea = useSelector((state: GlobalState) => state.massiveImport.stagingArea);
  const [fileDisplay, setFileDisplay] = useState<IMIFile[]>(filePage.files);
  const [fileStatusDropDownSelectedKeys, setFileStatusDropDownSelectedKeys] = useState<string[]>([]);
  const [inputButtonDisable, setInputButtonDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MassiveImportAction.setActiveFiles([]));
    ApiService.MassiveImportController.getFiles((response: IAPIResponse) => {
      setLoading(false);
      if (response.error == null) {
        dispatch(MassiveImportAction.setFiles(response.payload));
      }
    });
  }, []);

  useEffect(() => {
    setFileStatusDropDownSelectedKeys([]);
    setFileDisplay(filePage.files);
  }, [filePage.files]);

  const goNext = (route: string) => {
    dispatch(MassiveImportAction.setActiveFiles([]));
    navigate(concatUrl(AppRoutes.MASSIVE_IMPORT_ROUTE, route) + window.location.search);
  };

  const previewFilesInTableAndUpload = async (selectedFiles: File[]) => {
    let filesToUpload: File[] = [];

    // Preview files
    const miFiles: IMIFile[] = [];
    for (const file of selectedFiles) {
      const fileAlreadyExists = filePage.files.filter((x) => x.fileName === file.name).length > 0;
      miFiles.push({
        id: null,
        fileName: file.name,
        documentSize: file.size,
        extension: file.type,
        creationDate: new Date().toISOString(),
        timeToLive: 15,
        status: fileAlreadyExists ? IMIFileStatus.DuplicatedFile : IMIFileStatus.Upload,
        file: file,
      });
      if (!fileAlreadyExists) {
        filesToUpload.push(file);
      }
    }
    dispatch(MassiveImportAction.setFiles(filePage.files.concat(miFiles)));

    // Upload files
    setInputButtonDisable(true);
    for (const file of filesToUpload) {
      await ApiService.MassiveImportController.uploadDocument(file, (response: IAPIResponse) => {
        if (response.error == null) {
          dispatch(MassiveImportAction.updateFile(response.payload.blob));
          dispatch(MassiveImportAction.setStagingArea(response.payload.count));
        } else {
          let currentFile: IMIFile | null = filePage.files.filter((x) => x.fileName === file.name)[0];
          if (currentFile) {
            currentFile = {...currentFile, status: IMIFileStatus.GenericError};
            dispatch(MassiveImportAction.updateFile(currentFile));
          }
        }
      });
    }
    setInputButtonDisable(false);
    if (stagingArea.metadataValidCount > 0 || stagingArea.metadataNotValidCount > 0) {
      dispatch(MassiveImportAction.setNeedRefreshMetadata(true));
    }
  };

  useEffect(() => {
    if (fileStatusDropDownSelectedKeys.length > 0) {
      setFileDisplay(filePage.files.filter((file) => fileStatusDropDownSelectedKeys.includes(file.status.toString())));
    } else {
      setFileDisplay(filePage.files);
    }
  }, [fileStatusDropDownSelectedKeys]);

  return (
    <>
      <MenuTabs
        tabs={[
          {
            label: 'Files',
            callback: () => {},
            isPassed: false,
            isEvident: true,
          },
          {
            label: 'Metadata',
            callback: () => {
              goNext(AppRoutes.MASSIVE_IMPORT_ROUTE_METADATA);
            },
            isEvident: false,
            isPassed: false,
          },
        ]}
        leftText={''}
        rightText={''}
      />
      {loading ? (
        <Spinner label="Loading..." />
      ) : (
        <>
          {inputButtonDisable ? (
            <div className="file-drop">
              <FileSelectArea
                buttonIcon={Icons.getIcon('CloudUpload', '2em')}
                buttonLabel={'Wait the upload completed'}
                dropAreaTitle={'Wait that all the selected files are uploaded before uploading more'}
                disabled={true}
              />
            </div>
          ) : (
            <DragAndDrop
              onDropAndClick={previewFilesInTableAndUpload}
              inputMultiFileSelect={true}
              elementToRender={
                <FileSelectArea
                  buttonIcon={Icons.getIcon('CloudUpload', '2em')}
                  buttonLabel={'Choose documents'}
                  dropAreaTitle={'Drag your documents here or select them'}
                />
              }
            />
          )}
          <MassiveImportTableContainer
            header={
              <>
                <FluentUIMultiSelectDropdown
                  label="File Status"
                  placeholder="Select a file status"
                  info={getInputInfos('massive-import', 'file-status')}
                  options={MIFileStatusVisualization}
                  selectedKeys={fileStatusDropDownSelectedKeys}
                  setSelectedKeys={setFileStatusDropDownSelectedKeys}
                />
              </>
            }
            body={
              <div className="massive-import-files-table">
                <FileListC
                  defaultSortOnThisColumnIndex={2}
                  fileIconFromField={'fileName'}
                  onItemsSelected={(selectedRows: IMIFile[]) => {
                    if (selectedRows.length === 0) {
                      dispatch(MassiveImportAction.setActiveFiles([]));
                    } else {
                      dispatch(MassiveImportAction.setActiveFiles(selectedRows));
                    }
                  }}
                  currentSelectedItems={filePage.activeFiles}
                  currentSelectedItem={undefined}
                  hideBottomButton={true}
                  tableName={'normal'}
                  columns={[
                    {
                      name: 'File Type',
                      dataType: 'string',
                      fieldName: 'name',
                      iconName: 'Page',
                      size: 'tiny',
                    },
                    {
                      name: 'Name',
                      dataType: 'string',
                      fieldName: 'fileName',
                      iconName: null,
                      size: 'large',
                    },
                    {
                      name: 'Date Created',
                      dataType: 'date',
                      fieldName: 'creationDate',
                      iconName: null,
                      size: 'medium',
                    },
                    {
                      name: 'Time to live',
                      dataType: 'number',
                      fieldName: 'timeToLive',
                      iconName: null,
                      size: 'small',
                      onRender: (item: IMIFile) => {
                        return `${item.timeToLive} Days`;
                      },
                    },
                    {
                      name: 'File size',
                      dataType: 'number',
                      fieldName: 'documentSize',
                      iconName: null,
                      size: 'small',
                    },
                    {
                      name: 'Status',
                      dataType: 'string',
                      fieldName: 'documentStatus',
                      iconName: null,
                      size: 'small',
                      onRender: (item: IMIFile) => {
                        return <StatusBubbleGeneric bubbleStatus={getMassiveImportFileStatus(item.status)} />;
                      },
                    },
                  ]}
                  items={fileDisplay}
                />
              </div>
            }
          />
        </>
      )}

      {!loading && <NavButtons onNextClick={() => goNext(AppRoutes.MASSIVE_IMPORT_ROUTE_METADATA)} />}
    </>
  );
};

export default MassiveImportFiles;
