import * as React from 'react';
import {MutableRefObject, useEffect, useRef} from 'react';
import {Breadcrumb, IBreadcrumbItem, IBreadcrumbProps} from '@fluentui/react';

interface BreadcrumbFixedProps {
  fluentBreadcrumbProps: IBreadcrumbProps;
  onClick: (ev, item: IBreadcrumbItem, items: MutableRefObject<IBreadcrumbItem[]>) => any;
}

/*
 * Fix onClick issue on Fluent UI Breadcrumb
 * */
export const BreadcrumbFixed = (props: BreadcrumbFixedProps) => {
  const itemsRef = useRef<IBreadcrumbItem[]>(props.fluentBreadcrumbProps.items);

  useEffect(() => {
    itemsRef.current = props.fluentBreadcrumbProps.items;
  }, [props.fluentBreadcrumbProps.items]);

  return (
    <Breadcrumb
      {...props.fluentBreadcrumbProps}
      items={props.fluentBreadcrumbProps.items.map((item) => {
        return {
          ...item,
          onClick: (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => props.onClick(ev, item, itemsRef),
        };
      })}
    />
  );
};
