import {ITag} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {IMetadataDict, IMetadataValue} from '../../Models/IDocument';
import {ILibrary, IMetadata, MetadataType} from '../../Models/ILibrary';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import {TagUtils} from '../../Utils/TagUtils';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';

interface DynamicMetadataProps {
  values: IMetadataDict;
  outerState: any;
  positioning: number;
  autoIncrementNameFixer: (name: string) => string;
  customDisableMap?: any;
  onChange: (key: string, type: MetadataType, value: IMetadataValue | undefined) => void;
}

export const formatStringNumberPad = (numberStr: string | number | undefined) => {
  if (!numberStr) {
    return undefined;
  }
  numberStr = numberStr.toString();
  return numberStr.padStart(3, '0');
};

const getSelectTagValue = (type: MetadataType, tags: ITag[] | undefined): IMetadataValue | undefined => {
  if (tags == null) return undefined;

  if (type === MetadataType.Select) {
    return TagUtils.tagsToMetadata(tags.slice(-1), type);
  } else if (type === MetadataType.MultiSelect) {
    return TagUtils.tagsToMetadata(tags, type);
  }

  return undefined;
};

const getMatchedTags = (options: ITag[], text: string, selectedItems: ITag[]) => {
  let tags: ITag[] = [];
  let selectedIds: (string | number)[] = selectedItems.map((x: ITag) => {
    return x.key;
  });

  for (let i = 0; i < options.length; i++) {
    if (options[i].name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) !== -1 && selectedIds.indexOf(options[i].key) === -1) {
      tags.push(options[i]);
    }
  }

  return tags;
};

const unknwonValue = (source: any, keyName: string) => {
  if (source) {
    let meta = source[keyName];
    if (meta) {
      return meta === null || meta === undefined || meta === 'undefined';
    }
  }

  return true;
};

export const DynamicMetadata = (props: DynamicMetadataProps) => {
  const library = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);
  const [autoIncrements, setAutoIncrements] = useState<any>({});

  const [toggle, setToggle] = useState<boolean>(false);
  const [params] = useSearchParams();
  const pageMode = params.get('mode');
  const redraw = () => {
    setToggle(!toggle);
  };

  const addAutoIncrement = (key: string, value: string) => {
    let auto = {...autoIncrements};
    auto[key] = value;
    setAutoIncrements(auto);
  };

  const getAutoIncrementForKey = async (key: string) => {
    let response = await ApiService.GlobalVariablesController.getAbsoluteAutoIncrementPreview(key, (response: IAPIResponse) => {});
    if (response.error === null) {
      addAutoIncrement(key, response.payload);
    } else {
      try {
        setTimeout(() => {
          getAutoIncrementForKey(key);
        }, 2000);
      } catch (e) {}
    }
  };

  const getTargetMetas = () => {
    let targetMetas = library.metadata || [];
    return targetMetas.filter(
      (x: IMetadata) => (!x.inputPositioning && props.positioning === 0) || x.inputPositioning === props.positioning
    );
  };

  const init = () => {
    if (!props.values) {
      return;
    }

    let target = getTargetMetas();
    for (let i = 0; i < target.length; i++) {
      if (target[i].type === MetadataType.AutoIncrement) {
        let fixedName: string = props.autoIncrementNameFixer(target[i].name);
        if (fixedName !== '') {
          if (unknwonValue(props.values, target[i].name)) {
            redraw();
            getAutoIncrementForKey(fixedName);
          } else {
            addAutoIncrement(fixedName, props.values[target[i].name].numberValue + '');
          }
        }
      }
    }
  };

  useEffect(() => {
    init();
  }, [library, props.values, props.outerState]);

  const testIsDisabled = (name: string) => {
    if (props.customDisableMap) {
      return props.customDisableMap[name];
    }
    return false;
  };

  let targetMetas = getTargetMetas();

  return (
    <div>
      {targetMetas.length > 0 && props.positioning !== 1 && <hr style={{marginTop: '1em'}} />}
      {targetMetas.map((m, i) => {
        let commonProps = {
          label: m.displayName,
          errorMessage: (window as any)['highlight-errors'] === 1 && m.required ? 'This field is required' : null,
          info: getInputInfos('new-document', m.displayName.replace(' ', '-').toLowerCase()),
          required: m.required,
        };
        let commonClass = 'metadata-input';
        let commonStyle = {};
        if (targetMetas.length % 2 !== 0 && i === targetMetas.length - 1) {
          commonClass += ' metadata-100';
        } else {
          commonClass += ' metadata-split';

          if (i % 2 === 0) {
            commonStyle = {marginRight: '1%'};
          } else {
            commonStyle = {marginLeft: '1%'};
          }
        }

        switch (m.type) {
          case MetadataType.Select:
            return (
              <div className={commonClass} style={commonStyle} key={i}>
                <FluentUIDecorator
                  {...commonProps}
                  fluentComponent={FluentUIDecoratorTypes.Dropdown({
                    options: m.values.map((x, i) => {
                      return {key: x.id, text: x.name};
                    }),
                    disabled: (pageMode === 'newversion' && m.lockedOnNewVersion) || testIsDisabled(m.name),
                    selectedKey:
                      props.values != null && props.values[m.name] != null && props.values[m.name].selectValue
                        ? props.values[m.name].selectValue.id
                        : null,
                    onChange: (e, o) =>
                      props.onChange(m.name, m.type, {
                        type: m.type,
                        selectValue: {id: o.key.toString(), name: o.text},
                      }),
                  })}
                />
              </div>
            );
          case MetadataType.MultiSelect:
            return (
              <div className={commonClass} style={commonStyle} key={i}>
                <FluentUIDecorator
                  {...commonProps}
                  fluentComponent={FluentUIDecoratorTypes.TagPicker({
                    disabled: (pageMode === 'newversion' && m.lockedOnNewVersion) || testIsDisabled(m.name),
                    selectedItems: props.values != null && props.values[m.name] != null ? TagUtils.metadataToTag(props.values[m.name]) : [],
                    onResolveSuggestions: (f, t) => getMatchedTags((m.values || []).map(TagUtils.optionToTag), f, t),
                    onChange: (t) => props.onChange(m.name, m.type, getSelectTagValue(m.type, t)),
                  })}
                />
              </div>
            );
          case MetadataType.Text:
            return (
              <div className={commonClass} style={commonStyle} key={i}>
                <FluentUIDecorator
                  {...commonProps}
                  fluentComponent={FluentUIDecoratorTypes.TextField({
                    disabled: (pageMode === 'newversion' && m.lockedOnNewVersion) || testIsDisabled(m.name),
                    value: props.values != null && props.values[m.name] != null ? props.values[m.name].stringValue : undefined,
                    onChange: (e, t) =>
                      props.onChange(m.name, m.type, {
                        type: m.type,
                        stringValue: t,
                      }),
                  })}
                />
              </div>
            );
          case MetadataType.Date:
            return (
              <div className={commonClass} style={commonStyle} key={i}>
                <FluentUIDecorator
                  {...commonProps}
                  fluentComponent={FluentUIDecoratorTypes.DatePicker({
                    disabled: (pageMode === 'newversion' && m.lockedOnNewVersion) || testIsDisabled(m.name),
                    value: props.values != null && props.values[m.name] != null ? new Date(props.values[m.name].dateValue) : undefined,
                    onSelectDate: (d) =>
                      props.onChange(m.name, m.type, {
                        type: m.type,
                        dateValue: +(d || 0),
                      }),
                  })}
                />
              </div>
            );
          case MetadataType.AutoIncrement:
            let name = props.autoIncrementNameFixer(m.name);
            let value = '';

            if (name !== '') {
              value = autoIncrements[name];

              if (unknwonValue(props.values, m.name) && value !== undefined) {
                props.onChange(m.name, m.type, {
                  type: m.type,
                  numberValue: +value,
                  stringValue: formatStringNumberPad(value),
                });
              }
            }

            return (
              <div className={commonClass} style={commonStyle} key={i}>
                {value !== '' && (
                  <FluentUIDecorator
                    {...commonProps}
                    fluentComponent={FluentUIDecoratorTypes.TextField({
                      disabled: true,
                      value: formatStringNumberPad(value),
                    })}
                  />
                )}
                {value === '' && (
                  <FluentUIDecorator
                    {...commonProps}
                    fluentComponent={FluentUIDecoratorTypes.TextField({
                      disabled: true,
                    })}
                  />
                )}
              </div>
            );
          case MetadataType.TagPickerSingleSelect:
            return (
              <div className={commonClass} style={commonStyle} key={i}>
                <FluentUIDecorator
                  {...commonProps}
                  fluentComponent={FluentUIDecoratorTypes.TagPicker({
                    disabled: (pageMode === 'newversion' && m.lockedOnNewVersion) || testIsDisabled(m.name),
                    onResolveSuggestions: (text, s): ITag[] => {
                      const selectedValues = s.map((x) => x.name.toLowerCase());
                      return m.values
                        .filter((x) => {
                          return x.name.toLowerCase().includes(text.toLowerCase()) && !selectedValues.includes(x.name.toLowerCase());
                        })
                        .map((x) => {
                          return {key: x.id, name: x.name};
                        });
                    },
                    onChange: (selectedValues) => {
                      let value;
                      switch (selectedValues.length) {
                        case 0:
                          value = undefined;
                          break;
                        case 1:
                          value = {
                            type: m.type,
                            selectValue: {id: selectedValues[0].key.toString(), name: selectedValues[0].name},
                          };
                          break;
                        case 2:
                          value = {
                            type: m.type,
                            selectValue: {id: selectedValues[1].key.toString(), name: selectedValues[1].name},
                          };
                          break;
                      }
                      props.onChange(m.name, m.type, value);
                    },
                    selectedItems:
                      props.values != null && props.values[m.name] != null && props.values[m.name].selectValue
                        ? [TagUtils.optionToTag(props.values[m.name].selectValue)]
                        : null,
                  })}
                />
              </div>
            );
        }
      })}
      {targetMetas.length > 0 && props.positioning === 1 && <hr style={{marginTop: '1em'}} />}
    </div>
  );
};
