import {IBoLibrary, ILibrary, IProfessionalArea} from '../../../Models/ILibrary';
export const getDocumentTypePerSubtype = (optionsMap: any, documentSubTypeId: string) => {
  const relatedDocType: {key: string; text: string}[] = optionsMap.documentTypesForSubtypes.filter(
    (x: {key: string; text: string}) => x.key === documentSubTypeId
  );

  const relatedDocTypeMatch: string = relatedDocType.length > 0 ? relatedDocType[0].text : '?';
  return relatedDocTypeMatch;
}
const getLibFromProfArea = (libraries: ILibrary[], profAreaId: string): ILibrary | undefined => {
  return libraries.find(l => l.professionalAreas.find(pa => pa.id == profAreaId))
}


export const getMandatoryBosForProfessionalArea = (libraries: ILibrary[], profAreaId: string) => {
  const libraryWithProfArea = getLibFromProfArea(libraries, profAreaId)
  if(libraryWithProfArea){
    return libraryWithProfArea.bOs.map((x: IBoLibrary) => {
      return {text: x.displayName, key: x.source};
    });
  } else {
    return []
  }
}


export const getMandatoryMasterDataForProfessionalArea = (libraries: ILibrary[], profAreaId: string) => {
  const libraryWithProfArea = getLibFromProfArea(libraries, profAreaId)
  if(libraryWithProfArea){
    return libraryWithProfArea.masterDatas
    .filter(x => ["xware_seismic_survey", "xware_seismic_acquisition"].includes(x.source)) // take only seismic
    .map((x: IBoLibrary) => {
      return {text: x.displayName, key: x.source};
    });
  } else {
    return []
  }
}