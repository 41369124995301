import {MASSIVE_IMPORT_URLS} from '../../Constants/Urls';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from './../AjaxService';

const MassiveImportController = {
  clearUserStagingArea: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.CLEAR_USER_STAGING_AREA}`;
    request.successMessage = 'Staging area cleared';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getFiles: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.GET_BLOBS_DOCUMENTS}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getStagingAreaCounts: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.GET_STAGING_AREA_COUNT}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  uploadDocument: async (file: File, callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.UPLOAD_DOCUMENT}`;
    request.method = 'POST';
    request.payload = formData;
    request.ignoreDefaultHeaders = true;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  deleteFiles: async (fileNames: string[], callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    const body = {
      fileNames: fileNames,
    };
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.DELETE_FILES}`;
    request.method = 'DELETE';
    request.payload = body;
    request.successMessage = 'Files deleted';

    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  uploadMetadataExcel: async (file: File, callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let formData = new FormData();
    formData.append('file', file, file.name);
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.UPLOAD_METADATA_EXCEL}`;
    request.method = 'POST';
    request.payload = formData;
    request.ignoreDefaultHeaders = true;
    request.dontStringify = true;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getMetadata: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.GET_METADATA}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  triggerValidation: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.TRIGGER_VALIDATION}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  saveStagingArea: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.SAVE_STAGING_AREA}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  metadataUploadStatus: async (callback?: (response: IAPIResponse) => void): Promise<IAPIResponse> => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${MASSIVE_IMPORT_URLS.METADATA_UPLOAD_STATUS}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default MassiveImportController;
