import {LabelButton} from '@Eni/docware-fe-master';
import {SelectionMode, Spinner, Toggle} from '@fluentui/react';
import {ReactNode, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {BOsObject, BusinessObjectHistory} from '../../Models/BusinessObject';
import {IBOAggregated} from '../../Models/ILibrary';
import {IValue} from '../../Models/IValue';
import {FormatBoName} from '../../Pages/DocumentSearchPage/DocumentSearchPage';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../FluentUIDecorator/FluentUIDecorator';
import GenericList from '../GenericList/GenericList';
import './AggregatedBosHistorySearch.scss';

export interface AggregatedBosHistorySearchProps {
  aggregation: IBOAggregated;
  emit?: (item: BOsObject) => void;
  prefilled: any;
  countries: string[];
}

interface AggregatedBOSuggestion {
  boValue: ReactNode;
  activity: string;
  virtualProject: string;
  metadata: any;
  compositeKey: string;
}

export const getBoMeta = (id: string) => {
  let obj: any = localStorage.getItem('preselected-aggregated-bo');
  if (obj) {
    obj = JSON.parse(obj);
  } else {
    obj = {};
  }
  if (obj[id]) {
    return obj[id];
  } else {
    return null;
  }
};

const setBoLabel = (id: string, value: string) => {
  let obj: any = localStorage.getItem('preselected-aggregated-bo');
  if (obj) {
    obj = JSON.parse(obj);
  } else {
    obj = {};
  }
  obj[id] = value;
  localStorage.setItem('preselected-aggregated-bo', JSON.stringify(obj));
};

const AggregatedBosHistorySearch = (props: AggregatedBosHistorySearchProps) => {
  const [userHistory, setUserHistory] = useState<BusinessObjectHistory[]>([]);
  const [prefilled, setPrefilled] = useState<{id: string; name: string}[]>([]);
  const [aggregation, setAggregation] = useState<IBOAggregated | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>('');
  const [boSuggestions, setSearchSuggestions] = useState<AggregatedBOSuggestion[]>([]);

  function getColumns(){
    const integrationId = aggregation?.source;
    switch(integrationId){
      case "xware_activities":
        return ([
          {
            name: 'Activity',
            dataType: 'string',
            fieldName: 'activity',
            iconName: null,
            size: 'large',
          },
        ])
      case "xware_virtual_projects":
        return ([
          {
            name: 'Virtual Project',
            dataType: 'string',
            fieldName: 'virtualProject',
            iconName: null,
            size: 'large',
          },
        ])
      default:
        return ([
          {
            name: 'BO Value',
            dataType: 'string',
            fieldName: 'boValue',
            iconName: null,
            size: 'large',
          },
        ])
    }
  }

  const columns = getColumns();

  useEffect(() => {
    if (props.prefilled && props.prefilled.length > 0) {
      setPrefilled(props.prefilled);
    }
    setAggregation(props.aggregation);

    ApiService.BusinessObjectHistoryController.getHistory((response: IAPIResponse) => {
      if (response.error === null) {
        setUserHistory(response.payload);
      }
      setLoading(false);
    });
  }, []);

  const validateSearch = (text: string) => {
    return text.match(new RegExp(aggregation.searchTextPattern, 'g')) !== null;
  };

  const performSearch = () => {
    if (!validateSearch(searchText) || searchText == null || searchText.length < 3) {
      setSearchSuggestions([]);
    } else {
      const queryObject = {
        integrationId: aggregation.source,
        search: searchText,
        limit: 20,
        offset: 0,
        queryColumn: 'name',
        countryCodes: (props.countries || []).join(';'),
        allResults: true,
      };
      ApiService.IntegrationsController.getBOValues(queryObject, (response: IAPIResponse) => {
        if (response.error == null) {
          const boList: any[] = response.payload;
          
          let tags: AggregatedBOSuggestion[] = boList.map((x: any) => {
            return {
              boValue: FormatBoName(x.compositeKey, x.metadata),
              activity: `${x?.metadata?.study_id}|${x?.metadata?.study_name}|${x?.metadata?.activity_id}|${x?.metadata?.activity_name}`,
              virtualProject: `${x?.id}|${x?.name}`,
              compositeKey: x.compositeKey,
              metadata: x.metadata,
            };
          });

          setSearchSuggestions(tags);
        } else {
          setSearchSuggestions([]);
        }
      });
    }
  };

  useEffect(() => {
    if (aggregation) {
      performSearch();
    }
  }, [searchText]);

  return (
    <div className="aggregated-bos-history-search-main-wrap">
      {loading && <Spinner label="Loading..." labelPosition="right" />}
      {!loading && (
        <div>
          {prefilled.length > 0 && (
            <div>
              <div className="aggregated-bos-history-search-title">{aggregation.searchTitle}</div>
              <div className="aggregated-bos-history-search-separtor"></div>
              <div>
                <div className="aggregated-bos-history-search-prefilled-title">You selected the following BO aggregations:</div>
                {prefilled.map((x: {id: string; name: string}, i: number) => {
                  return (
                    <div className="aggregated-bos-history-search-prefilled-item" key={i}>
                      {FormatBoName(x.id, getBoMeta(x.id))}
                    </div>
                  );
                })}
              </div>
              <div style={{marginTop: '1em', display: 'flex', justifyContent: 'space-around'}}>
                <LabelButton
                  whiteOutlined
                  text="New selection"
                  icon="Add"
                  onClick={() => {
                    props.emit({});
                    setPrefilled([]);
                  }}
                />
              </div>
            </div>
          )}
          {prefilled.length === 0 && (
            <div>
              <div className="aggregated-bos-history-search-wrap-line">
                <div className="aggregated-bos-history-search-title">{aggregation.searchTitle}</div>
                <div className="aggregated-bos-history-search-separtor"></div>
                <div>
                  <FluentUIDecorator
                    label={null}
                    info={null}
                    validation={(text: string) => {
                      return validateSearch(text) || text === '';
                    }}
                    fluentComponent={FluentUIDecoratorTypes.SearchBox({
                      value: searchText,
                      placeholder: 'Search by Code',
                      onClear: () => {
                        setSearchText('');
                      },
                      onChange: (e: any) => {
                        setSearchText(e.target.value);
                      },
                    })}
                  />
                </div>
              </div>
              {!loading && (
                <div>
                  {boSuggestions.length === 0 && validateSearch(searchText) && (
                    <div className="aggregated-bos-history-search-info-label">There are no results matching your search.</div>
                  )}
                  {boSuggestions.length === 0 && searchText !== '' && (searchText.length < 3 || !validateSearch(searchText)) && (
                    <div className="aggregated-bos-history-search-info-error-label">
                      {/* Insert at least 3 characters to search for business
                      objects */}
                      Insert a valid assurance code
                    </div>
                  )}
                  {boSuggestions.length > 0 && (
                    <GenericList
                      selectionMode={SelectionMode.multiple}
                      columns={columns}
                      items={boSuggestions}
                      onSelectionChanged={(items: AggregatedBOSuggestion[]) => {
                        const itemObj: BOsObject = {};
                        itemObj[aggregation.source] = [];
                        for (let i = 0; i < items.length; i++) {
                          const item = items[i];
                          setBoLabel(item.compositeKey, item.metadata);
                          const pair: IValue = {
                            id: item.compositeKey,
                            name: item.compositeKey,
                          };
                          itemObj[aggregation.source].push(pair);
                        }
                        if (props.emit) {
                          props.emit(itemObj);
                        }
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AggregatedBosHistorySearch;
