import {AppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {reactPlugin} from './AppInsights';
import {ProviderWrapper} from './Components/ProviderWrapper/ProviderWrapper';
import {AppLayout} from './AppLayout';
import {ThemeProvider, PartialTheme} from '@fluentui/react';
import packageJson from '../package.json';
import './App.scss';
import {useEffect} from 'react';

const appTheme: PartialTheme = {
  palette: {
    themePrimary: '#f3a945',
    themeLighterAlt: '#fefbf7',
    themeLighter: '#fdf0e0',
    themeLight: '#fbe4c5',
    themeTertiary: '#f7c98c',
    themeSecondary: '#f4b159',
    themeDarkAlt: '#da963d', // this is the icons color!
    themeDark: '#b87f34',
    themeDarker: '#885d26',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#a3a2a0',
    neutralSecondaryAlt: '#a3a2a0',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#ffffff',
  },
};

export const App = () => {
  useEffect(() => {
    console.log('PACKAGE JSON VERSION', packageJson.version);
  }, []);

  localStorage.setItem('env', process.env.APP_ENV_NAME);

  return (
    <ThemeProvider theme={appTheme}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ProviderWrapper>
          <AppLayout />
        </ProviderWrapper>
      </AppInsightsContext.Provider>
    </ThemeProvider>
  );
};
