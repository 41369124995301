import { Route, Routes, useNavigate } from 'react-router-dom';
import { getTrackingComponent } from '../../AppInsights';
import AppRoutes from '../../Utils/AppRoutes';

import { Banner, LabelButton, ModalDialog } from '@Eni/docware-fe-master';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DriveDocumentManagementToolbar } from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import useFeature from '../../Components/FeatureFlag/useFeature';
import { InformationPanel } from '../../Components/InformationPanel/InformationPanel';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import { MASSIVE_IMPORT_URLS } from '../../Constants/Urls';
import { IMassiveImportOption } from '../../Models/IUser';
import { IMMetadataUploadValidation, IMMetadataValidationStatus, MassiveImportSavingStatus } from '../../Models/MassiveImport/IMIMetadata';
import { MassiveImportAction } from '../../Reducers/MassiveImport/MassiveImportActions';
import { GlobalState } from '../../Reducers/RootReducer';
import { APISettings, IAPIResponse } from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import Icons from '../../Utils/FabricIconsOutlet';
import { setPageContentState } from '../FullPageMessage/FullPageMessage';
import MassiveImportFiles from './MassiveImportFiles/MassiveImportFiles';
import MassiveImportMetadata from './MassiveImportMetadata/MassiveImportMetadata';
import {DOCUMENT_UPLOAD_FF_KEY} from '../../Constants/FeatureFlags';
import './MassiveImportPage.scss';



export function getMetadataValidationBanner(metadataUploadValidation?: IMMetadataUploadValidation): JSX.Element | null{
  switch(metadataUploadValidation?.validationStatus){
    case IMMetadataValidationStatus.TimeOut:
      return (
        <Banner
          message={metadataUploadValidation.message}
          type="error"
          enabled={true}
          isLarge={false}
          notDismissable={true}
          isMultiline={false}
        />
      );
    case IMMetadataValidationStatus.Error:
      return (
        <Banner
          message={"There was a problem with the latest metadata validation process. Upload a new file or contact the Docware Drive Team."}
          type="error"
          enabled={true}
          isLarge={false}
          notDismissable={true}
          isMultiline={false}
        />
      );
    case IMMetadataValidationStatus.Pending:
      return (
        
        <Banner
          message={
            <>
              {metadataUploadValidation.numberOfRows == 0
                ? `The validation process is about to start, it can take a few seconds. Click the 'Refresh Metadata Status' button to check again the validation status.`
                : `The validation process is started. ${metadataUploadValidation.numberOfProcessedRows} rows of ${metadataUploadValidation.numberOfRows} in total are been processed. Click the 'Refresh Metadata Status' button to check again the validation status.`}
            </>
          }
          type="info"
          enabled={true}
          isLarge={false}
          notDismissable={true}
          isMultiline={false}
        />
      );
  }

  switch(metadataUploadValidation?.savingStatus){
    case MassiveImportSavingStatus.Error:
      return (
        <Banner
          message={"There was a problem with the saving process. Upload a new file or contact the Docware Drive Team."}
          type="error"
          enabled={true}
          isLarge={false}
          notDismissable={true}
          isMultiline={false}
        />
      )
    case MassiveImportSavingStatus.Saving:
      return (
        <Banner
          message={"The staging area saving is in progress. You will be notified by email when the saving process is complete"}
          type="info"
          enabled={true}
          isLarge={false}
          notDismissable={true}
          isMultiline={false}
        />
      )
  }
}

const MassiveImportPage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'MassiveImportPage'}
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filePage = useSelector((state: GlobalState) => state.massiveImport.filePage);
  const stagingArea = useSelector((state: GlobalState) => state.massiveImport.stagingArea);
  const currentUser = useSelector((state: GlobalState) => state.user.currentUser);
  const metadataUploadValidation = useSelector((state: GlobalState) => state.massiveImport.metadataPage.metadataUploadValidation);
  const [clearStagingAreaModalOpen, setClearStagingAreaModalOpen] = useState<boolean>(false);
  const [clearingStagingArea, setClearingStagingArea] = useState<boolean>(false);
  const [metadataLoading, setMetadataLoading] = useState<boolean>(false);
  const [triggerMetadataReload, setTriggerMetadataReload] = useState<number>(0);
  const documentUploadEnabled = useFeature(DOCUMENT_UPLOAD_FF_KEY)

  if(!documentUploadEnabled){
    setPageContentState({
      title: `The system is under maintenance`,
      subtitle: 'The upload functionality is interrupted by the system administrator. Please wait the end of maintenance. You can check the news for more details',
      smallSubtitle: "",
      imageName: 'warning',
      button: {
        icon: 'ChromeBackMirrored',
        text: "Go back to Home",
        onClick: {
          type: 'navigate',
          arguments: AppRoutes.HOME_ROUTE,
        },
      },
    });
    navigate(AppRoutes.MESSAGE_PAGE);
  }


  useEffect(() => {
    ApiService.MassiveImportController.getStagingAreaCounts((response: IAPIResponse) => {
      if (response.error == null) {
        dispatch(MassiveImportAction.setStagingArea(response.payload));
      }
    });

    ApiService.MassiveImportController.metadataUploadStatus((response: IAPIResponse) => {
      if (response.error == null) {
        dispatch(MassiveImportAction.setMetadataUploadValidation(response.payload));
      }
    });
  }, []);

  const refreshMetadata = async () => {
    setMetadataLoading(true);
    const _ = await ApiService.MassiveImportController.triggerValidation();
    setMetadataLoading(false);
    dispatch(MassiveImportAction.setNeedRefreshMetadata(false));
    refreshMetadataStatus();
  };

  async function refreshMetadataStatus() {
    const statusResponse = await ApiService.MassiveImportController.metadataUploadStatus();
    if(statusResponse.error == null){
      const metadataUploadValidation: IMMetadataUploadValidation = statusResponse.payload;
      dispatch(MassiveImportAction.setMetadataUploadValidation(metadataUploadValidation));
      if(metadataUploadValidation.validationStatus == IMMetadataValidationStatus.Completed){
        setTriggerMetadataReload((prevState) => prevState + 1);
      }
      
    }
  }

  const clearUserStagingArea = () => {
    setClearingStagingArea(true);
    ApiService.MassiveImportController.clearUserStagingArea((response: IAPIResponse) => {
      if (response.error == null) {
        dispatch(MassiveImportAction.setStagingArea(response.payload.count));
        dispatch(MassiveImportAction.setFiles([]));
        dispatch(MassiveImportAction.setMetadata([]));
        setClearStagingAreaModalOpen(false);
        setClearingStagingArea(false);
      }
    });
  };

  const getExcelDownloadButtons = (): JSX.Element => {
    const subSurface = (
      <div className="mass-imp-left-button">
        <a href={`${APISettings.baseUrl}${MASSIVE_IMPORT_URLS.GET_EXCEL_FILE_SUBSURFACE}`}>
          <LabelButton text="Download Subsurface Excel Template" icon={'Download'} />
        </a>
      </div>
    );
    const development = (
      <div className="mass-imp-left-button">
        <a href={`${APISettings.baseUrl}${MASSIVE_IMPORT_URLS.GET_EXCEL_FILE_DEVELOPMENT}`}>
          <LabelButton text="Download Development Excel Template" icon={'Download'} />
        </a>
      </div>
    );


    switch (currentUser.massiveImportOption) {
      case IMassiveImportOption.Subsurface:
        return subSurface;
      case IMassiveImportOption.DevelopmentProjectManagementAndServices:
        return development;
      case IMassiveImportOption.All:
        return (
          <>
            {subSurface}
            {development}
          </>
        );
      default:
        return <></>;
    }
  };



  return (
    <div className="general-page-container">
      <DriveDocumentManagementToolbar type={filePage.activeFiles.length > 0 ? 'massive-import-actions-on-file' : 'navigation-voices'} />
      <div className="mass-imp-inner-section">
        <div className="mass-imp-header-container">
          <div className="mass-imp-header">
            <div className="mass-imp-title">
              Massive Upload
              <VideoTutorialIcon />
            </div>
            <div className="mass-imp-subtitle">Upload multiple documents from a massive import procedure</div>
          </div>
          <div className="mass-imp-metadata-upload-status-container">{getMetadataValidationBanner(metadataUploadValidation)}</div>
        </div>

        <div className="mass-imp-content flex-column-on-small-screen">
          <div className="mass-imp-left-section">
            <section>
              <div className="mass-imp-section-title">STAGING AREA</div>

              <div className="mass-imp-summary">
                <InformationPanel
                  rows={[
                    {
                      header: (
                        <div className="mass-imp-well-information-files-header">
                          {Icons.getIcon('Document', '1em')}
                          <span>Files</span>
                        </div>
                      ),
                      content:
                        stagingArea.filesMatchingCount > 0 || stagingArea.filesNotMatchingCount > 0 ? (
                          <>
                            <div className="mass-imp-well-information-content-summary">
                              <div>
                                <span className="mass-imp-well-information-content-text-success">{stagingArea.filesMatchingCount}</span>
                                &nbsp;Matching
                              </div>
                            </div>
                            <div className="mass-imp-well-information-content-summary">
                              <div>
                                <span className="mass-imp-well-information-content-text-warning">{stagingArea.filesNotMatchingCount}</span>
                                &nbsp;Not Matching
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="mass-imp-well-information-zero-content">
                            <span>No files</span>
                          </div>
                        ),
                    },
                    {
                      header: (
                        <div className="mass-imp-well-information-metadata-header-content">
                          {Icons.getIcon('Table', '1em')}
                          <span>Metadata</span>
                        </div>
                      ),
                      content:
                        stagingArea.metadataValidCount > 0 || stagingArea.metadataNotValidCount > 0 ? (
                          <>
                            <div className="mass-imp-well-information-content-summary">
                              <div>
                                <span className="mass-imp-well-information-content-text-success">{stagingArea.metadataValidCount}</span>
                                &nbsp;Valid
                              </div>
                            </div>
                            <div className="mass-imp-well-information-content-summary">
                              <div>
                                <span className="mass-imp-well-information-content-text-warning">{stagingArea.metadataNotValidCount}</span>
                                &nbsp;Not Valid
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="mass-imp-well-information-zero-content">
                            <span>No metadata</span>
                          </div>
                        ),
                    },
                  ]}
                />
                <div className="mass-imp-info-message-container">
                  <div className="mass-imp-message-content">
                    <span>
                      Files uploaded in massive import staging areas are maintained for 15 days. After this period of time they will be
                      automatically deleted.
                    </span>
                  </div>
                </div>

                {metadataUploadValidation?.validationStatus == IMMetadataValidationStatus.Pending ? (
                  <div className="mass-imp-left-button">
                    <LabelButton text="Refresh Metadata Status" icon={'Refresh'} onClick={refreshMetadataStatus} disabled={false} />
                  </div>
                ) : (
                  <div className="mass-imp-left-button">
                    <LabelButton text="Refresh Metadata" icon={'Refresh'} onClick={refreshMetadata} disabled={metadataLoading} />
                  </div>
                )}

                {getExcelDownloadButtons()}
                <div className="mass-imp-left-button">
                  <LabelButton
                    text="Clear Staging Area"
                    orangeSolid
                    icon={'OpenFile'}
                    onClick={() => {
                      setClearStagingAreaModalOpen(true);
                    }}
                    disabled={metadataUploadValidation?.validationStatus === IMMetadataValidationStatus.Pending}
                  />
                </div>
              </div>

              <ModalDialog
                enableModal={clearStagingAreaModalOpen}
                modalTitle="Do you really want to clear the staging area?"
                modalInnerComponent={
                  <div>
                    <div style={{fontWeight: '600'}}>All the files in your staging area will be deleted</div>
                  </div>
                }
                loadingButtons={clearingStagingArea}
                onAbort={() => {
                  setClearStagingAreaModalOpen(false);
                }}
                modalButtons={[
                  {
                    label: 'Cancel',
                    onClick: () => {
                      setClearStagingAreaModalOpen(false);
                    },
                  },
                  {
                    label: 'Clear Staging Area',
                    onClick: () => {
                      clearUserStagingArea();
                    },
                  },
                ]}
              />
            </section>
          </div>

          <div className="mass-imp-right-section">
            <section style={{maxWidth: '75vw'}}>
              <div className="mass-imp-section-title">DETAILS</div>
              <Routes>
                <Route path={AppRoutes.MASSIVE_IMPORT_ROUTE_FILES} element={<MassiveImportFiles />} />
                <Route
                  path={AppRoutes.MASSIVE_IMPORT_ROUTE_METADATA}
                  element={<MassiveImportMetadata loading={metadataLoading} setLoading={setMetadataLoading} reload={triggerMetadataReload}/>}
                />
              </Routes>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default getTrackingComponent(MassiveImportPage, 'MassiveImportPage');
