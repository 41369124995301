import {useState} from 'react';

import './FileFromTeams.scss';
import {ModalDialog} from '@Eni/docware-fe-master';
import {TeamsFilePicker} from '../TeamsFilePicker/TeamsFilePicker';
import {ITeamItem} from '../../Models/ITeam';

export interface FileFromTeamsProps {
  multiFileSelect: boolean;
  onFileSelection: (files: ITeamItem[]) => any;
  elementToRender: JSX.Element;
}

const FileFromTeams = (props: FileFromTeamsProps) => {
  const [showTeamsFilePicker, setShowTeamsFilePicker] = useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => {
          setShowTeamsFilePicker(true);
        }}>
        {props.elementToRender}
      </div>

      <ModalDialog
        enableModal={showTeamsFilePicker}
        modalTitle="Select file from Teams"
        fullWidth={true}
        modalInnerComponent={
          <TeamsFilePicker
            onFileSelection={(files: ITeamItem[]) => {
              setShowTeamsFilePicker(false);
              props.onFileSelection(files);
            }}
            multiFileSelect={props.multiFileSelect}
          />
        }
        loadingButtons={false}
        onAbort={() => {
          setShowTeamsFilePicker(false);
        }}
        modalButtons={[]}
      />
    </>
  );
};

export default FileFromTeams;
