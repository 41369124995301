import {AUTHENTICATION_URLS} from '../../Constants/Urls';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const AuthenticationController = {
  getUserInfo: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = AUTHENTICATION_URLS.GET_USER;
    request.alternative_base_url = process.env.REACT_APP_BASE_ROOT_URL;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default AuthenticationController;
