import {DOCUMENT_URLS} from '../../Constants/Urls';
import {ILibrary} from '../../Models/ILibrary';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse, silentLevels} from './../AjaxService';

const LibraryController = {
  adminUpdateAllLibraries: async (libraries: ILibrary[], callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.UPDATE_LIBRARIES_ADMIN;
    request.payload = libraries;
    request.method = 'post';
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getLibrariesAdmin: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_LIBRARIES_INFO_URL;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
  getAdminLibraries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_LIBRARIES_ADMIN_URL;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getLibraries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_LIBRARIES_URL;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getAllCleanLibraries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_ALL_LIBRARIES_URL;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getLibrariesSearch: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_LIBRARIES_SEARCH;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getDocTypeslConfigurations: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_DOC_TYPE_CONF;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getAllLibraries: async (callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.GET_ALL_LIBRARIES;
    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  createLibrary: async (library: any, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.CREATE_NEW_LIBRARY;
    request.payload = library;
    request.method = 'post';
    request.silentLevel = silentLevels.NO_UI_INTERACTIONS;

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  editLibrary: async (library: any, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = DOCUMENT_URLS.CREATE_NEW_LIBRARY;
    request.payload = library;
    request.method = 'PATCH';

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  getLibrarySummary: async (libraryId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.GET_LIBRARY_SUMMARY}?libraryId=${libraryId}`;
    request.method = 'GET';

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
  updateLibraryExportStatus: async (libraryId: string, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${DOCUMENT_URLS.UPDATE_EXPORT_STATUS}?libraryId=${libraryId}`;
    request.payload = {libraryId: libraryId};
    request.method = 'PATCH';

    let response: IAPIResponse = await AjaxService.call(request);

    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default LibraryController;
