import {HierarchyLibrary, IBoColor, ILibrary} from '../../Models/ILibrary';
import {IUser} from '../../Models/IUser';
import {IVideoTutorial} from '../../Models/IVideoTutorial';
import {IWorkflowItem} from '../../Models/IWorkflowItem';
import {InputInfo} from '../../Models/InputInfo';
import store from '../Store';
import {IFeatureFlag} from '../../Models/IFeatureFlag';

export const SET_INPUT_INFOS = 'SET_INPUT_INFOS';
export const SET_ALL_USERS = 'SET_ALL_USERS';
export const RELOAD_REQUEST = 'RELOAD_REQUEST';
export const SET_SORT_ORDER_DIRECTION = 'SET_SORT_ORDER_DIRECTION';
export const SET_SORT_ORDER_SEARCH = 'SET_SORT_ORDER_SEARCH';
export const SET_UPLOAD_APPROVAL_FLAG = 'SET_UPLOAD_APPROVAL_FLAG';
export const SET_DRIVE_DOC_SIZE = 'SET_DRIVE_DOC_SIZE';
export const SET_LIBRARY_HIERARCHY = 'SET_LIBRARY_HIERARCHY';
export const SET_LIBRARY_HIERARCHY_WHERE_USER_CAN_SEARCH = 'SET_LIBRARY_HIERARCHY_WHERE_USER_CAN_SEARCH';
export const SET_TIGHT_ERROR_BOS = 'SET_TIGHT_ERROR_BOS';
export const SET_TIGHT_ERROR_CTR = 'SET_TIGHT_ERROR_CTR';
export const SET_TASKS_BACKGROUND = 'SET_TASKS_BACKGROUND';
export const SET_BO_COLORS = 'SET_BO_COLORS';
export const SET_VIDEOS_TUTORIAL = 'SET_VIDEOS_TUTORIAL';
export const SET_WAITING_LOG_IN = 'SET_WAITING_LOG_IN';
export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS';
export const SET_ALL_CLEAN_LIBRARIES = 'SET_ALL_CLEAN_LIBRARIES';

interface SetTasksBackground {
  type: typeof SET_TASKS_BACKGROUND;
  payload: IWorkflowItem[];
}

interface SetTightErrorBos {
  type: typeof SET_TIGHT_ERROR_BOS;
  payload: boolean;
}

interface SetTightErrorCtr {
  type: typeof SET_TIGHT_ERROR_CTR;
  payload: boolean;
}

interface SetLibHierarchy {
  type: typeof SET_LIBRARY_HIERARCHY;
  payload: HierarchyLibrary[];
}

interface SetLibHierarchyWhereUserCanSearch {
  type: typeof SET_LIBRARY_HIERARCHY_WHERE_USER_CAN_SEARCH;
  payload: HierarchyLibrary[];
}

interface SetDriveDocSize {
  type: typeof SET_DRIVE_DOC_SIZE;
  payload: number;
}

interface SetFilterSortDirectionActionType {
  type: typeof SET_SORT_ORDER_DIRECTION;
  payload: boolean;
}

interface SetFilterSortOrderActionType {
  type: typeof SET_SORT_ORDER_SEARCH;
  payload: string;
}

interface SetInputInfosActionType {
  type: typeof SET_INPUT_INFOS;
  payload: InputInfo[];
}

interface SetAllUsersActionType {
  type: typeof SET_ALL_USERS;
  payload: IUser[];
}

interface SetApprovalUploadFlag {
  type: typeof SET_UPLOAD_APPROVAL_FLAG;
  payload: number;
}

interface SetReloadRequestActionType {
  type: typeof RELOAD_REQUEST;
}

interface SetBoColors {
  type: typeof SET_BO_COLORS;
  payload: IBoColor[];
}

interface SetVideosTutorial {
  type: typeof SET_VIDEOS_TUTORIAL;
  payload: IVideoTutorial[];
}

interface SetWaitingLogIn {
  type: typeof SET_WAITING_LOG_IN;
  payload: boolean;
}

interface SetFeatureFlags {
  type: typeof SET_FEATURE_FLAGS;
  payload: IFeatureFlag[];
}

interface SetAllCleanLibraries {
  type: typeof SET_ALL_CLEAN_LIBRARIES;
  payload: ILibrary[];
}

export type InputInfosActionType =
  | SetInputInfosActionType
  | SetTightErrorBos
  | SetTightErrorCtr
  | SetAllUsersActionType
  | SetReloadRequestActionType
  | SetFilterSortOrderActionType
  | SetFilterSortDirectionActionType
  | SetApprovalUploadFlag
  | SetLibHierarchy
  | SetLibHierarchyWhereUserCanSearch
  | SetTasksBackground
  | SetDriveDocSize
  | SetBoColors
  | SetVideosTutorial
  | SetWaitingLogIn
  | SetFeatureFlags
  | SetAllCleanLibraries

export const testExistsInputInfos = (page: string, key: string) => {
  let state = store.getState();
  let infos: InputInfo[] = state.generic.inputInfos.filter((x) => x.page === page && x.key === key);
  return infos.length > 0;
};

export const getInputInfos = (page: string, key: string) => {
  return {page, key};
};

export const getInputInfosValue = (page: string, key: string): string | null => {
  let state = store.getState();
  let infos: InputInfo[] = state.generic.inputInfos.filter((x) => x.page === page && x.key === key);
  if (infos.length > 0) {
    return infos[0].message;
  }
  return null;
};
const SetTasksBackground = (size: IWorkflowItem[]): SetTasksBackground => {
  return {type: SET_TASKS_BACKGROUND, payload: size};
};
const setDriveDocumentViewSize = (size: number): SetDriveDocSize => {
  return {type: SET_DRIVE_DOC_SIZE, payload: size};
};

const setLibraryHierarchy = (hierarchy: HierarchyLibrary[]): SetLibHierarchy => {
  return {type: SET_LIBRARY_HIERARCHY, payload: hierarchy};
};

const setLibraryHierarchyWhereUserCanSearch = (hierarchy: HierarchyLibrary[]): SetLibHierarchyWhereUserCanSearch => {
  return {type: SET_LIBRARY_HIERARCHY_WHERE_USER_CAN_SEARCH, payload: hierarchy};
};

const setUploadApprovalFlag = (flag: number): SetApprovalUploadFlag => {
  return {type: SET_UPLOAD_APPROVAL_FLAG, payload: flag};
};

const setTightErrorBos = (err: boolean): SetTightErrorBos => {
  return {type: SET_TIGHT_ERROR_BOS, payload: err};
};

const setTightErrorCtr = (err: boolean): SetTightErrorCtr => {
  return {type: SET_TIGHT_ERROR_CTR, payload: err};
};

const setSortSearchOrder = (sortDirectionDesc: boolean): SetFilterSortDirectionActionType => {
  return {type: SET_SORT_ORDER_DIRECTION, payload: sortDirectionDesc};
};

const setSortSearchBy = (sortBy: string): SetFilterSortOrderActionType => {
  return {type: SET_SORT_ORDER_SEARCH, payload: sortBy};
};

const reloadRequest = (): SetReloadRequestActionType => {
  return {type: RELOAD_REQUEST};
};

const setInputInfos = (inputInfos: InputInfo[]): SetInputInfosActionType => {
  return {type: SET_INPUT_INFOS, payload: inputInfos};
};

const setAllUsers = (allUsers: IUser[]): SetAllUsersActionType => {
  return {type: SET_ALL_USERS, payload: allUsers};
};

const setBoColors = (boColors: IBoColor[]): SetBoColors => {
  return {type: SET_BO_COLORS, payload: boColors};
};

const setVideosTutorial = (videosTutorial: IVideoTutorial[]): SetVideosTutorial => {
  return {type: SET_VIDEOS_TUTORIAL, payload: videosTutorial};
};

const setWaitingLogIn = (waitingLogIn: boolean): SetWaitingLogIn => {
  return {type: SET_WAITING_LOG_IN, payload: waitingLogIn};
};

const setFeatureFlags = (featureFlags: IFeatureFlag[]): SetFeatureFlags => {
  return {type: SET_FEATURE_FLAGS, payload: featureFlags};
};

const setAllCleanLibraries = (allCleanLibraries: ILibrary[]): SetAllCleanLibraries => {
  return {type: SET_ALL_CLEAN_LIBRARIES, payload: allCleanLibraries};
};

export const GenericActions = {
  setInputInfos,
  setAllUsers,
  reloadRequest,
  setSortSearchBy,
  setLibraryHierarchy,
  setLibraryHierarchyWhereUserCanSearch,
  setSortSearchOrder,
  setUploadApprovalFlag,
  setDriveDocumentViewSize,
  setTightErrorBos,
  setTightErrorCtr,
  SetTasksBackground,
  setBoColors,
  setVideosTutorial,
  setWaitingLogIn,
  setFeatureFlags,
  setAllCleanLibraries,
};
