import {ActionButton, Spinner} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {IDocumentItem} from '../../Models/IDocument';
import {IPermissionByData} from '../../Models/IUser';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {GenericActions} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import {AjaxService, IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {Banner, ModalDialog} from '@Eni/docware-fe-master';
import './ContextMenu.scss';
import EventDispatcher from '../../Utils/EventDispatcher';
import {
  loadDocumentDeletingDocumentVersionInfo,
  MoveToTrashSelectedDocuments,
} from '../MoveToTrashSelectedDocuments/MoveToTrashSelectedDocuments';
import {useLocation} from 'react-router';
import store from '../../Reducers/Store';
import DownloadDocumentModal from '../DownloadDocumentModal/DownloadDocumentModal';

export const getDocumentsToDelete = (permissions: IPermissionByData[], documents: IDocumentItem[]): IDocumentItem[] => {
  const documentPermissionCanDelete = permissions.filter((permission) => permission.canDelete).map((x) => x.documentId);

  return documents.filter((document) => documentPermissionCanDelete.includes(document.documentId));
};

export interface ICustomContextualMenuItem {
  key: string;
  text: string;
  iconName: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface IContextMenuModalBehaviorProps {
  title: string;
  message: string;
  showBinContent?: boolean;
  onAccept: (item: any) => void;
}

export interface IContextMenuProps {
  type: 'normal' | 'favorites' | 'bin' | 'admin-bin';
  item: IDocumentItem;
  emitAction: (type: string) => void;
}

const ContextMenu = (props: IContextMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [documentIsFavorite, setDocumentIsFavorite] = useState<boolean>(false);
  const [documentIsFavoriteInited, setDocumentIsFavoriteInited] = useState<boolean>(false);

  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  const permissionsOnDocuments = useSelector((state: GlobalState) => state.user.permissionsForDocuments);
  const documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  const [itemId, setItemId] = useState<string>('');
  const [hasDocument, setHasDocument] = useState<boolean>(false);
  const [showSingleFileSelectedModal, setShowSingleFileSelectedModal] = useState<boolean>(false);
  const [modalBehaviorSingleFileSelected, setModalBehaviorSingleFileSelected] = useState<IContextMenuModalBehaviorProps | null>(null);
  const [menuActionsSingleFileSelected, setMenuActionsSingleFileSelected] = useState<ICustomContextualMenuItem[]>([]);
  const [currentDocumentPermissions, setCurrentDocumentPermissions] = useState<IPermissionByData | null>(null);
  const [trashModalWarnings, setTrashModalWarnings] = useState<string[]>(undefined);
  const [trashModalLoading, setTrashModalLoading] = useState<boolean>(false);
  const [trashModalErrors, setTrashModalErrors] = useState<string[]>([]);
  /******************* MultiRows Selected *****************************************************************************/
  /******************* Begin Move to trash variables **********************************/
  const [showMultiRowsSelectedModal, setShowMultiRowsSelectedModal] = useState<boolean>(false);
  const [deleteLevel, setDeleteLevel] = useState<number>(-1);
  const [documentIdsToDelete, setDocumentIdsToDelete] = useState<string[]>([]);
  const [checkDocumentVersionInfo, setCheckDocumentVersionInfo] = useState<boolean>();
  /******************* End Move to trash variables ************************************/
  /******************* End MultiRows Selected *************************************************************************/

  /******************* Begin Download Document Modal variables **********************************/
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [documentIDsToDownload, setDocumentIDsToDownload] = useState<string[]>([]);
  /******************* End Download Document Modal variables ************************************/

  useEffect(() => {
    if (itemId !== props.item.documentId) {
      setItemId(props.item.documentId);
      setHasDocument(props.item.mainFile !== null);
      setDocumentIsFavoriteInited(false);
      setTrashModalWarnings(undefined);
    }
  }, [props.item.documentId]);

  useEffect(() => {
    setCurrentDocumentPermissions(null);
    for (let i = 0; i < permissionsOnDocuments.length; i++) {
      if (permissionsOnDocuments[i].documentId === itemId) {
        setCurrentDocumentPermissions(permissionsOnDocuments[i]);
      }
    }
  }, [itemId, permissionsOnDocuments]);

  const loadIsFavorite = () => {
    if (documentIsFavoriteInited) {
      return;
    }

    setDocumentIsFavoriteInited(true);
    ApiService.DocumentController.checkDocumentIsFavorite(loggedUser.id, itemId, (response: IAPIResponse) => {
      if (response.error == null) {
        setDocumentIsFavorite(response.payload);
      }
    });
  };

  const loadMoreInfo = async () => {
    loadIsFavorite();
    setTrashModalLoading(true);
    const warnings = await loadDocumentDeletingDocumentVersionInfo([itemId]);
    setTrashModalWarnings(warnings);
    setTrashModalLoading(false);
  };

  useEffect(() => {
    if (currentDocumentPermissions) {
      const normalFileSingleRow: ICustomContextualMenuItem[] = [
        {
          disabled: !currentDocumentPermissions.canRead,
          key: 'open',
          text: 'Open',
          iconName: 'Page',
          onClick: () => {
            navigate(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + itemId.toString());
          },
        },
        {
          disabled: !currentDocumentPermissions.canEdit && !currentDocumentPermissions.canEditMetadata,
          key: 'edit',
          text: 'Edit Attributes',
          iconName: 'Edit',
          onClick: () => {
            localStorage.removeItem('new-document-lock-url');
            navigate(AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + itemId.toString() + '&mode=edit');
          },
        },
        {
          disabled: !currentDocumentPermissions.canCreateNewVersion,
          key: 'newversion',
          text: 'New Version',
          iconName: 'VersionControlPush',
          onClick: () => {
            ApiService.DocumentController.generateDocumentSnapshot(itemId.toString(), (response: IAPIResponse) => {
              if (response.error === null) {
                localStorage.removeItem('new-document-lock-url');
                navigate(
                  AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + response.payload + '&mode=newversion&previousId=' + itemId.toString()
                );
              } else {
                if (response.raw.status === 404) {
                  window.document.dispatchEvent(
                    new CustomEvent('api-toast-result', {
                      detail: {
                        text: 'Cannot create New Version: this document already has a new version.',
                        type: 'error',
                      },
                    })
                  );
                }
              }
            });
          },
        },
        {
          disabled: !currentDocumentPermissions.canDelete,
          key: 'trash',
          text: 'Move to Trash',
          iconName: 'RecycleBin',
          onClick: () => {
            setShowSingleFileSelectedModal(true);
          },
        },
        {
          disabled: !hasDocument,
          key: 'download',
          text: 'Download',
          iconName: 'Download',
          onClick: () => {
            setShowDownloadModal(true)
            setDocumentIDsToDownload([itemId.toString()])
          },
        },
        {
          key: 'favorites',
          iconName: 'SingleBookmark',
          text: documentIsFavorite ? 'Remove from Favourites' : 'Add to Favourites',
          onClick: () => {
            if (loggedUser !== undefined) {
              const callMe = documentIsFavorite
                ? ApiService.DocumentController.deleteFavoriteDocument
                : ApiService.DocumentController.addFavoriteDocument;

              callMe([itemId.toString()], (res: IAPIResponse) => {
                if (res.error == null) {
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                  setDocumentIsFavorite(!documentIsFavorite);
                  dispatch(GenericActions.reloadRequest());
                  props.emitAction('favorites');
                }
              });
            }
          },
        },
      ];
      const favoriteFileSingleRow: ICustomContextualMenuItem[] = [
        {
          disabled: !currentDocumentPermissions.canRead,
          key: 'open',
          text: 'Open',
          iconName: 'Page',
          onClick: () => {
            navigate(AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + itemId.toString());
          },
        },
        {
          disabled: !currentDocumentPermissions.canEdit && !currentDocumentPermissions.canEditMetadata,
          key: 'edit',
          text: 'Edit Attributes',
          iconName: 'Edit',
          onClick: () => {
            localStorage.removeItem('new-document-lock-url');
            navigate(AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + itemId.toString() + '&mode=edit');
          },
        },
        {
          disabled: !currentDocumentPermissions.canCreateNewVersion,
          key: 'newversion',
          text: 'New Version',
          iconName: 'VersionControlPush',
          onClick: () => {
            ApiService.DocumentController.generateDocumentSnapshot(itemId.toString(), (response: IAPIResponse) => {
              if (response.error === null) {
                localStorage.removeItem('new-document-lock-url');
                navigate(
                  AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + response.payload + '&mode=newversion&previousId=' + itemId.toString()
                );
              } else {
                if (response.raw.status === 404) {
                  window.document.dispatchEvent(
                    new CustomEvent('api-toast-result', {
                      detail: {
                        text: 'Cannot create New Version: this document already has a new version.',
                        type: 'error',
                      },
                    })
                  );
                }
              }
            });
          },
        },
        {
          disabled: !currentDocumentPermissions.canDelete,
          key: 'trash',
          text: 'Move to Trash',
          iconName: 'RecycleBin',
          onClick: () => {
            setShowSingleFileSelectedModal(true);
          },
        },
        {
          disabled: !hasDocument,
          key: 'download',
          text: 'Download',
          iconName: 'Download',
          onClick: () => {
            setShowDownloadModal(true)
            setDocumentIDsToDownload([itemId.toString()])
          },
        },
        {
          key: 'favorites',
          text: 'Remove from Favourites',
          iconName: 'SingleBookmark',
          onClick: () => {
            if (loggedUser !== undefined) {
              ApiService.DocumentController.deleteFavoriteDocument([itemId.toString()], (res: IAPIResponse) => {
                if (res.error == null) {
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                  dispatch(GenericActions.reloadRequest());
                  props.emitAction('favorites');
                }
              });
            }
          },
        },
      ];
      const binFileSingleRow: ICustomContextualMenuItem[] = [
        {
          key: 'trash',
          text: 'Restore document',
          iconName: 'SkypeCircleCheck',
          onClick: () => {
            ApiService.DocumentController.setDeletedDocument(
              {
                documentId: itemId,
                documentActivation: 1,
              },
              (res: IAPIResponse) => {
                props.emitAction('trash');
              }
            );
          },
        },
        {
          disabled: !currentDocumentPermissions.canDelete,
          key: 'trash-permanently',
          text: 'Delete document',
          iconName: 'RecycleBin',
          onClick: () => {
            setShowSingleFileSelectedModal(true);
          },
        },
      ];
      const adminBinFileSingleRow: ICustomContextualMenuItem[] = [
        {
          key: 'trash',
          text: 'Restore document',
          iconName: 'SkypeCircleCheck',
          onClick: () => {
            ApiService.DocumentController.setDeletedDocument(
              {
                documentId: itemId,
                documentActivation: 1,
              },
              (res: IAPIResponse) => {
                props.emitAction('trash');
              }
            );
          },
        },
        {
          key: 'trash-permanently',
          text: 'Delete document',
          iconName: 'RecycleBin',
          onClick: () => {
            setShowSingleFileSelectedModal(true);
          },
        },
      ];

      const normalFavoriteMultipleRows: ICustomContextualMenuItem[] = [
        {
          key: 'trash',
          text: 'Move to Trash',
          iconName: 'RecycleBin',
          onClick: () => {
            setCheckDocumentVersionInfo(true);
            setDocumentIdsToDelete(
              documentSearch.activeDocuments.map((x: IDocumentItem, i: number) => {
                return x.documentId;
              })
            );
            setDeleteLevel(2);
            setShowMultiRowsSelectedModal(true);
          },
        },
        {
          key: 'download-all',
          text: 'Download all',
          iconName: 'Download',
          onClick: () => {
            if (documentSearch.activeDocuments.length > 0) {
              setShowDownloadModal(true);
              setDocumentIDsToDownload(documentSearch.activeDocuments.map((x) => x.documentId));
            }
          },
        },
        {
          key: 'add-to-favorites',
          text: 'Add to favorites',
          iconName: 'SingleBookmark',
          disabled: location.pathname === AppRoutes.STARRED_ROUTE,
          onClick: () => {
            if (documentSearch.activeDocuments.length > 0) {
              const documentsIds = documentSearch.activeDocuments.map((x) => x.documentId);
              ApiService.DocumentController.addFavoriteDocument(documentsIds, (res: IAPIResponse) => {
                /** trick to force the Drive page reload */
                dispatch(DocumentListAction.setActiveDocument(null));
                dispatch(DocumentListAction.setActiveDocuments([]));
                store.dispatch(GenericActions.reloadRequest());
              });
            }
          },
        },
        {
          key: 'remove-from-favorites',
          text: 'Remove from favorites',
          iconName: 'SingleBookmark',
          onClick: () => {
            if (documentSearch.activeDocuments.length > 0) {
              const documentsIds = documentSearch.activeDocuments.map((x) => x.documentId);
              ApiService.DocumentController.deleteFavoriteDocument(documentsIds, (res: IAPIResponse) => {
                /** trick to force the Drive page reload */
                dispatch(DocumentListAction.setActiveDocument(null));
                dispatch(DocumentListAction.setActiveDocuments([]));
                store.dispatch(GenericActions.reloadRequest());
              });
            }
          },
        },
      ];
      const binMultipleRows: ICustomContextualMenuItem[] = [
        {
          key: 'restore',
          text: 'Restore Documents',
          iconName: 'SkypeCircleCheck',
          onClick: async () => {
            for (let i = 0; i < documentSearch.activeDocuments.length; i++) {
              let doc: IDocumentItem = documentSearch.activeDocuments[i];

              await ApiService.DocumentController.setDeletedDocument({
                documentId: doc.documentId.toString(),
                documentActivation: 1,
              });
            }

            dispatch(DocumentListAction.setActiveDocuments([]));
            dispatch(DocumentListAction.setActiveDocument(null));
            dispatch(GenericActions.reloadRequest());
          },
        },
        {
          key: 'delete-documents',
          text: 'Delete Documents',
          iconName: 'RecycleBin',
          onClick: () => {
            if (documentSearch.activeDocuments.length > 0) {
              setDeleteLevel(3);
              const toDelete = getDocumentsToDelete(permissionsOnDocuments, documentSearch.activeDocuments).map((x) => x.documentId);
              setCheckDocumentVersionInfo(false);
              setDocumentIdsToDelete(toDelete);
              setShowMultiRowsSelectedModal(true);
            }
          },
        },
      ];
      const adminBinMultipleRows: ICustomContextualMenuItem[] = [
        {
          key: 'restore',
          text: 'Restore Documents',
          iconName: 'SkypeCircleCheck',
          onClick: async () => {
            for (let i = 0; i < documentSearch.activeDocuments.length; i++) {
              let doc: IDocumentItem = documentSearch.activeDocuments[i];

              await ApiService.DocumentController.setDeletedDocument({
                documentId: doc.documentId.toString(),
                documentActivation: 1,
              });
            }

            dispatch(DocumentListAction.setActiveDocuments([]));
            dispatch(DocumentListAction.setActiveDocument(null));
            dispatch(GenericActions.reloadRequest());
          },
        },
        {
          key: 'delete-documents',
          text: 'Delete Documents',
          iconName: 'RecycleBin',
          onClick: () => {
            if (documentSearch.activeDocuments.length > 0) {
              setDeleteLevel(4);
              const toDelete = getDocumentsToDelete(permissionsOnDocuments, documentSearch.activeDocuments).map((x) => x.documentId);
              setCheckDocumentVersionInfo(false);
              setDocumentIdsToDelete(toDelete);
              setShowMultiRowsSelectedModal(true);
            }
          },
        },
      ];

      if (documentSearch.activeDocuments.length === 0) {
        // single row selected
        switch (props.type) {
          case 'normal': {
            setTrashModalErrors([]);
            setMenuActionsSingleFileSelected([...normalFileSingleRow]);
            setModalBehaviorSingleFileSelected({
              title: 'Move to Trash?',
              message: 'Documents deleted in this way are moved to the User Trash Bin. Proceed anyway?',
              showBinContent: true,
              onAccept: async (item: any) => {
                if (documentSearch.activeDocuments.length === 0) {
                  setShowSingleFileSelectedModal(false);
                  let result = await ApiService.DocumentController.setDeletedDocument({
                    documentId: itemId,
                    documentActivation: 2,
                  });
                  if (result.error) {
                    if (result.payload?.title === "User's Trash Bin is full") {
                      window.document.dispatchEvent(new Event('trashBinFullModal'));
                    } else {
                      setTrashModalErrors([result.error]);
                    }
                  } else {
                    dispatch(GenericActions.reloadRequest());
                    dispatch(DocumentListAction.setActiveDocument(null));
                    dispatch(DocumentListAction.setActiveDocuments([]));
                  }
                }
              },
            });
            break;
          }
          case 'favorites': {
            setTrashModalErrors([]);
            setMenuActionsSingleFileSelected([...favoriteFileSingleRow]);
            setModalBehaviorSingleFileSelected({
              title: 'Move to Trash?',
              message: 'Documents deleted in this way are moved to the User Trash Bin. Proceed anyway?',
              showBinContent: true,
              onAccept: async (item: any) => {
                setShowSingleFileSelectedModal(false);
                let result = await ApiService.DocumentController.setDeletedDocument({
                  documentId: itemId,
                  documentActivation: 2,
                });

                if (result.error) {
                  if (result.payload?.title === "User's Trash Bin is full") {
                    window.document.dispatchEvent(new Event('trashBinFullModal'));
                  } else {
                    setTrashModalErrors([result.error]);
                  }
                } else {
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                  dispatch(GenericActions.reloadRequest());
                }
              },
            });
            break;
          }
          case 'bin': {
            setTrashModalErrors([]);
            setMenuActionsSingleFileSelected([...binFileSingleRow]);
            setModalBehaviorSingleFileSelected({
              title: 'Delete document?',
              message: 'Documents deleted in this way are deleted and removed from the trash page. Proceed anyway?',
              showBinContent: true,
              onAccept: async (item: any) => {
                setShowSingleFileSelectedModal(false);
                let result = await ApiService.DocumentController.setDeletedDocument({
                  documentId: itemId,
                  documentActivation: 3,
                });
                if (result.error) {
                  setTrashModalErrors([result.error]);
                } else {
                  props.emitAction('trash');
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                }
              },
            });
            break;
          }
          case 'admin-bin': {
            setTrashModalErrors([]);
            setMenuActionsSingleFileSelected([...adminBinFileSingleRow]);
            setModalBehaviorSingleFileSelected({
              title: 'Delete physically?',
              message:
                'Documents deleted in this way are removed from the system and are physically deleted. This action cannot be undone. Proceed anyway?',
              showBinContent: true,
              onAccept: async (item: any) => {
                setShowSingleFileSelectedModal(false);
                let result = await ApiService.DocumentController.setAdminDeletedDocument({
                  documentId: itemId,
                  documentActivation: 4,
                });
                if (result.error) {
                  setTrashModalErrors([result.error]);
                } else {
                  dispatch(DocumentListAction.setActiveDocument(null));
                  dispatch(DocumentListAction.setActiveDocuments([]));
                  props.emitAction('trash');
                }
              },
            });
            break;
          }
        }
      } else {
        // multiple rows selected
        switch (props.type) {
          case 'normal':
          case 'favorites': {
            setMenuActionsSingleFileSelected(normalFavoriteMultipleRows);
            break;
          }
          case 'bin': {
            setMenuActionsSingleFileSelected(binMultipleRows);
            break;
          }
          case 'admin-bin': {
            setMenuActionsSingleFileSelected(adminBinMultipleRows);
            break;
          }
        }
      }
    }
  }, [currentDocumentPermissions, documentIsFavorite, documentSearch.activeDocuments]);

  const renderExtraBinContent = () => {
    return (
      <div>
        {trashModalLoading && (
          <div style={{display: 'flex', justifyContent: 'space-around', margin: '1em'}}>
            <Spinner label="Checking selected document(s)..." labelPosition="right" />
          </div>
        )}
        {!trashModalLoading && (
          <div>
            {trashModalWarnings && (
              <div>
                {trashModalWarnings.length > 0 && <div className="trash-documents-warning">Please read carefully:</div>}
                {trashModalWarnings.map((x: string, i: number) => {
                  return (
                    <div className="trash-documents-warning-label" key={i}>
                      <Banner notDismissable enabled message={<div dangerouslySetInnerHTML={{__html: x}} />} type="warning" />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {trashModalErrors.length > 0 && <hr style={{marginTop: '1em'}} />}
        {trashModalErrors.map((x: string, i: number) => {
          return <Banner message={x} key={i} enabled={true} type="error" />;
        })}
      </div>
    );
  };

  return (
    <div className="context-menu-wrap">
      {modalBehaviorSingleFileSelected && (
        <ModalDialog
          modalTitle={modalBehaviorSingleFileSelected.title}
          modalMessage={modalBehaviorSingleFileSelected.message}
          modalInnerComponent={modalBehaviorSingleFileSelected.showBinContent ? <div>{renderExtraBinContent()}</div> : null}
          enableModal={showSingleFileSelectedModal}
          onAbort={() => {
            setShowSingleFileSelectedModal(false);
          }}
          onAccept={() => {
            modalBehaviorSingleFileSelected.onAccept(props.item);
          }}
        />
      )}
      <MoveToTrashSelectedDocuments
        showModal={showMultiRowsSelectedModal}
        setShowModal={setShowMultiRowsSelectedModal}
        deleteLevel={deleteLevel}
        setDeleteLevel={setDeleteLevel}
        documentIdsToDelete={documentIdsToDelete}
        checkDocumentVersionInfo={checkDocumentVersionInfo}
      />
      <DownloadDocumentModal
        enableModal={showDownloadModal}
        onClose={() => {
          setDocumentIDsToDownload([]);
          setShowDownloadModal(false);
        }}
        documentIds={documentIDsToDownload}
      />
      <div
        onMouseEnter={() => {
          loadMoreInfo();
        }}>
        <ActionButton
          iconProps={{iconName: 'More'}}
          menuProps={{
            items: menuActionsSingleFileSelected
              .filter((x: ICustomContextualMenuItem) => x.disabled !== true)
              .map((x: ICustomContextualMenuItem) => {
                let updt: any = {...x};
                updt.iconProps = {iconName: updt.iconName};
                return updt;
              }),
          }}
        />
      </div>
    </div>
  );
};

export default ContextMenu;
