import './MasterDataPicker.scss';
import {Checkbox, IPickerItemProps, ITag} from '@fluentui/react';
import React, {Dispatch, Fragment, useEffect, useState} from 'react';
import IntegrationsController from '../../Services/Controllers/IntegrationsController';
import {IAPIResponse} from '../../Services/AjaxService';
import TagPickerCustom from '../TagPickerCustom/TagPickerCustom';
import Icons from '../../Utils/FabricIconsOutlet';
import {randomColorFromString} from '../../Utils/StringUtils';
import {IBoValue} from '../../Models/IDocument';
import {IBoLibrary} from '../../Models/ILibrary';
import {ModalDialog} from '@Eni/docware-fe-master';
import { IMasterDataResponse, IMasterDataTag, IMasterdataResponseCheck, MasterDataDirection, iBoValueToIMasterDataTag, iMasterDataTagToIBoValue, masterDataConvertResponseToTag } from './Model';
import useFeature from '../FeatureFlag/useFeature';
import { MASTER_DATA_TERRITORIAL_MODAL_FF_KEY } from '../../Constants/FeatureFlags';
import { X_WARE_TERRITORIAL } from '../../Constants/MasterData';


export function getMasterData(
  masterDatas: IBoLibrary[],
  countriesIds: string[],
  initialStateMasterData: {[key: string]: IBoValue[]},
  onMasterDataChange: (
    firstMasterIntegrationId: string,
    firstMaster: IBoValue[],
    secondMasterIntegrationId: string,
    secondMaster: IBoValue[]
  ) => any,
  isOnSearch: boolean
): JSX.Element | JSX.Element[] {
  if (masterDatas.length < 2) {
    return <>Not enough Master Data</>;
  }
  if (masterDatas.length % 2 !== 0) {
    return <>Master Data must be in pairs</>;
  }

  const output: JSX.Element[] = [];
  for (let i = 0; i < masterDatas.length; i += 2) {
    const firstMaster = masterDatas[i];
    const secondMaster = masterDatas[i + 1];
    output.push(
      <MasterDataPicker
        key={i}
        firstMaster={{
          name: firstMaster.displayName,
          integrationId: firstMaster.key,
          initialState: (initialStateMasterData ?? {})[firstMaster.key] ?? [],
        }}
        secondMaster={{
          name: secondMaster.displayName,
          integrationId: secondMaster.key,
          initialState: (initialStateMasterData ?? {})[secondMaster.key] ?? [],
        }}
        onChange={onMasterDataChange}
        countriesIds={countriesIds}
        isOnSearch={isOnSearch}
      />
    );
  }
  return output;
}


interface IMasterDataPicker {
  name: string;
  integrationId: string;
  initialState: IBoValue[];
}

interface MasterDataPickerProps {
  firstMaster: IMasterDataPicker;
  secondMaster: IMasterDataPicker;
  onChange: (firstMasterIntegrationId: string, firstMaster: IBoValue[], secondMasterIntegrationId: string, secondMaster: IBoValue[]) => any;
  countriesIds: string[];
  isOnSearch: boolean;
}

const MasterDataPicker = (props: MasterDataPickerProps) => {
  const disableAutoComplete = props.isOnSearch;
  const [firstMaster, setFirstMaster] = useState<IMasterDataTag[]>([]);
  const [secondMaster, setSecondMaster] = useState<IMasterDataTag[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  // Modal for territorial:
  const [completeModalEnabled, setCompleteModalEnabled] = useState<boolean>(false);
  const [tempAddedTerritorialProject, setTempAddedTerritorialProject] = useState<IMasterDataTag | undefined>();
  const [tempTerritorialSurvey, setTempTerritorialSurvey] = useState<IMasterdataResponseCheck[] | undefined>();
  const territorialModalIsActive = useFeature(MASTER_DATA_TERRITORIAL_MODAL_FF_KEY)

  useEffect(() => {
    if (!initialized) {
      setFirstMaster(iBoValueToIMasterDataTag(props.firstMaster.initialState));
      setSecondMaster(iBoValueToIMasterDataTag(props.secondMaster.initialState));
      setTimeout(() => {
        setInitialized(true);
      }, 500);
    }
  }, [props.firstMaster.initialState, props.secondMaster.initialState]);

  useEffect(() => {
    if (initialized) {
      props.onChange(
        props.firstMaster.integrationId,
        iMasterDataTagToIBoValue(firstMaster),
        props.secondMaster.integrationId,
        iMasterDataTagToIBoValue(secondMaster)
      );
    }
  }, [firstMaster, secondMaster]);

  const resolveSuggestion = async (integrationId: string, search: string, keysToExclude: string[]): Promise<IMasterDataTag[]> => {
    if (search.trim() === '') {
      return [];
    }
    const response: IAPIResponse = await IntegrationsController.getMasterDataValues(integrationId, search, false, props.countriesIds, props.isOnSearch);
    if (response.error == null) {
      const payload: IMasterDataResponse[] = response.payload;
      const suggestions = masterDataConvertResponseToTag(payload);
      return suggestions.filter((x) => !keysToExclude.includes(x.key));
    } else {
      return [];
    }
  };

  const onAddTriggerOther = async (
    integrationId: string,
    search: string,
    addedElement: IMasterDataTag,
    masterDataDirection: MasterDataDirection,
  ): Promise<void> => {
    setLoading(true);
    const response: IAPIResponse = await IntegrationsController.getMasterDataValues(integrationId, search, true, props.countriesIds, props.isOnSearch);
    if (response.error == null) {
      const payload: IMasterDataResponse[] = response.payload;
      setLoading(false);
      completeMeAndOtherWithValues(
        addedElement,
        payload,
        masterDataDirection,
      )
    }
  }


  const completeMeAndOtherWithValues = (
    addedElement: IMasterDataTag,
    values: IMasterDataResponse[],
    masterDataDirection: MasterDataDirection,
    ) => {
    const {startMaster, targetMaster} = masterDataDirection
    // check if in the other master data there is already the same logical id
    const otherMasterDataSameIds = targetMaster.get.filter((x) => values.map((i) => i.id).includes(x.key));
    if (otherMasterDataSameIds.length > 0) {
      const groupID = otherMasterDataSameIds[0].groupId;
      // I don't add the id that are already in the group
      const masterDataFromPayloadFiltered = masterDataConvertResponseToTag(values, groupID).filter(
        (x) => !targetMaster.get.map((i) => i.key).includes(x.key)
      );
      targetMaster.set([...targetMaster.get, ...masterDataFromPayloadFiltered]);
      // I change also the groupId of the added element with that found in the other master data
      const addedElementWithChangedGroupId: IMasterDataTag = {...addedElement, groupId: groupID};
      startMaster.set([...startMaster.get, addedElementWithChangedGroupId]);
    } else {
      targetMaster.set([...targetMaster.get, ...masterDataConvertResponseToTag(values, addedElement.groupId)]);
      startMaster.set([...startMaster.get, addedElement]);
    }
  }

  const openTerritorialModal = async (
    integrationId: string,
    search: string,
    addedElement: IMasterDataTag,
  ) => {
    setLoading(true)
    setTempAddedTerritorialProject(addedElement)
    const response: IAPIResponse = await IntegrationsController.getMasterDataValues(integrationId, search, true, props.countriesIds, props.isOnSearch);
    if (response.error == null) {
      setLoading(false)
      setCompleteModalEnabled(true)
      const payload: IMasterDataResponse[] = response.payload;
      setTempTerritorialSurvey(payload.map(x => ({...x, checked: false})))
    }
  }

  const onAcceptTerritorialModal = () => {
    setCompleteModalEnabled(false)
    completeMeAndOtherWithValues(
      tempAddedTerritorialProject,
      tempTerritorialSurvey.filter(x => x.checked),
      {
        startMaster: {
          get: firstMaster,
          set: setFirstMaster
        },
        targetMaster: {
          get: secondMaster,
          set: setSecondMaster
        }
      }
    )
    setTempTerritorialSurvey(undefined)
    setTempAddedTerritorialProject(undefined)
  }



  const onRenderItem = (pickerItemProps: IPickerItemProps<IMasterDataTag>): JSX.Element => {
    const borderColor = randomColorFromString(pickerItemProps.item.groupId);
    const style = disableAutoComplete ? {} : {borderStyle: 'solid', borderColor: borderColor};

    return (
      <div key={pickerItemProps.item.key} className="tag-picker-tag-item" style={style}>
        <span className="tag-picker-tag-text" title={pickerItemProps.item.name}>
          {pickerItemProps.item.name}
        </span>
        <button type="button" className="tag-picker-tag-close-button" onClick={pickerItemProps.onRemoveItem}>
          <span className="tag-picker-tag-close-span">{Icons.getIcon('Cancel', '1em')}</span>
        </button>
      </div>
    );
  };



  return (
    <>
      <ModalDialog
        enableModal={completeModalEnabled}
        modalTitle={'Select one or more Territorial Survey'}
        modalInnerComponent={
          <div style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
            <Checkbox 
              label={"Select All"} 
              checked={tempTerritorialSurvey?.filter(t => !t.checked).length == 0} 
              onChange={(_, checked?: boolean) => {
                const t = tempTerritorialSurvey.map(k => ({...k, checked}))
                setTempTerritorialSurvey(t)
              }}
            />
            {tempTerritorialSurvey?.map((x, i) => (
              <Fragment key={x.id} >
                <Checkbox 
                  label={x.name} 
                  checked={x.checked} 
                  onChange={(_, checked?: boolean) => {
                    const t = tempTerritorialSurvey.map(k => (k.id === x.id ? {...k, checked} : {...k}))
                    setTempTerritorialSurvey(t)
                  }}
                />
              </Fragment>
            )
            )}
          </div>
        }
        onAbort={() => {
          setCompleteModalEnabled(false);
          setTempAddedTerritorialProject(undefined);
        }}
        onAccept={onAcceptTerritorialModal}
      />
      <div className="master-data-container" style={!disableAutoComplete ? {gap: '0.5em'} : {}}>
        {' '}
        {/*gap only in upload page*/}
        <div className="master-data-item">
          <div className="master-data-label">{props.firstMaster.name}</div>
          <TagPickerCustom
            className="tag-picker"
            onRenderItem={onRenderItem}
            disabled={loading}
            selectedItems={firstMaster}
            onResolveSuggestions={(search: string, selectedItems?: IMasterDataTag[]): Promise<IMasterDataTag[]> => {
              const keysToExclude = selectedItems.map((x) => x.key);
              return resolveSuggestion(props.firstMaster.integrationId, search, keysToExclude);
            }}
            onAdd={(selectedValues: IMasterDataTag[], addedElement: IMasterDataTag) => {
              if (disableAutoComplete) {
                setFirstMaster(selectedValues);
              } else {
                if(props.firstMaster.integrationId === X_WARE_TERRITORIAL && territorialModalIsActive){
                  openTerritorialModal(
                    props.secondMaster.integrationId,
                    addedElement.associatedId,
                    addedElement,
                  )
                } else {
                  onAddTriggerOther(
                    props.secondMaster.integrationId,
                    addedElement.associatedId,
                    addedElement,
                    {
                      startMaster: {
                        get: firstMaster,
                        set: setFirstMaster
                      },
                      targetMaster: {
                        get: secondMaster,
                        set: setSecondMaster
                      }
                    }
                  );
                }
              }
            }}
            onRemove={(selectedValues: IMasterDataTag[], removedElement: IMasterDataTag) => {
              if (disableAutoComplete) {
                setFirstMaster(selectedValues);
              } else {
                if (removedElement.groupId) {
                  const removedLastElementOfGroup = firstMaster.filter((x) => x.groupId == removedElement.groupId).length === 1;
                  if (removedLastElementOfGroup) {
                    setSecondMaster(secondMaster.filter((x) => x.groupId != removedElement.groupId));
                  }
                }
                setFirstMaster(selectedValues);
              }
            }}
          />
        </div>
        <div className="master-data-item">
          <div className="master-data-label">{props.secondMaster.name}</div>
          <TagPickerCustom
            className="tag-picker"
            onRenderItem={onRenderItem}
            disabled={loading}
            selectedItems={secondMaster}
            onResolveSuggestions={(search: string, selectedItems?: IMasterDataTag[]): Promise<IMasterDataTag[]> => {
              const keysToExclude = selectedItems.map((x) => x.key);
              return resolveSuggestion(props.secondMaster.integrationId, search, keysToExclude);
            }}
            onAdd={(selectedValues: IMasterDataTag[], addedElement: IMasterDataTag) => {
              if (disableAutoComplete) {
                setSecondMaster(selectedValues);
              } else {
                onAddTriggerOther(
                  props.firstMaster.integrationId,
                  addedElement.associatedId,
                  addedElement,
                  {
                    startMaster: {
                      get: secondMaster,
                      set: setSecondMaster
                    },
                    targetMaster: {
                      get: firstMaster,
                      set: setFirstMaster
                    }
                  }
                );
              }
            }}
            onRemove={(selectedValues: IMasterDataTag[], removedElement: IMasterDataTag) => {
              if (disableAutoComplete) {
                setSecondMaster(selectedValues);
              } else {
                if (removedElement.groupId) {
                  const removedLastElementOfGroup = secondMaster.filter((x) => x.groupId == removedElement.groupId).length === 1;
                  if (removedLastElementOfGroup) {
                    setFirstMaster(firstMaster.filter((x) => x.groupId != removedElement.groupId));
                  }
                }
                setSecondMaster(selectedValues);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MasterDataPicker;
