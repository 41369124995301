import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DocumentStatus} from '../../../Constants/Enums';
import {IDocumentSignStep, INewDocument} from '../../../Models/IDocument';
import {ILibrary, MetadataType} from '../../../Models/ILibrary';
import {GlobalState} from '../../../Reducers/RootReducer';
import AppRoutes, {concatUrl} from '../../../Utils/AppRoutes';
import {EnumUtils} from '../../../Utils/EnumUtils';
import {DocumentPickerEntry} from '../../../Components/DocumentPickerInput/DocumentPickerInput';
import {Banner, LabelButton, MenuTabs} from '@Eni/docware-fe-master';
import './DocumentSummary.scss';
import {useEffect, useState} from 'react';
import {DocumentSource} from '../../../Models/DocumentSource';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import {formatStringNumberPad} from '../../../Components/DynamicMetadata/DynamicMetadata';
import {cleanBoName} from '../../../Utils/NewDocumentUtils';

const NoData = '---';
const statusDict = EnumUtils.getDict(DocumentStatus);

const isPositiveInteger = (str: string) => {
  if (typeof str !== 'string') {
    return false;
  }

  const num = Number(str);

  if (Number.isInteger(num) && num > 0) {
    return true;
  }

  return false;
};

const AggregatedBoFullNameInferr = (name: string, metadata: any) => {
  if (!name) {
    name = '-';
  }

  if (!metadata) {
    return name.replaceAll('_', '|');
  }

  let values: string[] = Object.values(metadata ?? {});
  let noIdsValues: string[] = [];
  for (let i = 0; i < values.length; i++) {
    let str: string = values[i];
    if (str && !isPositiveInteger(str) && str.length > 3) {
      noIdsValues.push(values[i]);
    }
  }

  return noIdsValues.join(' | ');
};

const renderWFTableSection = (signers: (IDocumentSignStep | null)[]) => {
  return (
    <table style={{width: '100%'}}>
      <thead>
        <tr>
          {signers.map((x: IDocumentSignStep | null, i: number) => {
            return x === undefined ? (
              <th key={i}></th>
            ) : (
              <th key={i} className="summary-table-th w-33">
                Approval Step {x.order + 1}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          {signers.map((x: IDocumentSignStep | null, i: number) => {
            return x === undefined ? (
              <th key={i}></th>
            ) : (
              <td key={i} className="summary-table-td">
                {x.users.length > 0 ? x.users.map((a) => a.name).join(', ') : NoData}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

const renderWFTable = (signers: IDocumentSignStep[]) => {
  let tableData: JSX.Element[] = [];

  for (let i = 0; i < signers.length; i += 3) {
    let _1: IDocumentSignStep | undefined = signers[i];
    let _2: IDocumentSignStep | undefined = signers[i + 1];
    let _3: IDocumentSignStep | undefined = signers[i + 2];

    tableData.push(<div key={i}>{renderWFTableSection([_1, _2, _3])}</div>);
  }

  return tableData;
};


interface DocumentSummaryProps {
  onDraftSave: () => void;
  onCompleteSave: () => void;
}

export const DocumentSummary = (props: DocumentSummaryProps) => {
  const navigate = useNavigate();

  let generic = useSelector((state: GlobalState) => state.generic);
  const document = useSelector<GlobalState, INewDocument>((state) => state.newDocument.document);
  const selectedLibrary = useSelector<GlobalState, ILibrary>((state) => state.newDocument.selectedLibrary);
  const approvalNeeded = useSelector<GlobalState, number>((state) => state.generic.documentUploadApprovalFlag);
  const [canSuperseded, setCanSuperseded] = useState(false);
  const [canRelatedTo, setCanRelatedTo] = useState(false);
  const libraries = useSelector<GlobalState, ILibrary[]>((state) => state.newDocument.userLibraries);
  const [docSources, setDocSources] = useState<DocumentSource[]>([]);

  const getSourceLabelFromKey = (sourceKey: string) => {
    let matched: DocumentSource[] = docSources.filter((x: DocumentSource) => x.id === sourceKey);
    if (matched.length > 0) {
      return matched[0].name;
    }
    return null;
  };

  useEffect(() => {
    ApiService.DocumentSourcesController.getDocumentSource((response: IAPIResponse) => {
      if (response.error === null && response.payload !== null) {
        setDocSources(response.payload);
      }
    });

    if (document.professionalArea) {
      libraries.forEach((library) => {
        library.professionalAreas.forEach((professionalArea) => {
          if (professionalArea.id === document.professionalArea.id) {
            setCanSuperseded(library.allowSuperseded);
            setCanRelatedTo(library.allowRelatedTo);
          }
        });
      });
    }
  }, []);

  const goBack = () => {
    if (document != null && document.documentId) {
      navigate(concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_BO) + window.location.search);
    }
  };

  const listDynamicMetadata = () => {
    let dynamicMeta = selectedLibrary.metadata || [];
    let dynamicMetaFromForm = document.metadata ?? {};
    let results = [];
    for (let i = 0; i < dynamicMeta.length; i++) {
      let m: any = dynamicMeta[i];
      let value: any = null;

      //* the required dynamic field exists */
      if (dynamicMetaFromForm[m.name]) {
        if (m.type === MetadataType.Text) {
          value = dynamicMetaFromForm[m.name].stringValue;
        } else if (m.type === MetadataType.Date) {
          value = new Date(dynamicMetaFromForm[m.name].dateValue).toLocaleDateString();
        } else if (m.type === MetadataType.Select) {
          value = dynamicMetaFromForm[m.name].selectValue;
        } else if (m.type === MetadataType.MultiSelect) {
          value = dynamicMetaFromForm[m.name].multiselectValue;
        } else if (m.type === MetadataType.AutoIncrement) {
          value = formatStringNumberPad(dynamicMetaFromForm[m.name].numberValue);
        } else if (m.type === MetadataType.TagPickerSingleSelect) {
          value = dynamicMetaFromForm[m.name].selectValue;
        }
      }

      if (m.required || value != null) {
        results.push({label: m.displayName, value: value});
      }
    }

    return results;
  };

  const produceMetadataHtmlTable = (dynamicMeta: any[]) => {
    return (
      <table style={{width: '100%'}}>
        <thead>
          <tr>
            {dynamicMeta.map((x, i) => {
              return (
                <th key={i} className="summary-table-th w-33">
                  {x.label}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {dynamicMeta.map((x, i) => {
              let data = '---';
              if (x.value) {
                if (x.value.name) {
                  data = x.value.name;
                } else {
                  data = x.value;
                }
              }
              return (
                <td key={i} className="summary-table-td">
                  {data}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  };

  const produceAllDynamicMetadataTables = (dynamicMeta: any[]) => {
    let htmls = [];
    for (let i = 0; i < dynamicMeta.length; i += 3) {
      htmls.push(<div key={i}>{produceMetadataHtmlTable(dynamicMeta.slice(i, i + 3))}</div>);
    }
    return htmls;
  };

  const dynamicMeta = document ? produceAllDynamicMetadataTables(listDynamicMetadata()) : [];
  const matchingDocumentType = document?.documentTypeName;
  const matchingDocumentSubType = document?.documentSubTypeName;

  return (
    <div>
      <MenuTabs
        tabs={[
          {
            label: 'Info',
            callback: () => {
              navigate(concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_METADATA) + window.location.search);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Business Objects',
            callback: () => {
              navigate(concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_BO) + window.location.search);
            },
            isPassed: true,
            isEvident: false,
          },
          {
            label: 'Confirm',
            callback: () => {},
            isPassed: false,
            isEvident: true,
          },
        ]}
        leftText={''}
        rightText={'100%'}
      />

      {document && (
        <div>
          <div className="new-doc-summary-section">
            <div className="summary-edit-label">
              <LabelButton
                text={'Edit'}
                onClick={() => {
                  navigate(
                    concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_METADATA) + window.location.search
                  );
                }}
              />
            </div>
            <div className="summary-section-title">Info</div>
            {document.documentCode && <div className="summary-document-code">DOCUMENT CODE: {document.documentCode}</div>}
            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <th className="summary-table-th w-33">Document Name</th>
                  <th className="summary-table-th w-33">Document Type</th>
                  <th className="summary-table-th w-33">Security Level</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="summary-table-td">{document.documentName ? document.documentName : NoData}</td>
                  <td className="summary-table-td">
                    {matchingDocumentType != null
                      ? matchingDocumentType + (matchingDocumentSubType ? ', ' + matchingDocumentSubType : '')
                      : NoData}
                  </td>
                  <td className="summary-table-td">{document.securityLevel}</td>
                </tr>
              </tbody>
            </table>

            <div className="summary-table-separator"></div>

            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <th className="summary-table-th w-50">Country</th>
                  <th className="summary-table-th w-50">Status</th>
                  <th className="summary-table-th w-50">Area</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="summary-table-td">{document.countries ? document.countries.map((c) => c.name).join(', ') : NoData}</td>
                  <td className="summary-table-td">{document.documentStatus != null ? statusDict[document.documentStatus] : NoData}</td>
                  <td className="summary-table-td">{document.professionalArea ? document.professionalArea.name : NoData}</td>
                </tr>
              </tbody>
            </table>

            <div className="summary-table-separator"></div>

            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <th className="summary-table-th w-33">Document Date</th>
                  <th className="summary-table-th w-33">Authors</th>
                  <th className="summary-table-th w-33">White List</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="summary-table-td">
                    {document.documentDate ? new Date(document.documentDate).toLocaleDateString() : NoData}
                  </td>
                  <td className="summary-table-td">
                    {document.authors && document.authors.length > 0 ? document.authors.map((a) => a.name).join(', ') : NoData}
                  </td>
                  <td className="summary-table-td">
                    {document.whitelist && document.whitelist.length > 0 ? document.whitelist.map((a) => a.name).join(', ') : NoData}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="summary-table-separator" />
            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <th className="summary-table-th w-33">Tags</th>
                  {canSuperseded && <th className="summary-table-th w-33">Replaced Documents (Superseded)</th>}
                  {canRelatedTo && <th className="summary-table-th w-33">Related Documents</th>}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="summary-table-td">{document.tags ? document.tags.join(', ') : NoData}</td>
                  {canSuperseded && (
                    <td className="summary-table-td">
                      {document.relatedDocuments
                        ? document.relatedDocuments
                            .map((x: DocumentPickerEntry) => {
                              return x.documentName ?? '-';
                            })
                            .join(', ')
                        : NoData}
                    </td>
                  )}
                  {canRelatedTo && (
                    <td className="summary-table-td">
                      {document.linkedDocuments
                        ? document.linkedDocuments
                            .map((x: DocumentPickerEntry) => {
                              return x.documentName ?? '-';
                            })
                            .join(', ')
                        : NoData}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>

            <div className="summary-table-separator" />
            <table style={{width: '100%'}}>
              <thead>
                <tr>
                  <th className="summary-table-th w-33">Document Source</th>
                  <th className="summary-table-th w-33">Description</th>
                  <th className="summary-table-th w-33">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="summary-table-td">{document.documentSource ? getSourceLabelFromKey(document.documentSource) : NoData}</td>
                  <td className="summary-table-td">{document.description ? document.description : NoData}</td>
                  <td className="summary-table-td">{document.remarks ? document.remarks : NoData}</td>
                </tr>
              </tbody>
            </table>

            {dynamicMeta.length > 0 && (
              <div>
                <div className="summary-table-separator-dynamic-title">Dynamic Metadata</div>
              </div>
            )}
            {dynamicMeta}
          </div>

          {document.signers && document.signers.length > 0 && approvalNeeded > 0 && (
            <div className="new-doc-summary-section">
              <div className="summary-edit-label">
                <LabelButton
                  text={'Edit'}
                  onClick={() => {
                    navigate(
                      concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_METADATA) + window.location.search
                    );
                  }}
                />
              </div>
              <div className="summary-section-title">Approval Workflow</div>
              {renderWFTable(document.signers)}
            </div>
          )}

          {document.bOs != null && (
            <div>
              {Object.keys(document.bOs).length > 0 && (
                <div className="new-doc-summary-section">
                  <div className="summary-edit-label">
                    <LabelButton
                      text={'Edit'}
                      onClick={() => {
                        navigate(
                          concatUrl(AppRoutes.DOCUMENT_CREATION_ROUTE, AppRoutes.DOCUMENT_CREATION_ROUTE_BO) + window.location.search
                        );
                      }}
                    />
                  </div>
                  <div className="summary-section-title">Business Objects</div>
                  {Object.keys(document.bOs).map((key: string, i: number) => {
                    let businessObject: any = document.bOs[key];
                    let prettyName = cleanBoName(key, libraries);
                    let value = [NoData];
                    let renderingAggregatedBos = false;
                    if (businessObject) {
                      value = businessObject.map((x: any, i: number) => {
                        if (x.additionalInfo) {
                          renderingAggregatedBos = true;
                          return x.additionalInfo.join(', ');
                        } else if (x.isBOAggregate) {
                          renderingAggregatedBos = true;
                          return AggregatedBoFullNameInferr(x.name, x.metadata);
                        } else {
                          return x.name ? x.name.replaceAll('_', '|') : 'null';
                        }
                      });
                    }
                    return (
                      <div key={i}>
                        <table style={{width: '100%'}}>
                          <thead>
                            <tr>
                              <th className="summary-table-th w-33">Business Object</th>
                              <th className="summary-table-th w-66">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="summary-table-td">{prettyName}</td>
                              <td className="summary-table-td">
                                {renderingAggregatedBos && (
                                  <div>
                                    {value.map((x: string, i: number) => {
                                      return (
                                        <div className="summary-table-aggregated-bo-line" key={i}>
                                          {x}
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {!renderingAggregatedBos && <div>{value.join(', ')}</div>}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {i < Object.keys(document.bOs).length - 1 && <div className="summary-table-separator"></div>}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {generic.tightErrorBos && (
        <Banner
          message={
            <div>
              You are forbidden to save this document. You have selected one or more <strong>tight Business Object</strong> for which you
              don't have permissions on. Go back and remove those selections to unlock the buttons below.
            </div>
          }
          enabled
          type="error"
        />
      )}
      {generic.tightErrorCountry && (
        <Banner
          message={
            <div>
              You are forbidden to save this document. You have selected one or more <strong>tight Country</strong> for which you don't have
              permissions on. Go back and remove those selections to unlock the buttons below.
            </div>
          }
          enabled
          type="error"
        />
      )}

      <div className="nav-button-zone">
        <LabelButton text="Back" icon="ChromeBack" whiteOutlined onClick={() => goBack()} />
        <div className="on-row">
          {document && (document.documentStatus === 0 || document.documentStatus === 3) && (
            <LabelButton
              text="Save as Draft"
              disabled={generic.tightErrorBos || generic.tightErrorCountry}
              icon="SaveAs"
              whiteOutlined
              onClick={() => props.onDraftSave()}
            />
          )}
          <div style={{width: '2em'}} />
          <LabelButton
            text="Save & Complete"
            disabled={generic.tightErrorBos || generic.tightErrorCountry}
            icon="Save"
            orangeSolid
            onClick={() => props.onCompleteSave()}
          />
        </div>
      </div>
    </div>
  );
};
