import {concatRoute} from './Methods';

export const HOME_ROUTE = `/drive`;
export const MESSAGE_PAGE = concatRoute(HOME_ROUTE, 'message');
export const DOCUMENT_CREATION_ROUTE = concatRoute(HOME_ROUTE, 'new-document');
export const DOCUMENT_CREATION_ROUTE_WILDCARD = concatRoute(DOCUMENT_CREATION_ROUTE, '*');
export const DOCUMENT_CREATION_ROUTE_METADATA = `/`;
export const DOCUMENT_CREATION_ROUTE_BO = `/bo`;
export const DOCUMENT_CREATION_ROUTE_SUMMARY = `/summary`;
export const ACTIVITIES_ROUTE = concatRoute(HOME_ROUTE, 'activities');
export const STARRED_ROUTE = concatRoute(HOME_ROUTE, 'starred');
export const MY_DOCUMENTS_ROUTE = concatRoute(HOME_ROUTE, 'mydocuments');
export const TRASH_ROUTE = concatRoute(HOME_ROUTE, 'trash');
export const DOCUMENT_MANAGEMENT = concatRoute(HOME_ROUTE, 'document-management');
export const DOCUMENT_SEARCH = concatRoute(HOME_ROUTE, 'document-search');
export const DOCUMENT_SEARCH_WILDCARD = concatRoute(DOCUMENT_SEARCH, '*');
export const DOCUMENT_SEARCH_ALL = '/';
export const DOCUMENT_SEARCH_IMAGES = '/search-images';
export const DOCUMENT_SEARCH_MAP = '/search-map';
export const DOCUMENT_SEARCH_ATTACHMENTS = '/search-attachments';
export const ADMIN_ROUTE = concatRoute(HOME_ROUTE, 'admin');
export const ADMIN_ROUTE_WILDCARD = concatRoute(ADMIN_ROUTE, '*');
export const ADMIN_ROUTE_USERS_LIST = '/users-list';
export const ADMIN_ROUTE_DOCUMENT_SOURCES = '/document-sources';
export const ADMIN_ROUTE_INPUT_INFO = '/input-info-list';
export const ADMIN_ROUTE_WORKFLOW = '/workflow-editor';
export const ADMIN_ROUTE_DOC_TYPES = '/document-types';
export const ADMIN_SYS_LOGS = '/sys-logs';
export const ADMIN_ROUTE_TRASH = '/admin-trash';
export const ADMIN_ROUTE_NEWS = '/admin-news';
export const ADMIN_ROUTE_LIBRARY = '/library';
export const ADMIN_ROUTE_DOC_TYPE_SUBTYPE = '/admin-doc-types';
export const DOCUMENT_MANAGEMENT_SIGNATURE = concatRoute(DOCUMENT_MANAGEMENT, 'approve');
export const USER_INFO = concatRoute(HOME_ROUTE, 'user-info');
export const USER_REQUEST = concatRoute(HOME_ROUTE, 'user-request');
export const MASSIVE_IMPORT_ROUTE = concatRoute(HOME_ROUTE, 'massive-import');
export const MASSIVE_IMPORT_ROUTE_WILDCARD = concatRoute(MASSIVE_IMPORT_ROUTE, '*');
export const MASSIVE_IMPORT_ROUTE_FILES = `/`;
export const MASSIVE_IMPORT_ROUTE_METADATA = `/metadata`;
export const VIDEOS_TUTORIAL = concatRoute(HOME_ROUTE, 'videos-tutorial');
export const VIDEOS_TUTORIAL_LIST = concatRoute(VIDEOS_TUTORIAL, `list`);
export const VIDEO_TUTORIAL_VIDEO = concatRoute(VIDEOS_TUTORIAL, `video`);
export const VIDEO_TUTORIAL_VIDEO_NAME = concatRoute(VIDEO_TUTORIAL_VIDEO, `:videoName`);
export const NEW_LIB_PAGE = concatRoute(HOME_ROUTE, `new-lib`);
export const NEW_LIB_PAGE_WILDCARD = concatRoute(NEW_LIB_PAGE, '*');
export const NEW_LIB_PAGE_GENERAL_INFO = `/`;
export const NEW_LIB_PAGE_PROFESSIONAL_AREAS = `/professional-areas`;
export const NEW_LIB_PAGE_ELEMENT_TYPES = `/element-types`;
export const NEW_LIB_PAGE_DOCUMENT_TYPES = `/document-types`;
export const NEW_LIB_PAGE_DOCUMENT_SUB_TYPES = `/document-subtypes`;
export const NEW_LIB_PAGE_BUSINESS_OBJECT = `/business-object`;
export const NEW_LIB_PAGE_EXTRA_METADATA = `/extra-metadata`;
export const DOWNLOAD_BLOB = concatRoute(HOME_ROUTE, 'download/blob/:blobId');

const AppRoutes = {
  HOME_ROUTE,
  DOCUMENT_CREATION_ROUTE,
  DOCUMENT_CREATION_ROUTE_WILDCARD,
  DOCUMENT_CREATION_ROUTE_METADATA,
  DOCUMENT_CREATION_ROUTE_BO,
  DOCUMENT_CREATION_ROUTE_SUMMARY,
  ACTIVITIES_ROUTE,
  STARRED_ROUTE,
  MY_DOCUMENTS_ROUTE,
  MESSAGE_PAGE,
  TRASH_ROUTE,
  DOCUMENT_MANAGEMENT,
  DOCUMENT_MANAGEMENT_SIGNATURE,
  DOCUMENT_SEARCH,
  DOCUMENT_SEARCH_WILDCARD,
  DOCUMENT_SEARCH_ALL,
  DOCUMENT_SEARCH_IMAGES,
  DOCUMENT_SEARCH_MAP,
  DOCUMENT_SEARCH_ATTACHMENTS,
  ADMIN_ROUTE,
  ADMIN_ROUTE_WILDCARD,
  ADMIN_ROUTE_USERS_LIST,
  ADMIN_ROUTE_DOCUMENT_SOURCES,
  ADMIN_ROUTE_INPUT_INFO,
  ADMIN_ROUTE_DOC_TYPES,
  ADMIN_ROUTE_WORKFLOW,
  ADMIN_ROUTE_TRASH,
  ADMIN_ROUTE_NEWS,
  ADMIN_ROUTE_LIBRARY,
  ADMIN_SYS_LOGS,
  ADMIN_ROUTE_DOC_TYPE_SUBTYPE,
  USER_INFO,
  USER_REQUEST,
  MASSIVE_IMPORT_ROUTE,
  MASSIVE_IMPORT_ROUTE_WILDCARD,
  MASSIVE_IMPORT_ROUTE_FILES,
  MASSIVE_IMPORT_ROUTE_METADATA,
  VIDEOS_TUTORIAL,
  VIDEOS_TUTORIAL_LIST,
  VIDEO_TUTORIAL_VIDEO_NAME,
  NEW_LIB_PAGE,
  NEW_LIB_PAGE_WILDCARD,
  NEW_LIB_PAGE_GENERAL_INFO,
  NEW_LIB_PAGE_PROFESSIONAL_AREAS,
  NEW_LIB_PAGE_ELEMENT_TYPES,
  NEW_LIB_PAGE_DOCUMENT_TYPES,
  NEW_LIB_PAGE_DOCUMENT_SUB_TYPES,
  NEW_LIB_PAGE_BUSINESS_OBJECT,
  NEW_LIB_PAGE_EXTRA_METADATA,
  DOWNLOAD_BLOB,
};

export const concatUrl = (url1: string, url2: string) => {
  let finalUrl = url1;
  if (url1.endsWith('/')) {
    finalUrl = url1.substring(0, url1.length - 1);
  }

  if (url1.startsWith('/')) {
    finalUrl = finalUrl + '/' + url2.substring(1, url2.length);
  }

  return finalUrl;
};

export default AppRoutes;
