import {IOptionDto} from './IOptionDto';
import {removeDuplicatesFromArrayOfObject} from '../Utils/DictUtils';

export interface ILibraryDocumentSubTypeBase {
  id: string;
  name: string;
  isActive: boolean;
  professionalAreaIds?: string[];
  mandatoryBOs?: string[];
  mandatoryMasterDatas?: string[];
  addedInThisSession?: boolean;
}

export interface ILibraryDocumentSubTypeNewLib extends ILibraryDocumentSubTypeBase {
  documentType: string;
}

export interface ILibraryDocumentType {
  id: string;
  name: string;
  isActive: boolean;
  professionalAreaIds?: string[];
  addedInThisSession?: boolean;
  subTypes?: ILibraryDocumentSubTypeBase[];
}

export interface IProfessionalArea {
  id: string;
  name: string;
  isActive: boolean;
  userManagementEnabled: boolean;
  securityLevels?: string[];
  defaultSecurityLevel?: string;
  addedInThisSession?: boolean;
}

export interface IElementTypes {
  id: string;
  isAdmin: boolean;
  isManuallyManaged: boolean;
  name: string;
  addedInThisSession: boolean | null;
}

export interface ILibrary {
  id: string;
  name: string;
  professionalAreas: IProfessionalArea[];
  metadata: IMetadata[];
  documentTypes: ILibraryDocumentType[];
  bOs: IBoLibrary[];
  boSearch: IBOSearch[];
  masterDatas: IBoLibrary[];
  boAggregations: IBOAggregated[];
  boAggregationsSearch: IBOAggregated[];
  allowWhitelistOutsideLibrary: boolean;
  allowAuthorsOutsideLibrary: boolean;
  allowSuperseded: boolean;
  defaultRestrictedWhitelistTypes?: string[];
  allowRelatedTo: boolean;
  defaultCountryId: string | null;
  securityLevels: string[] | null;
  defaultSecurityLevel: string | null;
  allowedTypesInWorkflow: string[] | null;
  documentCodeDefinition: string[];
  isDocumentCodeVisible: boolean;
  maxWorkflowSignersGroups: number;
  elementTypes: IElementTypes[];
  skipDocTypeSelectionIfOnlyOne: boolean;
  checkTightBOs: boolean;
  active: boolean;
  permissionModel: string;
  allowVersioning: boolean;
  exportStatus: IExportStatus;
  defaultSignature: string;
  availableSignatures: string[];
}

export enum IExportStatus {
  WorkInProgress,
  ReadyToDeploy,
  UpToDate,
}

export interface ILibraryNewLib extends Omit<ILibrary, 'bOs' | 'boAggregations'> {
  documentSubTypes: ILibraryDocumentSubTypeNewLib[];
  bOs: string[];
  boAggregations: string[];
}

export interface IMetadata {
  name: string;
  displayName: string;
  type: MetadataType;
  required: boolean;
  values?: IOptionMetadata[];
  lockedOnNewVersion: boolean;
  searchable: boolean;
  showAdvancedFilterInFE: boolean;
  inputPositioning?: number;
  liveValidation?: boolean;
  addedInThisSession?: boolean;
}

export interface IOptionMetadata extends IOptionDto {
  addedInThisSession?: boolean;
}

export interface IBoLibrary {
  name: string;
  displayName: string;
  key: string;
  required: boolean;
  source: string;
  searchable: boolean;
  lockedOnNewVersion: boolean;
  cardColor: string;
  textColor: string;
  searchTextPattern: string;
  isExclusive: boolean;
  isLegacyBusinessObject: boolean;
}

export interface IBOAggregated extends IBoLibrary {
  searchTitle: string;
  tableTitle: string;
}

export interface IBOSearch extends IBoLibrary {
  substituteBOs: ISubstituteBO[];
}

export interface ISubstituteBO {
  key: string;
  keyTemplate: string | null;
}

export enum MetadataType {
  Text,
  Date,
  Select,
  MultiSelect,
  AutoIncrement,
  TagPickerSingleSelect,
}

export interface HierarchyItem {
  id: string;
  name: string;
  isActive: boolean;
}

export interface HierarchyDocType extends HierarchyItem {
  subTypes: HierarchyItem[];
}

export interface HierarchyProfessionalArea extends HierarchyItem {
  documentTypes: HierarchyDocType[];
}

export interface HierarchyLibrary extends HierarchyItem {
  professionalAreas: HierarchyProfessionalArea[];
  bOs: IBoLibrary[];
  boAggregations: IBOAggregated[];
  boAggregationsSearch: IBOAggregated[];
  boSearch: IBOSearch[];
  masterDatas: IBoLibrary[];
  countries: {key: string; name: string}[];
  metadata: IMetadata[];
}

export interface IBoColor {
  name: string;
  displayName: string;
  cardColor: string;
  textColor: string;
}

export interface ILibrarySummary {
  professionalAreaName: string;
  professionalAreaId: string;
  mainFilesSize: bigint;
  attachmentsSize: bigint;
  mainFilesCount: bigint;
  attachmentsCount: bigint;
}

export const LibraryFunctions = {
  getSecurityLevels: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const securityLevelsWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue) => {
      return accumulator.concat(currentValue.securityLevels);
    }, []);
    return [...new Set(securityLevelsWithDuplicates)];
  },
  getSignatures: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const signaturesWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue) => {
      return accumulator.concat(currentValue.availableSignatures);
    }, []);
    return [...new Set(signaturesWithDuplicates)];
  },
  getElementTypesNames: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const getElementTypesWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue: ILibrary) => {
      return accumulator.concat(currentValue.elementTypes?.map((x) => x.name) ?? []);
    }, []);
    return [...new Set(getElementTypesWithDuplicates)];
  },
  getElementTypes: (libraries?: (ILibrary | ILibraryNewLib)[]): IElementTypes[] => {
    const getElementTypesWithDuplicates = libraries?.reduce<IElementTypes[]>((accumulator: IElementTypes[], currentValue: ILibrary) => {
      return accumulator.concat(currentValue.elementTypes ?? []);
    }, []);
    return [...new Set(getElementTypesWithDuplicates)];
  },
  getProfessionalAreasNames: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const profAreasWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue: ILibraryNewLib) => {
      return accumulator.concat(currentValue.professionalAreas?.map((x) => x.name) ?? []);
    }, []);
    return [...new Set(profAreasWithDuplicates)];
  },
  getProfessionalAreas: (libraries?: (ILibrary | ILibraryNewLib)[]): IProfessionalArea[] => {
    const profAreasWithDuplicates = libraries?.reduce<IProfessionalArea[]>(
      (accumulator: IProfessionalArea[], currentValue: ILibraryNewLib) => {
        return accumulator.concat(currentValue.professionalAreas ?? []);
      },
      []
    );
    return [...new Set(profAreasWithDuplicates)];
  },
  getDocumentTypesNames: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const getDocumentTypesWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue: ILibrary) => {
      return accumulator.concat(currentValue.documentTypes?.map((x) => x.name) ?? []);
    }, []);
    return [...new Set(getDocumentTypesWithDuplicates)];
  },
  getDocumentSubTypesNames: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const getDocumentTypesWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue: ILibrary) => {
      const documentSubTypes =
        currentValue.documentTypes?.reduce<string[]>((a: string[], cv: ILibraryDocumentType) => {
          const subTypes = cv.subTypes?.map((x) => x.name) ?? [];
          return a.concat(subTypes);
        }, []) ?? [];
      return accumulator.concat(documentSubTypes);
    }, []);
    return [...new Set(getDocumentTypesWithDuplicates)];
  },
  getMetadataNames: (libraries?: (ILibrary | ILibraryNewLib)[]): string[] => {
    const getMetadataWithDuplicates = libraries?.reduce<string[]>((accumulator: string[], currentValue: ILibrary) => {
      return accumulator.concat(currentValue.metadata?.map((x) => x.displayName) ?? []);
    }, []);
    return [...new Set(getMetadataWithDuplicates)];
  },
  getILibraryNewLibFromILibrary: (library: ILibrary): ILibraryNewLib => {
    const documentSubTypes = library.documentTypes?.reduce<ILibraryDocumentSubTypeNewLib[]>((acc, cV) => {
      const documentSubTypes = cV.subTypes.map((x) => ({...x, documentType: cV.name}));
      return acc.concat(documentSubTypes);
    }, []);
    const bOsKeys = library.bOs?.map((x) => x.key) ?? [];
    const boAggKeys = library.boAggregations?.map((x) => x.key) ?? [];
    return {...library, documentSubTypes: documentSubTypes, bOs: bOsKeys, boAggregations: boAggKeys};
  },
  ILibraryNewLibToBackendFormat: (library: ILibraryNewLib) => {
    const beRequiredFields = {
      name: '',
      metadata: [],
      professionalAreas: [],
      elementTypes: [],
      documentTypes: [],
      permissionModel: '',
      documentCodeDefinition: [],
      supportEmail: 'docware@eni.com',
    };
    let beLibrary = {...library};
    beLibrary.documentTypes =
      library?.documentTypes?.map((documentType) => {
        const documentSubTypes = library?.documentSubTypes?.filter((x) => x.documentType == documentType.name) ?? [];
        return {...documentType, subTypes: documentSubTypes};
      }) ?? [];
    beLibrary.permissionModel = beLibrary.permissionModel ?? '';

    return {...beRequiredFields, ...beLibrary};
  },
  convertHierarchyLibraryToIbOsColor: (libraries: HierarchyLibrary[]): IBoColor[] => {
    const bosColors: IBoColor[] = [];

    libraries.forEach((library) => {
      library.bOs.forEach((bo) => {
        bosColors.push({name: bo.name, displayName: bo.displayName, cardColor: bo.cardColor, textColor: bo.textColor});
      });
      library.boAggregations.forEach((boA) => {
        bosColors.push({
          name: boA.name,
          displayName: boA.displayName,
          cardColor: boA.cardColor,
          textColor: boA.textColor,
        });
      });
      library.masterDatas.forEach((masterData) => {
        bosColors.push({
          name: masterData.name,
          displayName: masterData.displayName,
          cardColor: masterData.cardColor,
          textColor: masterData.textColor,
        });
      });
    });
    return removeDuplicatesFromArrayOfObject(bosColors, 'name');
  },
};
