import {useAppInsightsContext, useTrackEvent} from '@microsoft/applicationinsights-react-js';
import {getTrackingComponent} from './../../AppInsights';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import Icons from '../../Utils/FabricIconsOutlet';
import DocumentPreview from '../../Components/DocumentPreview/DocumentPreview';
import {LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import './DocumentManagementPage.scss';
import {FileInfo} from '../../Models/FileUtils';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/AjaxService';
import DocumentDetailColumn from '../../Components/DocumentDetailColumn/DocumentDetailColumn';
import {EDocumentSignatureType, IDocumentVersion, IDocumentVersionItem, INewDocument} from '../../Models/IDocument';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import {enhanceAppTitle} from '../../AppLayout';
import {DetailBox} from '../../Components/DetailBox/DetailBox';
import {GlobalState} from '../../Reducers/RootReducer';
import {RecentSearchItem} from '../../Models/RecentSearch';
import {
  IWorkflowItem,
  IWorkflowSignatureAction,
  IWorkflowSigner,
  IWorkflowSignStep,
  IWorkflowUser,
  WorkflowSignatureAction,
} from '../../Models/IWorkflowItem';
import {IPermissionByData, IUser} from '../../Models/IUser';
import AppRoutes from '../../Utils/AppRoutes';
import FullPageMessage, {setPageContentState} from '../FullPageMessage/FullPageMessage';
import {DocumentPickerEntry, DocumentPickerEntryVoice} from '../../Components/DocumentPickerInput/DocumentPickerInput';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../Components/FluentUIDecorator/FluentUIDecorator';
import {getInputInfos} from '../../Reducers/Generic/GenericAction';
import {Spinner} from '@fluentui/react';
import {getLatestVersion} from '../../Utils/VersioningUtils';
import DocumentEditLockController from '../../Services/Controllers/DocumentEditLockController';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import SignatureUtils from '../../Utils/SignatureUtils';

export const initVersionTracks = async (versionTrackId: string) => {
  let versionItems: IDocumentVersionItem[] = [];

  if (versionTrackId) {
    let response: IAPIResponse = await ApiService.VersioningController.getVersionById(versionTrackId);
    if (response.error === null) {
      let versionsBase: IDocumentVersion | null = response.payload;
      if (versionsBase) {
        // create a sorted copy of the array
        let rawVersionItems: IDocumentVersionItem[] = [...versionsBase.versions];
        rawVersionItems.sort((x: IDocumentVersionItem, y: IDocumentVersionItem) => x.version - y.version);

        // for each version voice
        for (let i = 0; i < rawVersionItems.length; i++) {
          let vItem: IDocumentVersionItem = rawVersionItems[i];

          // test current document in list is oldversion
          let docResponse: IAPIResponse = await ApiService.DocumentController.getFlagDocumentAsOld(vItem.referenceId);

          if (docResponse.error === null) {
            let analizedDocumentOldVersionFlag: boolean = docResponse.payload;

            // add voice
            versionItems.push(rawVersionItems[i]);

            // stop appending if this coument is not flagged as old
            if (analizedDocumentOldVersionFlag === false) {
              break;
            }
          }
          // something wrong
          else {
            break;
          }
        }
      }
    }
  }

  return {versions: versionItems, id: versionTrackId};
};

const anyRejection = (targetList: IWorkflowSigner[]) => {
  let allSigned: IWorkflowSigner[] = targetList.filter((x: IWorkflowSigner) => userHasSigned(x));
  for (let i = 0; i < allSigned.length; i++) {
    let signer: IWorkflowSigner = allSigned[i];
    if (signer.userSignStatus === 2 || signer.delegates.filter((x: IWorkflowSigner) => x.userSignStatus === 2).length > 0) {
      return true;
    }
  }
  return false;
};

const userHasSigned = (user: IWorkflowSigner) => {
  return user.userSignStatus !== 0 || user.delegates.filter((x: IWorkflowSigner) => x.userSignStatus !== 0).length > 0;
};

const getUserAppearenceInSignatureList = (userId: string, targetList: IWorkflowSigner[]) => {
  let yourAppearence: IWorkflowSigner[] = targetList.filter(
    (x: IWorkflowSigner) => x.userId === userId || x.delegates.filter((x: IWorkflowSigner) => x.userId === userId).length > 0
  );
  return yourAppearence.length > 0;
};

const yourSignatureStatus = (userId: string, targetList: IWorkflowSigner[]) => {
  let allUsersWhoMissSignature: IWorkflowSigner[] = targetList.filter((x: IWorkflowSigner) => !userHasSigned(x));

  for (let i = 0; i < allUsersWhoMissSignature.length; i++) {
    let signer: IWorkflowSigner = allUsersWhoMissSignature[i];

    // you are a main signature designee
    if (signer.userId === userId) {
      return true;
    }
    // you are one of the delegates
    else {
      for (let j = 0; j < signer.delegates.length; j++) {
        let delegatedSigner: IWorkflowUser = signer.delegates[j];

        // you are a main signature designee
        if (delegatedSigner.userId === userId) {
          return true;
        }
      }
    }
  }

  return false;
};

export const getWorkflowCount: (workflow: IWorkflowItem | null, loggedUser: IUser | undefined) => WorkFlowState = (
  workflow: IWorkflowItem | null,
  loggedUser: IUser | undefined
) => {
  if (workflow && loggedUser) {
    let rejected = false;
    for (let i = 0; i < workflow.signers.length; i++) {
      rejected = anyRejection(workflow.signers[i].users);
    }

    let totalSignaturesRequired: number = 0;

    let usersWhoSigned: IWorkflowSigner[] = [];
    for (let i = 0; i < workflow.signers.length; i++) {
      totalSignaturesRequired += workflow.signers[i].users.length;
      usersWhoSigned.concat(workflow.signers[i].users.filter((x: IWorkflowSigner) => userHasSigned(x)));
    }

    let activeStep: IWorkflowSignStep = workflow.signers[workflow.levelOfCompletion];

    let youShouldSign: boolean = yourSignatureStatus(loggedUser.id, activeStep.users);

    let youAppearInCurentStep: boolean = getUserAppearenceInSignatureList(loggedUser.id, activeStep.users);

    return {
      signed: usersWhoSigned.length,
      totalSignaturesRequired: totalSignaturesRequired,
      youShould: youShouldSign,
      display: youAppearInCurentStep,
      isRejected: rejected,
      workflowStatus: workflow.status,
    };
  } else {
    return {
      signed: 0,
      totalSignaturesRequired: 0,
      youShould: false,
      display: false,
      isRejected: false,
      workflowStatus: -1,
    };
  }
};

export interface WorkFlowState {
  signed: number;
  totalSignaturesRequired: number;
  youShould: boolean;
  display: boolean;
  workflowStatus: number;
  isRejected: boolean;
}

const DocumentManagementPage = () => {
  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  /** application insight */
  const appInsights = useAppInsightsContext();
  const trackDocumentSignature = useTrackEvent(appInsights, 'DocumentSignature', {
    UserId: loggedUser.id,
    UserName: loggedUser.firstName + ' ' + loggedUser.lastName,
    UserLibraries: loggedUser.UserLibraries,
    UserProfAreas: loggedUser.UserProfAreas,
    ExternalSignatureService: false,
  });

  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'DocumentManagementPage'}
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const [rejectReason, setRejectReason] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userMightSign, setUserMightSign] = useState<number>(-1);
  const [userHasNoSignaturesModal, setUserHasNoSignaturesModal] = useState<boolean>(false);
  const [showModalApprovalDev, setShowModalApprovalDev] = useState<boolean>(false);
  const [spinnerBar, setSpinnerBar] = useState<boolean>(false);
  const [activeFile, setActiveFileInner] = useState<FileInfo | null>(null);
  const [document, setDocument] = useState<INewDocument | null>(null);
  const [activeFileType, setActiveFileType] = useState<'main' | 'signature' | 'attachment'>('main');
  const [concludedWorkflow, setConcludedWorkflow] = useState<IWorkflowItem | null>(null);
  const [workflow, setWorkflow] = useState<IWorkflowItem | null>(null);
  const [versions, setVersions] = useState<IDocumentVersion | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const documentId = params.get('documentid');
  const [currentDocumentPermissions, setCurrentDocumentPermissions] = useState<IPermissionByData | null>(null);
  const [confirmUnlockDocument, setConfirmUnlockDocument] = useState<boolean>(false);
  const [loadingUnlocking, setLoadingUnlocking] = useState<boolean>(false);
  const currentUser = useSelector((state: GlobalState) => state.user.currentUser);

  const setActiveFile = (file: FileInfo | null, type: 'main' | 'signature' | 'attachment') => {
    if (file) {
      if (activeFile?.url !== file.url) {
        setActiveFileType(type);
        setActiveFileInner(file);
      }
    }
  };

  const loadDocumentWorkFlow = (documentId: string) => {
    if (documentId) {
      ApiService.WorkflowController.getById(documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          setWorkflow(response.payload);

          // if there is no active workflow, try to see if there is a non active workflow
          // this one is used only for UI purposes and it never interacts with the signature controls or its logics
          if (response.payload === null) {
            ApiService.WorkflowController.inspectById(documentId, (response: IAPIResponse) => {
              if (response.error == null) {
                setConcludedWorkflow(response.payload);
              }
              setSpinnerBar(false);
            });
          } else {
            // standard workflow with signature
            if (response?.payload?.signatureType === EDocumentSignatureType.DigitalSignature) {
              // load user signature status
              ApiService.SignatureController.getAvailableSignatures((response: IAPIResponse) => {
                if (response.error === null) {
                  let data: any = response.payload.data;
                  if (data) {
                    let signatures: string[] = data.split(';').filter((x) => x === 'Firma non qualificata');
                    setUserMightSign(signatures.length > 0 ? 1 : 0);
                  } else {
                    let standardErrorOpener =
                      'Ops! The signature service raised an error about your user profile. You cannot sign right now. The reason is: ';
                    let message = response.payload.message;
                    let showMessage = message ? standardErrorOpener + message : 'Ops! The signature service is not available right now...';

                    window.document.dispatchEvent(
                      new CustomEvent('api-toast-result', {
                        detail: {
                          text: showMessage,
                          type: 'warning',
                        },
                      })
                    );
                  }
                } else {
                  window.document.dispatchEvent(
                    new CustomEvent('api-toast-result', {
                      detail: {
                        text: 'Ops! The signature service is not available right now...',
                        type: 'warning',
                      },
                    })
                  );
                }
              });
            } else if (response?.payload?.signatureType === EDocumentSignatureType.SimpleApproval) {
              setUserMightSign(1);
            }
          }
        }
        setSpinnerBar(false);
      });
    }
  };

  const documentError = (
    title: string,
    message: string,
    imageName: string,
    documentId: string,
    buttonDescriptions?: {label: string; path: string}
  ) => {
    /** define with this call what will be visible in the MESSAGE_PAGE */
    let desc = buttonDescriptions ? buttonDescriptions : {label: 'Go back to Home', path: AppRoutes.HOME_ROUTE};
    setPageContentState({
      title: title,
      subtitle: message,
      smallSubtitle: documentId,
      imageName: imageName,
      button: {
        icon: 'ChromeBackMirrored',
        text: desc.label,
        onClick: {
          type: 'navigate',
          arguments: desc.path,
        },
      },
    });

    /** goto the MESSAGE_PAGE. this must happen after calling setPageContentState */
    navigate(AppRoutes.MESSAGE_PAGE);
  };

  const loadDocumentPermissions = (documentId: string) => {
    setCurrentDocumentPermissions(null);
    ApiService.DocumentController.getUserPermissionsOnDocument(documentId, (response: IAPIResponse) => {
      if (response.error === null) {
        let perms: IPermissionByData = response.payload;
        setCurrentDocumentPermissions(perms);

        if (!perms.canRead) {
          documentError('Document not available.', "You don't have permission to read this document.", 'accessDeniedDocument', documentId);
        }
      }
    });
  };

  const reloadPage = () => {
    if (document) {
      setLoading(true);
      loadDocumentPermissions(document.documentId);
      ApiService.DocumentController.getDocument(document.documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          let documentData = response.payload;

          if (documentData.documentActivation > 1) {
            documentError('Document deleted!', 'This document has been deleted.', 'documentNotfoundCat', document.documentId);
            return;
          }

          loadDocumentWorkFlow(document.documentId);
          setDocument(documentData);
          setLoading(false);
        } else {
          if (response.raw.status === 403) {
            documentError(
              'Document not available.',
              "You don't have permission to read this document.",
              'accessDeniedDocument',
              document.documentId
            );
          } else if (response.raw.status === 406) {
            documentError(
              'Document is Tight.',
              "You don't have permission to access this document.",
              'documentNotExistRocket',
              document.documentId
            );
          } else if (response.raw.status === 404) {
            documentError('Document not available.', 'This document does not exist.', 'documentNotExistRocket', document.documentId);
          } else {
            documentError(
              'Ops! Something went wrong.',
              'There was a problem opening this document.',
              'documentNotExistRocket',
              document.documentId
            );
          }
        }
      });
    }
  };

  const loadVersionTracks = async (versionTrackId: string) => {
    let tracks: any = await initVersionTracks(versionTrackId);
    (window as any)['version-tracks'] = tracks;
    setVersions(tracks);
  };

  const initPage = () => {
    if (documentId) {
      setLoading(true);
      loadDocumentPermissions(documentId);

      ApiService.DocumentController.getDocument(documentId, (response: IAPIResponse) => {
        if (response.error == null) {
          let documentData = response.payload;

          if (documentData.documentActivation > 1) {
            documentError('Document deleted!', 'This document has been deleted.', 'documentNotfoundCat', documentId);
            return;
          }

          loadDocumentWorkFlow(documentId);
          setDocument(documentData);
          setLoading(false);

          if (loggedUser && documentData.mainFile) {
            ApiService.RecentSearchController.addRecendSearch(
              {
                id: '', // this forces id creation on mongo side
                userId: loggedUser.id,
                documentId: documentData.documentId,
                date: new Date().toISOString(),
                documentName: documentData.documentName,
                documentExtension: ((fname: string) => {
                  let n = fname.split('.');
                  return n[n.length - 1];
                })(documentData.mainFile?.fileName),
              } as RecentSearchItem,
              () => {}
            );
          }

          dispatch(DocumentListAction.setActiveDocument(documentData));
          enhanceAppTitle(documentData.documentName);

          if (documentData.versionTrack) {
            loadVersionTracks(documentData.versionTrack);
          }

          let preselectFileObj: any = sessionStorage.getItem('open-this-file-version-nav');
          if (preselectFileObj) {
            sessionStorage.removeItem('open-this-file-version-nav');
            let obj: any = JSON.parse(preselectFileObj);

            let matchingFilesId = documentData.attachments.filter((x: FileInfo) => x.id === obj.file.id);
            let matchingFilesName = documentData.attachments.filter((x: FileInfo) => x.fileName === obj.file.fileName);
            let targFile = obj.file;

            if (matchingFilesId.length > 0) {
              targFile = matchingFilesId[0];
            } else {
              if (matchingFilesName.length > 0) {
                targFile = matchingFilesName[0];
              }
            }

            setActiveFile(targFile, obj.type);
          } else {
            setActiveFile(documentData.mainFile, 'main');
          }
        } else {
          if (response.raw.status === 403) {
            documentError(
              'Document not available.',
              "You don't have permission to read this document.",
              'accessDeniedDocument',
              documentId
            );
          } else if (response.raw.status === 406) {
            documentError('Document is Tight.', "You don't have permission to access this document.", 'documentNotExistRocket', documentId);
          } else if (response.raw.status === 404) {
            documentError('Document not available.', 'This document does not exist.', 'documentNotExistRocket', documentId);
          } else {
            documentError('Ops! Something went wrong.', 'There was a problem opening this document.', 'documentNotExistRocket', documentId);
          }
        }
      });
    }
  };

  useEffect(() => {
    initPage();
  }, [documentId]);

  let wflowState: WorkFlowState = getWorkflowCount(workflow, loggedUser);
  let documentVersionsSlot = versions ?? {versions: []};
  let versionContent =
    documentVersionsSlot.versions.length > 1 ? (
      <DetailBox
        title={'Versions (' + documentVersionsSlot.versions.length.toString() + ')'}
        data={[
          {
            title: null,
            data: (
              <div>
                {documentVersionsSlot.versions
                  .sort((a: IDocumentVersionItem, b: IDocumentVersionItem) => {
                    return b.version - a.version;
                  })
                  .map((x: IDocumentVersionItem, i: number) => {
                    return (
                      <div key={i} className="document-management-version-outer-box">
                        <div
                          className={
                            'document-management-version-inner-box ' +
                            (document?.documentId === x.referenceId ? 'document-management-version-inner-box-selected' : '')
                          }>
                          <div>{Icons.getIcon('Edit')}</div>
                          <div style={{marginLeft: '1.5em'}}>
                            <div className="document-management-version-inner-box-label">
                              {'Version number ' + x.version + (x.version === documentVersionsSlot.versions.length ? ' (latest)' : '')}
                            </div>
                            <Link to={'.?documentid=' + x.referenceId}>
                              {document?.documentId === x.referenceId ? 'Displayed now' : 'See this version'}
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ),
          },
        ]}
      />
    ) : (
      <div></div>
    );

  let extraColumns = [];

  // uncomment to add versions box to UI
  // extraColumns.push({position: 2, content: versionContent});

  if (document?.documentStatus === 1 || document?.documentStatus === 5) {
    if (document?.relatedDocuments && document?.relatedDocuments.length > 0) {
      let superseededLinks = (
        <DetailBox
          title={'Replaced Documents (' + document?.relatedDocuments.length.toString() + ')'}
          data={[
            {
              title: null,
              data: (
                <div>
                  {document?.relatedDocuments.map((x: DocumentPickerEntry, i: number) => {
                    let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + x.documentId.toString();
                    return (
                      <Link to={navUrl} style={{marginRight: '0.2em', cursor: 'pointer'}} key={i}>
                        <DocumentPickerEntryVoice entry={{...x, readonly: true}} onClick={() => {}} />
                      </Link>
                    );
                  })}
                </div>
              ),
            },
          ]}
        />
      );

      extraColumns.push({position: 3, content: superseededLinks});
    }
  }

  if (document?.linkedDocuments && document?.linkedDocuments.length > 0) {
    let linkedDocuments = (
      <DetailBox
        title={'Related Documents (' + document?.linkedDocuments.length.toString() + ')'}
        data={[
          {
            title: null,
            data: (
              <div>
                {document?.linkedDocuments.map((x: DocumentPickerEntry, i: number) => {
                  return (
                    <span
                      style={{marginRight: '0.2em', cursor: 'pointer'}}
                      key={i}
                      onClick={() => {
                        navigate('.?documentid=' + x.documentId);
                      }}>
                      <DocumentPickerEntryVoice entry={{...x, readonly: true}} onClick={() => {}} />
                    </span>
                  );
                })}
              </div>
            ),
          },
        ]}
      />
    );

    extraColumns.push({position: 4, content: linkedDocuments});
  }

  const trusdedLink = SignatureUtils.getSignatureRequestUrl()

  if (document && document.mainFile === null) {
    setPageContentState({
      title: 'Main Document not ready.',
      subtitle: 'This document does not have a Main Document yet.',
      smallSubtitle: documentId,
      imageName: 'documentNotExistRocket',
      button: {
        icon: 'ChromeBackMirrored',
        text: 'Edit this document',
        onClick: {
          type: 'navigate',
          arguments: AppRoutes.DOCUMENT_CREATION_ROUTE + '?documentid=' + documentId.toString() + '&mode=edit',
        },
      },
    });
  }

  return (
    <div>
      {(loading || currentDocumentPermissions === null) && (
        <div className="document-management-loading-spinner">
          <Spinner label="Loading..." />
        </div>
      )}
      {!(loading || currentDocumentPermissions === null) && (
        <div>
          <ModalDialog
            modalTitle={'Missing signature'}
            onAbort={() => {
              setUserHasNoSignaturesModal(false);
            }}
            modalInnerComponent={
              <div>
                To approve a document you need to have an active signature. Please request it via{' '}
                <u>
                  <Link target="_blank" to={trusdedLink}>
                    this trusted link
                  </Link>
                </u>
                , for information about how to create a signature please download the Signature Guide.
              </div>
            }
            enableModal={userHasNoSignaturesModal}
            modalButtons={[
              {
                onClick: () => {
                  setUserHasNoSignaturesModal(false);
                },
                label: 'Cancel',
              },
              {
                onClick: () => {
                  let url = trusdedLink;
                  window.open(url, '_blank').focus();
                },
                label: 'Request signature',
              },
            ]}
          />
          <ModalDialog
            modalTitle={'Reject the document?'}
            onAbort={() => {
              setShowModal(false);
            }}
            modalInnerComponent={
              <div>
                A document should be rejected when it doesn't meet all the approval requirements or when it contains errors.
                <br />
                <br />
                Rejecting a document will cause the conclusion of the Approval Workflow. This action cannot be undone.
                <br />
                <br />
                If you are sure you want to <strong>Reject</strong> the document, please provide a rejecting reason:
                <br />
                <FluentUIDecorator
                  label="Reject Reason"
                  info={getInputInfos('document-management', 'reject-reason')}
                  required={true}
                  fluentComponent={FluentUIDecoratorTypes.TextField({
                    maxLength: 500,
                    value: rejectReason,
                    onChange: (e, t) => setRejectReason(t),
                  })}
                />
              </div>
            }
            enableModal={showModal}
            modalButtons={[
              {
                onClick: () => {
                  setShowModal(false);
                },
                label: 'Cancel',
              },
              {
                disabled: rejectReason === '',
                onClick: () => {
                  setShowModal(false);
                  if (workflow && loggedUser) {
                    let workflowSignature: IWorkflowSignatureAction = {
                      workflowId: workflow.workflowId,
                      userSignStatus: WorkflowSignatureAction.rejected,
                      comment: rejectReason,
                    };
                    setSpinnerBar(true);
                    ApiService.WorkflowController.insertWorkflowSetSignature(workflowSignature, (response: IAPIResponse) => {
                      if (response.error == null) {
                        reloadPage();
                      }
                    });
                  }
                },
                label: 'Reject',
              },
            ]}
          />
          <ModalDialog
            modalTitle={'Approve the document?'}
            onAbort={() => {
              setShowModalApprovalDev(false);
            }}
            modalInnerComponent={
              <div>
                A document should be approved when it meets all the approval requirements and when it does not contain errors.
                <br />
                <br />
                This action cannot be undone.
                <br />
                <br />
                Are you sure you want to <strong>Approve</strong> the document?
              </div>
            }
            enableModal={showModalApprovalDev}
            modalButtons={[
              {
                onClick: () => {
                  setShowModalApprovalDev(false);
                },
                label: 'Cancel',
              },
              {
                onClick: () => {
                  setShowModalApprovalDev(false);
                  if (workflow && loggedUser) {
                    let workflowSignature: IWorkflowSignatureAction = {
                      workflowId: workflow.workflowId,
                      userSignStatus: WorkflowSignatureAction.signed,
                    };
                    setSpinnerBar(true);
                    ApiService.WorkflowController.insertWorkflowSetSignature(workflowSignature, (response: IAPIResponse) => {
                      if (response.error == null) {
                        reloadPage();
                      }
                    });
                    trackDocumentSignature({
                      UserId: loggedUser.id,
                      UserName: loggedUser.firstName + ' ' + loggedUser.lastName,
                      UserLibraries: loggedUser.UserLibraries,
                      UserProfAreas: loggedUser.UserProfAreas,
                      ExternalSignatureService: false,
                    });
                  }
                },
                label: 'Approve',
              },
            ]}
          />
          <div className="document-management-basic-page-wrap general-page-container">
            <div className="document-management-left-section-outer fill-width-on-small-screen">
              <DriveDocumentManagementToolbar
                type={document && document.mainFile ? 'actions-on-open-document' : 'actions-on-open-draft-document'}
                fileType={activeFileType}
                currentDocument={document}
              />
              <div className="document-management-left-section">
                {wflowState.display && (
                  <div
                    className={
                      'document-management-pending-approval-bar flex-column-on-small-screen ' +
                      (wflowState.isRejected
                        ? 'document-management-rejected-approval-bar'
                        : wflowState.signed === wflowState.totalSignaturesRequired
                        ? 'document-management-approved-approval-bar'
                        : '')
                    }>
                    {wflowState.signed !== wflowState.totalSignaturesRequired && !wflowState.isRejected && (
                      <div className="document-management-spaced-div">
                        <span className="document-management-pending-minor-span">{Icons.getIcon('Clock')}</span>
                        <span className="document-management-pending-minor-span">Pending Approval</span>
                      </div>
                    )}

                    {wflowState.signed === wflowState.totalSignaturesRequired && !wflowState.isRejected && (
                      <div className="document-management-spaced-div">
                        <span className="document-management-pending-minor-span">{Icons.getIcon('SkypeCircleCheck')}</span>
                        <span className="document-management-pending-minor-span">Approval concluded</span>
                      </div>
                    )}

                    {wflowState.isRejected && (
                      <div className="document-management-spaced-div">
                        <span className="document-management-pending-minor-span">{Icons.getIcon('StatusErrorFull')}</span>
                        <span className="document-management-pending-minor-span">Approval rejected</span>
                      </div>
                    )}

                    {spinnerBar && (
                      <div className="document-management-spaced-div document-management-spaced-div-has-spinner">
                        <Spinner labelPosition="right" label="Please wait..." />
                      </div>
                    )}

                    {!spinnerBar && (
                      <div className="document-management-spaced-div">
                        {wflowState.isRejected && wflowState.youShould && (
                          <span className="document-management-pending-span">You can't sign a rejected document</span>
                        )}
                        {!wflowState.isRejected && wflowState.youShould && (
                          <span className="document-management-pending-span document-management-pending-span-button">
                            <VideoTutorialIcon page="document-management-workflow-sign" style={{color: 'unset'}} />
                            <div style={{width: '1em'}} />
                            <LabelButton
                              whiteOutlined
                              darkBackground
                              onClick={() => {
                                setShowModal(true);
                              }}
                              text={'Reject'}
                              icon={'Cancel'}
                            />
                            <div style={{width: '1em'}} />
                            <LabelButton
                              blueSolid
                              disabled={userMightSign < 0}
                              onClick={() => {
                                if (document) {
                                  if (
                                    process.env.REACT_APP_ALLOW_DEVELOPER_APPROVAL_BUTTON === 'true' ||
                                    workflow.signatureType === EDocumentSignatureType.SimpleApproval
                                  ) {
                                    setShowModalApprovalDev(true);
                                  } else {
                                    if (userMightSign < 1) {
                                      setUserHasNoSignaturesModal(true);
                                      return;
                                    }

                                    navigate(`${AppRoutes.DOCUMENT_MANAGEMENT_SIGNATURE}?documentid=${document.documentId}`);
                                  }
                                }
                              }}
                              text={'Approve'}
                              icon={'CheckMark'}
                            />
                          </span>
                        )}
                        {wflowState.isRejected && !wflowState.youShould && (
                          <span className="document-management-pending-span">You rejected this document</span>
                        )}
                        {!wflowState.isRejected && !wflowState.youShould && (
                          <span className="document-management-pending-span">You already signed</span>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {document && document.isOldVersion && versions && versions.versions.length > 1 && (
                  <div className="document-management-old-version-banner-wrap">
                    <div className="document-management-old-version-banner-wrap-label">This is an OLD version of a newer document.</div>
                    <LabelButton
                      blueSolid
                      text="See latest version"
                      icon='Forward'
                      onClick={() => {
                        let latest: IDocumentVersionItem | null = getLatestVersion(versions);
                        if (latest) {
                          navigate(`${AppRoutes.DOCUMENT_MANAGEMENT}?documentid=${latest.referenceId}`);
                        }
                      }}
                    />
                  </div>
                )}
                {document?.lockByUsers.filter(u => u.id != currentUser.id).length > 0 && (
                  <div className='document-management-locked-document-bar'>
                    <div className='document-management-spaced-div'>
                      {Icons.getIcon('Lock')} &nbsp; Document locked
                      by {document.lockByUsers.map((x) => x.name).join(', ')}
                    </div>
                    {loggedUser.isSuperAdmin && (
                      <div className='document-management-spaced-div'>
                        {loadingUnlocking ? (
                          <Spinner label='Unlocking...' labelPosition='right' />
                        ) : confirmUnlockDocument ? (
                          <div className='document-management-pending-span document-management-pending-span-button'>
                            <LabelButton
                              text="Cancel"
                              onClick={() => {
                                setConfirmUnlockDocument(false);
                              }}
                            />
                            <div style={{width: '1em'}} />
                            <LabelButton
                              text="Confirm Unlock"
                              onClick={() => {
                                setLoadingUnlocking(true);
                                DocumentEditLockController.unlock(document.documentId, (response) => {
                                  setConfirmUnlockDocument(false);
                                  setLoadingUnlocking(false);
                                  reloadPage();
                                });
                              }}
                            />
                          </div>
                        ) : (
                          <LabelButton
                            text="Unlock"
                            onClick={() => {
                              setConfirmUnlockDocument(true);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}

                {document && document.mainFile && (
                  <DocumentPreview
                    signatureFile={document.mainFileForSignature}
                    title={document.documentName}
                    currentDocumentId={documentId}
                    currentFile={activeFile}
                    reduceHeight={wflowState.display || document.isOldVersion}
                    isAttachment={activeFileType === 'attachment'}
                  />
                )}
                {document && document.mainFile === null && <FullPageMessage />}
              </div>
            </div>
            <nav className="document-management-right-section hide-on-small-screen">
              <DocumentDetailColumn
                openFileInfo={{fileName: activeFile ? activeFile.fileName : '-', fileType: activeFileType}}
                versions={versions ? versions.versions : []}
                extraColumnsItems={extraColumns}
                workflow={workflow ?? concludedWorkflow}
                document={document}
                onFileSelected={setActiveFile}
              />
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default getTrackingComponent(DocumentManagementPage, 'DocumentManagementPage');
