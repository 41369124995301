import {Spinner} from '@fluentui/react';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {FileList, LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {formatFileSize} from '../../Components/FileUploadBoxPreview/FileUploadBoxPreview';
import {IDocumentItem} from '../../Models/IDocument';
import {GlobalState} from '../../Reducers/RootReducer';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {getTrackingComponent} from './../../AppInsights';
import ContextMenu from '../../Components/ContextMenu/ContextMenu';
import './TrashPage.scss';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import store from '../../Reducers/Store';
import PermissionsOnDocuments from '../../Utils/PermissionsOnDocuments';
import {UserActions} from '../../Reducers/User/UserAction';
import {IPermissionByData} from '../../Models/IUser';
import {getDocumentLabel, getDocumentLabelString} from '../../Utils/ComponentsUtils';
import AttachmentSmallBrowserButton from '../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';

const TrashPage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'TrashPage'}
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** redux reference to documents  */
  let documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  /** this state is only used as hook to request specific actions across the whole site */
  let generic = useSelector((state: GlobalState) => state.generic);

  const [loadAmount, setLoadAmount] = useState<number>(50);
  const [documentsToDelete, setDocumentsToDelete] = useState<string[]>([]);
  const [reloadTrashed, setreloadTrashed] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [trash, setTrash] = useState<IDocumentItem[]>([]);
  let currentPermissions = useSelector((state: GlobalState) => state.user.permissionsForDocuments);
  useEffect(() => {
    let trashIds = trash.map((x: IDocumentItem) => {
      return x.documentId;
    });

    PermissionsOnDocuments.getDocumentPermissions(currentPermissions, trashIds, (result: IPermissionByData[]) => {
      store.dispatch(UserActions.setPermissionsByDocuments(result));
    });
  }, [trash]);

  useEffect(() => {
    /** test body */
    const documentSearchBody: any = {
      //"search": "",
      limit: 200,
      offset: 0,
    };

    /** API call to get favorite documents */
    setLoading(true);
    ApiService.DocumentController.getDeletedDocuments(documentSearchBody, (response: IAPIResponse) => {
      if (response.error == null) {
        setTrash(response.payload);
      } else {
        setTrash([]);
      }
      setLoading(false);
    });

    dispatch(DocumentListAction.setActiveDocument(null));
  }, [reloadTrashed, generic.reloadRequest]);

  for (let i = 0; i < trash.length; i++) {
    if (trash[i].mainFile) {
      trash[i]['fileSize'] = formatFileSize(trash[i].mainFile.size);
    }
  }

  return (
    <div className="general-page-container">
      <DriveDocumentManagementToolbar
        type={
          documentSearch.activeDocument != null
            ? 'actions-on-trash-document'
            : documentSearch.activeDocuments.length === 0
            ? 'navigation-voices'
            : 'action-on-multi-select-trash-documents'
        }
      />{' '}
      <div className="trash-page-main-wrap">
        <div className="trash-page-title">
          User Trash Bin
          <VideoTutorialIcon />
        </div>
        {loading && (
          <div className="trash-page-spinner-wrap-main">
            <div className="trash-page-spinner-inner">
              <Spinner label="Loading..." />
            </div>
          </div>
        )}

        <ModalDialog
          modalTitle={'Delete permanently?'}
          modalMessage={'Documents deleted in this way are deleted and removed from the trash page. Proceed anyway?'}
          enableModal={showModal}
          onAbort={() => {
            setShowModal(false);
          }}
          onAccept={async () => {
            setShowModal(false);

            for (let i = 0; i < documentsToDelete.length; i++) {
              let id: string = documentsToDelete[i];
              /** this pattern is needed in order to be able to await each file deletion */
              let result = await ApiService.DocumentController.setDeletedDocument({
                documentId: id,
                documentActivation: 3,
              });
            }

            setreloadTrashed(!reloadTrashed);
          }}
        />

        {!loading && (
          <div>
            <div style={{marginTop: '1em'}}>
              <LabelButton
                whiteOutlined
                text={'Empty Trash Bin'}
                icon={'RecycleBin'}
                onClick={() => {
                  setDocumentsToDelete(
                    trash.map((x) => {
                      return x.documentId;
                    })
                  );
                  setShowModal(true);
                }}
              />
            </div>
            <FileList
              defaultSortOnThisColumnIndex={2}
              fileIconFromField={'mainFile.fileName'}
              onItemsSelected={(selectedRows: any[]) => {
                if (selectedRows.length === 0) {
                  store.dispatch(DocumentListAction.setActiveDocument(null));
                  store.dispatch(DocumentListAction.setActiveDocuments([]));
                }
                if (selectedRows.length === 1) {
                  store.dispatch(DocumentListAction.setActiveDocument(selectedRows[0]));
                  store.dispatch(DocumentListAction.setActiveDocuments([]));
                }
                if (selectedRows.length > 1) {
                  store.dispatch(DocumentListAction.setActiveDocument(null));
                  store.dispatch(DocumentListAction.setActiveDocuments(selectedRows));
                }
              }}
              currentSelectedItems={documentSearch.activeDocuments}
              currentSelectedItem={documentSearch.activeDocument}
              hideBottomButton={true}
              columns={[
                {
                  name: 'File Type',
                  dataType: 'string',
                  fieldName: 'name',
                  iconName: 'Page',
                  size: 'tiny',
                },
                {
                  name: '',
                  dataType: 'string',
                  fieldName: '',
                  iconName: '',
                  size: 'micro',
                  onRender: (item: IDocumentItem) => {
                    return (
                      <div className="file-name-column-cell">
                        <span className="file-name-column-cell-buttons">
                          <ContextMenu
                            item={item}
                            type={'bin'}
                            emitAction={(type: string) => {
                              if (type === 'trash') {
                                setreloadTrashed(!reloadTrashed);
                              }
                            }}
                          />
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Name',
                  dataType: 'string',
                  fieldName: 'documentName',
                  iconName: null,
                  size: 'large',
                  onRender: (item: IDocumentItem) => {
                    let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();
                    return (
                      <div className="file-name-column-cell">
                        <span
                          title={getDocumentLabelString(item.documentName, item.documentCode)}
                          className="file-name-column-cell-buttons-label file-linkname"
                          onClick={() => {
                            navigate(navUrl);
                          }}>
                          {getDocumentLabel(item.documentName, item.documentCode, navUrl)}
                          <div className="file-name-file-inline-button">
                            <AttachmentSmallBrowserButton ownerDocument={item} />
                          </div>
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Created by',
                  dataType: 'string',
                  fieldName: 'createdBy.name',
                  iconName: null,
                  size: 'medium',
                },
                {
                  name: 'Modified',
                  dataType: 'date',
                  fieldName: 'updateDate',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Professional Area',
                  dataType: 'string',
                  fieldName: 'library',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Last modified',
                  dataType: 'date',
                  fieldName: 'updateDate',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'File size',
                  dataType: 'number',
                  fieldName: 'fileSize',
                  iconName: null,
                  size: 'small',
                },
              ]}
              items={trash}
            />
            {trash.length === loadAmount && (
              <div className="trash-page-centered-button">
                <LabelButton
                  icon="ChevronDown"
                  whiteOutlined
                  text="Load more documents"
                  onClick={() => {
                    setLoadAmount(loadAmount + 50);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default getTrackingComponent(TrashPage, 'TrashPage');
