import Icons from '../../Utils/FabricIconsOutlet';
import './ButtonNoBorder.scss';

export interface ButtonNoBorderProps {
  onClick: () => any;
  buttonLabel: string;
  buttonIcon: string;
  buttonDisable: boolean;
}

const ButtonNoBorder = (props: ButtonNoBorderProps) => {
  const onClick = () => {
    if (!props.buttonDisable) {
      props.onClick();
    }
  };

  return (
    <div className={props.buttonDisable ? 'button-no-border-container-disabled' : 'button-no-border-container-enabled'} onClick={onClick}>
      {Icons.getIcon(props.buttonIcon, '1em')}
      <span className="button-no-border-text">{props.buttonLabel}</span>
    </div>
  );
};

export default ButtonNoBorder;
