import {Dropdown, IDropdownOption, IDropdownStyles} from '@fluentui/react/lib/Dropdown';
import {ESeverity} from '../../../Models/New';

interface ISeverityDropdownProps {
  selected: number;

  onChange(event: any): void;
}

export const SeverityDropdown = (props: ISeverityDropdownProps) => {
  const dropdownStyles: Partial<IDropdownStyles> = {dropdown: {width: '100%'}};

  let severityDropdownOptions: IDropdownOption[] = [];
  let selected = false;
  let text: string;
  for (let i = 0; i <= 2; i++) {
    selected = i === props.selected ? true : false;
    text = i === 1 ? 'warning' : ESeverity[i];
    text = text.charAt(0).toUpperCase() + text.slice(1);
    severityDropdownOptions.push({key: i, text: text, selected: selected});
  }

  return (
    <Dropdown
      label="Severity"
      selectedKey={props.selected === -1 ? undefined : props.selected}
      onChange={props.onChange}
      placeholder="Select severity"
      options={severityDropdownOptions}
      styles={dropdownStyles}
    />
  );
};
