import './TeamsFilePicker.scss';
import {MutableRefObject, useEffect, useState} from 'react';
import ApiService from '../../Services/ApiService';
import {IAPIResponse} from '../../Services/AjaxService';
import {ITeam, ITeamGetItemResponse, ITeamItem} from '../../Models/ITeam';
import {IBreadcrumbItem, INavLink, Nav, SelectionMode, Spinner} from '@fluentui/react';
import {FileList as FileListC, LabelButton} from '@Eni/docware-fe-master';
import EventDispatcher from '../../Utils/EventDispatcher';
import {BreadcrumbFixed} from '../BreadcrumbFixed/BreadcrumbFixed';

interface ITeamsBreadcrumbItem extends IBreadcrumbItem {
  driveid: string; // lowercase otherwise react warnings
}

interface ITeamsNavLinkGroup {
  links: ITeamsNavLinkFirstLevel[];
}

interface ITeamsNavLinkFirstLevel extends INavLink {
  links: ITeamsNavLink[];
}

interface ITeamsNavLink extends INavLink {
  driveId: string;
  itemId: string;
}

interface TeamsFilePickerProps {
  onFileSelection: (files: ITeamItem[]) => any;
  multiFileSelect: boolean;
}

export const TeamsFilePicker = (props: TeamsFilePickerProps) => {
  const [channelSelectedKey, setChannelSelectedKey] = useState<string>();
  const [channelGroups, setChannelGroups] = useState<ITeamsNavLinkGroup[]>([]);
  const [folderPath, setFolderPath] = useState<ITeamsBreadcrumbItem[]>([]);

  const [teamItems, setTeamItems] = useState<ITeamItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<ITeamItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingFiles, setLoadingFiles] = useState<boolean>(false);

  useEffect(() => {
    ApiService.TeamsIntegrationsController.getTeamsWithChannelsV2((response: IAPIResponse) => {
      if (response.error == null) {
        const teams = response.payload as ITeam[];

        setChannelGroups([
          {
            links: teams.map((team) => ({
              url: '',
              name: team.displayName,
              isExpanded: false,
              links: team.__channels.map((channel) => ({
                url: '',
                name: channel.name,
                key: channel.id,
                itemId: channel.id,
                driveId: channel.parentReference.driveId,
              })),
            })),
          },
        ]);
        setLoading(false);
      }
    });
  }, []);

  const onChannelClick = (ev?, item?: ITeamsNavLink) => {
    if (item && item.itemId && item.driveId) {
      setChannelSelectedKey(item.key);
      setFolderPath([
        {
          text: item.name,
          key: item.itemId,
          driveid: item.driveId,
        },
      ]);
      updateFolderItems(item.driveId, item.itemId);
    }
  };

  const updateFolderItems = (driveId: string, itemId: string) => {
    setLoadingFiles(true);
    ApiService.TeamsIntegrationsController.getItems(driveId, itemId, (response: IAPIResponse) => {
      if (response.error == null) {
        const responseBody = response.payload as ITeamGetItemResponse[];
        let items: ITeamItem[] = [];
        responseBody.map((item) => {
          items.push({
            name: item.name,
            nameWithFolderExt: 'folder' in item ? `${item.name}.folder` : item['name'],
            itemId: item.id,
            lastModifiedBy: item.lastModifiedBy.user.displayName,
            lastModifiedDateTime: item.fileSystemInfo.lastModifiedDateTime,
            driveId: driveId,
            isFolder: 'folder' in item,
            parentFolderID: item.parentReference.id,
          });
        });
        setTeamItems(items);
        setLoadingFiles(false);
      }
    });
  };

  useEffect(() => {
    sessionStorage.removeItem('file-table-last-selection');
    setSelectedItems([]);
  }, [teamItems]);

  const onBreadcrumbFolderClick = (ev, item: ITeamsBreadcrumbItem, folderPath: MutableRefObject<ITeamsBreadcrumbItem[]>): void => {
    updateFolderItems(item.driveid, item.key);
    const i = folderPath.current.findIndex((x) => x.key === item.key);
    if (i > -1) {
      setFolderPath(folderPath.current.slice(0, i + 1));
    }
  };

  return (
    <>
      <div className="teams-file-container">
        {loading ? (
          <div className="teams-file-loading-container">
            <Spinner label="Loading..." />
          </div>
        ) : (
          <>
            <section className="teams-file-left-section">
              <h1>Your teams</h1>
              <div className="teams-file-nav-container">
                <Nav groups={channelGroups} onLinkClick={onChannelClick} selectedKey={channelSelectedKey} />
              </div>
            </section>
            <section className="teams-file-right-section">
              {loadingFiles ? (
                <div className="teams-file-loading-container">
                  <Spinner label="Loading..." />
                </div>
              ) : (
                <div>
                  <BreadcrumbFixed fluentBreadcrumbProps={{items: folderPath}} onClick={onBreadcrumbFolderClick} />
                  <div className="teams-file-table-container">
                    <FileListC
                      defaultSortOnThisColumnIndex={1}
                      fileIconFromField={'nameWithFolderExt'}
                      onItemsSelected={(selectedRows: ITeamItem[]) => {
                        setSelectedItems(selectedRows);
                      }}
                      currentSelectedItems={selectedItems}
                      currentSelectedItem={undefined}
                      hideBottomButton={true}
                      selectionMode={props.multiFileSelect ? SelectionMode.multiple : SelectionMode.single}
                      tableName={'normal'}
                      noDataMessage={folderPath.length === 0 ? 'Select a channel from the left menu' : 'Empty Folder'}
                      columns={[
                        {
                          name: 'File Type',
                          dataType: 'string',
                          fieldName: 'nameWithFolderExt',
                          iconName: 'Page',
                          size: 'tiny',
                        },
                        {
                          name: 'Name',
                          dataType: 'string',
                          fieldName: 'name',
                          iconName: null,
                          size: 'large',
                          onRender: (item: ITeamItem) => {
                            if (item.isFolder) {
                              return (
                                <span
                                  style={{
                                    textDecoration: 'underline',
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    updateFolderItems(item.driveId, item.itemId);
                                    setFolderPath(
                                      folderPath.concat([
                                        {
                                          text: item.name,
                                          key: item.itemId,
                                          driveid: item.driveId,
                                        },
                                      ])
                                    );
                                  }}>
                                  {item.name}
                                </span>
                              );
                            } else {
                              return <span>{item.name}</span>;
                            }
                          },
                        },
                        {
                          name: 'Last Modified By',
                          dataType: 'string',
                          fieldName: 'lastModifiedBy',
                          iconName: null,
                          size: 'large',
                        },
                        {
                          name: 'Last Modified',
                          dataType: 'date',
                          fieldName: 'lastModifiedDateTime',
                          iconName: null,
                          size: 'medium',
                        },
                      ]}
                      items={teamItems}
                    />
                  </div>

                  <div className="teams-file-select-button-container">
                    <div />
                    <LabelButton
                      text={'Select'}
                      orangeSolid
                      icon={'OpenFile'}
                      disabled={selectedItems.filter((x) => !x.isFolder).length == 0}
                      onClick={() => {
                        const files = selectedItems.filter((x) => !x.isFolder);
                        if (files.length != selectedItems.length) {
                          EventDispatcher.dispatchToast("Select folders is not supported! Every selected folder it's ignored.", 'warning');
                        }
                        if (!props.multiFileSelect) {
                          props.onFileSelection(files.slice(0, 1));
                        } else {
                          props.onFileSelection(files);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </section>
          </>
        )}
      </div>
    </>
  );
};
