import {IPermissionRecap, IBaseLibraries} from '../../Models/IUser';
import {
  SET_USER,
  SET_PERMISSIONS_BY_ACTION,
  UserActionType,
  SET_ACCESSTOKEN,
  SET_PERMISSIONS_BY_DOCUMENTS,
  SET_USER_INFO,
} from './UserAction';
import {TSelectUserInfo, UserState} from './UserState';

const getLoggedUser = () => {
  let u: any = localStorage.getItem('logged-user');
  if (u != null) {
    return JSON.parse(u);
  } else {
    return undefined;
  }
};

const DEFAULT_STATE: UserState = {
  currentUser: getLoggedUser(),
  permissionsByActions: undefined,
  permissionsForDocuments: [],
  selectUserInfo: {} as TSelectUserInfo,
};

export const userReducer = (state: UserState = DEFAULT_STATE, action: UserActionType): UserState => {
  switch (action.type) {
    case SET_USER:
      if (action.payload !== undefined) {
        localStorage.setItem('logged-user', JSON.stringify(action.payload));
      } else {
        localStorage.removeItem('logged-user');
      }

      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_PERMISSIONS_BY_ACTION:
      return {
        ...state,
        permissionsByActions: action.payload,
      };
    case SET_PERMISSIONS_BY_DOCUMENTS:
      return {
        ...state,
        permissionsForDocuments: action.payload,
      };

    case SET_USER_INFO:
      return {
        ...state,
        selectUserInfo: action.payload,
      };
    default:
      return state;
  }
};
