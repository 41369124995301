import {Spinner} from '@fluentui/react';
import {useAppInsightsContext} from '@microsoft/applicationinsights-react-js';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {FileList, LabelButton, ModalDialog} from '@Eni/docware-fe-master';
import {formatFileSize} from '../../Components/FileUploadBoxPreview/FileUploadBoxPreview';
import {IDocumentItem} from '../../Models/IDocument';
import {GenericActions} from '../../Reducers/Generic/GenericAction';
import {GlobalState} from '../../Reducers/RootReducer';
import store from '../../Reducers/Store';
import {IAPIResponse} from '../../Services/AjaxService';
import ApiService from '../../Services/ApiService';
import AppRoutes from '../../Utils/AppRoutes';
import {getTrackingComponent} from '../../AppInsights';
import ContextMenu from '../../Components/ContextMenu/ContextMenu';
import './MyDocumentsPage.scss';
import StatusBubble from '../../Components/StatusBubble/StatusBubble';
import {setPageContentState} from '../FullPageMessage/FullPageMessage';
import {DocumentListAction} from '../../Reducers/DocumentSearch/DocumentSearchActions';
import PermissionsOnDocuments from '../../Utils/PermissionsOnDocuments';
import {UserActions} from '../../Reducers/User/UserAction';
import {IPermissionByData} from '../../Models/IUser';
import {getDocumentLabel, getDocumentLabelString} from '../../Utils/ComponentsUtils';
import AttachmentSmallBrowserButton from '../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import VideoTutorialIcon from '../../Components/VideoTutorialIcon/VideoTutorialIcon';
import { formatCurrentVersion } from '../../Utils/VersioningUtils';

const MyDocumentsPage = () => {
  /** application insight metrics */
  useAppInsightsContext().trackMetric(
    {
      average: 1,
      name: 'React Component Engaged Time (seconds)',
      sampleCount: 1,
    },
    {'Component Name': 'MyDocumentsPage'}
  );

  const navigate = useNavigate();

  /** redux reference to documents  */
  let documentSearch = useSelector((state: GlobalState) => state.documentSearch);

  const loggedUser = useSelector((state: GlobalState) => state.user.currentUser);

  /** this state is only used as hook to request specific actions across the whole site */
  let generic = useSelector((state: GlobalState) => state.generic);

  const permissions = useSelector((state: GlobalState) => state.user.permissionsByActions);

  const [loadAmount, setLoadAmount] = useState<number>(100);
  const [reloadDocuments, setReloadDocuments] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<IDocumentItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [documentsToDelete, setDocumentsToDelete] = useState<string[]>([]);

  useEffect(() => {
    if (!permissions?.canCreate) {
      /** define with this call what will be visible in the MESSAGE_PAGE */
      setPageContentState({
        title: "You can't stay here.",
        subtitle: "You don't have the permissions to use this page.",
        imageName: 'accessDeniedPage',
        button: {
          icon: 'ChromeBackMirrored',
          text: 'Go back to Drive',
          onClick: {
            type: 'navigate',
            arguments: AppRoutes.HOME_ROUTE,
          },
        },
      });

      /** goto the MESSAGE_PAGE. this must happen after calling setPageContentState */
      navigate(AppRoutes.MESSAGE_PAGE);
    }
  }, []);

  useEffect(() => {
    /** test body */
    const documentSearchBody: any = {
      //"search": "",
      limit: loadAmount,
      offset: 0,
    };

    /** API call to get favorite documents */
    if (loggedUser !== undefined) {
      setLoading(true);
      ApiService.DocumentController.getDocuments(documentSearchBody, (response: IAPIResponse) => {
        if (response.error == null) {
          setDocuments(response.payload);
        } else {
          setDocuments([]);
        }
        setLoading(false);
      });
    }
  }, [reloadDocuments, generic.reloadRequest, loadAmount]);

  let currentPermissions = useSelector((state: GlobalState) => state.user.permissionsForDocuments);
  useEffect(() => {
    let docIds = documents.map((x: IDocumentItem) => {
      return x.documentId;
    });

    PermissionsOnDocuments.getDocumentPermissions(currentPermissions, docIds, (result: IPermissionByData[]) => {
      store.dispatch(UserActions.setPermissionsByDocuments(result));
    });
  }, [documents]);

  for (let i = 0; i < documents.length; i++) {
    if (documents[i].mainFile) {
      documents[i]['fileSize'] = formatFileSize(documents[i].mainFile.size);
    }
  }

  return (
    <div className="general-page-container">
      <DriveDocumentManagementToolbar
        type={
          documentSearch.activeDocument != null
            ? 'actions-on-document'
            : documentSearch.activeDocuments.length === 0
            ? 'navigation-voices'
            : 'action-on-multi-select-documents'
        }
      />
      <ModalDialog
        modalTitle={'Delete document?'}
        modalMessage={'Documents deleted in this way are moved to the User Trash Bin. Proceed anyway?'}
        enableModal={showModal}
        onAbort={() => {
          setShowModal(false);
        }}
        onAccept={async () => {
          setShowModal(false);

          for (let i = 0; i < documentsToDelete.length; i++) {
            let id: string = documentsToDelete[i];
            /** this pattern is needed in order to be able to await each file deletion */
            let result = await ApiService.DocumentController.setDeletedDocument({documentId: id, documentActivation: 2});
            if (result.error) {
              if (result.payload?.title === "User's Trash Bin is full") {
                window.document.dispatchEvent(new Event('trashBinFullModal'));
              }
            }
          }

          store.dispatch(GenericActions.reloadRequest());
        }}
      />
      <div className="favorites-page-main-wrap">
        <div className="favorites-page-title">
          My documents
          <VideoTutorialIcon />
        </div>
        {loading && (
          <div className="favorites-page-spinner-wrap-main">
            <div className="favorites-page-spinner-inner">
              <Spinner label="Loading..." />
            </div>
          </div>
        )}
        {!loading && (
          <div>
            <FileList
              defaultSortOnThisColumnIndex={2}
              fileIconFromField={'mainFile.fileName'}
              onItemsSelected={(selectedRows: any[]) => {
                if (selectedRows.length === 0) {
                  store.dispatch(DocumentListAction.setActiveDocument(null));
                  store.dispatch(DocumentListAction.setActiveDocuments([]));
                }
                if (selectedRows.length === 1) {
                  store.dispatch(DocumentListAction.setActiveDocument(selectedRows[0]));
                  store.dispatch(DocumentListAction.setActiveDocuments([]));
                }
                if (selectedRows.length > 1) {
                  store.dispatch(DocumentListAction.setActiveDocument(null));
                  store.dispatch(DocumentListAction.setActiveDocuments(selectedRows));
                }
              }}
              currentSelectedItems={documentSearch.activeDocuments}
              currentSelectedItem={documentSearch.activeDocument}
              hideBottomButton={true}
              columns={[
                {
                  name: 'File Type',
                  dataType: 'string',
                  fieldName: 'name',
                  iconName: 'Page',
                  size: 'tiny',
                },
                {
                  name: '',
                  dataType: 'string',
                  fieldName: '',
                  iconName: '',
                  size: 'micro',
                  onRender: (item: IDocumentItem) => {
                    return (
                      <div className="file-name-column-cell">
                        <span className="file-name-column-cell-buttons">
                          <ContextMenu
                            item={item}
                            type={'normal'}
                            emitAction={(type: string) => {
                              if (type === 'favorites') {
                                store.dispatch(DocumentListAction.setActiveDocument(null));
                                store.dispatch(DocumentListAction.setActiveDocuments([]));
                                setReloadDocuments(!reloadDocuments);
                              }
                            }}
                          />
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Name',
                  dataType: 'string',
                  fieldName: 'documentName',
                  iconName: null,
                  size: 'large',
                  onRender: (item: IDocumentItem) => {
                    let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();
                    return (
                      <div className="file-name-column-cell">
                        <span
                          title={getDocumentLabelString(item.documentName, item.documentCode)}
                          className="file-name-column-cell-buttons-label file-linkname"
                          onClick={() => {
                            navigate(navUrl);
                          }}>
                          {getDocumentLabel(item.documentName, item.documentCode, navUrl)}
                          <div className="file-name-file-inline-button">
                            <AttachmentSmallBrowserButton ownerDocument={item} />
                          </div>
                        </span>
                      </div>
                    );
                  },
                },
                {
                  name: 'Version',
                  dataType: 'string',
                  fieldName: 'versionNumber',
                  iconName: null,
                  size: 'small',
                  onRender: (item: IDocumentItem) => {
                    return formatCurrentVersion(item.versionNumber)
                  }
                },
                {
                  name: 'Created by',
                  dataType: 'string',
                  fieldName: 'createdBy.name',
                  iconName: null,
                  size: 'medium',
                },
                {
                  name: 'Authors',
                  dataType: 'string',
                  fieldName: 'authors',
                  iconName: null,
                  size: 'medium',
                  onRender: (item: IDocumentItem) => {
                    return item.authors.map((x) => x.name).join(', ');
                  },
                },
                {
                  name: 'Modified',
                  dataType: 'date',
                  fieldName: 'updateDate',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Professional Area',
                  dataType: 'string',
                  fieldName: 'professionalArea.name',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Last modified',
                  dataType: 'date',
                  fieldName: 'updateDate',
                  iconName: null,
                  size: 'small',
                },
                {
                  name: 'Status',
                  dataType: 'string',
                  fieldName: 'documentStatus',
                  iconName: null,
                  size: 'small',
                  onRender: (item: IDocumentItem) => {
                    return <StatusBubble isOldVersion={item.isOldVersion} documentStatus={item.documentStatus} />;
                  },
                },
              ]}
              items={documents}
            />
            {documents.length === loadAmount && (
              <div className="favorites-page-centered-button">
                <LabelButton
                  whiteOutlined
                  icon="ChevronDown"
                  text="Load more documents"
                  onClick={() => {
                    setLoadAmount(loadAmount + 100);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default getTrackingComponent(MyDocumentsPage, 'MyDocumentsPage');
