import { useDispatch } from "react-redux";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import {DocumentListAction} from '../Reducers/DocumentSearch/DocumentSearchActions';

export const useNavigateToSearchAndReset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function navigateWrapper(to: To, options?: NavigateOptions) {
    dispatch(DocumentListAction.setActiveSearchResults([]))
    navigate(to, options);
  }
  return navigateWrapper
}