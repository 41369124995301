import {IPersonaProps, ITag} from '@fluentui/react';
import {IOptionDto, IPersonaOptionDto} from '../Models/IOptionDto';
import {IUser} from '../Models/IUser';
import {IAPIResponse} from '../Services/AjaxService';
import ApiService from '../Services/ApiService';
import {bonifyEmailField} from './DictUtils';

const FREE_TEXT = 'FREE_TEXT';

const optionsToPersonas = (users: IPersonaOptionDto[]) => {
  return users.map((u) => {
    return {
      key: u.id,
      text: u.name,
      secondaryText: u.id.startsWith(FREE_TEXT) ? '' : u.id.toUpperCase() + ' - ' + bonifyEmailField(u.secondaryText),
    };
  }) as IPersonaProps[];
};

export const IntegrationUtils = {
  joinPersonasArrays: (personasArray: IPersonaProps[][]) => {
    let result: IPersonaProps[] = [];

    for (let i = 0; i < personasArray.length; i++) {
      let arr = personasArray[i];
      for (let j = 0; j < arr.length; j++) {
        result.push({...arr[j]});
      }
    }

    return result;
  },
  resolvePeoplePickerVoicesNoCastAuthorizationRequest: async (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined
  ): Promise<IPersonaProps[]> => {
    if (filterText.length < 3) {
      return [];
    }

    let response: IAPIResponse = await ApiService.UsersController.getUsersAuthorizationRequest({
      search: filterText,
      limit: 20,
      offset: 0,
    });

    if (response.error == null) {
      let result = response.payload as IUser[];
      let selectedIds = (currentPersonas ?? []).map((p) => p.key);
      let users = result.filter((u) => selectedIds.indexOf(u.id) === -1);
      return users;
    } else {
      return [];
    }
  },
  resolvePeoplePickerVoicesNoCast: async (filterText: string, currentPersonas: IPersonaProps[] | undefined): Promise<IPersonaProps[]> => {
    if (filterText.length < 3) {
      return [];
    }

    let response: IAPIResponse = await ApiService.UsersController.getUsers({
      search: filterText,
      limit: 20,
      offset: 0,
    });

    if (response.error == null) {
      let result = response.payload as IUser[];
      let selectedIds = (currentPersonas ?? []).map((p) => p.key);
      let users = result.filter((u) => selectedIds.indexOf(u.id) === -1);
      return users;
    } else {
      return [];
    }
  },
  resolvePeoplePickerVoices: async (
    filterText: string,
    currentPersonas: IPersonaProps[] | undefined,
    extendList?: IPersonaProps[],
    returnFilterTextOption?: boolean
  ): Promise<IPersonaProps[]> => {
    if (filterText.length < 3) {
      return [];
    }

    let response: IAPIResponse = await ApiService.UsersController.getUsers({
      search: filterText,
      limit: 20,
      offset: 0,
    });

    if (response.error == null) {
      const result = response.payload as IUser[];
      const selectedIds = (currentPersonas ?? []).map((p) => p.key);
      const users: IPersonaOptionDto[] = [];

      if (returnFilterTextOption) {
        /* TODO 12261 Search Full Text del campo autore (FE). Scommentare per rilasciare.
        users.push({
          id: `${FREE_TEXT}-${crypto.randomUUID()}`,
          name: filterText,
          secondaryText: '',
        });
        */
      }

      result
        .filter((u) => selectedIds.indexOf(u.id) === -1)
        .forEach((u) => {
          users.push({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`,
            secondaryText: u.email,
          });
        });

      if (extendList) {
        for (let i = 0; i < extendList.length; i++) {
          let id: string = (extendList[i] as any).key;
          if (id.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())) {
            users.push({
              id: id,
              name: id,
              secondaryText: 'GROUP',
            });
          }
        }
      }

      return optionsToPersonas(users);
    } else {
      return [];
    }
  },
  resolvePeoplePickerVoicesCoOwners: async (
    filterText: string,
    profAreaId: string,
    countriesId: string[],
    documentSubTypeId: string,
    currentPersonas: IPersonaProps[]
  ): Promise<IPersonaProps[]> => {
    if (filterText.length < 3) {
      return [];
    }

    let response: IAPIResponse = await ApiService.UsersController.getCoOwners(filterText, profAreaId, countriesId, documentSubTypeId);

    if (response.error == null) {
      let result = response.payload as IUser[];
      let selectedIds = (currentPersonas ?? []).map((p) => p.key);
      let users = result
        .filter((u) => selectedIds.indexOf(u.id) === -1)
        .map((u) => {
          return {
            id: u.id,
            name: `${u.firstName} ${u.lastName}`,
            secondaryText: u.email,
          };
        }) as IPersonaOptionDto[];
      return optionsToPersonas(users);
    } else {
      return [];
    }
  },
  resolveIntegrationTags: async (
    filter: string,
    selectedItems: ITag[] | undefined,
    integration: string,
    values?: IOptionDto[]
  ): Promise<ITag[]> => {
    if (filter.length < 3) {
      return [];
    }

    if (integration !== null) {
      let queryObject = {
        integrationId: (integration as string) || '',
        search: filter,
        limit: 20,
        offset: 0,
        queryColumn: 'name',
      };

      let response: IAPIResponse = await ApiService.IntegrationsController.getBOValues(queryObject, () => {});

      if (response.error == null) {
        const boList: any[] = response.payload;
        let tags = boList.map((item: any) => {
          let tag: ITag = {
            key: item.id,
            name: item.name,
          };
          return tag;
        });
        return tags.filter((t) => !(selectedItems || []).some((s) => s.key === t.key));
      } else {
        return [];
      }
    } else if (values !== undefined && values.length > 0) {
      return values
        .filter((v) => v.name.indexOf(filter) !== -1)
        .map((o) => {
          return {
            key: o.id,
            name: o.name,
          };
        }) as ITag[];
    }

    return [];
  },
};
