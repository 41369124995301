import {ModalDialog} from '@Eni/docware-fe-master';
import {Checkbox} from '@fluentui/react';
import {useState} from 'react';
import ApiService from '../../Services/ApiService';

interface DownloadDocumentModalProps {
  enableModal: boolean;
  onClose: () => void;
  documentIds: string[];
}

const DownloadDocumentModal = ({enableModal, onClose, documentIds}: DownloadDocumentModalProps) => {
  const [downloadOriginalFile, setDownloadOriginalFile] = useState<boolean>(false);
  const [downloadSignedFile, setDownloadSignedFile] = useState<boolean>(false);
  const [downloadAttachments, setDownloadAttachments] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const downloadButtonEnable = downloadOriginalFile || downloadSignedFile || downloadAttachments;
  return (
    <>
      <ModalDialog
        modalTitle={'Select what you want download'}
        modalInnerComponent={
          <div>
            <div style={{display: 'flex', flexDirection: 'column', gap: '1rem', padding: '1rem'}}>
              <Checkbox
                label="Original File"
                checked={downloadOriginalFile}
                onChange={(e, checked?: boolean) => {
                  setDownloadOriginalFile(!!checked);
                }}
              />
              <Checkbox
                label="Signed file"
                checked={downloadSignedFile}
                onChange={(e, checked?: boolean) => {
                  setDownloadSignedFile(!!checked);
                }}
              />
              <Checkbox
                label="Attachments"
                checked={downloadAttachments}
                onChange={(e, checked?: boolean) => {
                  setDownloadAttachments(!!checked);
                }}
              />
            </div>
          </div>
        }
        enableModal={enableModal}
        onAbort={() => {
          onClose();
        }}
        loadingButtons={downloadLoading}
        modalButtons={[
          {
            label: 'Cancel',
            onClick: () => {
              onClose();
            },
          },
          {
            label: 'Download',
            disabled: !downloadButtonEnable,
            onClick: async () => {
              setDownloadLoading(true);
              await ApiService.DownloadController.downloadFile(documentIds, downloadOriginalFile, downloadSignedFile, downloadAttachments);
              setDownloadLoading(false);
              onClose();
            },
          },
        ]}
      />
    </>
  );
};

export default DownloadDocumentModal;
