import {GENERIC_URLS} from '../../Constants/Urls';
import {createEmptyRequest, AjaxService, IAPIRequest, IAPIResponse} from '../AjaxService';

const GenericController = {
  getSystemLogs: async (limit: number, callback?: (response: any) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = GENERIC_URLS.SYSTEM_LOGS + '?limit=' + limit.toString();
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default GenericController;
