import {VIDEO_TUTORIAL_URLS} from '../../Constants/Urls';
import {AjaxService, createEmptyRequest, IAPIRequest, IAPIResponse} from '../AjaxService';

const VideoTutorialController = {
  getVideosTutorial: async (callback?: (response: IAPIResponse) => void) => {
    let request: IAPIRequest = createEmptyRequest();
    request.url = `${VIDEO_TUTORIAL_URLS.GET_VIDEOS_TUTORIAL}`;
    let response: IAPIResponse = await AjaxService.call(request);
    if (callback) {
      callback(response);
    }
    return response;
  },
};

export default VideoTutorialController;
