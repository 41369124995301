import {IPersonaProps, Spinner} from '@fluentui/react';
import {useEffect, useState} from 'react';
import {IWorkflowItem, IWorkflowSigner, IWorkflowSignStep, IWorkflowUser} from '../../../Models/IWorkflowItem';
import {getInputInfos} from '../../../Reducers/Generic/GenericAction';
import {IAPIResponse} from '../../../Services/AjaxService';
import ApiService from '../../../Services/ApiService';
import Icons from '../../../Utils/FabricIconsOutlet';
import {IntegrationUtils} from '../../../Utils/IntegrationUtils';
import GenericList from '../../../Components/GenericList/GenericList';
import {ModalDialog} from '@Eni/docware-fe-master';
import {FluentUIDecorator, FluentUIDecoratorTypes} from '../../../Components/FluentUIDecorator/FluentUIDecorator';
import './WorkflowEditor.scss';
import AppRoutes from '../../../Utils/AppRoutes';
import {getDocumentLabel, getDocumentLabelString} from '../../../Utils/ComponentsUtils';
import {useNavigate} from 'react-router-dom';
import AttachmentSmallBrowserButton from '../../../Components/AttachmentSmallBrowserButton/AttachmentSmallBrowserButton';
import {FileInfo} from '../../../Models/FileUtils';

const optionsToPersonas = (users: IWorkflowUser[]) => {
  return users.map((u: IWorkflowUser) => {
    return {
      key: u.userId,
      text: u.name,
      secondaryText: u.userId.toUpperCase(),
    };
  }) as IPersonaProps[];
};

const personasToOptions = (users: IPersonaProps[]) => {
  return users.map((u: IPersonaProps) => {
    return {
      userId: u.key,
      name: u.text,
      userSignStatus: 0,
      delegates: [],
    };
  }) as IWorkflowSigner[];
};

const WorkflowEditor = (props: any) => {
  const [loadingInPopup, setLoadingInPopup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [workflows, setWorkflows] = useState<IWorkflowItem[]>([]);
  const [showEdit, setShowEditInner] = useState<boolean>(false);
  const [editingStepIndex, setEditingStepIndex] = useState<number>(-1);
  const [editItem, setEditItem] = useState<IWorkflowItem | null>(null);
  const [editItemDelegates, setEditItemDelegates] = useState<IWorkflowSigner | null>(null);
  const [loading2, setLoading2] = useState<boolean>(true);
  const [docsWithAttach, setDocsWithAttach] = useState<{documentId: string; attachments: FileInfo[]}[]>([]);

  const navigate = useNavigate();
  const setShowEdit = (set: boolean) => {
    if (!set) {
      setEditItem(null);
      setEditItemDelegates(null);
    }
    setShowEditInner(set);
  };

  const loadDocumentsAttachments = (documentIds: string[]) => {
    setLoading2(true);
    ApiService.DocumentController.testDocumentListAnyAttachments(documentIds, (response: IAPIResponse) => {
      if (response.error === null) {
        setDocsWithAttach(response.payload);
      }
      setLoading2(false);
    });
  };

  const init = () => {
    setLoading(true);
    ApiService.WorkflowController.getAll(0, 100, (response: IAPIResponse) => {
      if (response.error == null) {
        setWorkflows(response.payload);

        loadDocumentsAttachments(
          response.payload.map((x: IWorkflowItem) => {
            return x.documentId;
          })
        );
      } else {
        setWorkflows([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    init();
  }, []);

  const hasSignatures = (item: IWorkflowSigner) => {
    return item.userSignStatus === 1 || item.delegates.filter((x: IWorkflowSigner) => x.userSignStatus === 1).length > 0;
  };

  let modalButtons: any[] =
    editItem && editItem.status === 1
      ? [
          {
            label: 'Delete Workflow',
            onClick: () => {
              if (editItem) {
                setLoadingInPopup(true);
                ApiService.WorkflowController.cancelWorkflow(editItem.workflowId, (response: IAPIResponse) => {
                  if (response.error == null) {
                    setLoadingInPopup(false);
                    setShowEdit(false);
                    init();
                  }
                });
              } else {
                setShowEdit(false);
              }
            },
          },
          {
            onClick: () => {
              setShowEdit(false);
            },
            label: 'Cancel',
          },
          {
            label: 'Save',
            onClick: () => {
              if (editItem) {
                setLoadingInPopup(true);
                ApiService.WorkflowController.insertWorkflowUpdateDelegates(editItem, (response: IAPIResponse) => {
                  if (response.error == null) {
                    setShowEdit(false);
                    init();
                  }
                  setLoadingInPopup(false);
                });
              } else {
                setShowEdit(false);
              }
            },
          },
        ]
      : [];

  return (
    <div>
      <div className="workflow-editor-page-title">Workflows</div>
      <ModalDialog
        modalTitle={'Manage workflow'}
        loadingButtons={loadingInPopup}
        modalInnerComponent={
          <div>
            {editItem && (
              <div style={{marginTop: '1em'}}>
                <div className="workflow-editor-page-dialog-title">Edit workflow delegates</div>
                {editItem.status !== 1 && <div className="workflow-editor-page-dialog-subtitle">You can only edit running workflows.</div>}
                {editItem.status === 1 && (
                  <div>
                    <div className="workflow-editor-page-dialog-subtitle">
                      You cannot edit delegates for users that already signed the document (considering also their delegates).
                    </div>

                    {editItem.signers.map((x: IWorkflowSignStep, i: number) => {
                      return (
                        <div key={i}>
                          <div className="workflow-editor-user-voices-title">Approval Step {i + 1}</div>
                          <div>
                            {x.users.map((x: IWorkflowSigner, j: number) => {
                              return (
                                <div className="workflow-editor-user-voice" key={j}>
                                  <div className="workflow-editor-user-voice-inner">
                                    <div>{x.name}</div>
                                    {x.userSignStatus === 0 && (
                                      <div
                                        title={'Set delegates for this user'}
                                        className="workflow-editor-edit-user"
                                        onClick={() => {
                                          setEditingStepIndex(i);
                                          setEditItemDelegates(x);
                                        }}>
                                        {Icons.getIcon('FollowUser')}
                                      </div>
                                    )}
                                  </div>
                                  {x.delegates.length > 0 && (
                                    <div>
                                      <div className="workflow-editor-user-delegated-title">Delegates</div>
                                      {x.delegates.map((x: IWorkflowSigner, y: number) => {
                                        return (
                                          <div className="workflow-editor-user-delegated-voice" key={y}>
                                            {x.name}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}

                    {editItemDelegates && hasSignatures(editItemDelegates) && (
                      <div className="workflow-editor-user-delegated-has-signed-content-wrap">
                        <div className="workflow-editor-user-delegated-has-signed-title">Editing forbidden:</div>
                        <div className="workflow-editor-user-delegated-has-signed-content">
                          This user or at least one of its delegates have already signed the document.
                        </div>
                      </div>
                    )}

                    {editItemDelegates && !hasSignatures(editItemDelegates) && (
                      <div style={{marginTop: '2em'}}>
                        <FluentUIDecorator
                          label={'Set delegates for ' + editItemDelegates.name}
                          info={getInputInfos('new-document', 'delegates')}
                          fluentComponent={FluentUIDecoratorTypes.PeoplePicker({
                            selectedUsers: optionsToPersonas(editItemDelegates.delegates ?? []),
                            onFilterChanged: async (
                              filterText: string,
                              currentPersonas: IPersonaProps[] | undefined,
                              limitResults?: number | undefined
                            ) => {
                              let results: IPersonaProps[] = await IntegrationUtils.resolvePeoplePickerVoices(
                                filterText,
                                editItemDelegates.delegates.map((x: IWorkflowSigner) => {
                                  return {
                                    key: x.userId,
                                    text: x.name,
                                    secondaryText: x.userId.toUpperCase(),
                                  };
                                })
                              );

                              return results.filter((x: IPersonaProps) => x.key !== editItemDelegates.userId);
                            },
                            onPeopleChanged: (p) => {
                              let opt = personasToOptions(p ?? []);
                              let editItemDelegatesUpdate = {
                                ...editItemDelegates,
                              };
                              editItemDelegatesUpdate.delegates = opt;
                              setEditItemDelegates(editItemDelegatesUpdate);

                              let editItemUpdate = {...editItem};
                              let targetSigners: IWorkflowSigner[] = editItem.signers[editingStepIndex].users;

                              for (let k = 0; k < targetSigners.length; k++) {
                                if (targetSigners[k].userId === editItemDelegates.userId) {
                                  targetSigners[k].delegates = opt;
                                }
                              }

                              editItemUpdate.signers[editingStepIndex].users = [...targetSigners];
                              setEditItem(editItemUpdate);
                            },
                          })}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        }
        modalButtons={modalButtons}
        onAbort={() => {
          setShowEdit(false);
        }}
        enableModal={showEdit}
      />
      {(loading || loading2) && (
        <div className="activity-page-spinner-wrap-main">
          <div className="activity-page-spinner-inner">
            <Spinner label="Loading..." />
          </div>
        </div>
      )}
      {!loading && !loading2 && (
        <div>
          <GenericList
            onItemInvoked={(item: any) => {
              // setEditItem({...item}) is not working for some reason
              setEditItem(JSON.parse(JSON.stringify(item)));
              setShowEdit(true);
            }}
            allowFilter={true}
            searchText={'Search workflow'}
            columns={[
              {
                name: '',
                dataType: 'string',
                fieldName: '',
                iconName: null,
                size: 'small',
                onRender: (item: any) => {
                  if (item.status === 1) {
                    return <div style={{color: '#444444'}}>{Icons.getIcon('Clock')}</div>;
                  } else if (item.status === 2) {
                    return <div style={{color: '#84d195'}}>{Icons.getIcon('SkypeCircleCheck')}</div>;
                  } else if (item.status === 5) {
                    return <div style={{color: 'rgb(132 169 209)'}}>{Icons.getIcon('DRM')}</div>;
                  } else {
                    return <div style={{color: '#d18484'}}>{Icons.getIcon('StatusErrorFull')}</div>;
                  }
                },
              },
              {
                name: 'Document Name',
                dataType: 'string',
                fieldName: 'documentName',
                iconName: null,
                size: 'bigger',
                onRender: (item: any) => {
                  let navUrl: string = AppRoutes.DOCUMENT_MANAGEMENT + '?documentid=' + item.documentId.toString();

                  let properDocRefs: {documentId: string; attachments: FileInfo[]}[] = docsWithAttach.filter(
                    (x: {documentId: string; attachments: FileInfo[]}) => x.documentId === item.documentId
                  );
                  let properDocRef: {documentId: string; attachments: FileInfo[]} = {documentId: null, attachments: null};
                  if (properDocRefs.length > 0) {
                    properDocRef = properDocRefs[0];
                  }

                  return (
                    <div className="file-name-column-cell">
                      <span
                        title={getDocumentLabelString(item.documentName, null)}
                        className="file-name-column-cell-buttons-label file-linkname"
                        onClick={() => {
                          navigate(navUrl);
                        }}>
                        {getDocumentLabel(item.documentName, null, navUrl)}
                        <div className="file-name-file-inline-button">
                          <AttachmentSmallBrowserButton
                            explicitAttachmentList={properDocRef.attachments}
                            explicitDocumentId={properDocRef.documentId}
                          />
                        </div>
                      </span>
                    </div>
                  );
                },
              },
              {
                name: 'Started By',
                dataType: 'string',
                fieldName: 'createdBy.name',
                iconName: null,
                size: 'bigger',
              },
              {
                name: 'Creation Date',
                dataType: 'date',
                fieldName: 'creationDate',
                iconName: null,
                size: 'medium',
              },
              {
                name: 'Steps',
                dataType: 'string',
                fieldName: '',
                iconName: null,
                size: 'medium',
                onRender: (item: IWorkflowItem) => {
                  return <div>{item.signers.length}</div>;
                },
              },
              {
                name: 'Workflow Status',
                dataType: 'string',
                fieldName: '',
                iconName: null,
                size: 'medium',
                onRender: (item: IWorkflowItem) => {
                  let statLabel = '';
                  let statClass = '';
                  if (item.status === 1) {
                    statLabel = 'Running';
                    statClass = 'activity-page-wf-status-label-running';
                  } else if (item.status === 2) {
                    statClass = 'activity-page-wf-status-label-completed';
                    statLabel = 'Completed';
                  } else if (item.status === 5) {
                    statClass = 'activity-page-wf-status-label-cancelled';
                    statLabel = 'Reverted Draft';
                  } else {
                    statClass = 'activity-page-wf-status-label-blocked';
                    statLabel = 'Cancelled';
                  }

                  return <div className={'activity-page-wf-status-label ' + statClass}>{statLabel}</div>;
                },
              },
              {
                name: 'Document Status',
                dataType: 'string',
                fieldName: '',
                iconName: null,
                size: 'medium',
                onRender: (item: IWorkflowItem) => {
                  let statLabel = '';
                  let statClass = '';
                  if (item.documentWorkflowStatus === 1) {
                    statLabel = 'Pending';
                    statClass = 'activity-page-wf-status-label-running';
                  } else if (item.documentWorkflowStatus === 2) {
                    statClass = 'activity-page-wf-status-label-completed';
                    statLabel = 'Signed';
                  } else if (item.documentWorkflowStatus === 3) {
                    statClass = 'activity-page-wf-status-label-blocked';
                    statLabel = 'Rejected';
                  }

                  return <div className={'activity-page-wf-status-label ' + statClass}>{statLabel}</div>;
                },
              },
            ]}
            items={workflows}
          />
        </div>
      )}
    </div>
  );
};

export default WorkflowEditor;
