import {IPermissionByData} from '../Models/IUser';
import ApiService from '../Services/ApiService';

const scheduledCalls: string[] = [];

const PermissionsOnDocuments = {
  getDocumentPermissions: async (
    currentPermissions: IPermissionByData[],
    neededList: string[],
    callback: (result: IPermissionByData[]) => void
  ) => {
    if (scheduledCalls.indexOf(neededList.join(',')) !== -1) {
      return;
    }
    scheduledCalls.push(neededList.join(','));

    let missingInfos: string[] = [];
    for (let i = 0; i < neededList.length; i++) {
      if (currentPermissions.filter((x: IPermissionByData) => x.documentId === neededList[i]).length === 0) {
        missingInfos.push(neededList[i]);
      }
    }

    if (missingInfos.length > 0) {
      let result = await ApiService.DocumentController.getUserPermissionsOnDocumentList(missingInfos);

      let newPermissionList: IPermissionByData[] = [...currentPermissions];
      if (result.error === null) {
        newPermissionList = newPermissionList.concat(result.payload);
        callback(newPermissionList);
        setTimeout(() => {
          scheduledCalls.pop();
        }, 2000);
      }
    }
  },
};

export default PermissionsOnDocuments;
