import {DriveDocumentManagementToolbar} from '../../Components/DriveDocumentManagementToolbar/DriveDocumentManagementToolbar';
import {UserForm} from '../../Components/UserForm/UserForm';
import './UserRequestPage.scss';

const UserRequestPage = () => {
  return (
    <div>
      {localStorage.getItem('user-not-enabled') !== 'true' && <DriveDocumentManagementToolbar type={'navigation-voices'} />}
      <div className="user-request-page">
        <UserForm />
      </div>
    </div>
  );
};

export default UserRequestPage;
