/**
 * Get the current date in format YYYYMMDD
 * */
export const getCurrentDateFormatted = (): string => {
  const date = new Date();

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}${month}${day}`;
};

export function getLastLoginDate(lastLogin: string): string{
  const dt = new Date(lastLogin).toLocaleDateString()
  return dt === '1/1/1' ? 'Never' : dt
}
